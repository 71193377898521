import React, { useId, memo } from "react";
import styles from "./RatingEditor.module.scss";

const RatingEditor = ({ value: rating, onChange, ...props }) => {
  const _id = useId();

  const handleClick = (index) => {
    let _rating = index + 1;
    if (_rating === rating) {
      _rating--;
    }

    onChange && onChange(_rating);
  };

  return (
    <div>
      <label
        htmlFor={props.id || _id}
        className={`${
          props.labelClassName
            ? props.labelClassName
            : "email-templates-paragraph-1-medium"
        } form-control__label${
          props.labelMargin ? ` mb-${props.labelMargin}` : ""
        }`}
      >
        {props.label}
      </label>
      <div className={styles["rating-container"]}>
        {[...Array(5)].map((el, index) => (
          <div
            key={`rating-editor-${index}`}
            className={styles["rating-star"]}
            onClick={() => handleClick(index)}
          >
            <img
              src={`/emails/images/${
                index >= Number(rating) ? "Star (1).png" : "Star.png"
              }`}
            />
          </div>
        ))}
      </div>
      {props.validationError ? <div className="form-control__error ui-caption-regular">{props.validationError.message}</div> : ''}
    </div>
  );
};

export default memo(RatingEditor);
