import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import UiCaptionRegular from "../../ui-elements/text/UiCaptionRegular";
import CompanyDetails from "../../billing/CompanyDetails";
import BillingInformation from "../../billing/BillingInformation";
import MainContent from "../../dashboard/MainContent";
import PaymentMethods from "../../billing/PaymentMethods";

const PaymentInfoPage = () => {
  return (
    <MainContent>
      <div className="an-layout">
        <SectionHead
          title={
            <>
              <UiHeading
                text="Company details"
                desktop="email-templates-subtitle-2-bold"
              />
              <UiCaptionRegular
                text="Fill in key information about your business."
                margin="4"
                className="color-secondary-colour-secondary-7"
              />
            </>
          }
        />
        <CompanyDetails />
        <SectionHead
          title={
            <>
              <UiHeading
                text="Billing information"
                desktop="email-templates-subtitle-2-bold"
              />
              <UiCaptionRegular
                text="Input essential information for accurate and error-free invoices."
                margin="4"
                className="color-secondary-colour-secondary-7"
              />
            </>
          }
        />
        <BillingInformation />
        <SectionHead
          title={
            <>
              <UiHeading
                text="Payment methods"
                desktop="email-templates-subtitle-2-bold"
              />
              <UiCaptionRegular
                text="Keep your transactions hassle-free. Please note that your account must always have a default payment method to guarantee continuous service. Consequently, your payment method set as default cannot be removed."
                margin="4"
                className="color-secondary-colour-secondary-7"
              />
            </>
          }
        />
        <PaymentMethods />
      </div>
    </MainContent>
  );
}

export default PaymentInfoPage;
