import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import NotificationsDropdown from "./NotificationsDropdown";
import bell from "../static/images/dashboard/Bell.svg";
import "./Notifications.scss";

const Notifications = () => {
  // TODO: connect to API when notifications are ready + load notifications dynamically so we don't overload API with a big notifications GET request

  const [notificationsCount, setNotificationsCount] = useState(4);
  const [opened, setOpened] = useState(false);
  
  const toggleNotifications = () => setOpened(!opened);

  const markAllAsRead = () => {
    setNotificationsCount(0);
  }

  // TODO: disabled for a while ANOMX-15

  return <></>

  return (
    <OutsideClickHandler onOutsideClick={() => setOpened(false)}>
      <div className="notifications">
        <button
          className="notifications__button"
          onClick={toggleNotifications}
        >
          <img
            src={bell}
            alt=""
            className="notifications__icon"
          />
          {notificationsCount ? <span className="notifications__count">{notificationsCount}</span> : ''}
        </button>
        {opened ? <NotificationsDropdown markAllAsRead={markAllAsRead} /> : ''}
      </div>
    </OutsideClickHandler>
  );
}

export default Notifications;