import { useState, useEffect } from "react";
import axios from "axios";
import useStep from "../hooks/useStep";
import StepWizardNavigation from "../ui-elements/step-wizard/StepWizardNavigation";
import WidgetButton from "./WidgetButton";

const WidgetsSelectionStep = () => {
  const { currentStepValue, handlePrev, handleNext } = useStep();
  const [value, setValue] = useState(currentStepValue);
  const [widgets, setWidgets] = useState([]);

  useEffect(() => {
    axios.get('/widgets', {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    }).then(response => {
      setWidgets(response.data);
    }).catch(e => {
      console.error(e);
    });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    handleNext(value, null, null, null, true);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="an-row flex-wrap h-fit-content">
        <div className="widgets-list">
          {widgets.map((widget, key) => (
            <WidgetButton
              key={`widgetButton-${key}`}
              onClick={sWidget => setValue(sWidget)}
              widget={widget}
              active={value && widget._id === value._id}
            />
          ))}
        </div>
      </div>
      <StepWizardNavigation
        onPrev={handlePrev}
        nextDisabled={!value}
      />
    </form>
  );
}

export default WidgetsSelectionStep;
