import axios from "axios";

export const getCampaigns = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/campaigns", {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAvailableCampaigns = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/campaigns/available", {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getShopCampaigns = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/shop/campaigns", {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveShopCampaigns = (campaignSlug, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/shop/campaigns/${campaignSlug}`,
        data,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json', // Added Content-Type header
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateShopCampaignById = (campaignId, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `/shop/campaign/${campaignId}`,
        data,
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json', // Added Content-Type header
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getShopCampaign = (slug) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/campaigns/${slug}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const getEmailsSentStats = (campaigns, performanceDateRange, slug, individual) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  const params = {
    startDate: performanceDateRange[0].toLocaleDateString('en-CA', options),
    endDate: performanceDateRange[1].toLocaleDateString('en-CA', options),
    campaigns: campaigns.map(campaign => {
      return campaign._id
    }),
    individual
  }

  if (slug === 'customer-survey') {
    params.index = 'email';
  }

  return new Promise((resolve, reject) => {
    axios.get(`/shop/campaigns/email-stats`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const sendTestEmail = (campaigns) => {
  return new Promise((resolve, reject) => {
    axios.post('/shop/campaigns/send-test-email', {
      campaigns: campaigns.map(campaign => {
        return campaign._id;
      })
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const updateShopCampaign = (id, data) => {
  return new Promise((resolve, reject) => {
    axios.put(`/shop/campaign/${id}`, data, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const duplicateShopCampaign = id => {
  return new Promise((resolve, reject) => {
    axios.post(`/shop/campaign/${id}/duplicate`, {}, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const deleteShopCampaign = id => {
  return new Promise((resolve, reject) => {
    axios.delete(`/shop/campaign/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getCampaignLocationStats = slug => {
  return new Promise((resolve, reject) => {
    axios.get(`/shop/campaigns/${slug}/location-stats`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getCampaignTimeline = slug => {
  return new Promise((resolve, reject) => {
    axios.get(`/shop/campaigns/${slug}/timeline`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getCampaignActivityReport = ({ slug, params, shopCampaignId }) => {
  return new Promise((resolve, reject) => {
    const url = shopCampaignId ? `/shop/campaign/${shopCampaignId}/activity-report` : `/shop/campaigns/${slug}/activity-report`;
    
    axios.get(url, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      },
      params
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getEntries = ({ slug, params }) => {
  return new Promise((resolve, reject) => {
    axios.get(`/shop/campaigns/${slug}/entries`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      },
      params
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getAbandonedCarts = ({ params }) => {
  return new Promise((resolve, reject) => {
    axios.get(`/shop/carts`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      },
      params
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getCustomerSurveyTemplates = () => {
  return new Promise((resolve, reject) => {
    axios.get('/campaigns/customer-survey/templates', {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getCampignsTools = () => {
  return new Promise((resolve, reject) => {
    axios.get('/campaigns/tools')
        .then(response => resolve(response.data))
        .catch(error => reject(error));
  })
}

export const getTotalReviewsStats = performanceDateRange => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  return new Promise((resolve, reject) => {
    axios.get('/campaigns/product-review/stats', {
      params: {
        startDate: performanceDateRange[0].toLocaleDateString('en-CA', options),
        endDate: performanceDateRange[1].toLocaleDateString('en-CA', options)
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error))
  });
}

export const getCustomerFeedbackList = params => {
  return new Promise((resolve, reject) => {
    axios.get('/shop/campaigns/product-review/customer-feedback', {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      },
      params
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getCustomerFeedbackAnswers = id => {
  return new Promise((resolve, reject) => {
    axios.get(`/shop/campaigns/product-review/answers/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getRaffleGiveawaySubscribersToday = () => {
  return new Promise((resolve, reject) => {
    axios.get(`/shop/campaigns/raffle-giveaway/subscribers-today`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getTotalImpressionStats = (campaign, performanceDateRange) => {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  return new Promise((resolve, reject) => {
    axios.get(`/shop/campaign/${campaign._id}/impressions`, {
      params: {
        startDate: performanceDateRange[0].toLocaleDateString('en-CA', options),
        endDate: performanceDateRange[1].toLocaleDateString('en-CA', options)
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getParticipants = ({ slug, params }) => {
  return new Promise((resolve, reject) => {
    axios.get(`/shop/campaigns/${slug}/participants`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      },
      params
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error))
  })
}

export const getConsentList = (params) => {
  return new Promise((resolve, reject) => {
    axios.get('/shop/consent/list', {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      },
      params
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getPromoCategories = () => {
  return new Promise((resolve, reject) => {
    axios.get('/holidays/categories', {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error))
  })
}

export const getHolidays = (id) => {
  return new Promise((resolve, reject) => {
    axios.get(`/holidays/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error))
  })
}

export const getCustomerSurveyEntries = ({ shopCampaignId, params }) => {
  return new Promise((resolve, reject) => {
    axios.get('/shop/campaigns/customer-survey/entries', {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      },
      params: {
        ...params,
        shopCampaignId
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  })
}