import React, { useState, useContext, useEffect, useMemo } from "react";
import FormControl from "../ui-elements/forms/FormControl";
import UiButton from "../ui-elements/buttons/UiButton";
import { saveShopStoreInformation } from "../api/Shop";
import Preloader from "../preloader/Preloader";
import { AuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";
import { Notify } from "../ui-elements/notify/notify";

const StoreInformationForm = () => {
  const [info, setInformation] = useState({});
  const { shopData, updateShop } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!shopData?.storeInformation) return;
    setInformation(shopData.storeInformation || {});
  }, [shopData]);

  const handleOnChange = (e) => {
    const key = e.target.name;
    setInformation({ ...info, [key]: e.target.value });
  };

  const handleSaveInfo = async (e) => {
    e.preventDefault();
    if (Object.keys(info).length === 0 || loading) return;
    setLoading(true);
    try {
      await saveShopStoreInformation(info);
      await updateShop();
      Notify.success({ title: "Store information saved" });
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const isChanged = useMemo(() => {
    return (
      JSON.stringify(info) === JSON.stringify(shopData.storeInformation || {})
    );
  }, [shopData.storeInformation, info, loading]);

  return (
    <form className="card card_24 card_shadow" onSubmit={handleSaveInfo}>
      <div className="an-layout">
        <div className="an-row">
          <div className="an-col an-col_50">
            <FormControl
              label="Store address"
              type="text"
              id="address"
              name="address"
              placeholder="First Name"
              value={info.address || ""}
              require={true}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
              validationError={errors.address}
            />
          </div>
          <div className="an-col an-col_50">
            <FormControl
              label="Zip Code"
              type="text"
              id="zip-code"
              name="zipCode"
              placeholder="Zip Code"
              value={info.zipCode || ""}
              require={true}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
              validationError={errors.zipCode}
            />
          </div>
        </div>

        <div className="an-row">
          <div className="an-col an-col_50">
            <FormControl
              label="City"
              type="text"
              id="city"
              name="city"
              placeholder="City"
              value={info.city || ""}
              require={true}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
              validationError={errors.city}
            />
          </div>
          <div className="an-col an-col_50">
            <FormControl
              label="State/Province"
              type="text"
              id="state"
              name="state"
              placeholder="State/Province"
              value={info.state || ""}
              require={true}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
              validationError={errors.state}
            />
          </div>
        </div>

        <div className="an-row">
          <div className="an-col an-col_50">
            <FormControl
              label="Country"
              type="text"
              id="country"
              name="country"
              placeholder="Country"
              value={info.country || ""}
              require={true}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
              validationError={errors.country}
            />
          </div>
          <div className="an-col an-col_50">
            <FormControl
              label="Phone"
              type="text"
              id="phone-number"
              name="phoneNumber"
              placeholder="Phone"
              value={info.phoneNumber || ""}
              require={true}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
              validationError={errors.phoneNumber}
            />
          </div>
        </div>

        <div className="flex-right gap-8 mt-4">
          <UiButton
            type="button"
            text="Cancel"
            size="medium"
            design="secondary"
            onClick={() => setInformation(shopData.storeInformation || {})}
            disabled={loading || isChanged}
          />
          <UiButton
            text="Save changes"
            size="medium"
            disabled={loading || isChanged}
          />
        </div>

        {loading && <Preloader absolute={true} overflow={true} />}
      </div>
    </form>
  );
};

export default StoreInformationForm;
