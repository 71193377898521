import loaderAnim from "../static/images/preloader/Loader anim@3x.png";
import "./Preloader.scss";

const Preloader = ({absolute, overflow, className, opaque}) => {
  return (
    <div className={`
      preloader${absolute ? ' preloader_absolute' : ''}
      ${overflow ? ' preloader_overflow' : ''}
      ${opaque ? ' preloader_overflow-opaque' : ''}
      ${className ? ` ${className}`: ''}
    `}>
      <img
        src={loaderAnim}
        alt=""
        className="preloader__icon"
      />
    </div>
  );
}

export default Preloader;