import UiCaptionRegular from "../text/UiCaptionRegular";
import CopyText from "../copy-text/CopyText";
import "./Table.scss";

const Table = props => {
  return (
    <div className="anom-table-wrapper">
      <table className="anom-table">
        <thead>
          <tr>
            {props.ths.map((th, key) => <th className="ui-extra-small-medium text-uppercase anom-table__th anom-table__th_w_33" key={key}>{th}</th>)}
          </tr>
        </thead>
        <tbody className="anom-table__body">
          {props.trs.map((tr, key) => (
            <tr key={key}>
              {tr.map((td, key) => (
                <td key={key}>
                  {td.copy ? (
                    <CopyText
                      elem={(
                        <UiCaptionRegular
                          text={td.text}
                          className="color-secondary-colour-secondary-7"
                        />
                      )}
                      text={td.text}
                    />
                  ) : (
                    <UiCaptionRegular
                      text={td.text}
                      className="color-secondary-colour-secondary-7"
                    />
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table