import "./SectionHead.scss";

const SectionHead = ({ align, title, filter, filterClassName = '', filterLabel}) => {
  return (
    <div className={`section-head${align ? ` section-head_align_${align}` : ''}`}>
      {title && <div className="section-head__title">{title}</div>}
      {filter && 
        <div className={`section-head__filter ${filterClassName}`}>
          {filterLabel && <div className='email-templates-paragraph-2-regular color-secondary-colour-secondary-7'>{filterLabel}</div>}
          {filter}
        </div>
      }
    </div>
  )
}

export default SectionHead;