import axios from "axios";

// Get invoice pdf blob
export const loadPdf = url => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .get("/stripe/pdf", {
        responseType: 'arraybuffer',
        params: {
          url: url
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => new Uint8Array(response.data))
      .then(uint8Array => {
        resolve(uint8Array);
      })
      .catch((error) => {
        reject(error);
      });
  });
};