import { createContext, useState, useEffect, useContext } from "react";
import {
  getShopInfo,
  getShopScore,
  getShopUpgradable,
  getUser,
} from "../api/Auth";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { getShopPackage } from "../api/Shop";
import { CampaignContext } from "./CampaignContext";

const defaultState = {
  user: null,
  isLoggedIn: false,
  doSetUser: () => {},
  updateSession: () => {},
  setLoggedIn: () => {},
};

export const AuthContext = createContext(defaultState);

const AuthContextProvider = (props) => {
  const navigate = useNavigate();
  const { children } = props;
  const [user, setUser] = useState(null);
  const [isLoggedIn, setLoggedIn] = useState(
    Boolean(localStorage.getItem("token"))
  );
  const [shopData, setShopData] = useState(null);
  const [shopScore, setShopScore] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [shopUpgradable, setShopUpgradable] = useState(true);
  const { fetchCampaigns, fetchAvailableCampaigns, fetchShopCampaigns } =
    useContext(CampaignContext);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const { exp } = jwtDecode(token);

      if (Date.now() >= exp * 1000 - 1440000) {
        setLoggedIn(false);
        localStorage.removeItem("token");
      }
    }
  }, []);

  const doSetUser = async () => {
    try {
      const response = await getUser();
      setUser(response);
    } catch (e) {
      console.log("AuthContext: Error", e);
    }
  };

  const fetchCampaignsData = async () => {
    fetchCampaigns();
    fetchAvailableCampaigns();
    fetchShopCampaigns();
  };

  const updateShop = async () => {
    try {
      const response = await getShopInfo();
      setShopData(response);
      if (!response.importStatus || response.importStatus === "started") {
        navigate("/onboarding");
        throw new Error("shop import status is not successful");
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        navigate("/onboarding");
      }
    }
  };

  const updateShopData = async () => {
    try {
      await updateShop();
      await fetchCampaignsData();
      const score = await getShopScore();
      const _currentPackage = await getShopPackage();
      const _upgradable = await getShopUpgradable();
      setCurrentPackage(_currentPackage);
      setShopScore(score);
      setShopUpgradable(_upgradable);
    } catch (e) {
      console.log("AuthContext: Error", e);
    }
  };

  const updateSession = async (token) => {
    if (loading || !token) return;
    setLoading(true);
    await doSetUser(token);
    await updateShopData(token);
    setLoading(false);
  };

  const hasNoPermission = (user) => {
    if (!user) return true;

    return (
      (user.permission === "admin" || user.permission === "employee") &&
      !user.parentUser?.subscriptionStatus
    );
  };

  const isSubScriptionPaused = (user) => {
    if (!user) return true;

    return (
      user.permission === "superadmin" &&
      !user.subscriptionStatus &&
      user.failedAttempts > 2
    );
  };

  const logout = () => {
    setUser(null);
    setShopData(null);
    setLoggedIn(false);
    localStorage.removeItem("token");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        shopScore,
        isLoggedIn,
        setLoggedIn,
        shopData,
        currentPackage,
        setUser,
        setShopData,
        shopUpgradable,
        logout,
        doSetUser,
        updateShop,
        updateShopData,
        updateSession,
        fetchCampaignsData,
        hasNoPermission,
        isSubScriptionPaused,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
