import React, { useEffect, useState, useCallback } from "react";
import CustomizationContainer from "../../ui-elements/customization/customization-container/CustomizationContainer";
import CustomizationForms from "../../ui-elements/customization/customization-forms/CustomizationForms";
import { getEmailHeaderPreview } from "../../api/Customizer";
import Preloader from "../../preloader/Preloader";
import CustomizationOptionsContainer from "../../ui-elements/customization/customiztion-options-container/CustomizationOptionsContainer";
import useStep from "../../hooks/useStep";
import StepWizardNavigation from "../../ui-elements/step-wizard/StepWizardNavigation";

const Customization = ({ schemas }) => {
  const [loading, setLoading] = useState(false);
  const [previewHTML, setPreviewHTML] = useState("");
  const { stepData, currentStepValue, handlePrev, handleNext } = useStep();
  const [values, setValues] = useState(currentStepValue);

  useEffect(() => {
    fetchPreview(currentStepValue);
  }, [currentStepValue]);

  const fetchPreview = async (customizer) => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await getEmailHeaderPreview({
        customizer,
        layout: stepData.layout,
      });
      setPreviewHTML(response);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleChange = useCallback((values) => {
    setValues(values);
    fetchPreview(values);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      handleNext(values);
    },
    [values]
  );

  return (
    <form className="an-row w-100 h-100" onSubmit={handleSubmit}>
      <CustomizationOptionsContainer className="an-col_3_12">
        {schemas && (
          <CustomizationForms
            key={`customization-form`}
            values={values}
            schemas={schemas}
            onChange={handleChange}
          />
        )}
      </CustomizationOptionsContainer>

      <CustomizationContainer
        key={`customization-container`}
        className="an-col_7_12"
        previewHTML={previewHTML}
        loading={loading}
      />

      <StepWizardNavigation onPrev={() => handlePrev(values)} />
    </form>
  );
};

export default Customization;
