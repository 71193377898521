import UiHeading from "../ui-elements/text/UiHeading";
import Chips from "../ui-elements/common/Chips";
import UiSmallRegular from "../ui-elements/text/UiSmallRegular";
import CampaignIcon from "../ui-elements/common/CampaignIcon";
import "./Card.scss";

const Card = props => {
  return (
    <div className="card card_19_8 card_shadow performance-card an-col an-col_50">
      <CampaignIcon
        emoji={props.emoji}
        color={props.color}
      />
      <div className="performance-card__data">
        <div className="performance-card__values">
          <UiHeading
            element="div"
            desktop="other-headings-h6-bold"
            text={props.data.value}
          />
          {typeof props.data.diff !== 'undefined' ? (
            <Chips
              arrow={true}
              value={`${props.data.diff}%`}
              color={props.data.diffPlus ? 'green' : 'red'}
            />
          ) : ('')}
        </div>
        <UiSmallRegular
          text={props.label}
          className="color-secondary-colour-secondary-7"
        />
      </div>
    </div>
  );
}

export default Card;