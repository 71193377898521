
import { useParams } from "react-router-dom";

import ActivityTable from '../../ui-elements/activity-table/ActivityTable'
import { activityData } from './datasets';

const ActivityPage = () => {
	const { toolSlug } = useParams();

	return (
		<ActivityTable
      subTitle={'Latest activity from your customers'}
			slug={toolSlug}
			type="tools"
			data={activityData}
			showImport={true}
		/>
	)
}

export default ActivityPage