import React, { useState, useId, useCallback, useRef } from "react";
import "./ProductSelector.scss";
import { getShopProducts } from "../../../api/Shop";
import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";

const ProductSelector = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState(props.value?.name);
  const [_value, setValue] = useState(props.value);
  const _id = useId();
  const inputRef = useRef(null);
  const fetchProducts = async (value) => {
    const response = await getShopProducts(null, value);
    setSuggestions(response.items);
  };

  const debounceFn = useCallback(_.debounce(fetchProducts, 500), []);

  const onTextChanged = (e) => {
    const value = e.target.value;
    if (value.length > 0) {
      debounceFn(value);
    } else {
      setSuggestions([]);
    }
    setText(value);
  };

  const suggestionSelected = (item) => {
    setText(item.name);
    setSuggestions([]);

    setValue({
      id: item._id,
      name: item.name,
      originalId: item.originalId
    });
    
    props.onChange && props.onChange({
      id: item._id,
      name: item.name,
      originalId: item.originalId
    });
    
    inputRef.current.focus();
  };

  const renderSuggestions = () => {
    if (suggestions.length === 0) {
      return null;
    }

    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setSuggestions([]);
          setText(_value?.name || "");
        }}
      >
        <ul>
          {suggestions.map((item) => (
            <li
              className="ui-caption-regular color-secondary-colour-secondary-7"
              key={item._id}
              onClick={() => suggestionSelected(item)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </OutsideClickHandler>
    );
  };

  return (
    <div className="form-control">
      {props.label && (
        <label
          htmlFor={props.id || _id}
          className={`${
            props.labelClassName
              ? props.labelClassName
              : "email-templates-paragraph-1-medium"
          } form-control__label${
            props.labelMargin ? ` mb-${props.labelMargin}` : ""
          }`}
        >
          {props.label}
        </label>
      )}
      <div className="ProductSelector form-control__inner">
        <input
          ref={inputRef}
          id={props.id || _id}
          className={`form-control__input ui-caption-regular${
            props.validationError ? " form-control__input_error" : ""
          }${props.className ? ` ${props.className}` : ""}`}
          value={text}
          onChange={onTextChanged}
          type="text"
          disabled={props.disabled}
          placeholder={props.placeholder}
        />
        {renderSuggestions()}

        {props.validationError ? (
          <div className="form-control__error ui-caption-regular">
            {props.validationError.message
              ? props.validationError.message
              : typeof props.validationError !== "object"
              ? props.validationError
              : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProductSelector;
