import React from "react";
import UiButton from "../buttons/UiButton";
import useStep from "../../hooks/useStep";
import styles from "./StepWizardNavigation.module.scss";

const StepWizardNavigation = ({ prevDisabled, nextDisabled, onPrev }) => {
  const { currentStep } = useStep();
  const prevLabel = currentStep.prevLabel || "Back";
  const nextLabel = currentStep.nextLabel || "Next";

  return (
    <div className={styles["base"]}>
      <UiButton
        size="medium"
        type="button"
        onClick={onPrev}
        text={prevLabel}
        design="secondary"
        className="ui-button_mw_150"
        disabled={prevDisabled}
      />
      <UiButton
        size="medium"
        text={nextLabel}
        design="secondary"
        className="ui-button_mw_150"
        disabled={nextDisabled}
      />
    </div>
  );
};

export default StepWizardNavigation;
