import { useState, useContext } from "react";
import { Send, Play, Pause, TrashCan, Edit } from "akar-icons";
import { Table, Header, HeaderRow, Body, Row, Cell, HeaderCell } from "@table-library/react-table-library/table";
import { useSort, HeaderCellSort } from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";

import SectionHead from "../../ui-elements/common/SectionHead";
import UiButton from "../../ui-elements/buttons/UiButton";
import { sendTestEmail } from "../../api/Campaign";
import { Notify } from "../../ui-elements/notify/notify";
import AnTable from "../../ui-elements/common/AnTable";
import Chips from "../../ui-elements/common/Chips";
import FixedDropdown from '../../ui-elements/fixed-dropdown/FixedDropdown';

import { updateShopCampaign, duplicateShopCampaign, deleteShopCampaign } from "../../api/Campaign";
import AkarIconsChevronVertical from "../../static/images/common/akar-icons_chevron-vertical.svg";
import AkarIconsChevronVerticalCopy from "../../static/images/common/akar-icons_chevron-vertical copy.svg";
import "./CampaignInstances.scss";
import { CampaignContext } from "../../context/CampaignContext";

const CampaignInstances = ({ campaigns, slug, handleCreateMode, convertion }) => {
  const [sending, setSending] = useState(false);
  const { fetchShopCampaigns, campaigns: _dbCampaigns, tools } = useContext(CampaignContext);
  const dbCampaigns = _dbCampaigns.concat(tools)

  const findSingle = (dbCampaigns, slug) => {
    for (const obj of dbCampaigns) {
      if (obj.slug === slug) {
        return obj.single;
      }
    }
    return null;
  }

  const isSingle = findSingle(dbCampaigns, slug);

  const nodes = campaigns.map(campaign => {
    let instanceSubjectLine = '';

    if (campaign?.customizer) {
      Object.keys(campaign.customizer).map(key => {
        if (!instanceSubjectLine && key.includes('email') && campaign.customizer[key]?.emailSubjectLine) {
          instanceSubjectLine = campaign.customizer[key].emailSubjectLine;
        }

        return key;
      });
    }

    return {
      ...campaign,
      instanceSubjectLine
    }
  });

  const sort = useSort({ nodes }, {}, {
    sortIcon: {
      margin: '0px',
      iconDefault: <img src={AkarIconsChevronVertical} alt="" />,
      iconUp: <img src={AkarIconsChevronVerticalCopy} alt="" />,
      iconDown: <img src={AkarIconsChevronVerticalCopy} alt="" />,
    },
    sortFns: {
      INSTANCENAME: array => array.sort((a, b) => a.name.localeCompare(b.name)),
      INSTANCESUBJECTLINE: array => array.sort((a, b) => a.instanceSubjectLine.localeCompare(b.instanceSubjectLine)),
      STATUS: array => array.sort((a, b) => a.status.localeCompare(b.status)),
      EMAILSSENT: array => array.sort((a, b) => a.emailsSent - b.emailsSent),
      OPEN: array => array.sort((a, b) => a.opened - b.opened),
      CLICKED: array => array.sort((a, b) => a.clicked - b.clicked),
      ORDERED: array => array.sort((a, b) => a.purchased - b.purchased),
      CONVERTION: array => array.sort((a, b) => a.convertion - b.convertion),
    },
  });

  const theme = useTheme([getTheme(), {
    Table: `
      --data-table-library_grid-template-columns: repeat(2, minmax(0, 1fr)) repeat(5, 120px) 48px
    `
  }]);

  const _sendTestEmail = async (e) => {
    e.preventDefault();

    setSending(true);

    await sendTestEmail(campaigns);

    setSending(false);

    Notify.success({
      title: 'Test email has been sent',
      message: 'Please check your inbox'
    });
  }

  const renderStats = (value, emailsSent) => {
    return (value && emailsSent) ? `${value}/${(value / emailsSent * 100).toFixed(2)}%` : `${value}/0.00%`;
  }

  const activateCampaign = async (id) => {
    await updateShopCampaign(id, {
      status: 'active'
    });

    Notify.success({
      title: 'Campaign has been activated'
    });

    fetchShopCampaigns();
  }

  const pauseCampaign = async (id) => {
    await updateShopCampaign(id, {
      status: 'paused'
    });

    Notify.success({
      title: 'Campaign has been paused'
    });

    fetchShopCampaigns();
  }

  const duplicateCampaign = async (id) => {
    await duplicateShopCampaign(id);

    Notify.success({
      title: 'Campaign has been duplicated'
    });

    fetchShopCampaigns();
  }

  const archiveCampaign = async (id) => {
    await updateShopCampaign(id, {
      status: 'archived'
    });

    Notify.success({
      title: 'Campaign has been archived'
    });

    fetchShopCampaigns();
  }

  const deleteCampaign = async (id) => {
    await deleteShopCampaign(id);

    Notify.success({
      title: 'Campaign has been deleted'
    });

    fetchShopCampaigns();
  }
  
  const createDropdownItems = (item) => {
    let items = [
      {
        onClick: () => activateCampaign(item._id),
        disabled: item.status === 'active',
        Icon: <Play />,
        iconText: 'Activate campaign',
      },
      {
        onClick: () => pauseCampaign(item._id),
        disabled: item.status !== 'active',
        Icon: <Pause />,
        iconText: 'Pause campaign',
      },
    ]
    
    if (!['raffle-giveaway', 'subscribers-management'].includes(item.slug)) {
      items.push({
        type: 'navlink',
        to: `${item._id}/edit`,
        disabled: item.status === 'active',
        Icon: <Edit />,
        iconText: 'Edit campaign',
      })
    }
    
    if (item.slug !== 'subscribers-management') {
      items.push({
        onClick: () => duplicateCampaign(item._id),
        disabled: isSingle,
        Icon: <Icon icon="ic:baseline-control-point-duplicate" />,
        iconText: 'Duplicate campaign',
      })
    }

    items = items.concat([
      {
        onClick: () => archiveCampaign(item._id),
        disabled: true,
        Icon: <Icon icon="ic:baseline-archive" />,
        iconText: 'Archive campaign',
      },
      {
        onClick: () => deleteCampaign(item._id),
        disabled: false,
        Icon: <TrashCan />,
        iconText: 'Delete campaign',
      },
    ])
    return items
  }

  return (
    <div className="card card_br_24 card_shadow card_overflow_visible card_mw_100">
      <SectionHead
        title={
          <>
            <div className="email-templates-subtitle-2-medium">Instances of this campaign</div>
            <div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">Effortlessly monitor key metrics of your campaign instances, status and access relevant actions.</div>
          </>
        }
        filter={(slug === 'customer-survey' || slug === 'promo-campaign') ?
          <UiButton
            size="small"
            design="secondary"
            className="ui-button_icon"
            onClick={handleCreateMode}
            text={<span className="ui-button__text">Create new instance</span>}
          />
        :
          <UiButton
            size="small"
            design="secondary"
            className="ui-button_icon ui-button_icon_gap_4"
            disabled={sending}
            onClick={_sendTestEmail}
            text={
              <>
                <span className="ui-button__text">Send test email</span>
                <span className="ui-button__icon">
                  <Send size="24" />
                </span>
              </>
            }
          />
        }
      />
      <div className="mt-24 campaign-instances-table">
        <AnTable
          body={
            <Table
              data={{ nodes }}
              sort={sort}
              theme={theme}
              layout={{
                custom: true
              }}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCellSort sortKey="INSTANCENAME">Instance Name</HeaderCellSort>
                      <HeaderCellSort sortKey="INSTANCESUBJECTLINE">Instance Subject Line</HeaderCellSort>
                      <HeaderCellSort sortKey="STATUS">Status</HeaderCellSort>
                      <HeaderCellSort sortKey="EMAILSSENT">{slug == 'customer-survey' ? 'Surveys sent'  : 'Emails Sent'}</HeaderCellSort>
                      <HeaderCellSort sortKey="OPEN">Open</HeaderCellSort>
                      <HeaderCellSort sortKey="CLICKED">Clicked</HeaderCellSort>
                      {slug === 'subscribers-management' ?
                        <HeaderCellSort sortKey="CONVERTION">Convertion</HeaderCellSort>
                      :
                        <HeaderCellSort sortKey="ORDERED">Ordered</HeaderCellSort>
                      }
                      <HeaderCell />
                    </HeaderRow>
                  </Header>
                  <Body>
                    {tableList.map((item) => {
                      return (
                      <Row item={item} key={item._id}>
                        <Cell>
                          {isSingle ?
                            item.name
                          :
                            <NavLink className="underline" to={`${item._id}`}>{item.shopCampaignName}</NavLink>
                          }
                        </Cell>
                        <Cell>{item.instanceSubjectLine}</Cell>
                        <Cell>
                          {item.status && (
                            <Chips
                              color={item.status === 'active' ? 'green' : 'red'}
                              value={item.status}
                              className={slug === 'subscribers-management' ? 'chips_size_long' : ''}
                            />
                          )}
                        </Cell>
                        <Cell>{item.emailsSent.toLocaleString()}</Cell>
                        <Cell>{renderStats(item.opened, item.emailsSent)}</Cell>
                        <Cell>{renderStats(item.clicked, item.emailsSent)}</Cell>
                          {slug === 'subscribers-management' ?
                            <Cell>{renderStats(item.convertion, item.emailsSent)}</Cell>
                          :
                            <Cell>{renderStats(item.purchased, item.emailsSent)}</Cell>
                          }
                        <Cell>
                          <FixedDropdown className="campaign" items={createDropdownItems(item)} />
                        </Cell>
                      </Row>
                    )})}
                  </Body>
                </>
              )}
            </Table>
          }
        />
      </div>
    </div>
  )
}

export default CampaignInstances;