import React, { useState, useEffect, memo } from "react";
import axios from "axios";
import styles from "./MultiSelector.module.scss";
import UiBaseCheckbox from "../../forms/UiBaseCheckbox";

const MultiSelector = (props) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (options.length > 0) return;
    fetchOptions();
  }, [props.options, options]);

  const fetchOptions = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const url = props.options.slice(4);
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      });
      setOptions(response.data || []);
    } catch (e) {
      console.log("e", e);
    }
    setLoading(false);
  };

  const handleOnChange = (e, slug) => {
    let newValues = props.value;

    if (e.target.checked) {
      newValues = [...newValues, slug];
    } else {
      newValues = newValues.filter((value) => value !== slug);
    }

    props.onChange(newValues);
  };

  return (
    <div>
      <div className={styles["base"]}>
        {options.map((option) => (
          <UiBaseCheckbox
            key={option._id}
            name={option.slug}
            value={option.slug}
            label={option.name}
            checked={props.value ? props.value.includes(option.slug) : false}
            variant="pill"
            onChange={(e) => handleOnChange(e, option.slug)}
          />
        ))}
      </div>

      {props.validationError && (
        <div className="form-control__error ui-caption-regular">
          {props.validationError.message}
        </div>
      )}
    </div>
  );
};

export default memo(MultiSelector);
