import axios from "axios";

// Get packages
export const getShopWidgets = (status, slug) => { //
  const token = localStorage.getItem("token");
  const params = {}
  if (status) params['status'] = status;
  if (slug) params['campaign'] = slug;

  return new Promise((resolve, reject) => {
    axios.get(`/shop/widgets`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
};

// Get shop package
export const getShopPackage = () => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .get("/shop/package", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Save shop information
export const saveShopStoreInformation = (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .put(
        "/shop",
        { storeInformation: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Save social media
export const saveSocialMedia = (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .put(
        "/shop",
        { socialMedia: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Save social media
export const saveMainAssets = (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .put(
        "/shop",
        { mainAssets: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Save social media
export const saveSignature = (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .put(
        "/shop",
        { signature: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Save social media
export const saveEmailHeader = (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .put(
        "/shop",
        { emailHeader: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Save social media
export const saveEmailFooter = (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .put(
        "/shop",
        { emailFooter: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


// Save company details
export const saveCompanyDetails = (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .put(
        "/shop",
        { companyDetails: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Save billing information
export const saveBillingInformation = (data) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .put(
        "/shop",
        { billingInformation: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Save shop widget
export const saveWidget = (data, status) => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .post(
        "/shop/widgets",
        { data, status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Update widget
export const updateWidget = (data, status) => {
  return new Promise((resolve, reject) => {
    let putData = { ...data, status };
    const widgetId = data._id;

    delete putData._id;
    
    axios.put(`/shop/widgets/${widgetId}`, putData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Publish shop widget
export const publishWidget = widgetId => {
  return new Promise((resolve, reject) => {
    axios.put(`/shop/widgets/${widgetId}`, {
      status: 'published'
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Pause shop widget
export const pauseWidget = widgetId => {
  return new Promise((resolve, reject) => {
    axios.put(`/shop/widgets/${widgetId}`, {
      status: 'draft'
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Pause shop widget
export const duplicateWidget = widgetId => {
  return new Promise((resolve, reject) => {
    axios.post(`/shop/widgets/${widgetId}/duplicate`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Archive shop widget
export const archiveWidget = widgetId => {
  return new Promise((resolve, reject) => {
    axios.put(`/shop/widgets/${widgetId}`, {
      status: 'archived'
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Delete shop widget
export const deleteWidget = widgetId => {
  return new Promise((resolve, reject) => {
    axios.delete(`/shop/widgets/${widgetId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Get shop widget
export const getWidget = widgetId => {
  return new Promise((resolve, reject) => {
    axios.get(`/shop/widgets/${widgetId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Get product categories
export const getShopProductCategories = () => {
  return new Promise((resolve, reject) => {
    axios.get('/shop/products/categories', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Get product tags
export const getShopProductTags = () => {
  return new Promise((resolve, reject) => {
    axios.get('/shop/products/tags', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Get product tags
export const getShopProductById = (id) => {
  return new Promise((resolve, reject) => {
    let url = `shop/products/${id}`;

    axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Get product tags
export const getShopProducts = (filter, search) => {
  return new Promise((resolve, reject) => {
    let url = '/shop/products';

    if (filter) {
      url += `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
    } else if (search) {
      url += `?search=${encodeURIComponent(search)}`;
    }

    axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Get blog post categories
export const getBlogPostCategories = () => {
  return new Promise((resolve, reject) => {
    axios.get('/shop/posts/categories', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Get blog post tags
export const getBlogPostTags = () => {
  return new Promise((resolve, reject) => {
    axios.get('/shop/posts/tags', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Get blog posts
export const getBlogPosts = filter => {
  return new Promise((resolve, reject) => {
    let url = '/shop/posts';

    if (filter) {
      url += `?filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Get shop customers
export const getShopCustomers = ({ params }) => {
  return new Promise((resolve, reject) => {
    axios.get('/shop/customers', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      params
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// Get customer types
export const getCustomerTypes = () => {
  return new Promise((resolve, reject) => {
    axios.get('/customer-types').then(response => resolve(response.data)).catch(error => reject(error));
  });
}

// Get customers and subscribers count
export const getCustomersSubscribersCount = () => {
  return new Promise((resolve, reject) => {
    axios.get('/shop/customers/count', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}