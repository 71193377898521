import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import UiHeading from "../ui-elements/text/UiHeading";
import UiButton from "../ui-elements/buttons/UiButton";
import MainContent from "../dashboard/MainContent";
import illustration from "../static/images/campaign/Illustration.svg";

const WithValidSubScription = (WrappedComponent) => {
  const WrappedComponentWithSubscriptionCheck = (props) => {
    const { user, isSubScriptionPaused } = useContext(AuthContext);

    if (isSubScriptionPaused(user)) {
      return (
        <MainContent fullHeight>
          <div className="card card_24 card_shadow card_flex card_align_center text-center h-100">
            <div className="card__overflow">
              <UiHeading
                text="Subscription is paused ⚠️"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="mt-40">
                <img
                  src={illustration}
                  alt="This quest is reserved for upgraded plans"
                  className="responsive-img"
                />
              </div>
              <div className="mt-40">
                <UiButton
                  elem="a"
                  href="/billing"
                  text="Update payment method"
                  size="medium"
                />
              </div>
            </div>
          </div>
        </MainContent>
      );
    }

    return <WrappedComponent {...props} />;
  };

  return WrappedComponentWithSubscriptionCheck;
};

export default WithValidSubScription;
