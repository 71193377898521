import { useState } from "react";
import axios from "axios";
import FormControl from "../ui-elements/forms/FormControl";
import UiButton from "../ui-elements/buttons/UiButton";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";

const ForgotPasswordForm = props => {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});

  const updateData = e => setData({...data, [e.target.name]: e.target.value});

  const handleSubmitEvent = e => {
    e.preventDefault();

    axios.post('/user/forgot-password', data)
      .then(() => {
        props.updateHeading('Please check your email');
        props.updateParagraph('We just sent an email to reset your password');
        props.updateFormSubmitted();
      })
      .catch(err => {
        if (err?.response?.data?.errors) {
          setErrors(err.response.data.errors);
        }
      });
  }

  return (
    <form
      onSubmit={handleSubmitEvent}
      className="mt-72 login-form mt-32-mobile"
    >
      <FormControl
        type="email"
        id="email"
        name="email"
        placeholder="Ex. youremail@email.com*"
        required={true}
        onChange={updateData}
        validationError={errors.email}
      />
      <div className="mt-64 text-center">
        <UiButton
          type="submit"
          size="large"
          text="Send email"
          className="ui-button_mw_200"
        />
        <UiCaptionRegular
          margin="16"
          text={<>Still need help? <a href={process.env.REACT_APP_CUSTOMER_SUPPORT_URL}>Contact us</a></>}
        />
      </div>
    </form>
  );
}

export default ForgotPasswordForm;