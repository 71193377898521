import { useEffect, useState } from "react";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import ModalLayout from "../../modal/Layout";
import UiHeading from "../../ui-elements/text/UiHeading";
import UiCaptionRegular from "../../ui-elements/text/UiCaptionRegular";
import UiButton from "../../ui-elements/buttons/UiButton";
import FormControl from "../../ui-elements/forms/FormControl";
import UiCaptionMedium from "../../ui-elements/text/UiCaptionMedium";
import Preloader from "../../preloader/Preloader";

const DomainAccountsEmailVerify = () => {
  const { id } = useParams();
  const token = localStorage.getItem('token');
  const [verifyDisabled, setVerifyDisabled] = useState(true);
  const [verificationCode, setVerificationCode] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`/domains/emails/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      setLoading(false);

      if (response.data.verfied) {
        navigate('/store-settings/domain-accounts');
      }
    }).catch(err => {
      console.error(err);

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setVerifyDisabled(verificationCode.toString().length !== 6);
  }, [verificationCode]);

  const verify = () => {
    setLoading(true);
    
    axios.post(`/domains/emails/${id}/verify`, { verificationCode }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      setLoading(false);
      navigate('/store-settings/domain-accounts');
    }).catch(err => {
      setErrors(err.response.data.errors);
      setLoading(false);
    })
  }

  return (
    <ModalLayout backTo="/store-settings/domain-accounts">
      <div>
        <div className="onboarding-question text-center mt-40">
          <UiHeading
            text="Check your email!"
            desktop="other-headings-h6-bold"
          />
          <UiCaptionRegular
            text="Thanks for keeping your account secure. Please provide the code sent to your email mystore@domainname.com"
            margin="8"
            className="color-secondary-colour-secondary-7"
          />
          <div className="mt-64 maxw maxw_431 text-left">
            <UiCaptionMedium text="Verification code" />
            <FormControl
              type="number"
              name="verificationCode"
              placeholder="6-digit code"
              id="verification_code"
              onChange={e => setVerificationCode(e.target.value)}
              value={verificationCode}
              margin="4"
              validationError={errors.verificationCode}
            />
            <UiCaptionMedium
              text="Didn’t get the email?"
              margin="32"
            />
            <UiCaptionRegular
              text={<>Check your spam folder or <NavLink to="/store-settings/domains-accounts/">re-enter your email and try again.</NavLink></>}
              className="color-secondary-colour-secondary-7"
              margin="8"
            />
          </div>
        </div>
        <div className="onboarding-questionnaire__bottom">
          <UiButton
            size="medium"
            elem="a"
            href="/store-settings/domain-accounts"
            text="Back"
            design="secondary"
            className="ui-button_mw_150"
          />
          <UiButton
            onClick={verify}
            size="medium"
            text="Verify"
            design="secondary"
            className="ui-button_mw_150"
            disabled={verifyDisabled}
          />
        </div>
      </div>
      {loading ? (
        <Preloader
          absolute={true}
          overflow={true}
        />
      ) : ''}
    </ModalLayout>
  )
}

export default DomainAccountsEmailVerify;