import { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import Modal from "react-modal";
import Masonry from "react-responsive-masonry";
import _ from "lodash";
import UiCaptionRegular from "../text/UiCaptionRegular";
import UiButton from "../buttons/UiButton";
import UiSmallRegular from "../text/UiSmallRegular";
import Preloader from "../../preloader/Preloader";
import Error from "../error/Error";
import UiParagpraphMedium from "../text/UiParagpraphMedium";
import { Cross } from "akar-icons";
import FormControl from "../forms/FormControl";
import StockImage from "./StockImage";
import { getImages } from "../../api/Stock";
import "./FileUploader.scss";
import ProductImageSelector from "../../campaign/ProductImageSelector/ProductImageSelector";

Modal.setAppElement("#root");

const FileUploader = (props) => {
  const [fileUrl, setFileUrl] = useState(props.image);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [stockSearch, setStockSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [stockImages, setStockImages] = useState([]);
  const [isOpenProductCatalogModal, setIsProductCatalogModal] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setFileUrl(props.image);
  }, [props.image]);

  useEffect(() => {
    let _disabled = true;

    if (!props?.disabled) {
      _disabled = false;
    }

    if (props.productCatalog) {
      _disabled = !props.productId;
    }

    setDisabled(_disabled);
  }, [props.disabled, props.productCatalog, props.productId]);

  const getFormData = (file) => {
    const formData = new FormData();

    formData.append("file", file);
    props.width && formData.append("width", props.width);
    props.height && formData.append("height", props.height);
    props.opacity && formData.append("opacity", props.opacity);

    return formData
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    disabled,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length) {
        setError(fileRejections[0].errors[0].message);

        return;
      }

      if (acceptedFiles.length) {
        setIsUploading(true);

        if (props.onFileUploadStarted) {
          props.onFileUploadStarted();
        }

        const formData = getFormData(acceptedFiles[0]);

        axios
          .post("/upload", formData, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            setIsUploading(false);
            setFileUrl(response.data);

            props.onFileChange(response.data);

            setError(null);
          })
          .catch((e) => {
            console.error(e);

            setIsUploading(false);

            props.onFileChange(null);

            setError(null);
            setFileUrl(null);
          });
      }
    },
    accept: props.accept
      ? props.accept
      : {
          "image/heic": [],
          "image/webp": [],
          "image/svg+xml": [],
          "image/png": [],
          "image/jpeg": [],
        },
    maxSize: 10485760,
  });

  const handleExternalImageUpload = async (url) => { //
    setModalIsOpen(false);

    try {
      setIsUploading(true);

      const response = await fetch(url);
      const blob = await response.blob();

      const formData = getFormData(blob);

      const uploadResponse = await axios.post("/upload", formData, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      });

      setFileUrl(uploadResponse.data);

      props.onFileChange(uploadResponse.data);

      setIsUploading(false);
      setError(null);
    } catch (error) {
      console.error(error);

      setIsUploading(false);
      setError("Error uploading image. Please try again.");
    }
  };

  const handleProductCatalogClick = (url) => {
    setFileUrl(url);
    props.onFileChange(url);
    setError(null);
    setIsProductCatalogModal(false);
  };

  const showDialog = (e) => {
    e.preventDefault();

    open();
  };

  const removeImage = (e) => {
    e.preventDefault();

    setFileUrl(null);

    props.onFileChange("");
  };

  const showStockModal = (e) => {
    e.preventDefault();

    setModalIsOpen(true);
    searchImages("");
  };

  const searchImages = async (searchTerm) => {
    setLoading(true);

    const images = await getImages(searchTerm);

    setStockImages(images);
    setLoading(false);
  };

  const closeModal = (e) => {
    e.preventDefault();

    setModalIsOpen(false);
    setStockSearch("");
  };

  const runStockSearch = (e) => {
    const searchTerm = e.target.value;

    debounceFn(searchTerm);
    setStockSearch(searchTerm);
  };

  const debounceFn = useCallback(_.debounce(searchImages, 1000), []);

  return (
    <div className="form-control">
      {props.label && (
        <label
          className={`${
            props.labelClassName ?? "email-templates-paragraph-1-medium"
          } form-control__label${
            props.labelMargin ? ` mb-${props.labelMargin}` : ""
          }`}
        >
          {props.label}
        </label>
      )}
      <div
        className={`file-uploader${
          props.validationError ? " file-uploader_invalid" : ""
        } text-center ${props.className}${
          fileUrl ? " file-uploader_uploaded" : ""
        }`}
        {...getRootProps()}
      >
        {fileUrl ? (
          <>
            <img
              src={fileUrl}
              alt=""
              className="file-uploader__preview responsive-img"
            />
            <div className="file-uploader__buttons">
              <UiButton
                design="secondary"
                size="medium"
                text="Change"
                onClick={showDialog}
              />
              <UiButton
                design="secondary"
                size="medium"
                text="Delete"
                onClick={removeImage}
              />
            </div>
          </>
        ) : (
          <>
            <UiCaptionRegular
              text="Drag and drop images here or"
              className="color-secondary-colour-secondary-7"
            />
            <UiButton
              size="small"
              design="secondary"
              text="Browse image"
              className="mt-24"
              disabled={disabled}
              onClick={showDialog}
            />
            {props.stock && (
              <>
                <UiCaptionRegular
                  text="or"
                  className="color-secondary-colour-secondary-4 mt-10"
                />
                <UiButton
                  text="Select image from stock"
                  size="small"
                  className="mt-12"
                  onClick={showStockModal}
                  disabled={disabled}
                />
                <Modal
                  isOpen={modalIsOpen}
                  onRequestClose={closeModal}
                  className="an-modal an-modal__maxh-unset"
                  overlayClassName="an-overlay"
                >
                  <div className="an-modal__head">
                    <div className="an-modal__title">
                      <UiParagpraphMedium text="Select your image" />
                    </div>
                    <button onClick={closeModal} className="an-modal__close">
                      <Cross size="24" color="#d9d9d9" />
                    </button>
                  </div>
                  <div className="an-modal__body an-modal__body_mh_0">
                    <div className="mt-24">
                      <FormControl
                        type="text"
                        name="searchImage"
                        id="search_image"
                        placeholder="Search"
                        value={stockSearch}
                        fieldType="search"
                        onChange={runStockSearch}
                      />
                      <div className="mt-24 file-uploader__stock">
                        <Masonry columnsCount={2} gutter="3px">
                          {stockImages.map((image, key) => (
                            <StockImage
                              image={image}
                              key={key}
                              onClick={handleExternalImageUpload}
                            />
                          ))}
                        </Masonry>
                        {loading && (
                          <Preloader absolute={true} overflow={true} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-32 flex-right">
                    <UiButton size="medium" text="Close" onClick={closeModal} />
                  </div>
                </Modal>
              </>
            )}

            {props.productCatalog && (
              <>
                <UiCaptionRegular
                  text="or"
                  className="color-secondary-colour-secondary-4 mt-10"
                />
                <UiButton
                  text="Select from catalog"
                  size="small"
                  className="mt-12"
                  disabled={disabled}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsProductCatalogModal(true);
                  }}
                />

                <ProductImageSelector
                  productId={props.productId}
                  open={isOpenProductCatalogModal}
                  onClose={() => setIsProductCatalogModal(false)}
                  onSave={handleProductCatalogClick}
                />
              </>
            )}
          </>
        )}
        <input {...getInputProps()} />
        {isUploading && <Preloader absolute={true} overflow={true} />}
      </div>
      {props.helpText ? (
        <UiSmallRegular
          text={props.helpText}
          className="mt-16 color-secondary-colour-secondary-4"
        />
      ) : (
        ""
      )}
      {props.validationError ? (
        <UiCaptionRegular
          className="mt-4 color-dust-red-red-5"
          text={props.validationError.message}
        />
      ) : (
        <>{error ? <Error message={error} /> : ""}</>
      )}
    </div>
  );
};

export default FileUploader;
