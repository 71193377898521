import TagItem from "./TagItem";
import "./TagList.scss";

const TagList = props => {
  return(
    <div className="tag-list mt-16">
      {props.list.map((tag, key) => (
        <TagItem
          tag={tag}
          key={key}
          onClick={props.onClick}
        />
      ))}
    </div>
  );
}

export default TagList;