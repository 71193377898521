import axios from "axios";

export const generateString = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/gpt/string/?label=${data.label}&type=${data.type}&context=${data.context}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const generateCampaignData = (campaignId, index) => {
  return new Promise((resolve, reject) => {
    let url = `/gpt/campaign/${campaignId}/email`;

    if (index) {
      url += `?index=${index}`;
    }

    axios.get(url, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const generateCampaignConsentData = campaignId => {
  return new Promise((resolve, reject) => {
    let url = `/gpt/campaign/${campaignId}/consent`;

    axios.get(url, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const generateCustomerSurvey = (type, campaignSlug) => {
  return new Promise((resolve, reject) => {
    const mode = campaignSlug === 'product-review' ? 'product review' : '';
    let url = `/gpt/customer-survey/${type}${mode ? '?mode=' + mode : ''}`;

    axios.get(url, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const getCustomerSurveyQuestions = id => {
  return new Promise((resolve, reject) => {
    axios.get(`/gpt/customer-survey/questions/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getProductReviewQuestions = () => {
  return new Promise((resolve, reject) => {
    axios.get('gpt/product-review/questions', {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  })
}

export const getCampaignCustomizer = (id) => {
  return new Promise((resolve, reject) => {
    let url = `/gpt/campaign/${id}`;

    axios.get(url, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("token")}`,
      },
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      reject(error);
    });
  });
}

export const generateWidgetCustomization = (widgetId, layoutIndex, index) => {
  let url = `/gpt/widget/${widgetId}/layout/${layoutIndex}`
  if (index) url += `?ind=${index}`
  
  return new Promise((resolve, reject) => {
    axios.get(url, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const generateCouponParagraphContent = settings => {
  return new Promise((resolve, reject) => {
    axios.get(`/gpt/coupon?settings=${encodeURIComponent(JSON.stringify(settings))}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getPromoCampaignCustomizer = (promoId, holidayId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/gpt/campaign/${promoId}?holidayId=${holidayId}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  })
}