import { Table, Header, HeaderRow, Body, Row, Cell, HeaderCell } from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import AnTable from "../ui-elements/common/AnTable";
import DomainItem from "./DomainItem";

const DomainStatus = props => {
  const nodes = props.domains;

  const theme = useTheme([getTheme(), {
    Table: `
      --data-table-library_grid-template-columns: repeat(2, minmax(0, 1fr)) 158px 42px
    `
  }]);

  return (
    <div className="card card_shadow card_24">
      <AnTable
        body={
          <Table
            data={{ nodes }}
            theme={theme}
            layout={{
              custom: true
            }}
          >
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell>Domain</HeaderCell>
                    <HeaderCell>Verification Status</HeaderCell>
                    <HeaderCell />
                    <HeaderCell />
                  </HeaderRow>
                </Header>
                <Body>
                  {tableList.map(item => (
                    <DomainItem
                      key={item._id}
                      item={item}
                      refresh={props.refresh}
                    />
                  ))}
                </Body>
              </>
            )}
          </Table>
        }
        className="an-table_mh_72"
      />
    </div>
  )
}

export default DomainStatus;