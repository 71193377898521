import { useState, useEffect } from "react";
import UiButton from "../ui-elements/buttons/UiButton";
import { getEmailHeaderPreview } from "../api/Customizer";

const EmailHeaderLayout = () => {
  const [headerPreview, setHeaderPreview] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setHeaderPreview(await getEmailHeaderPreview());
  }

  return (
    <div className="card card_24 card_shadow an-layout an-layout_gap_32">
      <div className="email-preview" dangerouslySetInnerHTML={{ __html: headerPreview }} />
      <div className="text-center">
        <UiButton
          href={`/brand-assets/email-header`}
          elem={`a`}
          size="medium"
          design="secondary"
          text={`Customise my header`}
        />
      </div>
    </div>
  );
};

export default EmailHeaderLayout;
