import React, { useState, useCallback, useEffect, useRef } from "react";
import { Table, useCustom } from "@table-library/react-table-library/table";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import SectionHead from "../common/SectionHead";
import UiButton from "../buttons/UiButton";
import AnTable from "../../ui-elements/common/AnTable";
import { getCampaignActivityReport, getEntries, getParticipants, getConsentList, getCustomerSurveyEntries } from "../../api/Campaign";
import PageSize from "../common/PageSize";
import Pagination from "../common/Pagination";
import { sendTestEmail } from "../../api/Campaign";
import { Notify } from "../notify/notify";
import { Send } from "akar-icons";
import "./ActivityTable.scss";
import Filter from '../filter/Filter';
import Preloader from '../../preloader/Preloader'

import { getFilterItems, getTableStyle } from './tools';

import ToolsTable from './models/ToolsTable';
import GNSTable from "./models/GNSTable";
import ETPTable from "./models/ETPTable";
import SubscribersTable from "./models/SubscribersTable";
import RaffleParticipantsTable from "./models/RaffleParticipantsTable";
import DefaultTable from "./models/DefaultTable";
import OpenQuestionsTable from "./models/OpenQuestionsTable";

const ActivityTable = ({ 
  slug, 
  type, 
  shopCampaignId, 
  testEmail, 
  single,
  title,
  subTitle,
  showImport,
  data: exampleData
}) => {
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState({});
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);

  const [data, setData] = useState({
    nodes: [],
    totalPages: 0,
    total: 0
  });

  const timeFormat = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  }

  const fetchData = useCallback(async (params) => {
    try {
      setLoading(true);

      if (type === 'tools') {
        const consentList = await getConsentList(params);

        setData({
          nodes: consentList.data.map(item => ({
            ...item,
            time: new Intl.DateTimeFormat('en-US', timeFormat).format(new Date(item.date))
          })),
          totalPages: consentList.totalPages,
          total: consentList?.total
        });
        return
      }

      if (type === 'open-questions') {
        const entries = await getCustomerSurveyEntries({ shopCampaignId, params });

        setData({
          nodes: entries.data.map(item => ({
            ...item,
            time: new Intl.DateTimeFormat('en-US', timeFormat).format(new Date(item.createdAt))
          })),
          totalPages: entries.totalPages,
          total: entries?.total
        });

        return;
      }
      
      if (type === 'campaign-activity') {
        const result = await getCampaignActivityReport({
          params,
          slug,
          shopCampaignId
        });
  
        if (slug === 'generate-new-subscribers') {
          setData({
            nodes: result.data.map(item => ({
              ...item,
              time: new Intl.DateTimeFormat('en-US', timeFormat).format(new Date(item.date))
            })),
            totalPages: result.totalPages,
            total: result?.total
          });
        } else if (slug === 'email-this-product') {
          setData({
            nodes: result.data.map(item => ({
              ...item,
              time: new Intl.DateTimeFormat('en-US', timeFormat).format(new Date(item.createdAt))
            })),
            totalPages: result.totalPages,
            total: result?.total
          });
        } else {
          setData({
            nodes: result.data.map(item => ({
              ...item,
              time: new Intl.DateTimeFormat('en-US', timeFormat).format(new Date(item.date))
            })),
            totalPages: result.totalPages,
            total: result?.total
          });
        }
      } else if (type === 'subscribers') {
        const result = await getEntries({ slug, params });
  
        setData({
          nodes: result.data.map(item => ({
            ...item,
            time: new Intl.DateTimeFormat('en-US', timeFormat).format(new Date(item.createdAt))
          })),
          totalPages: result.totalPages,
          total: result?.total
        });
      } else if (type === 'participants') {
        if (slug === 'raffle-giveaway') {
          const response = await getParticipants({ slug, params })
          setData({
            nodes: response.data.map(item => ({
              ...item,
              time: new Intl.DateTimeFormat('en-US', timeFormat).format(new Date(item.createdAt))
            })),
            totalPages: response.totalPages,
            total: response?.total
          });
        }
      }
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }

  }, []);

  useEffect(() => {
    fetchData({
      search,
      order,
      page: 0,
      status
    });
  }, [fetchData]);

  const handleSearch = e => {
    setSearch(e.target.value);
  }

  const timeout = useRef();

  const onSearchChange = (action, state) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => fetchData({
      search: state.search,
      page: pagination.state.page,
      size: pagination.state.size,
      order: state.order,
      status
    }), 500);
  }

  const onPaginationChange = (action, state) => fetchData({
    search,
    page: state.page,
    size: state.size,
    order,
    status
  });

  useCustom('', data, {
    state: {
      search,
      order
    },
    onChange: onSearchChange
  });

  const pagination = usePagination(data, {
    state: {
      page: 0,
    },
    onChange: onPaginationChange,
  }, {
    isServer: true,
  });

  const theme = useTheme([getTheme(), {
    Table: getTableStyle({ type, slug, single })
  }]);

  const changePageSize = value => pagination.fns.onSetSize(value[0].value);

  const changeOrder = value => {
    let _order = order;

    if (order?.[value]) {
      _order[value] = order[value] === 1 ? -1 : 1;
    } else {
      _order = {};
      _order[value] = 1;
    }

    setOrder(_order);

    pagination.fns.onSetPage(0);
  }

  const _sendTestEmail = async (e) => {
    e.preventDefault();

    setSending(true);

    await sendTestEmail([
      {
        _id: shopCampaignId
      }
    ]);

    setSending(false);

    Notify.success({
      title: 'Test email has been sent',
      message: 'Please check your inbox'
    });
  }

  const applyFilter = filters => {
    setStatus(filters);

    fetchData({
      search,
      order,
      page: 0,
      status: filters
    })

    pagination.fns.onSetPage(0);
  }

  const resetFilter = () => {
    setStatus(null);

    fetchData({
      search,
      order,
      page: 0
    });

    pagination.fns.onSetPage(0);
  }
  
  const renderTable = tableList => {
    if (tableList.length === 0) {
      return <div style={{gridColumnStart: 1, gridColumnEnd: 'span 2'}}>No available data</div>
    }

    if (type === 'tools') {
      return <ToolsTable tableList={tableList} changeOrder={changeOrder} />
    }

    if (type === 'open-questions') {
      return (
        <OpenQuestionsTable
          order={order}
          tableList={tableList}
          changeOrder={changeOrder}
        />
      );
    }

    if (type === 'participants') {
      if (slug === 'raffle-giveaway') {
        return <RaffleParticipantsTable order={order} tableList={tableList} changeOrder={changeOrder} />
      }
    }
    
    if (type === 'campaign-activity') {
      if (slug === 'generate-new-subscribers') {
        return <GNSTable single={single} tableList={tableList} changeOrder={changeOrder} order={order} />
      } 

      if (slug === 'email-this-product') {
        return <ETPTable order={order} tableList={tableList} changeOrder={changeOrder} />
      } 
      
      return <DefaultTable order={order} tableList={tableList} changeOrder={changeOrder} />
    } else if (type === 'subscribers') {
      return <SubscribersTable order={order} tableList={tableList} changeOrder={changeOrder} />
    }
  }

  if (loading && !data?.nodes?.length) return <Preloader absolute={true} overflow={true} />

  return (
    <div className="card card_br_24 card_24 card_shadow card_align_center card_flex">
      {loading && <Preloader absolute={true} overflow={true} />}
      <SectionHead
        align="center"
        title={
          <>
            <div className="email-templates-subtitle-2-medium">{title ?? 'Activity report'}</div>
            <div className="mt-4 email-templates-caption-regular color-secondary-colour-secondary-4">
              {subTitle ?? 'Latest activity'}
            </div>
          </>
        }
        filter={
          <div className="d-flex d-flex_gap_12">
            {/* TODO: commented because it doesn't work well with CSFLE encrypted fields */}
            {/* <FormControl
              type="text"
              name="search"
              id="search"
              placeholder="Search"
              fieldType="search"
              className="form-control__input_size_xsmall"
              value={search}
              onChange={handleSearch}
            /> */}
            {/* TODO: no filters defined */}
            {[
              'win-back', 
              'welcome-new-customers',
              'generate-new-subscribers', 
              'back-in-stock',
              'product-upsell',
              'subscribers-management',
              'product-review'
            ].includes(slug) && type != 'subscribers' &&
              <Filter 
                subHeader={'Status'}
                filterItems={getFilterItems(type, slug)}
                apply={applyFilter}
                reset={resetFilter}
              />
            }
            {testEmail && (
              <div className="activity-send-email">
                <UiButton
                  size="small"
                  design="secondary"
                  className="ui-button_icon"
                  disabled={sending}
                  onClick={_sendTestEmail}
                  text={
                    <>
                      <span className="ui-button__text">Send test email</span>
                      <span className="ui-button__icon p-0">
                        <Send size="24" />
                      </span>
                    </>
                  }
                />
              </div>
            )}
            {showImport && (
              <UiButton
                size="small"
                className="ui-button_icon"
                design="secondary"
                onClick={() => console.log('import')}
                text={'Import list'}
              />
            )}
          </div>
        }
      />
      <div className="mt-24 activity-table">
        <AnTable
          body={
            <>
              <Table
                data={data}
                theme={theme}
                pagination={pagination}
                layout={{
                  custom: true
                }}
              >
                {tableList => renderTable(tableList)}
              </Table>
              <div className="an-table__bottom pl-0 pr-0">
                <PageSize
                  onChange={changePageSize}
                  total={data?.total ? data.total: 0}
                />
                <Pagination
                  pagination={pagination}
                  pagesCount={data?.totalPages ? data.totalPages : 0}
                />
              </div>
            </>
          }
        />
      </div>
    </div>
  );
}

export default ActivityTable;