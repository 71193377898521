import _ from "lodash";

export const isObjectEmpty = (obj) => {
  for (let key in obj) {
    if (_.isObject(obj[key])) {
      if (!isObjectEmpty(obj[key])) {
        return false;
      }
    } else {
      return false;
    }
  }
  return true;
};
