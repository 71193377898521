import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import "./index.css";
import App from "./App";

axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}`;

if (!localStorage.getItem('uuid')) {
  localStorage.setItem('uuid', uuidv4());
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);