import validator from "validator";

export const validateField = (value, type, field) => {
	switch (type) {
		case 'url':
			return validator.isURL(value);
		case 'email': 
			return validator.isEmail(value);
		case 'tel':
			return validator.isMobilePhone(value);
		case 'coupon': {
			if (field.required && !value.includeCoupon) {
        // invalid if includeCoupon no and coupon is required field
        return false;
      }
      if (value.includeCoupon && !value.validity) {
        // If yes checked in include coupon step,
        // but if coupon set up was not completed coupon step, It is invalid case.
        return false;
      }

      return true;
		}
		case 'upsellType': {
			const { type, products } = value
			if (type === "manualSelection" && (!products || !products.length)) {
        return false;
      }
		}
		
		case 'number': {
			if (field?.max && value > field.max) {
				return false;
			}
		}
		case 'datesAndTime': {
			const datesAndTimeValues = Object.values(value)
			if (datesAndTimeValues.includes(undefined)) {
				return false
			}
		}
		default:
			return value
	}
}

export const emailBodyWidget = {
  campaignIds: [],
  campaignNames: [],
  createdAt: null,
  layout: 1,
  name: "Email body",
  previews: [],
  shopId: null,
  status: "published",
  widgetId: 'emailBody',
  widgetName: "emailBody",
  widgetSlug: "email-body",
  __v: 0,
  _id: "emailBody",
}

export const getSortedWidgets = (widgetIds, allWidgets) => {
  if (!widgetIds?.length) return []
  const res = []
  const heroWidget = allWidgets.find(widget => widget.widgetSlug === 'hero')
  let filteredWidgetsIds = widgetIds;
  if (heroWidget && widgetIds.includes(heroWidget._id)) {
      res.push(heroWidget._id)
      filteredWidgetsIds = filteredWidgetsIds.filter(id => id != heroWidget._id)
  }
  res.push('emailBody')
  return res.concat(filteredWidgetsIds)
}

export const prizeFields = [
  {
    type: "radio",
    name: "raffleType",
    required: true,
    options: [
      {
        value: "catalog",
        label: "Prize from product catalog",
      },
      {
        value: "custom",
        label: "Custom prize",
      },
    ],
  },
  {
    type: 'hr'
  },
  {
    type: "product",
    label: "Choose from catalog",
    name: "product",
    required: true,
    placeholder: "Search your catalog",
    conditional: "raffleType",
    visible: true,
    conditionalValue: "catalog",
  },
  {
    label: "Product image",
    labelClassName: 'ui-caption-medium',
    labelMargin: 4,
    name: "image",
    required: true,
    type: "fileUploader",
    productCatalog: true,
    w: 584,
    h: 472,
    conditional: "raffleType",
    disabledBy: "raffleType",
    visible: true,
    conditionalValue: "catalog",
  },
  //
  {
    type: "radio",
    label: "What would you like to give as a prize?",
    name: "type",
    required: true,
    options: [
      {
        value: "digital",
        label: "Digital prize",
      },
      {
        value: "physical",
        label: "Physical prize",
      },
    ],
    conditional: "raffleType",
    conditionalValue: "custom",
  },
  {
    type: "text",
    label: "What is the prize name?",
    name: "name",
    required: true,
    placeholder: "Add prize name",
    conditional: "raffleType",
    conditionalValue: "custom",
    disabledBy: "type"
  },
  {
    type: "text",
    label: "Add coupon or voucher number",
    name: "voucher",
    required: true,
    placeholder: "Add number here",
    conditional: "type",
    conditionalValue: "digital",
    disabledBy: "type"
  },
  {
    type: "number",
    label: "What is the prize value?",
    name: "price",
    required: true,
    placeholder: "Add prize value",
    conditional: "raffleType",
    conditionalValue: "custom",
    disabledBy: "type"
  },
  {
    label: "Upload prize image",
    labelClassName: 'ui-caption-medium',
    labelMargin: 4,
    name: "image",
    required: true,
    type: "fileUploader",
    w: 584,
    h: 472,
    conditional: "raffleType",
    disabledBy: "type",
    conditionalValue: "custom",
  },
];