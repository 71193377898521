import UiBodyBold from "../ui-elements/text/UiBodyBold";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import UiButton from "../ui-elements/buttons/UiButton";
import CampaignIcon from "../ui-elements/common/CampaignIcon";
import "./Card.scss";

const Card = props => {
  return (
    <div className="campaign-card card card_16 card_shadow">
      <div>
        <CampaignIcon
          emoji={props.campaign.emoji}
          size="big"
        />
        <UiBodyBold
          text={props.campaign.name}
          margin="8"
        />
        <div className="pb-22">
          <UiCaptionRegular
            text={props.campaign.description}
            margin="8"
          />
        </div>
      </div>
      {props.disabled ? (
        <UiButton
          design="secondary"
          disabled={true}
          size="small"
          text="Setup campaign"
          className="ui-button_full-width"
        />
      ) : (
        <UiButton
          design="secondary"
          elem="a"
          href={`/campaigns/${props.campaign.slug}`}
          size="small"
          text="Setup campaign"
          className="ui-button_full-width"
        />
      )}
    </div>
  )
}

export default Card;