import React, { useContext } from "react";
import Banners from "../../campaigns/Banners";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import Counter from "../../campaigns/Counter";
import Preloader from "../../preloader/Preloader";
import List from "../../campaigns/List";
import YourCampaigns from "../../campaigns/YourCampaigns";
import { CampaignContext } from "../../context/CampaignContext";
import MainContent from "../../dashboard/MainContent";
import { AuthContext } from "../../context/AuthContext";
import WithValidSubScription from "../../with-valid-subscription/WithValidSubScription";

const CampaignsPage = () => {
  const {
    availableCampaigns,
    shopCampaigns,
    availableCampaignsLoading,
    shopCampaignsLoading,
  } = useContext(CampaignContext);

  const { user } = useContext(AuthContext);
  const performanceDisabled = (!user.subscriptionStatus && user.failedAttempts > 1);
  const available = availableCampaigns.length - shopCampaigns.length;
  const shopCampaignIds = shopCampaigns.map(sc => sc.campaignId);
  const campaignsToSetup = availableCampaigns.filter(ac => !shopCampaignIds.includes(ac._id));

  if (availableCampaignsLoading || shopCampaignsLoading) {
    return <Preloader />;
  }

  return (
    <MainContent>
      <div className="an-layout">
        <Banners />
        <YourCampaigns performanceDisabled={performanceDisabled} />
        {campaignsToSetup && campaignsToSetup.length ? (
          <>
            <div className="pt-24">
              <SectionHead
                title={
                  <>
                    <UiHeading
                      text="Customize your campaigns!"
                      desktop="email-templates-subtitle-2-bold"
                    />
                    <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                      Anomate automates the content, and with minor tweaks like
                      checking email copy, including coupons, <br />
                      filters, and adding widgets, you'll unleash their full
                      potential.
                    </div>
                  </>
                }
                filter={
                  <Counter
                    available={available}
                    total={availableCampaigns.length}
                  />
                }
              />
            </div>
            <List
              campaigns={campaignsToSetup}
              performanceDisabled={performanceDisabled}
            />
          </>
        ) : ('')}
      </div>
    </MainContent>
  );
};

export default WithValidSubScription(CampaignsPage);
