import { useEffect, useState } from "react";
import axios from "axios";
import Preloader from "../preloader/Preloader";
import UiButton from "../ui-elements/buttons/UiButton";
import "./SyncInProgress.scss";

const SyncInProgress = () => {
  const [socialMedia, setSocialMedia] = useState([]);
  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    try {
      axios.get('/options/social_media', {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('token')}`
        }
      }).then(response => {
        setSocialMedia(response.data);
        setPreloader(false);
      });
    } catch (error) {
      console.error(error);

      setPreloader(false);
    }
  }, []);

  if (preloader) {
    return <Preloader />
  }

  return (
    <div className="sync-in-progress">
      <div className="sync-in-progress__preloader">
        <Preloader />
      </div>
      <div className="mt-32 email-templates-subtitle-2-bold">Sync in Progress</div>
      <div className="email-templates-paragraph-1-regular color-secondary-colour-secondary-7">Syncing your store may take some time. You can close this window, and we'll email you once it's done.</div>
      <div className="mt-16 email-templates-paragraph-1-regular color-secondary-colour-secondary-7">While you wait, check out our knowledge base for helpful insights. And dont forget to share and let others know you're now part of the Anomate community!</div>
      <div className="pt-40 pb-40">
        <div className="sync-in-progress__buttons">
          <UiButton
            className="sync-in-progress__button"
            size="small"
            text="Check our blog"
            elem="a"
            target="_blank"
            href="https://anomate.co/blog"
          />
          <UiButton
            className="sync-in-progress__button"
            design="secondary"
            size="small"
            text="Check our use cases"
            elem="a"
            target="_blank"
            href="https://anomate.co/use-cases/"
          />
        </div>
        <div className="sync-in-progress__buttons mt-48">
          {socialMedia.map((item, key) => (
            <a
              href={item.url}
              target="_blank"
              rel="noreferrer"
              className="sync-in-progress__social"
              key={key}
            >
              <img
                src={item.onboardingIcon}
                alt=""
                className="sync-in-progress__social-icon"
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SyncInProgress;