import { useId, memo } from "react";

import DatePicker from "./datepicker/DatePicker";
import Select from "./form-control-components/Select";
import Textarea from "./form-control-components/Textarea";
import Radio from "./form-control-components/Radio";
import Address from "./form-control-components/Address";
import Default from "./form-control-components/Default";

import "./FormControl.scss";

const FormControl = (props) => {
  const _id = useId();
  const mutatedProps = {...props, id: props.id || _id}
  if (props.type == 'select') {
    return <Select {...mutatedProps} />
  }

  if (props.type == 'textarea') {
    return <Textarea {...mutatedProps} />
  }

  if (props.type === "radio") {
    return <Radio {...mutatedProps} />
  } 
  if (props.type === "date") {
    const datePickerProps = {...props, _id: _id}
    return <DatePicker {...datePickerProps} />
  } 
  if (props.type === "address") {
    return <Address {...mutatedProps} />
  }

  return <Default {...mutatedProps} />

};

export default memo(FormControl);
