import {
  CloudUpload,
  MoreVerticalFill,
  Pencil,
  Pause,
  TrashCan,
  Cross,
  ArrowBack,
  DragVerticalFill,
} from "akar-icons";
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import OutsideClickHandler from "react-outside-click-handler";
import Modal from "react-modal";
import UiHeading from "../ui-elements/text/UiHeading";
import Chips from "../ui-elements/common/Chips";
import UiExtraSmallRegular from "../ui-elements/text/UiExtraSmallRegular";
import { Notify } from "../ui-elements/notify/notify";
import {
  publishWidget,
  pauseWidget,
  duplicateWidget,
  archiveWidget,
  deleteWidget,
} from "../api/Shop";
import Preloader from "../preloader/Preloader";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import UiButton from "../ui-elements/buttons/UiButton";
import ReachedModal from "./ReachedModal";
import illustration12 from "../static/images/widgets/Illustration (1) 2.svg";
import "./WidgetPreview.scss";
import UiBaseCheckbox from "../ui-elements/forms/UiBaseCheckbox";
import classNames from "classnames";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import WidgetPreviewStatus from "./WidgetPreviewStatus";

Modal.setAppElement("#root");

const WidgetPreview = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.widget._id,
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [activePreviewKey, setActivePreviewKey] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const [reachedModalIsOpen, setReachedModalIsOpen] = useState(false);

  const showActions = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setShowDropdown(true);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActivePreviewKey((prevState) => {
        return prevState === props.widget.variants.length - 1
          ? 0
          : prevState + 1;
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, [props]);

  const handlePublishWidget = async (e) => {
    e.preventDefault();

    try {
      await publishWidget(props.widget._id);

      Notify.success({
        title: "Widget has been published successfully",
      });

      setShowDropdown(false);

      props.onUpdate();
    } catch (error) {
      Notify.error({
        title: error.message,
      });
    }
  };

  const handlePauseWidget = async (e) => {
    e.preventDefault();

    try {
      await pauseWidget(props.widget._id);

      Notify.success({
        title: "Widget has been paused successfully",
      });

      setShowDropdown(false);

      props.onUpdate();
    } catch (error) {
      Notify.error({
        title: error.message,
      });
    }
  };

  const handleDuplicateWidget = async (e) => {
    e.preventDefault();
    if (
      props.availableWidgetCount && Number(props.availableWidgetCount) >= 0 && 
      props.widgetsCount && props.widgetsCount >= Number(props.availableWidgetCount)
    ) {
      setReachedModalIsOpen(true);
    } else {
      try {
        await duplicateWidget(props.widget._id);
  
        Notify.success({
          title: "Widget has been duplicated successfully",
        });
  
        setShowDropdown(false);
  
        props.onUpdate();
      } catch (error) {
        Notify.error({
          title: error.message,
        });
      }
    }
  };

  const handleArchiveWidget = async (e) => {
    e.preventDefault();

    try {
      await archiveWidget(props.widget._id);

      Notify.success({
        title: "Widget has been archived successfully",
      });

      setShowDropdown(false);

      props.onUpdate();
    } catch (error) {
      Notify.error({
        title: error.message,
      });
    }
  };

  const handleDeleteWidget = async (e) => {
    e.preventDefault();

    setDeleting(true);

    try {
      await deleteWidget(props.widget._id);

      Notify.success({
        title: "Widget has been deleted successfully",
      });

      setModalIsOpen(false);

      props.onUpdate();
    } catch (error) {
      Notify.error({
        title: error.message,
      });
    }

    setDeleting(false);
  };

  const handleDeleteModal = (e) => {
    e.preventDefault();

    setModalIsOpen(true);
    setShowDropdown(false);
  };

  const handleDraftWidget = async (e) => {
    e.preventDefault();

    try {
      await pauseWidget(props.widget._id);

      Notify.success({
        title: "Widget has been moved successfully",
      });

      setShowDropdown(false);

      props.onUpdate();
    } catch (error) {
      error.response.status === 403
        ? setReachedModalIsOpen(true)
        : console.error(error);
    }
  };

  const handleClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation()
    props.onClick && props.onClick(props.widget._id);
  }, [props.widget._id, props.onClick]);

  const Component = props.selection || props.draggable ? "div" : Link;
  const to =
    props.selection || props.draggable
      ? undefined
      : `/widgets/${props.widget._id}`;

  return (
    <>
      <Component
        to={to}
        className={classNames(
          "card card_shadow card_border card_16 widget-preview card_overflow_visible",
          { "an-col an-col_33 ": !props.fullWidth },
          { 'widget-preview_draggable' : props.draggable }
        )}
        data-selection={props.selection}
        onClick={handleClick}
        ref={setNodeRef}
        {...attributes}
      >
        <div className="widget-preview__head">
          <div className="widget-preview__head-data">
            <UiHeading
              desktop="other-headings-h6-bold"
              element="h6"
              className="nowrap-overflow"
              text={props.widget.name}
              title={props.widget.name}
            />
            <Chips
              color="blue"
              value={props.widget.widgetName}
              className="mt-4"
            />
            {props.widget.variants.length && !props.draggable ? (
              <UiExtraSmallRegular
                text={`${props.widget.variants.length} variant${
                  props.widget.variants.length > 1 ? "s" : ""
                } created`}
                className="color-secondary-colour-secondary-5 text-uppercase"
              />
            ) : (
              ""
            )}
          </div>
          {!props.selection && !props.draggable && (
            <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
              <div className="position-relative">
                <div className="widget-preview__dots">
                  <MoreVerticalFill size="24" onClick={showActions} />
                </div>
                {showDropdown && (
                  <div className="dropdown dropdown_widget dropdown_w_340 dropdown_position_bottom">
                    {props.widget.status === "archived" ? (
                      <button
                        className="dropdown__item"
                        onClick={handleDraftWidget}
                      >
                        <span className="dropdown__item-icon">
                          <ArrowBack />
                        </span>
                        <span className="ui-caption-regular">
                          Move back to overview
                        </span>
                      </button>
                    ) : (
                      <>
                        <button
                          className="dropdown__item"
                          onClick={() =>
                            navigate(`/widgets/${props.widget._id}`)
                          }
                        >
                          <span className="dropdown__item-icon">
                            <Pencil />
                          </span>
                          <span className="ui-caption-regular">
                            Update widget
                          </span>
                        </button>
                        {props.widget.status === "draft" ? (
                          <button
                            className="dropdown__item"
                            onClick={handlePublishWidget}
                          >
                            <span className="dropdown__item-icon">
                              <CloudUpload />
                            </span>
                            <span className="ui-caption-regular">
                              Publish widget
                            </span>
                          </button>
                        ) : (
                          <button
                            className="dropdown__item"
                            onClick={handlePauseWidget}
                          >
                            <span className="dropdown__item-icon">
                              <Pause />
                            </span>
                            <span className="ui-caption-regular">
                              Pause widget
                            </span>
                          </button>
                        )}
                        <button
                          className="dropdown__item"
                          onClick={handleDuplicateWidget}
                        >
                          <span className="dropdown__item-icon">
                            <Icon icon="ic:baseline-control-point-duplicate" />
                          </span>
                          <span className="ui-caption-regular">
                            Duplicate widget
                          </span>
                        </button>
                        <button
                          className="dropdown__item"
                          onClick={handleArchiveWidget}
                        >
                          <span className="dropdown__item-icon">
                            <Pause />
                          </span>
                          <span className="ui-caption-regular">
                            Archive widget
                          </span>
                        </button>
                      </>
                    )}
                    <button
                      className="dropdown__item"
                      onClick={handleDeleteModal}
                    >
                      <span className="dropdown__item-icon">
                        <TrashCan />
                      </span>
                      <span className="ui-caption-regular">Delete widget</span>
                    </button>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          )}
          {props.selection && (
            <UiBaseCheckbox
              className={`widget-preview_selection-checkbox`}
              checked={props.checked}
              readOnly
            />
          )}
          {props.draggable && (
            <span {...listeners} className="widget-preview__drag">
              <DragVerticalFill size={24} />
            </span>
          )}
        </div>
        {props.widget.previews && (
          <div className={`widget-preview__html mt-8${props.draggable ? ' widget-preview__html_draggable' : ''}`}>
            {props.widget.previews.map((preview, key) => (
              <div
                className={`widget-preview__html-inner${
                  key === activePreviewKey
                    ? " widget-preview__html-inner_active"
                    : ""
                }`}
                key={key}
                dangerouslySetInnerHTML={{ __html: preview }}
              />
            ))}
          </div>
        )}
        <WidgetPreviewStatus widget={props.widget} />
      </Component>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="an-modal"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          <div className="an-modal__title w-100 text-center">
            <img src={illustration12} alt="" className="responsive-img" />
            <UiHeading
              element="h6"
              desktop="other-headings-h6-bold"
              text="Are you sure you want to delete this widget?"
              margin="24"
            />
            <UiCaptionRegular
              className="mt-8 color-secondary-colour-secondary-7"
              text="This message will self-destruct in 5 seconds and this action cannot be undone."
            />
          </div>
          <button
            onClick={() => setModalIsOpen(false)}
            className="an-modal__close"
          >
            <Cross size="24" color="#d9d9d9" />
          </button>
        </div>
        <div className="an-modal__body an-modal__body_mh_0 text-left">
          <div className="mt-40 text-right">
            <div className="button-group">
              <UiButton
                size="medium"
                design="secondary"
                text="Cancel"
                onClick={() => setModalIsOpen(false)}
              />
              <UiButton
                size="medium"
                text="Yes, delete"
                onClick={handleDeleteWidget}
              />
            </div>
          </div>
        </div>
        {deleting ? <Preloader absolute={true} overflow={true} /> : ""}
      </Modal>
      <ReachedModal
        reachedModalIsOpen={reachedModalIsOpen}
        closeReachedModal={() => setReachedModalIsOpen(false)}
        availableWidgetCount={props.availableWidgetCount}
      />
    </>
  );
};

export default WidgetPreview;
