import React from 'react'

import IntroCard from '../IntroCard';
import UiButton from '../../../ui-elements/buttons/UiButton';

const Categories = ({categories, handleSelect}) => {
	return (
		<div className="cards-wrapper">
      <div className="cards-list">
				{categories.map((category, index) => 
					<IntroCard
						key={index}
						item={category}
						buttonText={'Select category'}
						handleClick={() => handleSelect(category)}
					/>
				)}
      </div>
      <div className="mt-40 button-group button-group_center">
        <UiButton
          design="secondary"
          size="medium"
          text="Go back"
          elem="a"
          href={`/campaigns/promo-campaign`}
          className="ui-button_mw_180"
        />
      </div>
    </div>
	);
}

export default Categories;