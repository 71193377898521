import "./Stepper.scss";

const Stepper = (props) => {
  return (
    <ol className={`stepper text-center ${props.className ? props.className : ''}`}>
      {props.sections.map((step, key) => (
        <li
          className={`stepper__item ui-small-regular color-secondary-colour-secondary-7${
            props.currentSection - 1 === key ||
            (step.type && props.activeStepType === step.type)
              ? " stepper__item_active"
              : ""
          }`}
          key={`stepper-${key}`}
        >
          {step.stepName || step.name}
        </li>
      ))}
    </ol>
  );
};

export default Stepper;
