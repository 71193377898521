import Select from "react-dropdown-select";

const PageSize = props => {
  return (
    <div className="an-table__page-size ui-caption-regular color-secondary-colour-secondary-7">
      <span>Show</span>
      <Select
        dropdownPosition="top"
        options={[
          {
            label: 10,
            value: 10
          },
          {
            label: 20,
            value: 20
          },
          {
            label: 50,
            value: 50
          },
          {
            label: 100,
            value: 100
          }
        ]}
        values={[
          {
            label: 10,
            value: 10
          }
        ]}
        onChange={props.onChange}
        className="form-select"
      />
      <span>{props?.total ? `of ${props.total.toLocaleString()} ` : ''}entries</span>
    </div>
  )
}

export default PageSize;