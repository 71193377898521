import { Header, HeaderRow, HeaderCell, Body, Row, Cell } from "@table-library/react-table-library/table";

const ETPTable = ({
	tableList,
	changeOrder,
	order,
}) => {
	return (
		<>
			<Header>
				<HeaderRow>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.firstName ? ' activity-table__th_active' : ''}`}>Name</HeaderCell>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.email ? ' activity-table__th_active' : ''}`}>Email</HeaderCell>
					<HeaderCell onClick={() => changeOrder('createdAt')} className={`activity-table__th table-align-right${order?.date ? ' activity-table__th_active' : ''}`}>Time</HeaderCell>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.productName ? ' activity-table__th_active' : ''}`}>Product Name</HeaderCell>
				</HeaderRow>
			</Header>
			<Body>
				{tableList.map(item => (
					<Row
						item={item}
						key={item._id}
					>
						<Cell>{item.firstName} {item.lastName}</Cell>
						<Cell>{item.email}</Cell>
						<Cell className="table-align-right">{item.time}</Cell>
						<Cell>
							<a href={item.productPermalink} className="underline" target="_blank">{item.productName}</a>
						</Cell>
					</Row>
				))}
			</Body>
		</>
	)
}

export default ETPTable;