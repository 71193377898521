import { useContext } from "react";
import { CampaignContext } from '../context/CampaignContext';

const useCampaign = () => {
	const context = useContext(CampaignContext);
	if (!context) {
		throw new Error("useCampaign must be used within a CampaignContext.Provider");
	}
	return context;
};

export default useCampaign;
