import UiHeading from "../ui-elements/text/UiHeading";
import UiSmallRegular from "../ui-elements/text/UiSmallRegular";

const PackageInfo = props => {
  return (
    <div className="card card_16 an-col an-col_25">
      <UiHeading
        text={props.value}
        element="h6"
        desktop="other-headings-h6-bold"
        className="pt-2"
      />
      <UiSmallRegular
        text={props.label}
        className="color-secondary-colour-secondary-7"
      />
    </div>
  )
}

export default PackageInfo;