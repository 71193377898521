import TagItem from "../../../onboarding/TagItem";
import useAuth from "../../../hooks/useAuth";
import "./ChosenOption.scss";

const ChosenOptions = ({ value }) => {
  const { shopData } = useAuth();
  let labels = [];

  if (!value?.includeCoupon) {
    return;
  }

  const formatMoney = number => {
    return number.toLocaleString('en-US', {
      style: 'currency',
      currency: shopData.currency
    });
  }

  if (value?.type) {
    if (value.type === 'percentage') {
      labels.push('Percentage Discount');

      if (value?.amount) {
        labels.push(`${value.amount}% Disc`);
      }
    } else if (value.type === 'fixed') {
      labels.push('Fixed Cart Discount');

      if (value?.amount) {
        labels.push(`${formatMoney(parseInt(value.amount))} Disc`);
      }
    }
  }

  if (value?.minAmount) {
    labels.push(`Min Amount ${formatMoney(parseInt(value.minAmount))}`);
  }

  if (value?.excludeSaleItems) {
    labels.push('Exclude Sale Items');
  }

  if (value?.validity) {
    labels.push(`${value.validity} Days`);
  }

  if (labels.length === 0) {
    return;
  }

  return (
    <div className="tag-list text-uppercase chosen-options">
      {labels.map((label, key) => (
        <TagItem
          tag={label}
          size="xsmall"
          key={key}
          hideClose={true}
        />
      ))}
    </div>
  )
}

export default ChosenOptions;