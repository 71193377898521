import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Slider from "react-slick";
import classNames from "classnames";

import { Cross, CircleCheck } from "akar-icons";
import UiButton from "../../ui-elements/buttons/UiButton";
import Divider from "../../ui-elements/divider/Divider";
import { getShopProductById } from "../../api/Shop";

import styles from "./ProductImageSelector.module.scss";
import Preloader from "../../preloader/Preloader";

const ProductImageSelector = (props) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [selectedImg, setSelectedImg] = useState("");

  useEffect(() => {
    if (!props.productId) return;

    fetchProductById(props.productId);
  }, [props.productId]);

  const fetchProductById = async (id) => {
    try {
      const response = await getShopProductById(id);
      setProduct(response);
      setSelectedImg(response?.images[0]?.src);
    } catch (err) {
      console.log("err", err);
    }
    setLoading(false);
  };

  const selectImg = (src) => {
    setSelectedImg(src);
  };

  const handleOnSave = () => {
    if (!selectedImg) return;

    props.onSave(selectedImg);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    arrows: false,
  };

  console.log(product);

  return (
    <Modal
      isOpen={props.open}
      onRequestClose={props.onClose}
      className="an-modal an-modal__max-w-600 an-modal__rounded-8"
      overlayClassName="an-overlay"
    >
      <div className="an-modal__head">
        <div>
          <h2 className="email-templates-subtitle-3 m-0">
            Select image from your catalog
          </h2>
          <p className="email-templates-paragraph-2-regular font-normal color-secondary-colour-secondary-7 mt-4 mb-24">
            Select one image from your product catalog to display in your
            raffles emails and landing pages.
          </p>
        </div>
        <button onClick={props.onClose} className="an-modal__close">
          <Cross size="24" color="#d9d9d9" />
        </button>
      </div>
      <div className="an-modal__body an-modal__body_mh_0 text-left">
        <Divider />
        <div className="d-flex d-flex_column">
          <Link
            to={product?.permalink ? product.permalink : '#'}
            className="color-golden-purple-purple-5 ui-caption-regular font-normal text-center mt-16"
          >
            {product?.name}
          </Link>
          {!!product?.sku && (
            <span className="email-templates-paragraph-2-regular font-normal text-center color-secondary-colour-secondary-7 mt-4">
              Product code: {product.sku}
            </span>
          )}
          {!!product?.price_html && (
            <span
              className="email-templates-paragraph-2-regular font-normal text-center color-secondary-colour-secondary-7 mt-4"
              dangerouslySetInnerHTML={{
                __html: `Price: ${product.price_html}`
              }}
            />
          )}
          <div className={classNames(styles['selected-main-img'], 'mt-8')}>
            <img
              src={selectedImg}
              alt={"selectedImg"}
            />
          </div>
        </div>

        {loading && !product ? (
          <Preloader />
        ) : (
          <>
            <div className={classNames(styles["images-container"], 'mt-16')}>
              {product.images.length >= 5 ? (
                <div className={styles["images-container__main"]}>
                  <Slider {...settings}>
                    {product?.images?.map((item, index) => (
                      <div className={styles["image-container"]} key={index}>
                        <img
                          src={item.src}
                          alt={item.alt}
                          key={index}
                          height={"48px"}
                          onClick={() => selectImg(item.src)}
                          className={selectedImg === item.src ? styles["selected-image"] : ""}
                        />
                        {selectedImg === item.src && (
                          <CircleCheck size={24} className={styles["selected-icon"]} />
                        )}
                      </div>
                    ))}
                  </Slider>
                </div>
              ) : (
                <div className={styles["images-container__else"]}>
                  {product.images.map((item, index) => (
                    <div className={styles["image-container"]} key={index}>
                      <img
                        src={item.src}
                        alt={item.alt}
                        width={"48px"}
                        height={"48px"}
                        onClick={() => selectImg(item.src)}
                        className={selectedImg === item.src ? styles["selected-image"] : ""}
                      />
                      {selectedImg === item.src && (
                        <CircleCheck size={24} className={styles["selected-icon"]} />
                      )}
                    </div>
                  ))}
                </div>
              )}

              <span className="ui-small-regular font-normal text-center color-secondary-colour-secondary-7">
                Select the image you would like to use
              </span>
            </div>
          </>
        )}

        <Divider />

        <div className="mt-20 text-right">
          <div className="button-group">
            <UiButton
              size="medium"
              design="secondary"
              text="Cancel"
              onClick={props.onClose}
              className={"pl-36 pr-36"}
            />
            <UiButton
              size="medium"
              text={`Save`}
              onClick={handleOnSave}
              className={"pl-48 pr-48"}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductImageSelector;
