import React, { useState } from "react";
import { SketchPicker } from "react-color";
import FormControl from "../forms/FormControl";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";
import styles from "./ColorPicker.module.scss";

const ColorPicker = ({
  label,
  name,
  id,
  placeholder,
  labelClassName,
  onChange,
  value,
  required,
  labelMargin
}) => {
  const [openPicker, setOpenPicker] = useState(false);
  const [_value, setValue] = useState(value || "");
  const handleOnChange = (color) => {
    setValue(color.hex);
    onChange && onChange(color.hex);
  };

  return (
    <div className={classNames(styles["base"], "position-relative")}>
      <span
        style={{ backgroundColor: `${_value}` }}
        className={styles["color-box"]}
        onClick={() => setOpenPicker(true)}
      />
      <FormControl
        label={label}
        type="text"
        id={id}
        name={name}
        placeholder={placeholder}
        labelClassName={labelClassName}
        readOnly
        value={_value || ""}
        onClick={() => setOpenPicker(true)}
        required={required}
        labelMargin={labelMargin}
      />
      {openPicker && (
        <OutsideClickHandler onOutsideClick={() => setOpenPicker(false)}>
          <div className="dropdown">
            <SketchPicker color={_value} onChange={handleOnChange} />
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default ColorPicker;
