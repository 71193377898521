const LoginMobileImage = props => {
  return (
    <div className="login-mobile-image">
      <div className="container">
        <div className="login-mobile-image__inner">
          <img
            src={props.src}
            className="login-mobile-image__icon"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default LoginMobileImage;