import Header from "../header/Header";
import AnCaption from "../ui-elements/text/AnCaption";
import UiHeading from "../ui-elements/text/UiHeading";
import LoginImage from "./LoginImage";
import LoginMobileImage from "./LoginMobileImage";
import LoginForm from "./LoginForm";
import image from "../static/images/login/Image.svg";
import ntTrafficAnalytics from "../static/images/login/nt-traffic-analytics 1.svg";
import "./Login.scss";

const Login = props => {
  return (
    <div>
      <Header />
      <div className="login-wrapper">
        <div className="container">
          <div className="login-wrapper__inner">
            <div className="login-wrapper__content">
              <AnCaption text="Log In" />
              <UiHeading
                element="h1"
                desktop="h2-lg"
                mobile="h3-lg"
                text="Welcome back!"
                margin="4"
              />
              <LoginForm setLoggedIn={props.setLoggedIn} />
            </div>
            <LoginImage src={image} />
          </div>
        </div>
        <LoginMobileImage src={ntTrafficAnalytics} />
      </div>
    </div>
  );
};

export default Login;