export const getStats = (statObj, campaigns) => {
  const obj = {...statObj}
  
	campaigns.map((campaign, index) => {
		if (index == 0 && campaign.history) {
			Object.keys(obj).forEach((key) => {
				obj[key] += campaign?.history[key] ? campaign.history[key] : 0;
			})
		}

		Object.keys(obj).forEach((key) => {
			obj[key] += campaign[key]
		})
	});
  
  return obj
}