import { useState, useEffect } from "react";
import Banner from "../banner/Banner";
import UiHeading from "../ui-elements/text/UiHeading";
import UiBodyRegular from "../ui-elements/text/UiBodyRegular";
import ntReduceChurn1 from "../static/images/dashboard/nt-reduce churn 1.svg";
import UiButton from "../ui-elements/buttons/UiButton";

const CampaignsPaused = () => {
  const [bannerVisible, setBannerVisible] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem('campaignPauseBannerHidden')) {
      setBannerVisible(true);
    }
  }, []);

  const hideNotification = () => {
    setBannerVisible(false);

    localStorage.setItem('campaignPauseBannerHidden', true);
  };
    
  return (
    <>
      {bannerVisible ? (
        <Banner
          color="sunrise-yellow-yellow-1"
          className="pr-210"
          content={
            <>
              <UiHeading
                elem="h4"
                text={`Your campaigns are on pause!`}
                desktop="other-headings-h4-bold"
              />
              <UiBodyRegular text="Your campaigns took a breather because of a hiccup with your payment method. To keep things rolling smoothly, please update your payment details now. We will try again in 3 days." />
              <div className="button-group mt-58">
                <UiButton
                  text="Update payment method"
                  elem="a"
                  href="/billing"
                  size="small"
                />
                <UiButton
                  text="Remind me later"
                  size="small"
                  design="no-border"
                  onClick={hideNotification}
                />
              </div>
            </>
          }
          image={ntReduceChurn1}
        />
      ) : ''}
    </>
  )
}

export default CampaignsPaused;