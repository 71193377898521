import React, { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./QuestionBlock.module.scss";
import FormControl from "../../forms/FormControl";
import GenerateButton from "../customization-forms/GenerateButton";
import UiBaseCheckbox from "../../forms/UiBaseCheckbox";
import classNames from "classnames";
import QuestionBlockOption from "./QuestionBlockOption";
import {
  ChevronUp,
  ChevronDown,
  Pencil,
  TrashCan,
  DragVerticalFill,
} from "akar-icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { generateCustomerSurvey } from "../../../api/Gpt";

const QuestionBlock = (props) => {
  const { campaignSlug } = useParams();
  const [generating, setGenerating] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.id,
    });
  const maxAnswers = 5;
  const answers = props.answers;
  const handleQuestionChange = useCallback(
    (e) => {
      props.onQuestionChange(props.index, e.target.value);
    },
    [props.index]
  );

  const handleAnswerChange = useCallback(
    (e, index) => {
      props.onAnswerChange(props.index, index, e.target.value);
    },
    [props.index]
  );

  const handleOnRemove = useCallback(
    (index) => {
      props.onAnswerRemove(props.index, index);
    },
    [props.index]
  );

  const handleMandatoryChange = useCallback(
    (e) => {
      props.onMandatoryChange(props.index, e.target.checked);
    },
    [props.index]
  );

  const handleOnAdd = useCallback((index) => {
    props.onAnswerAdd(props.index, index);
  }, []);

  const handleMove = (key) => {
    if (key === "up" && !props.upDisabled) {
      props.onMoveUp(props.index);
    }
    if (key === "down" && !props.downDisabled) {
      props.onMoveDown(props.index);
    }
  };

  const style = {
    transform: CSS.Transform.toString(transform),
  };

  const generate = async () => {
    if (generating) return;
    setGenerating(true);
    try {
      console.log(props);
      
      const response = await generateCustomerSurvey(props.type, campaignSlug);
      props.onAfterGenerate(props.index, response);
    } catch (e) {
      console.log(e);
    }
    setGenerating(false);
  };

  return (
    <div
      className={classNames(styles["base"], {
        [`${styles["editing"]}`]: props.isEditing,
        [`${styles["dragging"]}`]: props.dragging,
      })}
      {...attributes}
      // style={style}
      ref={setNodeRef}
    >
      <div className={styles["box"]}>
        <div className={styles["top"]}>
          {props.isEditing ? (
            <>
              <FormControl
                label="What would you like to ask?"
                type="text"
                labelClassName="ui-caption-medium"
                placeholder="Write your question here"
                labelMargin="4"
                value={props.question}
                onChange={handleQuestionChange}
                readOnly={!props.isEditing}
              />
              <div className={styles["top_actions"]}>
                <GenerateButton disabled={generating} onClick={generate} />
                <UiBaseCheckbox
                  checked={props.mandatory}
                  label="Mandatory"
                  disabled={!props.isEditing}
                  extraClass="email-templates-caption-regular"
                  labelSpacing={"8"}
                  labelPosition="left"
                  onChange={handleMandatoryChange}
                />
              </div>
            </>
          ) : <div className={`email-templates-subtitle-3-semibold text-center ${styles['title']}`}>{props.question}</div>}
          
        </div>

        <div
          className={classNames(
            styles["option-container"],
            styles[`${props.type}`]
          )}
        >
          {props.type === "textarea" && (
            <QuestionBlockOption type={"textarea"} />
          )}

          {answers &&
            answers.length > 0 &&
            answers.map((answer, index) => (
              <QuestionBlockOption
                type={props.type}
                key={`answer-${answer._id}`}
                index={index}
                addDisabled={answers.length >= maxAnswers}
                removeDisabled={answers.length <= 1}
                value={answer.value || ""}
                isEditing={props.isEditing}
                onChange={handleAnswerChange}
                onAdd={handleOnAdd}
                onRemove={handleOnRemove}
              />
            ))}
        </div>
      </div>
      <div className={styles["actions"]}>
        <div className={styles["move-up-down"]}>
          <span
            className={classNames(styles["action"], {
              [`${styles["disabled"]}`]: props.upDisabled,
            })}
            onClick={() => handleMove("up")}
          >
            <ChevronUp />
          </span>

          <span
            className={classNames(styles["action"], {
              [`${styles["disabled"]}`]: props.downDisabled,
            })}
            onClick={() => handleMove("down")}
          >
            <ChevronDown />
          </span>
        </div>

        <span
          className={classNames(styles["action"], {
            [`${styles["active"]}`]: props.isEditing && !props.dragging,
          })}
          onClick={() => props.onEdit(props.id, props.type)}
        >
          <Pencil />
        </span>

        <span
          className={classNames(styles["action"], {
            [`${styles["active"]}`]: props.dragging,
          })}
          {...listeners}
        >
          <DragVerticalFill />
        </span>

        <span
          className={styles["action"]}
          onClick={() => props.onRemove(props.id)}
        >
          <TrashCan />
        </span>
      </div>
    </div>
  );
};

export default memo(QuestionBlock);
