import { useMemo, useEffect, useState } from "react";
import axios from "axios";

import CompleteSetup from "../../dashboard/CompleteSetup";
import Performance from "../../performance/Performance";
import CampaignsSetup from "../../dashboard/CampaignsSetup";
import MainContent from "../../dashboard/MainContent";
import CampaignsPaused from "../../dashboard/CampaignsPaused";
import CampaignsAndReportsPaused from "../../dashboard/CampaignsAndReportsPaused";

import { Notify } from "../../ui-elements/notify/notify";
import Preloader from "../../preloader/Preloader";

import useAuth from "../../hooks/useAuth"
import useCampaign from "../../hooks/useCampaign";
import { getTasks, getStatus } from "../../dashboard/tools";

const Content = ({ subscriptionStatus, failedAttempts, needSetup, tasks, setTasks }) => {
  if (!subscriptionStatus) {
    if (failedAttempts == 1) {
      return <CampaignsPaused />
    }
    if (failedAttempts > 1) {
      return <CampaignsAndReportsPaused />
    }
  } else {
    if (needSetup) {
      return <CompleteSetup tasks={tasks} setTasks={setTasks} />
    } else {
      return <CampaignsSetup />
    }
  }
  return null
}

const DashboardPage = () => {
  const [tasks, setTasks] = useState([]);
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false);
  const { shopData, user } = useAuth();
  const { shopCampaigns } = useCampaign();
  const token = localStorage.getItem('token')

  const fetchTaskStatus = async() => {
    try {
      const urls = ['/onboarding/questions', '/domains', '/shop/widgets', '/shop', '/user'];
      const requests = urls.map(url => axios.get(url, {headers: { Authorization: `Bearer: ${token}`}}));
      setShow(true)
      setLoading(true);
      const [questions, domains, widgets, shop, user] = await axios.all(requests);
      const defaultTasks = getTasks();

      const changedTasks = defaultTasks.map(task => {
        const {type} = task
        task.completed = getStatus(type, questions.data, domains.data, widgets.data, shop.data, user.data, shopCampaigns)

        if (type == 'onboarded') task.title = `${user.data?.onboardingData ? Object.keys(user.data?.onboardingData).length : 0} out of ${questions.data.length} questions answered`;

        return task
      })
      setTasks(changedTasks)
    } catch (error) {
      console.error(error)
      Notify.error({title: 'Something went wrong :(. Please, try later'})
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTaskStatus();
  }, [])

  const needSetup = useMemo(() => {
    let need = false
    for (let i = 0; i < tasks.length; i++) {
      if (!tasks[i].completed) {
        need = true
        break;
      }
    }

    return need
  }, [tasks])

  if (!show) return null

  return (
    <MainContent>
      {!loading && 
        <>
        <Content 
          subscriptionStatus={user.subscriptionStatus} 
          failedAttempts={user.failedAttempts}
          needSetup={needSetup}
          setTasks={setTasks}
          tasks={tasks}
        />
        <Performance 
          showPerformance={!needSetup}
          shop={shopData} 
        />
        </>
      }
      {loading && <Preloader absolute={true} overflow={true} />}
    </MainContent>
  );
};

export default DashboardPage;
