import React, { useState, useId, useRef } from "react";
import "./CountryAutocomplete.scss";
import TagItem from "../../../onboarding/TagItem";
import countries from "./countries";
import Suggestions from "./Suggestions";

const CountryAutocomplete = (props) => {
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState("");
  const [selectedCountries, setSelectedCountries] = useState(props.value);
  const _id = useId();
  const inputRef = useRef(null);
  
  const onTextChanged = (e) => {
    const value = e.target.value;
    let suggestions = [];
    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = countries.sort().filter((v) => regex.test(v));
    }
    setSuggestions(suggestions);
    setText(value);
  };

  const suggestionSelected = (value) => {
    if (!selectedCountries.includes(value)) {
      const _countries = [...selectedCountries, value];
      setSelectedCountries(_countries);
      props.onChange && props.onChange(_countries);
    }

    setText("");
    setSuggestions([]);
    inputRef.current.focus();
  };

  const removeCountry = (country) => {
    const _countries = selectedCountries.filter((c) => c !== country)
    setSelectedCountries(_countries)
    props.onChange && props.onChange(_countries);
  };

  return (
    <div className="form-control">
      {props.label && (
        <label
          htmlFor={props.id || _id}
          className={`${
            props.labelClassName
              ? props.labelClassName
              : "email-templates-paragraph-1-medium"
          } form-control__label${
            props.labelMargin ? ` mb-${props.labelMargin}` : ""
          }`}
        >
          {props.label}
        </label>
      )}
      <div className="CountryAutocomplete form-control__inner">
        <input
          id={props.id || _id}
          ref={inputRef}
          value={text}
          onChange={onTextChanged}
          type="text"
          placeholder={props.placeholder}
          className={`form-control__input ui-caption-regular${
            props.validationError ? " form-control__input_error" : ""
          }${props.className ? ` ${props.className}` : ""}`}
        />
        <Suggestions 
          setSuggestions={setSuggestions}
          setText={setText}
          suggestionSelected={suggestionSelected}
          suggestions={suggestions}
        />
        {selectedCountries.length > 0 && (
          <div className="tags-list">
            {selectedCountries.map((country, index) => (
              <TagItem
                key={`country-tag-${index}`}
                tag={country}
                onClick={() => removeCountry(country)}
              />
            ))}
          </div>
        )}

        {props.validationError ? (
          <div className="form-control__error ui-caption-regular">
            {props.validationError.message
              ? props.validationError.message
              : typeof props.validationError !== "object"
              ? props.validationError
              : ""}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CountryAutocomplete;
