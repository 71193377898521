import PackageItem from "./PackageItem";

const PackagesList = props => {
  return (
    <>
      {props.packages.map((pkg, key) => (
        <PackageItem
          key={key}
          package={pkg}
          currentPackage={props.currentPackage}
          campaigns={props.campaigns}
          services={props.services}
          setCurrentPackageId={props.setCurrentPackageId}
        />
      ))}
    </>
  )
}

export default PackagesList;