import React from 'react'

const DropdownContent = ({data, setOpen, onSelect, noDataText}) => {
  return (
    <div className='content'>
      <ul>
        {!data.length && <li className='unselectable'>{noDataText}</li>}
        {data.map((item, i) => (
          <li 
            className='item'
            title={item.text}
            key={i} 
            onClick={() => {
              setOpen(false)
              onSelect && onSelect(item);
            }}
          >
            {item.text}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DropdownContent;