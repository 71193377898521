import { useEffect, useState } from "react";
import { Cookies, useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../header/Header";
import AnCaption from "../ui-elements/text/AnCaption";
import UiHeading from "../ui-elements/text/UiHeading";
import UiBodyRegular from "../ui-elements/text/UiBodyRegular";
import Preloader from "../preloader/Preloader";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import ThankYouTableFiller from "./ThankYouTableFiller";
import "./ThankYou.scss";

const ThankYou = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['lead']);
  const [loading, setLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const subscriptionId = urlParams.get('subscription_id');
  const sessionId = urlParams.get('session_id');
  const navigate = useNavigate();
  
  const [orderData, setOrderData] = useState({
    packagePrice: 0,
    vat: 0,
    total: 0
  });

  const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: process.env.REACT_APP_CURRENCY,
    maximumFractionDigits: 2
  });
  
  const setOrderDataFromResponse = data => {
    let total = 0;

    if (data?.subscription?.items?.data && data.subscription.items.data.length) {
      data.subscription.items.data.map(item => {
        if (item?.price?.unit_amount) {
          total += item.price.unit_amount;
        }
      });
    }

    const vat = (total / 100 * 0.2).toFixed(2);

    total = (total / 100).toFixed(2);
    
    setOrderData({
      orderNumber: data.subscription.id,
      date: (new Date(data.subscription.created * 1000).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })),
      packageName: data.package.name,
      emailsPerMonth: data.package.emailsPerMonth,
      packagePrice: total - vat,
      vat,
      total
    });
  }

  useEffect(() => {
    removeCookie('lead');

    if (sessionId) {
      axios.get(`/stripe/session/${sessionId}`)
        .then(response => {
          setOrderDataFromResponse(response.data);
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
        });
    } else if (subscriptionId) {
      axios.get(`/stripe/subscription/${subscriptionId}`)
        .then(response => {
          setOrderDataFromResponse(response.data);
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
        });
    } else {
      navigate('/');
    }
  }, []);

  if (loading) {
    return <Preloader />
  }

  return (
    <div>
      <Header />
      <div className="select-your-plan pt-70 pb-70 pt-16-mobile pb-64-mobile">
        <div className="container">
          <div className="thank-you">
            <div className="text-center text-left-mobile">
              <AnCaption text="Check Your Email" />
              <UiHeading
                desktop="h3-lg"
                text="Thanks for subscribing!"
                margin="4"
                element="h1"
              />
              <UiBodyRegular
                text="We've sent you an email with your login details. Find your temporary password inside, and click the link to dive into the world of seamless marketing automation. Welcome aboard!"
                margin="24"
              />
            </div>
            <table className="thank-you-table mt-32">
              <thead>
                <tr className="text-left">
                  <th colSpan="2" className="ui-other-headings-h6-bold">Order Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <UiCaptionRegular text="Order number" />
                  </td>
                  <td>
                    <UiCaptionRegular text={orderData.orderNumber} />
                  </td>
                </tr>
                <tr>
                  <td className="thank-you-table__hr">
                    <UiCaptionRegular text="Date" />
                  </td>
                  <td className="thank-you-table__hr">
                    <UiCaptionRegular text={orderData.date} />
                  </td>
                </tr>
                <ThankYouTableFiller />
                <tr>
                  <td
                    colSpan="2"
                    className="thank-you-table__plan text-center"
                  >
                    <AnCaption text="Package" />
                    <UiHeading
                      desktop="other-headings-h5-bold"
                      text={orderData.packageName}
                      element="div"
                      margin="8"
                    />
                    <UiCaptionRegular text={`${orderData.emailsPerMonth} emails per month`} />
                  </td>
                </tr>
                <ThankYouTableFiller />
                <tr>
                  <td>
                    <UiCaptionRegular text="Package (Ex VAT)" />
                  </td>
                  <td>
                    <UiCaptionRegular text={currencyFormatter.format(orderData.packagePrice)} />
                  </td>
                </tr>
                <tr>
                  <td className="thank-you-table__hr">
                    <UiCaptionRegular text="VAT" />
                  </td>
                  <td className="thank-you-table__hr">
                    <UiCaptionRegular text={currencyFormatter.format(orderData.vat)} />
                  </td>
                </tr>
                <tr>
                  <td className="pt-16">
                    <UiHeading
                      desktop="other-headings-h5-bold"
                      text="Total"
                      element="div"
                    />
                  </td>
                  <td className="pt-16">
                    <UiHeading
                      desktop="other-headings-h5-bold"
                      text={currencyFormatter.format(orderData.total)}
                      element="div"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;