import {memo} from "react"
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';

import Legend from "./Legend";
import createChartTooltip from '../../ui-elements/charts/createCustomTooltip';

import EmptyData from './EmptyData';

const CampaignPerformance = ({ totalEmailsSent = 0, opened, clicked, purchased, unsubscribed, disablePurchased }) => {
  ChartJS.register(ArcElement, Tooltip);

  const formatNumber = number => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 0
    });
  }

  let labels = ['Opened', 'Clicked', 'Purchased', 'Unsubscribed'];
  let datasets = [];

  if (disablePurchased) {
    labels = ['Opened', 'Clicked', 'Unsubscribed'];
  }

  datasets.push({
    labels: ['Opened'],
    data: [opened, totalEmailsSent - opened > 0 ? totalEmailsSent - opened : 0],
    showTooltip: [true, false],
    backgroundColor: ['#4bd4fb', '#f5f5f5']
  });

  datasets.push({
    labels: ['Clicked'],
    data: [clicked, totalEmailsSent - clicked > 0 ? totalEmailsSent - clicked : 0],
    showTooltip: [true, false],
    backgroundColor: ['#9254de', '#f5f5f5']
  });

  if (!disablePurchased) {
    datasets.push({
      labels: ['Purchased'],
      data: [purchased, totalEmailsSent - purchased > 0 ? totalEmailsSent - purchased : 0],
      showTooltip: [true, false],
      backgroundColor: ['#ff85c0', '#f5f5f5']
    });
  }

  datasets.push({
    labels: ['Unsubscribed'],
    data: [unsubscribed, totalEmailsSent - unsubscribed > 0 ? totalEmailsSent - unsubscribed : 0],
    showTooltip: [true, false],
    backgroundColor: ['#ffc53d', '#f5f5f5']
  });

  const data = {
    labels,
    datasets
  }

  return (
    <>
      <div className="email-templates-subtitle-2-medium">Campaign performance</div>
      <div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">Overall performance for this campaign</div>
      {!totalEmailsSent ?
        <div className="mt-24">
          <EmptyData />
        </div>
      :
        <div className="mt-24 an-row items-center">
          <div className="an-col an-col_53 position-relative">
            <Doughnut
              data={data}
              options={{
                borderRadius: 6,
                cutout: 50,
                responive: true,
                clip: 10,
                plugins: {
                  tooltip: {
                    enabled: false,
                    callbacks: {
                      title: (tooltipItem) => tooltipItem[0]?.dataset.labels[0]
                    },
                    external: createChartTooltip
                  }
                }
              }}
            />
            <div className="overview-emails">
              <div className="overview-emails__count">{formatNumber(totalEmailsSent)}</div>
              <div className="overview-emails__label color-secondary-colour-secondary-7">Emails sent</div>
            </div>
          </div>
          <div className="an-col an-col_47 d-flex d-flex_column d-flex_gap_8">
            <Legend
              label="Opened"
              value={opened}
              total={totalEmailsSent}
              color="#4bd4fb"
            />
            <Legend
              label="Clicked"
              value={clicked}
              total={totalEmailsSent}
              color="#9254de"
            />
            {!disablePurchased && (
              <Legend
                label="Purchased"
                value={purchased}
                total={totalEmailsSent}
                color="#ff85c0"
              />
            )}
            <Legend
              label="Unsubscribed"
              value={unsubscribed}
              total={totalEmailsSent}
              color="#ffc53d"
            />
          </div>
        </div>
      }
      
    </>
  )
}

export default memo(CampaignPerformance);