import { useState, useEffect, useContext } from "react";
import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import FileUploader from "../ui-elements/file-uploader/FileUploader";
import FormControl from "../ui-elements/forms/FormControl";
import UiButton from "../ui-elements/buttons/UiButton";
import Preloader from "../preloader/Preloader";
import Notification from "../ui-elements/notification/Notification";
import { updateUser } from "../api/Auth";
import { AuthContext } from "../context/AuthContext";
import { Notify } from "../ui-elements/notify/notify";

const UserDetailsAndPreferences = props => {
  const [firstName, setFirstName] = useState(props.user.firstName);
  const [lastName, setLastName] = useState(props.user.lastName);
  const [position, setPosition] = useState(props.user.position);
  const [email, setEmail] = useState(props.user.email);
  const [marketing, setMarketing] = useState(props.user.marketing ? 'Yes' : 'No');
  const [wasDataChanged, setWasDataChanged] = useState(false);
  const [avatar, setAvatar] = useState(props.user.avatar);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const {doSetUser} = useContext(AuthContext)
  const [initialObject, setInitialObject] = useState({
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    position: props.user.position,
    email: props.user.email,
    marketing: props.user.marketing ? 'Yes' : 'No',
    avatar: props.user.avatar
  });

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
      return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    const updObject = {
      firstName: firstName,
      lastName: lastName,
      position: position,
      email: email,
      marketing: marketing,
      avatar: avatar
    }

    setWasDataChanged(!deepEqual(initialObject, updObject));
  }, [avatar, firstName, lastName, position, email, marketing]);

  const saveUser = async (e) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      firstName: firstName,
      lastName: lastName,
      position: position,
      email: email,
      marketing: marketing === 'Yes' ? true : false,
      avatar: avatar
    }
    try {
      await updateUser(data);
      await doSetUser()
      setErrors({});
      setLoading(false);
      setInitialObject(data);
      setWasDataChanged(false);
      Notify.success({title: 'Changes saved successfully', message: 'Your profile has been updated.'})
    } catch (err) {
      console.error(err);
      if (err.response.data.name === 'ValidationError') {
        setErrors(err.response.data.errors);
      }
    }
    setLoading(false);
  }

  const resetChanges = e => {
    e.preventDefault();

    setFirstName(initialObject.firstName);
    setLastName(initialObject.lastName);
    setPosition(initialObject.position);
    setEmail(initialObject.email);
    setMarketing(initialObject.marketing);
    setAvatar(initialObject.avatar);
  }

  const updateAvatar = url => {
    setAvatar(url);
    setButtonsDisabled(false);
  }

  return (
    <form
      className="card card_24 card_shadow"
      onSubmit={saveUser}
    >
      <div className="an-row">
        <div className="an-col an-col_39">
          <UiCaptionMedium text="Profile Picture" />
          <div className="mt-8">
            <FileUploader
              helpText="Formats HEIC, WEBP, SVG, PNG or JPG. Recommended min width 512px."
              onFileChange={updateAvatar}
              onFileUploadStarted={() => setButtonsDisabled(true)}
              image={avatar}
            />
          </div>
        </div>
        <div className="an-col an-col_61">
          <div className="an-layout">
            <div className="an-row">
              <div className="an-col an-col_50">
                <FormControl
                  label="First Name"
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="First Name"
                  value={firstName}
                  require={true}
                  labelMargin="4"
                  onChange={e => setFirstName(e.target.value)}
                  labelClassName="ui-caption-medium"
                  validationError={errors.firstName}
                />
              </div>
              <div className="an-col an-col_50">
                <FormControl
                  label="Last Name"
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Last Name"
                  value={lastName}
                  require={true}
                  labelMargin="4"
                  onChange={e => setLastName(e.target.value)}
                  labelClassName="ui-caption-medium"
                  validationError={errors.lastName}
                />
              </div>
            </div>
            <FormControl
              type="text"
              label="Position"
              id="position"
              name="position"
              placeholder="Position"
              value={position}
              labelMargin="4"
              onChange={e => setPosition(e.target.value)}
              labelClassName="ui-caption-medium"
              validationError={errors.position}
            />
            <FormControl
              label="Email"
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={email}
              require={true}
              labelMargin="4"
              onChange={e => setEmail(e.target.value)}
              labelClassName="ui-caption-medium"
              validationError={errors.email}
            />
            <FormControl
              label="Would you like to receive our marketing emails?"
              type="radio"
              id="marketing"
              name="marketing"
              value={marketing}
              labelMargin="4"
              onChange={e => setMarketing(e.target.value)}
              labelClassName="ui-caption-medium"
              options={['Yes', 'No']}
            />
          </div>
        </div>
      </div>
      <div className="mt-32 flex-right gap-8">
        <UiButton
          text="Cancel"
          size="medium"
          design="secondary"
          onClick={resetChanges}
          disabled={!wasDataChanged || buttonsDisabled}
        />
        <UiButton
          text="Save changes"
          size="medium"
          disabled={!wasDataChanged || buttonsDisabled}
        />
      </div>
      {loading ? (
        <Preloader
          absolute={true}
          overflow={true}
        />
      ) : ''}
    </form>
  )
}

export default UserDetailsAndPreferences;