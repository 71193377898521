import { useEffect, useState, useCallback, useContext } from "react";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { Table, Header, HeaderRow, HeaderCell, Body, Row, Cell } from "@table-library/react-table-library/table";
import { EyeOpen, Cross, ChevronUp } from "akar-icons";
import Modal from "react-modal";
import moment from "moment";
import SectionHead from "../../ui-elements/common/SectionHead";
import AnTable from "../../ui-elements/common/AnTable";
import { getCustomerFeedbackList, getCustomerFeedbackAnswers } from "../../api/Campaign";
import PageSize from "../../ui-elements/common/PageSize";
import Pagination from "../../ui-elements/common/Pagination";
import Stars from "../../ui-elements/common/Stars";
import UiParagraphMedium from "../../ui-elements/text/UiParagpraphMedium";
import UiSmallRegular from "../../ui-elements/text/UiSmallRegular";
import UiBodyMedium from "../../ui-elements/text/UiBodyMedium";
import UiSmallMedium from "../../ui-elements/text/UiSmallMedium";
import UiCaptionRegular from "../../ui-elements/text/UiCaptionRegular";
import { AuthContext } from "../../context/AuthContext";
import UiButton from "../../ui-elements/buttons/UiButton";
import './CustomerFeedbackPage.scss';

Modal.setAppElement('#root');

const CustomerFeedbackPage = () => {
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState({});
  const { shopData } = useContext(AuthContext);

  const [data, setData] = useState({
    nodes: [],
    totalPages: 0,
    products: []
  });

  const [reviewData, setReviewData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeAccordionKey, setActiveAccordionKey] = useState(0);

  const timeFormat = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  }

  const fetchData = useCallback(async (params) => {
    const result = await getCustomerFeedbackList(params);

    setData({
      nodes: result.data.map(item => ({
        ...item,
        time: new Intl.DateTimeFormat('en-US', timeFormat).format(new Date(item.createdAt))
      })),
      totalPages: result.totalPages,
      products: result.products
    });
  }, []);

  useEffect(() => {
    fetchData({
      search,
      order,
      page: 0
    });
  }, [fetchData]);

  const onPaginationChange = (action, state) => fetchData({
    search,
    page: state.page,
    size: state.size,
    order
  });

  const pagination = usePagination(data, {
    state: {
      page: 0
    },
    onChange: onPaginationChange,
  }, {
    isServer: true,
  });

  const theme = useTheme([getTheme(), {
    Table: '--data-table-library_grid-template-columns: minmax(100px, 1fr) 115px repeat(2, 303px) 48px'
  }]);

  const changePageSize = value => pagination.fns.onSetSize(value[0].value);

  const changeOrder = value => {
    let _order = order;

    if (order?.[value]) {
      _order[value] = order[value] === 1 ? -1 : 1;
    } else {
      _order = {};
      _order[value] = 1;
    }

    setOrder(_order);

    pagination.fns.onSetPage(0);
  }

  const viewRating = async (item) => {
    setReviewData({
      orderId: item?.orderId,
      answers: await getCustomerFeedbackAnswers(item._id),
      orderDate: item?.orderData?.date_created ? moment(item.orderData.date_created).format('MMMM D, YYYY') : '',
      orderSummary: item?.orderData?.line_items,
      rating: item?.stars,
      title: item?.firstName,
      date: item?.createdAt ? moment(item.createdAt).format('MMMM D, YYYY') : '',
      comment: item?.answers?.textarea_comments
    });

    setModalIsOpen(true);
  }

  const getPermalink = productId => {
    if (data.products.length === 0) {
      return '';
    }

    let permalink = '';

    data.products.map(product => {
      if (product?.originalId === productId) {
        permalink = product.permalink;
      }
    });

    return permalink;
  }

  const formatMoney = number => {
    return number.toLocaleString('en-US', {
      style: 'currency',
      currency: shopData.currency
    });
  }

  console.log(data);

  return (
    <div className="card card_br_24 card_24 card_shadow card_align_center card_flex">
      <SectionHead
        align="center"
        title={
          <>
            <div className="email-templates-subtitle-2-medium">Feedback received</div>
            <div className="mt-4 email-templates-caption-regular color-secondary-colour-secondary-4">Latest activity</div>
          </>
        }
      />
      <div className="mt-24 activity-table">
        <AnTable
          body={
            <>
              <Table
                data={data}
                theme={theme}
                pagination={pagination}
                layout={{
                  custom: true
                }}
              >
                {tableList => (
                  <>
                    <Header>
                      <HeaderRow>
                        <HeaderCell className={`activity-table__th activity-table__th_no-sorting`}>Name</HeaderCell>
                        <HeaderCell className={`activity-table__th activity-table__th_no-sorting table-align-right`}>Order ID</HeaderCell>
                        <HeaderCell onClick={() => changeOrder('createdAt')} className={`activity-table__th table-align-right${order?.createdAt ? ' activity-table__th_active' : ''}`}>Time</HeaderCell>
                        <HeaderCell onClick={() => changeOrder('stars')} className={`activity-table__th table-align-center${order?.stars ? ' activity-table__th_active' : ''}`}>Rating</HeaderCell>
                        <HeaderCell></HeaderCell>
                      </HeaderRow>
                    </Header>
                    <Body>
                      {tableList.map(item => (
                        <Row
                          item={item}
                          key={item._id}
                        >
                          <Cell>{item.firstName} {item.lastName}</Cell>
                          <Cell>{item.orderId}</Cell>
                          <Cell className="table-align-right">{item.time}</Cell>
                          <Cell>
                            <Stars rating={item.stars} />
                          </Cell>
                          <Cell>
                            {!!item?.answers && (
                              <button
                                className="view-btn"
                                onClick={() => viewRating(item)}
                              >
                                <EyeOpen size="16" />
                              </button>
                            )}
                          </Cell>
                        </Row>
                      ))}
                    </Body>
                  </>
                )}
              </Table>
              <div className="an-table__bottom pl-0 pr-0">
                <PageSize onChange={changePageSize} />
                <Pagination
                  pagination={pagination}
                  pagesCount={data?.totalPages ? data.totalPages : 0}
                />
              </div>
            </>
          }
        />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="an-modal"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          <div className="an-modal__title an-modal__title_fill text-left">
            <UiParagraphMedium text={`Order nr. #${reviewData?.orderId}`} />
            {!!reviewData?.orderDate && (
              <UiSmallRegular
                text={`Order placed on ${reviewData.orderDate}`}
                className="color-secondary-colour-secondary-7"
              />
            )}
          </div>
          <button
            onClick={() => setModalIsOpen(false)}
            className="an-modal__close"
          >
            <Cross
              size="24"
              color="#d9d9d9"
            />
          </button>
        </div>
        <div className="an-modal__body">
          <div className="cf-accordion">
            {!!reviewData?.answers && (
              <div className={`cf-accordion__item${activeAccordionKey === 1 ? ' cf-accordion__item_active' : ''}`}>
                <div
                  className="cf-accordion__title"
                  onClick={() => setActiveAccordionKey(1)}
                >
                  <UiBodyMedium text="Questions and answers" />
                  <div class="cf-accordion__icon">
                    <ChevronUp size="24" />
                  </div>
                </div>
                <div class="cf-accordion__body">
                  <table className="cf-answers-table">
                    <thead>
                      <tr>
                        <th>
                          <UiSmallMedium text="Questions" />
                        </th>
                        <th>
                          <UiSmallMedium text="Answer" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reviewData.answers.map(answer => (
                        <tr>
                          <td>
                            <UiCaptionRegular text={answer.question} />
                          </td>
                          <td>
                            <UiCaptionRegular text={answer.answer} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {!!reviewData?.orderSummary && (
              <div className={`cf-accordion__item${activeAccordionKey === 2 ? ' cf-accordion__item_active' : ''}`}>
                <div
                  className="cf-accordion__title"
                  onClick={() => setActiveAccordionKey(2)}
                >
                  <UiBodyMedium text="Order summary" />
                  <div class="cf-accordion__icon">
                    <ChevronUp size="24" />
                  </div>
                </div>
                <div class="cf-accordion__body">
                  <table className="cf-answers-table cf-answers-table_order">
                    <thead>
                      <tr>
                        <th>
                          <UiSmallMedium text="Product" />
                        </th>
                        <th>
                          <UiSmallMedium text="Quantity" />
                        </th>
                        <th>
                          <UiSmallMedium text="Price" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reviewData.orderSummary.map(item => (
                        <tr>
                          <td>
                            <div className="cf-answers-table__product">
                              {!!item?.image?.src && (
                                <img src={item.image.src} alt={item.name} className="cf-answers-table__product-image" />
                              )}
                              <div className="cf-answers-table__product-meta">
                                <a href={getPermalink(item.product_id)} target="_blank" className="cf-answers-table__product-link ui-caption-regular">{item.name}</a>
                                {item?.meta_data.length && (
                                  <>
                                    {item.meta_data.map(meta => (
                                      <>
                                        {!!meta?.display_key && meta.display_key[0] !== '_' && (
                                          <UiCaptionRegular text={`${meta.display_key}: ${meta.display_value}`} />
                                        )}
                                      </>
                                    ))}
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <UiCaptionRegular text={item.quantity} />
                          </td>
                          <td>
                            <UiCaptionRegular text={formatMoney(parseFloat(item.total))} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className="cf-modal-bottom">
            <Stars
              rating={reviewData.rating}
              align="left"
            />
            {!!reviewData.date && (
              <UiSmallRegular
                margin="8"
                text={`${reviewData?.title ? `${reviewData.title} on ${reviewData.date}` : reviewData.date}`}
                className="color-secondary-colour-secondary-4"
              />
            )}
            {!!reviewData?.comment && (
              <UiCaptionRegular
                margin="8"
                text={reviewData.comment}
                className="color-secondary-colour-secondary-7"
              />
            )}
            <div className="flex-right mt-16">
              {/* TODO */}
              <UiButton
                size="medium"
                text="Open chat with customer"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default CustomerFeedbackPage;