import axios from "axios";

export const getSubscribersManagementKPI = () => {
  return new Promise((resolve, reject) => {
    axios.get('/subscribers-management/kpi', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const getSubscribersManagementGeneralPerformance = ({ startDate, endDate }) => {
  return new Promise((resolve, reject) => {
    axios.get('/subscribers-management/general-performance', {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      },
      params: {
        startDate,
        endDate
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error)); 
  });
}

export const removeConsentEntries = ({ subscriberIds }) => {
  return new Promise((resolve, reject) => {
    axios.delete('/shop/consent/list/remove', {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      },
      params: {
        subscriberIds
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  })
}

export const resendConsentEmail = ({ subscriberIds }) => {
  return new Promise((resolve, reject) => {
    axios.post('/shop/consent/list/resend', {
      subscriberIds
    }, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}