import React, { useState, useEffect, useRef } from 'react'

import useCampaign from '../../../hooks/useCampaign';
import Preloader from '../../../preloader/Preloader';
import UiButton from '../../../ui-elements/buttons/UiButton';
import { getHolidays } from '../../../api/Campaign';

const Holidays = ({ category, handleBack, handleAIGenerate }) => {
	const [holidays, setHolidays] = useState([])
	const [loading, setLoading] = useState(true)
	const { campaigns } = useCampaign();
	const campaignInstance = campaigns.find((campaign) => campaign.slug === 'promo-campaign')
	const holiday = useRef(null)

	const fetchHolidays = async() => {
		try {
			setLoading(true)
			const response = await getHolidays(category._id);
			setHolidays(response)
		} catch (error) {
			throw error
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchHolidays()
	}, [])

	const handleNext = () => {
		handleAIGenerate({campaignId: campaignInstance._id, holiday: holiday.current})
	}

	if (loading) return <Preloader opaque overflow />

	return (
		<>
			<form className={`onboarding-inputs`} style={{overflowY: 'auto', marginTop: '20px', marginBottom: '20px'}}>
				{holidays.map((day, key) => {
					const {name, endDate, startDate, holidayCategory, _id} = day
					return (
						<div
							className={`onboarding-input text-left`}
							key={key}
						>
							<input
								type='radio'
								name='holidays'
								value={_id}
								onChange={() => holiday.current = day}
								className="onboarding-input__elem"
								id={`${name}_${key}`}
							/>
							<label 
								htmlFor={`${name}_${key}`} 
								className={`ui-caption-regular color-secondary-colour-secondary-7 onboarding-input__label onboarding-input__label_radio`}
							>
								{name}
								<div className='ui-extra-small-regular'>
									{`${startDate} - ${endDate}`}
								</div>
							</label>
						</div>
					)
				})}
			</form>
			<div className="onboarding-questionnaire__bottom">
				<UiButton
					onClick={handleBack}
					size="medium"
					text="Back"
					design="secondary"
					className="ui-button_mw_150"
				/>
				<UiButton
					onClick={handleNext}
					size="medium"
					text="Next"
					design="secondary"
					className="ui-button_mw_150"
				/>
			</div>
		</>
	);
}

export default Holidays;