import { useMemo, useContext } from "react";
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
} from "@table-library/react-table-library/table";
import {
  useSort,
  HeaderCellSort,
} from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import SectionHead from "../ui-elements/common/SectionHead";
import AnTable from "../ui-elements/common/AnTable";
import CampaignIcon from "../ui-elements/common/CampaignIcon";
import UiButton from "../ui-elements/buttons/UiButton";
import Chips from "../ui-elements/common/Chips";
import AkarIconsChevronVertical from "../static/images/common/akar-icons_chevron-vertical.svg";
import AkarIconsChevronVerticalCopy from "../static/images/common/akar-icons_chevron-vertical copy.svg";
import "./YourCampaigns.scss";
import { CampaignContext } from "../context/CampaignContext";
import { Tooltip } from "react-tooltip";
import { NavLink } from 'react-router-dom'

const CampaignPerformance = props => {
  const { shopCampaigns } = useContext(CampaignContext);
  const nodes = useMemo(() => {

    return shopCampaigns.reduce((acc, campaign) => {
      let chipsColor = "green";

      if (["subscribers-management"].includes(campaign.slug)) {
        return acc
      }

      if (campaign.status === "draft") {
        chipsColor = "blue";
      } else if (['inactive', 'paused'].includes(campaign.status)) {
        chipsColor = "red";
      }

      acc.push({
        ...campaign,
        createdAt: new Date(campaign.createdAt),
        chipsColor: chipsColor,
      })
      return acc
    }, []);
  }, [shopCampaigns]);

  const theme = useTheme([
    getTheme(),
    {
      Table: `
      --data-table-library_grid-template-columns: minmax(0, 1fr) 120px 180px 120px 120px 120px
    `,
    },
  ]);

  const sort = useSort(
    { nodes },
    {},
    {
      sortIcon: {
        margin: "0px",
        iconDefault: <img src={AkarIconsChevronVertical} alt="" />,
        iconUp: <img src={AkarIconsChevronVerticalCopy} alt="" />,
        iconDown: <img src={AkarIconsChevronVerticalCopy} alt="" />,
      },
      sortFns: {
        CAMPAIGNNAME: (array) =>
          array.sort((a, b) => a.name.localeCompare(b.name)),
        STATUS: (array) =>
          array.sort((a, b) => a.status.localeCompare(b.status)),
        CREATED: (array) => array.sort((a, b) => a.createdAt - b.createdAt),
        IMPRESSIONS: (array) => array.sort((a, b) => a.opened - b.opened),
        ENGAGEMENTS: (array) => array.sort((a, b) => a.clicked - b.clicked),
        EMAILSSENT: (array) =>
          array.sort((a, b) => a.emailsSent - b.emailsSent),
      },
    }
  );

  return (
    <>
      {nodes.length ? (
        <div className={`card card_24 card_br_24 card_shadow${props.performanceDisabled ? ' blured' : ''}`}>
          <SectionHead
            title={
              <>
                <h5 className="email-templates-subtitle-2-medium">
                  Your campaigns
                </h5>
                <div className="mt-4 email-templates-caption-regular color-secondary-colour-secondary-4">
                  Track the performance of your campaigns with key metrics
                  including impressions, engagement, email count, and status.
                </div>
              </>
            }
            // TODO: filter on click
            filter={
              <UiButton
                size="small"
                design="secondary"
                className="ui-button_mw_120"
                text="Overall"
              />
            }
          />
          <div className="mt-24 campaigns-table">
            <AnTable
              body={
                <Table
                  data={{ nodes }}
                  sort={sort}
                  theme={theme}
                  layout={{
                    custom: true,
                  }}
                >
                  {(tableList) => (
                    <>
                      <Header>
                        <HeaderRow>
                          <HeaderCellSort sortKey="CAMPAIGNNAME">
                            Campaign Name
                          </HeaderCellSort>
                          <HeaderCellSort sortKey="STATUS">
                            Status
                          </HeaderCellSort>
                          <HeaderCellSort sortKey="CREATED">
                            Created
                          </HeaderCellSort>
                          <HeaderCellSort
                            sortKey="IMPRESSIONS"
                            data-tooltip-id="impressions"
                            data-tooltip-html='<div class="ui-extra-small-medium text-uppercase color-gray-gray-9">Impressions</div><div class="ui-small-regular mt-8 color-secondary-colour-secondary-7">Impressions in email marketing count each time your email is opened, measuring its visibility and reach.</div>'
                            data-tooltip-place="bottom-end"
                          >
                            Impressions
                          </HeaderCellSort>
                          <HeaderCellSort
                            sortKey="ENGAGEMENTS"
                            data-tooltip-id="engagements"
                            data-tooltip-html='<div class="ui-extra-small-medium text-uppercase color-gray-gray-9">Engagement</div><div class="ui-small-regular mt-8 color-secondary-colour-secondary-7">Measure audience interaction related to, clicks, replies, and purchases from your campaigns, showcasing active interest and response from your customers.</div>'
                            data-tooltip-place="bottom-end"
                          >
                            Engagements
                          </HeaderCellSort>
                          <HeaderCellSort sortKey="EMAILSSENT">
                            Emails Sent
                          </HeaderCellSort>
                        </HeaderRow>
                      </Header>
                      <Body>
                        {tableList.map((item) => (
                          <Row item={item} key={item._id}>
                            <Cell>
                              <div className="campaign-name">
                                <CampaignIcon emoji={item.emoji} />
                                <span className="campaign-performance-link__name">
                                  <NavLink to={`/campaigns/${item.slug}`}>
                                    {item.shopCampaignName}
                                  </NavLink>
                                </span>
                              </div>
                            </Cell>
                            <Cell>
                              <Chips
                                color={item.chipsColor}
                                value={item.status}
                              />
                            </Cell>
                            <Cell>
                              {item.createdAt.toLocaleDateString("en-US", {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              })}
                            </Cell>
                            <Cell>{item.opened}</Cell>
                            <Cell>{`${item.clicked}${item.emailsSent
                                ? ` (${(
                                  (item.clicked / item.emailsSent) *
                                  100
                                ).toFixed(1)}%)`
                                : ""
                              }`}</Cell>
                            <Cell>{item.emailsSent}</Cell>
                          </Row>
                        ))}
                      </Body>
                    </>
                  )}
                </Table>
              }
            />
          </div>
          <Tooltip
            id="impressions"
            className="an-tooltip"
            arrowColor="transparent"
          />
          <Tooltip
            id="engagements"
            className="an-tooltip"
            arrowColor="transparent"
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CampaignPerformance;
