import React, { memo } from "react"
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import Legend from "../Legend";
import createChartTooltip from "../../../ui-elements/charts/createCustomTooltip";
import EmptyData from '../EmptyData';

const SurveyPerformance = (props) => {
  const { emailOpened, surveyOpened, answered, unsubscribed, sented = 0 } = props
  ChartJS.register(ArcElement, Tooltip);

  const formatNumber = number => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 0
    });
  }

  const legends = {
    emailOpened: {
      label: "Emails opened",
      color: "#4BD4FB"
    },
    surveyOpened: {
      label: "Open surveys",
      color: "#9254DE"
    },
    answered: {
      label: "Answered",
      color: "#FF85C0"
    },
    unsubscribed: {
      label: "Unsubscribed",
      color: "#FFC53D"
    },
  }

  const data = {
    datasets: [
      {
        labels:['Emails opened'],
        data: [emailOpened, sented - emailOpened > 0 ? sented - emailOpened : 0],
        showTooltip: [true, false],
        backgroundColor: ['#4BD4FB', '#f5f5f5']
      },
      {
        labels:['Open surveys'],
        data: [surveyOpened, sented - surveyOpened > 0 ? sented - surveyOpened : 0],
        showTooltip: [true, false],
        backgroundColor: ['#9254DE', '#f5f5f5']
      },
      {
        labels: ['Answered'],
        data: [answered, sented - answered > 0 ? sented - answered : 0],
        showTooltip: [true, false],
        backgroundColor: ['#FF85C0', '#f5f5f5']
      },
      {
        labels: ['Unsubscribed'],
        data: [unsubscribed, sented - unsubscribed > 0 ? sented - unsubscribed : 0],
        showTooltip: [true, false],
        backgroundColor: ['#FFC53D', '#f5f5f5']
      },
    ]
  }

  return (
    <>
      <div className="email-templates-subtitle-2-medium">Campaign performance</div>
      <div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">Latest activity</div>
      {!sented ?
        <div className="mt-24">
          <EmptyData />
        </div>
      :
        <div className="mt-24 an-row items-center">
          <div className="an-col an-col_53 position-relative">
            <Doughnut
              data={data}
              options={{
                borderRadius: 6,
                cutout: 50,
                responive: true,
                clip: 10,
                plugins: {
                  tooltip: {
                    enabled: false,
                    callbacks: {
                      title: (tooltipItem) => tooltipItem[0]?.dataset.labels[0]
                    },
                    external: createChartTooltip
                  }
                }
              }}
            />
            <div className="overview-emails">
              <div className="overview-emails__count">{formatNumber(sented)}</div>
              <div className="overview-emails__label color-secondary-colour-secondary-7">Emails sent</div>
            </div>
          </div>
          <div className="an-col an-col_47 d-flex d-flex_column d-flex_gap_8">
            {Object.keys(legends).map((legendKey) =>
              <Legend
                key={legendKey}
                label={legends[legendKey].label}
                value={props[legendKey]}
                total={sented}
                color={legends[legendKey].color}
              />
            )}
          </div>
        </div>
      }
      
    </>
  )
}

export default memo(SurveyPerformance);