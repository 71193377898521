import React, { useContext, useState, useMemo, useEffect } from "react";
import ModalLayout from "../../modal/Layout";
import StepWizard from "../../ui-elements/step-wizard/StepWizard";
import { AuthContext } from "../../context/AuthContext";
import LayoutSelectionStep from "../../brand-assets/email-header/LayoutSelectionStep";
import Customization from "../../brand-assets/email-header/Customization";
import { useNavigate } from "react-router-dom";
import { saveEmailHeader } from "../../api/Shop";
import Preloader from "../../preloader/Preloader";
import { Notify } from "../../ui-elements/notify/notify";
import { getEmailHeaderFields } from "../../api/Customizer";

const EmailHeaderCustomize = () => {
  const { shopData, updateShop } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [schemas, setSchemas] = useState([]);
  const navigate = useNavigate();

  const steps = useMemo(() => {
    return {
      layout: {
        title: "Select the layout for your email header",
        description: <>Customise the layout of your email header by choosing between a horizontal or vertical design. Each layout offers different options <br/>for customising the number of links, allowing you to tailor your email header to your specific needs in the next step.</>,
        component: <LayoutSelectionStep />,
      },
      customizer: {
        title: "Customise your email header",
        description: <>Provide the necessary details for the links displayed in your email header. Enter the name of each link, which will be visible to recipients, <br />along with the corresponding URL that directs customers to the desired page.</>,
        component: <Customization schemas={schemas} />,
        submitButtonLabel: "Save",
      },
    };
  }, [schemas]);

  useEffect(() => {
    fetchEmailHeaderFields();
  }, []);

  const fetchEmailHeaderFields = async () => {
    setLoading(true);
    try {
      const fields = await getEmailHeaderFields();
      setSchemas(fields);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleComplete = async (data) => {
    if (saving) return;
    setSaving(true);
    try {
      await saveEmailHeader(data);
      await updateShop();
      Notify.success({ title: "Email header updated successfully" });
      handleClose();
    } catch (e) {
      console.log("error", e);
    }
    setSaving(false);
  };

  const handleClose = () => {
    navigate("/brand-assets/common-layouts");
  };

  return (
    <ModalLayout backTo="/brand-assets/common-layouts">
      {!loading && (
        <StepWizard
          data={shopData.emailHeader}
          steps={steps}
          onComplete={handleComplete}
          onClose={handleClose}
        />
      )}
      {loading || (saving && <Preloader overflow />)}
    </ModalLayout>
  );
};

export default EmailHeaderCustomize;
