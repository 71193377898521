import { useState, useEffect } from "react";
import axios from "axios";
import Preloader from "../preloader/Preloader";
import UiButton from "../ui-elements/buttons/UiButton";
import FormControl from "../ui-elements/forms/FormControl";
import platformLogos from "../static/images/onboarding/Platform logos.svg";
import platformLogos1 from "../static/images/onboarding/Platform logos (1).svg";
import storeFavicon from "../static/images/onboarding/StoreFavicon.svg";
import "./ShopImport.scss";

const ShopImport = props => {
  const [platform, setPlatform] = useState('woocommerce');
  const [websiteUrl, setWebsiteUrl] = useState(props.user.websiteUrl);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [faviconUrl, setFaviconUrl] = useState(storeFavicon);
  const [authorizeDisabled, setAuthorizeDisabled] = useState(false);
  const [storeUrlView, setStoreUrlView] = useState(false);
  const [urlValidationMessage, setUrlValidationMessage] = useState({});
  const [wpApiPrefix, setWpApiPrefix] = useState('wp-json');
  const token = localStorage.getItem('token');

  const [platfroms, setPlatforms] = useState([
    {
      icon: platformLogos,
      enabled: true,
      name: 'woocommerce'
    },
    {
      icon: platformLogos1,
      enabled: false,
      name: 'shopify'
    }
  ]);

  useEffect(() => {
    axios.get(`/shop/fetchMeta/?url=${websiteUrl}`, {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    }).then(response => {
      setTitle(response.data.title);

      response.data.favicon ?? setFaviconUrl(response.data.favicon);

      setLoading(false);
    }).catch(error => {
      console.error(error);

      setLoading(false);
    });
  }, []);

  const showChangeUrl = () => setStoreUrlView(true);

  const authorize = async () => {
    const trimmedWebsiteUrl = websiteUrl.replace(/[\/]+$/, '');
    const authroizationUrl = `${trimmedWebsiteUrl}/wc-auth/v1/authorize/?app_name=Anomate&scope=read_write&user_id=${encodeURIComponent(props.user.token)}&return_url=${process.env.REACT_APP_HOME_URL}/onboarding&callback_url=${process.env.REACT_APP_API_BASE_URL}/user/authorize/keys`;

    props.disablePrev();

    setLoading(true);

    try {
      await axios.put('/user', {
        websiteUrl: trimmedWebsiteUrl
      }, {
        headers: {
          Authorization: `Bearer: ${token}`
        }
      });

      await axios.post('/shop/woocommerce/exists', {
        url: trimmedWebsiteUrl
      }, {
        headers: {
          Authorization: `Bearer: ${token}`
        }
      });

      setUrlValidationMessage({});

      window.location.href = authroizationUrl;
    } catch (error) {
      setUrlValidationMessage({
        message: 'WooCommerce is not found in provided URL'
      });

      props.enablePrev();

      setLoading(false);
    }
  }

  const updateWebsiteUrl = e => {
    setWebsiteUrl(e.target.value);
    setAuthorizeDisabled(!(platform && isValidUrl(e.target.value)));
  }

  const updatePlatform = selectedPlatform => {
    setPlatform(selectedPlatform);
    setAuthorizeDisabled(!isValidUrl(websiteUrl));
  }

  const isValidUrl = urlString => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i');

    return !!urlPattern.test(urlString);
  }

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className="mt-48 shop-import text-left">
      <div className="email-templates-paragraph-1-medium">What platform does your store currently use?</div>
      <div className="shop-import__platforms mt-16">
        {platfroms.map((platformItem, key) => (
          <button
            className={`shop-platform${platform === platformItem.name ? ' shop-platform_active' : ''}`}
            onClick={() => updatePlatform(platformItem.name)}
            disabled={!platformItem.enabled}
            key={key}
          >
            <img
              src={platformItem.icon}
              alt={platformItem.name}
              className="shop-platform__icon"
            />
          </button>
        ))}
      </div>
      {storeUrlView ? (
        <div className="mt-32">
          <FormControl
            id="store_url"
            name="store_url"
            label="What is your store URL?"
            labelMargin="16"
            onChange={updateWebsiteUrl}
            value={websiteUrl}
            type="url"
            validationError={!!urlValidationMessage.message}
          />
        </div>
      ) : (
        <div>
          <div className="shop-import-url-wrapper mt-32">
            <div className="shop-meta">
              {faviconUrl ? (
                <img
                  src={faviconUrl}
                  alt=""
                  className="shop-meta__icon"
                />
              ) : ('')}
              <div className="shop-meta__data">
                {title ? <div className="email-templates-subtitle-3-semibold">{title}</div> : ''}
                <div className="mt-8 email-templates-paragraph-2-regular color-secondary-colour-secondary-7">{websiteUrl}</div>
              </div>
            </div>
            <UiButton
              onClick={showChangeUrl}
              size="small"
              text="Not your store?"
              design="secondary"
              className="shop-import-url-wrapper__button"
            />
          </div>
        </div>
      )}
      {platform === 'woocommerce' ? (
        <FormControl
          id="api_prefix"
          name="api_prefix"
          label="API prefix"
          labelMargin="16"
          margin="32"
          onChange={(e) => setWpApiPrefix(e.target.value)}
          value={wpApiPrefix}
          type="text"
          validationError={!!urlValidationMessage.message}
        />
      ) : ('')}
      {urlValidationMessage ? (
        <div className="mt-16 ui-caption-regular color-dust-red-red-5">{urlValidationMessage.message}</div>
      ) : ('')}
      <div className="mt-16 pt-4 pb-2 text-right">
        <UiButton
          onClick={authorize}
          size="small"
          text="Authorise"
          disabled={authorizeDisabled}
        />
      </div>
    </div>
  )
}

export default ShopImport;