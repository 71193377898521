import React, { useState, useContext, useMemo, useEffect } from "react";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import Counter from "../../widgets/Counter";
import UiButton from "../../ui-elements/buttons/UiButton";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { getShopWidgets } from "../../api/Shop";
import WidgetPreview from "../../widgets/WidgetPreview";
import illustrations from "../../static/images/store-settings/Illustrations.svg";
import Preloader from "../../preloader/Preloader";
import Modal from "react-modal";
import MainContent from "../../dashboard/MainContent";
import ReachedModal from "../../widgets/ReachedModal";

Modal.setAppElement('#root');

const WidgetOverviewPage = () => {
  const [widgets, setWidgets] = useState([]);
  const [reachedModalIsOpen, setReachedModalIsOpen] = useState(false);
  const { currentPackage } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [updated, setUpdated] = useState(null);

  const availableWidgetCount = useMemo(() => {
    return currentPackage?.widgets || 0;
  }, [currentPackage]);

  useEffect(() => {
    fetchWidgets();
  }, [updated]);

  const fetchWidgets = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await getShopWidgets();
      setWidgets(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleCreateWidgetClick = () => {
    if (
      Number(availableWidgetCount) >= 0 &&
      widgets.length >= Number(availableWidgetCount)
    ) {
      setReachedModalIsOpen(true);
    } else {
      navigate("/widgets/new");
    }
  };

  const closeReachedModal = () => setReachedModalIsOpen(false);

  if (loading) {
    return <Preloader />;
  }

  return (
    <MainContent fullHeight={widgets.length === 0}>
      {widgets.length ? (
        <div className="an-layout">
          <SectionHead
            align="center"
            title={
              <>
                <UiHeading
                  text="My widgets"
                  desktop="email-templates-subtitle-2-bold"
                />
                <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                  Widgets offer a set of interactive features and personalised
                  content blocks for your campaign emails, allowing you to create
                  compelling and impactful marketing experiences.
                </div>
              </>
            }
            filter={
              <div className="widget-actions">
                <Counter
                  available={widgets.length}
                  total={
                    Number(availableWidgetCount) < 0 ? "∞" : availableWidgetCount
                  }
                />
                <UiButton
                  size="small"
                  design="secondary"
                  text="Create widget"
                  onClick={handleCreateWidgetClick}
                />
                <ReachedModal
                  reachedModalIsOpen={reachedModalIsOpen}
                  closeReachedModal={closeReachedModal}
                  availableWidgetCount={availableWidgetCount}
                />
              </div>
            }
          />
          <div className="an-row an-row_flex-wrap">
            {widgets.map((widget, key) => (
              <WidgetPreview
                widget={widget}
                key={key}
                onUpdate={() => setUpdated(Math.random())}
                availableWidgetCount={availableWidgetCount}
                widgetsCount={widgets.length}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="card card_24 card_shadow text-center card_h_100 card_align_center card_flex">
          <div className="card__overflow">
            <UiHeading
              text="New to widgets?"
              desktop="email-templates-subtitle-2-bold"
            />
            <div className="email-templates-paragraph-1-regular mt-8 color-secondary-colour-secondary-7">Enhance your email campaigns with dynamic widgets that captivate and engage your audience. <br />Widgets offer a range of interactive features and personalised content blocks, allowing you to create compelling <br />and impactful marketing experiences</div>
            <div className="pt-30 pb-30 mt-40 text-center">
              <img
                src={illustrations}
                alt="New to widgets?"
                className="responsive-img"
              />
            </div>
            <div className="mt-40">
              <UiButton
                size="medium"
                text="Create widgets"
                onClick={handleCreateWidgetClick}
              />
            </div>
          </div>
        </div>
      )}
    </MainContent>
  );
};

export default WidgetOverviewPage;
