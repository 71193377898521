import { v4 as uuidv4 } from "uuid";
import { arrayMove } from "@dnd-kit/sortable";

const blockReducer = (state, action) => {
	const block = state.find((block, index) => index === action.payload.index);
	switch (action.type) {
    case 'SET_BLOCKS':
      return action.payload;
      
		case "SET_BLOCK":
			const _answers = action.payload.value.answers;
			const question = action.payload.value.question;

			return [
				...state.slice(0, action.payload.index),
				{
					...block,
					question,
					...(_answers && {
						answers: _answers.map((answer) => ({
							_id: uuidv4(),
							value: answer,
						})),
					}),
				},
				...state.slice(action.payload.index + 1),
			];
		case "ADD_BLOCK":
			let answers = [{ _id: uuidv4(), value: "" }];

			if (action.payload.type === "textarea") {
				answers = null;
			}

			if (action.payload.type === "rating") {
				answers = [...Array(5)].map((el) => ({ _id: uuidv4(), value: "" }));
			}

			return [
				...state,
				{
					_id: action.payload.id,
					type: action.payload.type,
					question: "",
					...(answers && { answers }),
				},
			];
		case "REMOVE_BLOCK":
			return state.filter((block) => block._id !== action.payload.id);
		case "UPDATE_BLOCK_MANDATORY":
			return [
				...state.slice(0, action.payload.index),
				{ ...block, mandatory: action.payload.checked },
				...state.slice(action.payload.index + 1),
			];
		case "UPDATE_QUESTION":
			return [
				...state.slice(0, action.payload.index),
				{ ...block, question: action.payload.value },
				...state.slice(action.payload.index + 1),
			];
		case "ADD_ANSWER":
			return [
				...state.slice(0, action.payload.index),
				{
					...block,
					answers: [
						...block.answers.slice(0, action.payload.answerIndex + 1),
						{ _id: uuidv4(), value: "" },
						...block.answers.slice(action.payload.answerIndex + 1),
					],
				},
				...state.slice(action.payload.index + 1),
			];
		case "UPDATE_ANSWER":
			return [
				...state.slice(0, action.payload.index),
				{
					...block,
					answers: block.answers.map((answer, index) =>
						index === action.payload.answerIndex
							? { ...answer, value: action.payload.value }
							: answer
					),
				},
				...state.slice(action.payload.index + 1),
			];
		case "REMOVE_ANSWER":
			return [
				...state.slice(0, action.payload.index),
				{
					...block,
					answers: block.answers.filter(
						(answer, index) => index !== action.payload.answerIndex
					),
				},
				...state.slice(action.payload.index + 1),
			];
		case "MOVE_BLOCK":
			return arrayMove(state, action.payload.oldIndex, action.payload.newIndex);
		default:
			return state;
	}
};

export default blockReducer