import "./Stars.scss";

const Stars = ({ rating, align }) => {
  if (rating > 5) {
    rating = 5;
  } else if (rating < 1) {
    rating = 0;
  }

  const filledStars = Array(rating).fill(true);
  const unfilledStars = Array(5 - rating).fill(false);

  return (
    <div className={`stars-container${align ? ` stars-container_align_${align}` : ''}`}>
      {filledStars.map((_, index) => (
        <svg
          key={`filled-${index}`}
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
        >
          <path
            d="M15.1182 1.09357C14.9236 0.494715 14.0764 0.494713 13.8818 1.09357L11.2088 9.32011C11.1888 9.38192 11.1312 9.42376 11.0662 9.42376H2.41628C1.78661 9.42376 1.52481 10.2295 2.03422 10.5996L9.03214 15.6839C9.08471 15.7221 9.10671 15.7898 9.08663 15.8516L6.41367 24.0782C6.21909 24.677 6.9045 25.175 7.41391 24.8049L14.4118 19.7206C14.4644 19.6824 14.5356 19.6824 14.5882 19.7206L21.5861 24.8049C22.0955 25.175 22.7809 24.677 22.5863 24.0782L19.9134 15.8516C19.8933 15.7898 19.9153 15.7221 19.9679 15.6839L26.9658 10.5996C27.4752 10.2295 27.2134 9.42376 26.5837 9.42376H17.9338C17.8688 9.42376 17.8112 9.38192 17.7912 9.32012L15.1182 1.09357Z"
            fill="#FFC53D"
            stroke="#FFD666"
            strokeWidth="0.5"
          />
        </svg>
      ))}
      {unfilledStars.map((_, index) => (
        <svg
          key={`unfilled-${index}`}
          xmlns="http://www.w3.org/2000/svg"
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
        >
          <path
            d="M15.1182 1.09357C14.9236 0.494715 14.0764 0.494713 13.8818 1.09357L11.2088 9.32011C11.1888 9.38192 11.1312 9.42376 11.0662 9.42376H2.41628C1.78661 9.42376 1.52481 10.2295 2.03422 10.5996L9.03214 15.6839C9.08471 15.7221 9.10671 15.7898 9.08663 15.8516L6.41367 24.0782C6.21909 24.677 6.9045 25.175 7.41391 24.8049L14.4118 19.7206C14.4644 19.6824 14.5356 19.6824 14.5882 19.7206L21.5861 24.8049C22.0955 25.175 22.7809 24.677 22.5863 24.0782L19.9134 15.8516C19.8933 15.7898 19.9153 15.7221 19.9679 15.6839L26.9658 10.5996C27.4752 10.2295 27.2134 9.42376 26.5837 9.42376H17.9338C17.8688 9.42376 17.8112 9.38192 17.7912 9.32012L15.1182 1.09357Z"
            stroke="#FFC53D"
            strokeWidth="0.5"
          />
        </svg>
      ))}
    </div>
  )
}

export default Stars;