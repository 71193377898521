const LoginImage = props => {
  return (
    <img
      src={props.src}
      className="login-wrapper__image"
      alt=""
    />
  );
}

export default LoginImage;