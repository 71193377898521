import { CircleAlert } from "akar-icons";
import UiSmallRegular from "../text/UiSmallRegular";
import "./Error.scss";

const Error = props => {
  return (
    <div className="error-message mt-8">
      <CircleAlert
        size="22"
        color="#ff4d4f"
      />
      <UiSmallRegular text={props.message} />
    </div>
  );
}

export default Error;