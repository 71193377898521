export const activityData = [
	{
		date:"2024-07-02T13:18:50.973Z",
		email:"vladimir@anova.co",
		customerName: "Bethany Jenkins1",
		status:"paused",
		subscriberId:"9",
		_id:"2",
	},
	{
		date: "2024-07-02T13:18:50.973Z",
		email: "vladimir@anova.co",
		customerName: "Bethany Jenkins2",
		status: "active",
		subscriberId: "10",
		_id: "1",
	},
	{
		date: "2024-07-02T13:18:50.973Z",
		email: "vladimir@anova.co",
		customerName: "Bethany Jenkins3",
		status: "active",
		subscriberId: "11",
		_id: "3",
	},
	{
		date: "2024-07-02T13:18:50.973Z",
		email: "vladimir@anova.co",
		customerName: "Bethany Jenkins4",
		status: "paused",
		subscriberId: "12",
		_id: "4",
	},
]