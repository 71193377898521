import UiHeading from "../ui-elements/text/UiHeading";
import UiButton from "../ui-elements/buttons/UiButton";
import Illustration from "../static/images/onboarding/Illustration.svg";

const SyncCompleted = () => {
  const reload = () => {
    window.location.href = '/';
  }

  return (
    <div className="sync-completed">
      <UiHeading
        desktop="email-templates-h1-semibold"
        text="Store sync completed. Great job!"
      />
      <div className="mt-8 email-templates-paragraph-1-regular color-secondary-colour-secondary-7">Let’s start creating amazing automated emails to help you grow.</div>
      <div className="mt-32">
        <img
          src={Illustration}
          alt="Store sync completed. Great job!"
          className="responsive-img"
        />
      </div>
      <div className="mt-32 pt-6 pb-6">
        <UiButton
          design="secondary"
          size="medium"
          text="Let’s go!"
          className="ui-button_mw_150"
          onClick={reload}
        />
      </div>
    </div>
  );
}

export default SyncCompleted;