import { Calendar, Cross } from "akar-icons";
import { useState, useEffect, useContext } from "react";
import moment from "moment";
import axios from "axios";
import Modal from "react-modal";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { addDays, startOfMonth, endOfMonth, addMonths } from "date-fns";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import SupportForm from "../support/Form";
import UiHeading from "../ui-elements/text/UiHeading";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import UiButton from "../ui-elements/buttons/UiButton";
import SectionHead from "../ui-elements/common/SectionHead";
import UiBodyRegular from "../ui-elements/text/UiBodyRegular";
import Card from "./Card.js";
import CampaignPerformance from "./CampaignPerformance";
import TopSellers from "./TopSellers";
import Timeline from "./Timeline";
import Preloader from "../preloader/Preloader.js";
import { AuthContext } from "../context/AuthContext";
import illustration2 from "../static/images/dashboard/Illustration (2).svg";
import "./Performance.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../ui-elements/daterangepicker/DateRangePicker.scss";
import "../ui-elements/charts/ChartJsTooltip.scss";

Modal.setAppElement('#root');

export const getStaticRanges = () => {
  const today = new Date();
  
  return createStaticRanges([
    {
      label: 'Last 7 days',
      range: () => ({
        startDate: addDays(today, -6),
        endDate: today
      })
    },
    {
      label: 'Last 30 days',
      range: () => ({
        startDate: addDays(today, -29),
        endDate: today
      })
    },
    {
      label: 'This month',
      range: () => ({
        startDate: startOfMonth(today),
        endDate: today
      })
    },
    {
      label: 'Last month',
      range: () => ({
        startDate: startOfMonth(addMonths(today, -1)),
        endDate: endOfMonth(addMonths(today, -1))
      })
    }
  ])
}

const Performance = ({shop, showPerformance}) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [revenueLabels, setRevenueLabels] = useState([]);
  const [thisDatasetLabel, setThisDatasetLabel] = useState('This week');
  const [previousDatasetLabel, setPreviousDatasetLabel] = useState('Previous week');
  const [currentRevenue, setCurrentRevenue] = useState([]);
  const [currentRevenueLabels, setCurrentRevenueLabels] = useState([]);
  const [previousRevenue, setPreviousRevenue] = useState([]);
  const [previousRevenueLabels, setPreviousRevenueLabels] = useState([]);
  const [revenue, setRevenue] = useState({});
  const [newCustomers, setNewCustomers] = useState({});
  const [ordersSold, setOrdersSold] = useState({});
  const [itemsSold, setItemsSold] = useState({});
  const [orderValue, setOrderValue] = useState({});
  const [averageOrderItems, setAverageOrderItems] = useState({});
  const [averageCustomersOrders, setAverageCustomersOrders] = useState({});
  const [averageCustomerLtv, setAverageCustomerLtv] = useState({});
  const [filterLabel, setFilterLabel] = useState('Last 7 days');
  const [performanceLoader, setPerformanceLoader] = useState(true);
  const [isHoveringChart, setIsHoveringChart] = useState(false);
  const [supportSuccess, setSupportSuccess] = useState(false);
  const { user } = useContext(AuthContext);
  const performanceDisabled = (!user.subscriptionStatus && user.failedAttempts > 1);

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: shop.currency
  });

  const today = new Date();
  const yesterday = new Date(today);

  yesterday.setDate(yesterday.getDate() - 1);

  const [state, setState] = useState([{
    startDate: addDays(today, -6),
    endDate: today,
    key: 'selection'
  }]);

  const [performanceDateRange, setPerformanceDateRange] = useState([addDays(today, -6), today])
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  const dateFormattingOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  const staticRanges = getStaticRanges();
  const closeModal = () => setModalIsOpen(false);

  const getCardData = (value2, value1, isMoney) => {
    const previousValue = parseFloat(value1);
    let currentValue = parseFloat(value2);
    let diff = 0;
    let diffPlus = true;

    if (currentValue && !previousValue) {
      diff = 100;
    } else if (currentValue && previousValue) {
      diff = (((currentValue - previousValue) / previousValue) * 100).toFixed(1);
      diffPlus = currentValue >= previousValue;
    }

    if (isMoney) {
      currentValue = formatter.format(currentValue);
    }

    return {
      value: currentValue,
      diff: Math.abs(diff),
      diffPlus: diffPlus
    }
  }

  const dateFormatter = new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'short'
  });

  const setData = () => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }

    axios.get('/shop/performance', {
      params: {
        startDate: performanceDateRange[0].toLocaleDateString('en-CA', options),
        endDate: performanceDateRange[1].toLocaleDateString('en-CA', options)
      },
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    }).then(response => {
      let currentCurrentRevenue = [];
      let currentPreviousRevenue = [];
      let currentRevenueLabels = [];
      let currentRevLabels = [];
      let previousRevLabels = [];

      setTotalRevenue(response.data.current.totalRevenue);

      Object.keys(response.data.current.revenue).map(key => {
        currentCurrentRevenue.push(parseFloat(response.data.current.revenue[key]));
        currentRevenueLabels.push(dateFormatter.format(new Date(key)));
        currentRevLabels.push(moment(key, 'YYYY-MM-DD').format('Do, MMM'));

        return key;
      });

      Object.keys(response.data.previous.revenue).map(key => {
        currentPreviousRevenue.push(parseFloat(response.data.previous.revenue[key]));
        previousRevLabels.push(moment(key, 'YYYY-MM-DD').format('Do, MMM'));

        return key;
      });

      setCurrentRevenue(currentCurrentRevenue);
      setPreviousRevenue(currentPreviousRevenue);
      setCurrentRevenueLabels(currentRevLabels);
      setPreviousRevenueLabels(previousRevLabels);
      setRevenueLabels(currentRevenueLabels);
      setRevenue(getCardData(response.data.current.totalRevenue, response.data.previous.totalRevenue, true));
      setNewCustomers(getCardData(response.data.current.customers, response.data.previous.customers, false));
      setOrdersSold(getCardData(response.data.current.orders, response.data.previous.orders, false));
      setItemsSold(getCardData(response.data.current.items, response.data.previous.items, false));
      setOrderValue(getCardData(response.data.current.orderValue, response.data.previous.orderValue, true));
      setAverageOrderItems(getCardData(response.data.current.averageOrderItems, response.data.previous.averageOrderItems, false));
      setAverageCustomersOrders(getCardData(response.data.current.averageCustomerOrders, response.data.previous.averageCustomerOrders, false));
      setAverageCustomerLtv(getCardData(response.data.current.averageCustomerLtv, response.data.previous.averageCustomerLtv, true));
      setPerformanceLoader(false);
      setThisDatasetLabel(currentRevLabels.length === 1 ? currentRevLabels[0] : `${currentRevLabels[0]} - ${currentRevLabels[currentRevLabels.length - 1]}`);
      setPreviousDatasetLabel(previousRevLabels.length === 1 ? previousRevLabels[0] : `${previousRevLabels[0]} - ${previousRevLabels[previousRevLabels.length - 1]}`);
    }).catch(error => console.error(error));
  }

  const updateData = item => {
    setState([item.selection]);
    setPerformanceDateRange([item.selection.startDate, item.selection.endDate]);
  }

  const reloadPerformance = () => {
    setPerformanceLoader(true);
    setDatePickerVisible(false);
    setFilterLabel(`${performanceDateRange[0].toLocaleDateString('en-GB', dateFormattingOptions)} - ${performanceDateRange[1].toLocaleDateString('en-GB', dateFormattingOptions)}`);
    setData();
  }

  const externalTooltipHandler = context => {
    let tooltipEl = document.getElementById('chartjs-tooltip');
    const tooltipModel = context.tooltip;
    
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<table></table>';
      
      document.body.appendChild(tooltipEl);
    }
    
    const getBody = bodyItem => {
      return bodyItem.lines;
    }
    
    if (tooltipModel.body) {
      const bodyLines = tooltipModel.body.map(getBody);
      let innerHtml = '<tbody>';
  
      bodyLines.forEach((body, i) => {
        const splittedBody = body[0].split(':');

        if (splittedBody.length > 1) {
          innerHtml += '<tr>';
          innerHtml += '<td>';
          innerHtml += '<div class="chartjs-tooltip">';
          innerHtml += '<div class="chartjs-tooltip__label">';
          innerHtml += `<div class="chartjs-tooltip__box" style="background: ${tooltipModel.labelColors[i].backgroundColor}"></div>`;
          innerHtml += `<div class="chartjs-tooltip__label-value">${splittedBody[0]}</div>`;
          innerHtml += '</div>';
          innerHtml += `<div class="chartjs-tooltip__value">${splittedBody[1]}</div>`;
          innerHtml += '</div>';
          innerHtml += '</td>';
          innerHtml += '</tr>';
        }
      });
      
      innerHtml += '</tbody>';
  
      let tableRoot = tooltipEl.querySelector('table');
      
      tableRoot.innerHTML = innerHtml;
    }
    
    const position = context.chart.canvas.getBoundingClientRect();
    
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 73 + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 170 + 'px';
  }

  useEffect(() => {
    setData();
  }, []);

  useEffect(() => {
    const handleMouseMove = event => {
      const element = document.querySelector('.revenue-chart');

      if (element && !element.contains(event.target) && !isHoveringChart) {
        const tooltip = document.getElementById('chartjs-tooltip');

        if (tooltip) {
          tooltip.remove();
        }
      }
    }
  
    window.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isHoveringChart]);

  const handleFilterBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget) && datePickerVisible) {
      setDatePickerVisible(false)
    }
  }

  return (
    <>
      <div className="performance">
        <SectionHead
          title={
            <>
              <UiHeading
                desktop="other-headings-h5-bold"
                text="Your performance"
                elem="h5"
              />
              <UiCaptionRegular
                className="mt-8 color-secondary-colour-secondary-7"
                text="Current performance and activity for your campaigns."
              />
            </>
          }
          filter={
            <div tabIndex={0} onBlur={handleFilterBlur} >
              <UiButton
                size="small"
                design="secondary"
                className="ui-button_icon"
                disabled={!showPerformance || performanceDisabled}
                onClick={() => setDatePickerVisible(true)}
                text={
                  <>
                    <span className="ui-button__text">{filterLabel}</span>
                    <span className="ui-button__icon">
                      <Calendar
                        size="16"
                        color="#9254de"
                      />
                    </span>
                  </>
                }
              />
              {datePickerVisible ? (
                <div className="datepicker-wrapper card card_shadow card_br_24 card_24">
                  <DateRangePicker
                    onChange={item => updateData(item)}
                    showSelectionPreview={false}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    direction="horizontal"
                    showMonthAndYearPickers={false}
                    maxDate={today}
                    showDateDisplay={false}
                    staticRanges={staticRanges}
                    inputRanges={[]}
                    weekdayDisplayFormat="EEEEEE"
                  />
                  <div className="datepicker-wrapper__bottom">
                    <div className="datepicker-wrapper__dates color-secondary-colour-secondary-7">{performanceDateRange[0].toLocaleDateString('en-GB', dateFormattingOptions)} - {performanceDateRange[1].toLocaleDateString('en-GB', dateFormattingOptions)}</div>
                    <div className="button-group">
                      <UiButton
                        text="Cancel"
                        design="secondary"
                        size="small"
                        onClick={() => setDatePickerVisible(false)}
                      />
                      <UiButton
                        text="Apply"
                        size="small"
                        onClick={reloadPerformance}
                      />
                    </div>
                  </div>
                </div>
              ) : ('')}
            </div>
          }
        />
        {showPerformance ? (
          <div className="an-layout mt-24">
            <div className="an-row">
              <div className={`card card_24 card_shadow an-col an-col_50 card_flex card_br_24${performanceDisabled ? ' blured' : ''}`}>
                <div className="card__header">
                  <div>
                    <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7">Total revenue</div>
                    <UiHeading
                      element="h5"
                      desktop="other-headings-h5-semibold"
                      text={formatter.format(totalRevenue)}
                    />
                  </div>
                  <div className="revenue-legends">
                    <div className="ui-small-regular revenue-legend color-secondary-colour-secondary-7">{previousDatasetLabel}</div>
                    <div className="ui-small-regular revenue-legend revenue-legend_this color-secondary-colour-secondary-7">{thisDatasetLabel}</div>
                  </div>
                </div>
                <div
                  className="revenue-chart mt-24 card__body"
                  onMouseOver={() => setIsHoveringChart(true)}
                  onMouseOut={() => setIsHoveringChart(false)}
                >
                  <Line
                    data={{
                      labels: revenueLabels,
                      datasets: [
                        {
                          label: thisDatasetLabel,
                          data: currentRevenue,
                          borderColor: '#4bd4fb',
                          backgroundColor: '#4bd4fb',
                          tension: 0.4,
                          pointRadius: 0,
                          pointHoverRadius: 4,
                          borderWidth: 2,
                          labels: currentRevenueLabels
                        },
                        {
                          label: previousDatasetLabel,
                          data: previousRevenue,
                          borderColor: '#9254de',
                          backgroundColor: '#9254de',
                          tension: 0.4,
                          pointRadius: 0,
                          pointHoverRadius: 4,
                          borderWidth: 2,
                          labels: previousRevenueLabels
                        }
                      ]
                    }}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      interaction: {
                        mode: 'index',
                        intersect: false,
                      },
                      stacked: false,
                      plugins: {
                        legend: {
                          display: false
                        },
                        tooltip: {
                          enabled: false,
                          callbacks: {
                            label: tooltipItem => {
                              return `${tooltipItem.dataset.labels[tooltipItem.dataIndex]}:${formatter.format(tooltipItem.raw)}`;
                            }
                          },
                          external: externalTooltipHandler
                        }
                      },
                      animations: false,
                      scales: {
                        x: {
                          grid: {
                            display: false
                          },
                          ticks: {
                            color: '#bfbfbf',
                            font: {
                              family: 'Poppins',
                              size: 12,
                              weight: 500,
                              lineHeight: 1.75
                            }
                          }
                        },
                        y: {
                          border: {
                            dash: [3, 4]
                          },
                          grid: {
                            color: '#d9d9d9'
                          },
                          ticks: {
                            color: '#bfbfbf',
                            font: {
                              family: 'Poppins',
                              size: 12,
                              weight: 500,
                              lineHeight: 1.75
                            }
                          }
                        }
                      }
                    }}
                  />
                </div>
              </div>
              <div className={`an-layout an-col an-col_50${performanceDisabled ? ' blured blured_br_8' : ''}`}>
                <div className="an-row">
                  <Card
                    emoji="💷"
                    color="#f9f0ff"
                    data={revenue}
                    label="Revenue"
                  />
                  <Card
                    emoji="🙋🏻"
                    color="#caf3ff"
                    data={newCustomers}
                    label="New customers"
                  />
                </div>
                <div className="an-row">
                  <Card
                    emoji="📦"
                    color="#ffd8bf"
                    data={ordersSold}
                    label="Orders sold"
                  />
                  <Card
                    emoji="🧸"
                    color="#d9f7be"
                    data={itemsSold}
                    label="Items sold"
                  />
                </div>
                <div className="an-row">
                  <Card
                    emoji="💸"
                    color="#ffd6e7"
                    data={orderValue}
                    label="Order value"
                  />
                  <Card
                    emoji="🛒"
                    color="#ffffb8"
                    data={averageOrderItems}
                    label="Average order items"
                  />
                </div>
                <div className="an-row">
                  <Card
                    emoji="🛍"
                    color="#b5f5ec"
                    data={averageCustomersOrders}
                    label="Average customers orders"
                  />
                  <Card
                    emoji="⭐️"
                    color="#d6e4ff"
                    data={averageCustomerLtv}
                    label="Average customer LTV"
                  />
                </div>
              </div>
            </div>
            {performanceLoader ? (
              <Preloader
                overflow={true}
                className="preloader_br_24"
              />
            ) : ''}
          </div>
        ) : (
          <div className="card mt-24 text-center pt-102 pb-102">
            <UiHeading
              desktop="other-headings-h4-bold"
              elem="h4"
              text="Finish your setup"
            />
            <UiBodyRegular
              text="Once you have campaigns running, you will se their performance and highlights here"
              className="mt-8"
            />
            <div className="mt-32">
              <img
                src={illustration2}
                alt="Finish your setup"
                className="mt-32 responsive-img"
              />
            </div>
            <div className="mt-32">
              <UiButton
                design="secondary"
                size="small"
                text="Got a question?"
                className="pl-13 pr-13"
                onClick={() => setModalIsOpen(true)}
              />
            </div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              className="an-modal"
              overlayClassName="an-overlay"
            >
              <div className="an-modal__head">
                <div className="an-modal__title">
                  {!supportSuccess && (
                    <>
                      <UiHeading
                        element="h6"
                        desktop="other-headings-h6-bold"
                        text="How can we help you?"
                      />
                      <UiCaptionRegular
                        className="mt-8"
                        text="Choose a topic from the dropdown menu, and our team will promptly assist you."
                      />
                    </>
                  )}
                </div>
                <button
                  onClick={closeModal}
                  className="an-modal__close"
                >
                  <Cross
                    size="24"
                    color="#d9d9d9"
                  />
                </button>
              </div>
              <div className="an-modal__body mt-32">
                <SupportForm
                  closeModal={closeModal}
                  onSuccess={() => setSupportSuccess(true)}
                />
              </div>
            </Modal>
          </div>
        )}
      </div>
      {showPerformance ? (
        <>
          <CampaignPerformance performanceDisabled={performanceDisabled} />
          <div className="an-row">
            <TopSellers
              currency={shop.currency}
              className="an-col an-col_50"
              performanceDisabled={performanceDisabled}
            />
            <Timeline
              className="an-col an-col_50"
              performanceDisabled={performanceDisabled}
            />
          </div>
        </>
      ) : ('')}
    </>
  );
}

export default Performance;