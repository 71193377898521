import { Copy } from "akar-icons";
import "./CopyText.scss";

const CopyText = props => {
  const copy = async () => {
    try {
      await navigator.clipboard.writeText(props.text);
    } catch (err) {
      console.error(err);
    }
  }
  
  return (
    <div className="copy-text">
      {props.elem}
      <button
        className="copy-text__button"
        onClick={copy}
      >
        <Copy
          size="16"
          color="#d9d9d9"
        />
      </button>
    </div>
  )
}

export default CopyText;