import { useEffect, useState } from "react";
import IntroItem from "./IntroItem";
import PlaceholderIcon from "../static/images/onboarding/PlaceholderIcon.svg";
import PlaceholderIcon1 from "../static/images/onboarding/PlaceholderIcon (1).svg";
import PlaceholderIcon2 from "../static/images/onboarding/PlaceholderIcon (2).svg";
import "./IntroList.scss";

const IntroList = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([
      {
        icon: PlaceholderIcon,
        caption: 'Getting Started',
        text: 'Answer questions and sync your store'
      },
      {
        icon: PlaceholderIcon1,
        caption: 'Why Is This Important?',
        text: 'Customise Anomate to suit your brand ethos and needs'
      },
      {
        icon: PlaceholderIcon2,
        caption: 'Leaving Before Finishing',
        text: 'Don’t worry! <br />Your progress will be saved'
      }
    ]);
  }, []);

  return (
    <div className="onboarding-intro-list pt-24 pb-24">
      {data.map((item, key) => (
        <IntroItem
          item={item}
          key={key}
        />
      ))}
    </div>  
  )
}

export default IntroList;