import React from "react";
import styles from "./SurveyWorkspace.module.scss";
import classNames from "classnames";
import CustomizationPlaceholder from "../customization-placeholder/CustomizationPlaceholder";
import UiButton from "../../buttons/UiButton";
import { CirclePlus } from "akar-icons";

const SurveyWorkspace = ({
  className,
  children,
  showPlaceholder,
  hasAddButton,
  disabledAdd,
  onAdd,
}) => {
  return (
    <div className={classNames(styles["base"], "scrollbar", className)}>
      <div className={styles["header"]}>
        <div>
          <div className="ui-body-medium">Build your survey</div>
          <p className="ui-small-regular m-0 color-secondary-colour-secondary-7">
            Fine-tune your question blocks here to collect meaningful customer
            feedback.
          </p>
        </div>

        {hasAddButton && (
          <div className={styles["add-button"]}>
            <UiButton
              type="button"
              size="medium"
              design="secondary"
              hasIcon
              disabled={disabledAdd}
              text={
                <>
                  Add question <CirclePlus />
                </>
              }
              onClick={onAdd}
            />
          </div>
        )}
      </div>

      <div className={styles["body"]}>
        {showPlaceholder && (
          <CustomizationPlaceholder className={styles["placeholder"]} />
        )}
        {!showPlaceholder && children}
      </div>
    </div>
  );
};

export default SurveyWorkspace;
