const StockImage = props => {
  return (
    <img
      className="responsive-img"
      src={props.image.src.large}
      onClick={() => props.onClick(props.image.src.large2x)}
      alt=""
    />
  )
}

export default StockImage;