import { useLayoutEffect, useState } from "react";
import useStep from "../hooks/useStep";
import StepWizardNavigation from "../ui-elements/step-wizard/StepWizardNavigation";
import WidgetLayout from "./WidgetLayout";

const LayoutSelectionStep = () => {
  const {
    currentStepValue,
    handlePrev,
    handleNext,
    stepData,
    currentStep,
    setStepTitle,
  } = useStep();
  const [value, setValue] = useState(currentStepValue);

  useLayoutEffect(() => {
    const stepTitle = currentStep.title.replace(
      `{widgetName}`,
      stepData.widget.name.toLowerCase()
    );
    setStepTitle(stepTitle);
  }, [stepData, currentStep]);

  const handleSubmit = (e) => {
    e.preventDefault();

    handleNext(value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="widget-layouts">
        {stepData.widget.layouts.map((layout, key) => (
          <WidgetLayout
            onClick={() => setValue(key + 1)}
            layout={layout}
            key={key}
            widget={stepData.widget}
            active={value && key + 1 === value}
          />
        ))}
      </div>
      <StepWizardNavigation
        onPrev={handlePrev}
        nextDisabled={!value || typeof value !== "number"}
      />
    </form>
  );
};

export default LayoutSelectionStep;
