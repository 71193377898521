import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import illustration from "../static/images/widgets/Illustration.svg";
import UiHeading from "../ui-elements/text/UiHeading";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import FormControl from "../ui-elements/forms/FormControl";
import { Cross } from "akar-icons";
import UiButton from "../ui-elements/buttons/UiButton";
import Preloader from "../preloader/Preloader";

Modal.setAppElement("#root");

export const CampaignSaveModal = (props) => {
  const [value, setValue] = useState(props.value || "");
  const [error, setError] = useState(props.error || null);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const saveButtonText = props.isEdit ? "Update" : "Save";

  const handleSave = () => {
    if (!value) {
      setError({ message: "This is mandatory" });
      return;
    }

    props.onSave && props.onSave(value);
    setError(null);
  };

  return (
    <Modal
      isOpen={props.open}
      onRequestClose={props.onClose}
      className="an-modal"
      overlayClassName="an-overlay"
    >
      <div className="an-modal__head">
        <div className="an-modal__title">
          <img src={illustration} alt="" className="responsive-img" />
          <UiHeading
            element="h6"
            desktop="other-headings-h6-bold"
            text="How would you like to name your campaign?"
            margin="24"
          />
          <UiCaptionRegular
            className="mt-8 color-secondary-colour-secondary-7"
            text="Give your campaign a descriptive and meaningful name to help you quickly identify and locate it later on."
          />
          <FormControl
            name="name"
            type="text"
            id="name"
            placeholder="Enter campaign name*"
            margin="24"
            validationError={error}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
        </div>
        <button onClick={props.onClose} className="an-modal__close">
          <Cross size="24" color="#d9d9d9" />
        </button>
      </div>
      <div className="an-modal__body an-modal__body_mh_0 text-left">
        <div className="mt-40 text-right">
          <div className="button-group">
            <UiButton
              size="medium"
              design="secondary"
              text="Cancel"
              onClick={props.onClose}
            />
            <UiButton size="medium" text={saveButtonText} onClick={handleSave} />
          </div>
        </div>
      </div>
      {props.loading ? <Preloader absolute={true} overflow={true} /> : ""}
    </Modal>
  );
};
