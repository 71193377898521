import { Table, Header, HeaderRow, Body, Row, Cell, HeaderCell } from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import AnTable from "../ui-elements/common/AnTable";
import SenderAddressItem from "./SenderAddressItem";
import UiButton from "../ui-elements/buttons/UiButton";

const SenderAddress = props => {
  const nodes = props.emails;

  const theme = useTheme([getTheme(), {
    Table: `
      --data-table-library_grid-template-columns: repeat(2, minmax(0, 1fr)) 158px 42px
    `
  }]);

  return (
    <div className="card card_shadow card_24 card_overflow_visible">
      {props.emails.length ? (
        <AnTable
          body={
            <Table
              data={{ nodes }}
              theme={theme}
              layout={{
                custom: true
              }}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell>Email Address</HeaderCell>
                      <HeaderCell>Verification Status</HeaderCell>
                      <HeaderCell />
                      <HeaderCell />
                    </HeaderRow>
                  </Header>
                  <Body>
                    {tableList.map(item => (
                      <SenderAddressItem
                        key={item._id}
                        item={item}
                        refresh={props.refresh}
                      />
                    ))}
                  </Body>
                </>
              )}
            </Table>
          }
          className="an-table_overflow_visible an-table_mh_72"
        />
      ) : ''}
      <div className="mt-32 text-right">
        <UiButton
          design="secondary"
          elem="a"
          href="/store-settings/domain-accounts/email/add"
          text="Add new email"
          size="medium"
        />
      </div>
    </div>
  )
}

export default SenderAddress;