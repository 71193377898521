import styles from "./GenerateButton.module.scss";

const GenerateButton = props => {
  const generate = e => {
    e.preventDefault();
    props.onClick(props.field);
  }

  return (
    <div className="mt-4">
      <button className={styles['generate-button']} onClick={generate} disabled={props.disabled}>Generate for me</button>
    </div>
  )
}

export default GenerateButton;