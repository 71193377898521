import React from "react";
import TabNavBar from "../../ui-elements/tab-nav-bar/TabNavBar";
import { Outlet, useLocation } from "react-router-dom";

const BrandAssetsPage = () => {
  const { pathname } = useLocation();

  const tabNavItems = () => {
    const pathArr = pathname.slice(1).split("/");

    return [
      {
        label: "Brand information",
        to: ``,
        active: pathArr.length < 2,
      },
      {
        label: "Brand identity",
        to: `brand-identity`,
        active: pathArr.includes("brand-identity"),
      },
      {
        label: "Common layouts",
        to: `common-layouts`,
        active: pathArr.includes("common-layouts"),
      },
    ];
  };

  return (
    <>
      <TabNavBar items={tabNavItems()} />
      <Outlet />
    </>
  );
};

export default BrandAssetsPage;
