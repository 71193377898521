import React, { useState, useEffect } from "react";

import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";

import CurrentPackageData from "../../billing/CurrentPackageData";
import PackagesList from "../../billing/PackagesList";

import { getPackages } from "../../api/Package";
import { getCampaigns } from "../../api/Campaign";
import { getServices } from "../../api/Service";
import { getUserInvoice } from "../../api/Auth";

import Preloader from "../../preloader/Preloader";
import MainContent from "../../dashboard/MainContent";

import useAuth from "../../hooks/useAuth";
import useCampaign from "../../hooks/useCampaign"

const PackagesPage = () => {
  const { currentPackage, updateShopData, user, setUser } = useAuth();
  const { campaigns } = useCampaign();
  const [packages, setPackages] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const promises = [getPackages(), getServices(), getUserInvoice()];
      const responses = await Promise.all(
        promises.map((p) => p.catch((e) => e))
      );
      setPackages(responses[0]);
      setServices(responses[1]);
      setUpcomingInvoice(responses[2]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const updateCurrentPackage = async (packageId) => {
    setUser({...user, packageId})
    await updateShopData();
    fetchData();
  };

  return (
    <MainContent>
      <div className="an-layout">
        <SectionHead
          title={
            <>
              <UiHeading
                text="Your next invoice"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                Discover details about your upcoming invoice, including billing
                cycle and estimated charge. Additional charges will apply for
                extra emails sent beyond your package quota. Extra charges for
                the current period will be included in the upcoming billing
                cycle.
              </div>
            </>
          }
        />
        {currentPackage && upcomingInvoice && (
          <CurrentPackageData
            package={currentPackage}
            invoice={upcomingInvoice}
          />
        )}
        <div className="mt-8">
          <SectionHead
            align="center"
            title={
              <>
                <UiHeading
                  element="h2"
                  text="Manage your plan"
                  desktop="email-templates-subtitle-2-bold"
                />
                <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                  Effortlessly switch between plans and customise your
                  subscription to align with your business needs. Charges for
                  the new plan will take effect in the next billing cycle, while
                  extra email charges for the current period will be applied in
                  the upcoming period. Although campaigns not covered by your
                  new plan will be deactivated, rest assured that you will
                  retain access to your valuable data.
                </div>
              </>
            }
          />
        </div>
        <PackagesList
          currentPackage={currentPackage}
          packages={packages}
          campaigns={campaigns}
          services={services}
          setCurrentPackageId={(id) => updateCurrentPackage(id)}
        />
      </div>
    </MainContent>
  );
};

export default PackagesPage;
