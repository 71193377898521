import classNames from "classnames";
import { CircleX, CircleCheck } from "akar-icons";
import styles from "./VerificationStatus.module.scss";

const VerificationStatus = props => {
  return (
    <div className={classNames(styles['verification-status'], {
      [`${styles['verified']}`]: props.verfied,
      [`${styles['not-verified']}`]: !props.verified,
    })}
    >
      {props.status ? (
        <>
          <CircleCheck
            size="16"
            color="#52c41a"
          />
          <div className={classNames(styles['verification-status__label'], 'color-polar-green-green-6')}>Verified</div>
        </>
      ) : (
        <>
          <CircleX
            size="16"
            color="#d4b106"
          />
          <div className={classNames(styles['verification-status__label'], 'color-sunrise-yellow-yellow-7')}>Not verified</div>
        </>
      )}
    </div>
  )
}

export default VerificationStatus;