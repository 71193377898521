import { useEffect, useState } from "react";
import TotalEmailsSent from "./TotalEmailsSent";
import ActivityTable from "../../ui-elements/activity-table/ActivityTable";
import CampaignPerformance from "./CampaignPerformance";
import KPIStats from '../../ui-elements/kpi-stats/KPIStats';

// import { getCampaignLocationStats, getCampaignTimeline } from "../../api/Campaign";
import "./Overview.scss";

const CommonSingleOverview = ({ campaigns, slug }) => {
  const [stats, setStats] = useState(null)
  // const [locationStats, setLocationStats] = useState([]);
  // const [timeline, setTimeline] = useState([]);
  const campaignName = 'Back in stock';

  useEffect(() => {
    const { totalRevenue, totalOrders, emailsSent, couponsUsed, opened, clicked, purchased, unsubscribed } = campaigns[0]
    setStats({
      totalRevenue, 
      totalOrders,
      emailsSent, 
      couponsUsed, 
      opened, 
      clicked, 
      purchased, 
      unsubscribed
    })
    
    
    // fetchData();
  }, [campaigns]);

  // const fetchData = async () => {
  //   setLocationStats(await getCampaignLocationStats(slug));
  //   setTimeline(await getCampaignTimeline(slug));
  // }

  return (
    <div className="an-layout">
      <div className="an-row an-row_gap_8">
        {stats &&
          <KPIStats 
            stats={stats}
            indicators={{
              totalRevenue: {
                type: 'money',
                label: 'Total revenue'
              },
              totalOrders: 'Total orders',
              emailsSent: 'Total emails sent',
              couponsUsed: 'Coupons used',
            }}
          />
        }
      </div>
      {!!stats?.emailsSent && (
        <div className="an-row">
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
            <TotalEmailsSent campaigns={campaigns} />
          </div>
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
            <CampaignPerformance
              totalEmailsSent={stats.emailsSent}
              purchased={stats.purchased}
              opened={stats.opened}
              clicked={stats.clicked}
              unsubscribed={stats.unsubscribed}
            />
          </div>
        </div>
      )}
      <ActivityTable
        slug={slug}
        type="campaign-activity"
        shopCampaignId={campaigns[0]._id}
        testEmail={true}
      />
    </div>
  )
}

export default CommonSingleOverview;