import { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Row, Cell } from "@table-library/react-table-library";
import axios from "axios";
import VerificationStatus from "../ui-elements/verification-status/VerificationStatus";
import { MoreVerticalFill } from "akar-icons";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./DomainStatus.module.scss";

const SenderAddressItem = props => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [verificationDisabled, setVerificationDisabled] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const setAsDefault = () => {
    axios.post(`/domains/emails/${props.item._id}/default`, {}, {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    }).then(() => {
      props.refresh();
    }).catch(err => {
      console.error(err);
    })
  }

  const deleteEmail = () => {
    setShowDropdown(false);

    axios.delete(`/domains/emails/${props.item._id}`, {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    }).then(() => {
      props.refresh();
    }).catch(err => console.error(err));
  }

  const verify = () => {
    setVerificationDisabled(true);

    axios.get(`/domains/emails/${props.item._id}/verify`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      setVerificationDisabled(false);
      navigate(`/store-settings/domain-accounts/email/${props.item._id}/verify`);
    }).catch(err => {
      console.error(err);

      setVerificationDisabled(false);
    });
  }

  return (
    <Row
      item={props.item}
      key={props.item._id}
    >
      <Cell>
        <NavLink to={`/store-settings/domain-accounts/email/${props.item._id}`} className="underline">{props.item.email}</NavLink>
      </Cell>
      <Cell>
        <VerificationStatus status={props.item.verified} />
      </Cell>
      <Cell>
        {props.item.verified ? (
          <>
            {props.item.default ? <button className={styles['blue-btn']}>Default</button> : <button onClick={setAsDefault} className={styles['blue-btn']}>Set as default</button>}
          </>
        ) : <button onClick={verify} disabled={verificationDisabled} className={styles['blue-btn']}>Verify</button>}
      </Cell>
      <Cell>
        <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
          <button
            className={styles['remove-button']}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <MoreVerticalFill
              size="16"
              color="#d9d9d9"
            />
          </button>
          {showDropdown ? (
            <div className="dropdown">
              <NavLink className="dropdown__item ui-caption-regular" to={`/store-settings/domain-accounts/email/${props.item._id}`}>Edit email</NavLink>
              <button className="dropdown__item ui-caption-regular"  onClick={deleteEmail}>Delete email</button>
            </div>
          ) : ''}
        </OutsideClickHandler>
      </Cell>
    </Row>
  );
}

export default SenderAddressItem;