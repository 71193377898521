import UiHeading from "../ui-elements/text/UiHeading";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import UiButton from "../ui-elements/buttons/UiButton";
import ntWorkingWomanOnLaptop11 from "../static/images/store-settings/nt-working-woman-on-laptop 1 (1).svg";

const NoDomain = () => {
  return (
    <div className="card card_h_100 card_flex card_align_center card_br_24 card_24 card_shadow campaign-not-included text-center">
      <UiHeading
        text="Let’s add your domain!"
        desktop="other-headings-h6-bold"
      />
      <UiCaptionRegular
        margin="8"
        text="Once verified, you can use the domain as the sender address for your email campaigns, which can improve deliverability and brand recognition."
        className="maxw maxw_601 color-secondary-colour-secondary-7"
      />
      <div className="mt-32">
        <img
          src={ntWorkingWomanOnLaptop11}
          alt="Let’s add your domain!"
          className="responsive-img"
        />
      </div>
      <div className="mt-32">
        <UiButton
          elem="a"
          href="/store-settings/domain-accounts/add"
          text="Get started"
          size="medium"
          design="secondary"
          className="ui-button_mw_150"
        />
      </div>
    </div>
  )
}

export default NoDomain;