import { useState } from 'react'

import Legend from '../../../campaigns/overviews/Legend'
import ToolsPerformance from '../ToolsPerformance'

import Toogler from '../../../ui-elements/toogler/Toogler'
import CampaignIcon from '../../../ui-elements/common/CampaignIcon'
import UiCaptionRegular from '../../../ui-elements/text/UiCaptionRegular'
import UiHeading from '../../../ui-elements/text/UiHeading'
import Divider from '../../../ui-elements/divider/Divider'
import UiCaptionMedium from '../../../ui-elements/text/UiCaptionMedium'

const colors = ['#9254de', '#4bd4fb', '#d3adf7', '#85a5ff', '#ff7875']

const ConsentManagerCampaignPerformance = ({ data }) => {
	const [type, setType] = useState('day')

	return (
		<ToolsPerformance 
			title="Campaign performance"
			subTitle="Email performance for your selected dates."
			data={data[type] ?? null}
			type={type}
			yAxis={{
        min: 0
      }}
			filter={
        <Toogler
          items={[
            { value: 'day', text: 'Day' },
            { value: 'week', text: 'Week' },
            { value: 'month', text: 'Month' },
          ]}
          defaultStatus={type}
          onChange={(value) => setType(value)}
        />
      }
			Legend={() => {
				return (
					<>
						<div className='icon-wrapper'>
							<div>
								<CampaignIcon emoji={'📨'} />
							</div>
							<div>
								<UiCaptionRegular
									className=""
									text="Total email sends"
								/>
								<UiHeading
									element="h6"
									desktop="other-headings-h6-bold"
									text={data?.totalEmailSends ?? 0}
								/>
							</div>
						</div>
						{!!data?.legends && (
							<>
								<Divider className="mt-16 mb-16" />
								<div className='legends'>
									<UiCaptionMedium text="Overall performance" />
									{data.legends.map((stat, index) =>
										<Legend
											key={index}
											label={stat.label}
											value={stat.value}
											total={100}
											color={colors[index]}
											showTotal={false}
										/>
									)}
								</div>
							</>
						)}
					</>
				)
			}}
		/>
	)
}

export default ConsentManagerCampaignPerformance