import { ChevronDown } from "akar-icons";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import ComingSoon from "../ui-elements/common/ComingSoon";
import "./NavItem.scss";

const NavItem = (props) => {
  return (
    <li className="nav-item">
      <NavLink
        to={props.item.url}
        className={({ isActive }) =>
          `nav-item__link ui-caption-regular ${
            isActive ? "nav-item__link_active" : ""
          } ${
            props.item.subMenu && props.item.subMenu.length
              ? " nav-item__has-subnav"
              : ""
          }`
        }
      >
        <div className="nav-item__text-wrapper">
          {props.item.icon}
          <span className="nav-item__text">{props.item.text}</span>
        </div>
        {props.item.subMenu && props.item.subMenu.length ? (
          <div className="nav-item__chevron">
            <ChevronDown size="16" color="#ffffff" />
          </div>
        ) : (
          ""
        )}
      </NavLink>

      {props.item.subMenu && props.item.subMenu.length ? (
        <ul className="nav-item__subnav mt-4">
          {props.item.subMenu.map((item, key) => (
            <li className="nav-item__subitem" key={key}>
              <NavLink
                to={item.url}
                className={({ isActive }) =>
                  classNames(`nav-item__sublink ui-small-regular`, {
                    [`nav-item__sublink_active`]: isActive,
                  })
                }
              >
                <span className="nav-item__text">{item.text}</span>
                {item?.comingSoon && <ComingSoon />}
              </NavLink>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
    </li>
  );
};

export default NavItem;
