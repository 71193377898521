import Header from "../header/Header";
import AnCaption from "../ui-elements/text/AnCaption";
import UiHeading from "../ui-elements/text/UiHeading";
import LoginImage from "./LoginImage";
import LoginMobileImage from "./LoginMobileImage";
import UiBodyRegular from "../ui-elements/text/UiBodyRegular";
import WithAuthorization from "./WithAuthorization";
import SignUpForm from "./SignUpForm";
import image from "../static/images/sign-up/Image.svg";
import ecGlobalConnunication from "../static/images/sign-up/ec-global-communication.svg";
import "./Login.scss";

const SignUp = props => {
  return (
    <div>
      <Header />
      <div className="login-wrapper">
        <div className="container">
          <div className="login-wrapper__inner">
            <div className="login-wrapper__content login-wrapper__content_sign-up">
              <AnCaption text="Sign Up" />
              <UiHeading
                element="h1"
                desktop="h2-lg"
                mobile="h3-lg"
                text="Let’s get started today"
                margin="4"
              />
              <UiBodyRegular
                text="Boost your e-Commerce revenue with smart, autonomous marketing."
                margin="24"
              />
              <SignUpForm />
            </div>
            <LoginImage src={image} />
          </div>
        </div>
        <LoginMobileImage src={ecGlobalConnunication} />
      </div>
    </div>
  );
};

export default WithAuthorization(SignUp, true, '/');