import { useState, useEffect } from "react";
import axios from "axios";
import SectionHead from "../ui-elements/common/SectionHead";
import TimelineItem from "./TimelineItem";
import "./Timeline.scss";

const Timeline = props => {
  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    axios.get('/shop/timeline', {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    }).then(response => {
      setTimeline(response.data.map(item => {
        return {
          ...item,
          createdAt: new Date(item.createdAt)
        }
      }));
    }).catch(e => console.error(e));
  }, []);

  return (
    <>
      {timeline.length ? (
        <div className={`card card_24 card_br_24 card_shadow ${props.className}${props.performanceDisabled ? ' blured' : ''}`}>
          <SectionHead
            title={
              <>
                <h5 className="email-templates-subtitle-2-medium">Timeline</h5>
                <div className="mt-4 email-templates-caption-regular color-secondary-colour-secondary-4">Latest activity from your customers.</div>
              </>
            }
          />
          <div className="mt-24 timeline">
            {timeline.map((item, key) => (
              <TimelineItem
                key={key}
                item={item}
              />
            ))}
          </div>
        </div>
      ) : ('')}
    </>
  )
}

export default Timeline;