import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { 
    getCustomerSurveyQuestions, 
    getProductReviewQuestions, 
    getCampaignCustomizer,
	getPromoCampaignCustomizer
} from "../../api/Gpt"

const getDate = (str, year) => {
	const now = new Date()
	const currentYear = now.getFullYear();
	return new Date(`${str} ${year ?? currentYear}`)
}

export const getAIData = async (data, slug) => {
	const { id } = data
	if (slug === 'customer-survey') {
		const response = await getCustomerSurveyQuestions(id);
		const questions = response.reduce((acc, question) => {
			question._id = uuidv4();
			if (question.answers)
				question.answers = question.answers.map((answer) => ({
					value: answer,
					_id: uuidv4(),
				}));
			return acc.concat(question);
		}, []);
		return ({builder: questions})
	}

	if (slug === 'product-review') {
		const response = await getProductReviewQuestions();
		const questions = response.reduce((acc, question) => {
			question._id = uuidv4();
			
			if (question.answers) {
				question.answers = question.answers.map((answer) => ({
					value: answer,
					_id: uuidv4(),
				}));
			}

			return acc.concat(question);
		}, []);
		return ({reviewBuilder: { blocks: questions }})
	}

	if (slug === 'promo-campaign') {
		const { campaignId, holiday } = data
		const { startDate, endDate } = holiday
		let start = getDate(startDate)
		let end = getDate(endDate)
		let now = new Date()

		if (start < now) {
			start = getDate(startDate, now.getFullYear() + 1)
		}

		if (start > end) {
			end = getDate(endDate, start.getFullYear() + 1)
		}

		const response = await getPromoCampaignCustomizer(campaignId, holiday._id)
		return ({
			customizer: response, 
			promoSetup: {
				datesAndTime: {
					datesAndTime: {
						startDate: moment(start).format(),
						endDate: moment(end).format(),
					}
				}
			}})
	}

	const response = await getCampaignCustomizer(id);
	return ({customizer: response})

}