import { useState, useEffect } from "react";
import FormControl from "../../ui-elements/forms/FormControl";
import BasePill from "../../ui-elements/buttons/BasePill";
import './Filters.scss';

const CreationDateFilter = ({ onChange, filters }) => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [currentFilter, setCurrentFilter] = useState(null);
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  useEffect(() => {
    setFrom('');
    setTo('');

    filters.map(filter => {
      if (filter._id === 'olderThan') {
        setTo(filter.value);
      } else if (filter._id === 'newerThan') {
        setFrom(filter.value);
      }
    });
  }, [filters]);

  const handleChange = (name, value) => {
    let updatedFilters = filters;
    const index = filters.findIndex(item => item._id === name);

    if (index !== -1) {
      updatedFilters.splice(index, 1);
    }

    if (value) {
      updatedFilters = [...filters, {
        value: value,
        _id: name,
        name: `${name == 'newerThan' ? 'Newer than' : 'Older than'} ${months[value.getMonth()]} ${value.getDate()}, ${value.getFullYear()}`
      }];
    }

    if (name === 'olderThan') {
      setTo(value);
    } else if (name === 'newerThan') {
      setFrom(value);
    }
    
    onChange('date', updatedFilters);
  }

  const showOlderThan = e => {
    e.preventDefault();

    setCurrentFilter('olderThan');
  }

  const showNewerThan = e => {
    e.preventDefault();

    setCurrentFilter('newerThan');
  }

  return (
    <>
      <div className="email-templates-caption-medium">Select a rule</div>
      <div className="an-row an-row_gap_8 mt-4 pb-8 pt-4">
        <div className="an-col">
          <BasePill
            text="Older than"
            onClick={showOlderThan}
            active={currentFilter === 'olderThan'}
          />
        </div>
        <div className="an-col">
          <BasePill
            text="Newer than"
            onClick={showNewerThan}
            active={currentFilter === 'newerThan'}
          />
        </div>
      </div>
      {currentFilter === 'olderThan' && (
        <div className="pt-16 pb-16">
          <FormControl
            label="Select your date"
            labelClassName="email-templates-caption-medium"
            labelMargin="4"
            type="date"
            name="olderThan"
            id="older_than"
            placeholder="Month / Day / Year"
            onChange={handleChange}
            maxDate={new Date()}
            value={to}
          />
        </div>
      )}
      {currentFilter === 'newerThan' && (
        <div className="pt-16 pb-16">
          <FormControl
            label="Select your date"
            labelClassName="email-templates-caption-medium"
            labelMargin="4"
            type="date"
            name="newerThan"
            id="newer_than"
            placeholder="Month / Day / Year"
            onChange={handleChange}
            maxDate={to ? new Date(to) : null}
            value={from}
          />
        </div>
      )}
    </>
  )
}

export default CreationDateFilter;