import axios from "axios";

export const uploadFile = (file, message) => {
  const requestBody = new FormData();

  requestBody.append('file', file);
  requestBody.append('message', message);

  return new Promise((resolve, reject) => {
    axios.post(`/slack/upload`, requestBody, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const postMessage = async (message) => {
  return new Promise((resolve, reject) => {
    axios.post(`/slack/post`, { message }, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// export const uploadFile = async (token, channel, file, initialComment) => {
//   const requestBody = new FormData();

//   requestBody.append('token', token);
//   requestBody.append('filename', file.name);
//   requestBody.append('length', file.size);

//   const uploadResponse = await fetch('https://slack.com/api/files.getUploadURLExternal', {
//     method: 'POST',
//     body: requestBody,
//   });

//   const uploadBody = await uploadResponse.json();

//   if (!uploadBody.ok) {
//     throw new Error(JSON.stringify(uploadBody));
//   }

//   const upload = await fetch(uploadBody.upload_url, {
//     method: 'PUT',
//     headers: {
//       'Content-Type': 'application/octet-stream'
//     },
//     body: file
//   });

//   if (!upload.ok) {
//     throw new Error(JSON.stringify(upload));
//   }

//   const completeUploadBody = new FormData();

//   completeUploadBody.append('token', token);

//   completeUploadBody.append('files', JSON.stringify([
//     {
//       id: uploadBody.file_id,
//       title: file.name
//     }
//   ]));

//   completeUploadBody.append('channel_id', channel);
//   completeUploadBody.append('initial_comment', initialComment);
  
//   const completeUpload = await fetch('https://slack.com/api/files.completeUploadExternal', {
//     method: 'POST',
//     body: completeUploadBody
//   });

//   const completeUploadResponse = await completeUpload.json();

//   if (!completeUploadResponse.ok) {
//     throw new Error(JSON.stringify(completeUploadResponse));
//   }
// }

// export const postMessage = async (token, channel, message) => {
//   const requestBody = new FormData();
  
//   requestBody.append('token', token);
//   requestBody.append('channel', channel);
//   requestBody.append('text', message);

//   const response = await fetch('https://slack.com/api/chat.postMessage', {
//     method: 'POST',
//     body: requestBody
//   });

//   const responseBody = await response.json();

//   console.log(responseBody);

//   if (!responseBody.ok) {
//     throw new Error(JSON.stringify(responseBody));
//   }
// }
