import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import FormControl from "../../forms/FormControl";
import { useFormik } from "formik";
import * as yup from "yup";

const CouponType = forwardRef((props, ref) => {
  const initialValues = {
    type: props.value,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      type: yup.string().required("This is mandatory"),
    }),
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(false);
      props.onSubmit && props.onSubmit(values.type);
    },
  });

  useEffect(() => {
    formik.validateForm().then(errors => props.onValidate(Object.keys(errors).length === 0));
  }, [props.value, formik.validateForm]);

  useImperativeHandle(
    ref,
    () => ({
      submit() {
        formik.handleSubmit();
      }
    }),
    []
  );

  const handleChange = e => {
    formik.handleChange(e);
    
    setTimeout(() => {
      props.onNext();
    }, 100);
  }

  return (
    <div>
      <FormControl
        label={`Which type of discount would you like to offer?`}
        variant="pill"
        type="radio"
        name="type"
        id="type"
        value={formik.values.type}
        options={[
          { label: "Percentage discount", value: "percentage" },
          { label: "Fixed cart discount", value: "fixed" },
        ]}
        blockRadios
        labelClassName="ui-caption-medium"
        labelMargin="4"
        onChange={handleChange}
        validationError={
          formik.touched.type && Boolean(formik.errors.type)
            ? formik.errors.type
            : false
        }
      />
    </div>
  );
});

CouponType.displayName = "CouponType";
export default CouponType;
