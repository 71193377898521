import { useState } from "react";
import { Header, HeaderRow, HeaderCell, Body, Row, Cell } from "@table-library/react-table-library/table";
import { EyeOpen, Cross } from "akar-icons";
import Modal from "react-modal";
import UiParagraphMedium from "../../text/UiParagpraphMedium";
import UiSmallRegular from "../../text/UiSmallRegular";
import UiBodyMedium from "../../text/UiBodyMedium";
import UiSmallMedium from "../../text/UiSmallMedium";
import UiCaptionRegular from "../../text/UiCaptionRegular";
import UiCaptionMedium from "../../text/UiCaptionMedium";

Modal.setAppElement('#root');

const OpenQuestionsTable = ({ tableList, order, changeOrder }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const showModal = item => {
    setModalData(item);
    setModalIsOpen(true);

    console.log(item);
  }

	return (
		<>
			<Header>
				<HeaderRow>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.firstName ? ' activity-table__th_active' : ''}`}>Name</HeaderCell>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.email ? ' activity-table__th_active' : ''}`}>Email</HeaderCell>
					<HeaderCell onClick={() => changeOrder('createdAt')} className={`activity-table__th table-align-right${order?.createdAt ? ' activity-table__th_active' : ''}`}>Time</HeaderCell>
					<HeaderCell className="activity-table__th activity-table__th_no-sorting"></HeaderCell>
				</HeaderRow>
			</Header>
			<Body>
				{tableList.map(item => (
					<Row
						item={item}
						key={item._id}
					>
						<Cell>{item.firstName} {item.lastName}</Cell>
						<Cell>{item.email}</Cell>
						<Cell className="table-align-right">{item.time}</Cell>
						<Cell className="table-align-center">
              <button
                className="view-btn"
                onClick={() => showModal(item)}
              >
                <EyeOpen size="16" />
              </button>
            </Cell>
					</Row>
				))}
			</Body>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="an-modal"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          <div className="an-modal__title an-modal__title_fill text-left">
            <UiParagraphMedium text={`${modalData?.firstName} ${modalData?.lastName}`} />
            {!!modalData?.createdAt && (
              <UiSmallRegular
                text={`Survey sent on ${(new Date(modalData.createdAt)).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })}`}
                className="color-secondary-colour-secondary-7"
              />
            )}
          </div>
          <button
            onClick={() => setModalIsOpen(false)}
            className="an-modal__close"
          >
            <Cross
              size="24"
              color="#d9d9d9"
            />
          </button>
        </div>
        <div className="an-modal__body mt-24">
          <UiBodyMedium text="Questions and answers" />
          {!!modalData.answers && (
            <table className="cf-answers-table cf-answers-table_oq mt-24">
              <thead>
                <tr>
                  <th>
                    <UiSmallMedium text="Question" />
                  </th>
                  <th class="text-left">
                    <UiSmallMedium text="Answer" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {modalData.answers.map(answer => (
                  <tr className="color-secondary-colour-secondary-7">
                    <td>
                      <UiCaptionRegular text={answer.question} />
                    </td>
                    <td class="text-left">
                      <UiCaptionRegular text={answer.answer} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {!!modalData.openAnswers && (
            <>
              <div className="mt-24">
                <UiBodyMedium text="Open questions" />
              </div>
              <div className="mt-24 oq-list">
                {modalData.openAnswers.map((answer, key) => (
                  <div
                    className="oq-list__item color-secondary-colour-secondary-7"
                    key={key}
                  >
                    <UiCaptionMedium text="In a few words, how could we improve our customer service experience overall?" />
                    <UiCaptionRegular
                      text="Vulputate cras nisl elit gravida molestie amet sit. Ut non feugiat senectus sem pellentesque suspendisse porttitor."
                      className="mt-8"
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Modal>
		</>
	);
}

export default OpenQuestionsTable;