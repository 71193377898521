import React, { useState, useContext, useEffect } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import ModalLayout from "../../modal/Layout";
import IntroScreen from "../../campaign/IntroScreen/IntroScreen";
import StepWizard from "../../ui-elements/step-wizard/StepWizard";
import { CampaignContext } from "../../context/CampaignContext";
import { CampaignCustomization } from "../../campaign/CampaignCustomization";
import WidgetSetup from "../../campaign/WidgetSetup";
import PreviewStep from "../../campaign/PreviewStep";
import WidgetOrganizationStep from "../../campaign/WidgetOrganizationStep";
import { CampaignSaveModal } from "../../campaign/CampaignSaveModal";
import SurveyBuilder from "../../campaign/SurveyBuilder/SurveyBuilder";
import ReviewBuilder from '../../campaign/ReviewBuilder/ReviewBuilder';
import Preloader from "../../preloader/Preloader";
import { Notify } from "../../ui-elements/notify/notify";

import { saveShopCampaigns, updateShopCampaignById } from "../../api/Campaign";
import { getCustomerSurveyQuestions, getCampaignCustomizer, getProductReviewQuestions } from "../../api/Gpt";
import { getAIData } from "./tools";

const components = {
  customizer: <CampaignCustomization />,
  widgets: <WidgetSetup />,
  widgetOrganization: <WidgetOrganizationStep />,
  preview: <PreviewStep />,
  builder: <SurveyBuilder />,
  reviewBuilder: <ReviewBuilder />,
  raffleSetup: <CampaignCustomization />,
  promoSetup: <CampaignCustomization />,
};

const stepTexts = {
  widgets: {
    title: "What widgets would you like to include in your email?",
    description: `Widgets will add a dynamic feature to your email campaign. Select from your library 
      the widgets you would like to include in the emails of this campaign.`,
  },
  preview: {
    title: "Assets preview",
    description: `Take a sneak peek at your tailored series of {emailAmount} {campaignName} emails! 
      You're free to go back and make any changes until you're entirely satisfied with the results.`,
  },
};

const widgetOrganizationStep = {
  title: "Organize your selected  widgets",
  type: "widgets",
  description: `Arrange your selected widgets effortlessly by moving them up or down on the left panel. See the real time changes in the email 
    preview on the right, allowing you to visualise the placement of each element seamlessly.`,
  stepName: "widget Organize",
  component: components.widgetOrganization,
};

const CampaignGeneratePage = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { campaigns, shopCampaigns, fetchShopCampaigns, tools } =
    useContext(CampaignContext);
  const { campaignSlug, campaignId, toolSlug } = useParams();
  const mode = searchParams.get("mode");
  const show = searchParams.get("show")
  const [loading, setLoading] = useState(false);
  const [showCustomizer, setShowCustomizer] = useState(props.edit || show);
  const [saving, setSaving] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [stepData, setStepData] = useState(() => {
    if (!campaignId) return {}
    const shopCampaign = shopCampaigns.find((campaign) => campaign._id === campaignId);
    if (!shopCampaign) return {}
    return ({
      builder: shopCampaign?.builder,
      customizer: shopCampaign.customizer,
      widgets: shopCampaign.widgets,
      reviewBuilder: shopCampaign?.reviewBuilder.length ? shopCampaign.reviewBuilder[0] : {},
      promoSetup: shopCampaign?.promoSetup
    })
  });
  const navigate = useNavigate();
  const allCampaigns = campaigns.concat(tools)
  const campaign = allCampaigns.find((campaign) => campaign.slug === campaignSlug);
  const urlPrefix = `${campaign.tool ? 'tools' : 'campaigns'}`

  const steps = {};
  campaign.customizerSteps.forEach((step) => {
    let object = {
      [step.type]: {
        ...step,
        ...(stepTexts[step.type] && { title: stepTexts[step.type].title }),
        ...(stepTexts[step.type] && {
          description: stepTexts[step.type].description,
        }),
        component: components[step.type] || "",
      },
    };

    if (step.type === "widgets") {
      object = Object.assign(object, {
        widgetOrganization: widgetOrganizationStep,
      });
    }
    
    if (props.edit) {
      steps.builder = Object.assign({}, steps.builder, {
        hidden: true
      });
    }

    Object.assign(steps, object);
  });

  const getCampaignName = () => {
    const shopCampaign = shopCampaigns.find(
      (campaign) => campaign._id === campaignId
    );

    return shopCampaign?.shopCampaignName;
  };

  const handleComplete = (values) => {
    setStepData(values);
    setOpenSaveModal(true);
  };

  const handleClose = () => {
    setShowCustomizer(false);

    if(campaignId || campaign.tool) {
      navigate(`/${urlPrefix}/${campaignSlug}`);
    }
  };

  const handleSave = async (name) => {
    if (!stepData) return;
    if (saving) return;
    setSaving(true);
    try {
      const updatedStepData = { ...stepData, name };

      await saveShopCampaigns(campaignSlug, updatedStepData);
      await fetchShopCampaigns();
      
      let url = `/${urlPrefix}/${campaignSlug}`
      if (toolSlug) url += `/${toolSlug}`
      navigate(url);
    } catch (e) {
      console.log("e", e);
    }
    setSaving(false);
    setOpenSaveModal(false);
  };

  const handleUpdate = async (name) => {
    if (!stepData) return;
    if (saving) return;
    setSaving(true);
    try {
      const updatedStepData = { ...stepData, name };

      await updateShopCampaignById(campaignId, updatedStepData);
      await fetchShopCampaigns();

      let url = `/campaigns/${campaignSlug}`
      if (toolSlug) url += `/${toolSlug}`
      navigate(url);
    } catch (e) {
      console.log("e", e);
    }
    setSaving(false);
    setOpenSaveModal(false);
  };

  const handleAIGenerate = async(data) => {
    try {
      setShowCustomizer(true);
      setLoading(true);

      const customizerData = await getAIData(data, campaign.slug);
      setStepData((prev) => ({ ...prev, ...customizerData }));
    } catch (error) {
      Notify.error({
        title: (axios.isAxiosError(error) && error?.response?.data?.message) ? error?.response?.data?.message : 'Something went wrong :(. Try later'
      });
      console.error(error)
    } finally {
      setLoading(false);
    }
  }

  return (
    <ModalLayout backTo={`/${urlPrefix}/${campaignSlug}`}>
      {!showCustomizer && (
        <IntroScreen
          handleAIGenerate={handleAIGenerate}
          onNext={(e) => setShowCustomizer(true)}
        />
      )}
      {loading && <Preloader absolute={true} overflow={true} />}
      {!loading && showCustomizer && (
        <StepWizard
          steps={steps}
          data={stepData}
          onComplete={handleComplete}
          onClose={handleClose}
          showStepper
        />
      )}

      <CampaignSaveModal
        value={getCampaignName()}
        isEdit={Boolean(campaignId)}
        open={openSaveModal}
        loading={saving}
        onClose={() => setOpenSaveModal(false)}
        onSave={(value) =>
          Boolean(campaignId) ? handleUpdate(value) : handleSave(value)
        }
      />
    </ModalLayout>
  );
};

export default CampaignGeneratePage;
