import React from 'react'
import UiButton from '../ui-elements/buttons/UiButton';

const CompleteButton = ({ activeTask, handleSkip }) => {
  const getButtonContent = () => {
    let text, href, skipText = 'Skip'
    switch (activeTask) {
      case 'onboarded':
        text = 'Come back';
        href = '/onboarding'
        break;
      case 'storeSetup': 
        text = 'Go to setup';
        href = '/store-settings'
        break;
      case 'widgetsCreated': 
        text = 'Go to widgets';
        href = '/widgets/new'
        break;
      case 'campaignsCreated': 
        text = 'Go to campaigns';
        href = '/campaigns'
        skipText = 'Remind me later'
        break;

      default:
        break;
    }

    return { text, href, skipText }
  }
  if (!activeTask) return null

  const { text, href, skipText } = getButtonContent();
  
  return (
    <>
      <div className="complete-data__button-wrapper">
        <UiButton
          design="secondary"
          text={text}
          href={href}
          size="small"
          elem="a"
          className="ui-button_mw_150"
        />
      </div>
      {/* <div className="complete-data__button-wrapper">
        <UiButton
          design="no-border"
          text={skipText}
          size="small"
          onClick={handleSkip}
        />
      </div> */}
    </>
  );
}

export default CompleteButton;