import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import axios from "axios";
import { Tooltip } from "react-tooltip";
import ReactSlider from "react-slider";
import { Info, Check, Cross } from "akar-icons";
import Header from "../header/Header";
import AnCaption from "../ui-elements/text/AnCaption";
import UiHeading from "../ui-elements/text/UiHeading";
import UiBodyRegular from "../ui-elements/text/UiBodyRegular";
import UiBodyMedium from "../ui-elements/text/UiBodyMedium";
import UiSmallRegular from "../ui-elements/text/UiSmallRegular";
import UiButton from "../ui-elements/buttons/UiButton";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import Preloader from "../preloader/Preloader";
import WithAuthorization from "./WithAuthorization";
import "./SelectYourPlan.scss";
import "react-tooltip/dist/react-tooltip.css";

const SelectYourPlan = () => {
	const navigate = useNavigate();
  const minEmails = 0;
  const maxEmails = 100000;
  const step = 500;
  const [emails, setEmails] = useState(0);
  const [emailsFormatted, setEmailsFormatted] = useState(0);
  const numberFormatter = new Intl.NumberFormat('en-GB');
  const [packages, setPackages] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [services, setServices] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: process.env.REACT_APP_CURRENCY,
    maximumFractionDigits: 0
  });

  const handleSliderChange = value => {
    setEmailsFormatted(numberFormatter.format(value));
    setEmails(value);

    packages.map(packageItem => {
      if (value > packageItem.emailsPerMonth && packageItem.price) {
        const extraCount = Math.round((value - packageItem.emailsPerMonth) * 0.5);

        if (extraCount) {
          packageItem.prices.map(price => {
            if (price.billing_scheme === 'tiered') {
              packageItem.price = Number(packageItem.unitPrice) + extraCount;
            }

            return price;
          });
        }
      } else if (packageItem.unitPrice) {
        packageItem.price = packageItem.unitPrice;
      }

      return packageItem;
    });
  }

  const purchase = (e, packageItem) => {
    e.preventDefault();

    setButtonDisabled(true);

		const cookie = new Cookies();
		const lead = cookie.get('lead');
    
    axios
      .post('/stripe/subscriptions', {
        lead: lead,
        package: packageItem
      })
      .then(response => {
        if (typeof response.data.id !== 'undefined') {
          navigate(`/thank-you/?subscription_id=${response.data.id}`);
        } else {
          window.location.href = response.data;
        }
      })
      .catch(err => {
        setButtonDisabled(false);
        console.error(err);
      });
  }

  useEffect(() => {
		const cookie = new Cookies();
		const lead = cookie.get('lead');

		if (!lead) {
			navigate('/sign-up');
		}
    
    const urls = ['/packages', '/campaigns', '/services'];
    const requests = urls.map(url => axios.get(url));
  
    axios.all(requests)
      .then(response => {
        setPackages(response[0].data);
        setCampaigns(response[1].data);
        setServices(response[2].data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        
        console.error(err);
      });
  }, []);

  if (loading) {
    return <Preloader />
  }

  return (
    <div>
      <Header />
      <div className="select-your-plan pt-70 pb-70 pt-16-mobile pb-16-mobile">
        <div className="container">
					<div className="select-your-plan__inner">
            <div className="syp-head">
              <div className="syp-head__col">
                <AnCaption text="Our Packages" />
                <UiHeading
                  element="h1"
                  desktop="h3-lg"
                  text="Select your plan"
                  margin="4"
                />
                <UiBodyRegular
                  text="Choose your ideal plan with us, where contact count is limitless! Instead, each plan has an email quota."
                  margin="24"
                />
                <UiBodyRegular text="Extra charges apply for exceeding it, a sign you're thriving with Anomate, as it indicates boosted revenue. Choose growth! 🚀" />
              </div>
              <div className="syp-head__col mt-24-mobile text-center-mobile">
                <div className="syp-card">
                  <div className="syp-card__content">
                    <UiHeading
                      element="h5"
                      desktop="other-headings-h5-bold"
                      mobile="other-headings-h6-bold"
                      text="Estimate how much anomate will cost you"
                      margin="4"
                      className="mt-0-mobile"
                    />
                    <UiBodyRegular
                      text="Try our calculator to estimate extra charges for exceeding your monthly quota."
                      margin="24"
                      marginMobile="8"
                      mobile="caption-regular"
                    />
                    <UiBodyRegular
                      text="Opt for bigger plans for more value 😍"
                      mobile="caption-regular"
                    />
                  </div>
                  <div className="syp-card__bottom mt-4 mt-32-mobile">
                    <UiBodyMedium text="Estimate your price" />
                    <div className="syp-card__row mt-8 mt-4-mobile">
                      <ReactSlider
                        className="syp-slider syp-card__slider"
                        min={minEmails}
                        max={maxEmails}
                        onChange={handleSliderChange}
                        step={step}
                        value={emails}
                      />
                      <div className="syp-card__emails syp-emails">
                        <input
                          type="text"
                          className="syp-emails__count ui-other-headings-h6-bold"
                          name="emails"
                          id="emails"
                          value={emailsFormatted}
                          readOnly
                        />
                        <div className="syp-emails__label">
                          <UiBodyRegular
                            text="Sent emails per month"
                            mobile="caption-regular"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
						<div className="pricing-table mt-28 mt-32-mobile">
              <div className="pricing-table__head">
                <div className="pricing-table__col pricing-table__col_title text-center-mobile">
                  <UiHeading
                    element="h5"
                    desktop="other-headings-h5-bold"
                    mobile="other-headings-h6-bold"
                    text="What’s included?"
                  />
                </div>
                {packages.map((packageItem, index) => (
                  <div
                    className="pricing-table__col pricing-table__col_head"
                    key={index}
                  >
                    <div>
                      <UiHeading
                        element="div"
                        desktop="other-headings-h5-bold"
                        mobile="caption-medium"
                        text={packageItem.name}
                      />
                      <UiSmallRegular
                        text={`${numberFormatter.format(packageItem.emailsPerMonth)} emails per month`}
                        className="color-secondary-colour-secondary-7 mt-2-mobile pricing-table__emails-per-month"
                      />
                    </div>
                    <div className="pricing-table__price-section">
                      <div>
                        <UiHeading
                          element="div"
                          desktop="h3-lg"
                          mobile="other-headings-h5-bold"
                          text={currencyFormatter.format(packageItem.price / 100)}
                        />
                        <UiSmallRegular
                          text={packageItem.priceLabel}
                          margin="2"
                          className="pricing-table__emails-per-month"
                        />
                      </div>
                      <div>
                        <UiButton
                          text={packageItem.buttonLabel}
                          size="large"
                          className="pricing-table__button ui-small-medium-mobile"
                          onClick={e => purchase(e, packageItem)}
                          disabled={buttonDisabled}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pricing-table__body">
                <div className="pricing-table__row pricing-table__row_section">
                  <div className="pricing-table__col">
                    <UiHeading
                      element="div"
                      desktop="other-headings-h6-bold"
                      mobile="body-bold"
                      text="Campaigns"
                      className="color-golden-purple-purple-5 text-center-mobile"
                    />
                  </div>
                  {[...Array(packages.length)].map((x, i) => <div className="pricing-table__col mobile-hidden" key={i}>&nbsp;</div>)}
                </div>
                {campaigns.map((campaign, index) => (
                  <div
                    className="pricing-table__row"
                    key={index}
                  >
                    <div className="pricing-table__col pricing-table__service-name">
                      <UiCaptionRegular text={campaign.name} />
                      {(campaign.popup.title || campaign.popup.content) ? (
                        <div className="pricing-table__tooltip-wrapper">
                          <div
                            className="pricing-table__tooltip-toggle"
                            id={`campaign-${index}`}
                          >
                            <Info
                              color="#9254de"
                              size="16"
                            />
                          </div>
                          <Tooltip
                            anchorSelect={`#campaign-${index}`}
                            place="top"
                            className="pricing-table__tooltip"
                            arrowColor="transparent"
                          >
                            {campaign.popup.title ? <h5 className="ui-body-bold m-0 color-gray-gray-9">{campaign.popup.title}</h5> : ''}
                            {campaign.popup.content ? <div className="ui-small-regular">{campaign.popup.content}</div> : ''}
                          </Tooltip>
                        </div>
                      ) : ('')}
                    </div>
                    {packages.map((packageItem, index) => (
                      <div
                        className="pricing-table__col"
                        key={index}
                      >
                        {packageItem.campaigns.includes(campaign._id) ? (
                          <Check
                            color="#9254de"
                            size="24"
                          />
                        ) : (
                          <Cross
                            color="#aba3b1"
                            size="24"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                ))}
                <div className="pricing-table__row pricing-table__row_section">
                  <div className="pricing-table__col">
                    <UiHeading
                      element="div"
                      desktop="other-headings-h6-bold"
                      mobile="body-bold"
                      text="Services"
                      className="color-golden-purple-purple-5 text-center-mobile"
                    />
                  </div>
                  {[...Array(packages.length)].map((x, i) => <div className="pricing-table__col mobile-hidden" key={i}>&nbsp;</div>)}
                </div>
                {services.map((service, index) => (
                  <div
                    className="pricing-table__row"
                    key={index}
                  >
                    <div className="pricing-table__col pricing-table__service-name">
                      <UiCaptionRegular text={service.name} />
                      {(service.popup.title || service.popup.content) ? (
                        <div className="pricing-table__tooltip-wrapper">
                          <div
                            className="pricing-table__tooltip-toggle"
                            id={`service-${index}`}
                          >
                            <Info
                              color="#9254de"
                              size="16"
                            />
                          </div>
                          <Tooltip
                            anchorSelect={`#service-${index}`}
                            place="top"
                            className="pricing-table__tooltip"
                            arrowColor="transparent"
                          >
                            {service.popup.title ? <h5 className="ui-body-bold m-0 color-gray-gray-9">{service.popup.title}</h5> : ''}
                            {service.popup.content ? <div className="ui-small-regular">{service.popup.content}</div> : ''}
                          </Tooltip>
                        </div>
                      ) : ('')}
                    </div>
                    {packages.map((packageItem, key) => (
                      <div
                        className="pricing-table__col"
                        key={key}
                      >
                        {packageItem.services.includes(service._id) ? (
                          service.isWidgetService ? (
                            <UiCaptionRegular text={packageItem.widgetsPricingText} />
                          ) : (
                            <Check
                              color="#9254de"
                              size="24"
                            />
                          )
                        ) : (
                          <Cross
                            color="#aba3b1"
                            size="24"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
					</div>
				</div>
      </div>
    </div>
  );
}

export default WithAuthorization(SelectYourPlan, true, '/');