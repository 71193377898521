import axios from 'axios'

export const getQuestion = (shopCampaignId, questionId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/customer-survey/${shopCampaignId}/answers/${questionId}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };