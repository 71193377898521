import { useContext } from "react";
import { Play, Pause, TrashCan } from "akar-icons";
import { Table, Header, HeaderRow, Body, Row, Cell, HeaderCell } from "@table-library/react-table-library/table";
import { useSort, HeaderCellSort } from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { NavLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import moment from "moment";

import SectionHead from "../../../ui-elements/common/SectionHead";
import UiButton from "../../../ui-elements/buttons/UiButton";
import { Notify } from "../../../ui-elements/notify/notify";
import AnTable from "../../../ui-elements/common/AnTable";
import Chips from "../../../ui-elements/common/Chips";
import FixedDropdown from '../../../ui-elements/fixed-dropdown/FixedDropdown';

import { updateShopCampaign, deleteShopCampaign } from "../../../api/Campaign";
import AkarIconsChevronVertical from "../../../static/images/common/akar-icons_chevron-vertical.svg";
import AkarIconsChevronVerticalCopy from "../../../static/images/common/akar-icons_chevron-vertical copy.svg";
import "../CampaignInstances.scss";
import { CampaignContext } from "../../../context/CampaignContext";

const AutomatedRaffles = ({ campaigns, slug, handleCreateMode }) => {
  const { fetchShopCampaigns, campaigns: _dbCampaigns, shopCampaigns } = useContext(CampaignContext);
  console.log(shopCampaigns)

  const nodes = campaigns.map(campaign => {
    let instanceSubjectLine = '';

    if (campaign?.customizer) {
      Object.keys(campaign.customizer).map(key => {
        if (!instanceSubjectLine && key.includes('email') && campaign.customizer[key]?.emailSubjectLine) {
          instanceSubjectLine = campaign.customizer[key].emailSubjectLine;
        }

        return key;
      });
    }

    return {
      ...campaign,
      instanceSubjectLine
    }
  });

  const sort = useSort({ nodes }, {}, {
    sortIcon: {
      margin: '0px',
      iconDefault: <img src={AkarIconsChevronVertical} alt="" />,
      iconUp: <img src={AkarIconsChevronVerticalCopy} alt="" />,
      iconDown: <img src={AkarIconsChevronVerticalCopy} alt="" />,
    },
    sortFns: {
      RAFFLENAME: array => array.sort((a, b) => a.shopCampaignName.localeCompare(b.shopCampaignName)),
      STATUS: array => array.sort((a, b) => {
        const aStatus = getStatus(a.raffleSetup.datesAndTime.datesAndTime.endDate)
        const bStatus = getStatus(b.raffleSetup.datesAndTime.datesAndTime.endDate)
        return aStatus.localeCompare(bStatus)
      }),
      TYPE: array => array.sort((a, b) => {
        const aType = a.raffleSetup.raffleType.raffleType
        const bType = b.raffleSetup.raffleType.raffleType
        aType.localeCompare(bType)
      }),
      STARTDATE: array => array.sort((a, b) => {
        const aDate = new Date(a.raffleSetup.datesAndTime.datesAndTime.startDate)
        const bDate = new Date(b.raffleSetup.datesAndTime.datesAndTime.startDate)
        if (aDate > bDate) return 1
        if (aDate < bDate) return -1
        return 0
      }),
      ENDDATE: array => array.sort((a, b) => {
        const aDate = new Date(a.raffleSetup.datesAndTime.datesAndTime.endDate)
        const bDate = new Date(b.raffleSetup.datesAndTime.datesAndTime.endDate)
        if (aDate > bDate) return 1
        if (aDate < bDate) return -1
        return 0
      }),
      PARTICIPANTS: array => array.sort((a, b) => a.participants - b.participants),
      WINNERS: array => array.sort((a, b) => a.totalWinners - b.totalWinners),
    },
  });

  const theme = useTheme([getTheme(), {
    Table: `
      --data-table-library_grid-template-columns: repeat(1, minmax(0, 1fr)) 120px 150px repeat(2, 180px) 110px 100px 48px
    `
  }]);

  const activateCampaign = async (id) => {
    await updateShopCampaign(id, {
      status: 'active'
    });

    Notify.success({
      title: 'Raffle has been activated'
    });

    fetchShopCampaigns();
  }

  const pauseCampaign = async (id) => {
    await updateShopCampaign(id, {
      status: 'paused'
    });

    Notify.success({
      title: 'Raffle has been paused'
    });

    fetchShopCampaigns();
  }

  const archiveCampaign = async (id) => {
    await updateShopCampaign(id, {
      status: 'archived'
    });

    Notify.success({
      title: 'Raffle has been archived'
    });

    fetchShopCampaigns();
  }

  const deleteCampaign = async (id) => {
    await deleteShopCampaign(id);

    Notify.success({
      title: 'Raffle has been deleted'
    });

    fetchShopCampaigns();
  }

  const getDate = (date) => {
    const offset = date.split('+');
    return moment(date).utcOffset(`+${offset[1]}`).format('D MMM yyyy, hh:mma')
  }

  const getStatus = (endDate) => {
    const now = new Date();
    const end = new Date(endDate)
    
    if (now > end) {
      return 'ended'
    }
    
    return 'running'
  }
  
  const createDropdownItems = (item) => {
    let items = [
      {
        onClick: () => activateCampaign(item._id),
        disabled: item.status === 'active',
        Icon: <Play />,
        iconText: 'Activate raffle',
      },
      {
        onClick: () => pauseCampaign(item._id),
        disabled: item.status !== 'active',
        Icon: <Pause />,
        iconText: 'Pause raffle',
      },
      {
        onClick: () => archiveCampaign(item._id),
        disabled: true,
        Icon: <Icon icon="ic:baseline-archive" />,
        iconText: 'Archive raffle',
      },
      {
        onClick: () => deleteCampaign(item._id),
        disabled: false,
        Icon: <TrashCan />,
        iconText: 'Delete raffle',
      },
    ]
    return items
  }

  return (
    <div className="card card_br_24 card_shadow card_overflow_visible card_mw_100">
      <SectionHead
        title={
          <>
            <div className="email-templates-subtitle-2-medium">Automated raffles</div>
            <div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">
              Effortlessly monitor key metrics of your campaign instances, status and access relevant actions.
            </div>
          </>
        }
        filter={
          <UiButton
            size="small"
            design="secondary"
            className="ui-button_icon"
            onClick={handleCreateMode}
            text={<span className="ui-button__text">Create new Raffle</span>}
          />
        }
      />
      <div className="mt-24 campaign-instances-table campaign-instances-table_automated-raffles">
        <AnTable
          body={
            <Table
              data={{ nodes }}
              sort={sort}
              theme={theme}
              layout={{
                custom: true
              }}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCellSort sortKey="RAFFLENAME">Raffle Name</HeaderCellSort>
                      <HeaderCellSort sortKey="STATUS">Status</HeaderCellSort>
                      <HeaderCellSort sortKey="TYPE">Type</HeaderCellSort>
                      <HeaderCellSort sortKey="STARTDATE">Start date</HeaderCellSort>
                      <HeaderCellSort sortKey="ENDDATE">End date</HeaderCellSort>
                      <HeaderCellSort sortKey="PARTICIPANTS">Participants</HeaderCellSort>
                      <HeaderCellSort sortKey="WINNERS">Winners</HeaderCellSort>
                      <HeaderCell />
                    </HeaderRow>
                  </Header>
                  <Body>
                    {tableList.map((item) => {
                      const {raffleType, datesAndTime} = item.raffleSetup
                      const status = getStatus(datesAndTime.datesAndTime.endDate)
                      return (
                        <Row item={item} key={item._id}>
                          <Cell>
                            <NavLink className="underline" to={`${item._id}`}>{item.shopCampaignName}</NavLink>
                          </Cell>
                          <Cell>
                            <Chips
                              color={status === 'running' ? 'green' : 'red'}
                              value={status} 
                            />
                          </Cell>
                          <Cell>
                            <Chips
                              color={raffleType.raffleType === 'single' ? 'blue' : 'volcano'}
                              value={`${raffleType.raffleType} campaign`}
                            />
                          </Cell>
                          <Cell>
                            {getDate(datesAndTime.datesAndTime.endDate)}
                          </Cell>
                          <Cell>
                            {getDate(datesAndTime.datesAndTime.startDate)}
                          </Cell>
                          <Cell>{item.participants}</Cell>
                          <Cell>{item.totalWinners}</Cell>
                          <Cell>
                            <FixedDropdown className="campaign" items={createDropdownItems(item)} />
                          </Cell>
                        </Row>
                      )
                    })}
                  </Body>
                </>
              )}
            </Table>
          }
        />
      </div>
    </div>
  )
}

export default AutomatedRaffles;