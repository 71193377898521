import { useState, useEffect } from "react";
import UiButton from "../ui-elements/buttons/UiButton";
import { getEmailFooterPreview } from "../api/Customizer";

const EmailFooterLayout = () => {
  const [headerPreview, setHeaderPreview] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setHeaderPreview(await getEmailFooterPreview());
  }

  return (
    <div className="card card_24 card_shadow">
    <div className="email-preview" dangerouslySetInnerHTML={{ __html: headerPreview }} />
      <div className="text-center">
        <UiButton
          elem={`a`}
          href={`/brand-assets/email-footer`}
          size="medium"
          design="secondary"
          text={`Customise my footer`}
        />
      </div>
    </div>
  );
};

export default EmailFooterLayout;
