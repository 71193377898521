import { ChevronUp } from "akar-icons";
import UiCaptionMedium from "../text/UiCaptionMedium";
import "./AccordionItem.scss";

const AccordionItem = props => {
  const iconColor = props.isOpen ? '#ff85c0' : '#9254de';

  return (
    <div className="accordion-item">
      <div
        className="accordion-item__head"
        onClick={props.onClick}
      >
        <UiCaptionMedium text={props.title} />
        <div className={`accordion-item__icon${props.isOpen ? ' accordion-item__icon_active' : ''}`}>
          <ChevronUp
            color={iconColor}
            size="16"
          />
        </div>
      </div>
      {props.isOpen ? <div className="accordion-item__body">{props.content}</div> : ''}
    </div>
  )
}

export default AccordionItem;