import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useMemo,
} from "react";
import FormControl from "../../forms/FormControl";
import { useFormik } from "formik";
import * as yup from "yup";
import UiBaseCheckbox from "../../forms/UiBaseCheckbox";
import useAuth from "../../../hooks/useAuth";

const CouponMinAmount = forwardRef((props, ref) => {
  const initialValues = {
    minAmount: props.minAmount,
    excludeSaleItems: props.excludeSaleItems,
  };

  const { shopData } = useAuth();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      minAmount: yup
        .number()
        .positive("This is invalid")
    }),
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(false);
      props.onSubmit && props.onSubmit(values);
    },
  });

  useEffect(() => {
    formik.validateForm().then(errors => props.onValidate(Object.keys(errors).length === 0));
  }, [props.value, formik.validateForm]);

  useImperativeHandle(
    ref,
    () => ({
      submit() {
        formik.handleSubmit();
      }
    }),
    []
  );

  return (
    <div className="an-layout an-layout_gap_8">
      <FormControl
        label={`What is the minimum amount?`}
        labelClassName="ui-caption-medium"
        labelMargin="4"
        variant="pill"
        type="text"
        id="minAmount"
        blockRadios
        currency={shopData?.currency ? shopData.currency : null}
        value={Number(formik.values.minAmount) || ""}
        onChange={value => formik.setFieldValue('minAmount', value)}
        validationError={
          formik.touched.minAmount && Boolean(formik.errors.minAmount)
            ? formik.errors.minAmount
            : false
        }
      />

      <div>
        <label htmlFor="excludeSaleItems" className="ui-caption-medium mb-4 flex">
          Would you like to exclude sale items?
        </label>
        <UiBaseCheckbox
          label={`Yes`}
          id="excludeSaleItems"
          checked={formik.values.excludeSaleItems || false}
          onChange={formik.handleChange}
        />
      </div>
    </div>
  );
});

CouponMinAmount.displayName = "CouponMinAmount";
export default CouponMinAmount;
