import React, { useState } from "react";
import FormControl from "../ui-elements/forms/FormControl";
import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import FileUploader from "../ui-elements/file-uploader/FileUploader";
import UiButton from "../ui-elements/buttons/UiButton";
import ColorPicker from "../ui-elements/color-picker/ColorPicker";
import Preloader from "../preloader/Preloader";

const MainAssets = ({ mainAssets = {}, onSave, loading }) => {
  const [fileUploading, setFileUploading] = useState(false);
  const [data, setData] = useState(mainAssets);
  const handleOnChange = (value, key) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const buttonDisabled = () => {
    return JSON.stringify(data) === JSON.stringify(mainAssets);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(data);
  };

  return (
    <form className="card card_dropdown card_24" onSubmit={handleSubmit}>
      <div className="an-layout an-layout_gap_32">
        <div className="an-row">
          <div className="an-col an-col_50">
            <FormControl
              label="Brand name"
              type="text"
              id="brand name"
              name="brandName"
              labelMargin="4"
              placeholder="Add your brand name here*"
              value={data.brandName || ""}
              onChange={(e) => handleOnChange(e.target.value, "brandName")}
              labelClassName="ui-caption-medium"
              required
            />
          </div>
          <div className="an-col an-col_50">
            <ColorPicker
              label="Brand color"
              name="brand-color"
              id="brand-color"
              labelMargin="4"
              placeholder={`Brand color`}
              labelClassName="ui-caption-medium"
              value={data.brandColor}
              onChange={(value) => handleOnChange(value, "brandColor")}
              required
            />
          </div>
        </div>

        <div className="an-row">
          <div className="an-col an-col_50">
            <UiCaptionMedium text="Light logo" />
            <div className="mt-8">
              <FileUploader
                helpText="Formats PNG or JPG. Recommended min width 512px."
                onFileChange={(value) => {
                  handleOnChange(value, "logo");
                  setFileUploading(false);
                }}
                accept={{
                  'image/png': [],
                  'image/jpeg': []
                }}
                onFileUploadStarted={() => setFileUploading(true)}
                image={data.logo || ""}
                className="bg-gray-gray-3"
              />
            </div>
          </div>

          <div className="an-col an-col_50">
            <UiCaptionMedium text="Dark logo" />
            <div className="mt-8">
              <FileUploader
                helpText="Formats PNG or JPG. Recommended min width 512px."
                onFileChange={(value) => {
                  handleOnChange(value, "darkLogo");
                  setFileUploading(false);
                }}
                accept={{
                  'image/png': [],
                  'image/jpeg': []
                }}
                onFileUploadStarted={() => setFileUploading(true)}
                image={data.darkLogo || ""}
                className="bg-gray-gray-3"
              />
            </div>
          </div>
        </div>

        <div className="flex-right gap-8">
          <UiButton
            type="button"
            size="medium"
            design="secondary"
            text="Cancel"
            onClick={() => setData(mainAssets)}
            disabled={buttonDisabled() || fileUploading}
          />
          <UiButton
            size="medium"
            text="Save changes"
            disabled={buttonDisabled() || fileUploading}
          />
        </div>
      </div>
      {loading && <Preloader absolute overflow />}
    </form>
  );
};

export default MainAssets;
