import { ChevronLeft, ChevronRight } from "akar-icons";
import styles from "./Pagination.module.scss";

const Pagination = ({ pagination, nodes, pagesCount }) => {
  const totalPages = (typeof pagesCount !== 'undefined') ? pagesCount : pagination.state.getPages(nodes).length;

  if (totalPages <= 1) {
    return <></>;
  }

  const currentPage = pagination.state.page;

  const renderPages = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
      startPage = 0;
      endPage = totalPages - 1;
    } else {
      if (currentPage <= Math.ceil(maxPagesToShow / 2)) {
        startPage = 0;
        endPage = maxPagesToShow - 1;
      } else if (currentPage + Math.floor(maxPagesToShow / 2) >= totalPages) {
        startPage = totalPages - maxPagesToShow;
        endPage = totalPages - 1;
      } else {
        startPage = currentPage - Math.floor(maxPagesToShow / 2);
        endPage = currentPage + Math.floor(maxPagesToShow / 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          type="button"
          className={currentPage === i ? styles['active'] : ''}
          onClick={() => pagination.fns.onSetPage(i)}
        >
          {i + 1}
        </button>
      );
    }

    if (totalPages > maxPagesToShow) {
      if (startPage > 0) {
        pageNumbers.unshift(
          <span key="startEllipsis">...</span>
        );
      }

      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <span key="endEllipsis">...</span>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className={styles['pagination']}>
      <button
        type="button"
        onClick={() => pagination.fns.onSetPage(currentPage - 1)}
        disabled={currentPage === 0}
      >
        <ChevronLeft
          color="#aba3b1"
          size="24"
        />
      </button>
      {renderPages()}
      <button
        type="button"
        onClick={() => pagination.fns.onSetPage(currentPage + 1)}
        disabled={currentPage === totalPages - 1}
      >
        <ChevronRight
          color="#aba3b1"
          size="24"
        />
      </button>
    </div>
  );
}

export default Pagination;