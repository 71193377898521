import "./Counter.scss";

const Counter = props => {
  return (
    <div className="campaigns-counter">
      <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7">Campaigns to customise</div>
      <div className="campaigns-counter__count email-templates-caption-medium">{props.available} of {props.total}</div>
    </div>
  );
}

export default Counter;