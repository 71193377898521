import { useParams } from "react-router-dom";
import ActivityTable from "../../ui-elements/activity-table/ActivityTable";

const SubscribersPage = () => {
  const { campaignSlug } = useParams();

  return (
    <ActivityTable
      slug={campaignSlug}
      type="subscribers"
    />
  )
}

export default SubscribersPage;
