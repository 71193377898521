/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
  
/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
        if (isObject(source[key])) {
            if (!target[key]) Object.assign(target, { [key]: {} });
            mergeDeep(target[key], source[key]);
        } else {
            Object.assign(target, { [key]: source[key] });
        }
        }
    }

    return mergeDeep(target, ...sources);
}

export const dayItems = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

/**
 * Get x axis data.
 * @param index
 * @param data
 * @param type
 */
export const getXAxis = (index, data, type) => {
    if (type === 'day') {
        return data[index].labels
    }
    return data.labels
}

/**
 * Get chart data.
 * @param index
 * @param data
 * @param type
 */
export const getChartData = (index, data, type) => {
    if (type === 'day') {
        return data[index].datasets
    }

    return data.datasets
}

/**
 * Get chart data.
 * @param current
 * @param previous
 */
export const calculateTrend = (current, previous) => {
    if (!previous && !current) return 0;
    if (!previous) return 100;
    return ((current - previous) / previous) * 100;
}