import React, { useState, useEffect } from 'react'

import ToolsPerformance from '../ToolsPerformance';
import UiHeading from "../../../ui-elements/text/UiHeading";
import UiCaptionRegular from "../../../ui-elements/text/UiCaptionRegular";
import UiCaptionMedium from "../../../ui-elements/text/UiCaptionMedium";
import CampaignIcon from '../../../ui-elements/common/CampaignIcon';
import Chips from '../../../ui-elements/common/Chips';
import Divider from '../../../ui-elements/divider/Divider';
import Toogler from '../../../ui-elements/toogler/Toogler';
import useAuth from '../../../hooks/useAuth';

const StoreRevenue = ({ data }) => {
  const { shopData } = useAuth();
  const currency = shopData.currency

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: shopData.currency
  });

  const zeroFormatted = formatter.format(0);
  const [revenueData, setRevenueData] = useState({
    totalRevenueCurrent: zeroFormatted,
    totalRevenuePrevious: zeroFormatted,
    fromCampaignsRevenueCurrent: zeroFormatted,
    fromCampaignsRevenuePrevious: zeroFormatted,
    totalRevenueTrend: 0,
    fromCampaignsRevenueTrend: 0
  })

  const [type, setType] = useState('day');

  const calculateTrend = (current, previous) => {
    if (!previous && !current) {
      return 0;
    }

    if (!previous) {
      return 100;
    }

    return ((current - previous) / previous) * 100;
  }

  useEffect(() => {
    const _totalRevenueCurrent = data.totalRevenueCurrent ?? 0;
    const _totalRevenuePrevious = data.totalRevenuePrevious ?? 0;
    const _fromCampaignsRevenueCurrent = data.fromCampaignsRevenueCurrent ?? 0;
    const _fromCampaignsRevenuePrevious = data.fromCampaignsRevenuePrevious ?? 0;

    const obj = {
      totalRevenueCurrent: formatter.format(_totalRevenueCurrent),
      totalRevenuePrevious: formatter.format(_totalRevenuePrevious),
      fromCampaignsRevenueCurrent: formatter.format(_fromCampaignsRevenueCurrent),
      fromCampaignsRevenuePrevious: formatter.format(_fromCampaignsRevenuePrevious),
      totalRevenueTrend: calculateTrend(_totalRevenueCurrent, _totalRevenuePrevious),
      fromCampaignsRevenueTrend: calculateTrend(_fromCampaignsRevenueCurrent, _fromCampaignsRevenuePrevious),
    }

    setRevenueData(obj)
  }, [data]);

  if (!data.chart) return null

	return (
		<ToolsPerformance
      key={'store'}
			title="Store revenue"
			subTitle="Total revenue of your store for your selected dates."
      data={data?.chart ? data?.chart[type] : null}
      type={type}
      yAxis={{
        min: 0,
        ticks: {
          callback: (value, index, ticks) => {
            return value.toLocaleString('en-US', {
              style: 'currency',
              currency,
              minimumFractionDigits: 0
            })
          }
        }
      }}
      tooltipLabel={(tooltipItem, context) => {
        const label = tooltipItem.dataset.label
        const value = tooltipItem.raw.toLocaleString('en-US', {
          style: 'currency',
          currency,
          minimumFractionDigits: 0
        })
        return `${label}: ${value}`
      }}
      filter={
        <Toogler
          items={[
            { value: 'day', text: 'Day' },
            { value: 'week', text: 'Week' },
            { value: 'month', text: 'Month' },
          ]}
          defaultStatus={type}
          onChange={(value) => setType(value)}
        />
      }
			Legend={() => {
				return (
					<>
						<div className='icon-wrapper'>
							<div>
								<CampaignIcon emoji={'💰'} />
							</div>
							<div>
								<UiCaptionRegular text="Total revenue of the store" />
								<UiHeading
									element="h6"
									desktop="other-headings-h6-bold"
									text={
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div>{revenueData.totalRevenueCurrent}</div>
                      <div>
                        <Chips
                          color={revenueData.totalRevenueTrend >= 0 ? 'green' : 'red'}
                          value={`${Math.abs(revenueData.totalRevenueTrend).toFixed(1)}%`}
                          arrow={true}
                        />
                      </div>
                    </div>
                  }
								/>
							</div>
						</div>
            <div className='icon-wrapper mt-20'>
							<div>
								<CampaignIcon emoji={'✉️'} />
							</div>
							<div>
								<UiCaptionRegular
									className=""
									text="Revenue from campaigns"
								/>
								<UiHeading
									element="h6"
									desktop="other-headings-h6-bold"
									text={
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div>{revenueData.fromCampaignsRevenueCurrent}</div>
                      <div>
                        <Chips
                          color={revenueData.fromCampaignsRevenueTrend >= 0 ? 'green' : 'red'}
                          value={`${Math.abs(revenueData.fromCampaignsRevenueTrend).toFixed(1)}%`}
                          arrow={true}
                        />
                      </div>
                    </div>
                  }
								/>
							</div>
						</div>
						<Divider className="mt-16 mb-16" />
						<div className='legends'>
							<UiCaptionMedium text="Previous time frame" />
              <UiCaptionRegular 
                text={
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>Total revenue</div>
                    <div>{revenueData.totalRevenuePrevious}</div>
                  </div>
                }
              />
              <UiCaptionRegular 
                text={
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>From Campaigns</div>
                    <div>{revenueData.fromCampaignsRevenuePrevious}</div>
                  </div>
                }
              />
						</div>
					</>
				)
			}}
		/>
	);
}

export default StoreRevenue;