import { toast } from "react-toastify";
import { CircleCheckFill, CircleXFill } from "akar-icons";
import styles from "./Notification.module.scss";
import classNames from "classnames";

const success = ({ title, message }) => {
  return toast.success(
    <div className={styles["notification"]}>
      <div className={styles["notification__icon"]}>
        <CircleCheckFill size="44" color="#52c41a" />
      </div>
      <div className={styles["notification__message"]}>
        <div
          className={classNames(
            styles["title"],
            "email-templates-subtitle-2-medium"
          )}
        >
          {title}
        </div>
        {message && (
          <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-8">
            {message}
          </div>
        )}
      </div>
    </div>,
    {
      hideProgressBar: true,
      pauseOnHover: false,
      icon: false,
      autoClose: 2000
    }
  );
};

const error = ({ title, message }) => {
  return toast.error(
    <div className={classNames(styles["notification"], styles['notification_error'])}>
      <div className={styles["notification__icon"]}>
        <CircleXFill
          size="44"
          color="#f5222d"
        />
      </div>
      <div className={styles["notification__message"]}>
        <div
          className={classNames(
            styles["title"],
            "email-templates-subtitle-2-medium"
          )}
        >
          {title}
        </div>
        {message && (
          <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-8">
            {message}
          </div>
        )}
      </div>
    </div>,
    {
      hideProgressBar: true,
      pauseOnHover: false,
      icon: false,
      autoClose: 2000
    }
  );
};

export const Notify = { error, success };
