import { useState, useEffect } from "react";
import axios from "axios";
import Preloader from "../preloader/Preloader";
import UiButton from "../ui-elements/buttons/UiButton";
import Employee from "../ui-elements/employee/Employee";
import illustrations from "../static/images/store-settings/Illustrations.svg";

const Employees = props => {
  const [employees, setEmployees] = useState([]);
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    refresh();
  }, [props.added]);

  const refresh = () => {
    axios.get('/shop/employees', {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    }).then(response => {
      setEmployees(response.data);
      setLoading(false);
    }).catch(err => {
      console.error(err);

      setLoading(false);
    });
  }

  return (
    <div className="card card_24 card_mh_230 card_shadow">
      {loading ? (
        <Preloader
          overflow={true}
          absolute={true}
        />
      ) : (
        <div className="an-layout an-layout_gap_32">
          {employees.length ? (
            <>
              {employees.map((employee, key) => (
                <Employee
                  user={employee}
                  key={key}
                  refresh={refresh}
                />
              ))}
            </>
          ) : (
            <div className="pt-24 pb-24 text-center">
              <img
                src={illustrations}
                alt="You have no employees"
                className="responsive-img"
              />
              <div className="email-templates-subtitle-2-bold">You have no employees</div>
            </div>
          )}
          <div className="text-right">
            <UiButton
              text="Add employee"
              design="secondary"
              size="medium"
              onClick={() => props.add('employee')}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Employees;