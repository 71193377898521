import { useState, useContext, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { Cross } from "akar-icons";
import { Select } from "react-dropdown-select";
import countryCodes from "country-codes-list";
import validator from "validator";
import UiHeading from "../../ui-elements/text/UiHeading";
import UiCaptionRegular from "../../ui-elements/text/UiCaptionRegular";
import UiButton from "../../ui-elements/buttons/UiButton";
import UiParagpraphMedium from "../../ui-elements/text/UiParagpraphMedium";
import { AuthContext } from "../../context/AuthContext";
import UiBaseCheckbox from "../../ui-elements/forms/UiBaseCheckbox";
import UiCaptionMedium from "../../ui-elements/text/UiCaptionMedium";
import FormControl from "../../ui-elements/forms/FormControl";
import Preloader from "../../preloader/Preloader";
import ntGraphicDesigner1 from "../../static/images/profile/nt-graphic-designer 1.svg";
import illustration from "../../static/images/profile/Illustration.svg";
import { saveTwoFactor } from "../../api/Auth";
import MainContent from "../../dashboard/MainContent";

Modal.setAppElement('#root');

const TwoFactorAuthenticationPage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [step, setStep] = useState('enable');
  const { user, doSetUser } = useContext(AuthContext);
  const countryCodesObject = countryCodes.customList('countryCode', '{countryNameEn} (+{countryCallingCode})');
  const phoneCodesObject = countryCodes.customList('countryCode', '+{countryCallingCode}');
  const [cancelDisabled, setCancelDisabled] = useState(true);
  const [verifyDisabled, setVerifyDisabled] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [countryCallingCode, setCountryCallingCode] = useState(null);
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('token');
  const [initialPhoneNumber, setInitialPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (step === 'enable') {
      if (countryCode && phoneNumber && enabled) {
        setVerifyDisabled(!validator.isMobilePhone(`${phoneCodesObject[countryCode]}${phoneNumber}`));
        setCountryCallingCode(phoneCodesObject[countryCode]);
      } else {
        setVerifyDisabled(true);
      }
  
      setCancelDisabled(phoneNumber === initialPhoneNumber);
    }
  }, [phoneNumber, countryCode, enabled]);

  useEffect(() => {
    if (step === 'verify') {
      setVerifyDisabled(verificationCode.toString().length !== 6);
      setCancelDisabled(!verificationCode);
    }
  }, [verificationCode]);

  const phoneCodes = Object.keys(countryCodesObject).map(countryCode => {
    return {
      label: countryCodesObject[countryCode],
      value: countryCode
    }
  });

  const reset = () => {
    (step === 'enable') ? setPhoneNumber('') : setVerificationCode('');
  }

  const verify = async () => {
    setLoading(true);

    if (step === 'enable') {
      axios.post('/user/2fa', {
        countryCallingCode: countryCallingCode,
        phoneNumber: phoneNumber
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        if (response.data) {
          setStep('verify');
        }
  
        setLoading(false);
        setCancelDisabled(true);
        setVerifyDisabled(true);
      }).catch(err => {
        console.error(err);
  
        setLoading(false);
      });
    } else {
      try {
        await saveTwoFactor(verificationCode);
        await doSetUser();
        setStep('success');
        setLoading(false);
      } catch (err) {
        console.error(err);
        setErrors(err.errors);
      }
      setLoading(false);
    }
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setStep('enable');
    reset();
  }

  const updateCountryCode = e => setCountryCode(e[0].value);

  return (
    <MainContent fullHeight={!user.twoFactorEnabled}>
      {user.twoFactorEnabled ? (
        <div className="an-layout">
          <div className="card card_24 card_shadow">
            <UiParagpraphMedium text="Two-Factor authentication" />
            <UiCaptionRegular
              text="Two-factor authentication (2FA) is a security process that requires users to provide two forms of identification to access their accounts or devices. This typically involves providing a password or PIN as the first factor, followed by a second factor such as security code sent to your phone number. 2FA adds an extra layer of security and helps prevent unauthorized access, making it an important security measure for online accounts and devices."
              className="color-secondary-colour-secondary-7"
            />
            <div className="mt-32">
              <UiCaptionMedium text="Verify identity with SMS" />
              <UiCaptionRegular
                margin="4"
                className="color-secondary-colour-secondary-7"
                text={<>Your phone number <span className="color-gray-gray-9">{`${user.twoFactorCountryCode} ${user.twoFactorPhoneNumber}`}</span> has been verified via SMS.</>}
              />
            </div>
            <div className="mt-32 text-right">
              <UiButton
                onClick={() => setModalIsOpen(true)}
                text="Edit"
                size="medium"
                design="secondary"
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="card card_br_24 card_h_100 card_flex card_align_center card_24 card_shadow campaign-not-included text-center">
          <UiHeading
            text="Enable two-factor authentication"
            desktop="other-headings-h6-bold"
          />
          <UiCaptionRegular
            margin="8"
            text="Two-Factor Authentication (2FA) is a security measure that requires users to provide two types of verification to access their account or device. "
            className="maxw maxw_601 color-secondary-colour-secondary-7"
          />
          <div className="mt-32">
            <img
              src={ntGraphicDesigner1}
              alt="Enable two-factor authentication"
              className="responsive-img"
            />
          </div>
          <div className="mt-32">
            <UiButton
              onClick={() => setModalIsOpen(true)}
              text="Enable"
              size="medium"
              design="secondary"
              className="ui-button_mw_150"
            />
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="an-modal"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          {step === 'enable' ? (
            <div className="an-modal__title">
              <UiHeading
                element="h6"
                desktop="other-headings-h6-bold"
                text="Enable two-factor authentication"
              />
              <UiCaptionRegular
                className="mt-8 color-secondary-colour-secondary-7"
                text="Please provide a phone number to verify it’s you next time you access your account."
              />
            </div>
          ) : (
            <>
              {step === 'verify' ? (
                <div className="an-modal__title">
                  <UiHeading
                    element="h6"
                    desktop="other-headings-h6-bold"
                    text="Verify identity with SMS"
                  />
                  <UiCaptionRegular
                    className="mt-8 color-secondary-colour-secondary-7"
                    text="Thanks for keeping your account secure. Please provide the code sent to your phone number."
                  />
                </div>
              ) : (
                <div className="an-modal__title an-modal__title_fill text-center pt-50 pb-50">
                  <img
                    src={illustration}
                    alt="Two-factor authentication enabled"
                    className="responsive-img"
                  />
                  <UiHeading
                    margin="16"
                    desktop="other-headings-h6-bold"
                    text="Two-factor authentication enabled"
                  />
                  <UiCaptionRegular
                    text={`Your phone number ${countryCallingCode} ${phoneNumber} has been verified via SMS.`}
                    margin="8"
                    className="color-secondary-colour-secondary-7"
                  />
                </div>
              )}
            </>
          )}
          <button
            onClick={closeModal}
            className="an-modal__close"
          >
            <Cross
              size="24"
              color="#d9d9d9"
            />
          </button>
        </div>
        {(step === 'enable' || step === 'verify') ? (
          <div className="an-modal__body an-modal__body_mh_0 mt-32 pt-32 bt-1 text-left">
            {step === 'enable' ? (
              <>
                <UiCaptionMedium text="Mobile phone number" />
                <div className="mt-4 an-row an-row_gap_8">
                  <div className="an-col an-col_32">
                    <Select
                      options={phoneCodes}
                      placeholder="Select country"
                      onChange={updateCountryCode}
                      className="form-select"
                    />
                  </div>
                  <div className="an-col an-col_68">
                    <FormControl
                      type="number"
                      name="phoneNumber"
                      id="phone_number"
                      onChange={e => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                    />
                  </div>
                </div>
                <div className="mt-12">
                  <UiBaseCheckbox
                    name="enable"
                    id="enable"
                    label="Enable two-factor authentication using this phone number"
                    onChange={e => setEnabled(e.target.checked)}
                    checked={enabled}
                  />
                </div>
              </>
            ) : (
              <>
                <UiCaptionMedium text="Verification code from SMS" />
                <FormControl
                  type="number"
                  name="verificationCode"
                  id="verification_code"
                  placeholder="6-digit code"
                  onChange={e => setVerificationCode(e.target.value)}
                  value={verificationCode}
                  margin="4"
                  validationError={errors.verificationCode}
                />
              </>
            )}
            <div className="mt-24 pt-24 bt-1 text-right">
              <div className="button-group">
                <UiButton
                  size="medium"
                  design="secondary"
                  text="Cancel"
                  onClick={reset}
                  disabled={cancelDisabled}
                />
                <UiButton
                  size="medium"
                  text="Verify"
                  onClick={verify}
                  disabled={verifyDisabled}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="an-modal__body an-modal__body_mh_0 mt-32 text-right">
            <UiButton
              text="Got it"
              onClick={closeModal}
              size="medium"
            />
          </div>
        )}
        {loading ? (
          <Preloader
            absolute={true}
            overflow={true}
          />
        ) : ''}
      </Modal>
    </MainContent>
  );
};

export default TwoFactorAuthenticationPage;