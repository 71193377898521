import TimelineItem from "../../performance/TimelineItem";

const Timeline = ({ timeline, campaignName }) => {
  const updatedTimeline = timeline.map(item => {
    let title = '';

    if (item.action === 'Opened') {
      title = `${item.email} opened the email ${campaignName}`;
    } else if (item.action === 'Clicked') {
      title = `${item.email} went to the site through a link in the email`;
    } else if (item.action === 'Purchased') {
      title = `${item.email} placed an order`;
    } else if (item.action === 'Unsubscribed') {
      title = `${item.email} unsubscribed`;
    } else if (item.action === 'Subscribed') {
      title = `${item.email} subscribed`;
    } else if (item.action === 'Requested details') {
      title = `${item.email} request details of ${item.productName}`;
    } else if (item.action === 'Opened survey') {
      title = `${item.email} opened ${campaignName}`;
    } else if (item.action === 'Answered') {
      title = `${item.email} answered ${campaignName}`;
    } else if (item.action === 'Submitted') {
      title = `${item.email} subscribed to ${campaignName}`;
    }

    return {
      createdAt: new Date(item.date),
      type: item.action,
      title
    }
  });

  return (
    <>
      <div className="email-templates-subtitle-2-medium">Timeline</div>
      <div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">Latest activity from your customers</div>
      <div className="mt-24 timeline">
        {updatedTimeline.map((item, key) => (
          <TimelineItem
            item={item}
            key={key}
          />
        ))}
      </div>
    </>
  );
}

export default Timeline;