import React, { useState, useCallback, useEffect, useContext } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import _ from "lodash";

import CustomizationOptionsContainer from "../ui-elements/customization/customiztion-options-container/CustomizationOptionsContainer";
import StepWizardNavigation from "../ui-elements/step-wizard/StepWizardNavigation";
import CustomizationContainer from "../ui-elements/customization/customization-container/CustomizationContainer";
import CustomizationForms from "../ui-elements/customization/customization-forms/CustomizationForms";
import useStep from "../hooks/useStep";
import CustomizationElement from "../ui-elements/customization-element/CustomizationElement";
import { getCampaignPreview } from "../api/Customizer";
import { CampaignContext } from "../context/CampaignContext";
import { isObjectEmpty } from "../util/object";
import { generateCampaignData, generateCampaignConsentData } from "../api/Gpt";
import { CustomizationFormFields } from "../ui-elements/customization/customization-forms/CustomizationFormFields";
import PhysicalCustomPrizeModal from "./PhysicalCustomPrizeModal/PhysicalCustomPrizeModal";

import { validateField, prizeFields } from "./tools";

export const CampaignCustomization = () => {
  const {
    currentStep,
    currentStepValue = {},
    handlePrev,
    handleNext,
    stepData
  } = useStep();
  const [previewHTML, setPreviewHTML] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(currentStepValue);
  const sections = currentStep.sections.map((section) => {
    if (section.name === "prizeType") {
      return { ...section, fields: prizeFields };
    } else {
      return section;
    }
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const { campaigns, tools } = useContext(CampaignContext);
  const { campaignSlug } = useParams();
  const allCampaigns = campaigns.concat(tools)
  const campaign = allCampaigns.find((campaign) => campaign.slug === campaignSlug);
  const [validationErrors, setValidationErrors] = useState({});
  const [isCustomizerValid, setIsCustomizerValid] = useState(false);
  const [showValidators, setShowValidators] = useState({});
  const [visiblePhysicalPrizeModal, setVisiblePhysicalPrizeModal] =
    useState(false);

  useEffect(() => {
    if (currentStepValue && Object.keys(currentStepValue).length > 0) {
      setValues(currentStepValue);
    }
  }, [currentStepValue]);

  useEffect(() => {
    if (activeIndex >= 0) {
      debounceFetchPreview(values);
    }
  }, [values, activeIndex]);

  useEffect(() => {
    const mode = searchParams.get("mode");

    if (mode == "ai") {
      const valid = sections.reduce((acc, section, mainIndex) => {
        const _showValidators = showValidators[mainIndex] || {};
        if (!acc[activeIndex]) acc[activeIndex] = {};

        acc[mainIndex] = {
          ..._showValidators,
        };
        const subSections = section.sections ?? section.fields
        for (let idx = 0; idx < subSections.length; idx++) {
          acc[mainIndex][idx] = idx;
        }

        return acc;
      }, {});
      setShowValidators(valid);
      validateElements(currentStepValue);
    }
  }, []);

  useEffect(() => {
    if (currentStepValue && Object.keys(currentStepValue).length > 0) {
      validateElements(currentStepValue);
    }
  }, [currentStepValue]);

  useEffect(() => {
    if (Object.keys(validationErrors).length <= 0) return;
    setIsCustomizerValid(isObjectEmpty(validationErrors));
  }, [validationErrors]);

  const validateElements = (values) => {
    sections.forEach((section, index) => {
      let subSections = section.sections || [];

      if (!subSections.length && section.fields) {
        subSections = [{ fields: section.fields }];
      }

      validateElement(index, subSections, values[section.name], false);
    });
  };

  const validateElement = (index, sections, values = {}, strict) => {
    let _validationErrors = validationErrors[index] || {};

    sections.forEach((section, sectionIndex) => {
      const requiredFields = section.fields.filter((field) => {
        if (
          field.conditional &&
          field.conditionalValue &&
          field.conditionalValue !== values[field.conditional]
        ) {
          return false;
        }

        return field.required || field.type === "coupon";
      });

      const fieldErrors = {};

      requiredFields.forEach((field) => {
        if (field) {
          if (!values[field.name]) {
            if (!strict) {
              fieldErrors[field.name] = {
                message: "This is mandatory",
              };
            }
          } else if (!validateField(values[field.name], field.type, field)) {
            if (!strict) {
              fieldErrors[field.name] = {
                message: "This is invalid",
              };
            }
          } else {
            delete fieldErrors[field.name];
          }
        }
      });

      _validationErrors[sectionIndex] = fieldErrors;
    });

    setValidationErrors((prev) => ({ ...prev, [index]: _validationErrors }));
  };

  const fetchPreview = async (customizer) => {
    const activeSection = sections[activeIndex];

    if (loading || !activeSection) {
      return;
    }

    setLoading(true);
    const body = {
      customizer,
      id: campaign._id,
      section: activeSection.name,
    }

    if (stepData.raffleSetup) {
      body['raffleSetup'] = stepData.raffleSetup
    }

    try {
      const response = await getCampaignPreview(body);
      setPreviewHTML(response);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const debounceFetchPreview = useCallback(
    _.debounce((values) => fetchPreview(values), 500),
    [fetchPreview]
  );

  const handleChange = (_values, sectionName, changedFieldKey) => {
    if (
      changedFieldKey === "type" &&
      _values.raffleType === "custom" &&
      _values.type === "physical"
    ) {
      // open "something about custom prize" modal
      setVisiblePhysicalPrizeModal(true);
    }

    const newValues = { ...values, [sectionName]: _values };
    setValues(newValues);
    validateElements(newValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleNext(values);
  };

  const handleFormClick = useCallback(
    async (fieldName, sectionName) => {
      if (fieldName === "generateContent") {
        // const index = sectionName.toLowerCase();
        const emailData = await generateCampaignData(campaign._id, sectionName);
        const newValues = {
          ...values,
          [sectionName]: { ...values[sectionName], ...JSON.parse(emailData) },
        };
        setValues(newValues);
        validateElements(newValues);
      } else if (fieldName === "generateConsentContent") {
        const emailData = await generateCampaignConsentData(campaign._id);
        const newValues = {
          ...values,
          [sectionName]: { ...values[sectionName], ...JSON.parse(emailData) },
        };
        setValues(newValues);
        validateElements(newValues);
      }
    },
    [values, campaign]
  );

  const handleClick = useCallback(
    (index) => {
      const _section = sections[activeIndex];

      if (_section && !_section.sections && _section.fields) {
        const _showValidators = showValidators[index] || {};
        setShowValidators((prev) => ({
          ...prev,
          [activeIndex]: { ..._showValidators, [0]: 0 },
        }));
        validateElement(
          activeIndex,
          [{ fields: _section.fields }],
          values[_section.name]
        );
      }

      if (index === activeIndex) {
        setActiveIndex(-1);
        return;
      }

      setActiveIndex(index);
    },
    [activeIndex, values]
  );

  const handleOnElementBeforeChange = (index, sections, idx, values) => {
    const _showValidators = showValidators[index] || {};

    setShowValidators((prev) => ({
      ...prev,
      [index]: {
        ..._showValidators,
        [idx]: idx,
      },
    }));

    validateElement(index, sections, values);
  };

  const getValidationStatus = (index) => {
    if (Object.keys(showValidators).length > 0 && showValidators[index])
      return isObjectEmpty(validationErrors[index]);
    return null;
  };

  return (
    <>
      <PhysicalCustomPrizeModal
        open={visiblePhysicalPrizeModal}
        onClose={() => setVisiblePhysicalPrizeModal(false)}
      />

      <form className="an-row w-100 h-100" onSubmit={handleSubmit}>
        <CustomizationOptionsContainer className="an-col_3_12">
          {sections.map((section, index) => (
            <CustomizationElement
              validation={getValidationStatus(index)}
              key={`CustomizationElement_${index}`}
              title={section.title}
              description={section.description}
              icon={section.icon}
              onClick={handleClick}
              index={index}
              open={activeIndex === index}
              tooltip={section.tooltip}
              subtitle={section.subtitle}
              bodyClassName="an-layout_gap_16"
            >
              {section.fields &&
                section.fields.length > 0 &&
                section.fields.map((field, fieldIndex) => {
                  return (
                    <CustomizationFormFields
                      disableDebounce
                      key={`subSchema-${field.name}-${fieldIndex}`}
                      index={0}
                      field={field}
                      validations={Object.values(showValidators[index] || {})}
                      validationErrors={
                        validationErrors ? validationErrors[index] : {}
                      }
                      values={values[section.name]}
                      onChange={(values, changedKey) =>
                        handleChange(values, section.name, changedKey)
                      }
                    />
                  );
                })}

              <CustomizationForms
                activeIndex={-1}
                values={values[section.name] || {}}
                schemas={section.sections}
                onChange={(values, changedKey) =>
                  handleChange(values, section.name, changedKey)
                }
                onClick={(fieldName) =>
                  handleFormClick(fieldName, section.name)
                }
                size="small"
                validations={Object.values(showValidators[index] || {})}
                validationErrors={
                  validationErrors ? validationErrors[index] : {}
                }
                onElementBeforeChange={(idx, values) =>
                  handleOnElementBeforeChange(
                    index,
                    section.sections,
                    idx,
                    values
                  )
                }
              />
            </CustomizationElement>
          ))}
        </CustomizationOptionsContainer>

        <CustomizationContainer
          showPlaceholder={
            currentStep.type === "raffleSetup" ? false : activeIndex < 0
          }
          currentType={currentStep.type}
          key={`customization-container`}
          className="an-col_7_12"
          previewHTML={previewHTML}
          loading={loading}
        />

        <StepWizardNavigation
          onPrev={() => handlePrev(values)}
          nextDisabled={!isCustomizerValid}
        />
      </form>
    </>
  );
};
