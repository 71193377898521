import { Question } from "akar-icons";
import "./CustomerHelpChat.scss";

const CustomerHelpChat = () => {
  // TODO: ANOMX-8: disabled https://anova.atlassian.net/browse/ANOMX-8?focusedCommentId=25418
  return <></>

  return (
    <button className="customer-help-chat">
      <Question
        width="29"
        color="#ffffff"
      />
    </button>
  )
}

export default CustomerHelpChat;