import React from "react";
import he from "he";
import TagItem from "../../onboarding/TagItem";
import "./SelectedFilters.scss";

const SelectedFilters = ({ filters, onRemove }) => {
  return (
    <div className="selected-filters">
      {Object.keys(filters).map(key => (
        <React.Fragment key={key}>
          {filters[key].map((filter, _key) => (
            <TagItem
              key={_key}
              tag={he.decode(filter.name)}
              onClick={() => onRemove(filter, key)}
              size="small"
            />
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}

export default SelectedFilters;