import React, { useRef } from 'react'
import UiHeading from '../../ui-elements/text/UiHeading'
import UiSmallRegular from '../../ui-elements/text/UiSmallRegular'
import Chips from '../common/Chips';
import { Tooltip } from 'react-tooltip';
import { Info } from "akar-icons";

import useAuth from '../../hooks/useAuth';
import './Stat.scss';
import UiBodyMedium from '../text/UiBodyMedium';

const Stat = ({ value, type, label, chips, tooltip, name, smallValue }) => {
	const { shopData } = useAuth();
	const ref = useRef(null);
	const formatMoney = number => {
		return number.toLocaleString('en-US', {
			style: 'currency',
			currency: shopData.currency
		});
	}

	const formatNumber = number => {
		return number.toLocaleString('en-US', {
			minimumFractionDigits: 0
		});
	}

	const getPostion = () => {
		const rect = ref.current?.getBoundingClientRect()

		if (rect) {
			const coords = {
				x: rect.x + (rect.width - 30), // - (rect.width * 0.3)
				y: rect.y + (rect.height - 30)// - (rect.height * 0.7)
			}

			return coords
		}
		return ({x: 0, y: 0})
	}

	const renderLabel = () => {
		if (tooltip) {
			return (
				<div style={{display: 'flex', alignItems: 'center'}}>
					{label}
					<Info
						size="16"
						color="#D9D9D9"
						className='info'
					/>
				</div>
			)
		}

		return label
	}

	return (
		<div className={`stat`} ref={ref}>
			{tooltip && 
				<Tooltip 
					className='stat__tooltip' 
					arrowColor="transparent"
					anchorSelect={`.${name}`}
					place='bottom-start'
					positionStrategy='fixed'
					position={getPostion()}
				>
					<UiSmallRegular
						text={tooltip}
						className="color-secondary-colour-secondary-7"
					/>
				</Tooltip>
			}
			<div className={`card ${!smallValue ? 'card_shadow card_8_18' : 'card_16'} an-col ${name} ${tooltip ? 'hoverable' : ''}`}>
        {smallValue ? <UiBodyMedium text={value} /> : (
          <UiHeading
            desktop="other-headings-h6-bold"
            text={type === 'money' ? formatMoney(value) : formatNumber(value)}
          />
        )}
				<UiSmallRegular
					text={renderLabel()}
					className="color-secondary-colour-secondary-7"
				/>
				{!!chips && (
					<div className="stat__chips">
						<Chips
							color={chips < 0 ? 'red' : 'green'}
							value={`${Math.abs(chips).toFixed(1)}%`}
							arrow={true}
							/>
					</div>
				)}
			</div>
		</div>
	)
}

export default Stat;