import React, { useState, useEffect } from 'react'

import SurveyPerformance from './SurveyPerformance';
import TotalEmailsSent from '../TotalEmailsSent';
import UserLocation from '../UserLocation';
import Timeline from '../Timeline';
import CampaignInstances from '../CampaignInstances';

import KPIStats from '../../../ui-elements/kpi-stats/KPIStats';
import { getStats } from '../tools';

import { getCampaignLocationStats, getCampaignTimeline } from '../../../api/Campaign';

const SurveyOverview = ({campaigns, slug, handleCreateMode}) => {
  const statObj = {
    emailsSent: 0,
    opened: 0,
    openSurveys: 0,
    completedSurveys: 0,
    unsubscribed: 0,
    clicked: 0,
  }
  const [stats, setStats] = useState(getStats(statObj, campaigns))
  const [locationStats, setLocationStats] = useState([]);
  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    setStats(getStats(statObj, campaigns));
    fetchData();
  }, [campaigns])

  const fetchData = async () => {
    setLocationStats(await getCampaignLocationStats(slug));
    setTimeline(await getCampaignTimeline(slug));
  }

  return (
    <div className="an-layout">
      <div className="an-row an-row_gap_8">
        <KPIStats 
          stats={stats} 
          indicators={{
            emailsSent: "Surveys sent",
            opened: "Emails opened",
            openSurveys: "Open surveys",
            completedSurveys: "Completed surveys",
          }} 
        />
      </div>
      <div className="an-row">
        <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
          <SurveyPerformance 
            answered={stats.completedSurveys}
            emailOpened={stats.opened}
            surveyOpened={stats.openSurveys}
            unsubscribed={stats.unsubscribed}
            sented={stats.emailsSent}
          />
        </div>
        <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
          <TotalEmailsSent campaigns={campaigns} />
        </div>
      </div>
      <CampaignInstances campaigns={campaigns} slug={slug} handleCreateMode={handleCreateMode} />
      <div className="an-row">
          {!!locationStats.length && (
            <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
                <UserLocation
                  stats={locationStats}
                  clicked={stats.clicked}
                />
            </div>
          )}
          {!!timeline.length && (
            <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
                <Timeline
                  timeline={timeline}
                  campaignName={campaigns[0].name}
                />
            </div>
          )}
      </div>
    </div>
  );
}

export default SurveyOverview;