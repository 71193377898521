import "./ProductPreview.scss";

const ProductPreview = props => {
  return (
    <div className="product-preview">
      {props.src ? (
        <img
          src={props.src}
          alt={props.alt}
          className="product-preview__image"
        />
      ) : ('')}
      <div className="product-preview__name">{props.name}</div>
    </div>
  )
}

export default ProductPreview;