import { NavLink } from "react-router-dom";
import { Cross } from "akar-icons";
import "./EscapeButton.scss";

const EscapeButton = props => {
  const { iconProps = {}, buttonProps = {}} = props
  if (props.to) {
    return (
      <NavLink
        {...buttonProps}
        to={props.to}
        className="escape-button"
      >
        <Cross
          size={iconProps.size ?? "29"}
          color={iconProps.color ?? "#9254de"}
        />
      </NavLink>
    );
  }
  
  return ( 
    <div className="escape-button" {...buttonProps}>
      <Cross
        size={iconProps.size ?? "29"}
        color={iconProps.color ?? "#9254de"}
      />
    </div>
  )
}

export default EscapeButton;