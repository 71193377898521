import React, { useState, useEffect } from 'react'

const Radio = (props) => {
  const [otherInputVisible, setOtherInputVisible] = useState(false);

  useEffect(() => {
    let isCustom = true;

    if (props?.value) {
      props.options.map(option => {
        if ((typeof option === 'string' && props.value == option) || props.value == option?.value) {
          isCustom = false;
        }
      });
    } else {
      isCustom = false;
    }

    if (isCustom) {
      setOtherInputVisible(true);
    }
  }, []);

  return (
    <div className={`form-control ${props.className ? props.className : ''}${props.margin ? ` mt-${props.margin}` : ""}`}>
      {props.label && (
        <label
          className={`${
            props.labelClassName
              ? props.labelClassName
              : "email-templates-paragraph-1-medium"
          } form-control__label${
            props.labelMargin ? ` mb-${props.labelMargin}` : ""
          }`}
        >
          {props.label}
        </label>
      )}
        <div
          className={`form-control__radio-wrapper${
            props.blockRadios ? " form-control__radio-wrapper_block" : ''
          } ${
            props.blocks > 1 ?
            ` form-control__radio-wrapper_block_${props.blocks}` : ''
          } ${
            props.blockGap ?
            ` form-control__radio-wrapper_block_gap_${props.blockGap}` : ''
          }`}
        >
          {props.options.map((option, key) => (
            <div
              className={`form-control__${props.type}`}
              key={key}
              data-variant={props.variant}
            >
              {option.value === 'other' ? (
                <>
                  {otherInputVisible ? (
                    <label className="form-control__r-label form-control__r-label_p_4_8 form-control__r-label_empty">
                      <input
                        type="text"
                        className="form-control__input form-control__input_size_md"
                        onChange={(e) => props.onCustomChange(e.target.value, props.name)}
                        defaultValue={props?.value && !isNaN(props.value) ? props.value : ''}
                      />
                    </label>
                  ) : (
                    <label
                      className={`form-control__r-label form-control__r-label_empty ui-caption-regular ${
                        props.radioLabelColor
                          ? `color-${props.radioLabelColor}`
                          : "color-secondary-colour-secondary-7"
                      }`}
                      title={typeof option === "string" ? option : option.label}
                      onClick={() => {
                        setOtherInputVisible(true);

                        props.onReset();
                      }}
                    >
                      <span>
                        {typeof option === "string" ? option : option.label}
                      </span>
                    </label>
                  )}
                </>
              ) : (
                <>
                  <input
                    type={props.type}
                    className="form-control__r-input"
                    value={typeof option === "string" ? option : option.value}
                    checked={
                      typeof option === "string"
                        ? String(option) === String(props.value)
                        : String(option.value) === String(props.value)
                    }
                    name={props.name}
                    id={`${props.id}_${key}`}
                    onChange={props.onChange}
                    readOnly={props.readOnly ? "readonly" : false}
                    onClick={props.onClick}
                  />
                  <label
                    htmlFor={`${props.id}_${key}`}
                    className={`form-control__r-label form-control__r-label_${
                      props.type
                    } ui-caption-regular ${
                      props.radioLabelColor
                        ? `color-${props.radioLabelColor}`
                        : "color-secondary-colour-secondary-7"
                    }`}
                    title={typeof option === "string" ? option : option.label}
                  >
                    <span>
                      {typeof option === "string" ? option : option.label}
                    </span>
                  </label>
                </>
              )}
            </div>
          ))}
        </div>

        {props.validationError && (
          <div className="form-control__error ui-caption-regular">
            {props.validationError.message || props.validationError}
          </div>
        )}
    </div>
  );
}

export default Radio;