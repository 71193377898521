import React from "react";
import styles from "./CustomizationOptionsContainer.module.scss";
import classNames from "classnames";

const CustomizationOptionsContainer = ({ className, children }) => {
  return (
    <div className={classNames(styles["base"], "scrollbar", className)}>
      {children}
    </div>
  );
};

export default CustomizationOptionsContainer;
