import Header from "../header/Header";
import Survey from "./Survey";
import CustomerHelpChat from "../customer-help-chat/CustomerHelpChat";
import "./Onboarding.scss";
import { AuthContext } from "../context/AuthContext";
import Preloader from "../preloader/Preloader";
import { useContext } from "react";

const Onboarding = () => {
  const { user, loading } = useContext(AuthContext);
  if (!user || loading) return <Preloader />;

  return (
    <div className="onboarding">
      <Header type="onboarding" user={user} />
      <Survey user={user} />
      <CustomerHelpChat />
    </div>
  );
};

export default Onboarding;
