import "./HeaderProfileImage.scss";

const HeaderProfileImage = props => {
  if (props.avatar) {
    return (
      <div className="header-profile-avatar">
        <img
          src={props.avatar}
          alt=""
          className="header-profile-avatar__image"
        />
      </div>
    )
  }

  return (
    <div className="header-profile-image">
      <svg
        width="28"
        height="34"
        viewBox="0 0 28 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="header-profile-image__icon"
      >
        <path
          d="M1.10016 13.3192C2.28316 17.5855 2.87466 17.9635 4.59538 19.8537C4.59538 17.9857 4.91495 14.8503 4.91495 13.5447C10.5491 13.5447 15.3041 11.2348 16.9773 10.0798C16.9174 13.9966 20.5736 16.5576 20.5736 16.5576C20.5736 16.5576 20.0491 18.1393 19.7494 21.6795C19.5097 24.5116 20.1989 23.9643 20.5736 23.3366C21.6524 19.8416 23.4207 20.7756 24.1699 21.6795C33.3637 6.67667 19.225 3.77781 16.5279 4.05399C14.49 2.30651 11.6479 0.937076 9.65001 0.736217C2.44448 0.0118133 -0.818022 6.40148 1.10016 13.3192Z"
          fill="#262626" 
        />
        <path
          d="M20.4941 25.9044C19.5202 31.7947 12.983 36.0303 8.26551 31.2587C3.54806 26.4871 4.49209 17.7529 4.99609 13.6771"
          stroke="#262626"
          strokeWidth="1.01797"
          strokeLinecap="round"
        />
        <path
          d="M24.303 21.202C24.8912 22.691 24.3394 25.9905 20.4962 25.9905"
          stroke="#262626"
          strokeWidth="1.01797"
          strokeLinecap="round"
        />
        <path
          d="M22.6165 22.1794C22.7115 22.4618 22.6355 23.1306 21.5715 23.5476"
          stroke="#262626"
          strokeWidth="1.01797"
          strokeLinecap="round"
        />
        <path
          d="M5.15234 25.2088C3.90806 24.6758 3.03087 22.7018 3.36835 19.5408"
          stroke="#262626"
          strokeWidth="1.01797"
          strokeLinecap="round"
        />
        <path
          d="M12.1885 28.6611C11.0358 28.9737 11.1087 28.1136 10.234 27.3589"
          stroke="#262626"
          strokeWidth="1.01797"
          strokeLinecap="round"
        />
        <ellipse
          cx="0.732937"
          cy="0.830662"
          rx="0.732937"
          ry="0.830662"
          transform="matrix(-1 0 0 1 9.25684 20.3229)"
          fill="#262626"
        />
        <ellipse
          cx="0.830662"
          cy="0.781799"
          rx="0.830662"
          ry="0.781799"
          transform="matrix(-1 0 0 1 16.4883 20.4203)"
          fill="#262626"
        />
        <path
          d="M17.4648 19.5408C17.2905 19.0041 16.5382 18.0113 14.924 18.3333"
          stroke="#262626"
          strokeWidth="1.01797"
          strokeLinecap="round"
        />
        <path
          d="M9.94043 18.4656C9.57026 18.2444 8.5634 17.9346 7.49731 18.4656"
          stroke="#262626"
          strokeWidth="1.01797"
          strokeLinecap="round"
        />
        <path
          d="M12.5816 20.8112C12.7181 21.8695 12.811 24.0157 12.0898 24.1338"
          stroke="#262626"
          strokeWidth="1.01797"
          strokeLinecap="round"
        />
        <path
          d="M3.78418 29.0783C4.12421 30.012 5.07167 32.0247 6.14127 32.6062"
          stroke="#262626"
          strokeWidth="1.01797"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
}

export default HeaderProfileImage;