import { useParams } from "react-router-dom";
import './ViewEmail.scss';

const ViewEmail = () => {
  const { hash } = useParams();

  return (
    <div className="view-email">
      <iframe
        title="view-email"
        src={`${process.env.REACT_APP_API_BASE_URL}/anomate-emails/${hash}`}
        className="view-email__iframe"
      />
    </div>
  )
}

export default ViewEmail;