import UiCaptionRegular from "../../ui-elements/text/UiCaptionRegular";
import "./Legend.scss";

const Legend = ({ 
  label, 
  value, 
  total,
  color,
  showPercents=true,
  showTotal=true,
}) => {
  const formatNumber = number => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 0
    });
  }

  return (
    <div className="legend color-secondary-colour-secondary-7">
      <div className="legend__label">
        <div className="legend__color" style={{ background: color }}></div>
        <div className="legend__name">
          <UiCaptionRegular text={label} />
        </div>
      </div>
      <div className="legend__value">
        {showTotal && <UiCaptionRegular text={total === 0 ? `0.00%` : `${formatNumber(value)} ${showPercents ? `/ ${(value / total * 100).toFixed(2)}%` : ''}`} />}
        {!showTotal && <UiCaptionRegular text={total === 0 ? `0.00%` : `${(value / total * 100).toFixed(2)}%`} />}
      </div>
    </div>
  )
}

export default Legend;