import React, { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./LayoutEditor.module.scss";
import classNames from "classnames";

let MODAL_LAYOUTS = [
  "/static/modal-layouts/modal-layout-1.svg",
  "/static/modal-layouts/modal-layout-2.svg",
  "/static/modal-layouts/modal-layout-3.svg",
  "/static/modal-layouts/modal-layout-4.svg",
  "/static/modal-layouts/modal-layout-5.svg",
  "/static/modal-layouts/modal-layout-6.svg",
];

const LANDING_LAYOUTS = [
  "/static/landing-layouts/landing-layout-1.svg",
  "/static/landing-layouts/landing-layout-2.svg",
];

const LayoutEditor = (props) => {
  const { campaignSlug } = useParams();
  const [modalLayouts, setModalLayouts] = useState(MODAL_LAYOUTS);

  useEffect(() => {
    if (campaignSlug === 'raffle-giveaway') {
      setModalLayouts([...modalLayouts, '/static/modal-layouts/modal-layout-7.svg']);
    }
  }, [campaignSlug]);

  const layouts = props.variant === "modal" ? modalLayouts : LANDING_LAYOUTS;
  const width = props.width || 115;

  return (
    <div>
      <div className={classNames(styles["base"], !!(props.variant === 'landing') && styles['base_landing'])}>
        {layouts.map((layout, index) => (
          <div
            className={classNames(styles["layout"], {
              [styles["selected"]]: index + 1 === Number(props.value),
              [styles[`layout_width_${width}`]]: width,
            })}
            key={`layout-${index}`}
            onClick={() => props.onChange(index + 1)}
          >
            <img src={layout} alt="layout" />
          </div>
        ))}
      </div>
      
      {props.validationError && (
        <div className="form-control__error ui-caption-regular">
          {props.validationError.message}
        </div>
      )}
    </div>
  );
};

export default memo(LayoutEditor);
