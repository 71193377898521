import { useState, useEffect, useContext } from "react";
import MainContent from "../../dashboard/MainContent";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import { getUserInvoice } from "../../api/Auth";
import { AuthContext } from "../../context/AuthContext";
import CurrentPackageData from "../../billing/CurrentPackageData";
import PaymentHistory from "../../billing/PaymentHistory";

const InvoicesPage = () => {
  const { currentPackage, updateShopData } = useContext(AuthContext);
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const promises = [getUserInvoice()];

      const responses = await Promise.all(
        promises.map((p) => p.catch((e) => e))
      );

      setUpcomingInvoice(responses[0]);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <MainContent>
      <div className="an-layout">
        <SectionHead
          title={
            <>
              <UiHeading
                text="Your next invoice"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                Discover details about your upcoming invoice, including billing
                cycle and estimated charge. Additional charges will apply for
                extra emails sent beyond your package quota. Extra charges for
                the current period will be included in the upcoming billing
                cycle.
              </div>
            </>
          }
        />
        {currentPackage && upcomingInvoice && (
          <CurrentPackageData
            package={currentPackage}
            invoice={upcomingInvoice}
          />
        )}
        <SectionHead
          title={
            <>
              <UiHeading
                text="Check your invoices"
                desktop="email-templates-subtitle-2-bold"
                element="h2"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">Navigate through your billing history and download each month's invoice as a PDF with just a click.</div>
            </>
          }
        />
        <PaymentHistory />
      </div>
    </MainContent>
  );
}

export default InvoicesPage;
