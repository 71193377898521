import { Dashboard, Grid, CreditCard, Person, Gear, Edit, PeopleGroup, LightBulb } from "akar-icons";
import UiBodyMedium from "../ui-elements/text/UiBodyMedium";
import UiSmallRegular from "../ui-elements/text/UiSmallRegular";
import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import { useLocation, useParams } from "react-router-dom";
import { useContext } from "react";
import "./Breadcrumbs.scss";
import { CampaignContext } from "../context/CampaignContext";

const Breadcrumbs = (props) => {
  const { pathname } = useLocation();
  const { campaignSlug, toolSlug } = useParams();
  const { campaigns, tools } = useContext(CampaignContext);
  const type = () => {
    const pathArr = pathname.slice(1).split("/");

    switch (pathArr[0]) {
      case "":
        return "dashboard";
      case "campaigns":
        if (campaignSlug) return "campaign";
        return "campaigns";
      case "crm":
        if (pathArr[1] === 'customers') {
          return 'customers';
        }
      case "tools": {
        if (toolSlug) return "tool";
        return "tools";
      }
      default:
        return pathArr[0];
    }
  };

  const currentCampaign = () => {
    if (!campaignSlug) return null;
    return campaigns.find((campaign) => campaign.slug === campaignSlug);
  };

  const renderBreadcrumbs = () => {
    const currentType = type()
    if (currentType === "dashboard") {
      return (
        <>
          <Dashboard color="#595959" size="24" />
          <UiBodyMedium text="Dashboard" />
        </>
      );
    }
    
    if (currentType === "campaigns") {
      return (
        <ul className="breadcrumbs-list">
          <li className="breadcrumbs-list__item ui-body-medium">Campaigns</li>
          <li className="breadcrumbs-list__item ui-body-medium">
            All campaigns
          </li>
        </ul>
      );
    }
    
    if (currentType === "campaign") {
      return (
        <ul className="breadcrumbs-list">
          <li className="breadcrumbs-list__item ui-body-medium">Campaigns</li>
          <li className="breadcrumbs-list__item breadcrumbs-list__item_block ui-body-medium">
            <UiSmallRegular text="All campaigns" />
            <UiCaptionMedium text={currentCampaign().name} className="mt-4" />
          </li>
        </ul>
      );
    }
    
    if (currentType === "widgets") {
      return (
        <>
          <Grid color="#595959" size="24" />
          <UiBodyMedium text="Widgets" />
        </>
      );
    }
    
    if (currentType === "billing") {
      return (
        <>
          <CreditCard color="#595959" size="24" />
          <UiBodyMedium text="Billing" />
        </>
      );
    }
    
    if (currentType === "profile") {
      return (
        <>
          <Person color="#595959" size="24" />
          <UiBodyMedium text="Profile" />
        </>
      );
    }
    
    if (currentType === "store-settings") {
      return (
        <>
          <Gear color="#595959" size="24" />
          <UiBodyMedium text="Store settings" />
        </>
      );
    }
    
    if (currentType === "brand-assets") {
      return (
        <>
          <Edit color="#595959" size="24" />
          <UiBodyMedium text="Brand assets" />
        </>
      );
    }
    
    if (currentType === 'customers') {
      return (
        <ul className="breadcrumbs-list">
          <li className="breadcrumbs-list__item">
            <PeopleGroup color="#595959" size="24" />
            <UiBodyMedium text="CRM" />
          </li>
          <li className="breadcrumbs-list__item ui-body-medium">
            <UiSmallRegular text="Customers & Subscribers" />
          </li>
        </ul>
      )
    }
    
    if (currentType == 'tool') {
      const tool = tools.find((tool) => tool.slug == toolSlug)
      return (
        <ul className="breadcrumbs-list">
          <li className="breadcrumbs-list__item ui-body-medium">
            <LightBulb color="#595959" size="24" />
            <UiBodyMedium text="Tools" />
          </li>
          <li className="breadcrumbs-list__item ui-body-medium">
            <UiCaptionMedium text={tool.name} className="mt-4" />
          </li>
        </ul>
      );
    }
  };

  return (
    <div className={`breadcrumbs${props.campaign ? " p-0" : ""}`}>
      {renderBreadcrumbs()}
    </div>
  );
};

export default Breadcrumbs;
