import React from 'react'
import Stat from './Stat';

const KPIStats = ({ stats, indicators, chips, tooltips }) => {
	return Object.keys(indicators).map((key) => {
    let type = ''
    let label = indicators[key]
    if (indicators[key].label || indicators[key].type) {
      type = indicators[key].type ?? ''
      label = indicators[key].label ?? ''
    }

    return (
			<Stat
				key={key}
        name={key}
        label={label}
				value={stats[key]}
        type={type}
        tooltip={tooltips?.[key] ?? null}
        chips={chips?.[key] ? chips[key] : null}
			/>
		)
	})
}

export default KPIStats;