import { useParams } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import SectionHead from "../../ui-elements/common/SectionHead";
import Chips from "../../ui-elements/common/Chips";
import { CampaignContext } from "../../context/CampaignContext";
import UiHeading from "../../ui-elements/text/UiHeading";
import Preloader from "../../preloader/Preloader";
import CommonSingleOverview from "../../campaigns/overviews/CommonSingleOverview";
import CampaignStatus from "../../campaigns/overviews/CampaignStatus";
import GenerateNewSubscribersSingleOverview from "../../campaigns/overviews/GenerateNewSubscribersSingleOverview";
import SurveySingleOverview from "../../campaigns/overviews/SurveyOverview/SurveySingleOverview";
import RaffleSingleOverview from "../../campaigns/overviews/RaffleOverview/RaffleSingleOvervew";

import './SingleCampaign.scss'

const SingleCampaignPage = () => {
  const { campaignId, campaignSlug } = useParams();
  const { campaigns, shopCampaigns } = useContext(CampaignContext);
  const [shopCampaign, setShopCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const campaign = campaigns.find(currentCampaign => currentCampaign.slug === campaignSlug);
  const [status, setStatus] = useState('');

  useEffect(() => {
    fetchData();
  }, [campaignSlug, campaignId, shopCampaigns]);

  const fetchData = async () => {
    const _shopCampaign = shopCampaigns.filter(campaign => campaign._id === campaignId);

    console.log(_shopCampaign);

    setShopCampaign(_shopCampaign);
    setStatus(_shopCampaign[0].status);
    setLoading(false);
  };

  const getSingleCampaignOverview = () => {
    if (campaignSlug === 'generate-new-subscribers') {
      return <GenerateNewSubscribersSingleOverview campaigns={shopCampaign} slug={campaignSlug} />;
    }
    if (campaignSlug == 'customer-survey') {
      return <SurveySingleOverview campaigns={shopCampaign} />
    }

    if (campaignSlug === 'raffle-giveaway') {
      return <RaffleSingleOverview campaigns={shopCampaign} />
    }

    return <CommonSingleOverview campaigns={shopCampaign} slug={campaignSlug} />;
  };

  const renderSubtitle = () => {
    if (shopCampaign[0].raffleSetup) {
      return (
        <div
          className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4"
          dangerouslySetInnerHTML={{ __html: shopCampaign[0].raffleSetup.raffleInformation.raffleDecsription }}
        />
      )
    }

    if (campaign.overviewText) {
      return (
        <div
          className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4"
          dangerouslySetInnerHTML={{ __html: campaign.overviewText }}
        />
      )
    }

    return null
  }

  const getTitle = () => {
    if (campaign.slug === 'raffle-giveaway') {
      const {raffleType} = shopCampaign[0].raffleSetup
      return (
          <div className="title raffle-title">
            <span>{shopCampaign[0].shopCampaignName}</span>
            <Chips
              color={raffleType.raffleType === 'single' ? 'blue' : 'volcano'}
              value={`${raffleType.raffleType} campaign`}
            />
          </div>
      )
    }

    return shopCampaign[0].shopCampaignName
  }

  if (loading) {
    return <Preloader />;
  }

  return (
    <>
      <SectionHead
        align="center"
        title={
          <div className="pr-48">
            <UiHeading
              text={getTitle()}
              desktop="email-templates-subtitle-2-bold"
            />
            {renderSubtitle()}
          </div>
        }
        filter={
          <CampaignStatus status={status} campaign={campaign} shopCampaign={shopCampaign[0]} />
        }
      />
      {shopCampaign.length ? (
        <>{getSingleCampaignOverview()}</>
      ) : ''}
    </>
  );
};

export default SingleCampaignPage;
