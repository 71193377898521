import { Header, HeaderRow, HeaderCell, Body, Row, Cell } from "@table-library/react-table-library/table";
import { NavLink } from "react-router-dom";
import Chips from '../../common/Chips';

const GNSTable = ({
	single,
	order,
	tableList,
	changeOrder,
}) => {
  return (
		<>
			<Header>
				<HeaderRow>
					{!single ? (
						<>
							<HeaderCell onClick={() => changeOrder('instanceName')} className={`activity-table__th${order?.instanceName ? ' activity-table__th_active' : ''}`}>Instance Name</HeaderCell>
							<HeaderCell onClick={() => changeOrder('status')} className={`activity-table__th${order?.status ? ' activity-table__th_active' : ''}`}>Status</HeaderCell>
						</>
					) : ''}
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.firstName ? ' activity-table__th_active' : ''}`}>Name</HeaderCell>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting table-align-right${order?.email ? ' activity-table__th_active' : ''}`}>Email</HeaderCell>
					<HeaderCell onClick={() => changeOrder('action')} className={`activity-table__th table-align-right${order?.action ? ' activity-table__th_active' : ''}`}>Action</HeaderCell>
					<HeaderCell onClick={() => changeOrder('date')} className={`activity-table__th table-align-right${order?.date ? ' activity-table__th_active' : ''}`}>Time</HeaderCell>
				</HeaderRow>
			</Header>
			<Body>
				{tableList.map(item => (
					<Row
						item={item}
						key={item._id}
					>
						{!single ? (
							<>
								<Cell>
									<NavLink to={`../${item.shopCampaignId}`}>{item.instanceName}</NavLink>
								</Cell>
								<Cell>
									<Chips
										color={item.status == 'active' ? 'green' : 'red'}
										value={item.status}
									/>
								</Cell>
							</>
						) : ''}
						<Cell>{item.firstName} {item.lastName}</Cell>
						<Cell>{item.email}</Cell>
						<Cell>{item.action}</Cell>
						<Cell className="table-align-right">{item.time}</Cell>
					</Row>
				))}
			</Body>
		</>
	);
}

export default GNSTable;