import { useState, useEffect } from 'react'
import { ChevronUpSmall, ChevronDownSmall } from 'akar-icons'

import styles from './DeliverySettings.module.scss'

const NumberInput = ({ handleChange, value = 0, onKeyDown, name, min, onPaste }) => {
	const [state, setState] = useState(value)

  useEffect(() => {
    if (value != state && handleChange) handleChange(state)
  }, [state])

  useEffect(() => {
    if (value != state) setState(value)
  }, [value])
  
  const handleDecrease = () => {
    setState((prev = 0) => {
      return prev == 0 ? 0 : parseInt(prev) - 1
    })
  }
  const handleIncrease = () => {
    setState((prev = 0) => parseInt(prev) + 1)
  }
  
	return (
		<div className={styles['input']} tabIndex={0}>
			<input
        type='number'
        name={name}
        min={min}
        value={state}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        onChange={(e) => setState(e.target.value)}
			/>
			<div className={styles['chevrons']}>
        <div className={styles['chevron']} tabIndex={0} onClick={handleIncrease}>
          <ChevronUpSmall
            strokeWidth={2}
            size={16}
            color="#aba3b1"
          />
        </div>
        <div className={styles['chevron']} tabIndex={0} onClick={handleDecrease}>
          <ChevronDownSmall
            strokeWidth={2}
            size={16}
            color="#aba3b1"
          />
        </div>
			</div>
		</div>
	)
}

export default NumberInput