export const getTasks = () => {
  return [
    {
      type: 'onboarded',
      name: 'Onboarding questions',
      completed: false,
      active: false,
      title: `0 out of 11 questions answered`,
      content: 'Come back and complete a few questions to allow Anomate to get to know your brand and create better content for your emails.',
    },
    {
      type: 'storeSetup',
      name: 'Store setup',
      completed: false,
      active: false,
      title: 'Complete store setup',
      content: 'Provide some important settings for your emails, your brand, and add new users or employees to your store.',
    },
    {
      type: 'widgetsCreated',
      name: 'Create your widgets',
      completed: false,
      active: false,
      title: 'Unleash the power of widgets!',
      content: 'Would you like to include a Hero in your emails? Or advertise your new products?With widgets you can include all of this in your emails and more!'
    },
    {
      type: 'campaignsCreated',
      name: 'Customize your campaigns',
      completed: false,
      active: false,
      title: 'You have 0 campaigns!',
      content: 'Let\'s get started with setting up your campaigns in Anomate for email marketing success!'
    }
  ]
}

export const getStatus = (type, questions, domains, widgets, shop, user, shopCampaigns) => {
  const { onboardingData } = user;
  const { onboarded, storeSetup, widgetsCreated, campaignsCreated } = shop
  let completed = false;
  switch (type) {
    case 'onboarded': 
      completed = onboarded || (onboardingData ? Object.keys(onboardingData).length == questions.length : false);
      break;
    case 'storeSetup':
      completed = storeSetup || (!!shop?.emailSettings || !!domains.length);
      break;
    case 'widgetsCreated':
      completed = widgetsCreated || !!widgets.length;
      break;
    case 'campaignsCreated':
      completed = campaignsCreated || !!shopCampaigns.length;
      break;
  }

  return completed
}

export const getPropgress = (tasks) => {
  const part = 1 / tasks.length
  return tasks.reduce((memo, task) => {
    if (task.completed) memo = memo + part * 100
    return memo
  }, 0)
}