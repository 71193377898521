import { NavLink } from "react-router-dom";
import { ArrowLeft } from "akar-icons";
import "./BackButton.scss";

const BackButton = ({ slug }) => {
  return (
    <div className="back-button-top">
      <NavLink to={`/campaigns/${slug}`} className="back-button-top__link">
        <ArrowLeft size="24" />
        <span>Back</span>
      </NavLink>
    </div>
  )
}

export default BackButton;