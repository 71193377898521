const SignUpAccordionIcon = props => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="an-accordion__icon"
    >
      <path
        d="M16.2976 11.9994L8.28756 20.0096C8.10215 20.1949 8 20.4422 8 20.7059C8 20.9696 8.10215 21.2169 8.28756 21.4022L8.87731 21.9921C9.2616 22.3759 9.88618 22.3759 10.2699 21.9921L16.9963 15.2657L23.7301 21.9996C23.9155 22.1848 24.1627 22.2871 24.4263 22.2871C24.6901 22.2871 24.9373 22.1848 25.1228 21.9996L25.7124 21.4097C25.8979 21.2242 26 20.9771 26 20.7134C26 20.4497 25.8979 20.2023 25.7124 20.0171L17.695 11.9994C17.509 11.8137 17.2607 11.7117 16.9967 11.7123C16.7317 11.7117 16.4835 11.8137 16.2976 11.9994Z"
        fill="#722ED1"
      />
    </svg>
  );
}

export default SignUpAccordionIcon;