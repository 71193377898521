import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import UiHeading from '../../ui-elements/text/UiHeading';
import CampaignStep from '../../ui-elements/campaign-step/CampaignStep';
import UiButton from '../../ui-elements/buttons/UiButton';

const ToolsIntro = ({ onNext = () => { }, tool }) => {
  const navigate = useNavigate()
  
	if (!tool) return null;
  
	return (
		<div className='card card_br_24 card_24 card_shadow card_align_center card_h_100 card_flex text-center'>
			<UiHeading
				text="Ready to customize your campaign?"
				desktop="email-templates-subtitle-2-bold"
			/>
			<div
				className="email-templates-paragraph-1-regular mt-8 color-secondary-colour-secondary-7"
				dangerouslySetInnerHTML={{
					__html: tool.customizerText,
				}}
			/>
			<div className="cp-steps-wrapper mt-48 pt-24 pb-24">
				{tool.introCards.map((step, key) => (
					<CampaignStep key={key} step={step} />
				))}
			</div>
			<div className="mt-40 button-group button-group_center">
				<UiButton
					size="medium"
					text="Get started"
					className="ui-button_mw_180"
					type="button"
					onClick={(e) => {
            navigate(`/campaigns/${tool.slug}/consent-manager/generate?show=true`)
					}}
				/>
			</div>
		</div>
	);
};

export default ToolsIntro;
