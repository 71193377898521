import { Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useContext, useMemo } from "react";
import Preloader from "../preloader/Preloader";
import styles from "./Layout.module.scss";
import Nav from "./Nav";
import TopBar from "./TopBar";

const DashboardLayout = () => {
  const { user, shopData, loading } = useContext(AuthContext);

  const inValidShopStatus = useMemo(() => {
    if (!shopData) return true;

    return !shopData.importStatus || shopData.importStatus === "started";
  }, [shopData]);

  if (!user || !shopData || loading || inValidShopStatus) {
    return <Preloader />;
  }

  return (
    <div className={styles["main"]}>
      <div className={styles["main"]}>
        <Nav />

        <div className={styles["main-body"]}>
          <TopBar />
          <div className={styles["inner"]}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
