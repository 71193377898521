import { useState } from "react";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Modal from "react-modal";
import { Cross, MoreVerticalFill } from "akar-icons";
import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import { setPaymentMethodAsDefault, removePaymentMethod } from "../api/Auth";
import { Notify } from "../ui-elements/notify/notify";
import UiHeading from "../ui-elements/text/UiHeading";
import UiButton from "../ui-elements/buttons/UiButton";
import Preloader from "../preloader/Preloader";
import vector from "../static/images/billing/Vector.svg";
import logosMastercard from "../static/images/billing/logos_mastercard.svg";
import illustration from "../static/images/billing/Illustration.svg";
import styles from "./PaymentMethod.module.scss";

Modal.setAppElement('#root');

const PaymentMethod = props => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const formatMonth = month => {
    const stringMonth = String(month);

    if (month < 10) {
      return stringMonth.padStart(2, '0');
    }

    return stringMonth;
  }

  const setAsDefault = async () => {
    await setPaymentMethodAsDefault(props.data.id);

    props.onUpdate();

    Notify.success({
      title: 'Changes saved successfully',
      message: 'Default payment method has been changed.'
    });
  }

  const remove = async () => {
    await removePaymentMethod(props.data.id);

    props.onUpdate();

    Notify.success({
      title: 'Changes saved successfully',
      message: 'Payment method has been removed.'
    });

    setShowRemoveModal(false);
  }

  return (
    <div className={classNames(styles['payment-method'], { [`${styles['payment-method_default']}`]: props.default })}>
      <div className={styles['payment-method__info']}>
        <div className={styles['payment-method__card']}>
          <img
            src={props.data.card.brand === 'visa' ? vector : logosMastercard}
            alt={props.data.card.brand}
            className={styles['payment-method__card-logo']}
          />
        </div>
        <div className={styles['payment-method__data']}>
          <UiCaptionMedium
            text={`${props.data.card.brand} ending in ${props.data.card.last4}`}
            className="text-capitalize"
          />
          <UiCaptionRegular
            text={`Expiry ${formatMonth(props.data.card.exp_month)}/${props.data.card.exp_year}`}
            className="color-secondary-colour-secondary-7"
          />
          {props.default ? (
            <UiCaptionMedium
              text="Default"
              className="color-secondary-colour-secondary-7"
            />
          ) : (
            <UiCaptionMedium
              text="Set as default"
              className="color-secondary-colour-secondary-7 cursor-pointer"
              onClick={setAsDefault}
            />
          )}
        </div>
      </div>
      {!props.default && (
        <div className={styles['payment-method__actions']}>
          <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
            <MoreVerticalFill
              size="16"
              color="#d9d9d9"
              onClick={() => setShowDropdown(!showDropdown)}
            />
            {showDropdown && (
              <div className="dropdown">
                <button onClick={() => setShowRemoveModal(true)} className="dropdown__item ui-caption-regular">Remove payment method</button>
              </div>
            )}
          </OutsideClickHandler>
        </div>
      )}
      <Modal
        isOpen={showRemoveModal}
        onRequestClose={() => setShowRemoveModal(false)}
        className="an-modal"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          <div className="an-modal__title text-center pt-12 pb-12">
            <img
              src={illustration}
              alt="Remove payment method"
              className="responsive-img"
            />
            <UiHeading
              element="h6"
              desktop="other-headings-h6-bold"
              text="Remove payment method"
            />
            <UiCaptionRegular
              className="mt-8 color-secondary-colour-secondary-7"
              text="Are you sure you want to remove this payment method? This action cannot be undone."
            />
          </div>
          <button
            onClick={() => setShowRemoveModal(false)}
            className="an-modal__close"
          >
            <Cross
              size="24"
              color="#d9d9d9"
            />
          </button>
        </div>
        <div className="an-modal__body an-modal__body_mh_0 mt-32 text-right">
          <div className="button-group">
            <UiButton
              size="medium"
              design="secondary"
              text="Cancel"
              onClick={() => setShowRemoveModal(false)}
            />
            <UiButton
              size="medium"
              text="Yes, remove"
              onClick={remove}
            />
          </div>
        </div>
        {loading ? (
          <Preloader
            absolute={true}
            overflow={true}
          />
        ) : ''}
      </Modal>
    </div>
  )
}

export default PaymentMethod;