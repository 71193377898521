import axios from "axios";

export const getEmailHeaderPreview = (data) => {
  const customizer = data?.customizer;
  const layout = data?.layout;

  let url = `/customizer/header`;
  if (customizer) {
    url += `?customizer=${encodeURIComponent(JSON.stringify(customizer))}`;

    if (layout) {
      url += `&layout=${encodeURIComponent(JSON.stringify(layout))}`;
    }
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmailFooterPreview = (data) => {
  const url = `/customizer/footer${
    data ? `?customizer=${encodeURIComponent(JSON.stringify(data))}` : ""
  }`;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmailHeaderFields = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/customizer/header/fields", {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEmailFooterFields = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/customizer/footer/fields", {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getWidgetPreview = (data) => {
  const customizer = data?.customizer;
  const id = data?.id;
  const layout = data?.layout;

  let url = `/customizer/widget/${id}`;

  if (layout) {
    const filter = data?.filter;

    url += `?layout=${encodeURIComponent(JSON.stringify(layout))}`;

    if (filter && filter.nativeEvent === undefined) {
      url += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
    }

    if (customizer) {
      url += `&customizer=${encodeURIComponent(JSON.stringify(customizer))}`;
    }
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCampaignPreview = (data) => {
  const customizer = data?.customizer;
  const id = data?.id;
  const survey = data?.survey;
  const raffleSetup = data?.raffleSetup;

  let url = `/customizer/campaign/${id}`;

  if (data.section === "popup") {
    url += `/popup`;
  }

  if (customizer) {
    url += `?customizer=${encodeURIComponent(JSON.stringify(customizer))}`;
  } else {
    url += `?customizer={}`;
  }

  if (raffleSetup) {
    url += `&raffleSetup=${encodeURIComponent(JSON.stringify(raffleSetup))}`;
  }

  if (survey) {
    url += `&survey=${encodeURIComponent(JSON.stringify(survey))}`;
  }

  url += `&index=${data.section}`;

  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
