import UiButton from "../ui-elements/buttons/UiButton";
import akarIconsCircleAlertFill from "../static/images/onboarding/akar-icons_circle-alert-fill.svg"
import "./InstallationCompleted.scss";

const PluginNotFound = props => {
  return (
    <div className="installation-status text-center">
      <div className="installation-status__inner">
        <img
          src={akarIconsCircleAlertFill}
          alt="Plugin not found"
          className="installation-status__icon"
        />
        <div className="email-templates-subtitle-2-bold mt-32">Plugin not found</div>
        <div className="email-templates-paragraph-1-regular">Sorry but our services can’t reach Anomate Helper Plugin. <br />Please try again, or go back and ensure that our plugin is installed and active on your WordPress site.</div>
        <div className="mt-32">
          <UiButton
            onClick={props.checkPlugin}
            size="small"
            text="Try Again"
          />
        </div>
      </div>
    </div>
  );
}

export default PluginNotFound;