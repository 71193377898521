import React, {
  useState,
  useContext,
  useCallback,
  useLayoutEffect,
  useEffect,
} from "react";
import useStep from "../hooks/useStep";
import StepWizardNavigation from "../ui-elements/step-wizard/StepWizardNavigation";
import { useParams } from "react-router-dom";
import { CampaignContext } from "../context/CampaignContext";
import { getCampaignPreview } from "../api/Customizer";
import CustomizationOptionsContainer from "../ui-elements/customization/customiztion-options-container/CustomizationOptionsContainer";
import CustomizationContainer from "../ui-elements/customization/customization-container/CustomizationContainer";
import CustomizationElement from "../ui-elements/customization-element/CustomizationElement";
import PreviewInstances from "../ui-elements/preview-instances/PreviewInstances";

const PreviewStep = () => {
  const { currentStep, handlePrev, handleNext, stepData, setStepDescription } =
    useStep();
  const [previewHTML, setPreviewHTML] = useState("");
  const [loading, setLoading] = useState(false);
  const sections = currentStep.sections;
  const [activeIndex, setActiveIndex] = useState(0);
  const { campaigns, tools } = useContext(CampaignContext);
  const { campaignSlug } = useParams();
  const allCampaigns = campaigns.concat(tools)
  const campaign = allCampaigns.find((campaign) => campaign.slug === campaignSlug);
  const [customizer, setCustomizer] = useState({});
  const [activeInstance, setActiveInstance] = useState(0);
  const [previewTitle, setPreviewTitle] = useState(sections[0].title);


  useLayoutEffect(() => {
    const emails = sections.filter((section) => section.type === "email");
    const description = currentStep.description
      .replace("{campaignName}", campaign.name.toLowerCase())
      .replace("{emailAmount}", `${emails.length}`);

    setStepDescription(description);
  }, [currentStep.description, sections, campaign]);

  useEffect(() => {
    if (!stepData.widgets || !stepData.widgets.length) {
      const _customizer = {};
      Object.keys(stepData.customizer).forEach((key) => {
        const widgets = stepData.customizer[key]?.widgets;

        if (widgets && widgets.length) {
          _customizer[key] = { ...stepData.customizer[key], widgets: [] };
        } else {
          _customizer[key] = { ...stepData.customizer[key] };
        }
      });

      setCustomizer(_customizer);
      return;
    }

    setCustomizer(stepData.customizer);
  }, [stepData]);

  useEffect(() => {
    if (Object.keys(customizer).length <= 0) return;

    if (activeIndex >= 0) {
      fetchPreview();
    }
  }, [activeIndex, customizer]);

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

  const handleSubmit = (e) => {
    e.preventDefault();
    handleNext(customizer, "customizer");
  };

  const fetchPreview = async () => {
    const activeSection = sections[activeIndex];
    let survey = null
    if (activeSection.type === "survey") {
      if (stepData["builder"] && stepData.builder.length) {
        survey = stepData["builder"]
      } else if (stepData?.reviewBuilder?.blocks) {
        survey = stepData?.reviewBuilder?.blocks
      }
    }
    
    if (loading || !activeSection) {
      return;
    }

    setLoading(true);

    let index = activeSection.type;
    if (activeSection.index) {
      index += capitalizeFirstLetter(activeSection.index);
    }

    const body = {
      ...(survey && { survey }),
      customizer,
      id: campaign._id,
      section: index,
    }
    
    if (stepData.raffleSetup) {
      body["raffleSetup"] = stepData.raffleSetup
    }

    try {
      const response = await getCampaignPreview(body);
      setPreviewHTML(response);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleClick = useCallback(
    (index, title) => {
      if (index === activeIndex) {
        setActiveIndex(-1);

        return;
      }

      setActiveIndex(index);
      setPreviewTitle(title);
    },
    [activeIndex]
  );

  return (
    <form className="an-row w-100 h-100" onSubmit={handleSubmit}>
      <CustomizationOptionsContainer className="an-col_3_12">
        {sections.map((section, index) => (
          <CustomizationElement
            key={`CustomizationElement_${index}`}
            title={section.title}
            description={section.description}
            icon={section.icon}
            onClick={() => handleClick(index, section.title)}
            open={activeIndex === index}
            tooltip={section.tooltip}
            subtitle={section.subtitle}
            bodyClassName="an-layout_gap_16"
            isPreview
          />
        ))}
      </CustomizationOptionsContainer>

      <CustomizationContainer
        showPlaceholder={activeIndex < 0}
        key={`customization-container`}
        className="an-col_7_12"
        mobileView={activeInstance === 1}
        previewHTML={previewHTML}
        loading={loading}
      >
        {activeIndex >= 0 && (
          <div className="mt-8">
            <PreviewInstances
              active={activeInstance}
              onChange={setActiveInstance}
              title={previewTitle}
            />
          </div>
        )}
      </CustomizationContainer>

      <StepWizardNavigation
        onPrev={() => handlePrev(customizer, "customizer")}
      />
    </form>
  );
};

export default PreviewStep;
