import { useState, useEffect } from "react";
import MainContent from "../../dashboard/MainContent";
import TabNavBar from "../../ui-elements/tab-nav-bar/TabNavBar";
import { useLocation, Outlet } from "react-router-dom";
import { getShopWidgets } from "../../api/Shop";
import WithValidSubScription from "../../with-valid-subscription/WithValidSubScription";

const WidgetPage = () => {
  const { pathname } = useLocation();
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchWidgets();
  }, []);

  const fetchWidgets = async () => {
    try {
      const response = await getShopWidgets();

      setWidgets(response);
    } catch (error) {
      console.error(error);
    }
  }

  const tabNavItems = () => {
    const pathArr = pathname.slice(1).split("/");

    return [
      {
        label: "Overview",
        to: ``,
        active: pathArr.length < 2,
        disabled: widgets.length === 0
      },
      {
        label: "Archive",
        to: `archive`,
        active: pathArr.includes("archive"),
        disabled: widgets.length === 0
      },
    ];
  }

  return (
    <>
      <TabNavBar items={tabNavItems()} />
      <Outlet />
    </>
  );
};

export default WithValidSubScription(WidgetPage);
