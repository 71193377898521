export const getFilterItems = (type, slug) => {
	if (type === 'tools') {
		return [
			{
				label: 'Accepted',
				value: 'subscribe',
			},
			{
				label: 'Rejected',
				value: 'unsubscribe',
			},
			{
				label: 'No answer',
				value: 'request',
			},
		]
	}

	const items = [
		{
			label: 'Clicked',
			value: 'Clicked',
		},
		{
			label: 'Opened',
			value: 'Opened',
		},
		{
			label: 'Purchased',
			value: 'Purchased',
		},
		{
			label: 'Unsubscribed',
			value: 'Unsubscribed',
		}
	]

	if (slug == 'customer-survey') {
		items.push(...[
			{
				label: 'Opened survey',
				value: 'Opened survey',
			},
			{
				label: 'Completed survey',
				value: 'Completed survey',
			}
		])
	}

  if (slug === 'product-review') {
    items.push(...[
      {
        label: 'Answered',
        value: 'Answered'
      }
    ]);
  }

	return items
}

export const getTableStyle = (params) => {
  const { type, slug, single } = params
  let tableStyles = '--data-table-library_grid-template-columns: 180px minmax(0, 1fr) repeat(2, 180px)';
  
  if (type === 'open-questions') {
	  tableStyles = '--data-table-library_grid-template-columns: repeat(3, 1fr) 60px';
  }

  if (type === 'participants') {
	  tableStyles = '--data-table-library_grid-template-columns: repeat(4, 1fr) 90px';
  }

  if (type === 'tools') {
    tableStyles = '--data-table-library_grid-template-columns: 58px repeat(2, minmax(0, 1fr)) 120px 180px 48px';
  }

  if (type === 'subscribers') {
    tableStyles = '--data-table-library_grid-template-columns: repeat(4, 25%)';
  }

  if (slug === 'generate-new-subscribers') {
    tableStyles = single ? '--data-table-library_grid-template-columns: repeat(2, minmax(0, 1fr)) 180px 193px' : '--data-table-library_grid-template-columns: 193px 120px repeat(2, 193px) 180px 193px';
  }

  if (slug === 'email-this-product') {
    tableStyles = '--data-table-library_grid-template-columns: repeat(4, 25%)';
  }
  
  return tableStyles
}