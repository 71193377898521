import React, { useContext, useMemo } from "react";
import Preloader from "../preloader/Preloader";
import { AuthContext } from "../context/AuthContext";

const WithShop = ({ children }) => {
  const { user, shopData, loading } = useContext(AuthContext);

  const inValidShopStatus = useMemo(() => {
    if (!shopData) return true;

    return !shopData.importStatus || shopData.importStatus === "started";
  }, [shopData]);

  if (!user || !shopData || loading || inValidShopStatus) {
    return <Preloader />;
  }

  return <>{children}</>;
};

export default WithShop;
