import React, { useState, useEffect } from 'react'

import SectionHead from '../../../ui-elements/common/SectionHead';
import Toogler from '../../../ui-elements/toogler/Toogler';

import Card from './Card';
import OverviewTable from './OverviewTable';
import YourCampaigns from './YourCampaigns';

import './styles.scss'

const CampaignPerformance = ({ data }) => {
	const [type, setType] = useState('day');
  const [bestPerformingCampaign, setBestPerformingCampaign] = useState(null);
  const [worstPerformingCampaign, setWorstPerformingCampaign] = useState(null);

  useEffect(() => {
    let bestPerformingCampaignId = '';
    let worstPerformingCampaignId = '';
    let bestRevenue = 0;
    let worstRevenue = 0;

    if (data?.overview?.overall) {
			const { campaigns } = data.overview;

      Object.keys(campaigns).map(shopCampaignId => {
        if (shopCampaignId !== 'overall') {

          if (campaigns[shopCampaignId].revenue > bestRevenue) {
            bestPerformingCampaignId = shopCampaignId;
            bestRevenue = campaigns[shopCampaignId].revenue;
          }
  
          if (campaigns[shopCampaignId].revenue < worstRevenue || worstRevenue === 0) {
            worstPerformingCampaignId = shopCampaignId;
            worstRevenue = campaigns[shopCampaignId].revenue;
          }
        }
      });
    }

    if (bestPerformingCampaignId) {
			const { campaigns } = data.overview;

			const campaign = campaigns[bestPerformingCampaignId]
      setBestPerformingCampaign({
        name: campaign.campaignName,
        title: 'Best performing campaign',
        value: bestRevenue,
        params: [
          {
            name: 'Conversion rate',
            value: `${campaign.totalSends ? (campaign.orders / campaign.totalSends * 100).toFixed(2) : 0}%`,
            type: 'percent',
          },
          {
            name: 'Amount of orders',
            value: campaign.orders.toLocaleString()
          },
          {
            name: 'Average order value',
            type: 'currency',
            value: campaign.orders ? (campaign.revenue / campaign.orders).toFixed(2) : 0
          }
        ]
      });
    }

    if (worstPerformingCampaignId) {
			const { campaigns } = data.overview;

			const campaign = campaigns[worstPerformingCampaignId]
      setWorstPerformingCampaign({
        name: campaign.campaignName,
        title: 'Worst performing campaign',
        value: worstRevenue,
        params: [
          {
            name: 'Conversion rate',
            value: `${campaign.totalSends ? (campaign.orders / campaign.totalSends * 100).toFixed(2) : 0}%`,
            type: 'percent',
          },
          {
            name: 'Amount of orders',
            value: campaign.orders.toLocaleString()
          },
          {
            name: 'Average order value',
            type: 'currency',
            value: campaign.orders ? (campaign.revenue / campaign.orders).toFixed(2) : 0
          }
        ]
      });
    }
  }, [data]);

  return (
    <div className='an-col card card_shadow card_br_24 card_24'>
      <SectionHead
				title={
					<>
						<div className="email-templates-subtitle-2-medium">Campaigns performance</div>
						<div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">Campaigns performance report for your selected dates.</div>
					</>
				}
				filter={
					<Toogler
						items={[
							{ value: 'day', text: 'Day' },
							{ value: 'week', text: 'Week' },
							{ value: 'month', text: 'Month' },
						]}
						defaultStatus={type}
						onChange={(value) => setType(value)}
					/>
				}
			/>
      <div className='an-row mt-24'>
        {!!bestPerformingCampaign && <Card campaign={bestPerformingCampaign} />}
        {!!worstPerformingCampaign && <Card campaign={worstPerformingCampaign} />}
      </div>
      {!!data?.overview && (
        <div className='an-row mt-24'>
          <OverviewTable data={data?.overview} />
        </div>
      )}
			<div className='an-row mt-24'>
        {data?.campaigns && <YourCampaigns data={data} type={type} />}
			</div>
    </div>
  );
}

export default CampaignPerformance;