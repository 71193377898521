import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import styles from "./TabNavBar.module.scss";

const TabNavBar = (props) => {
  const { items, filter } = props;

  return (
    <div
      className={classNames(
        styles["base"],
        "top-bar__line top-bar__line_secondary"
      )}
    >
      <div className={styles["tabs-nav"]}>
        {items.map((item, index) => (
          <NavLink
            key={`tab-nav-link-${index}`}
            to={item.to}
            className={classNames(`ui-caption-medium`, styles["link"], {
              [`${styles["active"]}`]: item.active,
              [`${styles["disabled"]}`]: item.disabled,
            })}
          >
            {item.label}
          </NavLink>
        ))}
      </div>
      {filter && filter}
    </div>
  );
};

export default TabNavBar;
