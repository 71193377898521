import UiHeading from "../ui-elements/text/UiHeading";
import UiButton from "../ui-elements/buttons/UiButton";
import illustration from "../static/images/campaign/Illustration.svg";

const CampaignNotIncluded = () => {
  return (
    <div className="card card_h_100 card_flex card_align_center card_br_24 card_24 card_shadow text-center">
      <div className="card__overflow">
        <UiHeading
          text="This quest is reserved for upgraded plans ⚠️"
          desktop="email-templates-subtitle-2-bold"
        />
        <div className="email-templates-paragraph-1-regular mt-8">Eager for the full quest?. Upgrade now for boundless campaigns or <br />chart a different course within your current plan. Your campaign saga, your decision!</div>
        <div className="mt-40">
          <img
            src={illustration}
            alt="This quest is reserved for upgraded plans"
            className="responsive-img"
          />
        </div>
        <div className="mt-40">
          <UiButton
            elem="a"
            href="/billing/packages"
            text="Upgrade my plan"
            size="medium"
          />
        </div>
      </div>
    </div>
  )
}

export default CampaignNotIncluded;