import axios from "axios";
import React, { useState, useCallback } from "react";
import UiCaptionRegular from "../../text/UiCaptionRegular";
import FormControl from "../../forms/FormControl";
import ReactSelect from "react-dropdown-select";
import UiCaptionMedium from "../../text/UiCaptionMedium";

const Select = (props) => {
  const [options, setOptions] = useState([]);
  const fetchOptions = async () => {
    if (typeof props.options === "string") {
      const url = props.options.includes("/api")
        ? props.options.slice(4)
        : props.options;

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        let items = response.data;
        if (props.options.includes("product")) {
          items = response.data.items;
        }

        setOptions(
          items.map((option) => ({
            label: option.name,
            value: option._id,
          }))
        );
      } catch (e) {
        console.log("e", e);
      }
    } else {
      setOptions(props.options);
    }
  };

  if (options.length === 0) {
    fetchOptions();
  }

  const handleOnChange = (e) => {
    if (e[0]) props.onChange(e[0].value);
  };
  const values = options.filter((option) => String(option.value) === String(props.value));

  return (
    <div>
      {props.label && <UiCaptionMedium text={props.label} className="mb-4" />}

      <ReactSelect
        options={options}
        placeholder={props.placeholder}
        onChange={handleOnChange}
        className="form-select"
        values={values}
      />

      {/* <FormControl
        type={`radio`}
        value={props.value}
        onChange={handleOnChange}
        options={options.map((option) => ({
          label: option.name,
          value: option._id,
        }))}
        required={props.required}
        validationError={props.validationError}
        blockRadios
        blocks={2}
        variant="pill"
      /> */}
    </div>
  );
};

export default Select;
