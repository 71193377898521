import { useState, useEffect } from "react";

import "./Toogler.scss";

const Toogler = ({ items, defaultStatus, label, onChange, className='' }) => {
  const [state, setState] = useState(defaultStatus);

  const handleClick = (value) => {
    if (value === state) return;
    setState(value)
    onChange && onChange(value)
  }

	return (
		<div className={`toogler-wrap ${className}`}>
      {label && <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7">{label}</div>}
			<div className="toogler-wrap__toggler">
        {items.map(({value, text}) => {
          return (
            <div 
              key={value}
              tabIndex={0}
              onClick={() => handleClick(value)}
              className={`toogler-wrap__item ${state === value ? ' toogler-wrap__item_active' : ''}`}
            >
              {text}
            </div>    
          )
        })}
			</div>
		</div>
	);
}

export default Toogler;