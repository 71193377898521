import { useState, useEffect } from "react";
import FormControl from "../ui-elements/forms/FormControl";
import { Table, Header, HeaderRow, Body, Row, Cell, HeaderCell } from "@table-library/react-table-library/table";
import { useSort, HeaderCellSort } from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { Image } from "akar-icons";
import AnTable from "../ui-elements/common/AnTable";
import AkarIconsChevronVertical from '../static/images/common/akar-icons_chevron-vertical.svg';
import AkarIconsChevronVerticalCopy from '../static/images/common/akar-icons_chevron-vertical copy.svg';
import "./WidgetItemsList.scss";
import Preloader from "../preloader/Preloader";

const ProductsList = ({ itemsData, total, loading, values }) => {
  const [recentCount, setRecentCount] = useState(100);
  const [search, setSearch] = useState('');
  const nodes = itemsData?.items ? itemsData.items : [];
  let data = { nodes };

  useEffect(() => {
    setRecentCount(values?.number && values?.number[0]?.value && values.number[0].value < 100 ? values?.number[0].value : 100);
  }, [values]);

  const handleSearch = e => {
    setSearch(e.target.value);
  }

  data = {
    nodes: data.nodes.filter(item => item.name.toLowerCase().includes(search.toLowerCase()))
  }

  const theme = useTheme([getTheme(), {
    Table: `
      --data-table-library_grid-template-columns: 60px repeat(2, minmax(0, 1fr)) 100px
    `
  }]);

  const sort = useSort({ nodes }, {}, {
    sortIcon: {
      margin: '0px',
      iconDefault: <img src={AkarIconsChevronVertical} alt="" />,
      iconUp: <img src={AkarIconsChevronVerticalCopy} alt="" />,
      iconDown: <img src={AkarIconsChevronVerticalCopy} alt="" />
    },
    sortFns: {
      PRODUCTNAME: array => array.sort((a, b) => a.name.localeCompare(b.name)),
      SKU: array => array.sort((a, b) => a.sku.localeCompare(b.sku)),
      PRICE: array => array.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)),
    }
  });

  return (
    <div className="widget-items-list">
      <div className="widget-items-list__head">
        <div className="email-templates-paragraph-1-medium">{itemsData?.total ? `Displaying most recent ${(itemsData.total < 100 && itemsData.total < recentCount) ? itemsData.total : recentCount} products` : 'No products found'}</div>
        <FormControl
          type="text"
          name="search"
          id="search"
          placeholder="Search"
          fieldType="search"
          value={search}
          onChange={handleSearch}
        />
      </div>
      <div className="widget-items-list__body">
        {itemsData?.items && (
          <AnTable
            body={
              <Table
                data={data}
                sort={sort}
                theme={theme}
                layout={{
                  custom: true
                }}
              >
                {(tableList) => (
                  <>
                    <Header>
                      <HeaderRow>
                        <HeaderCell>
                          <div className="widget-items-list__icon">
                            <Image
                              color="#d9d9d9"
                              size="16"
                            />
                          </div>
                        </HeaderCell>
                        <HeaderCellSort sortKey="PRODUCTNAME">Product Name</HeaderCellSort>
                        <HeaderCellSort sortKey="SKU">SKU</HeaderCellSort>
                        <HeaderCellSort sortKey="PRICE">Price</HeaderCellSort>
                      </HeaderRow>
                    </Header>
                    <Body>
                      {tableList.map(item => (
                        <Row
                          item={item}
                          key={item._id}
                        >
                          <Cell>
                            {item.images && item.images.length && (
                              <img
                                src={item.images[0].src}
                                alt={item.name}
                                className="responsive-img"
                              />
                            )}
                          </Cell>
                          <Cell>{item.name}</Cell>
                          <Cell>{item.sku}</Cell>
                          <Cell>
                            <div dangerouslySetInnerHTML={{ __html: item.price_html }} />
                          </Cell>
                        </Row>
                      ))}
                    </Body>
                  </>
                )}
              </Table>
            }
          />
        )}
      </div>
      <div className="email-templates-caption-regular color-secondary-colour-secondary-7">{total ? `${total} items` : ''}{itemsData?.total && itemsData.total !== total ? ` | ${itemsData.total} products match your current selection` : ''}</div>
      {loading && <Preloader absolute={true} overflow={true} />}
    </div>
  )
}

export default ProductsList;