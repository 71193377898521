import React, { useState } from 'react'
import Modal from "react-modal";
import { Cross } from "akar-icons";
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import UiHeading from '../ui-elements/text/UiHeading';
import UiCaptionRegular from '../ui-elements/text/UiCaptionRegular';
import UiButton from '../ui-elements/buttons/UiButton';
import { Notify } from '../ui-elements/notify/notify';

import Preloader from "../preloader/Preloader";

import { createPaymentMethod } from '../api/Auth';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PaymentModal = ({open, setOpen, handlePaymentMethodUpdate, handleCreate, handleClose}) => {
  const [loading, setLoading] = useState(false)
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
      });

      if (error) {
        // if (error.type == 'card_error') {
          Notify.error({ title: 'Error', message: error.message });
        // }
      } else {
        if (handleCreate) {
          await handleCreate(paymentMethod.id);
        } else {
          await createPaymentMethod(paymentMethod.id);

          Notify.success({
            title: 'Changes saved successfully',
            message: 'A new payment method has been added.'
          });
          handlePaymentMethodUpdate && handlePaymentMethodUpdate();
          setOpen(false);
        }
      }

    } catch (error) {
      Notify.error({ title: error.response.data.message });
    }

    setLoading(false);
  }

  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      className="an-modal over-flow-hidden"
      overlayClassName="an-overlay"
    >
      <div className="an-modal__head">
        <div className="an-modal__title">
          <UiHeading
            element="h6"
            desktop="other-headings-h6-bold"
            text="Add new payment method"
          />
          <UiCaptionRegular
            className="mt-8 color-secondary-colour-secondary-7"
            text="Add a new payment method to ensure flexibility in your transactions."
          />
        </div>
        <button
          onClick={() => setOpen(false)}
          className="an-modal__close"
        >
          <Cross
            size="24"
            color="#d9d9d9"
          />
        </button>
      </div>
      <form
        onSubmit={handleSubmit}
        className="an-modal__body an-modal__body_mh_0 mt-32 pt-32 bt-1 text-left"
      >
        <CardElement />
        <div className="mt-24 pt-24 bt-1 text-right">
          <div className="button-group">
            <UiButton
              size="medium"
              design="secondary"
              text="Cancel"
              onClick={() => {
                setOpen(false);
                handleClose && handleClose();
              }}
            />
            <UiButton
              size="medium"
              text="Save"
              type="submit"
            />
          </div>
        </div>
      </form>
      {loading && <Preloader absolute={true} overflow={true} /> }
    </Modal>
  );
}

export default (props) => (
  <Elements stripe={stripePromise}>
    <PaymentModal {...props} />
  </Elements>
)