import { useState, useEffect } from "react";
import Modal from "react-modal";
import { Cross } from "akar-icons";
import axios from "axios";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import Admins from "../../store-settings/Admins";
import Employees from "../../store-settings/Employees";
import UiButton from "../../ui-elements/buttons/UiButton";
import UiCaptionRegular from "../../ui-elements/text/UiCaptionRegular";
import FormControl from "../../ui-elements/forms/FormControl";
import Preloader from "../../preloader/Preloader";
import MainContent from "../../dashboard/MainContent";

Modal.setAppElement("#root");

const StoreUsersPage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [permission, setPermission] = useState("admin");
  const [cancelDisabled, setCancelDisabled] = useState(true);
  const [added, setAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const initialData = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const [data, setData] = useState(initialData);

  const add = (perm) => {
    setModalIsOpen(true);
    setPermission(perm);
  };

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  useEffect(() => {
    let dataChanged = false;

    Object.keys(initialData).map((key) => {
      if (initialData[key] !== data[key]) {
        dataChanged = true;
      }

      return dataChanged;
    });

    setCancelDisabled(!dataChanged);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    data.permission = permission;

    axios
      .post("/user", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(() => {
        setAdded(Math.random());
        setLoading(false);
        setModalIsOpen(false);
      })
      .catch((err) => {
        console.error(err);

        setErrors(err.response.data.errors);
        setLoading(false);
      });
  };

  const reset = (e) => {
    e.preventDefault();

    setData(initialData);
  };

  return (
    <MainContent>
      <div className="an-layout">
        <SectionHead
          title={
            <>
              <UiHeading
                text="Admins"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                Store owners are admin type users. Every store must have at
                least one admin.
              </div>
            </>
          }
        />
        <Admins add={add} added={added} />
        <SectionHead
          title={
            <>
              <UiHeading
                text="Employees"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                Employees have limited access to store settings, such as
                billing.
              </div>
            </>
          }
        />
        <Employees add={add} added={added} />
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setModalIsOpen(false)}
          className="an-modal"
          overlayClassName="an-overlay"
        >
          <div className="an-modal__head">
            <div className="an-modal__title">
              <UiHeading
                element="h6"
                desktop="other-headings-h6-bold"
                text={
                  permission === "admin"
                    ? "Add a new admin"
                    : "Add new employee"
                }
              />
              <UiCaptionRegular
                className="mt-8 color-secondary-colour-secondary-7"
                text={
                  permission === "admin"
                    ? "Provide the necessary information to invite a new admin to join your team."
                    : "Input the required details to invite new team members."
                }
              />
            </div>
            <button
              onClick={() => setModalIsOpen(false)}
              className="an-modal__close"
            >
              <Cross size="24" color="#d9d9d9" />
            </button>
          </div>
          <form
            className="an-modal__body an-modal__body_mh_0"
            onSubmit={handleSubmit}
          >
            <div className="mt-32 pt-32 bt-1">
              <FormControl
                type="text"
                name="firstName"
                id="first_name"
                label="First name"
                labelClassName="ui-caption-medium"
                labelMargin="4"
                placeholder="First Name"
                required={true}
                value={data.firstName}
                onChange={handleChange}
                validationError={errors.firstName}
              />
              <FormControl
                type="text"
                name="lastName"
                id="last_name"
                label="Last name"
                labelClassName="ui-caption-medium"
                labelMargin="4"
                placeholder="Last Name"
                margin="24"
                required={true}
                onChange={handleChange}
                value={data.lastName}
                validationError={errors.lastName}
              />
              <FormControl
                type="email"
                name="email"
                id="email"
                label="Email"
                labelClassName="ui-caption-medium"
                labelMargin="4"
                placeholder="Email"
                margin="24"
                required={true}
                onChange={handleChange}
                value={data.email}
                validationError={errors.email}
              />
            </div>
            <div className="mt-32 pt-32 bt-1 flex-right gap-8">
              <UiButton
                size="medium"
                design="secondary"
                text="Cancel"
                disabled={cancelDisabled}
                onClick={reset}
              />
              <UiButton size="medium" text="Save changes" />
            </div>
          </form>
          {loading ? <Preloader absolute={true} overflow={true} /> : ""}
        </Modal>
      </div>
    </MainContent>
  );
};

export default StoreUsersPage;
