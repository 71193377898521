import React from 'react'

import UiButton from '../../../ui-elements/buttons/UiButton';
import CampaignStep from '../../../ui-elements/campaign-step/CampaignStep';

const Default = ({
	introCards, 
	onNext,
	mode,
	campaignSlug,
	handleAIGenerate
}) => {
	return (
		<>
			<div className="cp-steps-wrapper mt-48 pt-24 pb-24">
				{introCards.map((step, key) => (
					<CampaignStep key={key} step={step} />
				))}
			</div>
			<div className="mt-40 button-group button-group_center">
				<UiButton
					design="secondary"
					size="medium"
					text="Go back"
					elem="a"
					href={`/campaigns/${campaignSlug}`}
					className="ui-button_mw_180"
				/>
				<UiButton
					size="medium"
					text="Get started"
					className="ui-button_mw_180"
					type="button"
					onClick={(e) => {
						if (mode == 'ai') {
							handleAIGenerate();
						} else {
							onNext(e);
						}
					}}
				/>
			</div>
		</>
	)
}

export default Default;