import React, { useState, useContext, useEffect, useMemo } from "react";
import FormControl from "../ui-elements/forms/FormControl";
import UiButton from "../ui-elements/buttons/UiButton";
import { saveBillingInformation } from "../api/Shop";
import Preloader from "../preloader/Preloader";
import { AuthContext } from "../context/AuthContext";
import { Notify } from "../ui-elements/notify/notify";

const BillingInformation = () => {
  const [info, setInformation] = useState({});
  const { shopData, updateShop } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!shopData?.billingInformation) {
      return;
    }

    setInformation(shopData.billingInformation || {});
  }, [shopData]);

  const handleOnChange = e => setInformation({ ...info, [e.target.name]: e.target.value });

  const handleSaveInfo = async (e) => {
    e.preventDefault();

    if (Object.keys(info).length === 0 || loading) {
      return;
    }

    setLoading(true);

    try {
      await saveBillingInformation(info);
      await updateShop();

      Notify.success({
        title: 'Changes saved successfully',
        message: 'Your billing information have been updated.'
      });
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const isChanged = useMemo(() => {
    return (JSON.stringify(info) === JSON.stringify(shopData.billingInformation || {}));
  }, [shopData.billingInformation, info, loading]);
  
  return (
    <form className="card card_24 card_shadow card_overflow_visible" onSubmit={handleSaveInfo}>
      <div className="an-layout">
        <div className="an-row">
          <div className="an-col an-col_50">
            <FormControl
              label="Company address"
              type="text"
              id="company_address"
              name="companyAddress"
              placeholder="Company address"
              value={info.companyAddress || ''}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
            />
          </div>
          <div className="an-col an-col_50">
            <FormControl
              label="City"
              type="text"
              id="city"
              name="city"
              placeholder="City"
              value={info.city || ''}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
            />
          </div>
        </div>
        <div className="an-row">
          <div className="an-col an-col_50">
            <FormControl
              label="Postal /ZIP Code"
              type="text"
              id="postal_zip_code"
              name="postalZipCode"
              placeholder="Postal /ZIP Code"
              value={info.postalZipCode || ''}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
            />
          </div>
          <div className="an-col an-col_50">
            <FormControl
              label="State/Province"
              type="text"
              id="state_province"
              name="stateProvince"
              placeholder="State/Province"
              value={info.stateProvince || ''}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
            />
          </div>
        </div>
        <div className="an-row">
          <div className="an-col an-col_50">
            <FormControl
              label="Country"
              type="text"
              id="country"
              name="country"
              placeholder="Country"
              value={info.country || ''}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
            />
          </div>
          <div className="an-col an-col_50">
            <FormControl
              label="VAT number"
              type="text"
              id="vat_number"
              name="vatNumber"
              placeholder="VAT Number"
              value={info.vatNumber || ''}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
            />
          </div>
        </div>
        <div className="flex-right gap-8 mt-4">
          <UiButton
            type="button"
            text="Cancel"
            size="medium"
            design="secondary"
            onClick={() => setInformation(shopData.billingInformation || {})}
            disabled={loading || isChanged}
          />
          <UiButton
            text="Save changes"
            size="medium"
            disabled={loading || isChanged}
          />
        </div>
        {loading && <Preloader absolute={true} overflow={true} />}
      </div>
    </form>
  );
};

export default BillingInformation;
