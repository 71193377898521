import { mdiButtonPointer } from "@mdi/js";
import {
  Image,
  Star,
  InstagramFill,
  Phone,
  Location,
  PaperAirplane,
  Gift,
  Fire,
  ThumbsUp,
  Grid,
  RadioFill,
  CheckBox,
  Question,
  Heart,
  Gear,
  Calendar,
  PeopleGroup,
  Clipboard,
  Bell,
  Info,
  Trophy,
  CircleCheck,
  CircleX
} from "akar-icons";

export const MDI_ICON_MAP = {
  mdiButtonPointer: mdiButtonPointer,
};

export const AKAR_ICON_MAP = {
  Image: Image,
  Star: Star,
  InstagramFill: InstagramFill,
  Phone: Phone,
  Location: Location,
  PaperAirplane: PaperAirplane,
  Gift,
  Fire,
  ThumbsUp,
  Grid,
  RadioFill,
  CheckBox,
  Question,
  Heart,
  Gear,
  Calendar,
  PeopleGroup,
  Clipboard,
  Bell,
  Info,
  Trophy,
  CircleCheck,
  CircleX
};
