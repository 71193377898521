import React, { useState, useMemo } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import SectionHead from '../../../ui-elements/common/SectionHead';
import illustrations from "../../../static/images/store-settings/Illustrations.svg";
import Dropdown from '../../../ui-elements/dropdown/Dropdown';
import createChartTooltip from "../../../ui-elements/charts/createCustomTooltip";
import Preloader from '../../../preloader/Preloader';
import Legend from "../Legend";
import EmptyData from '../EmptyData';

import { getDataSet } from './tools';

import { getQuestion } from '../../../api/Survey';

const Answers = ({ campaign }) => {
  console.log(campaign);
  ChartJS.register(ArcElement, Tooltip);

  const [question, setQuestion] = useState('');
  const [type, setType] = useState('checkbox')
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);

  const colors = {
    checkbox: ['#B37FEB', '#7DE2FF', '#FF85C0', '#FFC53D', '#52C41A'],
    rating: ['#52C41A', '#BAE637', '#FFD666', '#FF9C6E', '#FF4D4F'],
  }

  const getData = async (item) => {
    try {
      setQuestion(item.text)
      setType(['checkbox', 'radio'].includes(item.type) ? 'checkbox' : item.type)
      setLoading(true)

      const response = await getQuestion(campaign._id, item._id);

      console.log(response);

      setChartData(response)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const { datasets, totalValue } = useMemo(() => {
    return getDataSet(chartData || [], colors[type])
  }, [chartData])

  const questions = useMemo(() => {
    return campaign.builder.reduce((acc, item) => {
      if (item.type != 'textarea') return acc.concat({ text: item.question, ...item })
      return acc
    }, [])
  }, [])

  return (
    <>
      <SectionHead
        title={
          <>
            <div className="email-templates-subtitle-2-medium">
              Answers
            </div>
            <div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">
              {question}
            </div>
          </>
        }
        filterClassName={'section-head__filter-without_shrink'}
        filter={
          <Dropdown
            data={questions}
            noDataText='The list of questions is empty'
            onSelect={(item) => getData(item)}
            disabled={campaign.completedSurveys === 0}
          />
        }
      />
      <div className="mt-24 an-row items-center">
        {campaign.completedSurveys === 0 ? (
          <EmptyData />
        ) : (
          <>
            {!chartData ?
              <div className="pt-30 pb-30 mt-40 text-center">
                <img
                  src={illustrations}
                  alt="Select one question to see the chart"
                  className="responsive-img"
                />
                <div className="ui-caption-regular color-secondary-colour-secondary-4 mt-4">
                  Select one question to see the chart
                </div>

              </div>
              :
              <>
                <div className="an-col an-col_53 position-relative">
                  <Doughnut
                    data={{ datasets: datasets }}
                    options={{
                      borderRadius: 6,
                      cutout: 50,
                      responive: true,
                      clip: 10,
                      plugins: {
                        tooltip: {
                          enabled: false,
                          callbacks: {
                            title: (tooltipItem) => tooltipItem[0]?.dataset.labels[0]
                          },
                          external: createChartTooltip
                        }
                      }
                    }}
                  />
                  <div className="overview-emails">
                    <div className="overview-emails__count">{totalValue}</div>
                    <div className="overview-emails__label color-secondary-colour-secondary-7">Answers</div>
                  </div>
                </div>
                <div className="an-col an-col_47 d-flex d-flex_column d-flex_gap_8">
                  {chartData.map((item, index) =>
                    <Legend
                      key={index}
                      label={item.name}
                      value={item.value}
                      total={totalValue}
                      color={colors[type][index]}
                    />
                  )}
                </div>
              </>
            }
          </>
        )}
        {loading && <Preloader absolute={true} overflow={true} />}
      </div>
    </>
  );
}

export default Answers;