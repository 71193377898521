import React from "react";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "./context/AuthContext";
import AppRoutes from "./routes/AppRoutes";
import CampaignContextProvider from "./context/CampaignContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./App.scss";

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        closeButton={false}
        rtl={false}
        limit={2}
      />
      <BrowserRouter>
        <CampaignContextProvider>
          <AuthContextProvider>
            <AppRoutes />
          </AuthContextProvider>
        </CampaignContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
