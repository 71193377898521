import React from "react";
import styles from "./MainContent.module.scss";
import classNames from "classnames";

const MainContent = (props) => {
  const { children, className, fullHeight, ...rest } = props;
  return (
    <div
      id="main_content"
      className={classNames(
        styles["base"],
        { [`${styles["full-height"]}`]: fullHeight },
        className
      )}
      {...rest}
    >
      <div className={classNames(styles["inner"])}>{children}</div>
    </div>
  );
};

export default MainContent;
