import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Navigate } from "react-router-dom";

const Private = ({ allowedRoles, children }) => {
  const { user } = useContext(AuthContext);

  if (allowedRoles.includes(user.permission)) {
    return children;
  } else {
    return <Navigate to="/" replace />;
  }
};

export default Private;
