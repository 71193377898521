import React from 'react'

import UiSmallRegular from '../../../ui-elements/text/UiSmallRegular'
import UiCaptionRegular from '../../../ui-elements/text/UiCaptionRegular'
import Divider from '../../../ui-elements/divider/Divider'
import useAuth from '../../../hooks/useAuth';

const Card = ({campaign}) => {
	const { shopData } = useAuth();
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: shopData.currency,
		maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	});

	const { name, value, params, title } = campaign
	return (
		<div className='campaign-stat an-col an-col_50'>
				<div className='campaign-stat__header mb-16'>
					<div>
						<UiSmallRegular 
							className='campaign-stat__title'
							text={title}
						/>
						<div className='email-templates-paragraph-1-medium'>{name}</div>
					</div>
					<div className='email-templates-h1-semibold'>{formatter.format(value)}</div>
				</div>
				<Divider className="mb-16" />
				<div className='campaign-stat__content'>
					{params.map(({name, value, type}, index) => {
						let _value = value
						if (type === 'currency') { 
							_value = formatter.format(value);
						}

						return (
							<React.Fragment key={index}>
								<UiCaptionRegular text={name} />
								<UiCaptionRegular text={_value} className="text-right" />
							</React.Fragment>
						)
					})}
				</div>
		</div>
	);
}

export default Card;