import { useContext, useMemo } from 'react'
import { useParams } from "react-router-dom";

import ConsentManagerCampaignPerformance from './ConsentManagerCampaignPerformance';
import ConsentPerformance from './ConsentPerformance'
import CampaignInstances from '../../../campaigns/overviews/CampaignInstances'

import { CampaignContext } from "../../../context/CampaignContext";

const ConsentOverview = ({ tool, statData }) => {
  const { toolSlug } = useParams();
  const { shopCampaigns } = useContext(CampaignContext);
  const filteredShopCampaign = useMemo(() => shopCampaigns.filter((campaign) => campaign.slug === toolSlug), [shopCampaigns])

	return (
		<div className='an-layout'>
      <div className='an-row'>
        <ConsentManagerCampaignPerformance data={statData.campaignPerformance} />
      </div>
      <div className='an-row'>
        <ConsentPerformance data={statData.consentPerformance} />
      </div>
      <div className='an-row'>
        <CampaignInstances 
          campaigns={filteredShopCampaign} 
          slug={tool.slug}
        />
      </div>
		</div>
	)
}

export default ConsentOverview