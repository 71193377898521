import MainContent from '../../../dashboard/MainContent'

import General from './General';
import Optimisation from './Optimisation';

const DeliverySettings = () => {
 
	return (
		<MainContent>
      <General />
      <Optimisation />
		</MainContent>
	)
}

export default DeliverySettings