import { useParams } from "react-router-dom";
import ActivityTable from "../../ui-elements/activity-table/ActivityTable";
import AbandonedCarts from "../../ui-elements/abandoned-carts/AbandonedCarts";

const UserActivityPage = () => {
  const { campaignSlug } = useParams();

  if (campaignSlug === 'cart-abandonment') {
    return (
      <AbandonedCarts />
    )
  }

  return (
    <ActivityTable
      slug={campaignSlug}
      type="campaign-activity"
    />
  )
}

export default UserActivityPage;
