import React, { useState } from 'react'
import Select from 'react-dropdown-select';

import ToolsPerformance from '../ToolsPerformance';
import CampaignIcon from '../../../ui-elements/common/CampaignIcon';
import UiCaptionRegular from '../../../ui-elements/text/UiCaptionRegular';
import UiHeading from '../../../ui-elements/text/UiHeading';
import UiCaptionMedium from '../../../ui-elements/text/UiCaptionMedium';
import Legend from '../../../campaigns/overviews/Legend';
import Chips from '../../../ui-elements/common/Chips';
import Divider from '../../../ui-elements/divider/Divider';

import { calculateTrend } from '../tools';

const colors = ['#9254de', '#4bd4fb', '#d3adf7', '#85a5ff', '#ff7875']

const YourCampaigns = ({ data, type }) => {
  const { overview, campaigns } = data ?? {};
  const [selectedCampaign, setSelectedCampaign] = useState([{value: Object.keys(campaigns)[0], ...campaigns[Object.keys(campaigns)[0]]}])
  const selectedCampaignData = overview.campaigns[selectedCampaign[0].value]

  if (!selectedCampaignData) {
    return;
  }

  const getConversion = () => {
    const totalSends = selectedCampaignData.totalSends
    const orders = selectedCampaignData.orders

    if (!totalSends) return 0
    return `${((orders / totalSends) * 100).toFixed(1)}%`
  }

  const trend = calculateTrend(selectedCampaignData.totalSends, selectedCampaign[0].previousSends)
  const conversionTrend = calculateTrend(getConversion(), !selectedCampaign.previousSends ? 0 : selectedCampaign.previousOrders / selectedCampaign.previousSends)

  const legends = [
    {
      label: 'Open rate',
      value: selectedCampaignData.open
    },
    {
      label: 'Click rate',
      value: selectedCampaignData.click
    },
    {
      label: 'Unsubscribed',
      value: selectedCampaignData.unsubscribed
    },
    {
      label: 'Bounces',
      value: selectedCampaignData.bounces
    },
    {
      label: 'Complaints',
      value: selectedCampaignData.spam
    },
  ]

  return (
    <ToolsPerformance
      key={selectedCampaign[0].value}
      title="Your campaigns"
      subTitle="Your campaigns performance over time"
      withWrap={false}
      type={type}
      data={selectedCampaign[0].chart[type]}
      selectedCampaign={selectedCampaign}
      yAxis={{
        min: 0,
        max: 100,
        ticks: {
          stepSize: 25,
          callback: (value, index, ticks) => {
            return value + '%'
          }
        }
      }}
      tooltipLabel={(tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw.toFixed(2)}%`}
      filter={
        <Select
          className='form-select form-select_campaigns'
          values={selectedCampaign}
          labelField='name'
          options={Object.keys(campaigns).map(key => ({
            value: key,
            ...campaigns[key]
          }))}
          onChange={value => setSelectedCampaign(value)}
        />
      }
      Legend={() => {
        return (
          <>
            <div className='icon-wrapper'>
              <div>
                <CampaignIcon emoji={'📬'} />
              </div>
              <div>
                <UiCaptionRegular
                  className=""
                  text="Total sends"
                />
                <UiHeading
                  element="h6"
                  desktop="other-headings-h6-bold"
                  text={
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div>{selectedCampaignData.totalSends.toLocaleString()}</div>
                      <div>
                        <Chips
                          color={trend >= 0 ? 'green' : 'red'}
                          value={`${Math.abs(trend).toFixed(1)}%`}
                          arrow={true}
                        />
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
            <div className='icon-wrapper mt-20'>
              <div>
                <CampaignIcon emoji={'💸'} />
              </div>
              <div>
                <UiCaptionRegular
                  className=""
                  text="Conversion rate"
                />
                <UiHeading
                  element="h6"
                  desktop="other-headings-h6-bold"
                  text={
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div>{getConversion()}</div>
                      <div>
                        <Chips
                          color={conversionTrend >= 0 ? 'green' : 'red'}
                          value={`${Math.abs(conversionTrend).toFixed(1)}%`}
                          arrow={true}
                        />
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
            <Divider className="mt-16 mb-16" />
            <div className='legends'>
              <UiCaptionMedium text="Overall performance" />
              {legends.map(({value, label}, index) => 
                <Legend
                  key={index}
                  label={label}
                  value={value}
                  total={selectedCampaignData.totalSends}
                  color={colors[index]}
                  showTotal={false}
                />
              )}
            </div>
          </>
        )
      }}
    />
  );
}

export default YourCampaigns;