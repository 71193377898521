import React from "react";
import UiHeading from "../ui-elements/text/UiHeading";
import styles from "./WidgetEmptyStep.module.scss";
import UiBodyRegular from "../ui-elements/text/UiBodyRegular";
import UiButton from "../ui-elements/buttons/UiButton";
import illustration from "../static/images/widgets/ec-not-found.svg";

const WidgetEmptyStep = () => {
  const description = (
    <>
      Widgets offer a touch of personalisation and dynamism to your email
      campaigns. Opt to craft widgets now, <br />
      and we'll save your campaign progress before guiding you to our widget
      setup. Or, continue <br />
      and integrate widgets at a later stage.
    </>
  );

  return (
    <div className={styles["base"]}>
      <div className={styles["header"]}>
        <UiHeading
          text={`Oops! where are the widgets?`}
          desktop="other-headings-h6-bold"
        />
        {description && (
          <UiBodyRegular
            margin="8"
            className="color-secondary-colour-secondary-7 text-break-spaces"
            text={
              typeof description === "string"
                ? description.replace("<br />", "\n")
                : description
            }
          />
        )}
      </div>

      <div className={styles["illustration"]}>
        <img src={illustration} alt="" />
      </div>

      <div className="button-group">
        <UiButton
          size="medium"
          text={`Create later`}
          design="secondary"
          className="ui-button_mw_180"
        />
        <UiButton
          elem="a"
          target="_blank"
          href="/widgets/new"
          size="medium"
          text={`Create widgets`}
          className="ui-button_mw_180"
        />
      </div>
    </div>
  );
};

export default WidgetEmptyStep;
