import { useState } from "react";
import axios from "axios";
import { Row, Cell } from "@table-library/react-table-library/table";
import { Link } from "react-router-dom";
import { TrashCan } from "akar-icons";
import VerificationStatus from "../ui-elements/verification-status/VerificationStatus";
import styles from "./DomainStatus.module.scss";

const DomainItem = props => {
  const [verified, setVerified] = useState(props.item.verified);
  const [verificationDisabled, setVerificationDisabled] = useState(false);
  const [removeDisabled, setRemoveDisabled] = useState(false);
  const token = localStorage.getItem('token');

  const removeDomain = () => {
    setRemoveDisabled(true);

    axios.delete(`/domains/${props.item._id}`, {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    }).then(() => {
      props.refresh();

      setRemoveDisabled(false);
    }).catch(error => {
      console.error(error);

      setRemoveDisabled(false);
    });
  }

  const verifyDomain = () => {
    setVerificationDisabled(true);

    axios.post(`/domains/${props.item._id}/verify`, {}, {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    }).then(response => {
      setVerified(response.data);
      setVerificationDisabled(false);
    }).catch(error => {
      console.error(error);

      setVerificationDisabled(false);
    })
  }

  return (
    <Row item={props.item} >
      <Cell>
        <Link to={`/store-settings/domain-accounts/${props.item._id}`} className="underline">{props.item.name}</Link>
      </Cell>
      <Cell>
        <VerificationStatus status={verified} />
      </Cell>
      <Cell>
        {!verified ? <button onClick={verifyDomain} disabled={verificationDisabled} className={styles['blue-btn']}>Verify</button> : ''}
      </Cell>
      <Cell>
        <button
          className={styles['remove-button']}
          onClick={removeDomain}
          disabled={removeDisabled}
        >
          <TrashCan
            size="16"
            color="#d9d9d9"
          />
        </button>
      </Cell>
    </Row>
  )
}

export default DomainItem;