import React, {
  useState,
  useCallback,
  useReducer,
  useMemo,
  useEffect,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { SortableContext } from "@dnd-kit/sortable";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import validator from "validator";

import StepWizardNavigation from '../../ui-elements/step-wizard/StepWizardNavigation';
import CustomizationOptionsContainer from '../../ui-elements/customization/customiztion-options-container/CustomizationOptionsContainer';
import SurveyWorkspace from '../../ui-elements/customization/survey-workspace/SurveyWorkspace';
import CustomizationElement from '../../ui-elements/customization-element/CustomizationElement';
import QuestionBlock from '../../ui-elements/customization/question-block/QuestionBlock';
import CustomizationForms from '../../ui-elements/customization/customization-forms/CustomizationForms';

import useStep from '../../hooks/useStep';
import { isObjectEmpty } from '../../util/object';

import blockReducer from './reducer';
import fields from './fields';

const type = "rating";

const ReviewBuilder = () => {
  const {
    currentStep,
    currentStepValue,
    handlePrev,
    handleNext,
  } = useStep();
  const [values, setValues] = useState(() => currentStepValue ?? {});

  const [editingBlock, setEditingBlock] = useState(null);
  const [blocks, dispatchBlocks] = useReducer(blockReducer, currentStepValue?.blocks || []);
  const [draggingItem, setDraggingItem] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [validationErrors, setValidationErrors] = useState({});
  const [showValidators, setShowValidators] = useState({});
  const sections = currentStep.sections;
  sections[0].sections = fields;

  useEffect(() => {
    const reviewServices = currentStepValue?.reviewService;
    if (reviewServices && Object.keys(reviewServices).length > 0) {
      validateElements(reviewServices);
    }

    if (currentStepValue?.blocks) {
      dispatchBlocks({
        type: 'SET_BLOCKS',
        payload: currentStepValue.blocks
      })
    }
    
    setValues(currentStepValue ?? {});
  }, [currentStepValue]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validReviewService = {};
    const reviewService = values?.reviewService;

    Object.keys(reviewService).forEach((key) => {
      if (reviewService[key]) {
        validReviewService[key] = reviewService[key];
      }
    });

    handleNext({ reviewService: validReviewService, blocks });
  };

  const handleOnEdit = useCallback((id, type) => {
    setEditingBlock({ _id: id, type });
  }, []);

  const handleAfterGenerate = useCallback((index, values) => {
    dispatchBlocks({
      type: "SET_BLOCK",
      payload: { index, value: values },
    });
  }, []);

  const handleOnMoveUp = useCallback((index) => {
    dispatchBlocks({
      type: "MOVE_BLOCK",
      payload: { oldIndex: index - 1, newIndex: index },
    });
  }, []);

  const handleOnMoveDown = useCallback((index) => {
    dispatchBlocks({
      type: "MOVE_BLOCK",
      payload: { oldIndex: index, newIndex: index + 1 },
    });
  }, []);

  const handleRemove = useCallback(
    (id) => {
      dispatchBlocks({ type: "REMOVE_BLOCK", payload: { id } });

      if (editingBlock?._id === id) {
        setEditingBlock(null);
      }
    },
    [editingBlock]
  );

  const handleMandatoryChange = useCallback((index, checked) => {
    dispatchBlocks({
      type: "UPDATE_BLOCK_MANDATORY",
      payload: { index, checked },
    });
  }, []);

  const handleQuestionChange = useCallback((index, value) => {
    dispatchBlocks({ type: "UPDATE_QUESTION", payload: { index, value } });
  }, []);

  const handleAnswerChange = useCallback((index, answerIndex, value) => {
    dispatchBlocks({
      type: "UPDATE_ANSWER",
      payload: { index, answerIndex, value },
    });
  }, []);

  const handleAnswerAdd = useCallback((index, answerIndex) => {
    dispatchBlocks({
      type: "ADD_ANSWER",
      payload: { index, answerIndex },
    });
  }, []);

  const handleAnswerRemove = useCallback((index, answerIndex) => {
    dispatchBlocks({
      type: "REMOVE_ANSWER",
      payload: { index, answerIndex },
    });
  }, []);

  const handleDragStart = (event) => {
    setDraggingItem(blocks.find((block) => block._id === event.active.id));
    if (event.active.id !== editingBlock?._id) {
      setEditingBlock(null);
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    if (!active || !over || !blocks) return;

    const oldIndex = blocks.findIndex((block) => block._id === active.id);
    const newIndex = blocks.findIndex((block) => block._id === over.id);

    dispatchBlocks({
      type: "MOVE_BLOCK",
      payload: { oldIndex, newIndex },
    });

    setDraggingItem(null);
    setEditingBlock({ _id: draggingItem._id, type: draggingItem.type });
  };

  const handleClick = useCallback(
    (index) => {
      if (index === activeIndex) {
        setActiveIndex(-1);

        return;
      }

      setActiveIndex(index);
    },
    [activeIndex]
  );

  const handleOnAdd = () => {
    const id = uuidv4();
    setEditingBlock({ _id: id, type });
    dispatchBlocks({ type: "ADD_BLOCK", payload: { id, type } });
  };

  const handleChange = (_values, sectionName) => {
    const newValues = { ...values, [sectionName]: _values };
    setValues(newValues);
    validateElements(newValues);
  };

  const validateElements = (values) => {
    sections.forEach((section, index) => {
      validateElement(index, section.sections, values[section.name], false);
    });
  };

  const validateElement = (index, sections, values = {}) => {
    let _validationErrors = validationErrors[index] || {};

    sections.forEach((section, sectionIndex) => {
      const fieldErrors = _validationErrors[sectionIndex] || {};

      section.fields.forEach((field) => {
        if (field && values[field.name]) {
          const validator = validateField(
            values[field.name],
            field.type,
            field
          );

          if (!validator.valid) {
            fieldErrors[field.name] = {
              message: validator.message,
            };
          } else {
            delete fieldErrors[field.name];
          }
        }
      });

      _validationErrors[sectionIndex] = fieldErrors;
    });

    // setValidationErrors((prev) => ({ ...prev, [index]: _validationErrors }));
  };

  const validateField = (value, type, field) => {
    if (type === "url") {
      return { valid: validator.isURL(value), message: "URL is invalid" };
    }

    return { valid: true };
  };

  const isReviewServiceValid = useMemo(() => {
    if (!values?.reviewService) {
      return false;
    }
    const validValues = Object.values(values?.reviewService).filter((value) =>
      Boolean(value)
    );
    if (!validValues.length) {
      return false;
    }

    return true;
  }, [values]);

  const reviewServiceFieldValidation = (index) => {
    if (Object.keys(showValidators).length > 0 && showValidators[index]) {
      if (!isReviewServiceValid) {
        return false;
      }

      return isObjectEmpty(validationErrors[index]);
    }

    return null;
  };

  const handleElementBeforeChange = (index, idx, section, values) => {
    const fields = section.sections[idx].fields;
    const fieldValues = [];
    for (const field of fields) {
      if (values[field.name]) {
        fieldValues.push(values[field.name]);
      }
    }

    if (!fieldValues.length) {
      if (
        showValidators &&
        showValidators[index] &&
        showValidators[index][idx] !== undefined
      ) {
        setShowValidators((prev) => ({
          ...prev,
          [index]: { ...showValidators[index], [idx]: false },
        }));
      }
      return;
    }

    const _showValidators = showValidators[index] || {};
    setShowValidators((prev) => ({
      ...prev,
      [index]: { ..._showValidators, [idx]: idx },
    }));
    validateElement(index, section.sections, values);
  };

  const valid = useMemo(() => {
    if (blocks.length === 0) return false;

    for (const block of blocks) {
      if (!block.question) return false;

      if (block.answers) {
        if (block.answers.length === 0 && block.type !== "textarea")
          return false;

        for (const answer of block.answers) {
          if (!answer.value) return false;
        }
      }
    }

    if (!isObjectEmpty(validationErrors)) {
      return false;
    }

    return isReviewServiceValid;
  }, [blocks, validationErrors, isReviewServiceValid]);

  return (
    <form className="an-row w-100 h-100" onSubmit={handleSubmit}>
      <CustomizationOptionsContainer className="an-col_3_12">
        {currentStep.sections.map((section, index) => (
          <CustomizationElement
            {...section}
            index={index}
            key={`question-type-${section.name}`}
            onClick={handleClick}
            open={activeIndex === index}
            bodyClassName="an-layout_gap_16"
            validation={reviewServiceFieldValidation(index)}
            errorMessage={"This is an invalid"}
          >
            <CustomizationForms
              disableDebounce
              activeIndex={-1}
              values={values[section.name] || {}}
              schemas={section.sections}
              onChange={(values) => handleChange(values, section.name)}
              size="small"
              validations={Object.values(showValidators[index] || {})}
              validationErrors={validationErrors ? validationErrors[index] : {}}
              onElementBeforeChange={(idx, values) =>
                handleElementBeforeChange(index, idx, section, values)
              }
            />
          </CustomizationElement>
        ))}
      </CustomizationOptionsContainer>

      <SurveyWorkspace
        hasAddButton
        disabledAdd={blocks.length === 5}
        showPlaceholder={!blocks.length}
        key={`customization-container`}
        className="an-col_7_12"
        onAdd={handleOnAdd}
      >
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <SortableContext
            items={blocks.map((block) => ({ ...block, id: block._id }))}
          >
            {blocks.map((block, index) => (
              <QuestionBlock
                {...block}
                id={block._id}
                key={`question-block-${block._id}`}
                index={index}
                onQuestionChange={handleQuestionChange}
                upDisabled={index === 0}
                downDisabled={index === blocks.length - 1}
                isEditing={editingBlock?._id === block._id}
                dragging={draggingItem?._id === block._id}
                onRemove={handleRemove}
                onEdit={handleOnEdit}
                onAfterGenerate={handleAfterGenerate}
                onMoveUp={handleOnMoveUp}
                onMoveDown={handleOnMoveDown}
                onAnswerChange={handleAnswerChange}
                onAnswerAdd={handleAnswerAdd}
                onAnswerRemove={handleAnswerRemove}
                onMandatoryChange={handleMandatoryChange}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {draggingItem ? (
              <QuestionBlock dragging isEditing {...draggingItem} />
            ) : null}
          </DragOverlay>
        </DndContext>
      </SurveyWorkspace>

      <StepWizardNavigation
        onPrev={() => handlePrev({ ...values, blocks })}
        nextDisabled={!valid}
      />
    </form>
  );
};

export default ReviewBuilder;
