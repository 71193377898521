import React, { useState, useCallback } from "react";
import CustomizationElement from "../../customization-element/CustomizationElement";
import { isObjectEmpty } from "../../../util/object";
import { CustomizationFormFields } from "./CustomizationFormFields";
import CustomizationSections from "./CustomizationSections";

const CustomizationForms = ({
  values = {},
  schemas,
  onChange,
  activeIndex,
  onElementBeforeChange,
  disableDebounce,
  size,
  validations = [],
  validationErrors = {},
  validateElement,
  onClick = () => {},
}) => {
  const [_activeIndex, setActiveIndex] = useState(activeIndex || 0);
  const showValidators = useCallback(
    (index) => {
      return validations.includes(index) ? true : null;
    },
    [validations, validationErrors]
  );

  const handleClick = (index) => {
    if (_activeIndex !== -1) {
      onElementBeforeChange && onElementBeforeChange(_activeIndex, values);
    }

    if (index === _activeIndex) {
      setActiveIndex(-1);

      return;
    }

    setActiveIndex(index);
  };

  return (
    <>
      {schemas &&
        schemas.length > 0 &&
        schemas.map((schema, index) => (
          <CustomizationElement
            key={`CustomizationElement_${index}`}
            title={schema.title}
            description={schema.description}
            icon={schema.icon}
            onClick={() => handleClick(index)}
            open={_activeIndex === index}
            tooltip={schema.tooltip}
            subtitle={schema.subtitle}
            size={size}
            validation={
              showValidators(index)
                ? isObjectEmpty(validationErrors[index])
                : null
            }
          >
            {schema.fields &&
              schema.fields.length > 0 &&
              schema.fields.map((field, key) => {
                return (
                  <CustomizationFormFields
                    key={`subSchema-${key}`}
                    index={index}
                    field={field}
                    validations={validations}
                    validationErrors={validationErrors}
                    values={values}
                    onChange={onChange}
                    disableDebounce={disableDebounce}
                    onClick={onClick}
                    onShowNextCustomizationElement={() => handleClick(index + 1)}
                  />
                );
            })}
            {schema?.sections?.length > 0 && 
              <CustomizationSections
                validationErrors={validationErrors}
                validateElement={validateElement}
                customizerIndex={index}
                sections={schema.sections} 
                size={size}
                disableDebounce={disableDebounce}
                onChange={onChange}
                onClick={onClick}
                values={values}
              />
            }
          </CustomizationElement>
        ))}
    </>
  );
};

export default CustomizationForms;
