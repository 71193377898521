import React, { useContext, useState, useMemo, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import illustrations from "../static/images/store-settings/Illustrations.svg";
import UiButton from "../ui-elements/buttons/UiButton";
import Modal from "react-modal";
import UiHeading from "../ui-elements/text/UiHeading";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import FormControl from "../ui-elements/forms/FormControl";
import { Cross } from "akar-icons";
import Preloader from "../preloader/Preloader";
import Select from "react-dropdown-select";
import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import { saveSocialMedia } from "../api/Shop";
import SocialMedia from "./SocialMedia";
import classNames from "classnames";
import validator from "validator";

Modal.setAppElement("#root");

const socialMediaChannels = [
  {
    label: "Facebook",
    value: "facebook",
  },
  {
    label: "Instagram",
    value: "instagram",
  },
  {
    label: "LinkedIn",
    value: "linkedin",
  },
  {
    label: "Pinterest",
    value: "pinterest",
  },
  {
    label: "TikTok",
    value: "tiktok",
  },
  {
    label: "X",
    value: "x",
  },
  {
    label: "YouTube",
    value: "youtube",
  },
];

const SocialMediaForm = () => {
  const { shopData, updateShop } = useContext(AuthContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const [socialMedia, setSocialMedia] = useState(shopData.socialMedia || {});
  const [modalStatus, setModalStatus] = useState("add");
  const socialMediaChannelOptions = useMemo(() => {
    const _mediaChannels = shopData.socialMedia || {};
    const channels = Object.keys(_mediaChannels);
    return socialMediaChannels.map((option) => ({
      ...option,
      disabled: channels.includes(option.value),
    }));
  }, [shopData]);

  useEffect(() => {
    setSocialMedia(shopData.socialMedia || {});
  }, [shopData]);

  const handleChange = (e) => {
    const key = e.target.name;
    setData((prev) => ({ ...prev, [key]: e.target.value }));
  };

  const handleChannelChange = (e) => {
    setData((prev) => ({ ...prev, channel: e[0].value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    if (data.url && !validator.isURL(data.url)) {
      setErrors({
        url: {
          message: "Please enter a valid URL"
        }
      });
      return;
    }

    setLoading(true);
    try {
      const _data = { ...socialMedia, [data.channel]: data.url };
      await saveSocialMedia(_data);
      await updateShop();
      setModalIsOpen(false);
      setData({});
      setErrors({});
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const handleRemove = async (channel) => {
    if (loading) return;
    setLoading(true);
    try {
      const _data = socialMedia;
      delete _data[channel];
      await saveSocialMedia(_data);
      await updateShop();
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const selectedChannel = () => {
    return socialMediaChannelOptions.filter(
      (option) => option.value === data.channel
    );
  };

  const handleClose = () => {
    setModalIsOpen(false);
    setData({});
    setErrors({});
  };

  const saveButtonDisabled = useMemo(() => {
    if (!data.channel || !data.url) return true;

    const oldData = { channel: data.channel, url: socialMedia[data.channel] };
    return JSON.stringify(oldData) === JSON.stringify(data);
  }, [data, socialMedia]);

  return (
    <>
      <div
        className={classNames(
          "card card_24  card_shadow card_overflow_visible",
          {
            ["card_mh_230"]: !Object.keys(socialMedia).length,
          }
        )}
      >
        <div className="an-layout an-layout_gap_32">
          {shopData.socialMedia ? (
            <>
              {Object.keys(socialMedia).map((key) => (
                <SocialMedia
                  key={key}
                  channel={key}
                  url={socialMedia[key]}
                  onEdit={(value) => {
                    setData(value);
                    setModalStatus("edit");
                    setModalIsOpen(true);
                  }}
                  onRemove={handleRemove}
                />
              ))}
            </>
          ) : (
            <div className="pt-24 pb-24 text-center">
              <img
                src={illustrations}
                alt="You have no profiles"
                className="responsive-img"
              />
              <div className="email-templates-subtitle-2-bold">
                You have no profiles
              </div>
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                Please, connect your social media profiles.
              </div>
            </div>
          )}
          {socialMediaChannelOptions.filter((option) => !option.disabled)
            .length > 0 && (
            <div className="text-right">
              <UiButton
                text="Add social media"
                design="secondary"
                size="medium"
                onClick={() => {
                  setModalIsOpen(true);
                  setModalStatus("add");
                  setData({});
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleClose}
        className="an-modal"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          <div className="an-modal__title">
            <UiHeading
              element="h6"
              desktop="other-headings-h6-bold"
              text={
                modalStatus === "add"
                  ? "Add social media profile"
                  : "Update social media profile"
              }
            />
            <UiCaptionRegular
              className="mt-8 color-secondary-colour-secondary-7"
              text={`Choose a social media platform and enter your URL.`}
            />
          </div>
          <button onClick={handleClose} className="an-modal__close">
            <Cross size="24" color="#d9d9d9" />
          </button>
        </div>
        <form
          className="an-modal__body an-modal__body_mh_0"
          onSubmit={handleSubmit}
        >
          <div className="mt-32 pt-32 bt-1">
            <div>
              <UiCaptionMedium text="Social media" className="mb-4" />
              <Select
                id="channel"
                name="channel"
                options={socialMediaChannelOptions}
                placeholder="Select social media"
                onChange={handleChannelChange}
                className="form-select"
                values={selectedChannel()}
                disabled={modalStatus === "edit"}
                required
              />
            </div>

            <FormControl
              type="text"
              name="url"
              id="url"
              label="Social media URL"
              labelClassName="ui-caption-medium"
              labelMargin="4"
              placeholder="Add URL here*"
              required
              onChange={handleChange}
              margin="24"
              value={data.url || ''}
              validationError={errors.url}
            />
          </div>
          <div className="mt-32 pt-32 bt-1 flex-right gap-8">
            <UiButton
              type="button"
              size="medium"
              design="secondary"
              text="Cancel"
              onClick={handleClose}
            />
            <UiButton
              size="medium"
              text="Save changes"
              disabled={saveButtonDisabled}
            />
          </div>
        </form>
        {loading ? <Preloader absolute={true} overflow={true} /> : ""}
      </Modal>
    </>
  );
};

export default SocialMediaForm;
