import { useState, useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UiButton from "../ui-elements/buttons/UiButton";
import Preloader from "../preloader/Preloader";
import UiHeading from "../ui-elements/text/UiHeading";
import StepWizard from "../ui-elements/step-wizard/StepWizard";
import "../onboarding/Onboarding.scss";
import "../onboarding/Survey.scss";
import "../onboarding/Questionnaire.scss";
import "./CreateWidget.scss";
import ModalLayout from "../modal/Layout";
import WidgetCustomization from "./WidgetCustomization";
import { Notify } from "../ui-elements/notify/notify";
import { updateWidget, getWidget } from "../api/Shop";
import Modal from "react-modal";
import illustration from "../static/images/widgets/Illustration.svg";
import FormControl from "../ui-elements/forms/FormControl";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import { Cross, ChevronDown } from "akar-icons";
import OutsideClickHandler from "react-outside-click-handler";
import FilterStep from "./FilterStep";

Modal.setAppElement("#root");

const EditWidget = () => {
  const [loading, setLoading] = useState(true);
  const [schemas, setSchemas] = useState([]);
  const [saving, setSaving] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const _widgetData = await getWidget(id);

    setWidgetData(_widgetData);
    setLoading(false);
  }

  const [widgetData, setWidgetData] = useState({
    name: ''
  });
  
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [nameError, setNameError] = useState(null);
  const navigate = useNavigate();

  const steps = useMemo(() => {
    return {
      filter: {
        title: "What filters would you like to apply to your {widgetName} widget?",
        description: `Use the filtering options on the left to narrow down your choices, and see the results in the preview table on the right. 
            Select up to two filtering options and define rules to feature the perfect products in your widget. `,
        component: <FilterStep />,
      },
      customizer: {
        title: "Customise your {widgetName} widget",
        description: (
          <>
            Personalise your widget section to match your brand's style and
            messaging. Utilise the customisation options <br />
            to modify content, and use the preview area to visualise changes in
            real-time.
          </>
        ),
        component: <WidgetCustomization />,
        submitButtonLabel: "Next",
      },
    };
  }, [schemas]);

  const handleComplete = (values) => {
    setWidgetData(values);
    setModalIsOpen(true);
  }

  const handleSaveWidget = async (status) => {
    if (saving) {
      return;
    }
    
    setSaving(true);

    try {
      await updateWidget(widgetData, status);
      
      Notify.success({
        title: 'Widget has been updated'
      });

      handleClose();
    } catch (e) {
      console.log('error', e);
    }

    setSaving(false);
  }

  const handleClose = () => navigate("/widgets");

  if (loading) {
    return <Preloader />;
  }

  const toggleDropdown = () => {
    if (!widgetData.name) {
      setNameError({
        message: 'Please enter a widget name'
      });

      return;
    } else {
      setNameError(null);
    }

    setShowDropdown(!showDropdown);
  }

  const changeWidgetName = e => setWidgetData({...widgetData, 'name': e.target.value});

  return (
    <ModalLayout backTo="/widgets">
      {!loading && (
        <StepWizard
          steps={steps}
          onComplete={handleComplete}
          onClose={handleClose}
          data={widgetData}
        />
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="an-modal"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          <div className="an-modal__title">
            <img
              src={illustration}
              alt=""
              className="responsive-img"
            />
            <UiHeading
              element="h6"
              desktop="other-headings-h6-bold"
              text="How would you like to name this widget?"
              margin="24"
            />
            <UiCaptionRegular
              className="mt-8 color-secondary-colour-secondary-7"
              text="Giving a widget a descriptive and meaningful name can help you quickly identify and locate it later on."
            />
            <FormControl
              name="name"
              type="text"
              id="name"
              placeholder="Enter widget name*"
              margin="24"
              required={true}
              validationError={nameError}
              onChange={changeWidgetName}
              defaultValue={widgetData.name}
            />
          </div>
          <button
            onClick={() => setModalIsOpen(false)}
            className="an-modal__close"
          >
            <Cross
              size="24"
              color="#d9d9d9"
            />
          </button>
        </div>
        <div className="an-modal__body an-modal__body_mh_0 text-left">
          <div className="mt-40 text-right">
            <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
              <div className="button-group">
                <UiButton
                  size="medium"
                  design="secondary"
                  text="Cancel"
                  onClick={() => setModalIsOpen(false)}
                />
                <UiButton
                  size="medium"
                  text={
                    <>
                      <span className="ui-button__text">Save widget as</span>
                      <span className="ui-button__icon">
                        <ChevronDown
                          size="24"
                          color="#ffffff"
                        />
                      </span>
                    </>
                  }
                  onClick={toggleDropdown}
                  arrow={true}
                />
                {showDropdown && (
                  <div className="dropdown dropdown_widget">
                    <button className="dropdown__item ui-caption-regular" onClick={() => handleSaveWidget('draft')}>Save as draft</button>
                    <button className="dropdown__item ui-caption-regular" onClick={() => handleSaveWidget('published')}>Save and publish</button>
                  </div>
                )}
              </div>
            </OutsideClickHandler>
          </div>
        </div>
        {saving ? (
          <Preloader
            absolute={true}
            overflow={true}
          />
        ) : ''}
      </Modal>
    </ModalLayout>
  );
};

export default EditWidget;
