import React, { useState, useCallback, useEffect, useRef, useContext } from "react";
import { Table, Header, HeaderRow, HeaderCell, Body, Row, Cell, useCustom } from "@table-library/react-table-library/table";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import SectionHead from "../common/SectionHead";
import FormControl from "../forms/FormControl";
import UiButton from "../buttons/UiButton";
import AnTable from "../../ui-elements/common/AnTable";
import { getAbandonedCarts } from "../../api/Campaign";
import PageSize from "../common/PageSize";
import Pagination from "../common/Pagination";
import { EyeOpen, Send, Cross } from "akar-icons";
import "../activity-table/ActivityTable.scss";
import "./AbandonedCarts.scss";
import Chips from "../common/Chips";
import { AuthContext } from "../../context/AuthContext";
import Modal from "react-modal";
import UiParagraphMedium from "../text/UiParagpraphMedium";
import UiSmallRegular from "../text/UiSmallRegular";
import Filter from '../filter/Filter';
import moment from "moment";

Modal.setAppElement('#root');

const AbandonedCarts = () => {
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { shopData } = useContext(AuthContext);

  const [cartData, setCartData] = useState({
    incrementId: 0,
    nodes: [],
    token: '',
    created: ''
  });

  const [data, setData] = useState({
    nodes: [],
    totalPages: 0
  });

  const fetchData = useCallback(async (params) => {
    const result = await getAbandonedCarts({ params });

    setData({
      nodes: result.data,
      totalPages: result.totalPages,
    });
  }, []);

  useEffect(() => {
    fetchData({
      search,
      order,
      page: 0
    });
  }, [fetchData]);

  const handleSearch = e => {
    setSearch(e.target.value);
  }

  const timeout = useRef();

  const onSearchChange = (action, state) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => fetchData({
      search: state.search,
      page: pagination.state.page,
      size: pagination.state.size,
      order: state.order
    }), 500);
  }

  const onPaginationChange = (action, state) => fetchData({
    search,
    page: state.page,
    size: state.size,
    order
  });

  useCustom('', data, {
    state: {
      search,
      order
    },
    onChange: onSearchChange
  });

  const pagination = usePagination(data, {
    state: {
      page: 0,
    },
    onChange: onPaginationChange,
  }, {
    isServer: true,
  });

  const tableStyles = '--data-table-library_grid-template-columns: 90px minmax(0, 1fr) 180px 120px 80px 100px 48px';
  const cartTableStyles = '--data-table-library_grid-template-columns: minmax(0, 1fr) repeat(2, 120px)';

  const theme = useTheme([getTheme(), {
    Table: tableStyles
  }]);

  const cartTheme = useTheme([getTheme(), {
    Table: cartTableStyles
  }]);

  const changePageSize = value => pagination.fns.onSetSize(value[0].value);

  const changeOrder = value => {
    let _order = order;

    if (order?.[value]) {
      _order[value] = order[value] === 1 ? -1 : 1;
    } else {
      _order = {};
      _order[value] = 1;
    }

    setOrder(_order);

    pagination.fns.onSetPage(0);
  }

  const timeAgo = dateString => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDifference = now - date;
    const seconds = Math.floor(timeDifference / 1000);
    
    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(seconds / 86400);
      
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  }

  const formatMoney = value => {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: shopData.currency
    });
  }

  const viewCart = cart => {
    let nodes = cart.cart.map(item => {
      return {
        isProduct: true,
        name: item.name,
        link: item.permalink,
        quantity: item.quantity,
        price: formatMoney(item.line_total)
      }
    });
    
    nodes.push({
      label: 'Discount',
      price: formatMoney(cart.total_discount)
    });
    
    nodes.push({
      label: 'Shipping',
      price: formatMoney(cart.total_shipping)
    });

    nodes.push({
      label: `Total (includes ${formatMoney(cart.total_tax)} tax)`,
      price: formatMoney(cart.total)
    });

    setCartData({
      incrementId: cart.incrementId,
      token: cart.token,
      created: moment(cart.createdAt).format('MMMM, DD, YYYY hh:mm a'),
      nodes
    });

    setModalIsOpen(true);
  }

  const calculateItemsCount = cart => {
    let items = 0;

    cart.map(item => {
      if (item?.quantity) {
        items += parseInt(item.quantity);
      }
    });

    return items;
  }

  const renderTable = tableList => {
    if (tableList.length === 0) {
      return <div style={{gridColumnStart: 1, gridColumnEnd: 'span 2'}}>No carts found</div>
    }

    return (
      <>
        <Header>
          <HeaderRow>
            <HeaderCell onClick={() => changeOrder('incrementId')} className={`activity-table__th${order?.incrementId ? ' activity-table__th_active' : ''}`}>Cart</HeaderCell>
            <HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.email ? ' activity-table__th_active' : ''}`}>Email</HeaderCell>
            <HeaderCell onClick={() => changeOrder('lastUpdate')} className={`activity-table__th${order?.lastUpdate ? ' activity-table__th_active' : ''}`}>Last Active</HeaderCell>
            <HeaderCell onClick={() => changeOrder('purchased')} className={`activity-table__th table-align-right${order?.purchased ? ' activity-table__th_active' : ''}`}>Status</HeaderCell>
            <HeaderCell onClick={() => changeOrder('items')} className={`activity-table__th table-align-right${order?.items ? ' activity-table__th_active' : ''}`}>Items</HeaderCell>
            <HeaderCell onClick={() => changeOrder('total')} className={`activity-table__th table-align-right${order?.total ? ' activity-table__th_active' : ''}`}>Price</HeaderCell>
            <HeaderCell />
          </HeaderRow>
        </Header>
        <Body>
          {tableList.map(item => (
            <Row
              item={item}
              key={item._id}
            >
              <Cell>#{item.incrementId}</Cell>
              <Cell>{item.email}</Cell>
              <Cell>{timeAgo(item.lastUpdate)}</Cell>
              <Cell className="table-align-center">
                {item.purchased ? (
                  <Chips
                    color="green"
                    value="Recovered"
                  />
                ) : (
                  <Chips
                    color="red"
                    value="Abandoned"
                  />
                )}
              </Cell>
              <Cell className="table-align-right">{calculateItemsCount(item.cart)}</Cell>
              <Cell className="table-align-right">{formatMoney(item.total)}</Cell>
              <Cell className="table-align-center">
                <button
                  className="view-cart"
                  onClick={() => viewCart(item)}
                >
                  <EyeOpen size="16" />
                </button>
              </Cell>
            </Row>
          ))}
        </Body>
      </>
    );
  }

  return (
    <div className="card card_br_24 card_24 card_shadow card_align_center card_h_100 card_flex">
      <SectionHead
        align="center"
        title={
          <>
            <div className="email-templates-subtitle-2-medium">Abandoned carts</div>
            <div className="mt-4 email-templates-caption-regular color-secondary-colour-secondary-4">Latest activity</div>
          </>
        }
        filter={
          <div className="d-flex d-flex_gap_12">
            {/* TODO: commented because it doesn't work well with CSFLE encrypted fields */}
            {/* <FormControl
              type="text"
              name="search"
              id="search"
              placeholder="Search"
              className="form-control__input_search form-control__input_size_xsmall"
              value={search}
              onChange={handleSearch}
            /> */}
            {/* TODO: no filters defined */}
            <Filter 
              subHeader={'Status'}
              filterItems={[
                {
                  label: 'Recovered',
                  value: 'recovered',
                },
                {
                  label: 'Abandoned',
                  value: 'abandoned',
                }
              ]}
              apply={(filters) => {
                fetchData({status: filters})
              }}
              reset={() => {
                fetchData({
                  search: '',
                  order: {},
                  page: 0
                })
              }}
            />
          </div>
        }
      />
      <div className="mt-24 activity-table">
        <AnTable
          body={
            <>
              <Table
                data={data}
                theme={theme}
                pagination={pagination}
                layout={{
                  custom: true
                }}
              >
                {tableList => renderTable(tableList)}
              </Table>
              <div className="an-table__bottom pl-0 pr-0">
                <PageSize onChange={changePageSize} />
                <Pagination
                  pagination={pagination}
                  pagesCount={data?.totalPages ? data.totalPages : 0}
                />
              </div>
            </>
          }
        />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="an-modal an-modal_w_490 text-center"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          <div className="an-modal__title an-modal__title_fill text-left">
            <UiParagraphMedium text={`Cart #${cartData.incrementId}`} />
            <UiSmallRegular
              text="Latest activities"
              className="color-secondary-colour-secondary-7"
            />
          </div>
          <button
            onClick={() => setModalIsOpen(false)}
            className="an-modal__close"
          >
            <Cross
              size="24"
              color="#d9d9d9"
            />
          </button>
        </div>
        <div className="an-modal__body an-modal__body_mh_0 mt-24">
          <div className="mt-24 cart-table">
            <AnTable
              body={
                <>
                  <Table
                    data={cartData}
                    theme={cartTheme}
                    layout={{
                      custom: true
                    }}
                  >
                    {tableList => (
                      <>
                        <Header>
                          <HeaderRow>
                            <HeaderCell>Product</HeaderCell>
                            <HeaderCell>Quantity</HeaderCell>
                            <HeaderCell>Price</HeaderCell>
                          </HeaderRow>
                        </Header>
                        <Body>
                          {tableList.map((item, key) => (
                            <Row id={key}>
                              <Cell className="table-align-left">
                                {item.isProduct ? (
                                  <a
                                    href={item.link}
                                    target="_blank"
                                    className="color-secondary-colour-secondary-7 underline"
                                  >{item.name}</a>
                                ) : item.label}
                              </Cell>
                              <Cell className="table-align-right">{item.quantity}</Cell>
                              <Cell className="table-align-right">{item.price}</Cell>
                            </Row>
                          ))}
                        </Body>
                      </>
                    )}
                  </Table>
                </>
              }
            />
          </div>
          <div className="mt-24 text-left">
            <span className="ui-caption-medium">Cart token: </span>
            <span className="color-secondary-colour-secondary-7 ui-caption-regular">{cartData.token}</span>
          </div>
          <div className="mt-16 text-left">
            <span className="ui-caption-medium">Cart created: </span>
            <span className="color-secondary-colour-secondary-7 ui-caption-regular">{cartData.created}</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AbandonedCarts;