import React, { useState, useEffect, useRef } from 'react'

import UiButton from '../buttons/UiButton'
import UiBaseCheckbox from '../forms/UiBaseCheckbox'

import './Filter.scss'

const Filter = ({ 
  filterItems = [], 
  subHeader, 
  apply, 
  reset,
  triggerTitle = 'Filter by',
}) => {
	const [show, setShow] = useState(false)
  const [selected, setSelected] = useState([])
  const ref = useRef(null)
  const coords =  useRef({})
  
  const handleApply = () => {
    setShow(false)
    apply && apply(selected)
  }
  
  const handleOutsideClick = (e) => {
    if (!e.target.closest(`.${ref.current.className}`) && show) {
      setShow(false)
    }
  }
  
  const handleChange = (e) => {
    const { checked, value } = e.target
    if (checked) setSelected(selected.concat(value))
    else setSelected(selected.filter((item => item != value)))
  }
  
  const handleReset = () => {
    setShow(false)
    setSelected([])
    reset && reset()
  }
  
  const handleOpen = (e) => {
    const rect = e.target.getBoundingClientRect()

    coords.current['top'] = rect.bottom + 4
    coords.current['right'] = document.body.clientWidth - rect.right
    
    setShow(!show)
  }
  
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick)
    return () => {
      document.removeEventListener("click", handleOutsideClick)
    };
  })
  
	return (
		<div className='filter' ref={ref}>
			<UiButton
				size="small"
				design="secondary"
        text={triggerTitle}
        onClick={handleOpen}
			/>
      {show && 
        <div 
          className='content'
          style={{
            top: coords.current.top || 0,
            right: coords.current.right || 0
          }}
        >
          <div className='content_header'>
            <div className='ui-caption-medium'>Filter by</div>
            <div className='ui-small-regular reset_button' onClick={handleReset}>Reset filter</div>
          </div>
          <div className='content_body'>
            {subHeader && <div className='ui-small-medium'>{subHeader}</div>}
            {filterItems.map((item, index) => (
              <UiBaseCheckbox 
                key={index}
                className='fluid reverse'
                value={item.value}
                label={item.label}
                onChange={handleChange}
                checked={selected.includes(item.value)}
              />
            ))}
          </div>
          <div className='content_footer'>
            <UiButton
              size="small"
              design="no-border"
              text="Clear"
              onClick={() => setSelected([])}
            />
            <UiButton
              size="small"
              design="secondary"
              text="Apply"
              onClick={handleApply}
            />
          </div>
        </div>
      }
		</div>
	)
}

export default Filter