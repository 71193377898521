import React from "react";
import Flatpickr from "../../flatpickr/Flatpickr";
import styles from "./date-time.module.scss";

const DateTimeEditor = (props) => {
  const startDate = props.value?.startDate;
  const endDate = props.value?.endDate;

  const handleChange = (value, key) => {
    const _value = {
      startDate,
      endDate,
    };
    _value[key] = value
    props.onChange && props.onChange(_value);
  };

  return (
    <div>
      <div className={styles["base"]}>
        <Flatpickr
          name='min'
          value={startDate}
          minDate={new Date()}
          maxDate={endDate ?? ''}
          enableTime
          addOn="🚀"
          placeholder="Start date"
          onChange={(value) => handleChange(value, "startDate")}
        />

        <span className="ui-caption-medium color-secondary-colour-secondary-7">
          to
        </span>

        <Flatpickr
          name='max'
          value={endDate}
          minDate={startDate || new Date()}
          enableTime
          addOn="🏁"
          placeholder="End date"
          onChange={(value) => handleChange(value, "endDate")}
        />
      </div>

      {props.validationError && (
        <div className="form-control__error ui-caption-regular">
          {props.validationError.message}
        </div>
      )}
    </div>
  );
};

export default DateTimeEditor;
