import React from "react";
import MainContent from "../../dashboard/MainContent";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import EmailHeaderLayout from "../../brand-assets/EmailHeaderLayout";
import EmailFooterLayout from "../../brand-assets/EmailFooterLayout";

const BrandLayoutsPage = () => {
  return (
    <MainContent>
      <div className="an-layout">
        <SectionHead
          title={
            <>
              <UiHeading
                text="Email header"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                Customise the appearance of your email header effortlessly.
                Choose from options such as header layout (horizontal or
                vertical), a custom call out message, and menu links to create a
                personalised and engaging email header.
              </div>
            </>
          }
        />

        <EmailHeaderLayout />

        <SectionHead
          title={
            <>
              <UiHeading
                text="Email footer"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                Customise the layout of your email footer with ease. Choose from
                various options such as displaying social media icons, company
                logo, adding footer links, and including a review snippet for a
                fully tailored and professional email footer.
              </div>
            </>
          }
        />

        <EmailFooterLayout />
      </div>
    </MainContent>
  );
};

export default BrandLayoutsPage;
