import React, { useState } from "react";
import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import classNames from "classnames";
import styles from "./SocialMedia.module.scss";
import {
  FacebookFill,
  MoreVerticalFill,
  PinterestFill,
  InstagramFill,
  LinkedinFill,
  TiktokFill,
  XFill,
  YoutubeFill
} from "akar-icons";
import OutsideClickHandler from "react-outside-click-handler";

const SocialMedia = ({ channel, url, onEdit, onRemove }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const icon = () => {
    switch (channel) {
      case "facebook":
        return <FacebookFill />;
      case "x":
        return <XFill />;
      case "pinterest":
        return <PinterestFill />;
      case "instagram":
        return <InstagramFill />;
      case "linkedin":
        return <LinkedinFill />;
      case "tiktok":
        return <TiktokFill />;
      case "youtube":
        return <YoutubeFill />;
      default:
        return "";
    }
  };

  return (
    <div
      className={classNames(
        styles["base"],
        "card card_16 card_overflow_visible card_shadow card_border"
      )}
    >
      <div className={styles["inner"]}>
        <div className={styles["icon"]}>{icon()}</div>
        <div className={styles["info"]}>
          <UiCaptionMedium text={channel} className={styles["channel"]} />
          <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7">
            {url}
          </div>
        </div>
        <div className="position-relative">
          <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
            <button
              className="action-btn"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <MoreVerticalFill size="16" color="#d9d9d9" />
            </button>
            {showDropdown ? (
              <div className="dropdown">
                <button
                  className="dropdown__item ui-caption-regular"
                  onClick={() => onEdit({ channel, url })}
                >
                  Edit profile
                </button>
                <button
                  className="dropdown__item ui-caption-regular"
                  onClick={() => onRemove(channel)}
                >
                  Remove profile
                </button>
              </div>
            ) : (
              ""
            )}
          </OutsideClickHandler>
        </div>
      </div>
    </div>
  );
};

export default SocialMedia;
