import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Legend from "./Legend";
import {memo} from "react"
import createChartTooltip from '../../ui-elements/charts/createCustomTooltip';
import EmptyData from './EmptyData';

const PopupPerformance = ({ totalPopupsDisplayed=0, subscribed, clicked, purchased }) => {
  ChartJS.register(ArcElement, Tooltip);

  const formatNumber = number => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 0
    });
  }

  const data = {
    datasets: [
      {
        labels: ['Subscribed'],
        data: [subscribed, totalPopupsDisplayed - subscribed > 0 ? totalPopupsDisplayed - subscribed : 0],
        showTooltip: [true, false],
        backgroundColor: ['#7de2ff', '#f5f5f5']
      },
      {
        labels: ['Clicked'],
        showTooltip: [true, false],
        data: [clicked, totalPopupsDisplayed - clicked > 0 ? totalPopupsDisplayed - clicked : 0],
        backgroundColor: ['#b37feb', '#f5f5f5']
      },
      {
        labels:['Purchased'],
        data: [purchased, totalPopupsDisplayed - purchased > 0 ? totalPopupsDisplayed - purchased : 0],
        showTooltip: [true, false],
        backgroundColor: ['#ff85c0', '#f5f5f5']
      }
    ]
  }

  return (
    <>
      <div className="email-templates-subtitle-2-medium">Campaign performance</div>
      <div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">Overall performance for this campaign</div>
      {!totalPopupsDisplayed ?
        <div className="mt-24">
          <EmptyData />
        </div>
      :
        <div className="mt-24 an-row items-center">
          <div className="an-col an-col_53 position-relative">
            <Doughnut
              data={data}
              options={{
                borderRadius: 6,
                cutout: 50,
                responive: true,
                clip: 10,
                plugins: {
                  tooltip: {
                    enabled: false,
                    callbacks: {
                      title: (tooltipItem) => tooltipItem[0]?.dataset.labels[0]
                    },
                    external: createChartTooltip
                  }
                }
              }}
            />
            <div className="overview-emails">
              <div className="overview-emails__count">{formatNumber(totalPopupsDisplayed)}</div>
              <div className="overview-emails__label color-secondary-colour-secondary-7">Popups</div>
            </div>
          </div>
          <div className="an-col an-col_47 d-flex d-flex_column d-flex_gap_8">
            <Legend
              label="Subscribed"
              value={subscribed}
              total={totalPopupsDisplayed}
              color="#7de2ff"
            />
            <Legend
              label="Clicked"
              value={clicked}
              total={totalPopupsDisplayed}
              color="#b37feb"
            />
            <Legend
              label="Purchased"
              value={purchased}
              total={totalPopupsDisplayed}
              color="#ff85c0"
            />
          </div>
        </div>
      }
      
    </>
  )
}

export default memo(PopupPerformance);