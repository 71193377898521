import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import UiButton from "../ui-elements/buttons/UiButton";
import HeaderProfile from "../header/HeaderProfile";
import Notifications from "./Notifications";
import "./TopBar.scss";
import { AuthContext } from "../context/AuthContext";

const TopBar = (props) => {
  const { user, shopUpgradable } = useContext(AuthContext);

  return (
    <div className="top-bar">
      <div className="top-bar__line">
        <Breadcrumbs />
        <div className="top-bar__profile">
          {shopUpgradable && (
            <UiButton
              elem="a"
              href="/billing/packages"
              text="Upgrade"
              size="medium"
            />
          )}
          <Notifications />
          <HeaderProfile type="dashboard" user={user} />
        </div>
      </div>
    </div>
  );
};

export default TopBar;
