import { useState, useEffect, useContext } from "react";
import FormControl from "../../ui-elements/forms/FormControl";
import './Filters.scss';

const TopSellersFilter = ({ onChange, filters }) => {
  const [num, setNum] = useState('');

  useEffect(() => {
    filters.map(filter => {
      if (filter._id === 'num') {
        setNum(parseInt(filter.value));
      }
    });
  }, [filters]);

  const handleChange = e => {
    let updatedFilters = filters;
    const index = filters.findIndex(item => item._id === e.target.name);

    if (index !== -1) {
      updatedFilters.splice(index, 1);
    }

    if (e.target.value) {
      updatedFilters = [...filters, {
        value: e.target.value,
        _id: e.target.name,
        name: `${e.target.value} top-selling products`
      }];
    }

    if (e.target.name === 'num') {
      setNum(parseInt(e.target.value));
    }
    
    onChange('number', updatedFilters);
  }

  return (
    <>
      <div className="email-templates-caption-medium">Filter Top Selling</div>
      <div className="an-row an-row_gap_8 mt-4">
        <div className="an-col">
          <FormControl
            label="Number of product"
            type="number"
            name="num"
            id="num"
            labelMargin="4"
            placeholder="Ex. 20"
            labelClassName="ui-caption-regular"
            onChange={handleChange}
            value={num}
          />
        </div>
      </div>
    </>
  )
}

export default TopSellersFilter;