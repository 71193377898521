import "../campaigns/Counter.scss";

const Counter = props => {
  return (
    <div className="campaigns-counter">
      <div className="campaigns-counter__count email-templates-caption-medium">{props.available} out of {props.total} widgets</div>
    </div>
  );
}

export default Counter;