import React, {useState, useEffect} from 'react'

import ToolsPerformance from '../ToolsPerformance';
import UiHeading from "../../../ui-elements/text/UiHeading";
import UiCaptionRegular from "../../../ui-elements/text/UiCaptionRegular";
import UiCaptionMedium from "../../../ui-elements/text/UiCaptionMedium";
import CampaignIcon from '../../../ui-elements/common/CampaignIcon';
import Chips from '../../../ui-elements/common/Chips';
import Divider from '../../../ui-elements/divider/Divider';
import Legend from '../../../campaigns/overviews/Legend';
import Toogler from '../../../ui-elements/toogler/Toogler';

const colors = ['#9254de', '#4bd4fb', '#d3adf7', '#85a5ff', '#ff7875']

const EmailDelivery = ({ data }) => {
  const [totalSends, setTotalSends] = useState(0);
  const [totalSendsTrend, setTotalSendsTrend] = useState(0);
  const [openRate, setOpenRate] = useState(0);
  const [clickRate, setClickRate] = useState(0);
  const [unsubscribed, setUnsubscribed] = useState(0);
  const [bounces, setBounces] = useState(0);
  const [complaints, setComplaints] = useState(0);
  const [type, setType] = useState('day')

  useEffect(() => {
    setTotalSends(data.totalSends ? data.totalSends.toLocaleString() : 0);
    setTotalSendsTrend(data.totalSendsTrend ? data.totalSendsTrend : 0);
    setOpenRate(data.openRate ? data.openRate : 0);
    setClickRate(data.clickRate ? data.clickRate : 0);
    setUnsubscribed(data.unsubscribed ? data.unsubscribed : 0);
    setBounces(data.bounces ? data.bounces : 0);
    setComplaints(data.complaints ? data.complaints : 0);
  }, [data]);

  const legends = [
    {
      label: 'Open rate',
      value: openRate
    },
    {
      label: 'Click rate',
      value: clickRate
    },
    {
      label: 'Unsubscribed',
      value: unsubscribed
    },
    {
      label: 'Bounces',
      value: bounces
    },
    {
      label: 'Complaints',
      value: complaints
    },
  ]

  if (!data.chart) return null

	return (
		<ToolsPerformance
      key={'email'}
			title="Email delivery"
			subTitle="Email performance for your selected dates."
      data={data?.chart ? data?.chart[type] : null}
      type={type}
      yAxis={{
        min: 0,
        max: 100,
        ticks: {
          stepSize: 25,
          callback: (value, index, ticks) => {
            return value + '%'
          }
        }
      }}
      tooltipLabel={(tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw.toFixed(2)}%`}
      filter={
        <Toogler
          items={[
            { value: 'day', text: 'Day' },
            { value: 'week', text: 'Week' },
            { value: 'month', text: 'Month' },
          ]}
          defaultStatus={type}
          onChange={(value) => setType(value)}
        />
      }
			Legend={() => {
				return (
					<>
            <div className='icon-wrapper'>
							<div>
								<CampaignIcon emoji={'📨'} />
							</div>
							<div>
								<UiCaptionRegular
									className=""
									text="Total sends"
								/>
								<UiHeading
									element="h6"
									desktop="other-headings-h6-bold"
									text={
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div>{totalSends}</div>
                      <div>
                        <Chips
                          color={totalSendsTrend >= 0 ? 'green' : 'red'}
                          value={`${Math.abs(totalSendsTrend).toFixed(1)}%`}
                          arrow={true}
                        />
                      </div>
                    </div>
                  }
								/>
							</div>
						</div>
						<Divider className="mt-16 mb-16" />
						<div className='legends'>
							<UiCaptionMedium text="Overall performance" />
							{legends.map(({value, label}, index) => 
                <Legend
									key={index}
									label={label}
									value={value}
									total={100}
									color={colors[index]}
									showTotal={false}
								/>
							)}
						</div>
					</>
				)
			}}
		/>
	);
}

export default EmailDelivery;