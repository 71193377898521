import React, { useState } from "react";
import styles from "./LinksEditor.module.scss";
import UiButton from "../../buttons/UiButton";
import FormControl from "../../forms/FormControl";
import UiCaptionRegular from "../../text/UiCaptionRegular";
import classNames from "classnames";
import Divider from "../../divider/Divider";

const LinksEditor = ({ links = [], onChange, max }) => {
  const [_links, setLinks] = useState(links);

  const handleChange = (index, key, value) => {
    const newValue = _links[index] ? { ..._links[index] } : {};
    newValue[key] = value;
    const newValues = [
      ..._links.slice(0, index),
      newValue,
      ..._links.slice(index + 1),
    ];

    setLinks(newValues);
    onChange && onChange(newValues);
  };

  const handleRemove = (e, index) => {
    e.preventDefault();
    
    const newLinks = [..._links.slice(0, index), ..._links.slice(index + 1)];

    setLinks(newLinks);
    
    onChange && onChange(newLinks);
  };

  return (
    <div className={styles["base"]}>
      <div
        className={classNames(styles["add-wrapper"], 'text-center')}
        onClick={() => setLinks((prev) => [...prev, {}])}
      >
        <UiCaptionRegular
          text="Click here to add a button"
          className="color-secondary-colour-secondary-4"
        />
        <UiButton
          type="button"
          size="small"
          text="Add button"
          className="mt-8"
          disabled={_links.length >= max}
        />
      </div>
      {_links.length > 0 && (
        <>
          <Divider />
          <div className={classNames(styles["links"], "an-layout", "mt-16")}>
            {_links.map((link, index) => (
              <div className="an-layout" key={`link-editor-${index}`}>
                <FormControl
                  type={"text"}
                  value={link.label || ""}
                  label={`Link label ${index + 1}`}
                  placeholder={"Ex: Men*"}
                  labelClassName="ui-caption-medium"
                  labelMargin="4"
                  onChange={(e) => handleChange(index, "label", e.target.value)}
                  required
                />

                <FormControl
                  type={"url"}
                  value={link.url || ""}
                  label={`URL ${index + 1}`}
                  placeholder={"Your URL goes here*"}
                  labelClassName="ui-caption-medium"
                  labelMargin="4"
                  onChange={(e) => handleChange(index, "url", e.target.value)}
                  required
                />
                <div className={classNames(styles['remove-wrapper'], 'text-right')}>
                  <UiButton
                    className="ui-button_add text-uppercase"
                    size="small"
                    design="secondary"
                    text="Remove button"
                    onClick={e => handleRemove(e, index)}
                  />
                </div>
                {index < _links.length - 1 && <Divider />}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default LinksEditor;
