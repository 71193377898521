import { useState, useEffect } from "react";
import axios from "axios";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { stateToHTML } from "draft-js-export-html";
import { useLocation } from "react-router-dom";
import Preloader from "../../preloader/Preloader";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import UiBaseCheckbox from "../../ui-elements/forms/UiBaseCheckbox";
import UiBodyMedium from "../../ui-elements/text/UiBodyMedium";
import UiCaptionRegular from "../../ui-elements/text/UiCaptionRegular";
import UiSmallRegular from "../../ui-elements/text/UiSmallRegular";
import UiButton from "../../ui-elements/buttons/UiButton";
import UiCaptionMedium from "../../ui-elements/text/UiCaptionMedium";
import MainContent from "../../dashboard/MainContent";
import akarIconsArrowBack from "../../static/images/store-settings/akar-icons_arrow-back.svg";
import akarIconsArrowForward from "../../static/images/store-settings/akar-icons_arrow-forward.svg";
import akarIconsTextAlignCenter from "../../static/images/store-settings/akar-icons_text-align-center.svg";
import akarIconsTextAlignLeft from "../../static/images/store-settings/akar-icons_text-align-left.svg";
import akarIconsTextAlignRight from "../../static/images/store-settings/akar-icons_text-align-right.svg";
import akarIconsTextAlignJustified from "../../static/images/store-settings/akar-icons_text-align-justified.svg";
import icBaselineFormatBold from "../../static/images/store-settings/ic_baseline-format-bold.svg";
import icRoundFormatItalic from "../../static/images/store-settings/ic_round-format-italic.svg";
import akarIconsLinkChain from "../../static/images/store-settings/akar-icons_link-chain.svg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./StoreSettings.scss";
import "../../wysiwyg/Wysiwyg.scss";
import { Notify } from "../../ui-elements/notify/notify";
import FormControl from "../../ui-elements/forms/FormControl";

const StoreInformationPage = () => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [data, setData] = useState({});
  const [wasDataChanged, setWasDataChanged] = useState(false);
  const [initialObject, setInitialObject] = useState({});
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [marketingEditorState, setMarketingEditorState] = useState(EditorState.createEmpty());
  const [goodbyeEditorState, setGoodbyeEditorState] = useState(
    EditorState.createEmpty()
  );
  const [gdprEdited, setGdprEdited] = useState(false);
  const [marketingEdited, setMarketingEdited] = useState(false);
  const [unsubscribeEdited, setUnsubscribeEdited] = useState(false);
  const token = localStorage.getItem("token");
  const { pathname } = useLocation();
  const maxLength = 80;

  useEffect(() => {
    axios
      .get("/shop", {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      })
      .then((response) => {
        const emailSettings = {
          enableDoubleOptIn:
            response.data.emailSettings?.enableDoubleOptIn ?? false,
          enableGdprConsentBox:
            response.data.emailSettings?.enableGdprConsentBox ?? false,
          enableMarketingConsentBox:
            response.data.emailSettings?.enableMarketingConsentBox ?? false,
          sendUnsubscribeGoodbyeEmailNotification:
            response.data.emailSettings
              ?.sendUnsubscribeGoodbyeEmailNotification ?? false,
          consentBox: response.data.emailSettings?.consentBox ?? "",
          marketingBox: response.data.emailSettings?.marketingBox ?? "",
          tickTheConsentBoxByDefault:
            response.data.emailSettings?.tickTheConsentBoxByDefault ?? false,
          tickTheMarketingBoxByDefault:
            response.data.emailSettings?.tickTheMarketingBoxByDefault ?? false,
          goodbyeEmailNotification:
            response.data.emailSettings?.goodbyeEmailNotification ?? "",
          goodbyeEmailSubject: response.data.emailSettings?.goodbyeEmailSubject ?? ''
        };

        setInitialObject(emailSettings);
        setData(emailSettings);

        if (response.data.emailSettings?.consentBox) {
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(response.data.emailSettings.consentBox)
              )
            )
          );
        }

        if (response.data.emailSettings?.marketingBox) {
          setMarketingEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(response.data.emailSettings.marketingBox)
              )
            )
          );
        }

        if (response.data.emailSettings?.goodbyeEmailNotification) {
          setGoodbyeEditorState(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(
                  response.data.emailSettings.goodbyeEmailNotification
                )
              )
            )
          );
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);

        console.error(err);
      });
  }, []);

  const save = (e) => {
    e.preventDefault();

    setSaving(true);

    axios
      .put(
        "/shop",
        {
          emailSettings: data,
        },
        {
          headers: {
            Authorization: `Bearer: ${token}`,
          },
        }
      )
      .then(() => {
        setInitialObject(data);
        setWasDataChanged(false);
        setSaving(false);
        Notify.success({
          title: "Changes saved successfully",
          message: "Email settings have been updated.",
        });
      })
      .catch((err) => {
        console.error(err);

        setSaving(false);
      });
  };

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const updateData = (e) => {
    let updatedData = {};

    if (e.target.type === "checkbox") {
      updatedData = e.target.checked
        ? { ...data, [e.target.name]: true }
        : { ...data, [e.target.name]: false };
    } else {
      updatedData = { ...data, [e.target.name]: e.target.value };
    }

    setData(updatedData);
    setWasDataChanged(!deepEqual(initialObject, updatedData));
  };

  const reset = (e) => {
    e.preventDefault();
  };

  const setConsentBoxValue = (value) => {
    const updatedData = { ...data, ["consentBox"]: value };

    setData(updatedData);
    setWasDataChanged(!deepEqual(initialObject, updatedData));
  };

  const setMarketingBoxValue = (value) => {
    const updatedData = { ...data, ["marketingBox"]: value };

    setData(updatedData);
    setWasDataChanged(!deepEqual(initialObject, updatedData));
  };

  const setGoodbyeEmailNotificationValue = (value) => {
    const updatedData = { ...data, ["goodbyeEmailNotification"]: value };

    setData(updatedData);
    setWasDataChanged(!deepEqual(initialObject, updatedData));
  };

  const updateConsentBoxValue = () => {
    setConsentBoxValue(stateToHTML(editorState.getCurrentContent()));
    setGdprEdited(true);
  };

  const updateMarketingBoxValue = () => {
    setMarketingBoxValue(stateToHTML(marketingEditorState.getCurrentContent()));
    setMarketingEdited(true);
  };

  const updateGoodbyeEmailNotificationValue = () => {
    setGoodbyeEmailNotificationValue(
      stateToHTML(goodbyeEditorState.getCurrentContent())
    );
    setUnsubscribeEdited(true);
  };

  const handleKeyCommand = (command) => {
    if (command === "split-block") {
      return "handled";
    }

    return "not-handled";
  };

  const generateConsentBoxContent = (e) => {
    e.preventDefault();

    setSaving(true);

    axios
      .get("/gpt/email/consent", {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      })
      .then((response) => {
        const blocksFromHtml = htmlToDraft(response.data);

        setEditorState(() => {
          const newState = EditorState.createWithContent(
            ContentState.createFromBlockArray(blocksFromHtml.contentBlocks)
          );

          setConsentBoxValue(stateToHTML(newState.getCurrentContent()));
          setGdprEdited(true);

          return newState;
        });

        setSaving(false);
      })
      .catch((err) => {
        console.error(err);

        setSaving(false);
      });
  };

  const generateMarketingBoxContent = (e) => {
    e.preventDefault();

    setSaving(true);

    axios
      .get("/gpt/email/marketing", {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      })
      .then((response) => {
        const blocksFromHtml = htmlToDraft(response.data);

        setMarketingEditorState(() => {
          const newState = EditorState.createWithContent(
            ContentState.createFromBlockArray(blocksFromHtml.contentBlocks)
          );

          setMarketingBoxValue(stateToHTML(newState.getCurrentContent()));
          setMarketingEdited(true);

          return newState;
        });

        setSaving(false);
      })
      .catch((err) => {
        console.error(err);

        setSaving(false);
      });
  };

  const generateGoodbyeEmailNotificationContent = (e) => {
    e.preventDefault();

    setSaving(true);

    axios
      .get("/gpt/email/unsubscribe", {
        headers: {
          Authorization: `Bearer: ${token}`,
        },
      })
      .then((response) => {
        const blocksFromHtml = htmlToDraft(response.data);

        setGoodbyeEditorState(() => {
          const newState = EditorState.createWithContent(
            ContentState.createFromBlockArray(blocksFromHtml.contentBlocks)
          );

          setGoodbyeEmailNotificationValue(
            stateToHTML(newState.getCurrentContent())
          );
          setUnsubscribeEdited(true);

          return newState;
        });

        setSaving(false);
      })
      .catch((err) => {
        console.error(err);

        setSaving(false);
      });
  };

  const handleBeforeInput = (input, editorState) => {
    if (editorState.getCurrentContent().getPlainText('').length + input.length > maxLength) {
      return 'handled';
    }

    return 'not-handled';
  }

  const handlePastedText = (pastedText, html, editorState) => {
    if (editorState.getCurrentContent().getPlainText('').length + pastedText.length > maxLength) {
      return 'handled';
    }

    return 'not-handled';
  }

  if (loading) {
    return <Preloader />;
  }

  return (
    <MainContent>
      <div className="an-layout">
        <SectionHead
          title={
            <>
              <UiHeading
                text="Email preferences"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                Fine-tune your customers email experience effortlessly. Secure
                sign-ups with double opt-in, ensure transparency with GDPR
                fields and say goodbye gracefully with farewell emails.
              </div>
            </>
          }
        />
        <form
          className="card card_24 card_shadow store-settings card_overflow_visible"
          onSubmit={save}
        >
          <div className="store-settings__section">
            <UiBaseCheckbox
              onChange={updateData}
              id="enable_double_opt_in"
              name="enableDoubleOptIn"
              value="yes"
              align="top"
              checked={data.enableDoubleOptIn}
              label={
                <>
                  <UiBodyMedium
                    text="Enable Double Opt-In"
                    className="color-gray-gray-9"
                  />
                  <UiSmallRegular text="Activating double opt-in adds a two-step process for user sign-ups. After signing up, users get a confirmation email from Anomate with a link to confirm their subscription. This ensures genuine sign-ups and reduces unauthorized entries. The confirmation email is sent by Anomate and can't be customized." />
                </>
              }
            />
          </div>
          <div className="store-settings__section">
            <UiBaseCheckbox
              onChange={updateData}
              id="enable_gdpr_consent_box"
              name="enableGdprConsentBox"
              value="yes"
              align="top"
              checked={data.enableGdprConsentBox}
              label={
                <>
                  <UiBodyMedium
                    text="Enable Privacy Policy Checkbox"
                    className="color-gray-gray-9"
                  />
                  <UiSmallRegular text="Turning on the GDPR consent box adds a checkbox to sign-up forms, asking users to agree to your data usage policy. This ensures compliance with GDPR by getting explicit user consent before collecting their information. You can customize the suggested content for the consent box." />
                </>
              }
            />
            {data.enableGdprConsentBox ? (
              <div className="mt-24">
                <div className="pl-26">
                  <UiCaptionMedium
                    text="Consent box editor"
                    className="color-secondary-colour-secondary-7"
                  />
                  <div
                    className={`wysiwyg mt-8${gdprEdited ? " wysiwyg_edited" : ""
                      }`}
                  >
                    <div className="wysiwyg__generate">
                      <UiButton
                        text="Generate content"
                        className="text-uppercase email-templates-button-small-medium"
                        size="small"
                        design="secondary"
                        onClick={generateConsentBoxContent}
                      />
                    </div>
                    <Editor
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                      onContentStateChange={updateConsentBoxValue}
                      handleKeyCommand={handleKeyCommand}
                      handleBeforeInput={handleBeforeInput}
                      handlePastedText={handlePastedText}
                      toolbar={{
                        options: ["history", "link"],
                        history: {
                          undo: {
                            icon: akarIconsArrowBack,
                          },
                          redo: {
                            icon: akarIconsArrowForward,
                          },
                        },
                        link: {
                          options: ["link"],
                          link: {
                            icon: akarIconsLinkChain
                          }
                        },
                      }}
                    />
                  </div>
                  <div className="mt-8 d-flex d-flex_jc_space">
                    <UiBaseCheckbox
                      label="Tick the consent box by default"
                      onChange={updateData}
                      id="tick_the_consent_box_by_default"
                      name="tickTheConsentBoxByDefault"
                      value="yes"
                      checked={data.tickTheConsentBoxByDefault}
                    />
                    <UiCaptionRegular
                      text={`${maxLength} characters max`}
                      className="color-secondary-colour-secondary-7"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="store-settings__section">
            <UiBaseCheckbox
              onChange={updateData}
              id="enable_marketing_consent_box"
              name="enableMarketingConsentBox"
              value="yes"
              align="top"
              checked={data.enableMarketingConsentBox}
              label={
                <>
                  <UiBodyMedium
                    text="Enable Marketing Consent Checkbox"
                    className="color-gray-gray-9"
                  />
                  <UiSmallRegular text="Activating the marketing consent checkbox adds a prompt on sign-up forms for users to allow the use of their data for marketing. This ensures GDPR compliance by obtaining explicit consent before data collection. You can customize the suggested content for this checkbox." />
                </>
              }
            />
            {data.enableMarketingConsentBox ? (
              <div className="mt-24">
                <div className="pl-26">
                  <UiCaptionMedium
                    text="Consent box editor"
                    className="color-secondary-colour-secondary-7"
                  />
                  <div
                    className={`wysiwyg mt-8${marketingEdited ? " wysiwyg_edited" : ""
                      }`}
                  >
                    <div className="wysiwyg__generate">
                      <UiButton
                        text="Generate content"
                        className="text-uppercase email-templates-button-small-medium"
                        size="small"
                        design="secondary"
                        onClick={generateMarketingBoxContent}
                      />
                    </div>
                    <Editor
                      editorState={marketingEditorState}
                      onEditorStateChange={setMarketingEditorState}
                      onContentStateChange={updateMarketingBoxValue}
                      handleBeforeInput={handleBeforeInput}
                      handlePastedText={handlePastedText}
                      handleKeyCommand={handleKeyCommand}
                      toolbar={{
                        options: ["history", "link"],
                        history: {
                          undo: {
                            icon: akarIconsArrowBack,
                          },
                          redo: {
                            icon: akarIconsArrowForward,
                          },
                        },
                        link: {
                          options: ["link"],
                          link: {
                            icon: akarIconsLinkChain
                          }
                        },
                      }}
                    />
                  </div>
                  <div className="mt-8 d-flex d-flex_jc_space">
                    <UiBaseCheckbox
                      label="Tick the marketing box by default"
                      onChange={updateData}
                      id="tick_the_marketing_box_by_default"
                      name="tickTheMarketingBoxByDefault"
                      value="yes"
                      checked={data.tickTheMarketingBoxByDefault}
                    />
                    <UiCaptionRegular
                      text={`${maxLength} characters max`}
                      className="color-secondary-colour-secondary-7"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="store-settings__section">
            <UiBaseCheckbox
              onChange={updateData}
              id="send_unsubscribe_goodbye_email_notification"
              name="sendUnsubscribeGoodbyeEmailNotification"
              value="yes"
              align="top"
              checked={data.sendUnsubscribeGoodbyeEmailNotification}
              label={
                <>
                  <UiBodyMedium
                    text="Send Unsubscribe Goodbye Email Notification"
                    className="color-gray-gray-9"
                  />
                  <UiSmallRegular text="Enable this setting to automatically send a goodbye email to users who unsubscribe. This courtesy email acknowledges their decision and provides a final message. You can customize the suggested content for this email." />
                </>
              }
            />
            {data.sendUnsubscribeGoodbyeEmailNotification ? (
              <div className="mt-24">
                <div className="pl-26 an-layout">
                  <div>
                    <UiCaptionMedium
                      text="Goodbye email subject"
                      className="color-secondary-colour-secondary-7"
                    />
                    <FormControl
                      type="text"
                      name="goodbyeEmailSubject"
                      id="goodbyeEmailSubject"
                      className="mt-8"
                      value={data?.goodbyeEmailSubject ? data.goodbyeEmailSubject : ''}
                      onChange={updateData}
                    />
                  </div>
                  <div>
                    <UiCaptionMedium
                      text="Goodbye email editor"
                      className="color-secondary-colour-secondary-7"
                    />
                    <div
                      className={`wysiwyg mt-8${unsubscribeEdited ? " wysiwyg_edited" : ""
                        }`}
                    >
                      <div className="wysiwyg__generate">
                        <UiButton
                          text="Generate content"
                          className="text-uppercase email-templates-button-small-medium"
                          size="small"
                          design="secondary"
                          onClick={generateGoodbyeEmailNotificationContent}
                        />
                      </div>
                      <Editor
                        editorState={goodbyeEditorState}
                        onEditorStateChange={setGoodbyeEditorState}
                        onContentStateChange={updateGoodbyeEmailNotificationValue}
                        toolbar={{
                          options: [
                            "history",
                            "blockType",
                            "inline",
                            "textAlign",
                            "link",
                          ],
                          history: {
                            undo: {
                              icon: akarIconsArrowBack,
                            },
                            redo: {
                              icon: akarIconsArrowForward,
                            },
                          },
                          blockType: {
                            options: [
                              "Paragraph",
                              "H1",
                              "H2",
                              "H3",
                              "H4",
                              "H5",
                              "H6",
                            ],
                          },
                          textAlign: {
                            left: {
                              icon: akarIconsTextAlignLeft,
                            },
                            center: {
                              icon: akarIconsTextAlignCenter,
                            },
                            right: {
                              icon: akarIconsTextAlignRight,
                            },
                            justify: {
                              icon: akarIconsTextAlignJustified,
                            },
                          },
                          inline: {
                            options: ["bold", "italic"],
                            bold: {
                              icon: icBaselineFormatBold,
                            },
                            italic: {
                              icon: icRoundFormatItalic,
                            },
                          },
                          link: {
                            options: ["link"],
                            link: {
                              icon: akarIconsLinkChain
                            }
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="mt-32 flex-right gap-8">
            <UiButton
              text="Cancel"
              size="medium"
              design="secondary"
              onClick={reset}
              disabled={!wasDataChanged}
            />
            <UiButton
              text="Save changes"
              size="medium"
              disabled={!wasDataChanged}
            />
          </div>
          {saving ? <Preloader absolute={true} overflow={true} /> : ""}
        </form>
      </div>
    </MainContent>
  );
};

export default StoreInformationPage;
