import React from 'react'
import UiBodyBold from '../../ui-elements/text/UiBodyBold';
import UiCaptionRegular from '../../ui-elements/text/UiCaptionRegular';
import UiButton from '../../ui-elements/buttons/UiButton';
import CampaignIcon from '../../ui-elements/common/CampaignIcon';

const IntroCard = ({ item, buttonText, handleClick }) => {
  const { name, description, emoji } = item;

  return (
    <div className="cards-list__item card card_16 card_shadow text-left">
      <div>
        <CampaignIcon
          emoji={emoji}
          size="big"
        />
        <UiBodyBold
          text={name}
          margin="8"
        />
        <div className="pb-22">
          <UiCaptionRegular
            text={description}
            margin="8"
          />
        </div>
      </div>
      <UiButton
        design="secondary"
        size="small"
        text={buttonText}
        className="ui-button_full-width"
        onClick={handleClick}
      />
    </div>
  );
}

export default IntroCard;