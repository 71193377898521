import React from "react";
import classNames from "classnames";
import styles from "./CustomizationContainer.module.scss";
import Preloader from "../../../preloader/Preloader";
import CustomizationPlaceholder from "../customization-placeholder/CustomizationPlaceholder";

const CustomizationContainer = ({
  className,
  children,
  previewHTML,
  loading,
  showPlaceholder,
  noPreview,
  mobileView,
  currentType
}) => {
  return (
    <div className={classNames(styles["base"], className)}>
      {!noPreview && (
        <>
          {showPlaceholder && <CustomizationPlaceholder />}
          {!showPlaceholder && (
            <iframe
              className={classNames(styles["customizer-preview"], {[`${styles["mobile"]}`]: mobileView, [`${styles["no-border"]}`]: currentType === 'raffleSetup'})}
              srcDoc={previewHTML}
            />
          )}
          {loading && <Preloader absolute overflow />}
        </>
      )}
      {children}
    </div>
  );
};

export default CustomizationContainer;
