import Select from "react-dropdown-select";
import { useState, useEffect } from "react";
import axios from "axios";
import FormControl from "../ui-elements/forms/FormControl";
import UiButton from "../ui-elements/buttons/UiButton";
import Preloader from "../preloader/Preloader";
import UiHeading from "../ui-elements/text/UiHeading";
import ntExploring1 from "../static/images/support/nt-exploring 1.svg";

const SupportForm = props => {
  const [supportTopics, setSupportTopics] = useState([]);
  const [topic, setTopic] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [messageSent, setMessageSent] = useState(false);
  const token = localStorage.getItem('token');

  useEffect(() => {
    axios.get('/support/topics', {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    }).then(response => {
      let currentTopics = [];

      response.data.map(topic => {
        currentTopics.push({
          value: topic.topic,
          label: topic.topic
        });

        return topic;
      });

      setSupportTopics(currentTopics);
      setLoading(false);
    }).catch(err => {
      console.error(err);

      setLoading(false);
    });
  }, []);

  const sendRequest = e => {
    e.preventDefault();

    if (message) {
      setLoading(true);

      axios.post('/support/request', {
        topic: topic,
        message: message
      }, {
        headers: {
          Authorization: `Bearer: ${token}`
        }
      }).then(() => {
        setMessageSent(true);

        props.onSuccess();
        
        setLoading(false);
      }).catch(() => setLoading(false));
    } else {
      setErrors({
        message: {
          validationError: {
            message: 'Please enter your request'
          }
        }
      });
    }
  }

  if (loading) {
    return <Preloader absolute={true} />
  }

  const updateTopic = value => {
    if (value[0].value) {
      setTopic(value[0].value);
    }
  }

  return (
    <form className="support-form" onSubmit={sendRequest}>
      {messageSent ? (
        <div className="text-center">
          <UiHeading
            desktop="email-templates-subtitle-2-bold"
            text="Support is on the way!"
          />
          <div className="email-templates-paragraph-1-regular mt-8">Your support request is in the queue. Hold tight, <br />and we'll be with you in 3 to 5 business days. Thank you.</div>
          <div className="mt-24">
            <img
              src={ntExploring1}
              alt="Support is on the way!"
              className="responsive-img"
            />
          </div>
          <div className="mt-42 flex-right">
            <UiButton
              text="Close"
              size="medium"
              onClick={props.closeModal}
              className="ui-button_mw_132"
            />
          </div>
        </div>
      ) : (
        <>
          <Select
            options={supportTopics}
            placeholder="Please select a topic"
            onChange={(value) => updateTopic(value)}
            className="form-select"
          />
          <FormControl
            type="textarea"
            name="message"
            id="message"
            margin="24"
            inputClassName="border-color-gray-gray-5"
            required={true}
            placeholder="Message*"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            validationError={errors.message}
          />
          <div className="text-right mt-16">
            <UiButton
              type="submit"
              text="Submit"
              size="medium"
              className="ui-button_mw_132"
            />
          </div>
        </>
      )}
    </form>
  )
}

export default SupportForm;