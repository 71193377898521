import React, { useState, useRef, useEffect } from "react";
import { FaceVeryHappy } from "akar-icons";
import EmojiPicker from "emoji-picker-react";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./EmojiSelector.module.scss";

const EmojiSelector = ({ onChange = () => {} }) => {
  const [showPicker, setShowPicker] = useState(false);
  const [top, setTop] = useState(true);
  const pickerHeight = 450;
  const anchorRef = useRef(null);

  const handleClick = (e) => {
    const { top: anchorTop, height, bottom } = e.target.getBoundingClientRect();
    let _top = anchorTop + height + 10;

    if (bottom > pickerHeight + 50) {
      const inputHeight = 50;
      _top = bottom - pickerHeight - height - inputHeight - 10;
    }

    setShowPicker(true);
    setTop(_top);
  };

  const handleEmojiClick = (value) => {
    onChange(value.emoji);
  };

  return (
    <div className={styles["base"]}>
      <span className={styles["anchor"]} ref={anchorRef} onClick={handleClick}>
        <FaceVeryHappy strokeWidth={2} size={24} />
      </span>

      <OutsideClickHandler onOutsideClick={() => setShowPicker(false)}>
        <EmojiPicker
          style={{ top: top }}
          className={styles["picker"]}
          open={showPicker}
          width={300}
          emojiStyle="native"
          onEmojiClick={handleEmojiClick}
          skinTonesDisabled
          height={pickerHeight}
        />
      </OutsideClickHandler>
    </div>
  );
};

export default EmojiSelector;
