import { useState, useEffect } from "react";
import { getBlogPostCategories, getShopProductCategories } from "../../api/Shop";
import Preloader from "../../preloader/Preloader";
import BasePill from "../../ui-elements/buttons/BasePill";
import './Filters.scss';

const CategoryFilter = ({ onChange, filters, type }) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [notFoundMessage, setNotFoundMessage] = useState('');
  
  useEffect(() => {
    fetchData();
    setLoading(false);
  }, []);

  const fetchData = async () => {
    let _categories = type === 'post' ? await getBlogPostCategories() : await getShopProductCategories();

    if (type !== 'post') {
      console.log(_categories);

      _categories = _categories.map(category => {
        return {
          ...category,
          _id: category.originalId
        }
      });
    }

    setCategories(_categories);

    if (_categories.length === 0) {
      setNotFoundMessage('No categories found.');
    }
  }

  const toggleFilter = (e, category) => {
    e.preventDefault();

    const index = filters.findIndex(item => item._id === category._id);
    let updatedFilters = [];

    if (index === -1) {
      updatedFilters = [...filters, category];
    } else {
      updatedFilters = [...filters];

      updatedFilters.splice(index, 1);
    }

    onChange('category', updatedFilters);
  }

  return (
    <>
      <div className="email-templates-caption-medium">Select a rule</div>
      <div className="filter-items mt-4">
        {notFoundMessage && (<div className="email-templates-caption-regular color-secondary-colour-secondary-4">{notFoundMessage}</div>)}
        {categories.map(category => (
          <BasePill
            key={category._id}
            text={category.name}
            onClick={e => toggleFilter(e, category)}
            active={filters && filters.some(item => item._id === category._id)}
          />
        ))}
        {loading && <Preloader absolute={true} overflow={true} />}
      </div>
    </>
  )
}

export default CategoryFilter;