import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ModalLayout from "../../modal/Layout";
import VerificationToken from "../../store-settings/VerificationToken";
import Preloader from "../../preloader/Preloader";
import UiButton from "../../ui-elements/buttons/UiButton";

const DomainAccountsDomain = () => {
  const { id } = useParams();
  const [verificationToken, setVerificationToken] = useState('');
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`/domains/${id}`, {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    }).then(response => {
      setVerificationToken(response.data.verificationToken);
      setLoading(false);
    }).catch(error => {
      console.error(error);

      setLoading(false);
    });
  }, []);

  const verify = () => {
    setLoading(true);

    axios.post(`/domains/${id}/verify`, {}, {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    }).then(() => {
      setLoading(false);
      navigate('/store-settings/domain-accounts');
    }).catch(error => {
      console.error(error);

      setLoading(false);
    });
  }
  
  return (
    <ModalLayout backTo="/store-settings/domain-accounts">
      <div>
        <div className="onboarding-question text-center mt-40">
          <VerificationToken token={verificationToken} />
        </div>
      </div>
      <div className="onboarding-questionnaire__bottom">
        <UiButton
          size="medium"
          elem="a"
          href="/store-settings/domain-accounts"
          text="Back"
          design="secondary"
          className="ui-button_mw_150"
        />
        <UiButton
          size="medium"
          text="I`ve done this"
          onClick={verify}
          design="secondary"
          className="ui-button_mw_150"
        />
      </div>
      {
        loading ? (
          <Preloader
            absolute={true}
            overflow={true}
          />
        ) : ''
      }
    </ModalLayout >
  )
}

export default DomainAccountsDomain;