import { Cross } from "akar-icons";
import "./TagItem.scss";
import classNames from "classnames";

const TagItem = (props) => {
  const removeTag = (e, tag) => {
    e.preventDefault();
    props.onClick(tag);
  };

  return (
    <div
      className={classNames("tag-item", {
        [`tag-item_size_${props.size}`]: props.size,
        [`tag-item_line-clamp_${props.lineClamp}`]: props.lineClamp,
      })}
      title={props.lineClamp ? props.tag : ""}
    >
      <div
        className={`${
          props.size === "small"
            ? "ui-small-regular"
            : `${props.size === 'xsmall' ? 'ui-extra-small-regular' : 'email-templates-caption-regular'}`
        } color-golden-purple-purple-5`}
      >
        {props.tag}
      </div>
      {!props?.hideClose && (
        <div
          onClick={(e) => removeTag(e, props.tag)}
          className="tag-item__remove"
        >
          <Cross size="16" color="#9254de" />
        </div>
      )}
    </div>
  );
};

export default TagItem;
