import React, { useState } from 'react'
import { Table, Header, HeaderRow, Body, Row, Cell, HeaderCell } from "@table-library/react-table-library/table";
import { useSort, HeaderCellSort } from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { NavLink } from 'react-router-dom';

import SectionHead from '../../../ui-elements/common/SectionHead';
import AnTable from '../../../ui-elements/common/AnTable';
import AkarIconsChevronVertical from '../../../static/images/common/akar-icons_chevron-vertical.svg'
import AkarIconsChevronVerticalCopy from '../../../static/images/common/akar-icons_chevron-vertical copy.svg'
import Toogler from '../../../ui-elements/toogler/Toogler';

import useAuth from '../../../hooks/useAuth';

const keys = ['totalSends', 'open', 'click', 'unsubscribed', 'bounces', 'spam'];

const OverviewTable = ({ data }) => {
	const { shopData } = useAuth()
	const [mode, setMode] = useState('percent')
	const theme = useTheme([getTheme(), {
    // Table: `--data-table-library_grid-template-columns: 200px repeat(8, 109px)`
    Table: `--data-table-library_grid-template-columns: 1fr 110px 80px 80px 120px 100px 80px 110px 115px`
  }]);

	const sort = useSort(
    { nodes: Object.values(data.campaigns ?? {})},
    {},
    {
      sortIcon: {
        margin: "0px",
        iconDefault: <img src={AkarIconsChevronVertical} alt="" />,
        iconUp: <img src={AkarIconsChevronVerticalCopy} alt="" />,
        iconDown: <img src={AkarIconsChevronVerticalCopy} alt="" />,
      },
      sortFns: {
        CAMPAIGNNAME: (array) => array.sort((a, b) => a.name.localeCompare(b.name)),
				TOTALSENDS: (array) => array.sort((a, b) => a.totalSends - b.totalSends),
				OPEN: (array) => array.sort((a, b) => a.open - b.open),
				CLICK: (array) => array.sort((a, b) => a.click - b.click),
				UNSUBSCRIBED: (array) => array.sort((a, b) => a.unsubscribed - b.unsubscribed),
				BOUNCES: (array) => array.sort((a, b) => a.bounces - b.bounces),
				SPAM: (array) => array.sort((a, b) => a.spam - b.spam),
				CONVERSION: (array) => array.sort((a, b) => a.conversion - b.conversion),
				REVENUE: (array) => array.sort((a, b) => a.revenue - b.revenue),
      },
    }
  );



	const getValueByMod = (value, key, row) => {
		if (key === 'conversion') {
			if (!row.totalSends) return `0%`
			return `${((row.orders / row.totalSends) * 100).toFixed(1)}%`
		}

		if (key === 'revenue') {
			return value.toLocaleString('en-US', {
				style: 'currency',
				currency: shopData.currency
			})
		}

		if (mode === 'nominal') {
			return value.toLocaleString('en-US', {
				minimumFractionDigits: 0
			})
		}

		const total = overall[key]
		if (!total) return 0
		return `${((value / total) * 100).toFixed(1)}%`
	}

	if (Object.keys(data).length === 0) return;
	const { overall } = data

	return (
		<div>
			<SectionHead
				title={
					<>
						<div className="email-templates-subtitle-2-medium">Overview</div>
						<div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">
							See the performance as percentage or nominal values for your campaigns.
						</div>
					</>
				}
				filter={
					<Toogler
						label={'See as'}
						className='secondary'
						items={[
							{ value: 'percent', text: 'PCT(%)' },
							{ value: 'nominal', text: 'Nominal' },
						]}
						defaultStatus={mode}
						onChange={value => setMode(value)}
					/>
				}
			/>
			<AnTable 
				className='mt-24'
				body={
					<Table
						data={{ nodes: Object.values(data.campaigns)}}
						theme={theme}
						sort={sort}
						layout={{
							custom: true
						}}
					>
						{(tableList) => (
							<>
								<Header>
									<HeaderRow>
										<HeaderCellSort sortKey="CAMPAIGNAME">Campaign Name</HeaderCellSort>
										<HeaderCellSort sortKey="TOTALSENDS">Total sends</HeaderCellSort>
										<HeaderCellSort sortKey="OPEN">Open</HeaderCellSort>
										<HeaderCellSort sortKey="CLICK">Click</HeaderCellSort>
										<HeaderCellSort sortKey="UNSUBSCRIBED">Unsubscribed</HeaderCellSort>
										<HeaderCellSort sortKey="BOUNCES">Bounces</HeaderCellSort>
										<HeaderCellSort sortKey="SPAM">Spam</HeaderCellSort>
										<HeaderCellSort sortKey="CONVERSION">Conversion</HeaderCellSort>
										<HeaderCellSort sortKey="REVENUE">Revenue</HeaderCellSort>
									</HeaderRow>
								</Header>
								<Body>
									<Row className='overall-tr'>
										<Cell>Overall</Cell>
										{keys.map(key => 
											<Cell className='text-right' key={key}>
												{overall[key].toLocaleString('en-US', {
													minimumFractionDigits: 0
												})}
											</Cell>
										)}
										<Cell className='text-right'>
											{overall.totalSends === 0 ?
												`0%`
											:
												`${((overall.orders / overall.totalSends) * 100).toFixed(1)}%`
											}
										</Cell>
										<Cell className='text-right'>
											{overall.revenue.toLocaleString('en-US', {
												style: 'currency',
												currency: shopData.currency
											})}
										</Cell>
									</Row>
									{tableList && tableList.map((item, index) => {
										return (
											<Row item={item} key={index}>
												<Cell>
													<NavLink to={`${item?.link}`}>{item?.campaignName}</NavLink>
												</Cell>
												{keys.map((key) => 
													<Cell className='text-right' key={key}>{getValueByMod(item[key], key)}</Cell>
												)}
												<Cell className='text-right'>{getValueByMod(item.revenue, 'conversion', item)}</Cell>
												<Cell className='text-right'>{getValueByMod(item.revenue, 'revenue')}</Cell>
											</Row>
										)
									})}
								</Body>
							</>
						)}
					</Table>
				}
			/>
		</div>
	);
}

export default OverviewTable;