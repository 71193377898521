import { useState } from "react";
import Header from "../header/Header";
import AnCaption from "../ui-elements/text/AnCaption";
import UiHeading from "../ui-elements/text/UiHeading";
import LoginImage from "./LoginImage";
import LoginMobileImage from "./LoginMobileImage";
import ForgotPasswordForm from "./ForgotPasswordForm";
import WithAuthorization from "./WithAuthorization";
import image from "../static/images/login/Image.svg";
import ntTrafficAnalytics from "../static/images/login/nt-traffic-analytics 1.svg";
import "./Login.scss";

const ForgotPassword = props => {
  const [heading, setHeading] = useState('Forgot your password?');
  const [paragraph, setParagraph] = useState('Enter your email address below, and we\'ll send you a link to reset your password and regain access to your account.');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const updateHeading = heading => setHeading(heading);
  const updateParagraph = paragraph => setParagraph(paragraph);
  const updateFormSubmitted = () => setFormSubmitted(true);

  return (
    <div>
      <Header />
      <div className="login-wrapper">
        <div className="container">
          <div className="login-wrapper__inner">
            <div className="login-wrapper__content">
              <AnCaption text="Password Recovery" />
              <UiHeading
                element="h1"
                desktop="email-templates-h1-bold"
                mobile="h3-lg"
                text={heading}
                margin="12"
              />
              <div className="email-templates-paragraph-2-regular mt-20 color-gray-gray-8">{paragraph}</div>
              {!formSubmitted ? (
                <ForgotPasswordForm
                  updateHeading={updateHeading}
                  updateParagraph={updateParagraph}
                  updateFormSubmitted={updateFormSubmitted}
                />
              ) : ('')}
            </div>
            <LoginImage src={image} />
          </div>
        </div>
        <LoginMobileImage src={ntTrafficAnalytics} />
      </div>
    </div>
  );
}

export default WithAuthorization(ForgotPassword, true, '/');