import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import UiHeading from "../../ui-elements/text/UiHeading";
import { CampaignContext } from "../../context/CampaignContext";

import Screens from "./Screens";

const IntroScreen = ({ onNext = () => {}, handleAIGenerate }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { campaignSlug } = useParams();
  const { campaigns } = useContext(CampaignContext);

  const campaign = campaigns.find(
    (currentCampaign) => currentCampaign.slug === campaignSlug
  );
  const mode = searchParams.get("mode");

  if (!campaign) return null;

  const introCards =
    mode === "ai" ? campaign.introCardsAi : campaign.introCards;

  return (
    <div className="text-center card card_flex card_align_center h-100">
      <UiHeading
        text="Ready to customize your campaign?"
        desktop="email-templates-subtitle-2-bold"
      />
      <div
        className="email-templates-paragraph-1-regular mt-8 color-secondary-colour-secondary-7"
        dangerouslySetInnerHTML={{
          __html: campaign.customizerText,
        }}
      />
      <Screens
        campaign={campaign}
        campaignSlug={campaignSlug}
        mode={mode}
        introCards={introCards}
        onNext={onNext}
        handleAIGenerate={handleAIGenerate}
      />
    </div>
  );
};

export default IntroScreen;
