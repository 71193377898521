import React, { useState, useEffect, useRef } from 'react'
import { CircleAlert } from "akar-icons";
import OutsideClickHandler from "react-outside-click-handler";
import { Calendar } from "react-date-range";

const DatePicker = (props) => {
  const [calendarIsShow, setCalendarIsShow] = useState(false);
  const [calendarValue, setCalendarValue] = useState("");
  const [calendarRect, setCalendarRect] = useState(null);
  const [calendarElemValue, setCalendarElemValue] = useState({});
  const inputRef = useRef(null);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const hadnleScroll = () => setCalendarIsShow(false);
  
  useEffect(() => {
    window.addEventListener('scroll', hadnleScroll)
    return () => window.removeEventListener('scroll', hadnleScroll)
  }, [])
    
  useEffect(() => {
    let initialDate = "";

    if (props.value) {
      const date = new Date(props.value);

      initialDate = `${months[date.getMonth()]} / ${date.getDate()} / ${date.getFullYear()}`;

      setCalendarElemValue(date);
    } else {
      setCalendarElemValue({});
    }

    setCalendarValue(initialDate);
  }, [props.value]);

  const handleCalendarChange = (value) => {
    const date = new Date(value);

    setCalendarValue(`${months[date.getMonth()]} / ${date.getDate()} / ${date.getFullYear()}`);
    setCalendarIsShow(false);
    props.onChange(props.name, value);
  };

  const handleClick = (e) => {
    const { bottom, top } = e.target.getBoundingClientRect();
    const calendarMarginTop = 4;
    const calendarApproxHeight = 330;
    
    const yCalendar = bottom + calendarMarginTop + calendarApproxHeight
    const windowInnerHeight = window.innerHeight
    if (yCalendar > windowInnerHeight) {
      setCalendarRect({ bottom: windowInnerHeight - top + calendarMarginTop });
    } else {
      setCalendarRect({ top: bottom - 6 });
    }
    setCalendarIsShow(true)
  }

  let labelClassName = props.labelClassName ? props.labelClassName : "email-templates-paragraph-1-medium"
  labelClassName += " form-control__label"
  props.labelMargin && (labelClassName += ` mb-${props.labelMargin}`)

  return (
    <div className={`form-control${props.margin ? ` mt-${props.margin}` : ""}`}>
      {props.label && (
        <label
          htmlFor={props.id || props._id}
          className={labelClassName}
        >
          {props.label}
        </label>
      )}
      <div className="form-control__inner">
        <input
          ref={inputRef}
          type="text"
          id={props.id || props._id}
          name={props.name}
          placeholder={props.placeholder}
          className={`form-control__input form-control__input_date ui-caption-regular${
            props.validationError ? " form-control__input_error" : ""
          }${props.className ? ` ${props.className}` : ""}`}
          required={props.required ? props.required : false}
          value={calendarValue}
          readOnly={true}
          onClick={handleClick}
        />
        {props.append && (
          <div className="form-control__append ui-caption-regular">{props.append}</div>
        )}
        {props.validationError && props.validationWithIcon && (
          <div className="form-control__error-icon">
            <CircleAlert size="18" color="#ff4d4f" />
          </div>
        )}
      </div>
      {props.validationError && (
        <div className="form-control__error ui-caption-regular">{props.validationError.message}</div>
      )}
      {calendarIsShow && (
        <OutsideClickHandler onOutsideClick={() => setCalendarIsShow(false)}>
          <div className="datepicker-wrapper datepicker-wrapper_static">
            <div 
              className='calendar_wrapper' 
              style={{ 
                position: 'fixed', 
                top: calendarRect?.top, 
                bottom: calendarRect?.bottom,
                width: inputRef.current.offsetWidth 
              }}
            >
              <Calendar
                onChange={handleCalendarChange}
                maxDate={props.maxDate}
                minDate={props.minDate}
                weekdayDisplayFormat="EEEEEE"
                date={calendarValue}
              />
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
}

export default DatePicker