import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import "./TimelineItem.scss";

const TimelineItem = props => {
  const formatDate = date => {
    const now = Date.now();
    const diff = now - date.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (days > 0) {
      return `${days}d ago`;
    } else if (hours > 0) {
      return `${hours}h ago`;
    } else if (minutes > 0) {
      return `${minutes}m ago`;
    } else {
      return 'just now';
    }
  }

  const getIconClassModificator = type => {
    let classModificator = ' timeline-item__icon_';
    const goldActions = ['customer_sign_up', 'Clicked', 'Opened survey'];

    if (goldActions.includes(type)) {
      classModificator += 'gold';
    } else {
      return '';
    }

    return classModificator;
  }

  return (
    <div className="timeline-item">
      <UiCaptionMedium
        text={formatDate(props.item.createdAt)}
        className="timeline-item__date"
      />
      <div className={`timeline-item__icon${getIconClassModificator(props.item.type)}`} />
      <div className="timeline-item__action">
        <UiCaptionRegular
          text={props.item.title}
          className="timeline-item__title color-secondary-colour-secondary-6"
        />
        {props.item.objectType && props.item.objectId ? (
          <a href={`/${props.item.objectType}/${props.item.objectId}`} className="ui-caption-medium timeline-item__link">#{props.item.objectId}</a>
        ) : ('')}
      </div>
    </div>
  );
}

export default TimelineItem;