import { useState, useEffect } from 'react';

import { Header, HeaderRow, HeaderCell, Body, Row, Cell } from "@table-library/react-table-library/table";
import { TriangleDownFill } from 'akar-icons'

import Chips from '../../common/Chips';
import FixedDropdown from '../../fixed-dropdown/FixedDropdown';
import UiBaseCheckbox from '../../forms/UiBaseCheckbox';
import { removeConsentEntries, resendConsentEmail } from '../../../api/Tools';
import { Notify } from '../../notify/notify';

const ToolsTable = ({changeOrder, tableList}) => {
	const [selected, setSelected] = useState([]);
  const [currentTableList, setCurrentTableList] = useState(tableList ? tableList : []);

  useEffect(() => {
    setCurrentTableList(tableList);
  }, [tableList]);
  
  const handleCheckAll = (e) => {
    if (e.target.checked) {
      setSelected(currentTableList.map(item => item._id))      
    } else {
      setSelected([])
    }
  }
  
  const handleCheck = (checked, id) => {
    if (checked) {
      setSelected(selected.concat(id))
    } else {
      setSelected(selected.filter(check => check !== id))
    }
  }

  const getActionColor = action => {
    if (action === 'request') {
      return 'gold';
    } else if (action === 'subscribe') {
      return 'green';
    } else if (action === 'unsubscribe') {
      return 'red';
    }
  }

  const getActionLabel = action => {
    if (action === 'request') {
      return 'No Answer';
    } else if (action === 'subscribe') {
      return 'Accepted';
    } else if (action === 'unsubscribe') {
      return 'Rejected';
    }
  }

  const removeEntry = async (item) => {
    if (item._id) {
      await removeConsentEntries({
        subscriberIds: [item._id]
      });

      refreshCurrentList([item._id]);
    }
  }

  const refreshCurrentList = subscriberIds => {
    const _updatedTableList = currentTableList.map(entry => {
      if (subscriberIds.includes(entry._id)) {
        return {
          ...entry,
          hidden: true
        }
      }

      return entry;
    });

    setCurrentTableList(_updatedTableList);
  }

  const removeSelectedEntries = async () => {
    if (selected && selected.length) {
      await removeConsentEntries({
        subscriberIds: selected
      });

      refreshCurrentList(selected);
    }
  }

  const resendEmail = async (item) => {
    if (item._id) {
      await resendConsentEmail({
        subscriberIds: [item._id]
      });

      Notify.success({
        title: 'Email has been resent successfully',
      });
    }
  }
  
  const resendSelectedEntries = async () => {
    if (selected && selected.length) {
      await resendConsentEmail({
        subscriberIds: selected
      });

      Notify.success({
        title: 'The emails were successfully scheduled',
      });
    }
  }
  
	return (
		<>
			<Header>
				<HeaderRow>
					<HeaderCell
						className={`activity-table__th activity-table__th_no-sorting`}
					>
            <div style={{gap: '4px', justifyContent: 'left'}}>
              <UiBaseCheckbox 
                className='without-label' 
                onChange={handleCheckAll}
              />
              <FixedDropdown
                className="campaign"
                disabled={!selected.length}
                selector={true}
                icon={
                  <TriangleDownFill
                    size="16"
                    className={`icon-button${!selected.length ? ' disabled' : ''} `}
                  />
                }
                items={[
                  {
                    onClick: () => removeSelectedEntries(),
                    iconText: 'Remove from list',
                  },
                  {
                    onClick: () => resendSelectedEntries(),
                    iconText: 'Resend email',
                  },
                ]}
              />
            </div>
					</HeaderCell>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting`}>
						Customer name
					</HeaderCell>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting`}>
						Email
					</HeaderCell>
					<HeaderCell
						onClick={() => changeOrder('action')}
						className={`activity-table__th table-align-center`}
					>
						Status
					</HeaderCell>
					<HeaderCell
						onClick={() => changeOrder('date')}
						className={`activity-table__th table-align-right`}
					>
						Date
					</HeaderCell>
					<HeaderCell />
				</HeaderRow>
			</Header>
			<Body>
				{currentTableList.map(item => (
					<Row
						item={item}
						key={item._id}
            className={item?.hidden ? 'an-table__hidden-row' : ''}
					>
						<Cell>
              <UiBaseCheckbox 
                checked={selected.includes(item._id)}
                className='without-label' 
                onChange={(e) => handleCheck(e.target.checked, item._id)}
              />
            </Cell>
						<Cell>{item.firstName} {item.lastName}</Cell>
						<Cell>{item.email}</Cell>
						<Cell>
							{item.action && (
								<Chips
									color={getActionColor(item.action)}
									value={getActionLabel(item.action)}
                  className="chips_minw_80"
								/>
							)}
						</Cell>
						<Cell className="table-align-right">{item.time}</Cell>
						<Cell className="table-align-center">
							<FixedDropdown
								className="campaign"
								items={[
									{
										onClick: () => removeEntry(item),
										iconText: 'Remove from list',
									},
									{
										onClick: () => resendEmail(item),
										iconText: 'Resend email',
									},
								]}
							/>
						</Cell>
					</Row>
				))}
			</Body>
		</>
	)
}

export default ToolsTable