import "./UiBaseCheckbox.scss";
import { useId } from "react";
import classNames from "classnames";

const UiBaseCheckbox = (props) => {
  const _id = useId();

  return (
    <div
      className={`ui-base-checkbox ${props.className || ""}`}
      data-variant={props.variant}
    >
      <input
        type="checkbox"
        name={props.name}
        id={props.id || _id}
        value={props.value || ""}
        className="ui-base-checkbox__input"
        onChange={props.onChange}
        checked={props.checked}
        readOnly={props.readOnly ? "readonly" : false}
        disabled={props.disabled}
      />
      <label
        htmlFor={props.id || _id}
        className={classNames(
          "ui-base-checkbox__label",
          "email-templates-paragraph-2-regular",
          {
            [`ui-base-checkbox__label_align_${props.align}`]: props.align,
            [`ui-base-checkbox__label_spacing_${props.labelSpacing}`]: props.labelSpacing,
            [`ui-base-checkbox__label_position_${props.labelPosition}`]: props.labelPosition,
            [props.extraClass]: props.extraClass,
          }
        )}
      >
        {props.label}
      </label>
    </div>
  );
};

export default UiBaseCheckbox;
