import React, { useMemo } from "react";
import TabNavBar from "../../ui-elements/tab-nav-bar/TabNavBar";
import { Outlet, useLocation } from "react-router-dom";

const StoreSettingsPage = () => {
  const { pathname } = useLocation();

  const tabNavItems = () => {
    const pathArr = pathname.slice(1).split("/");

    return [
      {
        label: "Email settings",
        to: ``,
        active: pathArr.length < 2,
      },
      {
        label: "Delivery settings",
        to: `delivery-settings`,
        active: pathArr.includes("delivery-settings"),
      },
      {
        label: "Domain & accounts",
        to: `domain-accounts`,
        active: pathArr.includes("domain-accounts"),
      },
      {
        label: "Users",
        to: `users`,
        active: pathArr.includes("users"),
      },
    ];
  };

  return (
    <>
      <TabNavBar items={tabNavItems()} />
      <Outlet />
    </>
  );
};

export default StoreSettingsPage;
