import React, { useState, useEffect } from "react";
import Modal from "react-modal";

import { getDefaultPaymentMethod, getPaymentMethods } from "../api/Auth";

import UiButton from "../ui-elements/buttons/UiButton";
import Preloader from "../preloader/Preloader";

import PaymentMethod from "./PaymentMethod";
import PaymentModal from "./PaymentModal";

Modal.setAppElement('#root');

const PaymentMethods = () => {
  const [loading, setLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
  const [paymentMethodUpdated, setPaymentMethodUpdated] = useState(null);

  useEffect(() => {
    async function fetchPaymentMethods() {
      try {
        const currentPaymentMethods = await getPaymentMethods();

        setPaymentMethods(currentPaymentMethods.data);
      } catch (error) {
        console.error(error);
      }
    }

    async function fetchDefaultPaymentMethod() {
      try {
        const currentDefaultPaymentMethod = await getDefaultPaymentMethod();

        setDefaultPaymentMethod(currentDefaultPaymentMethod);
      } catch (error) {
        console.error(error);
      }
    }

    fetchPaymentMethods();
    fetchDefaultPaymentMethod();
    setLoading(false);
  }, [paymentMethodUpdated]);

  const handlePaymentMethodUpdate = () => setPaymentMethodUpdated(Math.random());

  return (
    <div className="card card_24 card_shadow card_overflow_visible">
      <div className="an-layout">
        {paymentMethods.map((paymentMethod, key) => (
          <PaymentMethod
            data={paymentMethod}
            key={key}
            default={paymentMethods.length === 1 || defaultPaymentMethod && defaultPaymentMethod.id === paymentMethod.id}
            onUpdate={handlePaymentMethodUpdate}
          />
        ))}
        <div className="flex-right gap-8 mt-4">
          <UiButton
            text="Add payment method"
            size="medium"
            design="secondary"
            onClick={() => setModalIsOpen(true)}
          />
        </div>
      </div>
      <PaymentModal 
        open={modalIsOpen}
        setOpen={setModalIsOpen}
        handlePaymentMethodUpdate={handlePaymentMethodUpdate}
      />
      {loading && <Preloader overflow={true} absolute={true} />}
    </div>
  );
};

export default PaymentMethods;