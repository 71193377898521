import React, { useContext, useMemo } from "react";
import MainContent from "../../dashboard/MainContent";
import { CampaignContext } from "../../context/CampaignContext";
import { useParams } from "react-router-dom";
import CampaignNotIncluded from "../../campaign/CampaignNotIncluded";
import TabNavBar from "../../ui-elements/tab-nav-bar/TabNavBar";
import BackButton from "../../ui-elements/back-button/BackButton";
import { Outlet, useLocation } from "react-router-dom";
import WithValidSubScription from "../../with-valid-subscription/WithValidSubScription";

const CampaignPage = ({ fullHeight }) => {
  const { campaignSlug, campaignId } = useParams();
  const { pathname } = useLocation();
  const { availableCampaigns, shopCampaigns, campaigns } =
    useContext(CampaignContext);

  const currentCampaign = campaigns.find(
    (campaign) => campaign.slug === campaignSlug
  );

  const configured =
    shopCampaigns.filter(
      (shopCampaign) => shopCampaign.campaignId == currentCampaign._id
    ).length > 0;

  const included =
    availableCampaigns.filter((campaign) => campaign.slug == campaignSlug)
      .length > 0;

  const tabNavItems = () => {
    const pathArr = pathname.slice(1).split("/");
    
    let navItems = [
      {
        label: "Overview",
        to: ``,
        active: pathArr.length < 3,
        disabled: !included || !configured,
      },
      {
        label: "User activities",
        to: `user-activities`,
        active: pathArr.includes("user-activities"),
        disabled: !included || !configured,
      }
    ]

    if (campaignSlug === 'back-in-stock') {
      navItems.push({
        label: "Subscribers",
        to: `subscribers`,
        active: pathArr.includes("subscribers"),
        disabled: !included || !configured,
      });
    }

    if (campaignSlug == 'customer-survey') {
      navItems.push({
        label: "Surveys",
        to: `surveys`,
        active: pathArr.includes("surveys"),
        disabled: !included || !configured,
      });
    }

    if (campaignSlug === 'product-review') {
      navItems.push({
        label: 'Customer feedback',
        to: 'customer-feedback',
        active: pathArr.includes('customer-feedback'),
        disabled: !included || !configured
      });
    }

    if (campaignSlug === 'raffle-giveaway') {
      navItems.push({
        label: 'Participants',
        to: 'participants',
        active: pathArr.includes('participants'),
        disabled: !included || !configured
      });
    }

    return navItems;
  };

  return (
    <>
      {campaignId || pathname.includes('/create') ? <BackButton slug={campaignSlug} /> : <TabNavBar items={tabNavItems()} />}
      {/* TODO: maybe move MainContent with fullHeight prop to Outlet because urls like /campaigns/back-in-stock/user-activities should have full height card element */}
      <MainContent fullHeight={!included || !configured || fullHeight}>
        {included ? (
          <>
            <Outlet configured={configured} />
          </>
        ) : (
          <CampaignNotIncluded />
        )}
      </MainContent>
    </>
  );
};

export default WithValidSubScription(CampaignPage);
