import classNames from "classnames";
import he from "he";
import "./BasePill.scss";

const BasePill = props => {
  return (
    <button className={classNames('base-pill', { 'base-pill_active' : props.active })} onClick={props.onClick}>{he.decode(props.text)}</button>
  )
}

export default BasePill;