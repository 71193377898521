import React from 'react'
import { Header, HeaderRow, HeaderCell, Body, Row, Cell } from "@table-library/react-table-library/table";
import { NavLink } from "react-router-dom";
import moment from 'moment';

const RaffleParticipantsTable = ({
	tableList,
	changeOrder,
	order,
}) => {
	const getDate = (date) => {
		const formated = moment().format(date);
    return moment(formated).format('D MMM yyyy, hh:mma')
  }
    return (
		<>
			<Header>
				<HeaderRow>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.firstName ? ' activity-table__th_active' : ''}`}>Raffle Name</HeaderCell>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.email ? ' activity-table__th_active' : ''}`}>Name</HeaderCell>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.date ? ' activity-table__th_active' : ''}`}>EMAIL</HeaderCell>
					<HeaderCell className={`activity-table__th table-align-right activity-table__th_no-sorting${order?.productName ? ' activity-table__th_active' : ''}`}>Time</HeaderCell>
					<HeaderCell className={`activity-table__th table-align-right activity-table__th_no-sorting${order?.productName ? ' activity-table__th_active' : ''}`}>Entries</HeaderCell>
				</HeaderRow>
			</Header>
			<Body>
				{tableList.map(item => (
					<Row
						item={item}
						key={item._id}
					>
						<Cell>
							<NavLink className='underline' to={`/campaigns/raffle-giveaway/${item.shopCampaignId}`}>{item.instanceName}</NavLink>
						</Cell>
						<Cell>{item.firstName} {item.lastName}</Cell>
						<Cell>{item.email}</Cell>
						<Cell className='text-right'>
							{getDate(item.createdAt)}
						</Cell>
						<Cell className='text-right'>{item.entries}</Cell>
					</Row>
				))}
			</Body>
		</>
	)
}

export default RaffleParticipantsTable;