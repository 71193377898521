import Inputs from "./Inputs";
import "./Question.scss";

const Question = props => {
  const { question, current, total } = props;
  return (
    <div className="onboarding-question">
      <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-4 text-center">Question {current} of {total}</div>
      <div className="email-templates-subtitle-2-bold text-center mt-32">{question.question}</div>
      <div className="email-templates-paragraph-1-regular text-center mt-8 color-secondary-colour-secondary-7" dangerouslySetInnerHTML={{ __html: question.content }} />
      <div className="mt-32 onboarding-question__answers">
        <Inputs
          question={question}
          onboardingData={props.onboardingData}
          placeholder={props.placeholder}
          updateOnboardingData={props.updateOnboardingData}
          value={props.value}
          next={props.next}
          user={props.user}
        />
      </div>
    </div>
  )
}

export default Question;