import React, { useState, useEffect } from 'react'

import UiButton from '../../../ui-elements/buttons/UiButton';
import Preloader from '../../../preloader/Preloader';
import IntroCard from '../IntroCard';
import { getCustomerSurveyTemplates } from '../../../api/Campaign';

const Survey = ({ handleAIGenerate }) => {
  const [surveys, setSurveys] = useState([])
  const [loading, setLoading] = useState(true)

  const setFetchedSurveys = async () => {
    setLoading(true)
    const fetchedSurveys = await getCustomerSurveyTemplates();

    setSurveys(fetchedSurveys);
    setLoading(false)
  }
  
  useEffect(() => {
    setFetchedSurveys();
  }, []);

  if (loading) return <Preloader overflow opaque />

  return (
    <div className="cards-wrapper">
      <div className="cards-list">
      {surveys.map((survey, key) => 
        <IntroCard 
          key={key}
          item={survey}
          buttonText='Select survey'
          handleClick={() => handleAIGenerate({id: survey._id})}
        />
      )}
      </div>
      <div className="mt-40 button-group button-group_center">
        <UiButton
          design="secondary"
          size="medium"
          text="Go back"
          elem="a"
          href={`/campaigns/customer-survey`}
          className="ui-button_mw_180"
        />
      </div>
    </div>
  );
}

export default Survey;