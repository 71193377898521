import React, { useState, useEffect } from 'react'
import Select from 'react-dropdown-select'
import axios from 'axios'

import SectionHead from '../../../ui-elements/common/SectionHead'
import UiHeading from '../../../ui-elements/text/UiHeading'
import UiCaptionRegular from '../../../ui-elements/text/UiCaptionRegular'
import FormControl from '../../../ui-elements/forms/FormControl'
import UiCaptionMedium from '../../../ui-elements/text/UiCaptionMedium'
import Divider from '../../../ui-elements/divider/Divider'
import UiButton from '../../../ui-elements/buttons/UiButton'
import useAuth from "../../../hooks/useAuth";
import Preloader from '../../../preloader/Preloader'
import { Notify } from '../../../ui-elements/notify/notify';

import styles from './DeliverySettings.module.scss'
import timezones from './timezone.json'

const General = () => {
	const { shopData, setShopData } = useAuth();
  const [replyAddress, setReplyAddress] = useState(shopData?.deliverySettingsGeneral?.replyAddress ? shopData.deliverySettingsGeneral.replyAddress : '');
  const [sendTestEmailAddress, setSendTestEmailAddress] = useState(shopData?.deliverySettingsGeneral?.sendTestEmailAddress ? shopData.deliverySettingsGeneral.sendTestEmailAddress : '');
  const [deliveryTimeZone, setDeliveryTimeZone] = useState(shopData?.deliverySettingsGeneral?.deliveryTimeZone ? shopData.deliverySettingsGeneral.deliveryTimeZone : (shopData?.timezone ? shopData.timezone : ''));
  const [wasDataChanged, setWasDataChanged] = useState(false);
  const [saving, setSaving] = useState(false);

  const [initialObject, setInitialObject] = useState({
    replyAddress: shopData?.deliverySettingsGeneral?.replyAddress ? shopData.deliverySettingsGeneral.replyAddress : '',
    sendTestEmailAddress: shopData?.deliverySettingsGeneral?.sendTestEmailAddress ? shopData.deliverySettingsGeneral.sendTestEmailAddress : '',
    deliveryTimeZone: shopData?.deliverySettingsGeneral?.deliveryTimeZone ? shopData.deliverySettingsGeneral.deliveryTimeZone : (shopData?.timezone ? shopData.timezone : '')
  });

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
      return true;
    }

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  useEffect(() => {
    const updObject = {
      replyAddress,
      sendTestEmailAddress,
      deliveryTimeZone
    }

    setWasDataChanged(!deepEqual(initialObject, updObject));
  }, [replyAddress, sendTestEmailAddress, deliveryTimeZone]);

	const handleSubmit = e => {
    e.preventDefault();

    setSaving(true);

    const data = {
      replyAddress,
      sendTestEmailAddress,
      deliveryTimeZone
    }

    axios.put('/shop', {
      deliverySettingsGeneral: data,
      timezone: deliveryTimeZone
    }, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    }).then(() => {
      setInitialObject(data);
      setWasDataChanged(false);
      setSaving(false);
      setShopData((prev) => {
        prev.deliverySettingsGeneral = data
        return prev
      })
      Notify.success({
        title: 'Changes saved successfully',
        message: 'Delivery settings have been updated.',
      });
    }).catch(err => {
      console.error(err);

      setSaving(false);
    });
  }

  const resetChanges = e => {
    e.preventDefault();

    setReplyAddress(initialObject.replyAddress);
    setSendTestEmailAddress(initialObject.sendTestEmailAddress);
    setDeliveryTimeZone(initialObject.deliveryTimeZone);
  }

  const getTimeZoneLabel = zone => {
    const timeZone = timezones.find(tz => tz.zone === zone);

    if (timeZone) {
      return `${timeZone.name} ${timeZone.gmt}`;
    }

    return 'Time zone is not found';
  }

	return (
    <div className='an-layout'>
			<SectionHead
				title={
					<>
						<UiHeading
							text='General settings'
							desktop='email-templates-subtitle-2-bold'
						/>
            <UiCaptionRegular
              text='Customise essential email parameters for seamless communication.'
              margin='4'
              className='color-secondary-colour-secondary-7'
            />
					</>
				}
			/>
      <form className='card card_24 card_shadow card_overflow_visible' onSubmit={handleSubmit}>
        <div className='an-layout'>
          <div className='an-row'>
            <div className='an-col an-col_50'>
              <div className={styles['title']}>
                <UiCaptionMedium text='Reply address' />
              </div>
              <FormControl
                type='email'
                id='reply_address'
                name='replyAddress'
                placeholder='Ex. contact@anomate.co'
                value={replyAddress}
                labelMargin='4'
                onChange={e => setReplyAddress(e.target.value)}
                labelClassName='ui-caption-medium'
              />
            </div>
            <div className='an-col an-col_50'>
              <div className={styles['title']}>
                <UiCaptionMedium text='Send test email address' />
              </div>
              <FormControl
                type='email'
                id='testEmail'
                name='testEmail'
                value={sendTestEmailAddress}
                placeholder='Ex. myemail@email.com'
                labelMargin='4'
                onChange={e => setSendTestEmailAddress(e.target.value)}
                labelClassName='ui-caption-medium'
              />
            </div>
          </div>
          <div className='an-row'>
            <div className='an-col an-col_50'>
              <div className={styles['title']}>
                <UiCaptionMedium text='Delivery Time Zone' />
              </div>
              <div className='mt-4'>
                <Select
                  options={timezones.map((tz) => ({
                    value: tz.zone,
                    label: `${tz.name} ${tz.gmt}`,
                    key: tz.name
                  }))}
                  searchable={true}
                  placeholder='Select Time Zone'
                  className='form-select'
                  onChange={e => setDeliveryTimeZone(e[0].value)}
                  values={[{
                    label: getTimeZoneLabel(deliveryTimeZone),
                    value: deliveryTimeZone
                  }]}
                />
              </div>
            </div>
          </div>
          <Divider />
          <div className="flex-right gap-8 mt-4">
            <UiButton
              type="button"
              text="Cancel"
              size="medium"
              design="secondary"
              onClick={resetChanges}
              disabled={!wasDataChanged}
            />
            <UiButton
              text="Save changes"
              size="medium"
              disabled={!wasDataChanged}
            />
          </div>
        </div>
        {saving && (
          <Preloader
            absolute={true}
            overflow={true}
          />
        )}
      </form>
		</div>
	)
}

export default General