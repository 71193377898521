import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import UiBaseCheckbox from "../ui-elements/forms/UiBaseCheckbox";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import UiButton from "../ui-elements/buttons/UiButton";
import FormControl from "../ui-elements/forms/FormControl";
import SignUpAccordionIcon from "./SignUpAccordionIcon";
import UiBodyBold from "../ui-elements/text/UiBodyBold";
import "./SignUpForm.scss";

const SignUpForm = () => {
  const [activeAccordionItem, setActiveAccordionItem] = useState(0);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [industries, setIndustries] = useState([]);
  const [cookies, setCookie] = useCookies(['lead']);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('/industries')
      .then(response => {
        let industriesData = [];

        response.data.map(industry => {
          return industriesData.push({
            value: industry._id,
            label: industry.name
          });
        });

        setIndustries(industriesData);
      })
      .catch(err => console.error(err));
  }, []);

  const updateData = e => {
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        setData({...data, [e.target.name]: e.target.value});
      } else {
        const newData = {...data};

        delete newData[e.target.name];
        
        setData(newData);
      }
    } else {
      setData({...data, [e.target.name]: e.target.value});
    }
  }

  const updateErrors = errors => {
    setErrors(errors);

    if (typeof errors.firstName !== 'undefined' || typeof errors.lastName !== 'undefined' || typeof errors.email !== 'undefined') {
      setActiveAccordionItem(0);
    } else if (typeof errors.businessName !== 'undefined' || typeof errors.websiteUrl !== 'undefined' || typeof errors.industry !== 'undefined') {
      setActiveAccordionItem(1);
    }
  }

  const successSignUp = data => {
    setCookie('lead', data);
    setErrors({});
    navigate('/select-your-plan');
  }

  const handleSubmitEvent = e => {
    e.preventDefault();
    
    axios.post('/leads', data)
      .then(response => successSignUp(response.data))
      .catch(err => {
        if (err.response.data.name === 'ValidationError') {
          updateErrors(err.response.data.errors);
        }
      });
  }

  const handleAccordionClick = index => setActiveAccordionItem(index);

  return (
    <form
      className="mt-32 login-form login-form_sign-up"
      onSubmit={handleSubmitEvent}
    >
      <div className="an-accordion">
        <div
          className={`an-accordion__item${activeAccordionItem === 0 ? ' an-accordion__item_active' : ''}`}
          onClick={(() => handleAccordionClick(0))}
        >
          <div className="an-accordion__title ui-body-bold">
            <UiBodyBold text="Personal details" />
            <SignUpAccordionIcon />
          </div>
          <div className="an-accordion__body">
            <div className="an-row">
              <div className="an-col an-col_50">
                <FormControl
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="First name*"
                  onChange={updateData}
                  validationError={errors.firstName}
                />
              </div>
              <div className="an-col an-col_50">
                <FormControl
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Last name*"
                  onChange={updateData}
                  validationError={errors.lastName}
                />
              </div>
            </div>
            <FormControl
              type="email"
              name="email"
              id="email"
              placeholder="Email*"
              margin="16"
              onChange={updateData}
              validationError={errors.email}
            />
          </div>
        </div>
        <div
          className={`an-accordion__item${activeAccordionItem === 1 ? ' an-accordion__item_active' : ''}`}
          onClick={(() => handleAccordionClick(1))}
        >
          <div className="an-accordion__title ui-body-bold">
            <UiBodyBold text="Business details" />
            <SignUpAccordionIcon />
          </div>
          <div className="an-accordion__body">
            <FormControl
              type="text"
              name="businessName"
              id="businessName"
              placeholder="Business name*"
              onChange={updateData}
              validationError={errors.businessName}
            />
            <FormControl
              type="url"
              name="websiteUrl"
              id="websiteUrl"
              placeholder="Website URL*"
              margin="16"
              onChange={updateData}
              validationError={errors.websiteUrl}
            />
            <FormControl
              type="select"
              name="industry"
              id="industry"
              placeholder="Indsutry*"
              margin="16"
              onChange={updateData}
              options={industries}
              validationError={errors.industry}
            />
          </div>
        </div>
      </div>
      <div className="mt-64 text-center">
        <UiButton
          type="submit"
          size="large"
          text="Sign up"
          className="ui-button_mw_200"
        />
        <div className="mt-16">
          <UiBaseCheckbox
            name="marketing"
            id="marketing"
            value="1"
            label="I am happy to receive your marketing emails."
            extraClass="color-gray-gray-9 pl-26"
            onChange={updateData}
          />
        </div>
        <UiCaptionRegular
          margin="8"
          marginMobile="16"
          text={<>By signing in, I agree to Anomate’s <a href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL} target="_blank" rel="noreferrer">Terms & Conditions</a> and <a href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">Privacy Policy</a></>}
        />
        <UiCaptionRegular
          margin="40"
          text={<>Already have an account? <a href="/login">Log in</a></>}
        />
      </div>
    </form>
  );
}

export default SignUpForm;