const createChartTooltip = (context) => {
  const tooltipModel = context.tooltip;
  if (!tooltipModel.dataPoints) return
  const dataPoint = tooltipModel.dataPoints[0]
  // Tooltip Element
  let tooltipEl = document.getElementById('chartjs-tooltip_custom');
  // Create element on first render
  if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip_custom';
      tooltipEl.innerHTML = '<table></table>';
      document.body.appendChild(tooltipEl);
  }

  // Hide if no tooltip
    if (tooltipModel.opacity === 0 || (dataPoint?.dataset?.showTooltip && !dataPoint?.dataset?.showTooltip[dataPoint?.dataIndex])) {
      tooltipEl.style.opacity = 0;
      return;
  }
    
  // Set caret Position
  tooltipEl.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
      tooltipEl.classList.add('no-transform');
  }

  function getBody(bodyItem) {
      return bodyItem.lines;
  }

	// Set Text
  if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);
			const { displayColors = true } = context.chart.config['_config'].options.plugins.tooltip

			let innerHtml = '<thead>';

      titleLines.forEach(function(title) {
          innerHtml += '<tr><th>' + title + '</th></tr>';
      });
      innerHtml += '</thead><tbody>';

      bodyLines.forEach(function(body, i) {
          const colors = tooltipModel.labelColors[i];
          innerHtml += '<tr>';
          innerHtml += '<td>';
          innerHtml += '<div class="chartjs-tooltip">';
          innerHtml += '<div class="chartjs-tooltip__label">';
          if (displayColors) {
						innerHtml += `<div class="chartjs-tooltip__box" style="background: ${colors.backgroundColor}"></div>`;
					}
          innerHtml += '</div>';
          innerHtml += `<div class="chartjs-tooltip__value">${body}</div>`;
          innerHtml += '</div>';
          innerHtml += '</td>';
          innerHtml += '</tr>';
      });
      innerHtml += '</tbody>';

      let tableRoot = tooltipEl.querySelector('table');
      tableRoot.innerHTML = innerHtml;
  }

  const position = context.chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = 'absolute';
  tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
  tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
  tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
  tooltipEl.style.pointerEvents = 'none';
}

export default createChartTooltip