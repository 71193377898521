import React, { useContext, useState, useMemo, useEffect } from "react";
import ModalLayout from "../../modal/Layout";
import StepWizard from "../../ui-elements/step-wizard/StepWizard";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { saveEmailFooter } from "../../api/Shop";
import Preloader from "../../preloader/Preloader";
import { Notify } from "../../ui-elements/notify/notify";
import { getEmailFooterFields } from "../../api/Customizer";
import Customization from "../../brand-assets/email-footer/Customization";

const EmailFooterCustomize = () => {
  const { shopData, updateShop } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [schemas, setSchemas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmailFooterFields();
  }, []);

  const steps = useMemo(() => {
    return {
      customizer: {
        title: "Customise your email footer",
        description: <>Customise the layout of your email footer with ease. Choose from various options such as displaying social media icons, <br/> company logo, adding footer links, and including a review snippet for a fully tailored and professional email footer.</>,
        component: <Customization schemas={schemas} />,
        submitButtonLabel: "Save",
      },
    };
  }, [schemas]);

  const fetchEmailFooterFields = async () => {
    setLoading(true);
    try {
      const fields = await getEmailFooterFields();
      setSchemas(fields);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleComplete = async (data) => {
    if (saving) return;
    setSaving(true);
    try {
      await saveEmailFooter(data);
      await updateShop();
      Notify.success({ title: "Email footer updated successfully" });
      handleClose();
    } catch (e) {
      console.log("error", e);
    }
    setSaving(false);
  };

  const handleClose = () => {
    navigate("/brand-assets/common-layouts");
  };

  return (
    <ModalLayout backTo="/brand-assets/common-layouts">
      {!loading && (
        <StepWizard
          data={shopData.emailFooter}
          steps={steps}
          onComplete={handleComplete}
          onClose={handleClose}
        />
      )}
      {loading && <Preloader overflow />}
    </ModalLayout>
  );
};

export default EmailFooterCustomize;
