import { useState, useEffect } from "react";
import Intro from "./Intro";
import Questionnaire from "./Questionnaire";
import "./Survey.scss";

const Survey = props => {
  const [state, setState] = useState('intro');
  const [step, setStep] = useState(1);

  useEffect(() => {
    props.user.onboardingStep ? setQuestionsState() : setIntroState();
  }, []);

  const setQuestionsState = () => setState('questions');

  const setIntroState = () => {
    setState('intro');
    setStep(1);
  }

  return (
    <div className="onboarding-survey">
      <div className="container">
        <div className="onboarding-survey__card">
          {state === 'intro' ? 
            <Intro setQuestionsState={setQuestionsState} />
          : 
            <Questionnaire user={props.user} setIntroState={setIntroState} step={props.step} />
          }
        </div>
      </div>
    </div>
  );
}

export default Survey;