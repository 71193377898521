import validator from "validator";

export const validateField = (value, type) => {
    if (type === "url") {
      return validator.isURL(value);
    } else if (type === "openingHours") {
      return value.some((item) => {
        return (
          item.opensAt1 !== "" ||
          item.closesAt1 !== "" ||
          item.opensAt2 !== "" ||
          item.closesAt2 !== "" ||
          item.closed === true
        );
      });
    } else if (type === "email") {
      return validator.isEmail(value);
    } else if (type === "tel") {
      return validator.isMobilePhone(value);
    }

    return value;
  };