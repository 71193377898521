import { useEffect, useState } from "react";
import TotalEmailsSent from "./TotalEmailsSent";
import ActivityTable from "../../ui-elements/activity-table/ActivityTable";
import PopupPerformance from "./PopupPerformance";
import KPIStats from '../../ui-elements/kpi-stats/KPIStats';
import "./Overview.scss";

const GenerateNewSubscribersSingleOverview = ({ campaigns, slug }) => {
  const statObj = {
    totalRevenue: 0,
    totalOrders: 0,
    popupsDisplayed: 0,
    newSubscribers: 0,
    clicked: 0,
    purchased: 0
  }
  const [stats, setStats] = useState(statObj)

  useEffect(() => {
    const { 
      totalRevenue,
      totalOrders,
      popupsDisplayed,
      newSubscribers,
      clicked,
      purchased
    } = campaigns[0]
    
    setStats({
      totalRevenue,
      totalOrders,
      popupsDisplayed,
      newSubscribers,
      clicked,
      purchased
    })
  }, [campaigns]);

  return (
    <div className="an-layout">
      <div className="an-row an-row_gap_8">
        <KPIStats 
          stats={stats}
          indicators={{
            popupsDisplayed: 'Popups displayed',
            newSubscribers: 'Total new subscribers',
            totalOrders: 'Total orders',
            totalRevenue: {
              type: 'money',
              label: 'Total revenue',
            }
          }}
        />
      </div>
      {!!stats.popupsDisplayed && (
        <div className="an-row">
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
            <PopupPerformance
              totalPopupsDisplayed={stats.popupsDisplayed}
              subscribed={stats.newSubscribers}
              clicked={stats.clicked}
              purchased={stats.purchased}
            />
          </div>
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
            <TotalEmailsSent campaigns={campaigns} />
          </div>
        </div>
      )}
      <ActivityTable
        slug={slug}
        type="campaign-activity"
        shopCampaignId={campaigns[0]._id}
        testEmail={true}
        single={false}
      />
    </div>
  )
}

export default GenerateNewSubscribersSingleOverview;