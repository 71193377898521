import { Info } from "akar-icons";
import { Tooltip } from "react-tooltip";
import "./Score.scss";

const Score = ({ value }) => {
  return (
    <div className="complete-progress">
      <div className="complete-progress__inner">
        <div className="complete-progress__head">
          <div className="email-templates-caption-medium">Anomate score</div>
          <div className="complete-progress__caption">
            <div className="email-templates-button-xs-medium color-secondary-colour-secondary-7">{value} out of 100</div>
            <div
              className="complete-progress__icon"
              data-tooltip-id="anomate-score"
              data-tooltip-html='<div class="ui-extra-small-medium text-uppercase color-gray-gray-9">What is Anomate score?</div><div class="ui-small-regular mt-8 color-secondary-colour-secondary-7">This score indicates how well Anomate understands your brand and its readiness to generate accurate content. Complete pending tasks to train Anomate for better results.</div>'
              data-tooltip-place="bottom-end"
            >
              <Info
                size="16"
                color="#9254de"
              />
              <Tooltip
                id="anomate-score"
                className="complete-progress__tooltip"
                arrowColor="transparent"
              />
            </div>
          </div>
        </div>
        <div className="complete-progress__body mt-2">
          {!!value && <div className="complete-progress__current" style={{ width: `${value}%` }}></div>}
        </div>
      </div>
    </div>
  );
}

export default Score;