import { Link } from "react-router-dom";

const HeaderProfileNav = props => {
  return (
    <nav className="header-profile-nav fadeIn">
      <ul className="header-profile-nav__list">
        {props.links.map((link, key) => (
          <li
            className="header-profile-nav__item"
            key={key}
          >
            <Link
              to={link.url}
              target={link.target}
              className="ui-caption-regular header-profile-nav__link color-secondary-colour-secondary-7"
              onClick={props.onClick}
            >
              <div className="header-profile-nav__icon">{link.icon}</div>
              <div className="header-profile-nav__title">{link.title}</div>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default HeaderProfileNav;