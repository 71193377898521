import { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { addDays } from 'date-fns';

import SectionHead from "../../ui-elements/common/SectionHead";
import { getStaticRanges } from '../../performance/Performance';
import Preloader from '../../preloader/Preloader';
import WrapperDatePicker from './WrapperDatePicker';
import EmptyData from './EmptyData';

import { getEmailsSentStats } from '../../api/Campaign';

import "../../ui-elements/charts/ChartJsTooltip.scss";

const TotalEmailsSent = ({ campaigns, slug, individual }) => {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Tooltip);

  const [barLabels, setBarLabels] = useState([]);
  const [filterLabel, setFilterLabel] = useState('Last 7 days');
  const [isHoveringChart, setIsHoveringChart] = useState(false);
  const today = new Date();
  const staticRanges = getStaticRanges();
  const [performanceDateRange, setPerformanceDateRange] = useState([addDays(today, -6), today]);
  const [emailsSent, setEmailsSent] = useState([]);
  const [cumulativeSent, setCumulativeSent] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setData();
  }, [campaigns]);

  useEffect(() => {
    const handleMouseMove = event => {
      const element = document.querySelector('.overview-total-emails-sent');

      if (element && !element.contains(event.target) && !isHoveringChart) {
        const tooltip = document.getElementById('chartjs-tooltip');

        tooltip && tooltip.remove();
      }
    }
  
    window.addEventListener('mousemove', handleMouseMove);
  
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isHoveringChart]);

  const [state, setState] = useState([{
    startDate: addDays(today, -6),
    endDate: today,
    key: 'selection'
  }]);

  const dateFormattingOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  const setData = async () => {
    const data = await getEmailsSentStats(campaigns, performanceDateRange, slug, individual);
    let emailsSentData = [];
    let cumulativeData = [];
    let cumulative = 0;

    setBarLabels(Object.keys(data));

    Object.entries(data).map(([date, value]) => {
      emailsSentData.push(value);
      
      cumulative += value;

      cumulativeData.push(cumulative);
    });

    setEmailsSent(emailsSentData);
    setCumulativeSent(cumulativeData);
    setLoading(false);
  }

  const updateData = item => {
    setState([item.selection]);
    setPerformanceDateRange([item.selection.startDate, item.selection.endDate]);
  }

  const reloadPerformance = () => {
    setLoading(true);
    setFilterLabel(`${performanceDateRange[0].toLocaleDateString('en-GB', dateFormattingOptions)} - ${performanceDateRange[1].toLocaleDateString('en-GB', dateFormattingOptions)}`);
    setData();
  }

  const externalTooltipHandler = context => {
    let tooltipEl = document.getElementById('chartjs-tooltip');
    const tooltipModel = context.tooltip;
    
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<table></table>';
      
      document.body.appendChild(tooltipEl);
    }
    
    const getBody = bodyItem => {
      return bodyItem.lines;
    }
    
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);
      let innerHtml = '<thead>';
      
      titleLines.forEach(title => {
        innerHtml += '<tr>';
        innerHtml += '<th>';
        innerHtml += title;
        innerHtml += '</th>';
        innerHtml += '</tr>';
      });
      
      innerHtml += '</thead>';
      innerHtml += '<tbody>';
  
      bodyLines.forEach((body, i) => {
        const splittedBody = body[0].split(':');

        if (splittedBody.length > 1) {
          innerHtml += '<tr>';
          innerHtml += '<td>';
          innerHtml += '<div class="chartjs-tooltip">';
          innerHtml += '<div class="chartjs-tooltip__label">';
          innerHtml += `<div class="chartjs-tooltip__box" style="background: ${tooltipModel.labelColors[i].backgroundColor}"></div>`;
          innerHtml += `<div class="chartjs-tooltip__label-value">${splittedBody[0]}</div>`;
          innerHtml += '</div>';
          innerHtml += `<div class="chartjs-tooltip__value">${splittedBody[1]}</div>`;
          innerHtml += '</div>';
          innerHtml += '</td>';
          innerHtml += '</tr>';
        }
      });
      
      innerHtml += '</tbody>';
  
      let tableRoot = tooltipEl.querySelector('table');
      
      tableRoot.innerHTML = innerHtml;
    }
    
    const position = context.chart.canvas.getBoundingClientRect();
    
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - 73 + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 100 + 'px'
  }
  
  const cumulativeSentCount = cumulativeSent.reduce((acc, item) => acc + item, 0)
  const emailsSentCount = emailsSent.reduce((acc, item) => acc + item, 0)

  return (
    <>
      <SectionHead
        title={
          <>
            <div className="email-templates-subtitle-2-medium">
              Total {slug && slug == 'customer-survey' ? 'surveys' : 'emails'} sent
            </div>
            <div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">
              Total number of {slug && slug == 'customer-survey' ? 'surveys' : 'emails'} sent for this campaign
            </div>
          </>
        }
        filter={
          <WrapperDatePicker 
            filterLabel={filterLabel}
            handleChange={item => updateData(item)}
            ranges={state}
            staticRanges={staticRanges}
            today={today}
            secondaryText={`${performanceDateRange[0].toLocaleDateString('en-GB', dateFormattingOptions)} - ${performanceDateRange[1].toLocaleDateString('en-GB', dateFormattingOptions)}`}
            reloadPerformance={reloadPerformance}
          />
        }
      />

        <div
          className="mt-24 overview-total-emails-sent"
          onMouseOver={() => setIsHoveringChart(true)}
          onMouseOut={() => setIsHoveringChart(false)}
        >
          {loading && <Preloader overflow={true} className="preloader_br_24" />}
          {(!cumulativeSentCount && !emailsSentCount && !loading) && <EmptyData />}
          {(!!cumulativeSentCount || !!emailsSentCount) && !loading && 
            <Bar
              data={{
                labels: barLabels,
                datasets: [
                  {
                    label: 'Cumulative',
                    data: cumulativeSent,
                    borderColor: '#9254de',
                    backgroundColor: '#9254de',
                    tension: 0.4,
                    pointRadius: 0,
                    pointHoverRadius: 4,
                    borderWidth: 2,
                    type: 'line',
                    yAxisID: 'y1'
                  },
                  {
                    label: 'Total emails sent',
                    data: emailsSent,
                    borderColor: '#4bd4fb',
                    backgroundColor: '#4bd4fb',
                    borderRadius: 6,
                    yAxisID: 'y'
                  }
                ]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                  mode: 'index',
                  intersect: false,
                },
                stacked: false,
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    enabled: false,
                    callbacks: {
                      label: tooltipItem => {
                        return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                      }
                    },
                    external: externalTooltipHandler
                  }
                },
                animations: false,
                scales: {
                  x: {
                    grid: {
                      display: false
                    },
                    ticks: {
                      color: '#bfbfbf',
                      font: {
                        family: 'Poppins',
                        size: 12,
                        weight: 500,
                        lineHeight: 1.75
                      }
                    }
                  },
                  y: {
                    position: 'left',
                    border: {
                      dash: [3, 4]
                    },
                    grid: {
                      color: '#d9d9d9'
                    },
                    ticks: {
                      color: '#bfbfbf',
                      font: {
                        family: 'Poppins',
                        size: 12,
                        weight: 500,
                        lineHeight: 1.75
                      }
                    }
                  },
                  y1: {
                    position: 'right',
                    border: {
                      dash: [3, 4]
                    },
                    grid: {
                      drawOnChartArea: false
                    },
                    ticks: {
                      color: '#bfbfbf',
                      font: {
                        family: 'Poppins',
                        size: 12,
                        weight: 500,
                        lineHeight: 1.75
                      }
                    }
                  }
                }
              }}
            />
          }
        </div>

      
    </>
  )
}

export default TotalEmailsSent;