import React, { useState, useCallback, useEffect } from "react";
import FormControl from "../../forms/FormControl";
import Divider from "../../divider/Divider";
import { getShopProducts } from "../../../api/Shop";
import styles from "./UpsellTypeEditor.module.scss";
import UiBaseCheckbox from "../../forms/UiBaseCheckbox";
import classNames from "classnames";
import _ from "lodash";
import TagItem from "../../../onboarding/TagItem";

const options = [
  {
    label: "Related product",
    value: "relatedProduct",
  },
  {
    label: "Same tag",
    value: "sameTag",
  },
  {
    label: "Same category",
    value: "sameCategory",
  },
  {
    label: "Manual selection",
    value: "manualSelection",
  },
];

export const UpsellTypeEditor = (props) => {
  const [value, setValue] = useState(props.value || {});
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const fetchProducts = async () => {
    try {
      const response = await getShopProducts(null, '');
      setProducts(response.items);
      setFilteredProducts(response.items);
    } catch (err) {
      console.log("error");
    }
  };

  useEffect(() => {
    if (value?.type !== "manualSelection") return;
    if (products.length > 0) return;
    fetchProducts();
  }, [value?.type, products]);

  const handleOnChange = useCallback(
    (e) => {
      setValue({ type: e.target.value });
      props.onChange({ type: e.target.value });
    },
    [props.onChange]
  );

  const handleProductChange = useCallback(
    (checked, productId) => {
      let selectedProducts = value.products || [];

      if (checked) {
        selectedProducts.push(productId);
      } else {
        selectedProducts = selectedProducts.filter(
          (product) => product !== productId
        );
      }
      const newValue = { ...value, products: selectedProducts };
      setValue(newValue);
      props.onChange(newValue);
    },
    [products, value, setValue, props.onChange]
  );

  const handleFilterProducts = (value) => {
    if (!value) {
      setFilteredProducts(products);
      return;
    }

    const _products = products.filter((product) =>
      product.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredProducts(_products);
  };

  const debounceFn = useCallback(_.debounce(handleFilterProducts, 300), [
    products,
  ]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    debounceFn(e.target.value);
  };

  const isCheckedProduct = (pId) => {
    if (!value?.products) return false;

    return value.products.includes(pId);
  };

  return (
    <div className="an-layout an-layout_gap_16">
      <FormControl
        type={`radio`}
        value={value?.type || ""}
        onChange={handleOnChange}
        options={options}
        required={props.required}
        validationError={props.validationError}
        blockRadios
        blocks={1}
        blockGap={16}
        variant="pill"
      />

      {value?.type === "manualSelection" && (
        <>
          <Divider className={styles["type-divider"]} />

          <div className="an-layout an-layout_gap_8">
            <FormControl
              value={searchValue}
              onChange={handleSearch}
              type={`text`}
              placeholder="Search products"
              className="form-control__input_search"
            />
            {products.length > 0 &&
              value?.products &&
              value.products.length > 0 && (
                <div
                  className={classNames(
                    styles["selected-products"],
                    "scrollbar"
                  )}
                >
                  {value.products.map((pId) => (
                    <TagItem
                      tag={products.find((product) => product._id === pId).name}
                      key={`${pId}-tag-item`}
                      onClick={() => handleProductChange(false, pId)}
                      lineClamp={1}
                      size="small"
                    />
                  ))}
                </div>
              )}

            <Divider />

            <div className={classNames(styles["products"], "scrollbar")}>
              {filteredProducts.map((product, index) => (
                <UiBaseCheckbox
                  key={product._id}
                  label={product.name}
                  checked={isCheckedProduct(product._id)}
                  onChange={(e) =>
                    handleProductChange(e.target.checked, product._id)
                  }
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
