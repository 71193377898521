import UiSmallRegular from '../../ui-elements/text/UiSmallRegular';

import EmptySvg from '../../static/images/charts/Empty.svg'
import './EmptyData.scss'

const EmptyData = () => {
	return  (
		<div className='empty-data'>
			<div>
				<img
					src={EmptySvg}
					alt="Need more data"
				/>
			</div>
			<UiSmallRegular
				className="color-secondary-colour-secondary-7 text-center"
				text={'This chart needs more data.'}
			/>
		</div>
	)
}

export default EmptyData