import axios from "axios";

// Get user
export const getUser = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/user", {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get user
export const updateUser = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put("/user", data, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get user
export const getUserInvoice = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/user/invoice", {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get Shop information
export const getShopInfo = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/shop", {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get Shop score
export const getShopScore = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/shop/score", {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get Shop score
export const getShopUpgradable = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/shop/upgradable", {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// Login with email and password
export const loginWithEmailPassword = ({ email, password }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "/login",
        {
          email,
          password,
          uuid: localStorage.getItem('uuid')
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

// Verify and Save 2FA
export const saveTwoFactor = (verificationCode) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "/user/2fa/save",
        { token: verificationCode },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export const verifyTwoFactor = ({ verificationCode, email, password, uuid, trustedDevice }) => {
  return new Promise((resolve, reject) => {
    axios.post('/user/2fa/verify', {
      token: verificationCode,
      email,
      password,
      uuid,
      trustedDevice
    }, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    })
    .then(response => resolve(response.data))
    .catch(err => reject(err.response.data));
  });
}

// Get payment methods
export const getPaymentMethods = () => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .get("/user/payment-methods", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get default payment method
export const getDefaultPaymentMethod = () => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .get("/user/payment-methods/default", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Set payment method as default
export const setPaymentMethodAsDefault = id => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .post(`/user/payment-methods/default`, { id }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Remove payment method
export const removePaymentMethod = id => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .delete(`/user/payment-methods/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Create payment method
export const createPaymentMethod = id => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .post(`/user/payment-methods`, {
        id: id
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

// Get invoices
export const getInvoices = () => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .get(`/user/invoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}