import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import UserDetailsAndPreferences from "../../profile/UserDetailsAndPreferences";
import PasswordSettings from "../../profile/PasswordSettings";
import MainContent from "../../dashboard/MainContent";

const UserDetailsPage = () => {
  const { user } = useContext(AuthContext);
  return (
    <MainContent>
      <div className="an-layout">
        <SectionHead
          title={
            <>
              <UiHeading
                text="User details and preferences"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                Update your avatar, set newsletter preferences, and ensure your
                name, email, and position details are accurate.
              </div>
            </>
          }
        />
        <UserDetailsAndPreferences user={user} />
        <SectionHead
          title={
            <>
              <UiHeading
                element="h2"
                text="Password settings"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                To enhance the security of your account, please provide your
                current password, create a new one, and confirm the change.
              </div>
            </>
          }
        />
        <PasswordSettings user={user} />
      </div>
    </MainContent>
  );
};

export default UserDetailsPage;
