import React from 'react'

const Select = (props) => {
  return (
    <div className={`form-control${props.margin ? ` mt-${props.margin}` : ""}`}>
      <select
        id={props.id}
        name={props.name}
        className={`form-control__input ui-caption-regular form-control__input_select${
          props.validationError ? " form-control__input_error" : ""
        }`}
        required={props.required ? props.required : false}
        onChange={props.onChange}
        defaultValue={props.defaultValue ? props.value : props.placeholder}
        readOnly={props.readOnly ? "readonly" : false}
        onClick={props.onClick}
      >
        <option value={props.placeholder} disabled>
          {props.placeholder}
        </option>
        {props.options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {props.validationError && (
        <div className="form-control__error ui-caption-regular">
          {props.validationError.message}
        </div>
      )}
    </div>
  );
}

export default Select;