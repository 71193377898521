import React, { useState, useRef } from "react";
import IncludeCoupon from "./IncludeCoupon";
import CouponType from "./CouponType";
import CouponAmount from "./CouponAmount";
import CouponMinAmount from "./CouponMinAmount";
import CouponValidity from "./CouponValidity";
import Navigation from "./Navigation";
import ChosenOptions from "./ChosenOptions";
import CouponParagraph from "./CouponParagraph";

const STEPS = {
  INCLUDE_COUPON: 1,
  TYPE: 2,
  AMOUNT: 3,
  MIN_AMOUNT_SALE_ITEMS: 4,
  VALIDITY: 5,
  PARAGRAPH: 6
};

const CouponEditor = (props) => {
  const [value, setValue] = useState(props.value || {});
  const [step, setStep] = useState(STEPS.INCLUDE_COUPON);
  const stepRef = useRef(null);
  const handleOnChange = (key, value) => {
    setValue((prev) => ({ ...prev, [key]: value }));
  };

  const [nextDisabled, setNextDisabled] = useState(false);

  const handleComplete = (value) => {
    props.onChange && props.onChange(value);
    props.showNextCustomizationElement && props.showNextCustomizationElement();
  };

  const handleIncludeCouponChange = (includeCoupon) => {
    const newValue = {...value, includeCoupon}
    setValue(newValue);

    if (includeCoupon) {
      setStep(STEPS.TYPE);
    } else {
      handleComplete(newValue);
    }
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    
    stepRef.current.submit();
  };

  const getValues = () => {
    return value;
  }

  return (
    <div>
      <ChosenOptions value={value} />
      <div className="an-layout">
        {step === STEPS.INCLUDE_COUPON && (
          <IncludeCoupon
            ref={stepRef}
            value={value.includeCoupon}
            onChange={handleIncludeCouponChange}
            required={props.required}
            onValidate={result => setNextDisabled(!result)}
          />
        )}

        {step === STEPS.TYPE && (
          <CouponType
            ref={stepRef}
            value={value.type}
            onNext={handleSubmit}
            onValidate={result => setNextDisabled(!result)}
            onSubmit={(value) => {
              handleOnChange("type", value);
              setStep(STEPS.AMOUNT);
            }}
          />
        )}

        {step === STEPS.AMOUNT && (
          <CouponAmount
            ref={stepRef}
            type={value.type}
            value={value.amount}
            onValidate={result => setNextDisabled(!result)}
            onNext={handleSubmit}
            onSubmit={(value) => {
              handleOnChange("amount", value);
              setStep(STEPS.MIN_AMOUNT_SALE_ITEMS);
            }}
          />
        )}

        {step === STEPS.MIN_AMOUNT_SALE_ITEMS && (
          <CouponMinAmount
            ref={stepRef}
            minAmount={value.minAmount}
            excludeSaleItems={value.excludeSaleItems}
            onValidate={result => setNextDisabled(!result)}
            onSubmit={(value) => {
              setValue((prev) => ({
                ...prev,
                excludeSaleItems: value.excludeSaleItems,
                minAmount: value.minAmount,
              }));
              setStep(STEPS.VALIDITY);
            }}
          />
        )}

        {step === STEPS.VALIDITY && (
          <CouponValidity
            ref={stepRef}
            value={value.validity}
            onValidate={result => setNextDisabled(!result)}
            onNext={handleSubmit}
            onSubmit={(validity) => {
              const newValue = {...value, validity}
              setValue(newValue);
              setStep(STEPS.PARAGRAPH);
            }}
          />
        )}

        {step === STEPS.PARAGRAPH && (
          <CouponParagraph
            ref={stepRef}
            value={value.couponParagraph}
            onGetValues={getValues}
            onValidate={result => setNextDisabled(!result)}
            onSubmit={(couponParagraph) => {
              const newValue = {...value, couponParagraph}
              setValue(newValue);
              setStep(STEPS.INCLUDE_COUPON);
              handleComplete(newValue);
            }}
          />
        )}

        {value.includeCoupon && (
          <Navigation
            onBack={() => setStep((prev) => prev - 1)}
            onNext={handleSubmit}
            disableNext={nextDisabled}
            disableBack={step === STEPS.INCLUDE_COUPON}
            nextLabel={step === STEPS.PARAGRAPH ? 'Done' : 'Next'}
          />
        )}
      </div>
      {props.validationError && (
        <div className="form-control__error ui-caption-regular">
          {props.validationError.message}
        </div>
      )}
    </div>
  );
};

export default CouponEditor;
