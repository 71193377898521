import React, { useContext, useMemo } from "react";
import MainContent from "../../dashboard/MainContent";
import { CampaignContext } from "../../context/CampaignContext";
import { useParams } from "react-router-dom";
import TabNavBar from "../../ui-elements/tab-nav-bar/TabNavBar";
import { Outlet, useLocation } from "react-router-dom";
import WithValidSubScription from "../../with-valid-subscription/WithValidSubScription";
import CampaignStatus from "../../campaigns/overviews/CampaignStatus";

import './Tools.scss'

const CampaignPage = () => {
	const { pathname } = useLocation();
  const { toolSlug } = useParams();
	const { tools } =	useContext(CampaignContext);
  const included = tools.filter((tool) => tool.slug === toolSlug).length > 0;
  const { shopCampaigns } = useContext(CampaignContext);
  const pathArr = pathname.slice(1).split("/");
  const filteredShopCampaign = useMemo(() => shopCampaigns.filter((campaign) => campaign.slug === toolSlug), [shopCampaigns])

  const showFilter = filteredShopCampaign.length && pathArr.includes('consent-manager');

	const tabNavItems = () => {
		let navItems = [
      {
        label: "Overview",
        to: ``,
        active: pathArr.length === 2
      },
			{
				label: "Consent manager",
        to: `consent-manager`,
				active: pathArr.includes('consent-manager')
			},
			{
				label: "List reputation",
        to: `list-reputation`,
				active: pathArr.includes('list-reputation')
			}
		]

		return navItems;
	};

	return (
		<>
      <TabNavBar 
        items={tabNavItems()} 
        filter={showFilter ?
          <CampaignStatus
            enableLabel={false}
            status={filteredShopCampaign[0].status}
            shopCampaign={filteredShopCampaign[0]}
          />
          : null
        } 
      />
			<MainContent fullHeight={filteredShopCampaign.length === 0}>
        {included ? 
          <Outlet />
        : 
          <div>ToolsPage</div>
        }
			</MainContent>
		</>
	);
};

export default WithValidSubScription(CampaignPage);
