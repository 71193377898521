import React from 'react'
import { Tooltip } from "react-tooltip";
import { Info } from "akar-icons";
import styles from "./CustomizationElement.module.scss";

const CustomTooltip = ({tooltip}) => {
  const _id = Math.floor(Math.random() * (1000 - 0 + 1) + 0);
  return (
    <>
      <Info
        size="16"
        color="#d9d9d9"
        className={styles["info"]}
        data-tooltip-id={`tooltip_${_id}`}
        data-tooltip-html={`
          ${tooltip?.title ? `
            <div class="ui-extra-small-medium text-uppercase color-gray-gray-9">
              ${tooltip.title}
            </div>
          ` : ''}
          <div class="ui-small-regular${tooltip.title ? ' mt-8' : ''} color-secondary-colour-secondary-7">
            ${tooltip.description}
          </div>
        `}
      />
      <Tooltip
        id={`tooltip_${_id}`}
        className="an-tooltip an-tooltip_w_289"
        arrowColor="transparent"
      />
    </>
  );
}

export default CustomTooltip;