import { useEffect, useState, useMemo } from "react";
import TotalEmailsSent from "./TotalEmailsSent";
import CampaignInstances from './CampaignInstances';
import CampaignPerformance from "./CampaignPerformance";
import Timeline from "./Timeline";
import { getStats } from './tools';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { DateRangePicker, createStaticRanges } from "react-date-range";
import { addDays, startOfMonth, endOfMonth, addMonths } from "date-fns";

import { getCampaignTimeline, getTotalReviewsStats } from "../../api/Campaign";
import createChartTooltip from "../../ui-elements/charts/createCustomTooltip";
import SectionHead from '../../ui-elements/common/SectionHead';
import Legend from "./Legend";
import UiButton from "../../ui-elements/buttons/UiButton";
import Preloader from "../../preloader/Preloader";
import "./Overview.scss";
import EmptyData from "./EmptyData";
import { getDataSet } from './SurveyOverview/tools';

export const getStaticRanges = () => {
  const today = new Date();
  
  return createStaticRanges([
    {
      label: 'Last 7 days',
      range: () => ({
        startDate: addDays(today, -6),
        endDate: today
      })
    },
    {
      label: 'Last 30 days',
      range: () => ({
        startDate: addDays(today, -29),
        endDate: today
      })
    },
    {
      label: 'This month',
      range: () => ({
        startDate: startOfMonth(today),
        endDate: today
      })
    },
    {
      label: 'Last month',
      range: () => ({
        startDate: startOfMonth(addMonths(today, -1)),
        endDate: endOfMonth(addMonths(today, -1))
      })
    }
  ])
}

const ProductReviewOverview = ({ campaigns, slug }) => {
  ChartJS.register(ArcElement, Tooltip);

  const dateFormattingOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  const statObj = {
    totalRevenue: 0,
    totalOrders: 0,
    emailsSent: 0,
    couponsUsed: 0,
    opened: 0,
    clicked: 0,
    purchased: 0,
    unsubscribed: 0,
  }

  const [stats, setStats] = useState(statObj);
  const [timeline, setTimeline] = useState([]);
  const [totalReviews, setTotalReviews] = useState([]);
  const [filterLabel, setFilterLabel] = useState('Last 7 days');
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const today = new Date();
  const [performanceDateRange, setPerformanceDateRange] = useState([addDays(today, -6), today]);
  const staticRanges = getStaticRanges();
  const [performanceLoader, setPerformanceLoader] = useState(true);
  const colors = ['#52c41a', '#bae637', '#ffd666', '#ff9c6e', '#ff4d4f'];
  const labels = ['1 star', '2 stars', '3 stars', '4 stars', '5 stars'];

  const [state, setState] = useState([{
    startDate: addDays(today, -6),
    endDate: today,
    key: 'selection'
  }]);

  const formatNumber = number => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 0
    });
  }

  const updateData = item => {
    setState([item.selection]);
    setPerformanceDateRange([item.selection.startDate, item.selection.endDate]);
  }

  useEffect(() => {
    setStats(getStats(statObj, campaigns))
    fetchData();
  }, [campaigns]);

  const fetchData = async () => {
    setTimeline(await getCampaignTimeline(slug));
    setTotalReviews(await getTotalReviewsStats(performanceDateRange));
    setPerformanceLoader(false);
  }

  const reloadPerformance = async () => {
    setPerformanceLoader(true);
    setDatePickerVisible(false);
    setFilterLabel(`${performanceDateRange[0].toLocaleDateString('en-GB', dateFormattingOptions)} - ${performanceDateRange[1].toLocaleDateString('en-GB', dateFormattingOptions)}`);
    setTotalReviews(await getTotalReviewsStats(performanceDateRange));
    setPerformanceLoader(false);
  }

  const { datasets, totalValue } = useMemo(() => {
    return getDataSet(totalReviews || [], colors);
  }, [totalReviews]);

  return (
    <div className="an-layout">
      <div className="an-row">
        <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
          <CampaignPerformance
            totalEmailsSent={stats.emailsSent}
            purchased={stats.purchased}
            opened={stats.opened}
            clicked={stats.clicked}
            unsubscribed={stats.unsubscribed}
            disablePurchased={true}
          />
        </div>
        <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
          <TotalEmailsSent campaigns={campaigns} showChart={!!stats?.emailsSent} />
        </div>
      </div>
      <CampaignInstances campaigns={campaigns} slug={slug} />
      <div className="an-row">
        <div className="an-col an-col_50 card card_shadow card_overflow_visible card_br_24 card_24">
          <SectionHead
            title={
              <>
                <div className="email-templates-subtitle-2-medium">Total reviews</div>
                <div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">Latest activity</div>
              </>
            }
            filterClassName={'section-head__filter-without_shrink'}
            filter={
              <>
                <UiButton
                  size="small"
                  design="secondary"
                  onClick={() => setDatePickerVisible(true)}
                  text={filterLabel}
                />
                {datePickerVisible ? (
                  <div className="datepicker-wrapper card card_shadow card_br_24 card_24">
                    <DateRangePicker
                      onChange={item => updateData(item)}
                      showSelectionPreview={false}
                      moveRangeOnFirstSelection={false}
                      ranges={state}
                      direction="horizontal"
                      showMonthAndYearPickers={false}
                      maxDate={today}
                      showDateDisplay={false}
                      staticRanges={staticRanges}
                      inputRanges={[]}
                      weekdayDisplayFormat="EEEEEE"
                    />
                    <div className="datepicker-wrapper__bottom">
                      <div className="datepicker-wrapper__dates color-secondary-colour-secondary-7">{performanceDateRange[0].toLocaleDateString('en-GB', dateFormattingOptions)} - {performanceDateRange[1].toLocaleDateString('en-GB', dateFormattingOptions)}</div>
                      <div className="button-group">
                        <UiButton
                          text="Cancel"
                          design="secondary"
                          size="small"
                          onClick={() => setDatePickerVisible(false)}
                        />
                        <UiButton
                          text="Apply"
                          size="small"
                          onClick={reloadPerformance}
                        />
                      </div>
                    </div>
                  </div>
                ) : ('')}
              </>
            }
          />
          <div className="mt-24 an-row items-center">
            {performanceLoader ? (
              <Preloader
                absolute={true}
                overflow={true}
              />
            ) : (
              <>
                {totalReviews.length ? (
                  <>
                    <div className="an-col an-col_53 position-relative">
                      <Doughnut
                        data={{
                          labels,
                          datasets
                        }}
                        options={{
                          borderRadius: 6,
                          cutout: 50,
                          responive: true,
                          clip: 10,
                          plugins: {
                            tooltip: {
                              enabled: false,
                              callbacks: {
                                title: (tooltipItem) => tooltipItem[0]?.dataset.labels[0]
                              },
                              external: createChartTooltip
                            }
                          }
                        }}
                      />
                      <div className="overview-emails">
                        <div className="overview-emails__count">{formatNumber(totalValue)}</div>
                        <div className="overview-emails__label color-secondary-colour-secondary-7">Reviews</div>
                      </div>
                    </div>
                    <div className="an-col an-col_47 d-flex d-flex_column d-flex_gap_8">
                      {datasets.reverse().map((set, key) =>
                        <Legend
                          key={key}
                          label={set.labels[0]}
                          value={set.data[0]}
                          total={totalValue}
                          color={set.backgroundColor[0]}
                        />
                      )}
                    </div>
                  </>
                ) : <EmptyData />}
              </>
            )}
          </div>
        </div>
        {!!timeline.length && (
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
            <Timeline
              timeline={timeline}
              campaignName={campaigns[0].name}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductReviewOverview;