import React, { useId, useState } from "react";
import ReactTextareaAutoSize from "react-textarea-autosize";

const TextareaAutosize = (props) => {
  const _id = useId();
  const [limited, setLimited] = useState(false);

  return (
    <div
      className={`form-control${props.margin ? ` mt-${props.margin}` : ""}${
        props.fullWidth ? " w-100" : ""
      }`}
    >
      {props.label && (
        <label
          htmlFor={props.id || _id}
          className={`${
            props.labelClassName
              ? props.labelClassName
              : "email-templates-paragraph-1-medium"
          } form-control__label${
            props.labelMargin ? ` mb-${props.labelMargin}` : ""
          }`}
        >
          {props.label}
        </label>
      )}
      <ReactTextareaAutoSize
        id={props.id || _id}
        name={props.name}
        placeholder={props.placeholder}
        className={`form-control__input form-control__input_textarea ui-caption-regular${
          props.validationError ? " form-control__input_error" : ""
        }${props.inputClassName ? ` ${props.inputClassName}` : ""}`}
        required={props.required ? props.required : false}
        onKeyUp={(e) => {
          const {maxLength, validationError} = props
          if (!limited && maxLength && !validationError && e.target.value.length >= maxLength) {
            setLimited(true)
          }
        }}
        onChange={props.onChange}
        value={props.value ?? props.value}
        minRows={props.minRows}
        readOnly={props.readOnly ? "readonly" : false}
        disabled={props.disabled}
        onClick={props.onClick}
        maxLength={props.maxLength}
      />
      {limited && !props.validationError && (
        <div className="ui-small-regular color-secondary-colour-secondary-7" style={{position: 'absolute'}}>
          {`Only ${props.maxLength} characters are allowed`}
        </div>
      )}
      {props.validationError && (
        <div className="form-control__error ui-caption-regular">
          {props.validationError.message}
        </div>
      )}
    </div>
  );
};

export default TextareaAutosize;
