import React, { forwardRef, useImperativeHandle, useState, useEffect } from "react";
import FormControl from "../../forms/FormControl";
import { useFormik } from "formik";
import * as yup from "yup";
import UiButton from "../../buttons/UiButton";
import { generateCouponParagraphContent } from "../../../api/Gpt";

const CouponParagraph = forwardRef((props, ref) => {
  const initialValues = {
    couponParagraph: props.value
  }
  
  const [disabledInput, setDisabledInput] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      couponParagraph: yup.string().required("This is mandatory"),
    }),
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(false);
      props.onSubmit && props.onSubmit(values.couponParagraph);
    },
  });

  const runValidation = () => formik.validateForm().then(errors => props.onValidate(Object.keys(errors).length === 0));

  useEffect(() => {
    runValidation();
  }, [props.value]);

  useImperativeHandle(ref, () => ({
    submit() {
      formik.handleSubmit();
    }
  }), []);

  const generateCouponParagraph = async (e) => {
    e.preventDefault();

    setDisabledInput(true);

    const response = await generateCouponParagraphContent(props.onGetValues());
    
    if (response) {
      formik.setFieldValue('couponParagraph', response);
    }
    
    setDisabledInput(false);

    setTimeout(() => {
      runValidation();
    }, 100);
  }

  return (
    <div>
      <FormControl
        type="textarea"
        name="couponParagraph"
        id="couponParagraph"
        label="Add a paragraph for your coupon"
        value={formik.values.couponParagraph}
        required={true}
        placeholder="Ex. Because you are special, get this discount!"
        onChange={(e) => {
          formik.handleChange(e)
          
          runValidation();
        }}
        labelMargin="4"
        disabled={disabledInput}
        labelClassName="ui-caption-medium"
        validationError={
          formik.touched.couponParagraph && Boolean(formik.errors.couponParagraph)
            ? formik.errors.couponParagraph
            : false
        }
      />
      <div className="d-flex flex-right">
        <UiButton
          design="secondary"
          size="extra-small"
          disabled={disabledInput}
          className="text-uppercase mt-12"
          type="button"
          text="Generate Content"
          onClick={generateCouponParagraph}
        />
      </div>
    </div>
  );
});

CouponParagraph.displayName = "CouponParagraph";
export default CouponParagraph;
