import PackageInfo from "./PackageInfo";

const CurrentPackageData = props => {
  const formatDate = date => {
    const month = date.toLocaleString('default', {
      month: 'short'
    });

    return `${date.getDate().toString().padStart(2, '0')} ${month} ${date.getFullYear()}`;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: props.invoice.currency,
    minimumFractionDigits: 0
  });

  const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  });

  return (
    <div className="an-row an-row_gap_8">
      <PackageInfo
        value={props.package.name}
        label="Current package"
      />
      <PackageInfo
        value={`${numberFormatter.format(props.package.emailsPerMonth)} emails`}
        label="Quota per month"
      />
      <PackageInfo
        value={formatDate(new Date(props.invoice.period_end * 1000))}
        label="Next billing cycle"
      />
      <PackageInfo
        value={formatter.format(props.invoice.amount_due / 100)}
        label="Estimated charge"
      />
    </div>
  )
}

export default CurrentPackageData;