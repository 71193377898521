import React from 'react'
import { AddressAutofill } from "@mapbox/search-js-react";

const Address = (props) => {
  return (
    <div className={`form-control${props.margin ? ` mt-${props.margin}` : ""}`}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`${
            props.labelClassName
              ? props.labelClassName
              : "email-templates-paragraph-1-medium"
          } form-control__label${
            props.labelMargin ? ` mb-${props.labelMargin}` : ""
          }`}
        >
          {props.label}
        </label>
      )}
        <div className="form-control__inner">
          <AddressAutofill accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}>
            <input
              type="text"
              id={props.id}
              name={props.name}
              placeholder={props.placeholder}
              className={`form-control__input ui-caption-regular${
                props.validationError ? " form-control__input_error" : ""
              }${props.className ? ` ${props.className}` : ""}`}
              required={props.required ? props.required : false}
              onChange={props.onChange}
              defaultValue={props.defaultValue}
              onKeyDown={props.onKeyDown}
              value={props.value}
              minLength={props.minLength}
              readOnly={props.readOnly ? "readonly" : false}
              onClick={props.onClick}
              autoComplete="address-line1"
            />
          </AddressAutofill>
        </div>
        {props.validationError && (
          <div className="form-control__error ui-caption-regular">
            {props.validationError.message || props.validationError}
          </div>
        )}
      </div>
  );
}

export default Address;