import React from "react";
import classNames from "classnames";
import UiCaptionRegular from "../text/UiCaptionRegular";
import styles from "./LayoutSelector.module.scss";

const LayoutSelector = ({
  imageSrc,
  description,
  selected,
  className,
  onClick,
}) => {
  return (
    <div
      className={classNames(
        "card card_8 card_shadow card_border",
        styles["base"],
        {
          [`${styles["selected"]}`]: selected,
        },
        className
      )}
      onClick={onClick}
    >
      <div className="an-layout an-layout_gap_16">
        <img src={imageSrc} className="responsive-img" />
        <UiCaptionRegular text={description} />
      </div>
    </div>
  );
};

export default LayoutSelector;
