import React from 'react'

import GenerateNewSubscribersOverview from "../../campaigns/overviews/GenerateNewSubscribersOverview";
import EmailThisProductOverview from "../../campaigns/overviews/EmailThisProductOverview";
import CartAbandonmentOverview from "../../campaigns/overviews/CartAbandonmentOverview";
import SurveyOverview from "../../campaigns/overviews/SurveyOverview/SurveyOverview";
import CommonOverview from "../../campaigns/overviews/CommonOverview";
import ProductReviewOverview from "../../campaigns/overviews/ProductReviewOverview";
import RaffleGiveawayOverview from "../../campaigns/overviews/RaffleOverview/RaffleGiveawayOverview";

const CampaignOverviews = ({campaignSlug, shopCampaign, handleCreateMode}) => {
  if (campaignSlug === 'generate-new-subscribers') {
    return <GenerateNewSubscribersOverview campaigns={shopCampaign} slug={campaignSlug} />;
  } 
  if (campaignSlug === 'email-this-product') {
    return <EmailThisProductOverview campaigns={shopCampaign} slug={campaignSlug} />;
  } 
  if (campaignSlug === 'cart-abandonment') {
    return <CartAbandonmentOverview campaigns={shopCampaign} slug={campaignSlug} />;
  }

  if (campaignSlug == 'customer-survey') {
    return <SurveyOverview campaigns={shopCampaign} slug={campaignSlug} handleCreateMode={handleCreateMode} />
  }

  if (campaignSlug === 'product-review') {
    return <ProductReviewOverview campaigns={shopCampaign} slug={campaignSlug} />;
  }

  if (campaignSlug === 'raffle-giveaway') {
    return <RaffleGiveawayOverview campaigns={shopCampaign} slug={campaignSlug} handleCreateMode={handleCreateMode} />;
  }

  return <CommonOverview campaigns={shopCampaign} slug={campaignSlug} handleCreateMode={handleCreateMode} />;
}

export default CampaignOverviews;