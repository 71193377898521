import { useContext } from "react";
import { Cross } from "akar-icons";
import Score from "../dashboard/Score";
import "./Banner.scss";
import { AuthContext } from "../context/AuthContext";

const Banner = (props) => {
  const { shopScore: score } = useContext(AuthContext);

  return (
    <>
      {score ? (
        <div
          className={`banner${
            props.color ? ` banner_color_${props.color}` : ""
          }${props.className ? ` ${props.className}` : ""}`}
        >
          {props.closeButton ? (
            <button className="banner__close" onClick={props.onCloseClick}>
              <Cross size="16" color="#d9d9d9" />
            </button>
          ) : (
            ""
          )}
          {props.content}
          {props.image ? (
            <img src={props.image} alt="" className="banner__image" />
          ) : (
            ""
          )}
          {props.emoji ? (
            <div className="banner__emoji">{props.emoji}</div>
          ) : (
            ""
          )}
          {props.score ? <Score value={score} /> : ""}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Banner;
