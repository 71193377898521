import React from 'react'
import { useParams } from 'react-router-dom';

import SectionHead from '../../ui-elements/common/SectionHead';
import UiHeading from '../../ui-elements/text/UiHeading';
import UiButton from '../../ui-elements/buttons/UiButton';

import useCampaign from '../../hooks/useCampaign';

const CreateNewInstance = () => {
  const { campaignSlug } = useParams();
  const { campaigns } = useCampaign();
  const campaign = campaigns.find((currentCampaign) => currentCampaign.slug === campaignSlug);

  return (
    <>
    <SectionHead
      align="center"
      title={
        <>
          <div className="email-templates-subtitle-2-bold">{campaign.name}</div>
          <div className="mt-4 email-templates-caption-regular color-secondary-colour-secondary-4">{campaign.dashboardDescription}</div>
        </>
      }
    />
    <div className='card card_br_24 card_24 card_shadow card_align_center card_h_100 card_flex text-center'>
      <div className="card__overflow">
        <UiHeading
          desktop="email-templates-subtitle-1-semibold"
          text={'How would you like to create your campaign?'}
          element="h2"
        />
        <div className="email-templates-paragraph-1-regular mt-8 color-secondary-colour-secondary-7">
          Choose AI-generated to auto-fill all email content—editable anytime. Or start manually, <br /> 
          with AI help available upon request. Customise as you see fit!
        </div>
        <div className="mt-40 pt-101 pb-101 button-group button-group_center button-group_gap_16">
          <UiButton
            text="Generate manually"
            design="secondary"
            className="ui-button_mw_210"
            elem="a"
            href="../generate"
          />
          <UiButton
            text="Generate with AI"
            className="ui-button_mw_210"
            elem="a"
            href="../generate?mode=ai"
          />
        </div>
      </div>
    </div>
    </>
  );
}

export default CreateNewInstance;