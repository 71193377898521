import React, { useContext, useState } from "react";
import MainContent from "../../dashboard/MainContent";
import MainAssets from "../../brand-assets/MainAssets";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import { AuthContext } from "../../context/AuthContext";
import { saveMainAssets, saveSignature } from "../../api/Shop";
import { Notify } from "../../ui-elements/notify/notify";
import SignatureForm from "../../brand-assets/SignatureForm";

const BrandIdentifyPage = () => {
  const { shopData, updateShop } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [signatureLoading, setSignatureLoading] = useState(false);

  const handleMainAssetsSave = async (data) => {
    if (loading) return;
    setLoading(true);
    try {
      await saveMainAssets(data);
      await updateShop();
      Notify.success({ title: "Main assets updated successfully" });
    } catch (err) {
      console.log("err", err);
    }
    setLoading(false);
  };

  const handleSignatureSave = async (value) => {
    if (signatureLoading) return;
    setSignatureLoading(true);
    try {
      await saveSignature(value);
      await updateShop();
      Notify.success({ title: "Signature updated successfully" });
    } catch (err) {
      console.log("err", err);
    }
    setSignatureLoading(false);
  };

  return (
    <MainContent>
      <div className="an-layout">
        <SectionHead
          title={
            <>
              <UiHeading
                text="Main assets"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                Customise your brand identity by uploading your light and dark
                logos, specifying your brand name, and selecting your brand
                color.
              </div>
            </>
          }
        />

        <MainAssets
          loading={loading}
          mainAssets={shopData?.mainAssets}
          onSave={handleMainAssetsSave}
        />

        <SectionHead
          title={
            <>
              <UiHeading
                text="Brand signature"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                This signature will be included at the end of your emails when
                applicable.
              </div>
            </>
          }
        />

        <SignatureForm
          loading={signatureLoading}
          signature={shopData.signature}
          onSave={handleSignatureSave}
        />
      </div>
    </MainContent>
  );
};

export default BrandIdentifyPage;
