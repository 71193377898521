import React, { useState, useCallback, useReducer, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { DndContext, DragOverlay } from "@dnd-kit/core";

import useStep from "../../hooks/useStep";
import useCampaign from '../../hooks/useCampaign';

import StepWizardNavigation from "../../ui-elements/step-wizard/StepWizardNavigation";
import CustomizationOptionsContainer from "../../ui-elements/customization/customiztion-options-container/CustomizationOptionsContainer";
import SurveyWorkspace from "../../ui-elements/customization/survey-workspace/SurveyWorkspace";
import CustomizationElement from "../../ui-elements/customization-element/CustomizationElement";
import QuestionBlock from "../../ui-elements/customization/question-block/QuestionBlock";
import Preloader from '../../preloader/Preloader';

import { getCampaignCustomizer } from '../../api/Gpt';

const blockReducer = (state, action) => {
  const block = state.find((block, index) => index === action.payload.index);
  switch (action.type) {
    case "SET_BLOCK":
      const _answers = action.payload.value.answers;
      const question = action.payload.value.question;

      return [
        ...state.slice(0, action.payload.index),
        {
          ...block,
          question,
          ...(_answers && {
            answers: _answers.map((answer) => ({
              _id: uuidv4(),
              value: answer,
            })),
          }),
        },
        ...state.slice(action.payload.index + 1),
      ];
    case "ADD_BLOCK":
      let answers = [{ _id: uuidv4(), value: "" }];

      if (action.payload.type === "textarea") {
        answers = null;
      }

      if (action.payload.type === "rating") {
        answers = [...Array(5)].map((el) => ({ _id: uuidv4(), value: "" }));
      }

      return [
        ...state,
        {
          _id: action.payload.id,
          type: action.payload.type,
          question: "",
          ...(answers && { answers }),
        },
      ];
    case "REMOVE_BLOCK":
      return state.filter((block) => block._id !== action.payload.id);
    case "UPDATE_BLOCK_MANDATORY":
      return [
        ...state.slice(0, action.payload.index),
        { ...block, mandatory: action.payload.checked },
        ...state.slice(action.payload.index + 1),
      ];
    case "UPDATE_QUESTION":
      return [
        ...state.slice(0, action.payload.index),
        { ...block, question: action.payload.value },
        ...state.slice(action.payload.index + 1),
      ];
    case "ADD_ANSWER":
      return [
        ...state.slice(0, action.payload.index),
        {
          ...block,
          answers: [
            ...block.answers.slice(0, action.payload.answerIndex + 1),
            { _id: uuidv4(), value: "" },
            ...block.answers.slice(action.payload.answerIndex + 1),
          ],
        },
        ...state.slice(action.payload.index + 1),
      ];
    case "UPDATE_ANSWER":
      return [
        ...state.slice(0, action.payload.index),
        {
          ...block,
          answers: block.answers.map((answer, index) =>
            index === action.payload.answerIndex
              ? { ...answer, value: action.payload.value }
              : answer
          ),
        },
        ...state.slice(action.payload.index + 1),
      ];
    case "REMOVE_ANSWER":
      return [
        ...state.slice(0, action.payload.index),
        {
          ...block,
          answers: block.answers.filter(
            (answer, index) => index !== action.payload.answerIndex
          ),
        },
        ...state.slice(action.payload.index + 1),
      ];
    case "MOVE_BLOCK":
      return arrayMove(state, action.payload.oldIndex, action.payload.newIndex);
    default:
      return state;
  }
};

const SurveyBuilder = () => {
  const {
    currentStep,
    currentStepValue = [],
    handlePrev,
    handleNext,
    handleAINext,
  } = useStep();
  
  const { campaignSlug } = useParams();
  const [searchParams] = useSearchParams()
  const { campaigns } = useCampaign();
  const campaign = campaigns.find(
    (currentCampaign) => currentCampaign.slug === campaignSlug
  );
  const [editingBlock, setEditingBlock] = useState(null);
  const [blocks, dispatchBlocks] = useReducer(blockReducer, currentStepValue);
  const [draggingItem, setDraggingItem] = useState(null);
  const [loading, setLoading] = useState(false)

  const valid = useMemo(() => {
    if (blocks.length === 0) return false;

    for (const block of blocks) {
      if (!block.question) return false;

      if (block.answers) {
        if (block.answers.length === 0 && block.type !== "textarea")
          return false;

        for (const answer of block.answers) {
          if (!answer.value) return false;
        }
      }
    }

    return true;
  }, [blocks]);

  const handleSubmit = async(e) => {
    e.preventDefault();
    const mode = searchParams.get("mode");
    if (mode == "ai") {
      setLoading(true);
      
      const response = await getCampaignCustomizer(campaign._id);
      handleAINext({builder: blocks, customizer: response})
      
      setLoading(false);
      return
    } 
    handleNext(blocks);
  };

  const handleLeftElementClick = (type) => {
    const id = uuidv4();
    setEditingBlock({ _id: id, type });
    dispatchBlocks({ type: "ADD_BLOCK", payload: { id, type } });
  };

  const handleOnEdit = useCallback((id, type) => {
    setEditingBlock({ _id: id, type });
  }, []);

  const handleAfterGenerate = useCallback((index, values) => {
    dispatchBlocks({
      type: "SET_BLOCK",
      payload: { index, value: values },
    });
  }, []);

  const handleOnMoveUp = useCallback((index) => {
    dispatchBlocks({
      type: "MOVE_BLOCK",
      payload: { oldIndex: index - 1, newIndex: index },
    });
  }, []);

  const handleOnMoveDown = useCallback((index) => {
    dispatchBlocks({
      type: "MOVE_BLOCK",
      payload: { oldIndex: index, newIndex: index + 1 },
    });
  }, []);

  const handleRemove = useCallback(
    (id) => {
      dispatchBlocks({ type: "REMOVE_BLOCK", payload: { id } });

      if (editingBlock?._id === id) {
        setEditingBlock(null);
      }
    },
    [editingBlock]
  );

  const handleMandatoryChange = useCallback((index, checked) => {
    dispatchBlocks({
      type: "UPDATE_BLOCK_MANDATORY",
      payload: { index, checked },
    });
  }, []);

  const handleQuestionChange = useCallback((index, value) => {
    dispatchBlocks({ type: "UPDATE_QUESTION", payload: { index, value } });
  }, []);

  const handleAnswerChange = useCallback((index, answerIndex, value) => {
    dispatchBlocks({
      type: "UPDATE_ANSWER",
      payload: { index, answerIndex, value },
    });
  }, []);

  const handleAnswerAdd = useCallback((index, answerIndex) => {
    dispatchBlocks({
      type: "ADD_ANSWER",
      payload: { index, answerIndex },
    });
  }, []);

  const handleAnswerRemove = useCallback((index, answerIndex) => {
    dispatchBlocks({
      type: "REMOVE_ANSWER",
      payload: { index, answerIndex },
    });
  }, []);

  const handleDragStart = (event) => {
    setDraggingItem(blocks.find((block) => block._id === event.active.id));
    if (event.active.id !== editingBlock?._id) {
      setEditingBlock(null);
    }
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    if (!active || !over || !blocks) return;

    const oldIndex = blocks.findIndex((block) => block._id === active.id);
    const newIndex = blocks.findIndex((block) => block._id === over.id);

    dispatchBlocks({
      type: "MOVE_BLOCK",
      payload: { oldIndex, newIndex },
    });

    setDraggingItem(null);
    setEditingBlock({ _id: draggingItem._id, type: draggingItem.type });
  };

  return (
    <form className="an-row w-100 h-100" onSubmit={handleSubmit}>
      {loading && <Preloader absolute={true} overflow={true} />}
      <CustomizationOptionsContainer className="an-col_3_12">
        {currentStep.sections.map((section) => (
          <>
            {!section?.disabled && (
              <CustomizationElement
                {...section}
                key={`question-type-${section.name}`}
                hideArrowIcon
                open={editingBlock?.type === section.name}
                onClick={() => handleLeftElementClick(section.name)}
                disabled={blocks.length === 5}
              />
            )}
          </>
        ))}
      </CustomizationOptionsContainer>

      <SurveyWorkspace
        showPlaceholder={!blocks.length}
        key={`customization-container`}
        className="an-col_7_12"
      >
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <SortableContext
            items={blocks.map((block) => ({ ...block, id: block._id }))}
          >
            {blocks.map((block, index) => (
              <QuestionBlock
                {...block}
                id={block._id}
                key={`question-block-${block._id ?? index}`}
                index={index}
                onQuestionChange={handleQuestionChange}
                upDisabled={index === 0}
                downDisabled={index === blocks.length - 1}
                isEditing={editingBlock?._id === block._id}
                dragging={draggingItem?._id === block._id}
                onRemove={handleRemove}
                onEdit={handleOnEdit}
                onAfterGenerate={handleAfterGenerate}
                onMoveUp={handleOnMoveUp}
                onMoveDown={handleOnMoveDown}
                onAnswerChange={handleAnswerChange}
                onAnswerAdd={handleAnswerAdd}
                onAnswerRemove={handleAnswerRemove}
                onMandatoryChange={handleMandatoryChange}
              />
            ))}
          </SortableContext>
          <DragOverlay>
            {draggingItem ? (
              <QuestionBlock dragging isEditing {...draggingItem} />
            ) : null}
          </DragOverlay>
        </DndContext>
      </SurveyWorkspace>

      <StepWizardNavigation
        onPrev={() => handlePrev(blocks)}
        nextDisabled={!valid}
      />
    </form>
  );
};

export default SurveyBuilder;
