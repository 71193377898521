import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom';
import { NavLink } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { MoreVerticalFill } from "akar-icons";

import './FixedDropdown.scss';

const FixedDropdown = ({ className, items, icon, disabled, selector }) => {
	const [show, setShow] = useState(false)
  const [rect, setRect] = useState(null)
  
  const handleScroll = () => setShow(false);
  const handleOpen = (e) => {
    if (disabled) return;
    const rect = e.target.getBoundingClientRect();
    
    //248 - width dropdown, 40 - approx height, 48 - approx item height
    const yDropdown = rect.bottom + 40
    const approxDropdownHeight = items.length * 48
    const left = rect.left - 248

    if ((yDropdown + approxDropdownHeight) < window.innerHeight) setRect({ top: rect.top + 40, left });
    else setRect({ bottom: window.innerHeight - rect.top + 15, left });
    
    setShow(true)
  }
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return window.removeEventListener('scroll', handleScroll)
  }, [])

	return (
		<div style={{
      width: '16px',
      height: '16px'
    }}>
			<button
        onClick={handleOpen}
				className="campaign-instances-table__actions"
			>
        {icon ?? <MoreVerticalFill size="16" />}
			</button>
			{show && 
				createPortal(
          <div 
            className={`fixed-dropdown fixed-dropdown_${className}`} 
            style={{
              left: rect?.left + (selector ? 225 : 0), 
              top: rect?.top ? rect?.top - (selector ? 18 : 0) : 'none', 
              bottom: rect?.bottom
            }}
          >
            <OutsideClickHandler onOutsideClick={() => setShow(false)}>
              {items.map(({onClick, disabled=false, Icon, iconText, to, type='button'}, index) => {
                if (type == 'navlink') {
                  return (
                    <NavLink
                      to={disabled ? '#' : to}
                      className="fixed-dropdown__item fixed-dropdown__item-nav"
                      disabled={disabled}
                      key={index}
                    >
                      {Icon && 
                        <span className="fixed-dropdown__item-icon">
                          {Icon}
                        </span>
                      }
                      <span className="ui-caption-regular">{iconText}</span>
                    </NavLink>
                  )
                }
                
                return(
                  <button
                    className="fixed-dropdown__item"
                    onClick={() => {
                      setShow(false)
                      onClick && onClick()
                    }}
                    key={index}
                    disabled={disabled}
                  >
                    {Icon && 
                      <span className="fixed-dropdown__item-icon">
                        {Icon}
                      </span>
                    }
                    <span className="ui-caption-regular">{iconText}</span>
                  </button>
                )
              })}
            </OutsideClickHandler>
          </div>,
					document.body
				)
			}
		</div>	
	)
}

export default FixedDropdown