import { useMemo, useContext } from 'react';
import { Table, Header, HeaderRow, Body, Row, Cell } from "@table-library/react-table-library/table";
import { useSort, HeaderCellSort } from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { Link } from 'react-router-dom';
import SectionHead from "../ui-elements/common/SectionHead";
import AnTable from "../ui-elements/common/AnTable";
import CampaignIcon from "../ui-elements/common/CampaignIcon";
import AkarIconsChevronVertical from '../static/images/common/akar-icons_chevron-vertical.svg';
import AkarIconsChevronVerticalCopy from '../static/images/common/akar-icons_chevron-vertical copy.svg';
import "./CampaignPerformance.scss";
import { CampaignContext } from '../context/CampaignContext';

const CampaignPerformance = props => {
  const {shopCampaigns} = useContext(CampaignContext)
  const nodes = useMemo(() => {
    return shopCampaigns
      .filter(campaign => campaign.slug !== 'subscribers-management')
      .map(campaign => {
        return {
          ...campaign,
          createdAt: new Date(campaign.createdAt)
        };
      });
  }, [shopCampaigns]);

  const theme = useTheme([getTheme(), {
    Table: `
      --data-table-library_grid-template-columns: repeat(2, minmax(0, 1fr)) 120px 120px 120px 120px
    `
  }])

  const sort = useSort({ nodes }, {}, {
    sortIcon: {
      margin: '0px',
      iconDefault: <img src={AkarIconsChevronVertical} alt="" />,
      iconUp: <img src={AkarIconsChevronVerticalCopy} alt="" />,
      iconDown: <img src={AkarIconsChevronVerticalCopy} alt="" />
    },
    sortFns: {
      CAMPAIGNNAME: array => array.sort((a, b) => a.name.localeCompare(b.name)),
      CREATED: array => array.sort((a, b) => a.createdAt - b.createdAt),
      EMAILSSENT: array => array.sort((a, b) => a.emailsSent - b.emailsSent),
      OPEN: array => array.sort((a, b) => a.open - b.open),
      CLICKED: array => array.sort((a, b) => a.clicked - b.clicked),
      UNSUBSCRIBED: array => array.sort((a, b) => a.unsubscribed - b.unsubscribed)
    }
  });

  return (
    <>
      {nodes.length ? (
        <div className={`card card_24 card_br_24 card_shadow card_overflow_visible${props.performanceDisabled ? ' blured' : ''}`}>
          <SectionHead
            title={
              <>
                <h5 className="email-templates-subtitle-2-medium">Campaign performance</h5>
                <div className="mt-4 email-templates-caption-regular color-secondary-colour-secondary-4">Overview with the performance of all your active campaigns.</div>
              </>
            }
          />
          <div className="mt-24 campaign-performance-table">
            <AnTable
              body={
                <Table
                  data={{ nodes }}
                  sort={sort}
                  theme={theme}
                  layout={{
                    custom: true
                  }}
                >
                  {(tableList) => (
                    <>
                      <Header>
                        <HeaderRow>
                          <HeaderCellSort sortKey="CAMPAIGNNAME">Campaign Name</HeaderCellSort>
                          <HeaderCellSort sortKey="CREAETED">Created</HeaderCellSort>
                          <HeaderCellSort sortKey="EMAILSSENT">Emails Sent</HeaderCellSort>
                          <HeaderCellSort sortKey="OPEN">Open</HeaderCellSort>
                          <HeaderCellSort sortKey="CLICKED">Clicked</HeaderCellSort>
                          <HeaderCellSort sortKey="UNSUBSCRIBED">Unsubscribed</HeaderCellSort>
                        </HeaderRow>
                      </Header>
                      <Body>
                        {tableList.map(item => (
                          <Row
                            item={item}
                            key={item._id}
                          >
                            <Cell>
                              <Link
                                to={`/campaigns/${item.slug}`}
                                className="campaign-performance-link"
                              >
                                <CampaignIcon emoji={item.emoji} />
                                <span className="campaign-performance-link__name">{item.shopCampaignName}</span>
                              </Link>
                            </Cell>
                            <Cell>
                              {item.createdAt.toLocaleDateString('en-US', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric'
                              })}
                            </Cell>
                            <Cell>{item.emailsSent}</Cell>
                            <Cell>{`${item.opened}${item.emailsSent ? `/${(item.opened / item.emailsSent * 100).toFixed(1)}%` : ''}`}</Cell>
                            <Cell>{`${item.clicked}${item.emailsSent ? `/${(item.clicked / item.emailsSent * 100).toFixed(1)}%` : ''}`}</Cell>
                            <Cell>{`${item.unsubscribed}${item.emailsSent ? `/${(item.unsubscribed / item.emailsSent * 100).toFixed(1)}%` : ''}`}</Cell>
                          </Row>
                        ))}
                      </Body>
                    </>
                  )}
                </Table>
              }
            />
          </div>
        </div>
      ) : ('')}
    </>
  )
}

export default CampaignPerformance;