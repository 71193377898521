import React, { useState, useRef, useEffect } from 'react'
import { DateRangePicker } from 'react-date-range';
import UiButton from "../../ui-elements/buttons/UiButton";

const WrapperDatePicker = ({
  filterLabel,
  handleChange,
  ranges,
  today,
  staticRanges,
  secondaryText,
  reloadPerformance
}) => {
  const [show, setShow] = useState(false)
  const coords = useRef({})
  const ref = useRef(null)
  
  const handleScroll = () => {
    if (show) setShow(false)
  }

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget) && show) {
      setShow(false)
    }
  }
  
  useEffect(() => {
    const mainElement = document.getElementById('main_content') 
    if (mainElement) {
      mainElement.addEventListener('scroll', handleScroll)
      return () => mainElement.removeEventListener('scroll', handleScroll)
    }
  })
  
  const handleApply = () => {
    setShow(false);
    reloadPerformance()
  }
  
  const handleOpen = (e) => {
    const rect = e.currentTarget.getBoundingClientRect()
    coords.current['top'] = rect.bottom + 4 // custom top padding
    coords.current['right'] = document.body.clientWidth - rect.right //48 - card padding 24px
    setShow(true)
  }
	
	return (
		<div 
      className='datepicker-container' 
      ref={ref} 
      tabIndex={0} 
      onBlur={handleBlur}
    >
			<UiButton
				size="small"
				design="secondary"
				className="ui-button_icon"
				text={filterLabel}
        onClick={handleOpen}
			/>
      {show && 
				<div 
          className="datepicker-wrapper fixed-position card card_shadow card_br_24 card_24"
          style={{
            position: 'fixed !important',
            top: coords.current.top || 0,
            right: coords.current.right || 0
          }}
        >
					<DateRangePicker
            onChange={handleChange}
						showSelectionPreview={false}
						moveRangeOnFirstSelection={false}
						months={2}
            ranges={ranges}
						direction="horizontal"
						showMonthAndYearPickers={false}
						maxDate={today}
						showDateDisplay={false}
						staticRanges={staticRanges}
						inputRanges={[]}
						weekdayDisplayFormat="EEEEEE"
					/>
					<div className="datepicker-wrapper__bottom">
						<div className="datepicker-wrapper__dates color-secondary-colour-secondary-7">
              {secondaryText}
            </div>
						<div className="button-group">
							<UiButton
								text="Cancel"
								design="secondary"
								size="small"
                onClick={() => setShow(false)}
							/>
							<UiButton
								text="Apply"
								size="small"
                onClick={handleApply}
							/>
						</div>
					</div>
				</div>
		  }
		</div>
	)
}

export default WrapperDatePicker