import { useState } from 'react'

import Legend from '../../../campaigns/overviews/Legend'
import ToolsPerformance from '../ToolsPerformance'

import Toogler from '../../../ui-elements/toogler/Toogler'
import CampaignIcon from '../../../ui-elements/common/CampaignIcon'
import UiCaptionRegular from '../../../ui-elements/text/UiCaptionRegular'
import UiHeading from '../../../ui-elements/text/UiHeading'
import Divider from '../../../ui-elements/divider/Divider'
import UiCaptionMedium from '../../../ui-elements/text/UiCaptionMedium'

const colors = ['#9254de', '#4bd4fb', '#ff7875'];

const ConsentPerformance = ({ data }) => {
	const [type, setType] = useState('day')

	return (
		<ToolsPerformance 
			title="Consent performance"
			subTitle="Consent performance for your selected dates."
			data={data[type] ?? null}
			type={type}
			yAxis={{
        min: 0
      }}
			filter={
        <Toogler
          items={[
            { value: 'day', text: 'Day' },
            { value: 'week', text: 'Week' },
            { value: 'month', text: 'Month' },
          ]}
          defaultStatus={type}
          onChange={(value) => setType(value)}
        />
      }
			Legend={() => {
				return (
					<>
						<div style={{display: 'flex', flexDirection: 'column', gap: '24px'}}>
							<div className='icon-wrapper'>
								<div>
									<CampaignIcon emoji={'🥳'} />
								</div>
								<div>
									<UiCaptionRegular
										className=""
										text="Converted for this period"
									/>
									<UiHeading
										element="h6"
										desktop="other-headings-h6-bold"
										text={data?.converted ?? 0}
									/>
								</div>
							</div>
							<div className='icon-wrapper'>
								<div>
									<CampaignIcon emoji={'🫥'} />
								</div>
								<div>
									<UiCaptionRegular
										className=""
										text="Denied for this period"
									/>
									<UiHeading
										element="h6"
										desktop="other-headings-h6-bold"
										text={data?.denied ?? 0}
									/>
								</div>
							</div>
						</div>
            {!!data?.legends && (
              <>
                <Divider className="mt-16 mb-16" />
                <div className='legends'>
                  <UiCaptionMedium text="Overall performance" />
                  {data.legends.map((stat, index) =>
                    <Legend
                      key={index}
                      label={stat.label}
                      value={stat.value}
                      total={100}
                      color={colors[index]}
                      showTotal={false}
                    />
                  )}
                </div>
              </>
            )}
					</>
				)
			}}
		/>
	)
}

export default ConsentPerformance