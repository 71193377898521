import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import CompleteButton from "./CompleteButton";
import "./CompleteTask.scss";

const CompleteTask = ({task, active, switchTask}) => {
  let renderedName = task.name;
  const iconColor = task.completed ? '#73d13d' : '#c0bac5';

  if (task.completed) {
    renderedName += ' completed';
  } else if (task.type === 'onboarded') {
    renderedName += ' (skipped)';
  }

  return (
    <li className={`complete-task${task.completed ? ' complete-task_completed' : ''}${active ? ' complete-task_active' : ''}`} onClick={() => switchTask(task)}>
      <svg
        width="43"
        height="42"
        viewBox="0 0 43 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.334 21.875L19.709 27.125L28.6673 16.625"
          stroke={iconColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.5007 38.5C31.3958 38.5 39.4173 30.665 39.4173 21C39.4173 11.335 31.3958 3.5 21.5007 3.5C11.6055 3.5 3.58398 11.335 3.58398 21C3.58398 30.665 11.6055 38.5 21.5007 38.5Z"
          stroke={iconColor}
          strokeWidth="2"
        />
      </svg>
      <UiCaptionRegular
        text={renderedName}
        className="color-secondary-colour-secondary-8"
      />
      {!task?.completed && <CompleteButton activeTask={task.type} />}
    </li>
  );
}

export default CompleteTask;