import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import validator from "validator";
import ModalLayout from "../../modal/Layout";
import UiHeading from "../../ui-elements/text/UiHeading";
import UiCaptionRegular from "../../ui-elements/text/UiCaptionRegular";
import UiCaptionMedium from "../../ui-elements/text/UiCaptionMedium";
import FormControl from "../../ui-elements/forms/FormControl";
import Preloader from "../../preloader/Preloader";
import UiButton from "../../ui-elements/buttons/UiButton";

const DomainAccountsEmailEdit = () => {
  const { id } = useParams();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [initialEmail, setInitialEmail] = useState('');
  const [verifyDisabled, setVerifyDisabled] = useState(true);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [domain, setDomain] = useState('');

  useEffect(() => {
    axios.get('/domains', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      if (response.data.length === 0) {
        setLoading(false);
        navigate('/store-settings/domain-accounts');

        return;
      }

      setDomain(response.data[0].name);

      axios.get(`/domains/emails/${id}`, {
        headers: {
          Authorization: `Bearer: ${token}`
        }
      }).then(response => {
        setEmail(response.data.email);
        setInitialEmail(response.data.email);
        setLoading(false);
      }).catch(err => {
        console.error(err);

        setLoading(false);
      })
    }).catch(err => {
      console.error(err);
      
      setLoading(false);
    })
  }, []);

  useEffect(() => {
    setVerifyDisabled(!validator.isEmail(email) || (email === initialEmail));
  }, [email]);

  const verify = () => {
    setLoading(true);
    
    if (email.includes(`@${domain}`)) {
      axios.put(`/domains/emails/${id}`, {
        email: email
      }, {
        headers: {
          Authorization: `Bearer: ${token}`
        }
      }).then(response => {
        navigate(`/store-settings/domain-accounts/email/${response.data}/verify`);
        setLoading(false);
      }).catch(err => {
        setErrors(err.response.data.errors);
        setLoading(false);
      })
    } else {
      setErrors({
        email: {
          name: 'validationError',
          message: 'This email address is not associated with your domain. Please provide another email address.'
        }
      });
    }
  }
  
  return (
    <ModalLayout backTo="/store-settings/domain-accounts">
      <div>
        <div className="onboarding-question text-center mt-40">
          <UiHeading
            text="Edit email"
            desktop="other-headings-h6-bold"
          />
          <UiCaptionRegular
            text={<>Please provide us an email address associated with your domain. <br />We will send an email with a 6 digit code to verify your domain. </>}
            margin="8"
            className="color-secondary-colour-secondary-7"
          />
          <div className="mt-64 maxw maxw_431 text-left">
            <UiCaptionMedium text="Email address" />
            <FormControl
              type="email"
              name="email"
              placeholder="Ex. mydomain@domainname.com"
              id="email"
              onChange={e => setEmail(e.target.value)}
              value={email}
              margin="4"
              validationError={errors.email}
              validationWithIcon={true}
            />
          </div>
        </div>
        <div className="onboarding-questionnaire__bottom">
          <UiButton
            size="medium"
            elem="a"
            href="/store-settings/domain-accounts"
            text="Back"
            design="secondary"
            className="ui-button_mw_150"
          />
          <UiButton
            onClick={verify}
            size="medium"
            text="Verify"
            design="secondary"
            className="ui-button_mw_150"
            disabled={verifyDisabled}
          />
        </div>
      </div>
      {loading ? (
        <Preloader
          absolute={true}
          overflow={true}
        />
      ) : ''}
    </ModalLayout>
  )
}

export default DomainAccountsEmailEdit;