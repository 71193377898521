import React, { useLayoutEffect, useState, useCallback, useRef } from "react";
import useStep from "../hooks/useStep";
import Preloader from "../preloader/Preloader";
import StepWizardNavigation from "../ui-elements/step-wizard/StepWizardNavigation";
import ProductsFilter from "./ProductsFilter";
import CustomizationOptionsContainer from "../ui-elements/customization/customiztion-options-container/CustomizationOptionsContainer";
import CustomizationContainer from "../ui-elements/customization/customization-container/CustomizationContainer";
import ProductsList from "./ProductsList";
import BlogPostsList from "./BlogPostsList";
import BlogPostsFitler from "./BlogPostsFilter";
import { getShopProducts, getBlogPosts } from "../api/Shop";

const filterAvailableWidgets = ["products", "blog-post"];

const FilterStep = () => {
  const {
    stepData,
    handleSkip,
    handlePrev,
    handleNext,
    currentStepValue,
    setStepTitle,
    currentStep,
  } = useStep();
  const widgetType = stepData.widget.slug;
  const [loading, setLoading] = useState(true);
  const [loadingItems, setLoadingItems] = useState(false);
  const [values, setValues] = useState(currentStepValue);
  const [itemsData, setItemsData] = useState([]);
  const [totalItems, setTotalItems] = useState(null);
  const debounceTimeout = useRef(null);

  useLayoutEffect(() => {
    if (!filterAvailableWidgets.includes(widgetType)) {
      handleSkip();
    } else {
      const stepTitle = currentStep.title.replace(
        `{widgetName}`,
        stepData.widget.name.toLowerCase()
      );
      setStepTitle(stepTitle);
    }
    setLoading(false);
  }, [widgetType, stepData, currentStep]);

  const handleSubmit = (e) => {
    e.preventDefault();

    handleNext(values);
  };

  const handleChange = useCallback((values) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      setValues(values);
      fetchItems(widgetType, values);
    }, 1000);
  }, []);

  const fetchItems = async (widgetType, filter) => {
    if (loadingItems) {
      return;
    }

    setLoadingItems(true);

    try {
      const response = widgetType === 'products' ? await getShopProducts(filter) : await getBlogPosts(filter);

      if (allInnerArraysEmpty(filter)) {
        setTotalItems(response.total);
      }

      setItemsData(response);
    } catch (err) {
      console.error(err);
    }

    setLoadingItems(false);
  }

  const allInnerArraysEmpty = obj => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && Array.isArray(obj[key]) && obj[key].length > 0) {
        return false;
      }
    }

    return true;
  }

  if (loading) {
    return <Preloader />;
  }

  return (
    <form
      onSubmit={handleSubmit}
      className="an-row w-100 h-100"
    >
      <CustomizationOptionsContainer className="an-col_3_12">
        {widgetType === 'products' ? (
          <ProductsFilter
            onChange={handleChange}
            values={values}
          />
        ) : (
          <BlogPostsFitler
            onChange={handleChange}
            values={values}
          />
        )}
      </CustomizationOptionsContainer>
      <CustomizationContainer
        key={`customization-container`}
        className="an-col_7_12"
        loading={loading}
        noPreview={true}
      >
        {widgetType === 'products' ? (
          <ProductsList
            itemsData={itemsData}
            loading={loadingItems}
            total={totalItems}
            values={values}
          />
        ) : (
          <BlogPostsList
            itemsData={itemsData}
            loading={loadingItems}
            total={totalItems}
          />
        )}
      </CustomizationContainer>
      <StepWizardNavigation
        onPrev={handlePrev}
        nextDisabled={!itemsData?.total}
      />
    </form>
  );
};

export default FilterStep;
