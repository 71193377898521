import { useState, useEffect } from "react";
import { getBlogPostTags, getShopProductTags } from "../../api/Shop";
import Preloader from "../../preloader/Preloader";
import BasePill from "../../ui-elements/buttons/BasePill";
import './Filters.scss';

const TagFilter = ({ onChange, filters, type }) => {
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [notFoundMessage, setNotFoundMessage] = useState('');
  
  useEffect(() => {
    fetchData();
    setLoading(false);
  }, []);

  const fetchData = async () => {
    const _tags = type === 'post' ? await getBlogPostTags() : await getShopProductTags();

    setTags(_tags);

    if (_tags.length === 0) {
      setNotFoundMessage('No tags found.');
    }
  }

  const toggleFilter = (e, tag) => {
    e.preventDefault();

    const index = filters.findIndex(item => item._id === tag._id);
    let updatedFilters = [];

    if (index) {
      updatedFilters = [...filters, tag];
    } else {
      updatedFilters = [...filters];

      updatedFilters.splice(index, 1);
    }

    onChange('tag', updatedFilters);
  }

  return (
    <>
      <div className="email-templates-caption-medium">Select a rule</div>
      <div className="filter-items mt-4">
        {notFoundMessage && (<div className="email-templates-caption-regular color-secondary-colour-secondary-4">{notFoundMessage}</div>)}
        {tags.map(tag => (
          <BasePill
            key={tag._id}
            text={tag.name}
            onClick={e => toggleFilter(e, tag)}
            active={filters && filters.some(item => item._id === tag._id)}
          />
        ))}
        {loading && <Preloader absolute={true} overflow={true} />}
      </div>
    </>
  )
}

export default TagFilter;