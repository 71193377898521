import { useEffect, useState } from 'react';
import KPIStats from "../../ui-elements/kpi-stats/KPIStats";
import { getSubscribersManagementKPI } from "../../api/Tools";

const SubscriberManagementOverview = () => {
  const tooltips = {
    totalSubscribers: "This shows the total number of subscribers currently in your shop's database",
    gaveConsent: 'This indicates the number of subscribers who agreed to receive marketing materials and will be included in marketing emails',
    deniedConsent: 'This shows the number of subscribers who opted out of receiving marketing emails',
    engagementRate: 'This shows the percentage of subscribers who engage with your marketing materials by taking actions, such as opening emails or clicking links',
  }

  const [stats, setStats] = useState({
    totalSubscribers: 0,
    gaveConsent: 0,
    deniedConsent: 0,
    engagementRate: 0
  });

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => setStats(await getSubscribersManagementKPI());
  
  return (
    <div className="an-layout">
			<div className="an-row an-row_gap_8 an-row_fill">
				{!!Object.keys(stats).length && (
          <KPIStats
            stats={stats}
            indicators={{
              totalSubscribers: 'Total subscribers',
              gaveConsent: 'Subscribers gave consent',
              deniedConsent: 'Subscriber denied consent',
              engagementRate: 'Engagement rate',
            }}
            tooltips={tooltips}
          />
        )}
			</div>
		</div>
  );
}

export default SubscriberManagementOverview;