import { useMemo, useContext } from "react";
import { Dashboard, Send, Grid, PeopleGroup, SignOut, LightBulb } from "akar-icons";
import { NavLink } from "react-router-dom";
import NavItem from "./NavItem";
import logo from "../static/images/dashboard/Logo.svg";
import "./Nav.scss";
import "./NavItem.scss";
import { CampaignContext } from "../context/CampaignContext";

const Nav = () => {
  const iconSize = 16;
  const iconColor = "#ffffff";
  const { campaigns, tools } = useContext(CampaignContext);

  const navItems = useMemo(() => {
    const campaignsSubMenu = campaigns.map((campaign) => {
      const url = `/campaigns/${campaign.slug}`;

      return {
        url: url,
        text: campaign.name,
        comingSoon: campaign?.comingSoon
      };
    });
    
    const toolsSubMenu = tools.map((tool) => ({
      url: `/tools/${tool.slug}`,
      text: tool.name,
    }))

    return [
      {
        url: "/",
        icon: <Dashboard size={iconSize} color={iconColor} />,
        text: "Dashboard",
      },
      {
        url: "/campaigns",
        icon: <Send size={iconSize} color={iconColor} />,
        text: "Campaigns",
        subMenu: campaignsSubMenu,
      },
      {
        url: "/widgets",
        icon: <Grid size={iconSize} color={iconColor} />,
        text: "Widgets",
      },
      {
        url: "/crm/customers",
        icon: <PeopleGroup size={iconSize} color={iconColor} />,
        text: "CRM",
        subMenu: [
          {
            url: '/crm/customers',
            text: 'Customers & Subscribers'
          }
        ]
      },
      {
        url: toolsSubMenu[0].url,
        icon: <LightBulb size={iconSize} color={iconColor} />,
        text: "Tools",
        subMenu: toolsSubMenu
      },
    ];
  }, [campaigns]);

  return (
    <div className="dashboard-nav">
      <div className="dashboard-nav__main">
        <NavLink to="/" className="dashboard-nav__logo pt-16 pb-8">
          <img src={logo} className="responsive-img" alt="Anomate" />
        </NavLink>
        <nav className="board-nav mt-24 pt-16 pb-8">
          <ul className="board-nav__list">
            {navItems.map((item, key) => (
              <NavItem item={item} key={key} />
            ))}
          </ul>
        </nav>
      </div>

      <nav className="board-nav pt-16 pb-8">
        <ul className="board-nav__list">
          <NavItem
            item={{
              url: "/logout",
              icon: <SignOut size={iconSize} color={iconColor} />,
              text: "Log out",
            }}
          />
        </ul>
      </nav>
    </div>
  );
};

export default Nav;
