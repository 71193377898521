import React, { forwardRef, useImperativeHandle, useMemo, useEffect } from "react";
import FormControl from "../../forms/FormControl";
import { useFormik } from "formik";
import * as yup from "yup";

const CouponValidity = forwardRef((props, ref) => {
  const initialValues = {
    validity: props.value,
  };

  const getOptionLabel = (value) => {
    return `${value} days`;
  };

  const options = useMemo(() => {
    return [
      { label: getOptionLabel(2), value: 2 },
      { label: getOptionLabel(7), value: 7 },
      { label: getOptionLabel(14), value: 14 },
      { label: 'Other amount', value: 'other' },
    ];
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      validity: yup.number().required("This is mandatory"),
    }),
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(false);
      props.onSubmit && props.onSubmit(values.validity);
    },
  });

  const runValidation = () => formik.validateForm().then(errors => props.onValidate(Object.keys(errors).length === 0));

  useEffect(() => {
    runValidation();
  }, [props.value, formik.validateForm]);

  useImperativeHandle(
    ref,
    () => ({
      submit() {
        formik.handleSubmit();
      }
    }),
    []
  );

  return (
    <div className="an-layout an-layout_gap_8">
      <FormControl
        label={`How many days should be the coupon valid?`}
        variant="pill"
        type="radio"
        name="validity"
        id="validity"
        value={Number(formik.values.validity)}
        options={options}
        blockRadios
        labelClassName="ui-caption-medium"
        labelMargin="4"
        onChange={e => {
          formik.handleChange(e);

          setTimeout(() => {
            props.onNext();
          }, 100);
        }}
        onReset={() => formik.setFieldValue('validity', undefined)}
        onCustomChange={(value, name) => {
          formik.setFieldValue(name, value);

          runValidation();
        }}
        blocks={2}
        validationError={
          formik.touched.validity && Boolean(formik.errors.validity)
            ? formik.errors.validity
            : false
        }
      />
    </div>
  );
});

CouponValidity.displayName = "CouponValidity";
export default CouponValidity;
