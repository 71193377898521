import React from 'react'

import Default from './Default/Default';
import Survey from './Survey/Survey';
import Promo from './Promo/Promo';

import './Screen.scss'

const Screens = ({
	handleAIGenerate,
	introCards, 
	onNext, 
	mode, 
	campaignSlug,
	campaign,
}) => {

	if (mode === 'ai') {
		if (campaignSlug === 'customer-survey') {
			return <Survey handleAIGenerate={handleAIGenerate} />
		}

		if (campaignSlug === 'promo-campaign') {
			return <Promo handleAIGenerate={handleAIGenerate} />
		}
	}

	return (
		<Default
			campaignSlug={campaignSlug}
			introCards={introCards}
			onNext={onNext}
			handleAIGenerate={() => handleAIGenerate({id: campaign._id})}
			mode={mode}
		/>
	)
}

export default Screens;