import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Calendar } from 'akar-icons'
import { addDays, startOfMonth, endOfMonth, addMonths } from "date-fns";
import { createStaticRanges } from "react-date-range";
import axios from "axios";

import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import KPIStats from '../../ui-elements/kpi-stats/KPIStats'
import { Notify } from "../../ui-elements/notify/notify";
import Preloader from "../../preloader/Preloader";

import ToolsIntro from './ToolsIntro';
import ToolsOverviews from './ToolsOverviews';
import WrapperDatePicker from '../../campaigns/overviews/WrapperDatePicker';
import useCampaign from '../../hooks/useCampaign';

const getStaticRanges = () => {
  const today = new Date();

  return createStaticRanges([
    {
      label: 'Last week',
      range: () => ({
        startDate: addDays(today, -6),
        endDate: today
      })
    },
    {
      label: 'Last 3 months',
      range: () => ({
        startDate: startOfMonth(addMonths(today, -3)),
        endDate: endOfMonth(addMonths(today, -1))
      })
    },
    {
      label: 'Last 6 months',
      range: () => ({
        startDate: startOfMonth(addMonths(today, -6)),
        endDate: endOfMonth(addMonths(today, -1))
      })
    },
    {
      label: 'Last 12 months',
      range: () => ({
        startDate: startOfMonth(addMonths(today, -12)),
        endDate: endOfMonth(addMonths(today, -1))
      })
    }
  ])
}

const ConsentManagerPage = () => {
	const { toolSlug } = useParams()
	const { tools } = useCampaign()
	const tool = tools.find((tool) => tool.slug == toolSlug)
  const { shopCampaigns } = useCampaign();
  const [filterLabel, setFilterLabel] = useState('Last 3 months');
  const [loading, setLoading] = useState(true);
  const [statData, setStatData] = useState({});

  const shopCampaign = shopCampaigns.filter((campaign) => campaign.slug === toolSlug)
  // const [shopCampaign, setShopCampaign] = useState(() => {
  //   return shopCampaigns.filter((campaign) => campaign.slug === toolSlug)
  // });
  
  const today = new Date();
  const staticRanges = getStaticRanges();
  
  const [state, setState] = useState([{
    startDate: startOfMonth(addMonths(today, -3)),
    endDate: endOfMonth(addMonths(today, -1)),
    key: 'selection'
  }])
  
  const updateData = item => {
    setState([item.selection]);
  }

  const dateFormattingOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  const setData = async() => {
    try {
      setLoading(true)
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }

      const response = await axios.get('/shop/consent/stats', {
        params: {
          startDate: state[0].startDate.toLocaleDateString('en-CA', options),
          endDate: state[0].endDate.toLocaleDateString('en-CA', options)
        },
        headers: {
          Authorization: `Bearer: ${localStorage.getItem('token')}`
        }
      })
      setStatData(response.data)
    } catch (error) {
      Notify.error({
        title: 'Something went wrong :(. Try later'
      });
      console.error(error)
    } finally {
      setLoading(false)
    }
    
  }

  const reloadPerformance = () => {
    setFilterLabel(`${state[0].startDate.toLocaleDateString('en-GB', dateFormattingOptions)} - ${state[0].endDate.toLocaleDateString('en-GB', dateFormattingOptions)}`);
    setData();
  }

  useEffect(() => {
    reloadPerformance();
  }, []);

  const filter = shopCampaign.length ? (
    <WrapperDatePicker
      filterLabel={
        <>
          <span className="ui-button__text">{filterLabel}</span>
          <span className="ui-button__icon">
            <Calendar
              size="16"
              color="#9254de"
            />
          </span>
        </>
      }
      handleChange={item => updateData(item)}
      ranges={state}
      staticRanges={staticRanges}
      today={today}
      reloadPerformance={reloadPerformance}
    />
  ) : '';

  if (loading) return <Preloader absolute={true} overflow={true} />

	return (
		<>
      {shopCampaign.length > 0 && (
        <div className="an-row an-row_gap_8 an-row_fill">
          {!!Object.keys(statData).length && (
            <KPIStats
              stats={{
                totalEmailsSent: statData?.kpis?.totalEmailsSent ? statData.kpis.totalEmailsSent : 0,
                subscribersGaveConsent: `${statData?.kpis?.subscribersGaveConsent ? statData.kpis.subscribersGaveConsent : 0}%`,
                subscribersDeniedConsent: `${statData?.kpis?.subscribersDeniedConsent ? statData.kpis.subscribersDeniedConsent : 0}%`,
                subscribersWhoDidntEngage: `${statData?.kpis?.subscribersDidntEngage ? statData.kpis.subscribersDidntEngage : 0}%`
              }}
              indicators={{
                totalEmailsSent: 'Total emails sent',
                subscribersGaveConsent: 'Subscribers gave consent',
                subscribersDeniedConsent: 'Subscribers denied consent',
                subscribersWhoDidntEngage: 'Subscribers who didn’t engage',
              }}
            />
          )}
        </div>
      )}
			<SectionHead
				align="center"
				title={
					<div className="pr-48">
						<UiHeading
							// text={tool.name}
              text="Overall performance"
							desktop="email-templates-subtitle-2-bold"
						/>
						{tool.overviewText && (
							<div
								className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4"
								// dangerouslySetInnerHTML={{ __html: tool.overviewText }}
							>
                Lorem ipsum dolor sit amet consectetur. Augue mattis malesuada purus volutpat sed pretium neque egestas.
              </div>
						)}
					</div>
				}
        filter={filter}
			/>
      {shopCampaign.length ? (
        <ToolsOverviews
          tool={tool}
          statData={statData}
        />
      ) : <ToolsIntro tool={tool} />}
		</>
	);
};

export default ConsentManagerPage;
