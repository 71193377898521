import React from "react";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import StoreInformationForm from "../../brand-assets/StoreInformationForm";
import MainContent from "../../dashboard/MainContent";
import SocialMediaForm from "../../brand-assets/SocialMediaForm";

const BrandInformationPage = () => {
  return (
    <MainContent>
      <div className="an-layout">
        <SectionHead
          title={
            <>
              <UiHeading
                text="Store information"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                This information will be included in the footer of your emails.
              </div>
            </>
          }
        />

        <StoreInformationForm />

        <SectionHead
          title={
            <>
              <UiHeading
                text="Social media profile"
                desktop="email-templates-subtitle-2-bold"
              />
              <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                Your provided social media links will be added to the footer of
                your emails.
              </div>
            </>
          }
        />

        <SocialMediaForm />
      </div>
    </MainContent>
  );
};

export default BrandInformationPage;
