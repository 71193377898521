import React, { useState, useEffect } from "react";
import BasePill from "../ui-elements/buttons/BasePill";
import UiButton from "../ui-elements/buttons/UiButton";
import "./ProductsFilter.scss";
import CategoryFilter from "./filters/CategoryFilter";
import TagFilter from "./filters/TagFilter";
import CreationDateFilter from "./filters/CreationDateFilter";
import SelectedFilters from "./filters/SelectedFilters";

const ProductsFilter = ({ onChange, values }) => {
  const [filters, setFilters] = useState({
    category: values?.category ? values.category : [],
    tag: values?.tag ? values.tag : [],
    date: values?.date ? values.date : [],
  });
  
  const [activeFilter, setActiveFilter] = useState(null);

  useEffect(() => {
    onChange(filters);
  }, [filters]);

  const handleFilterChange = (type, data) => setFilters({ ...filters, [type]: data });

  const filterItems = [
    {
      slug: 'category',
      label: 'From category',
      component: (
        <CategoryFilter
          onChange={handleFilterChange}
          filters={filters?.category}
          type="post"
        />
      ),
    },
    {
      slug: 'tag',
      label: 'From tag',
      component: (
        <TagFilter
          onChange={handleFilterChange}
          filters={filters?.tag}
          type="post"
        />
      ),
    },
    {
      slug: 'date',
      label: 'From creation date',
      component: (
        <CreationDateFilter
          onChange={handleFilterChange}
          filters={filters?.date}
        />
      ),
    }
  ];

  const updateActiveFilter = (e, type) => {
    e.preventDefault();
    
    setActiveFilter(activeFilter !== type ? type : null);
  }

  const handleAndClick = e => {
    e.preventDefault();

    setActiveFilter(null);
  }

  const handleFilterRemove = (filter, type) => setFilters({ ...filters, [type]: filters[type].filter(item => item !== filter) });

  const allInnerArraysEmpty = obj => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && Array.isArray(obj[key]) && obj[key].length > 0) {
        return false;
      }
    }

    return true;
  }

  return (
    <div className="products-filter">
      <div className="products-filter__inner">
        <div className="email-templates-caption-medium">Select a filter</div>
        <div className="products-filter__list mt-8">
          {filterItems.map(filter => (
            <React.Fragment key={filter.slug}>
              {!activeFilter || activeFilter === filter.slug || filters[filter.slug]?.length ? (
                <BasePill
                  text={filter.label}
                  onClick={e => updateActiveFilter(e, filter.slug)}
                  active={activeFilter === filter.slug || filters[filter.slug]?.length}
                />
              ) : ''}
            </React.Fragment>
          ))}
        </div>
        {activeFilter && (
          <div className="mt-8 pt-16 pb-16 text-right">
            <UiButton
              design="secondary"
              size="medium"
              text="And"
              className="ui-button_mw_120"
              onClick={handleAndClick}
            />
          </div>
        )}
        {activeFilter && (
          <div className="products-filter__selection mt-4">
            {filterItems.map(filter => (
              <React.Fragment key={filter.slug}>
                {activeFilter === filter.slug && filter.component}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      <div className="products-filter__summary pt-8 bt-1">
        <div className="email-templates-caption-medium">Selected filters</div>
        <div className="email-templates-caption-regular mt-4 color-secondary-colour-secondary-4">
          {allInnerArraysEmpty(filters) ? (
            <>You have no filters selected</>
          ) : (
            <SelectedFilters
              filters={filters}
              onRemove={handleFilterRemove}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductsFilter;