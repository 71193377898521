import React, { useState } from 'react'
import { CircleAlert } from "akar-icons";
import { CurrencyInput } from "react-currency-mask";

const Default = (props) => {
  const [limited, setLimited] = useState(false);

  const renderInput = () => {
    return (
      <>
        {props?.currency ? (
          <CurrencyInput
            onChangeValue={(event, originalValue) => props.onChange(originalValue)}
            currency={props.currency}
            locale="en-US"
            value={props.value}
            InputElement={getInput()}
          />
        ) : getInput()}
      </>
    )
  }

  const getInput = () => {
    return (
      <input
        type={props.type}
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        className={`form-control__input ui-caption-regular${
          props.validationError ? " form-control__input_error" : ""
        }${props.className ? ` ${props.className}` : ""}${props?.fieldType && props.fieldType === 'search' ? ' form-control__input_search' : ''}`}
        required={props.required ? props.required : false}
        onKeyUp={(e) => {
          if (props.maxLength && e.target.value.length >= props.maxLength && !limited) {
            setLimited(true)
          }
        }}
        disabled={props.disabled}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        onKeyDown={props.onKeyDown}
        value={props.value}
        max={props?.max}
        min={props?.min}
        minLength={props.minLength}
        readOnly={props.readOnly ? "readonly" : false}
        onClick={props.onClick}
        onPaste={props.onPaste}
        autoFocus={props.autoFocus}
        maxLength={props.maxLength}
      />
    );
  }

  return (
    <div className={`form-control ${props.classNameDiv ? props.classNameDiv : ''}${props.margin ? ` mt-${props.margin}` : ""}`}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`${
            props.labelClassName
              ? props.labelClassName
              : "email-templates-paragraph-1-medium"
          } form-control__label${
            props.labelMargin ? ` mb-${props.labelMargin}` : ""
          }`}
        >
          {props.label}
        </label>
      )}
      <div className="form-control__inner">
        {renderInput()}
        {props.append && (
          <div className="form-control__append ui-caption-regular">
            {props.append}
          </div>
        )}
        {limited && !props.validationError && (
          <div className="ui-small-regular color-secondary-colour-secondary-7" style={{position: 'absolute'}}>
            {`Only ${props.maxLength} characters are allowed`}
          </div>
        )}
        {props.validationError && props.validationWithIcon && (
          <div className="form-control__error-icon">
            <CircleAlert size="18" color="#ff4d4f" />
          </div>
        )}
      </div>
      {props.validationError && (
        <div className="form-control__error ui-caption-regular">
          {props.validationError.message ? props.validationError.message : (typeof props.validationError !== 'object' ? props.validationError : '')}
        </div>
      )}
    </div>
  );
}

export default Default;