import React, { useState, useMemo, useCallback, useEffect, createContext } from "react";
import styles from "./StepWizard.module.scss";
import UiHeading from "../text/UiHeading";
import UiCaptionRegular from "../text/UiCaptionRegular";
import useStep from "../../hooks/useStep";
import classNames from "classnames";
import Stepper from "../../stepper/Stepper";

const StepWizardContent = ({ showStepper }) => {
  const {
    currentStep,
    hideStepHeader,
    stepTitle,
    stepDescription,
    steps,
    currentStepIndex,
  } = useStep();
  const description = stepDescription || currentStep?.description || "";
  const stepperMap = {};
  const stepper = Object.values(steps).filter((step) => {
    if (!stepperMap[step.type]) {
      stepperMap[step.type] = true;
      return true;
    }
    return false;
  });
  const activeStepType = Object.values(steps)[currentStepIndex]?.type;

  return (
    <div className={styles["base"]}>
      {!hideStepHeader && (
        <div className={styles["header"]}>
          <UiHeading
            text={stepTitle || currentStep.title}
            desktop="other-headings-h6-bold"
            margin="24"
          />
          {description && (
            <UiCaptionRegular
              margin="8"
              className="color-secondary-colour-secondary-7 text-break-spaces"
              text={
                typeof description === "string"
                  ? description.replace("<br />", "\n")
                  : description
              }
            />
          )}
        </div>
      )}

      <div className={classNames(styles["body"], "scrollbar")}>
        {currentStep.component && currentStep.component}
      </div>

      {showStepper && (
        <Stepper
          className={styles["stepper"]}
          sections={stepper}
          activeStepType={activeStepType}
        />
      )}
    </div>
  );
};

export const StepContext = createContext();

const filterHiddenStep = obj => {
  let filteredObj = {};

  for (let key in obj) {
    if (!obj[key]?.hidden) {
      filteredObj[key] = obj[key];
    }
  }

  return filteredObj;
}

const StepWizard = (props) => {
  const [stepData, setStepData] = useState(props.data || {});
  const [stepTitle, setStepTitle] = useState(null);
  const [stepDescription, setStepDescription] = useState(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [navigationDirection, setNavigationDirection] = useState(null);
  const [hideStepHeader, setHideStepHeader] = useState(false);
  const steps = filterHiddenStep(props.steps);

  const stepKey = useMemo(() => {
    return Object.keys(steps)[currentStepIndex];
  }, [steps, currentStepIndex]);

  const currentStep = useMemo(() => {
    return steps[stepKey];
  }, [steps, stepKey]);

  const currentStepValue = useMemo(() => {
    return stepData[stepKey];
  }, [stepData, stepKey]);

  useEffect(() => {
    setStepData(props.data || {})
  }, [props.data])

  const handleSkip = useCallback(() => {
    let index = currentStepIndex + 1;
    if (navigationDirection === "back") {
      index = currentStepIndex - 1;
    }

    index === -1 ? props.onClose() : setCurrentStepIndex(index);
  }, [navigationDirection, currentStepIndex, setCurrentStepIndex]);
  
  const handleAINext = useCallback((mergeState) => {
    if (currentStepIndex + 1 === Object.keys(steps).length) {
      props.onComplete && props.onComplete(mergeState);
      return;
    }
    
    stepTitle && setStepTitle(null);
    stepDescription && setStepDescription(null);
    setNavigationDirection("next");
    
    setStepData(mergeState);
    setCurrentStepIndex(currentStepIndex + 1);
  },[
    stepData,
    stepKey,
    stepTitle,
    stepDescription,
    steps,
    currentStepIndex,
    props.onComplete,
    setStepData,
    setStepTitle,
    setStepDescription,
    setCurrentStepIndex,
    setNavigationDirection,
  ])

  const handleNext = useCallback(
    (values, key, isComplete, skip, isNewWidget) => {
      const _key = key || stepKey;
      const newStepData = skip ? stepData : { ...stepData, [_key]: values };
      
      if (isNewWidget) {
        delete newStepData.variants
      }
      
      if (isComplete) {
        props.onComplete && props.onComplete(newStepData);
        return;
      }

      if (currentStepIndex + 1 === Object.keys(steps).length) {
        props.onComplete && props.onComplete(newStepData);
        return;
      }

      stepTitle && setStepTitle(null);
      stepDescription && setStepDescription(null);
      setNavigationDirection("next");

      setStepData(newStepData);
      setCurrentStepIndex(currentStepIndex + 1);
    },
    [
      stepData,
      stepKey,
      stepTitle,
      stepDescription,
      steps,
      currentStepIndex,
      props.onComplete,
      setStepData,
      setStepTitle,
      setStepDescription,
      setCurrentStepIndex,
      setNavigationDirection,
    ]
  );

  const handlePrev = (values, key, skip) => {
    const _key = key || stepKey;
    const newStepData = skip ? stepData : { ...stepData, [_key]: values };

    stepTitle && setStepTitle(null);
    stepDescription && setStepDescription(null);
    setNavigationDirection("back");

    if (currentStepIndex === 0) {
      props.onClose && props.onClose();
      return;
    }

    setStepData(newStepData);
    setCurrentStepIndex(currentStepIndex - 1);
  };

  return (
    <StepContext.Provider
      value={{
        stepKey,
        stepData,
        currentStep,
        currentStepValue,
        currentStepIndex,
        hideStepHeader,
        handleNext,
        handleAINext,
        stepTitle,
        setStepTitle,
        stepDescription,
        setStepDescription,
        navigationDirection,
        setHideStepHeader,
        setNavigationDirection,
        handlePrev,
        handleSkip,
        steps
      }}
    >
      <StepWizardContent
        key={`step-wizard-${stepKey}`}
        showStepper={props.showStepper}
      />
    </StepContext.Provider>
  );
};

export default StepWizard;
