import { useEffect, useState } from "react";
import TotalEmailsSent from "./TotalEmailsSent";
import CampaignPerformance from "./CampaignPerformance";
import UserLocation from "./UserLocation";
import Timeline from "./Timeline";
import CampaignInstances from "./CampaignInstances";
import { getCampaignLocationStats, getCampaignTimeline } from "../../api/Campaign";
import KPIStats from '../../ui-elements/kpi-stats/KPIStats';
import { getStats } from './tools';
import "./Overview.scss";

const EmailThisProductOverview = ({ campaigns, slug }) => {
  const statObj = {
    totalRevenue: 0,
    totalOrders: 0,
    emailsSent: 0,
    opened: 0,
    clicked: 0,
    purchased: 0,
    unsubscribed: 0,
  }
  const [stats, setStats] = useState(statObj)
  const [locationStats, setLocationStats] = useState([]);
  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    setStats(getStats(statObj, campaigns))
    fetchData();
  }, [campaigns]);

  const fetchData = async () => {
    setLocationStats(await getCampaignLocationStats(slug));
    setTimeline(await getCampaignTimeline(slug));
  }
  
  return (
    <div className="an-layout">
      <div className="an-row an-row_gap_8">
        <KPIStats 
          stats={stats}
          indicators={{
            totalRevenue: {
              type: 'money',
              label: 'Total revenue',
            },
            totalOrders: 'Total orders',
            emailsSent: 'Emails sent',
            opened: 'Emails opened'
          }}
        />
      </div>
      <div className="an-row">
        <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
          <CampaignPerformance
            totalEmailsSent={stats?.emailsSent}
            purchased={stats?.purchased}
            opened={stats?.opened}
            clicked={stats?.clicked}
            unsubscribed={stats?.unsubscribed}
          />
        </div>
        <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
          <TotalEmailsSent campaigns={campaigns} />
        </div>
      </div>
      <CampaignInstances 
        campaigns={campaigns}
        slug={slug}
      />
      <div className="an-row">
        {!!locationStats.length && (
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
            <UserLocation
              stats={locationStats}
              clicked={stats.clicked}
            />
          </div>
        )}
        {!!timeline.length && (
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
            <Timeline
              timeline={timeline}
              campaignName={campaigns[0].name}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default EmailThisProductOverview;