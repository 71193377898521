import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Calendar } from 'akar-icons'
import { addDays, startOfMonth, endOfMonth, addMonths } from "date-fns";
import { createStaticRanges } from "react-date-range";

import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import WrapperDatePicker from '../../campaigns/overviews/WrapperDatePicker';
import useCampaign from '../../hooks/useCampaign';
import Preloader from "../../preloader/Preloader";

import StoreRevenue from "./OverviewPageComponents/StoreRevenue";
import EmailDelivery from "./OverviewPageComponents/EmailDelivery";
import CampaignPerformance from "./OverviewPageComponents/CampaignPerformance";
import OrdersPerformance from "./OverviewPageComponents/OrdersPerformance";
import SubscriberManagementOverview from "./SubscriberManagementOverview";
import { getSubscribersManagementGeneralPerformance } from "../../api/Tools";

const getStaticRanges = () => {
  const today = new Date();

  return createStaticRanges([
    {
      label: 'Last week',
      range: () => ({
        startDate: addDays(today, -6),
        endDate: today
      })
    },
    {
      label: 'Last 3 months',
      range: () => ({
        startDate: startOfMonth(addMonths(today, -3)),
        endDate: endOfMonth(addMonths(today, -1))
      })
    },
    {
      label: 'Last 6 months',
      range: () => ({
        startDate: startOfMonth(addMonths(today, -6)),
        endDate: endOfMonth(addMonths(today, -1))
      })
    },
    {
      label: 'Last 12 months',
      range: () => ({
        startDate: startOfMonth(addMonths(today, -12)),
        endDate: endOfMonth(addMonths(today, -1))
      })
    }
  ])
}

const OverviewPage = () => {
	const { toolSlug } = useParams()
	const { tools } = useCampaign()
	const tool = tools.find((tool) => tool.slug == toolSlug)
  const { shopCampaigns } = useCampaign();
  const [filterLabel, setFilterLabel] = useState('Last 3 months');
  const [performanceLoader, setPerformanceLoader] = useState(true);
  const [generalPerformance, setGeneralPerformance] = useState({});
  const [loading, setLoading] = useState(true)

  const [shopCampaign, setShopCampaign] = useState(() => {
    return shopCampaigns.filter((campaign) => campaign.slug === toolSlug)
  });
  
  const today = new Date();
  const staticRanges = getStaticRanges();
  
  const [state, setState] = useState([{
    startDate: startOfMonth(addMonths(today, -3)),
    endDate: endOfMonth(addMonths(today, -1)),
    key: 'selection'
  }])
  
  const updateData = item => {
    setState([item.selection]);
  }

  const dateFormattingOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }

  const setData = async () => {
    try {
      setLoading(true)
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      const startDate = state[0].startDate.toLocaleDateString('en-CA', options);
      const endDate = state[0].endDate.toLocaleDateString('en-CA', options);

      const response = await await getSubscribersManagementGeneralPerformance({
        startDate,
        endDate
      })
      setGeneralPerformance(response)
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  const reloadPerformance = () => {
    setPerformanceLoader(true);
    setFilterLabel(`${state[0].startDate.toLocaleDateString('en-GB', dateFormattingOptions)} - ${state[0].endDate.toLocaleDateString('en-GB', dateFormattingOptions)}`);
    setData();
  }

  useEffect(() => {
    reloadPerformance();
  }, []);

  const filter = (
    <WrapperDatePicker
      filterLabel={
        <>
          <span className="ui-button__text">{filterLabel}</span>
          <span className="ui-button__icon">
            <Calendar
              size="16"
              color="#9254de"
            />
          </span>
        </>
      }
      handleChange={item => updateData(item)}
      ranges={state}
      staticRanges={staticRanges}
      today={today}
      reloadPerformance={reloadPerformance}
    />
  );

  if (loading) {
    return <Preloader absolute={true} overflow={true} />
  }

	return (
		<div className='an-layout'>
      <SubscriberManagementOverview />
			<SectionHead
				align="center"
				title={
					<div className="pr-48">
						<UiHeading
							text="General performance"
							desktop="email-templates-subtitle-2-bold"
						/>
            <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">Current performance report for your selected dates.</div>
					</div>
				}
        filter={filter}
			/>
      <div className="an-row">
        <StoreRevenue data={generalPerformance?.storeRevenue ? generalPerformance.storeRevenue : {}} />
      </div>
      <div className="an-row">
        <EmailDelivery data={generalPerformance?.emailDelivery ? generalPerformance.emailDelivery : {}} />
      </div>
      <div className="an-row">
        <CampaignPerformance data={generalPerformance?.campaignsPerformance ? generalPerformance.campaignsPerformance : {}} />
      </div>
      <div className="an-row">
        <OrdersPerformance data={generalPerformance?.ordersPerformance ? generalPerformance.ordersPerformance : {}} />
      </div>
		</div>
	);
};

export default OverviewPage;
