import React, { useState, useEffect } from "react";
import moment from "moment";
import Stat from "../../../ui-elements/kpi-stats/Stat";
import TotalImpressions from "./TotalImpressions";
import ActivityTable from "../../../ui-elements/activity-table/ActivityTable";
import RaffleGiveawayCampaignPerformance from "./RaffleGiveawayCampaignPerformance";

const RaffleSingleOverview = ({ campaigns }) => {

  const [stats, setStats] = useState(campaigns[0]);
  const [prize, setPrize] = useState('');
  const [winners, setWinners] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setPrize(campaigns[0].raffleSetup.prizeType.raffleType === 'catalog' ? campaigns[0].raffleSetup.prizeType.product.name : campaigns[0].raffleSetup.prizeType.name);

    if (campaigns[0].raffleSetup.raffleInformation.amountOfWinners) {
      setWinners(campaigns[0].raffleSetup.raffleInformation.amountOfWinners);
    }

    if (campaigns[0].raffleSetup.datesAndTime.datesAndTime.startDate) {
      setStartDate(moment(campaigns[0].raffleSetup.datesAndTime.datesAndTime.startDate).format('DD MMM YYYY, hh:mma'));
    }

    if (campaigns[0].raffleSetup.datesAndTime.datesAndTime.endDate) {
      setEndDate(moment(campaigns[0].raffleSetup.datesAndTime.datesAndTime.endDate).format('DD MMM YYYY, hh:mma'));
    }
  }, [campaigns]);

  return (
    <div className="an-layout">
      <div className="an-row an-row_fill an-row_gap_8">
        <Stat
          label={prize}
          value="Prize"
          smallValue={true}
        />
        <Stat
          label={winners}
          value="Winners"
          smallValue={true}
        />
        <Stat
          label={startDate}
          value="Start date"
          smallValue={true}
        />
        <Stat
          label={endDate}
          value="End date"
          smallValue={true}
        />
      </div>
      <div className="an-row">
        <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
          <TotalImpressions campaign={campaigns[0]} />
        </div>
        <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
          <RaffleGiveawayCampaignPerformance
            stats={stats}
            title="Raffle performance"
          />
        </div>
      </div>
      <ActivityTable
        slug="raffle-giveaway"
        type="campaign-activity"
        shopCampaignId={campaigns[0]._id}
        testEmail={true}
      />
    </div>
  );
}

export default RaffleSingleOverview;