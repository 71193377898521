import React, { useState, useEffect } from 'react'
import { cloneDeep } from 'lodash'

import ToolsPerformance from '../ToolsPerformance';
import SectionHead from '../../../ui-elements/common/SectionHead';
import CampaignIcon from '../../../ui-elements/common/CampaignIcon';
import UiCaptionRegular from '../../../ui-elements/text/UiCaptionRegular';
import UiHeading from '../../../ui-elements/text/UiHeading';
import Chips from '../../../ui-elements/common/Chips';
import Divider from '../../../ui-elements/divider/Divider';
import UiCaptionMedium from '../../../ui-elements/text/UiCaptionMedium';
import Toogler from '../../../ui-elements/toogler/Toogler';
import Card from './Card';
import useAuth from '../../../hooks/useAuth';

const OrdersPerformance = ({ data }) => {
  const { shopData } = useAuth();
	const [mode, setMode] = useState('orders')
	const [type, setType] = useState('day')
  const [cardData, setCardData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalOrdersTrend, setTotalOrdersTrend] = useState(0);
  const [couponsCreated, setCouponsCreated] = useState(0);
  const [couponsClaimed, setCouponsClaimed] = useState(0);
  const [averageOrderItems, setAverageOrderItems] = useState(0);
  const [averageOrderValue, setAverageOrderValue] = useState(0);

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: shopData.currency,
    maximumFractionDigits: 0
  });

  const legend = {
    created: {
      label: 'Coupons created',
      value: couponsCreated
    },
    claimed: {
      label: 'Coupons claimed',
      value: couponsClaimed
    },
    items: {
      label: 'Average order items',
      value: averageOrderItems
    },
    value: {
      label: 'Average order value',
      value: averageOrderValue
    }
  }

  useEffect(() => {
    let _cardData = [];

    if (data?.bestSellingProducts) {
      data.bestSellingProducts.map((product, key) => {
        if (key === 0) {
          _cardData.push({
            title: 'Best selling products',
            name: product?.name,
            value: product?.revenue,
            params: []
          });
        } else {
          _cardData[0].params.push({
            name: product?.name,
            type: 'currency',
            value: product?.revenue
          });
        }
      });
    }

    if (data?.bestSellingCategories) {
      data.bestSellingCategories.map((category, key) => {
        if (key === 0) {
          _cardData.push({
            title: 'Best selling categories',
            name: category?.name,
            value: category?.revenue,
            params: []
          });
        } else {
          _cardData[1].params.push({
            name: category?.name,
            type: 'currency',
            value: category?.revenue
          });
        }
      });
    }

    setTotalOrders(data?.overview?.totalOrders ? data.overview.totalOrders : 0);
    setTotalOrdersTrend(data?.overview?.totalOrdersTrend ? data.overview.totalOrdersTrend : 0);
    setCouponsCreated(data?.overview?.couponsCreated ? data.overview.couponsCreated : 0);
    setCouponsClaimed(data?.overview?.couponsClaimed ? data.overview.couponsClaimed : 0);
    setAverageOrderItems(data?.overview?.averageOrderItems ? data.overview.averageOrderItems : 0);
    setAverageOrderValue(data?.overview?.averageOrderValue ? data.overview.averageOrderValue : 0);
    setCardData(_cardData);
  }, [data]);

  const getChartData = (chartData) => {
    const _chartData = cloneDeep(chartData)
    const hiddenIndex = mode === 'orders' ? 1 : 0
    if (type === 'day') {
      const newData = _chartData.map(item => {
        item.datasets[hiddenIndex].hidden = true
        return item
        
      })
      return newData
    }
    _chartData.datasets[hiddenIndex].hidden = true
    return _chartData
  }

  return (
		<div className='an-col card card_shadow card_br_24 card_24'>
			<SectionHead
				title={
					<>
						<div className="email-templates-subtitle-2-medium">Orders performance</div>
						<div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">
							Current performance with the orders from your campaigns for your selected dates.
						</div>
					</>
				}
				filter={
					<Toogler
						items={[
							{ value: 'day', text: 'Day' },
							{ value: 'week', text: 'Week' },
							{ value: 'month', text: 'Month' },
						]}
						defaultStatus={type}
						onChange={(value) => setType(value)}
					/>
				}
			/>
      {!!cardData.length && (
        <div className='an-row mt-24 mb-24'>
          {cardData.map((card, index) => <Card key={index} campaign={card} />)}
        </div>
      )}
			<ToolsPerformance
        key={mode}
				title="Overview"
				subTitle="Overall order performance over time."
        type={type}
        data={getChartData(data.overview.chart[type])}
        displayTooltipColors={false}
        tooltipLabel={(tooltipItem, chartData) => {
          const { dataIndex } = tooltipItem
          const orderValue = chartData[0].data[dataIndex]
          const orderLabel = chartData[0].label
          const revenueValue = chartData[1].data[dataIndex]
          const revenueLabel = chartData[1].label
          return (
            `<div>
              <div style="display: flex, jusitfy-content: space-between;">${orderLabel}: ${orderValue.toLocaleString()}</div>
              <div>${revenueLabel}: ${formatter.format(revenueValue)}</div>
            </div>`
          )
        }}
        yAxis={{
          min: 0,
          ticks: {
            callback: (value, index, ticks) => {
              if (mode === 'orders') {
                return value
              }
              return value.toLocaleString('en-US', {
                style: 'currency',
                currency: shopData.currency,
                minimumFractionDigits: 0
              })
            }
          }
        }}
				filter={
					<Toogler
						label={'See as'}
						items={[
							{ value: 'orders', text: 'Orders' },
							{ value: 'revenue', text: 'Revenue' },
						]}
            className='secondary'
						defaultStatus={mode}
						onChange={value => setMode(value)}
					/>
				}
				withWrap={false}
				Legend={() => {
					return (
						<>
							<div className='icon-wrapper'>
								<div>
									<CampaignIcon emoji={'📦'} />
								</div>
								<div>
									<UiCaptionRegular text="Total orders" />
									<UiHeading
										element="h6"
										desktop="other-headings-h6-bold"
										text={
											<div style={{display: 'flex', justifyContent: 'space-between'}}>
												<div>{totalOrders.toLocaleString()}</div>
												<div>
													<Chips
														color={totalOrdersTrend >= 0 ? 'green' : 'red'}
														value={`${Math.abs(totalOrdersTrend).toFixed(1)}%`}
														arrow={true}
													/>
												</div>
											</div>
										}
									/>
								</div>
							</div>
							<Divider className="mt-16 mb-16" />
							<div className='legends'>
								<UiCaptionMedium text="Overall performance" />
								{Object.keys(legend).map((key) => {
									const { label, value } = legend[key];
									let _value;
									if (key === 'value') {
										_value = formatter.format(value)
									} else {
										_value = value.toLocaleString('en-US', { minimumFractionDigits: 0 })
									}
									return (
										<UiCaptionRegular 
										key={key}
										text={
											<div style={{display: 'flex', justifyContent: 'space-between'}}>
												<div>{label}</div>
												<div>{_value}</div>
											</div>
										}
									/>
									)
								})}
							</div>
						</>
					)
				}}
		/>
		</div>
	);
}

export default OrdersPerformance;