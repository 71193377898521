import React from "react";
import UiButton from "../../buttons/UiButton";
import styles from "./Navigation.module.scss";
import classNames from "classnames";
import ButtonGroup from "../../button-group/button-group";

const Navigation = (props) => {
  return (
    <div className={classNames(styles["base"])}>
      <ButtonGroup>
        <UiButton
          type="button"
          disabled={props.disableBack}
          onClick={props.onBack}
          size="small"
          text="Back"
        />
        <UiButton
          type="button"
          size="small"
          disabled={props.disableNext}
          onClick={props.onNext}
          text={props.nextLabel}
        />
      </ButtonGroup>
    </div>
  );
};

export default Navigation;
