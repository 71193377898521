import "./IntroItem.scss";

const IntroItem = props => {
  return (
    <div className="onboarding-intro-item">
      <img
        src={props.item.icon}
        alt={props.item.caption}
        className="onboarding-intro-item__icon"
      />
      <div className="email-templates-overline mt-24">{props.item.caption}</div>
      <div className="email-templates-paragraph-2-regular mt-4 color-secondary-colour-secondary-7" dangerouslySetInnerHTML={{ __html: props.item.text }} />
    </div>
  )
}

export default IntroItem;