import DownloadPluginStep from "./DownloadPluginStep";
import UiButton from "../ui-elements/buttons/UiButton";
import NiArrowDownSquare from "../static/images/onboarding/ni-arrow-down-square.svg";
import NiArrowUpSquare from "../static/images/onboarding/ni-arrow-up-square.svg";
import NiCheckHand from "../static/images/onboarding/ni-check-hand.svg";
import "./DownloadPlugin.scss";

const DownloadPlugin = () => {
  const steps = [
    {
      icon: NiArrowDownSquare,
      title: 'Download plugin',
      text: 'Click on the download button to get Anomate plugin.'
    },
    {
      icon: NiArrowUpSquare,
      title: 'Upload plugin',
      text: 'Go to Wordpress “Add new plugins” and then click “Upload plugin”.'
    },
    {
      icon: NiCheckHand,
      title: 'Activate plugin',
      text: 'Go to “Installed plugins” and activate “Anomate helper”'
    }
  ]

  return (
    <div className="text-center">
      <div className="download-plugin mt-48 pt-24 pb-24">
        {steps.map((step, key) => (
          <DownloadPluginStep
            step={step}
            key={key}
          />
        ))}
      </div>
      <div className="mt-32">
        <UiButton
          elem="a"
          href="/anomate-helper.zip"
          size="small"
          text="Download plugin"
          download={true}
          target="_blank"
        />
      </div>
    </div>
  );
}

export default DownloadPlugin;