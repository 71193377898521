import { useState } from "react";
import { EyeOpen, EyeClosed } from "akar-icons";
import axios from "axios";
import FormControl from "../ui-elements/forms/FormControl";
import Preloader from "../preloader/Preloader";
import UiButton from "../ui-elements/buttons/UiButton";
import Notification from "../ui-elements/notification/Notification";
import { Notify } from "../ui-elements/notify/notify";

const PasswordSettings = props => {
  const dataStructure = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }

  const [currentPasswordInputType, setCurrentPasswordInputType] = useState('password');
  const [newPasswordInputType, setNewPasswordInputType] = useState('password');
  const [confirmNewPasswordInputType, setConfirmNewPasswordInputType] = useState('password');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(dataStructure);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [cancelDisabled, setCancelDisabled] = useState(true);

  const updateData = e => {
    const currentData = { ...data, [e.target.name]: e.target.value };

    setData(currentData);
    setCancelDisabled(!(currentData.currentPassword || currentData.newPassword || currentData.confirmNewPassword));

    if (!currentData.currentPassword || !currentData.newPassword || !currentData.confirmNewPassword) {
      setSubmitDisabled(true);

      return;
    }

    if (currentData.newPassword !== currentData.confirmNewPassword) {
      setSubmitDisabled(true);

      return;
    }

    setSubmitDisabled(false);
  }

  const toggleInputPasswordType = elem => {
    if (elem === 'current') {
      setCurrentPasswordInputType(currentPasswordInputType === 'password' ? 'text' : 'password');
    } else if (elem === 'new') {
      setNewPasswordInputType(newPasswordInputType === 'password' ? 'text' : 'password');
    } else if (elem === 'confirmNew') {
      setConfirmNewPasswordInputType(confirmNewPasswordInputType === 'password' ? 'text' : 'password');
    }
  }

  const savePassword = e => {
    e.preventDefault();

    setLoading(true);

    axios.post('/user/password', data, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    }).then(() => {
      setLoading(false);
      setErrors({});
      setSubmitDisabled(true);
      Notify.success({title: 'Changes saved successfully', message: 'Your password has been updated.'})
      reset();
    }).catch(err => {
      if (err.response.data.name === 'ValidationError') {
        setErrors(err.response.data.errors);
      }

      setLoading(false);
    });
  }

  const reset = e => {
    if (e) {
      e.preventDefault();
    }

    setData(dataStructure);
    setCancelDisabled(true);
  }

  return (
    <form
      className="card card_24 card_shadow"
      onSubmit={savePassword}
    >
      <div className="an-layout">
        <div className="form-control">
          <FormControl
            type={currentPasswordInputType}
            id="currentPassword"
            name="currentPassword"
            placeholder="Write your current password here"
            label="Current password"
            labelClassName="ui-caption-medium"
            labelMargin="4"
            required={true}
            onChange={updateData}
            validationError={errors.currentPassword}
            value={data.currentPassword}
          />
          <div
            onClick={() => toggleInputPasswordType('current')}
            className="form-control__toggle-password-input toggle-password-input toggle-password-input_with-label"
          >
            {currentPasswordInputType === 'password' ? (
              <EyeOpen
                color="#aba3b1"
                size="16"
              />
            ) : (
              <EyeClosed
                color="#aba3b1"
                size="16"
              />
            )}
          </div>
        </div>
        <div className="form-control">
          <FormControl
            type={newPasswordInputType}
            id="newPassword"
            name="newPassword"
            placeholder="Write your new password here"
            label="New password"
            labelClassName="ui-caption-medium"
            labelMargin="4"
            required={true}
            minLength="6"
            onChange={updateData}
            validationError={errors.newPassword}
            value={data.newPassword}
          />
          <div
            onClick={() => toggleInputPasswordType('new')}
            className="form-control__toggle-password-input toggle-password-input toggle-password-input_with-label"
          >
            {newPasswordInputType === 'password' ? (
              <EyeOpen
                color="#aba3b1"
                size="16"
              />
            ) : (
              <EyeClosed
                color="#aba3b1"
                size="16"
              />
            )}
          </div>
        </div>
        <div className="form-control">
          <FormControl
            type={confirmNewPasswordInputType}
            id="confirmNewPassword"
            name="confirmNewPassword"
            placeholder="Confirm your new password here"
            label="Confirm new password"
            labelClassName="ui-caption-medium"
            labelMargin="4"
            minLength="6"
            required={true}
            onChange={updateData}
            validationError={errors.confirmNewPassword}
            value={data.confirmNewPassword}
          />
          <div
            onClick={() => toggleInputPasswordType('confirmNew')}
            className="form-control__toggle-password-input toggle-password-input toggle-password-input_with-label"
          >
            {confirmNewPasswordInputType === 'password' ? (
              <EyeOpen
                color="#aba3b1"
                size="16"
              />
            ) : (
              <EyeClosed
                color="#aba3b1"
                size="16"
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-32 flex-right gap-8">
        <UiButton
          text="Cancel"
          size="medium"
          design="secondary"
          onClick={reset}
          disabled={cancelDisabled}
        />
        <UiButton
          text="Update password"
          size="medium"
          disabled={submitDisabled}
        />
      </div>
      {loading ? (
        <Preloader
          absolute={true}
          overflow={true}
        />
      ) : ''}
    </form>
  )
}

export default PasswordSettings;