import ConsentOverview from './ConsentManagerComponents/ConsentOverview'

const ToolsOverviews = ({ tool, statData }) => {
	if (tool.slug == 'subscribers-management') {
		return (
			<ConsentOverview 
        tool={tool}
        statData={statData}
      />
		);
	}
	
	return null
}

export default ToolsOverviews