const DonwloadPluginStep = props => {
  return (
    <div className="download-plugin__step">
      <img
        src={props.step.icon}
        alt={props.step.title}
        className="download-plugin__icon"
      />
      <div className="email-templates-paragraph-1-medium mt-24 download-plugin__title">{props.step.title}</div>
      <div className="mt-4 email-templates-paragraph-2-regular color-secondary-colour-secondary-7">{props.step.text}</div>
    </div>
  );
}

export default DonwloadPluginStep;