import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";

import CustomizationContainer from "../ui-elements/customization/customization-container/CustomizationContainer";
import CustomizationForms from "../ui-elements/customization/customization-forms/CustomizationForms";
import CustomizationOptionsContainer from "../ui-elements/customization/customiztion-options-container/CustomizationOptionsContainer";
import StepWizardNavigation from "../ui-elements/step-wizard/StepWizardNavigation";
import WidgetVariantsGallery from "../ui-elements/customization/widget-variants-gallery/WidgetVariantsGallery";
import { Notify } from "../ui-elements/notify/notify";

import useStep from "../hooks/useStep";
import { isObjectEmpty } from "../util/object";
import { validateField } from "./tools";

import { getWidgetPreview } from "../api/Customizer";
import { generateWidgetCustomization } from "../api/Gpt";

const WidgetCustomization = () => {
  const [loading, setLoading] = useState(false);
  const [previewHTML, setPreviewHTML] = useState("");
  const [isWidgetCustomizerValid, setIsWidgetCustomizerValid] = useState(false);
  const { stepData, handlePrev, handleNext, setStepTitle, currentStep } = useStep();
  const [values, setValues] = useState(null);
  const [variants, setVariants] = useState(stepData.variants || []);
  const [validationErrors, setValidationErrors] = useState({});
  const [showValidators, setShowValidators] = useState([]);

  useEffect(() => {
    setIsWidgetCustomizerValid(!Object.keys(validationErrors).length)
    // if (Object.keys(validationErrors).length <=0) return
    // setIsWidgetCustomizerValid(isObjectEmpty(validationErrors));
  }, [validationErrors]);

  useLayoutEffect(() => {
    const stepTitle = currentStep.title.replace(`{widgetName}`, stepData.widget.name.toLowerCase());
    setStepTitle(stepTitle);
  }, [stepData, currentStep]);

  useEffect(() => {
    if (
      !stepData.variants ||
      (stepData.variants && stepData.variants.length === 0)
    ) {
      setVariants([{ active: true }]);
      setValues({});
    } else {
      // find active variant
      const activeVariant = stepData.variants.find((variant) => variant.active);

      // set customizer values
      if (activeVariant) {
        const _value = { ...activeVariant };
        delete _value.active;
        setValues(_value);
      } else {
        setVariants(
          stepData.variants.map((variant, index) => ({
            ...variant,
            active: index === 0,
          }))
        );
        setValues(stepData.variants[0]);
      }
    }
  }, [stepData.variants]);

  useEffect(() => {
    if (!values) return;
    fetchPreview(values);
  }, [values]);

  const fetchPreview = async (customizer) => {
    if (loading) return;
    setLoading(true);

    try {
      const response = await getWidgetPreview({
        customizer,
        id: stepData.widget._id,
        layout: stepData.layout,
        filter: stepData?.filter
      });

      setPreviewHTML(response);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleChange = useCallback((values) => {
    setValues((prev) => ({...prev, ...values}));
    setVariants((prev) =>
      prev.map((variant) => {
        if (variant.active) {
          return { ...variant, ...values };
        } else {
          return variant;
        }
      })
    );

    validateElements(values);
  }, []);

  const handleAddNewVariant = useCallback(() => {
    setValues({});
    setVariants((prev) => [
      ...prev.map((variant) => ({ ...variant, active: false })),
      { active: true },
    ]);
  }, [values]);

  const handleMoveVariant = (variantIndex) => {
    const _variant = { ...variants[variantIndex] };
    delete _variant.active;
    setValues(_variant);

    setVariants((prev) => [
      ...prev.map((variant, index) => ({
        ...variant,
        active: index === Number(variantIndex),
      })),
    ]);
  };

  const handleRemove = (variantIndex) => {
    const _variantIndex = variantIndex > 0 ? variantIndex - 1 : variantIndex;

    let _variants = [
      ...variants.slice(0, variantIndex),
      ...variants.slice(variantIndex + 1),
    ];

    const _variant = { ..._variants[_variantIndex] };
    delete _variant.active;
    setValues(_variant);

    _variants = _variants.map((variant, index) => ({
      ...variant,
      active: index === _variantIndex,
    }));
    setVariants(_variants);
  };

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const submitter = e.nativeEvent.submitter;

      switch (submitter.getAttribute("data-action")) {
        case "add-variant":
          handleAddNewVariant();
          return;
        case "move":
          const index = submitter.getAttribute("data-variant");
          handleMoveVariant(index);
          return;
        default:
          break;
      }

      const _variants = variants.map((variant) => {
        delete variant.active;
        return variant;
      });
      handleNext(_variants, "variants");
  }, [values, stepData]);

  const handleOnPrev = useCallback(() => {
    handlePrev(variants, "variants"); //(Object value, Object key)
  }, [variants, values]);

  const validateElements = (values) => {
    stepData.widget.layouts[stepData.layout - 1].customizer.map(
      (customizerItem, index) => {
        validateElement(index, values, false);
      }
    );
  };

  const validateElement = (index, values, strict) => {
    let _validationErrors = validationErrors[index] || {};
    const widgetLayout = stepData.widget.layouts[stepData.layout - 1]
    const customizerItem = widgetLayout.customizer[index]

    if (customizerItem.fields) {
      const requiredFields = customizerItem.fields.map((field) => {
        if (field.required) {
          return field;
        }
      })
      requiredFields.map((field) => {
        if (field) {
          if (!values[field.name]) {
            if (!strict) {
              _validationErrors[field.name] = {
                message: "This is mandatory",
              };
            }
          } else if (!validateField(values[field.name], field.type)) {
            if (!strict) {
              _validationErrors[field.name] = {
                message: "This is invalid",
              };
            }
          } else {
            delete _validationErrors[field.name];
          }
        }
      });
  
      setValidationErrors((prev) => {
        const prevState = {...prev}
        if (!Object.keys(_validationErrors).length) {
          delete prevState[index]
          return prevState
        }
        return ({ ...prev, [index]: _validationErrors })
      });
    }

    if (customizerItem.sections) {
      for (let i = 0; i < customizerItem.sections.length; i++) {
        const section = customizerItem.sections[i];
        const requiredFields = section.fields.map((field) => {
          if (field.required) {
            return field;
          }
        })

        requiredFields.map((field) => {
          if (field) {
            if (!values[field.name]) {
              if (!strict) {
                if (!_validationErrors[i]) _validationErrors[i] = {}
                _validationErrors[i][field.name] = {
                  message: "This is mandatory",
                };
              }
            } else if (!validateField(values[field.name], field.type)) {
              if (!strict) {
                if (!_validationErrors[i]) _validationErrors[i] = {}
                _validationErrors[i][field.name] = {
                  message: "This is invalid",
                };
              }
            } else {
              if (_validationErrors[i]) {
                delete _validationErrors[i][field.name];
                if (!Object.keys(_validationErrors[i]).length) {
                  delete _validationErrors[i]
                }
              }
            }
          }
        });
      }
      setValidationErrors((prev) => {
        const prevState = {...prev}
        if (!Object.keys(_validationErrors).length) {
          delete prevState[index]
          return prevState
        }
        return ({ ...prev, [index]: _validationErrors })
      });
      //   ({ ...prev, [index]: _validationErrors })
      // });
    }
  };

  const handleOnBeforeChange = (index, values) => {
    validateElement(index, values);
    setShowValidators((prev) => [
      ...prev.slice(0, index),
      index,
      ...prev.slice(index + 1),
    ]);
  };

  const handleGenerate = async(schemaName, schemaIndex) => {
    const layoutIndex = stepData.layout
    const widgetId = stepData.widget._id
    const widgetSlugs = ["trust-factors", "call-to-action", "promotional-banner"]
    const isSpecial = widgetSlugs.includes(stepData.widget.slug)

    try {
      const data = await generateWidgetCustomization(widgetId, layoutIndex, isSpecial ? schemaIndex + 1 : null)
      setValues(prev => ({...prev, ...data}))
      setVariants((prev) =>
        prev.map((variant) => {
          if (variant.active) return { ...variant, ...data };
          else return variant;
        })
      );
    } catch (error) {
      console.error(error)
      Notify.error({title: 'Something wrong with generation :( Please, try later'})
    }
  }

  return (
    <form className="an-row w-100 h-100" onSubmit={handleSubmit}>
      <CustomizationOptionsContainer className="an-col_3_12">
        <CustomizationForms
          key={`customization-form${variants.length}`}
          values={values || {}}
          schemas={stepData.widget.layouts[stepData.layout - 1].customizer}
          validations={showValidators}
          validationErrors={validationErrors}
          validateElement={validateElement}
          onChange={handleChange}
          onClick={handleGenerate}
          onElementBeforeChange={handleOnBeforeChange}
        />
      </CustomizationOptionsContainer>
      <CustomizationContainer
        key={`customization-container`}
        className="an-col_7_12"
        previewHTML={previewHTML}
        loading={loading}
      >
        <WidgetVariantsGallery
          variants={variants}
          onRemove={handleRemove}
          addDisabled={!isWidgetCustomizerValid}
        />
      </CustomizationContainer>
      <StepWizardNavigation
        onPrev={handleOnPrev}
        nextDisabled={!isWidgetCustomizerValid}
      />
    </form>
  );
};

export default WidgetCustomization;
