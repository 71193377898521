import React from 'react'
import OutsideClickHandler from "react-outside-click-handler";

const Suggestions = ({suggestions, setSuggestions, setText, suggestionSelected}) => {
  if (suggestions.length === 0) {
    return null;
  }
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setSuggestions([]);
        setText("");
      }}
    >
      <ul>
        {suggestions.map((item, index) => (
          <li
            className="ui-caption-regular color-secondary-colour-secondary-7"
            key={index}
            onClick={() => suggestionSelected(item)}
          >
            {item}
          </li>
        ))}
      </ul>
    </OutsideClickHandler>
  );
}

export default Suggestions;