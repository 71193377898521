import Card from "./Card";
import "./List.scss";

const List = props => {
  return (
    <div className="campaigns-list">
      {props.campaigns.map((campaign, key) => (
        <Card
          campaign={campaign} 
          key={key}
          disabled={props.performanceDisabled}
        />
      ))}
    </div>
  )
}

export default List;