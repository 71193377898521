import React, { useState } from "react";

import CustomizationElement from "../../customization-element/CustomizationElement";
import { CustomizationFormFields } from "./CustomizationFormFields";

const CustomizationSections = ({
  validationErrors,
  customizerIndex,
  sections, 
  size,
  values,
  onChange,
  disableDebounce,
  onClick,
  validateElement,
}) => {
  const [_activeIndex, setActiveIndex] = useState(-1)
  const [validators, setValidators] = useState(() => {
    if (validationErrors[customizerIndex]) return Object.keys(validationErrors[customizerIndex]).map(Number)
    return []
  })

  const getValidatorFlag = (sectionIndex) => {
    if (validationErrors && validationErrors[customizerIndex]) {
      const sectionErrors = validationErrors[customizerIndex][sectionIndex]
      if (sectionErrors) return false
      return true
    }

    return null
  }

  const handleOnBeforeChange = (index, values) => {
    validateElement(customizerIndex, values);
    setValidators((prev) => [
      ...prev.slice(0, index),
      index,
      ...prev.slice(index + 1),
    ]);
  };

  const handleClick = (sectionIndex) => {
    if (_activeIndex !== -1) {
      handleOnBeforeChange(_activeIndex, values);
    }


    if (sectionIndex === _activeIndex) {
        setActiveIndex(-1);
        return;
    }
  
    setActiveIndex(sectionIndex);
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
      {sections.map((section, sectionIndex) => {
        const fields = section.fields || []
        return (
          <CustomizationElement
              key={`CustomizationSubElement_${sectionIndex}`}
              title={section.title}
              description={section.description}
              icon={section.icon}
              onClick={() => handleClick(sectionIndex)}
              open={_activeIndex === sectionIndex}
              tooltip={section.tooltip}
              subtitle={section.subtitle}
              size={size}
              validation={getValidatorFlag(sectionIndex)}
          >
              {!!fields.length && fields.map((field, key) => {
                return (
                  <CustomizationFormFields
                    key={`subSubSchema-${key}`}
                    index={sectionIndex}
                    genIndex={customizerIndex}
                    field={field}
                    validations={validators}
                    validationErrors={validationErrors[customizerIndex]}
                    values={values}
                    onChange={onChange}
                    disableDebounce={disableDebounce}
                    onClick={onClick}
                  />
                );
              })}
          </CustomizationElement>
        )
      })}
    </div>
  );
}

export default CustomizationSections;