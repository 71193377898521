import React from "react";
import styles from "./WidgetVariantsGallery.module.scss";
import {
  CirclePlus,
  TrashCan,
  CircleChevronLeft,
  CircleChevronRight,
} from "akar-icons";
import { Tooltip } from "react-tooltip";
import UiSmallRegular from "../../text/UiSmallRegular";
import classNames from "classnames";

const WidgetVariantsGallery = ({ variants = [], onRemove = () => {}, addDisabled }) => {
  const currentIndex = variants.findIndex((variant) => variant.active);

  const canRemove = (variant) => {
    return variant.active && variants.length > 1;
  };

  const handleOnClick = (e) => {
    if (e.currentTarget.getAttribute("data-action") === "remove") {
      const index = e.currentTarget.getAttribute("data-variant");
      onRemove(Number(index));
      return;
    }
  };

  return (
    <div className="mt-8">
      <div className={styles["widget-nav-row"]}>
        <button
          className={styles["widget-nav-btn"]}
          disabled={currentIndex === 0}
          data-action="move"
          data-variant={currentIndex - 1}
        >
          <CircleChevronLeft strokeWidth={2} size={24} />
        </button>

        <div className={styles["variants"]}>
          {variants.map((variant, key) => (
            <button
              type={canRemove(variant) ? "button" : "submit"}
              className={classNames(styles["variant"], {
                [`${styles["variant_current"]}`]: variant.active,
              })}
              key={key}
              data-variant={`${key}`}
              data-action={canRemove(variant) ? "remove" : "move"}
              disabled={variant.active && variants.length <= 1}
              onClick={handleOnClick}
            >
              {variant.active && variants.length > 1 && (
                <TrashCan size="24" color="#ffffff" />
              )}

              {!variant.active && variant.image && (
                <img src={variant.image} alt={`variant-${key}`} />
              )}
            </button>
          ))}
          {!addDisabled && (
            <>
              <button
                className={classNames(styles["variant"], styles["variant_add"])}
                data-tooltip-id="add-variant"
                data-tooltip-place="bottom"
                data-action="add-variant"
                data-tooltip-html={`<div class="ui-extra-small-medium text-uppercase color-gray-gray-9">Add new variant</div><div class="ui-small-regular mt-8 color-secondary-colour-secondary-7">Add variety and flexibility with variants for a dynamic widget experience. Widgets should always have one variant active.</div>`}
              >
                <CirclePlus size="24" color="#9254de" />
              </button>
              <Tooltip
                id="add-variant"
                className="an-tooltip an-tooltip_w_300 an-tooltip_white"
                arrowColor="transparent"
              />
            </>
          )}
        </div>

        <button
          className={styles["widget-nav-btn"]}
          disabled={currentIndex === variants.length - 1}
          data-action="move"
          data-variant={currentIndex + 1}
        >
          <CircleChevronRight strokeWidth={2} size={24} />
        </button>
      </div>
      <UiSmallRegular
        text={variants.length === 1 ? `${addDisabled ? '' : 'You can add variants for this widget.'}` : `You have ${variants.length} variants for this widget`}
        margin="8"
        className="text-center color-secondary-colour-secondary-7"
      />
    </div>
  );
};

export default WidgetVariantsGallery;
