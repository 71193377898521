import { useState, useContext, useEffect } from "react";
import classNames from "classnames";
import { MoreVerticalFill } from "akar-icons";
import OutsideClickHandler from "react-outside-click-handler";
import Modal from "react-modal";
import { Cross } from "akar-icons";
import axios from "axios";
import UiBodyRegular from "../text/UiBodyRegular";
import UiCaptionRegular from "../text/UiCaptionRegular";
import UiHeading from "../text/UiHeading";
import FormControl from "../forms/FormControl";
import Preloader from "../../preloader/Preloader";
import UiButton from "../buttons/UiButton";
import { AuthContext } from "../../context/AuthContext";
import illustration2 from "../../static/images/store-settings/Illustration (2).svg";
import styles from "./Employee.module.scss";
import UiCaptionMedium from "../text/UiCaptionMedium";

Modal.setAppElement('#root');

const Employee = props => {
  const [employee, setEmployee] = useState(props.user);
  const initials = `${employee.firstName.slice(0, 1)} ${employee.lastName.slice(0, 1)}`;
  const [showDropdown, setShowDropdown] = useState(false);
  const lastLogin = new Date(employee.lastLogin);
  const userType = employee.permission === 'employee' ? 'employee' : 'admin';
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [cancelDisabled, setCancelDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const token = localStorage.getItem('token');

  const initialData = {
    firstName: employee.firstName,
    lastName: employee.lastName,
    email: employee.email
  }

  const [data, setData] = useState(initialData);

  const outputLastLogin = lastLogin.toLocaleString('en-GB', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timezone: 'UTC'
  });

  const editUser = () => {
    setShowDropdown(false);
    setModalIsOpen(true);
  }

  const showDeleteModal = () => {
    setShowDropdown(false);
    setDeleteModalIsOpen(true);
  }

  const deleteUser = () => {
    setLoading(true);

    axios.delete(`/user/${props.user._id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      props.refresh();

      setLoading(false);
      setDeleteModalIsOpen(false);
    }).catch(err => console.error(err));
  }

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    axios.put(`/user/${props.user._id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(response => {
      setLoading(false);
      setEmployee(response.data);
      setModalIsOpen(false);
    }).catch(err => {
      setLoading(false);
      setErrors(err.response.data.errors);
    })
  }

  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value });

  useEffect(() => {
    let dataChanged = false;

    Object.keys(initialData).map(key => {
      if (initialData[key] !== data[key]) {
        dataChanged = true;
      }

      return dataChanged;
    });

    setCancelDisabled(!dataChanged);
  }, [data]);

  const reset = e => {
    e.preventDefault();
  }

  return (
    <div className={classNames('card card_16_8_16_16 card_shadow card_border card_overflow_visible', styles['employee'])}>
      <div className={styles['employee__data']}>
        <div className={classNames(styles['employee__icon'], styles['employee__icon_admin'])}>
          {employee.avatar ? (
            <img
              src={employee.avatar}
              alt={initials}
              className={styles['employee__avatar']}
            />
          ) : (
            <UiHeading
              text={initials}
              className="text-uppercase"
              element="h4"
              desktop="other-headings-h4-bold"
            />
          )}
        </div>
        <div>
          <UiBodyRegular text={`${employee.firstName} ${employee.lastName}`} className="color-gray-gray-9" />
          <UiCaptionRegular
            text={employee.email}
            className="color-secondary-colour-secondary-7"
          />
          {employee.lastLogin ? (
            <UiCaptionRegular
              text={`Last login was on ${outputLastLogin} UTC.`}
              className="color-secondary-colour-secondary-7"
            />
          ) : ''}
        </div>
      </div>
      <div className="position-relative">
        <OutsideClickHandler onOutsideClick={() => setShowDropdown(false)}>
          <button
            className="action-btn"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <MoreVerticalFill
              size="16"
              color="#d9d9d9"
            />
          </button>
          {showDropdown ? (
            <div className="dropdown">
              <button className="dropdown__item ui-caption-regular" onClick={editUser} disabled={employee.permission === 'superadmin' && user.permission !== 'superadmin'}>Edit {userType}</button>
              <button className="dropdown__item ui-caption-regular" onClick={showDeleteModal} disabled={employee.permission === 'superadmin'}>Delete {userType}</button>
            </div>
          ) : ''}
        </OutsideClickHandler>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="an-modal"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          <div className="an-modal__title">
            <UiHeading
              element="h6"
              desktop="other-headings-h6-bold"
              text={`Edit ${userType}`}
            />
          </div>
          <button
            onClick={() => setModalIsOpen(false)}
            className="an-modal__close"
          >
            <Cross
              size="24"
              color="#d9d9d9"
            />
          </button>
        </div>
        <form
          className="an-modal__body an-modal__body_mh_0"
          onSubmit={handleSubmit}
        >
          <div className="mt-32 pt-32 bt-1">
            <FormControl
              type="text"
              name="firstName"
              id="first_name"
              label="First name"
              labelClassName="ui-caption-medium"
              labelMargin="4"
              placeholder="First Name"
              required={true}
              value={data.firstName}
              onChange={handleChange}
              validationError={errors.firstName}
            />
            <FormControl
              type="text"
              name="lastName"
              id="last_name"
              label="Last name"
              labelClassName="ui-caption-medium"
              labelMargin="4"
              placeholder="Last Name"
              margin="24"
              required={true}
              onChange={handleChange}
              value={data.lastName}
              validationError={errors.lastName}
            />
            <FormControl
              type="email"
              name="email"
              id="email"
              label="Email"
              labelClassName="ui-caption-medium"
              labelMargin="4"
              placeholder="Email"
              margin="24"
              required={true}
              onChange={handleChange}
              value={data.email}
              validationError={errors.email}
            />
          </div>
          <div className="mt-32 pt-32 bt-1 flex-right gap-8">
            <UiButton
              size="medium"
              design="secondary"
              text="Cancel"
              disabled={cancelDisabled}
              onClick={reset}
            />
            <UiButton
              size="medium"
              text="Save changes"
            />
          </div>
        </form>
        {loading ? (
          <Preloader
            absolute={true}
            overflow={true}
          />
        ) : ''}
      </Modal>
      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={() => setDeleteModalIsOpen(false)}
        className="an-modal"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          <div className="an-modal__title text-center">
            <img
              src={illustration2}
              alt="Remove user"
              className="responsive-img"
            />
            <UiHeading
              text="Remove user"
              desktop="other-headings-h6-bold"
              element="h6"
            />
            <UiCaptionRegular
              text="Are you sure you want to remove this employee from your team? This action cannot be undone."
              margin="8"
              className="color-secondary-colour-secondary-7"
            />
          </div>
          <button
            onClick={() => setDeleteModalIsOpen(false)}
            className="an-modal__close"
          >
            <Cross
              size="24"
              color="#d9d9d9"
            />
          </button>
        </div>
        <div className="an-modal__body an-modal__body_mh_0 mt-32 flex-right gap-8">
          <UiButton
            size="medium"
            design="secondary"
            text="Cancel"
            onClick={() => setDeleteModalIsOpen(false)}
          />
          <UiButton
            size="medium"
            text="Yes, remove"
            onClick={deleteUser}
          />
        </div>
        {loading ? (
          <Preloader
            absolute={true}
            overflow={true}
          />
        ) : ''}
      </Modal>
    </div>
  )
}

export default Employee;