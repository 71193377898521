import { Header, HeaderRow, HeaderCell, Body, Row, Cell } from "@table-library/react-table-library/table";

const DefaultTable = ({
	tableList,
	order,
	changeOrder,
}) => {
	return (
		<>
			<Header>
				<HeaderRow>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.firstName ? ' activity-table__th_active' : ''}`}>Name</HeaderCell>
					<HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.email ? ' activity-table__th_active' : ''}`}>Email</HeaderCell>
					<HeaderCell onClick={() => changeOrder('action')} className={`activity-table__th${order?.action ? ' activity-table__th_active' : ''}`}>Action</HeaderCell>
					<HeaderCell onClick={() => changeOrder('date')} className={`activity-table__th table-align-right${order?.date ? ' activity-table__th_active' : ''}`}>Time</HeaderCell>
				</HeaderRow>
			</Header>
			<Body>
				{tableList.map(item => (
					<Row
						item={item}
						key={item._id}
					>
						<Cell>{item.firstName} {item.lastName}</Cell>
						<Cell>{item.email}</Cell>
						<Cell>{item.action}</Cell>
						<Cell className="table-align-right">{item.time}</Cell>
					</Row>
				))}
			</Body>
		</>
	);
}

export default DefaultTable;