import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UiHeading from "../../ui-elements/text/UiHeading";
import UiCaptionRegular from "../../ui-elements/text/UiCaptionRegular";
import UiButton from "../../ui-elements/buttons/UiButton";
import ModalLayout from "../../modal/Layout";
import FormControl from "../../ui-elements/forms/FormControl";
import Preloader from "../../preloader/Preloader";
import VerificationToken from "../../store-settings/VerificationToken";

const DomainAccountsAdd = () => {
  const [nextDisabled, setNextDisabled] = useState(true);
  const [domain, setDomain] = useState('');
  const [showSenderEmail, setShowSenderEmail] = useState(false);
  const [senderEmail, setSenderEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(true);
  const [verificationToken, setVerificationToken] = useState(null);
  const [nextLabel, setNextLabel] = useState('Very my domain');
  const [domainId, setDomainId] = useState(null);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('/domains', {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    }).then(response => {
      if (response.data.length) {
        navigate('/store-settings/domain-accounts');
      }
    }).catch(err => console.error(err));
  }, []);

  const next = () => {
    if (verificationToken) {
      setLoading(true);

      axios.post(`/domains/${domainId}/verify`, {}, {
        headers: {
          Authorization: `Bearer: ${token}`
        }
      }).then(() => {
        setLoading(false);
        navigate('/store-settings/domain-accounts');
      }).catch(error => {
        console.error(error);

        setLoading(false);
      });
    } else {
      setLoading(true);

      axios.post('/domains', {
        domain: domain,
        name: senderEmail
      }, {
        headers: {
          Authorization: `Bearer: ${token}`
        }
      }).then(response => {
        setVerificationToken(response.data.verificationToken);
        setDomainId(response.data._id);
        setNextLabel('I’ve done this');
        setErrors({});
        setLoading(false);
      }).catch(err => {
        console.error(err);

        if (err.response.data.name === 'ValidationError') {
          setErrors(err.response.data.errors);
        }

        setNextDisabled(true);
        setLoading(false);
      })
    }
  }

  const onDomainChange = e => {
    setDomain(e.target.value);
    setShowSenderEmail(validateDomain(e.target.value));
  }

  const onSenderEmailChange = e => {
    setSenderEmail(e.target.value);
  }

  useEffect(() => {
    setNextDisabled(!(showSenderEmail && senderEmail));
  }, [showSenderEmail, senderEmail, domain]);

  const validateDomain = name => {
    const regex = /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,6}$/;

    return regex.test(name);
  }

  return (
    <ModalLayout backTo="/store-settings/domain-accounts">
      <div>
        <div className="onboarding-question text-center pt-40">
          {verificationToken ? <VerificationToken token={verificationToken} /> : (
            <>
              <UiHeading
                text="Link existing domain"
                desktop="other-headings-h6-bold"
              />
              <UiCaptionRegular
                margin="8"
                className="color-secondary-colour-secondary-7"
                text={<>In order to use our service you should verify that your are the owner of the domain that you are going to use. <br />Please enter your domain name and follow the instructions.</>}
              />
              <div className="onboarding-question__answers mt-64 maxw maxw_431 text-left">
                <FormControl
                  type="text"
                  name="domain"
                  id="domain"
                  label="What is your domain?"
                  placeholder="Ex. yourdomain.com"
                  labelMargin="4"
                  labelClassName="ui-caption-medium"
                  onChange={onDomainChange}
                  value={domain}
                  validationError={errors.name}
                />
                {showSenderEmail ? (
                  <div className="mt-32">
                    <FormControl
                      type="text"
                      name="senderEmail"
                      id="senderEmail"
                      label="What is your sender email?"
                      placeholder="info"
                      labelMargin="4"
                      labelClassName="ui-caption-medium"
                      onChange={onSenderEmailChange}
                      value={senderEmail}
                      append={`@${domain}`}
                      validationError={errors.email}
                    />
                  </div>
                ) : ''}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="onboarding-questionnaire__bottom">
        <UiButton
          size="medium"
          elem="a"
          href="/store-settings/domain-accounts"
          text="Back"
          design="secondary"
          className="ui-button_mw_150"
        />
        <UiButton
          onClick={next}
          size="medium"
          text={nextLabel}
          design="secondary"
          className="ui-button_mw_150"
          disabled={nextDisabled}
        />
      </div>
      {
        loading ? (
          <Preloader
            absolute={true}
            overflow={true}
          />
        ) : ''
      }
    </ModalLayout >
  )
}

export default DomainAccountsAdd;