import { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip } from 'chart.js';
import { mergeDeep, getXAxis, getChartData, dayItems } from './tools';

import createChartTooltip from '../../ui-elements/charts/createCustomTooltip';
import SectionHead from '../../ui-elements/common/SectionHead';
import Switcher from '../../ui-elements/switcher/Switcher';

const ToolsPerformance = ({
	title,
	subTitle,
	Legend,
  withWrap=true,
  filter,
	data,
	type,
	yAxis,
	tooltipLabel,
	selectedCampaign,
	displayTooltipColors=true,
}) => {
	const defaultYAxis = {
		border: {
			display: false,
			dash: [3, 4]
		},
		grid: {
			color: '#d9d9d9',
			drawTicks: false,
		},
		ticks: {
			padding: 10,
			color: '#bfbfbf',
			font: {
				family: 'Poppins',
				size: 12,
				weight: 500,
				lineHeight: 1.75
			}
		}
	}

	ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);
	const [labels, setLabels] = useState(getXAxis(0, data, type))
	const [chartData, setChartData] = useState(getChartData(0, data, type));

	useEffect(() => {
		setLabels(getXAxis(0, data, type))
		setChartData(getChartData(0, data, type))
	}, [type])


	const handleChangeSwitcher = (index) => {
		const _chartData = getChartData(index, data, type)
		setChartData(_chartData)
	}

	if (!data) return null

	return (
		<div className={withWrap ? 'an-col card card_shadow card_br_24 card_24' : ''}>
			<SectionHead
				title={
					<>
						<div className="email-templates-subtitle-2-medium">{title}</div>
						<div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">{subTitle}</div>
					</>
				}
				filter={filter ?? null}
			/>
			<div className='an-row mt-24'>
				<div className='an-col an-col_28 pr-24 border-r'>
					<Legend />
				</div>
				<div className='an-col'>
					<Line
						className='performance-chart'
						data={{
							datasets: chartData,
							labels: labels
						}}
						options={{
							borderRadius: 6,
							cutout: 50,
							responive: true,
							clip: 10,
							plugins: {
								tooltip: {
									displayColors: displayTooltipColors,
									enabled: false,
									callbacks: {
										title: (tooltipItem) => {
											return tooltipItem[0].label
										},
										label: (tooltipItem) => {
											if (tooltipLabel) return tooltipLabel(tooltipItem, chartData)

											const label = tooltipItem.dataset.label
											const value = tooltipItem.raw
											return `${label}: ${value}`
										}
									},
									external: createChartTooltip
								}
							},
							scales: {
								x: {
									grid: {
										display: false,
										tickBorderDashOffset: 10,
									},
									border: {
										display: false,
									},
									ticks: {
										color: '#bfbfbf',
										stepSize: 1,
										font: {
											family: 'Poppins',
											size: 12,
											weight: 500,
											lineHeight: 1.75
										}
									}
								},
								y: mergeDeep(defaultYAxis, yAxis),
							}
						}}
					/>
					{type === 'day' && 
						<Switcher
							selectedCampaign={selectedCampaign}
							className='switcher-day'
							onChange={handleChangeSwitcher}
							items={dayItems}	
						/>
					}
				</div>
			</div>
		</div>
	)
}

export default ToolsPerformance