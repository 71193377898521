import UiHeading from "../ui-elements/text/UiHeading";
import IntroList from "./IntroList";
import UiButton from "../ui-elements/buttons/UiButton";
import "./Intro.scss";

const Intro = props => {
  return (
    <div className="onboarding-intro text-center">
      <div className="onboarding-intro__content">
        <UiHeading
          element="h1"
          desktop="email-templates-h1-semibold"
          text="Welcome to Anomate!"
        />
        <div className="email-templates-paragraph-1-regular mt-8 color-secondary-colour-secondary-7">Completing the onboarding process personalises Anomate, training our AI system to understand <br />your needs and deliver exceptional, tailored results. </div>
      </div>
      <IntroList />
      <div className="onboarding-intro__bottom pt-6 pb-6">
        <UiButton
          onClick={props.setQuestionsState}
          size="medium"
          text="Start!"
          design="secondary"
          className="ui-button_mw_150"
        />
      </div>
    </div>
  )
}

export default Intro;