import React, { useState, useEffect } from "react";
import { UnmountClosed, Collapse } from "react-collapse";
import classNames from "classnames";
import styles from "./CustomizationElement.module.scss";
import { ChevronDown, Info, CircleAlert, CircleCheck } from "akar-icons";
import MdiIcon from "@mdi/react";
import { Icon } from "@iconify/react";
import { AKAR_ICON_MAP, MDI_ICON_MAP } from "../icon-map/IconMap";
import SVG from "react-inlinesvg";
import UiCaptionRegular from "../text/UiCaptionRegular";
import CustomTooltip from "./CustomTooltip";

const CustomizationElement = ({
  index,
  title,
  description,
  icon,
  children,
  open,
  tooltip,
  onClick,
  subtitle,
  size,
  bodyClassName,
  validation,
  errorMessage,
  isPreview,
  hideArrowIcon,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClick = () => {
    if (disabled) return;

    if (children) {
      setIsOpen(!isOpen);
    }
    onClick && onClick(index);
  };

  const renderIcon = () => {
    if (icon?.type === "mdi" && MDI_ICON_MAP[icon.value]) {
      return <MdiIcon path={MDI_ICON_MAP[icon.value]} />;
    }

    if (icon?.type === "AkarIcons" && AKAR_ICON_MAP[icon.value]) {
      const IconComponent = AKAR_ICON_MAP[icon.value];
      return <IconComponent />;
    }

    if (icon?.type === "ic") {
      return <Icon icon={`ic:${icon.value}`} />;
    }

    if (icon?.type === "custom") {
      return <SVG src={icon.value} />;
    }

    return "";
  };

  return (
    <div
      className={classNames(
        styles["base"],
        { [`${styles["open"]}`]: isOpen },
        { [styles[`${size}`]]: size },
        { [styles[`preview`]]: isPreview },
        { [styles[`collapsible`]]: children },
        { [styles[`disabled`]]: disabled }
      )}
    >
      <div
        className={classNames(styles["header"], {
          [`${styles["valid"]}`]: validation,
        })}
        onClick={handleClick}
      >
        <div className={classNames(styles["icon"])}>{renderIcon()}</div>
        <div className={styles["content"]}>
          <div className={classNames(styles["title"], "ui-caption-regular")}>
            <span>{title}</span>
            {/* FOR PROD! */}
            {/* {tooltip && <CustomTooltip tooltip={tooltip} />} */}
          </div>
          <div
            className={classNames(styles["description"], "ui-small-regular")}
          >
            {description}
          </div>
        </div>
        {validation === false ? (
          <CircleAlert size="24" color="#ff4d4f" />
        ) : validation === true ? (
          <CircleCheck size="24" color="#73d13d" />
        ) : (
          !hideArrowIcon && (
            <span className={styles["chevron-down"]}>
              <ChevronDown size="24" />
            </span>
          )
        )}
      </div>
      {!isPreview && children && (
        <UnmountClosed isOpened={isOpen}>
          <div
            className={classNames(styles["body"], "an-layout", bodyClassName)}
          >
            {subtitle && (
              <UiCaptionRegular
                text={subtitle}
                className={`color-secondary-colour-secondary-7`}
              />
            )}
            {children}

            {validation === false && errorMessage && (
              <div className="form-control__error ui-caption-regular">
                {errorMessage}
              </div>
            )}
          </div>
        </UnmountClosed>
      )}
    </div>
  );
};

export default CustomizationElement;
