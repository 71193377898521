import { AuthContext } from "../context/AuthContext";
import { Navigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import Preloader from "../preloader/Preloader";

const WithAuthorization = ({ children }) => {
  const token = localStorage.getItem("token");
  const {
    user,
    shopData,
    isLoggedIn,
    updateSession,
    loading,
    hasNoPermission,
  } = useContext(AuthContext);

  useEffect(() => {
    if (!isLoggedIn) return;

    if ((!user || !shopData) && token) {
      updateSession(token);
    }
  }, [token, user, shopData, isLoggedIn]);

  if (isLoggedIn) {
    if (loading || !user) {
      return <Preloader />;
    } else {
      if (hasNoPermission(user)) {
        return <Navigate to="/access-denied" replace />;
      } else {
        return children;
      }
    }
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default WithAuthorization;
