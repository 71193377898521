import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import useStep from "../hooks/useStep";
import StepWizardNavigation from "../ui-elements/step-wizard/StepWizardNavigation";
import { getShopWidgets } from "../api/Shop";
import WidgetPreview from "../widgets/WidgetPreview";
import FormControl from "../ui-elements/forms/FormControl";
import Preloader from "../preloader/Preloader";
import WidgetEmptyStep from "./WidgetEmptyStep";
import { getSortedWidgets } from "./tools";

const WidgetSetup = () => {
  const { currentStepValue, setHideStepHeader, handlePrev, handleNext } =
    useStep();
  const { campaignSlug } = useParams();
  const [widgets, setWidgets] = useState(null);
  const [filteredWidgets, setFilteredWidgets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedWidgets, setSelectedWidgets] = useState(currentStepValue || []);

  useEffect(() => {
    fetchWidgets();
  }, []);

  useEffect(() => {
    if (widgets && !widgets.length) {
      setHideStepHeader(widgets.length <= 0);
    }
  }, [widgets]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleNext(getSortedWidgets(selectedWidgets.filter(id => id !== 'emailBody'), widgets));
    setHideStepHeader(false);
  };

  const fetchWidgets = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await getShopWidgets(null, campaignSlug);
      const _widgets = response.filter(
        (widget) => widget.status === "published"
      );
      setWidgets(_widgets);
      setFilteredWidgets(_widgets);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleFilterWidgets = (value) => {
    if (!value) {
      setFilteredWidgets(widgets);
      return;
    }

    const _widgets = widgets.filter((widget) =>
      widget.name.toLowerCase().includes(value.toLowerCase())
    );
    setSelectedWidgets([]);
    setFilteredWidgets(_widgets);
  };

  const debounceFn = useCallback(_.debounce(handleFilterWidgets, 300), [
    widgets,
    setFilteredWidgets,
    setSelectedWidgets,
  ]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    debounceFn(e.target.value);
  };

  const handleWidgetClick = (id) => {
    const index = selectedWidgets.indexOf(id);

    if (index >= 0) {
      setSelectedWidgets((prev) => [
        ...prev.slice(0, index),
        ...prev.slice(index + 1),
      ]);

      return;
    }

    setSelectedWidgets((prev) => [...prev, id]);
  };

  const handlePrevClick = () => {
    handlePrev(selectedWidgets);
    setHideStepHeader(false);
  };

  if (!widgets) return <Preloader />;

  if (widgets.length <= 0) {
    return (
      <form className="h-100" onSubmit={handleSubmit}>
        <WidgetEmptyStep />
        <StepWizardNavigation onPrev={handlePrevClick} />
      </form>
    );
  } else {
    return (
      <form onSubmit={handleSubmit}>
        <div className="an-layout an-layout_gap_16">
          <div className="an-row">
            <div className="an-col_61 m-auto">
              <FormControl
                value={searchValue}
                placeholder="Search"
                onChange={handleSearch}
                ssName="form-control__input_search"
              />
            </div>
          </div>

          <div className="an-row an-row_flex-wrap">
            {filteredWidgets.map((widget, key) => (
              <WidgetPreview
                widget={widget}
                key={key}
                selection
                checked={selectedWidgets.includes(widget._id)}
                onClick={handleWidgetClick}
              />
            ))}
          </div>
          {loading && <Preloader absolute overflow />}
        </div>

        <StepWizardNavigation onPrev={handlePrevClick} />
      </form>
    );
  }
};

export default WidgetSetup;
