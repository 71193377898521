import { useState, useEffect } from "react";
import axios from "axios";
import Preloader from "../preloader/Preloader";
import UiButton from "../ui-elements/buttons/UiButton";
import Employee from "../ui-elements/employee/Employee";

const Admins = props => {
  const [admins, setAdmins] = useState([]);
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    refresh();
  }, [props.added]);

  const refresh = () => {
    axios.get('/shop/admins', {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    }).then(response => {
      setAdmins(response.data);
      setLoading(false);
    }).catch(err => {
      console.error(err);

      setLoading(false);
    });
  }

  return (
    <div className="card card_24 card_mh_230 card_shadow">
      {loading ? (
        <Preloader
          overflow={true}
          absolute={true}
        />
      ) : (
        <div className="an-layout an-layout_gap_32">
          {admins.map((admin, key) => (
            <Employee
              user={admin}
              key={key}
              refresh={refresh}
            />
          ))}
          <div className="text-right">
            <UiButton
              text="Add admin"
              design="secondary"
              size="medium"
              onClick={() => props.add('admin')}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Admins;