import UiHeading from "../ui-elements/text/UiHeading";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import Table from "../ui-elements/table/Table";

const VerificationToken = props => {
  return (
    <>
      <UiHeading
        text="Add the following records to your DNS provider"
        desktop="other-headings-h6-bold"
      />
      <UiCaptionRegular
        margin="8"
        className="color-secondary-colour-secondary-7"
        text={<>To add DNS records, copy the provided records below, log in to your DNS provider's website, <br />navigate to DNS management, select the record type, and fill in the information.</>}
      />
      <div className="onboarding-question__answers mt-64 maxw maxw_825 text-left">
        <Table
          ths={['Type', 'Name', 'Value']}
          trs={
            [
              [
                {
                  text: 'TXT',
                  copy: false
                },
                {
                  text: '@',
                  copy: false
                },
                {
                  text: props.token,
                  copy: true
                }
              ]
            ]
          }
        />
      </div>
    </>
  )
}

export default VerificationToken;