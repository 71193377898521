import { useState, useEffect, useContext } from "react";
import FormControl from "../../ui-elements/forms/FormControl";
import './Filters.scss';
import { AuthContext } from "../../context/AuthContext";

const PriceRangeFilter = ({ onChange, filters }) => {
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const { shopData, updateShop } = useContext(AuthContext);

  useEffect(() => {
    setFrom('');
    setTo('');

    filters.map(filter => {
      if (filter._id === 'from') {
        setFrom(parseInt(filter.value));
      } else if (filter._id === 'to') {
        setTo(parseInt(filter.value));
      }
    });
  }, [filters]);

  const currencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: shopData.currency,
    maximumFractionDigits: 0
  });

  const handleChange = e => {
    let updatedFilters = filters;
    const index = filters.findIndex(item => item._id === e.target.name);

    if (index !== -1) {
      updatedFilters.splice(index, 1);
    }

    if (e.target.value) {
      updatedFilters = [...filters, {
        value: e.target.value,
        _id: e.target.name,
        name: `${e.target.name} ${currencyFormatter.format(e.target.value)}`
      }];
    }

    if (e.target.name === 'from') {
      setFrom(e.target.value);
    } else if (e.target.name === 'to') {
      setTo(e.target.value);
    }
    
    onChange('price', updatedFilters);
  }

  return (
    <>
      <div className="email-templates-caption-medium">Add price range</div>
      <div className="an-row an-row_gap_8 mt-4">
        <div className="an-col an-col_50">
          <FormControl
            label="From"
            type="number"
            name="from"
            id="from"
            labelMargin="4"
            placeholder="Ex. 10"
            labelClassName="ui-caption-regular"
            onChange={handleChange}
            value={from}
          />
        </div>
        <div className="an-col an-col_50">
          <FormControl
            label="To"
            name="to"
            id="to"
            type="number"
            labelMargin="4"
            placeholder="Ex. 100"
            labelClassName="ui-caption-regular"
            onChange={handleChange}
            value={to}
          />
        </div>
      </div>
    </>
  )
}

export default PriceRangeFilter;