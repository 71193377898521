import React, { useState, useRef, useEffect } from 'react'

import UiButton from '../buttons/UiButton';
import DropdownContent from './DropdownContent';

import './Dropdown.scss';

const Dropdown = ({ data, onSelect, noDataText='No data', disabled }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handleClick = (e) => {
    if (!e.target.closest(`.${ref.current.className}`) && open) {
      setOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  })

  return (
    <div ref={ref} className='ui-dropdown'>
      <UiButton
        design="secondary"
        text={'Select question'}
        size="small"
        onClick={() => setOpen(open => !open)}
        disabled={disabled}
      />
      {open && 
        <DropdownContent 
          data={data} 
          setOpen={setOpen} 
          onSelect={onSelect}
          noDataText={noDataText}
        />
      }
    </div>
  );
}

export default Dropdown;