import { useParams } from "react-router-dom";
import { useContext, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SectionHead from "../../ui-elements/common/SectionHead";
import { CampaignContext } from "../../context/CampaignContext";
import UiHeading from "../../ui-elements/text/UiHeading";
import UiButton from "../../ui-elements/buttons/UiButton";
import Preloader from "../../preloader/Preloader";
import CampaignStatus from "../../campaigns/overviews/CampaignStatus";
import CampaignOverviews from "./CampaignOverviews";
import image from "../../static/images/campaign/Image.svg";

const OverviewPage = ({ setFullHeight }) => {
  const { campaignSlug } = useParams();
  const { campaigns, shopCampaigns } = useContext(CampaignContext);
  const shopCampaign = useMemo(() => shopCampaigns.filter((campaign) => campaign.slug === campaignSlug), [shopCampaigns, campaignSlug])
  const status = useMemo(() => shopCampaign.length ? shopCampaign[0].status : '', [shopCampaign])
  const navigate = useNavigate();
  const location = useLocation()
  // const [loading, setLoading] = useState(true);
  const campaign = campaigns.find((currentCampaign) => currentCampaign.slug === campaignSlug);

  const handleCreateMode = () => {
    setFullHeight(true);
    navigate(`${location.pathname}/create`)
  }

  // if (loading) return <Preloader />;
  
  return (
    <>
      <SectionHead
        align="center"
        title={
          <div className="pr-48">
            <UiHeading
              text={campaign.name}
              desktop="email-templates-subtitle-2-bold"
            />
            {campaign.overviewText && (
              <div
                className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4"
                dangerouslySetInnerHTML={{ __html: campaign.overviewText }}
              />
            )}
          </div>
        }
        filter={
          <>
            {!!shopCampaign && campaign?.single && status && <CampaignStatus status={status} shopCampaign={shopCampaign[0]}/>}
          </>
        }
      />
      {shopCampaign.length ? (
        <CampaignOverviews campaignSlug={campaignSlug} shopCampaign={shopCampaign} handleCreateMode={handleCreateMode} />
      ) : (
        <div className="card card_br_24 card_24 card_shadow card_align_center card_h_100 card_flex text-center">
          <div className="card__overflow">
            <UiHeading
              desktop="email-templates-subtitle-1-semibold"
              text={campaign?.comingSoon ? 'Building Something Amazing' : 'How would you like to create your campaign?'}
              element="h2"
            />
            <div className="email-templates-paragraph-1-regular mt-8 color-secondary-colour-secondary-7">{campaign?.comingSoon ? <>We're hard at work creating new functionalities that will enhance your experience. Stay tuned <br />for updates as we continue to innovate and improve our platform. Thank you for your patience and support</> : <>Choose AI-generated to auto-fill all email content—editable anytime. Or start manually, <br /> with AI help available upon request. Customise as you see fit!</>}</div>
            {campaign?.comingSoon ? (
              <img
                src={image}
                alt=""
                className="mt-40"
              />
            ) : (
              <div className="mt-40 pt-101 pb-101 button-group button-group_center button-group_gap_16">
                <UiButton
                  text="Generate manually"
                  design="secondary"
                  className="ui-button_mw_210"
                  elem="a"
                  href="generate"
                />
                <UiButton
                  text="Generate with AI"
                  className="ui-button_mw_210"
                  elem="a"
                  href="generate?mode=ai"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OverviewPage;
