import { useState, useEffect } from 'react'
import { ChevronRightSmall, ChevronLeftSmall } from 'akar-icons'

import UiButton from '../buttons/UiButton'

import './Switcher.scss'

const Switcher = ({ items, className, onChange, selectedCampaign }) => {
  const [index, setIndex] = useState(0)
  
  useEffect(() => {
    //reset when data chaged
    if (selectedCampaign) setIndex(0)
  }, [selectedCampaign])
  
  const handleClick = (direction) => {
    if (direction === 'prev') {
      setIndex(index - 1)
      onChange && onChange(index - 1)
    } else {
      setIndex(index + 1)
      onChange && onChange(index + 1)
    }
    
  }
	
	return(
		<div className={'switcher' + ` ${className}`}>
      <UiButton 
        className='switcher-prev'
        type="button"
        arrow
        hasIcon
        text={<ChevronLeftSmall />}
        design="secondary"
        onClick={() => handleClick('prev')}
        disabled={index === 0}
        size="medium"
		  />
      <div className='switcher-text'>{items[index]?.text ? items[index]?.text : items[index]}</div>
      <UiButton
        type="button"
        className='switcher-next'
        arrow
        hasIcon
        text={<ChevronRightSmall />}
        design="secondary"
        onClick={() => handleClick('next')}
        disabled={index === items.length - 1}
        size="medium"
      />
    </div>
	)
}

export default Switcher