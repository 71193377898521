import React from 'react'
import { useParams } from 'react-router-dom';

import ActivityTable from '../../ui-elements/activity-table/ActivityTable';

const Participants = () => {
	const { campaignSlug } = useParams();
  return (
    <ActivityTable
      slug={campaignSlug}
			type='participants'
			title='Participants'
    />
  )
}

export default Participants;