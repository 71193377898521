import { createContext, useState } from "react";
import {
  getAvailableCampaigns,
  getCampaigns,
  getShopCampaigns,
  getCampignsTools
} from "../api/Campaign";

export const CampaignContext = createContext();

const CampaignContextProvider = (props) => {
  const { children } = props;
  const [campaigns, setCampaigns] = useState([]);
  const [tools, setTools] = useState([]);
  const [availableCampaigns, setAvailableCampaigns] = useState([]);
  const [shopCampaigns, setShopCampaigns] = useState([]);
  const [availableCampaignsLoading, setAvailableCampaignsLoading] =
    useState(false);
  const [shopCampaignsLoading, setShopCampaignsLoading] = useState(false);
  const [campaignsLoading, setCampaignsLoading] = useState(false);

  const fetchCampaigns = async () => {
    if (campaignsLoading) return;
    setCampaignsLoading(true);
    try {
      const [campaigns, campaignsTools] = await Promise.all([getCampaigns(), getCampignsTools()])

      setTools(campaignsTools)
      setCampaigns(campaigns);
    } catch (e) {
      console.log("CampaignContextProvider: error", e);
    }
    setCampaignsLoading(false);
  };

  const fetchAvailableCampaigns = async () => {
    if (availableCampaignsLoading) return;
    setAvailableCampaignsLoading(true);
    try {
      const response = await getAvailableCampaigns();
      setAvailableCampaigns(response);
    } catch (e) {
      console.log("CampaignContextProvider: error", e);
    }
    setAvailableCampaignsLoading(false);
  };

  const fetchShopCampaigns = async () => {
    if (shopCampaignsLoading) return;
    setShopCampaignsLoading(true);
    try {
      const response = await getShopCampaigns();

      setShopCampaigns(response);
    } catch (e) {
      console.log("CampaignContextProvider: error", e);
    }
    setShopCampaignsLoading(false);
  };

  return (
    <CampaignContext.Provider
      value={{
        availableCampaignsLoading,
        shopCampaignsLoading,
        availableCampaigns,
        shopCampaigns,
        campaigns,
        tools,
        fetchAvailableCampaigns,
        fetchShopCampaigns,
        fetchCampaigns,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignContextProvider;
