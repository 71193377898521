import Modal from "react-modal";
import { Cross } from "akar-icons";
import UiHeading from "../ui-elements/text/UiHeading";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import UiButton from "../ui-elements/buttons/UiButton";
import illustration1 from "../static/images/widgets/Illustration_copy.svg";

Modal.setAppElement('#root');

const ReachedModal = ({ reachedModalIsOpen, closeReachedModal, availableWidgetCount }) => {
  return (
    <Modal
      isOpen={reachedModalIsOpen}
      onRequestClose={closeReachedModal}
      className="an-modal text-center"
      overlayClassName="an-overlay"
    >
      <div className="an-modal__head">
        <div className="an-modal__title an-modal__title_fill">
          <img
            src={illustration1}
            alt=""
            className="responsive-img"
          />
          <UiHeading
            element="h6"
            desktop="other-headings-h6-bold"
            text={`Plan alert: ${availableWidgetCount} widgets at a time!`}
            margin="16"
          />
          <UiCaptionRegular
            className="mt-8 color-secondary-colour-secondary-7"
            text="Upgrade for a widget galore or part ways with your current creation."
          />
        </div>
        <button
          onClick={closeReachedModal}
          className="an-modal__close"
        >
          <Cross
            size="24"
            color="#d9d9d9"
          />
        </button>
      </div>
      <div className="an-modal__body mt-44">
        <UiButton
          elem="a"
          text="Upgrade my plan"
          size="medium"
          href="/billing/packages"
        />
      </div>
    </Modal>
  )
}

export default ReachedModal;