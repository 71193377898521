import React, { useCallback, useState, useEffect } from "react";
import LinksEditor from "../links-editor/LinksEditor";
import RatingEditor from "../rating-editor/RatingEditor";
import MultiSelector from "../multi-selector/MultiSelector";
import UiBaseCheckbox from "../../forms/UiBaseCheckbox";
import FileUploader from "../../file-uploader/FileUploader";
import ButtonContent from "./ButtonContent";
import OpeningHours from "../../opening-hours/OpeningHours";
import UiButton from "../../buttons/UiButton";
import TextareaAutosize from "../../textarea-autosize/TextareaAutosize";
import FormControl from "../../forms/FormControl";
import Divider from "../../divider/Divider";
import CouponEditor from "../coupon-editor/CouponEditor";
import { UpsellTypeEditor } from "../upsell-type-editor/UpsellTypeEditor";
import LayoutEditor from "../layout-editor/LayoutEditor";
import Select from "../select/Select";
import DateTimeEditor from "../date-time/date-time";
import EmojiSelector from "../../emoji-selector/EmojiSelector";
import GenerateButton from "./GenerateButton";
import _ from "lodash";
import { generateString } from "../../../api/Gpt";
import CountryAutocomplete from "../../forms/country-autocomplete/CountryAutocomplete";
import ProductSelector from "../../forms/product-selector/ProductSelector";

export const CustomizationFormFields = ({
  index,
  field,
  values = {},
  validations = [],
  validationErrors = {},
  onChange,
  onClick,
  disableDebounce,
  genIndex,
  onShowNextCustomizationElement
}) => {
  const [_values, setValues] = useState(values);
  const [generationDisabled, setGenerationDisabled] = useState(false);
  const showValidators = useCallback((index) => {
      return validations.includes(index) ? true : null;
    },
    [validations]
  );

  const showNextCustomizationElement = () => onShowNextCustomizationElement();

  useEffect(() => {
    if (!_.isEqual(values, _values)) setValues(values);
  }, [values]);

  const debounceFn = useCallback(_.debounce(onChange, 300), []);

  const handleChange = useCallback((key, value) => {
    const newValues = { ..._values, [key]: value };
    setValues(newValues);
    if (disableDebounce) onChange(newValues, key);
    else debounceFn(newValues, key);

  }, [_values, setValues, disableDebounce, debounceFn]);

  const generate = async (field) => {
    setGenerationDisabled(true);

    const response = await generateString({
      label: field.label,
      context: field.generateContext,
      type: field.generateType,
    });

    handleChange(field.name, response);
    setGenerationDisabled(false);
  };

  const renderForm = useCallback(
    (schema, key, index) => {
      if (!schema) return;
      const _validationError =
        showValidators(index) && validationErrors[index]
          ? validationErrors[index][key]
          : false;

      switch (schema.type) {
        case "links":
          return (
            <LinksEditor
              max={3}
              links={_values[key]}
              onChange={(links) => handleChange(key, links)}
              validationError={_validationError}
            />
          );
        case "rating":
          return (
            <RatingEditor
              labelMargin="4"
              label={schema.label}
              labelClassName="ui-caption-medium"
              value={_values[key] || false}
              onChange={(value) => handleChange(key, value)}
              validationError={_validationError}
            />
          );
        case "checkbox":
          if (schema.options) {
            return (
              <MultiSelector
                value={_values[key] || []}
                options={schema.options}
                onChange={(value) => handleChange(key, value)}
                validationError={_validationError}
              />
            );
          } else {
            return (
              <UiBaseCheckbox
                type={schema.type}
                label={schema.label}
                checked={_values[key] || false}
                placeholder={schema.placeholder}
                onChange={(e) => handleChange(key, e.target.checked)}
                validationError={_validationError}
              />
            );
          }
        case "fileUploader":
          const productId = _values.product?.id
          return (
            <FileUploader
              {...schema}
              onFileChange={(url) => handleChange(key, url)}
              image={_values[key]}
              className="file-uploader_customizer"
              stock={schema?.stock}
              width={schema?.w}
              height={schema?.h}
              opacity={schema?.opacity}
              productId={productId}
              accept={{
                "image/png": [],
                "image/jpeg": [],
              }}
              disabled={isDisabled()}
              validationError={_validationError}
            />
          );
        case "openingHours":
          const defaultOpeningHours = {
            opensAt1: "",
            closesAt1: "",
            opensAt2: "",
            closesAt2: "",
            closed: false,
          };

          return (
            <OpeningHours
              value={
                _values[key] ? _values[key] : Array(7).fill(defaultOpeningHours)
              }
              onChange={(val) => handleChange(key, val)}
              validationError={_validationError}
            />
          );
        case "button":
          return (
            <ButtonContent>
              <UiButton
                type="button"
                size="extra-small"
                design={`secondary`}
                text={schema.label}
                className={`text-uppercase`}
                disabled={generationDisabled}
                onClick={() => {
                  setGenerationDisabled(true)
                  onClick(schema.name, typeof genIndex != 'undefined' ? genIndex : index).finally(() => setGenerationDisabled(false))
                }}
              />
            </ButtonContent>
          );
        case "textarea":
          return (
            <TextareaAutosize
              type={schema.type}
              value={_values[key] || ""}
              label={schema.label}
              placeholder={schema.placeholder}
              maxLength={schema.maxlength}
              labelClassName="ui-caption-medium"
              labelMargin="4"
              onChange={(e) => handleChange(key, e.target.value)}
              required={schema.required}
              validationError={_validationError}
            />
          );
        case "date":
          return (
            <FormControl
              type="date"
              value={_values[key] || ""}
              name={schema.name}
              label={schema.label}
              placeholder={schema.placeholder}
              labelClassName="ui-caption-medium"
              labelMargin="4"
              minDate={new Date()}
              onChange={(name, value) => handleChange(name, value)}
              required={schema.required}
              validationError={_validationError}
            />
          );
        case "hr":
          return <Divider />;
        case "coupon":
          return (
            <CouponEditor
              value={_values[key]}
              required={schema.required}
              onChange={(value) => handleChange(key, value)}
              validationError={_validationError}
              showNextCustomizationElement={showNextCustomizationElement}
            />
          );
        case "product":
          return (
            <ProductSelector
              {...schema}
              labelClassName="ui-caption-medium"
              labelMargin="4"
              disabled={!isFieldAvailable(true)}
              validationError={_validationError}
              value={_values[key] || []}
              onChange={(value) => {
                handleChange(key, value);
              }}
            />
          );
        case "country":
          return (
            <CountryAutocomplete
              {...schema}
              labelClassName="ui-caption-medium"
              labelMargin="4"
              validationError={_validationError}
              value={_values[key] || []}
              onChange={(value) => {
                handleChange(key, value);
              }}
            />
          );
        case "radio":
          return (
            <FormControl
              label={schema.label}
              labelClassName="ui-caption-medium"
              labelMargin="4"
              type={schema.type}
              value={_values[key] || ""}
              onChange={(e) => {
                handleChange(key, e.target.value);
              }}
              options={schema.options.map((option) => ({
                ...option,
                ...(option.name && { label: option.name }),
                value: option.value,
              }))}
              required={schema.required}
              validationError={_validationError}
              blockRadios
              variant="pill"
              blocks={schema.blocks}
            />
          );
        case "upsellType":
          return (
            <UpsellTypeEditor
              value={_values[key] || ""}
              onChange={(value) => {
                handleChange(key, value);
              }}
              validationError={_validationError}
              required={schema.required}
            />
          );
        case "modalLayout":
          return (
            <LayoutEditor
              variant="modal"
              value={_values[key] || ""}
              onChange={(value) => {
                handleChange(key, value);
              }}
              validationError={_validationError}
              required={schema.required}
            />
          );
        case "landingLayout":
          return (
            <LayoutEditor
              variant="landing"
              value={_values[key] || ""}
              onChange={(value) => {
                handleChange(key, value);
              }}
              validationError={_validationError}
              required={schema.required}
              width={152}
            />
          );
        case "select":
          return (
            <Select
              {...schema}
              value={_values[key] || ""}
              onChange={(value) => {
                handleChange(key, value);
              }}
              validationError={_validationError}
            />
          );
        case "datesAndTime":
          return (
            <DateTimeEditor
              {...schema}
              value={_values[key] || ""}
              onChange={(value) => handleChange(key, value)}
              validationError={_validationError}
            />
          );
        default:
          return (
            <FormControl
              type={schema.type}
              value={_values[key] || ""}
              label={schema.label}
              placeholder={schema.placeholder}
              labelClassName="ui-caption-medium"
              labelMargin="4"
              max={schema?.max}
              maxLength={schema.maxlength}
              disabled={isDisabled()}
              onChange={(e) => handleChange(key, e.target.value)}
              required={schema.required}
              validationError={_validationError}
            />
          );
      }
    },
    [_values, validationErrors, showValidators, generationDisabled]
  );
  
  const isDisabled = () => {
    if (field.disabledBy && !_values[field.disabledBy]) return true
    return false
  }
  
  const isFieldAvailable = (skip=false) => {
    if (field.visible && !skip && !_values[field.conditional]) return true
    if (field.conditional && !_values[field.conditional]) {
      return false;
    }

    if (
      field.conditional &&
      field.conditionalValue &&
      _values[field.conditional] !== field.conditionalValue
    ) {
      return false;
    }

    if (
      field.conditional === "coupon" &&
      _values[field.conditional] &&
      !_values[field.conditional].includeCoupon
    ) {
      return false;
    }

    if (field.name === 'voucher' && _values.raffleType !== 'custom') {
      return false
    }

    return true;
  };

  if (!isFieldAvailable()) return null;

  return (
    <div>
      {renderForm(field, field.name, index)}
      {field.generate && (
        <GenerateButton
          onClick={(field) => generate(field)}
          field={field}
          disabled={generationDisabled}
        />
      )}
      {field.emojiSelector && (
        <EmojiSelector
          onChange={(value) =>
            handleChange(field.name, `${_values[field.name] || ""}${value}`)
          }
        />
      )}
    </div>
  );
};
