import { Link } from "react-router-dom";
import "./UiButton.scss";

const UiButton = props => {
  if (props.elem === 'a') {
    return <Link to={props.href} target={props.target} download={props.download} className={`ui-button${props.arrow ? ' ui-button_arrow' : ''}${props.size ? ` ui-button_${props.size}` : ''}${props.design ? ` ui-button_${props.design}` : ''}${props.className ? ` ${props.className}` : ''}${props.disabled ? ` ui-button_disabled` : ''}`}>{props.text}</Link>;
  }

  return (
    <button type={props.type} onClick={props.onClick} className={`ui-button${props.size ? ` ui-button_${props.size}` : ''}${props.hasIcon ? ` ui-button_icon` : ''}${props.design ? ` ui-button_${props.design}` : ''}${props.arrow ? ' ui-button_arrow' : ''} ${props.className ? props.className : ''}`} disabled={props.disabled}>{props.text}</button>
  );
}

export default UiButton;