import UiExtraSmallRegular from "../text/UiExtraSmallRegular";
import "./ComingSoon.scss";

const ComingSoon = () => {
  return (
    <span className="coming-soon">
      <UiExtraSmallRegular text="Coming Soon" />
    </span>
  )
}

export default ComingSoon;