import { useState, useEffect, useContext, useMemo } from "react";
import { ArrowRight } from "akar-icons";
import Banner from "../banner/Banner";
import UiHeading from "../ui-elements/text/UiHeading";
import UiBodyRegular from "../ui-elements/text/UiBodyRegular";
import UiButton from "../ui-elements/buttons/UiButton";
import ntRecruitDirectly1 from "../static/images/campaigns/nt-recruit-directly 1.svg";
import ntExploring1 from "../static/images/campaigns/nt-exploring 1.svg";
import ntOctopus1 from "../static/images/campaigns/nt-octopus 1.svg";
import { CampaignContext } from "../context/CampaignContext";
import { AuthContext } from "../context/AuthContext";

const Banners = () => {
  const [showUpgradeBanner, setShowUpgradeBanner] = useState(false);
  const [showProductUpsellBanner, setProductUpsellBanner] = useState(false);
  const [showTransformBanner, setShowTransformBanner] = useState(false);
  const [showEmailReachedBanner, setShowEmailReachedBanner] = useState(false);
  const upgradeUrl = "/billing/packages";
  const { shopCampaigns } = useContext(CampaignContext);
  const { shopUpgradable, currentPackage, shopData } = useContext(AuthContext);

  useEffect(() => {
    const productUpsellBannerHidden = localStorage.getItem(
      "productUpsellBannerHidden"
    );

    if (!productUpsellBannerHidden) {
      const productUpsellCampaigns = shopCampaigns.filter(
        (shopCampaign) => shopCampaign.slug === "product-upsell"
      );
      setProductUpsellBanner(productUpsellCampaigns.length === 0);
    }
  }, [shopCampaigns]);

  useEffect(() => {}, [shopUpgradable]);

  const limit = useMemo(() => {
    if (!currentPackage) return false;

    return shopData.limitReached;
  }, [shopData, currentPackage]);

  const showOtherBanners = () => {
    const upgradeBannerHidden = localStorage.getItem("upgradeBannerHidden");
    const tranformBannerHidden = localStorage.getItem("transformBannerHidden");

    if (!upgradeBannerHidden) {
      setShowUpgradeBanner(shopUpgradable);
    } else if (!tranformBannerHidden) {
      setShowTransformBanner(shopUpgradable);
    }
  };

  useEffect(() => {
    const emailReachedBannerHidden = localStorage.getItem(
      "emailReachedBannerHidden"
    );
    if (!emailReachedBannerHidden) {
      if (limit) {
        setShowEmailReachedBanner(true);
      } else {
        showOtherBanners();
      }
    }
  }, [limit]);

  const hideUpgradeNotification = () => {
    localStorage.setItem("upgradeBannerHidden", true);

    setShowUpgradeBanner(false);
  };

  const hideUpsellBanner = () => {
    localStorage.setItem("productUpsellBannerHidden", true);

    setProductUpsellBanner(false);
  };

  const hideTransfromBanner = () => {
    localStorage.setItem("transformBannerHidden", true);

    setShowTransformBanner(false);
  };

  const hideEmailReachedBanner = () => {
    localStorage.setItem("emailReachedBannerHidden", true);

    setShowEmailReachedBanner(false);
  };

  return (
    <>
      {showEmailReachedBanner ? (
        <Banner
          color="volcano-volcano-1"
          className="an-col pr-210"
          content={
            <>
              <UiHeading
                elem="h4"
                text="Attention: monthly email limit reached!"
                desktop="other-headings-h4-bold"
              />
              <UiBodyRegular
                text="Uh-oh! You've hit the monthly limit of 500 emails on your free plan. Upgrade now to unlock higher email quotas, and enjoy advanced features. Or wait until next month when your email quota resets!"
                className="color-secondary-colour-secondary-7"
              />
              <div className="button-group mt-16">
                <UiButton
                  text="Upgrade my plan"
                  elem="a"
                  href={upgradeUrl}
                  size="small"
                />
                <UiButton
                  text="Remind me later"
                  size="small"
                  design="no-border"
                  onClick={hideEmailReachedBanner}
                />
              </div>
            </>
          }
          image={ntOctopus1}
        />
      ) : (
        <>
          {showUpgradeBanner || showProductUpsellBanner ? (
            <div className="an-row">
              {showUpgradeBanner ? (
                <Banner
                  className="an-col an-col_67 pr-198"
                  color="cyan-cyan-1"
                  content={
                    <>
                      <UiHeading
                        elem="h4"
                        text="Elevate your marketing game"
                        desktop="other-headings-h4-bold"
                      />
                      <UiBodyRegular
                        text="Take your marketing to new heights! Our premium plans offer exclusive features for a game-changing strategy. Upgrade now!"
                        className="color-secondary-colour-secondary-7"
                      />
                      <div className="button-group mt-16">
                        <UiButton
                          text="Upgrade my plan"
                          elem="a"
                          href={upgradeUrl}
                          size="small"
                        />
                        <UiButton
                          text="Remind me later"
                          size="small"
                          design="no-border"
                          onClick={hideUpgradeNotification}
                        />
                      </div>
                    </>
                  }
                  image={ntRecruitDirectly1}
                />
              ) : (
                ""
              )}
              {showProductUpsellBanner ? (
                <Banner
                  className="an-col an-col_33 pr-76"
                  content={
                    <>
                      <UiHeading
                        elem="h6"
                        text="Did you know..."
                        desktop="other-headings-h6-bold"
                        className="color-golden-purple-purple-5"
                      />
                      <UiBodyRegular
                        text="that upselling campaigns increase business revenue by 10-30% on average?"
                        className="color-secondary-colour-secondary-7"
                        margin="8"
                      />
                      <div className="mt-8">
                        <UiButton
                          text={
                            <>
                              <span className="ui-button__text">
                                Let’s do something about it
                              </span>
                              <ArrowRight size="16" color="#9254de" />
                            </>
                          }
                          elem="a"
                          href="/campaigns/product-upsell"
                          size="small"
                          design="no-border"
                          arrow={true}
                        />
                      </div>
                    </>
                  }
                  emoji="🎯"
                  closeButton={true}
                  onCloseClick={hideUpsellBanner}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            <>
              {showTransformBanner ? (
                <Banner
                  className="an-col pr-210"
                  content={
                    <>
                      <UiHeading
                        elem="h4"
                        text="Transform your marketing journey"
                        desktop="other-headings-h4-bold"
                      />
                      <UiBodyRegular
                        text={
                          <>
                            Embark on a transformative marketing journey! Our
                            premium plans unveil a world of possibilities.{" "}
                            <br />
                            Upgrade today to redefine your strategy and lead
                            your brand to new horizons!
                          </>
                        }
                        className="color-secondary-colour-secondary-7"
                      />
                      <div className="button-group mt-16">
                        <UiButton
                          text="Upgrade my plan"
                          elem="a"
                          href={upgradeUrl}
                          size="small"
                        />
                        <UiButton
                          text="Remind me later"
                          size="small"
                          design="no-border"
                          onClick={hideTransfromBanner}
                        />
                      </div>
                    </>
                  }
                  image={ntExploring1}
                />
              ) : (
                ""
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Banners;
