import { memo } from "react"
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import Legend from "../Legend";
import createChartTooltip from '../../../ui-elements/charts/createCustomTooltip';
import EmptyData from '../EmptyData';

const RaffleGiveawayCampaignPerformance = ({ stats, title }) => {
  ChartJS.register(ArcElement, Tooltip);

  const formatNumber = number => {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 0
    });
  }

  let labels = ['Impressions', 'Signed Up', 'Referrals'];

  const datasets = [
    {
      labels: ['Impressions'],
      data: [stats.impressions, 0],
      showTooltip: [true, false],
      backgroundColor: ['#4bd4fb', '#f5f5f5'],
    },
    {
      labels: ['Signed Up'],
      data: [stats.participants, stats.impressions - stats.participants > 0 ? stats.impressions - stats.participants : 0],
      showTooltip: [true, false],
      backgroundColor: ['#9254de', '#f5f5f5'],
    },
    {
      labels: ['Referrals'],
      data: [stats.refs, stats.impressions - stats.refs > 0 ? stats.impressions - stats.refs : 0],
      showTooltip: [true, false],
      backgroundColor: ['#ff85c0', '#f5f5f5'],
    }
  ];

  const data = {
    labels,
    datasets
  }

  return (
    <>
      <div className="email-templates-subtitle-2-medium">{title ? title : 'Campaign performance'}</div>
      <div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">Latest activity</div>
      {!stats.impressions ?
        <div className="mt-24">
          <EmptyData />
        </div>
      :
        <div className="mt-24 an-row items-center">
          <div className="an-col an-col_53 position-relative">
            <Doughnut
              data={data}
              options={{
                borderRadius: 6,
                cutout: 50,
                responive: true,
                clip: 10,
                plugins: {
                  tooltip: {
                    enabled: false,
                    callbacks: {
                      title: (tooltipItem) => tooltipItem[0]?.dataset.labels[0]
                    },
                    external: createChartTooltip
                  }
                }
              }}
            />
            <div className="overview-emails">
              <div className="overview-emails__count">{formatNumber(stats.impressions)}</div>
              <div className="overview-emails__label color-secondary-colour-secondary-7">Impressions</div>
            </div>
          </div>
          <div className="an-col an-col_47 d-flex d-flex_column d-flex_gap_8">
            <Legend
              label="Impressions"
              value={stats.impressions}
              total={stats.impressions}
              showPercents={false}
              color="#4bd4fb"
            />
            <Legend
              label="Signed Up"
              value={stats.participants}
              total={stats.impressions}
              color="#9254de"
            />
            <Legend
              label="Referrals"
              value={stats.refs}
              total={stats.impressions}
              color="#ff85c0"
            />
          </div>
        </div>
      }
    </>
  )
}

export default memo(RaffleGiveawayCampaignPerformance);