import React, { useState, useEffect } from "react";
import LayoutSelector from "../../ui-elements/layout-selector/LayoutSelector";
import useStep from "../../hooks/useStep";
import StepWizardNavigation from "../../ui-elements/step-wizard/StepWizardNavigation";

const layouts = [
  {
    imageSrc: "/static/brand-assets/email-header/layout-1.svg",
    description: "Default header. Display only your logo in the center.",
  },
  {
    imageSrc: "/static/brand-assets/email-header/layout-2.svg",
    description:
      "Vertical email header with logo and custom links to your selected page.",
  },
  {
    imageSrc: "/static/brand-assets/email-header/layout-3.svg",
    description:
      "Vertical email header with logo and custom links to your selected page.",
  },
];

const LayoutSelectionStep = () => {
  const { currentStepValue, handlePrev, handleNext } = useStep();
  const [value, setValue] = useState(currentStepValue);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value === 1) {
      handleNext(value, null, true);
      return;
    }

    handleNext(value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="an-row flex-wrap h-fit-content">
        {layouts.map((layout, index) => (
          <LayoutSelector
            onClick={() => setValue(index + 1)}
            key={`layout-${index}`}
            className="an-col_3_16 color-secondary-colour-secondary-7"
            imageSrc={layout.imageSrc}
            selected={Number(value) === index + 1}
            description={layout.description}
          />
        ))}
      </div>

      <StepWizardNavigation onPrev={() => handlePrev(value)} />
    </form>
  );
};

export default LayoutSelectionStep;
