import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import Preloader from "../preloader/Preloader";
import EscapeButton from "../ui-elements/escape-button/EscapeButton";
import styles from "./Layout.module.scss";
import classNames from "classnames";

const ModalLayout = ({ children, backTo, justify }) => {
  const { user, shopData, loading } = useContext(AuthContext);

  if (!user || !shopData || loading) {
    return <Preloader />;
  }

  return (
    <div className={classNames(styles["base"], styles["center"])}>
      
      <div className={styles["inner"]}>
        <div className="container">
          <div className={styles["card"]}>
            <div className={classNames(styles["card-inner"], { [styles[`card-inner_justify_${justify}`]]: justify })}>
              {backTo && <EscapeButton to={backTo} />}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLayout;
