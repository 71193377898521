import { useState, useEffect } from "react";

import { updateShopCampaign } from "../../api/Campaign";
import { Notify } from "../../ui-elements/notify/notify";
import useCampaign from "../../hooks/useCampaign";

import "./CampaignStatus.scss";

const CampaignStatus = ({ status, shopCampaign, enableLabel = true }) => {  
  const [state, setState] = useState(status);
  const { fetchShopCampaigns } = useCampaign()

  useEffect(() => {
    setState(status)
  }, [status])

  const handleActivate = async(status) => {
    if (shopCampaign) {
      try {
        await updateShopCampaign(shopCampaign._id, { status });

        Notify.success({
          title: `Campaign has been ${status == 'active' ? 'activated' : 'paused'}`
        });
        
        setState(status)
        fetchShopCampaigns();  
      } catch (error) {
        console.error(error)
        Notify.error({
          title: 'Something went wrong :(. Try later'
        });
      }
    }
  }

  const handleChange = (e) => {
    handleActivate(e.target.checked ? 'active' : 'paused')
  }

  return (
    <div className="campaign-status">
      {enableLabel && <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7">Campaign status</div>}
      <div className="campaign-status__toggler">
        <input type="checkbox" className="checkbox" onChange={handleChange} defaultChecked={state == 'active'} />
        <div className={`campaign-status__item campaign-status__item_active${state === 'active' ? ' campaign-status__item_current' : ''}`}>Active</div>
        <div className={`campaign-status__item campaign-status__item_paused${state !== 'active' ? ' campaign-status__item_current' : ''}`}>Paused</div>
      </div>
    </div>
  );
}

export default CampaignStatus;