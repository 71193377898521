import styles from "./CampaignStep.module.scss";

const CampaignStep = props => {
  return (
    <div className={styles['campaign-step']}>
      <img
        src={props.step.icon}
        alt={props.step.caption}
        className={styles['campaign-step__icon']}
      />
      <div className="email-templates-overline mt-24">{props.step.caption}</div>
      <div className="email-templates-paragraph-2-regular mt-4 color-secondary-colour-secondary-7">{props.step.description}</div>
    </div>
  )
}

export default CampaignStep;