import { useEffect, useState } from "react";
import TotalEmailsSent from "./TotalEmailsSent";
import CampaignInstances from './CampaignInstances';
import PopupPerformance from "./PopupPerformance";
import UserLocation from "./UserLocation";
import Timeline from "./Timeline";
import { getCampaignLocationStats, getCampaignTimeline } from "../../api/Campaign";
import KPIStats from '../../ui-elements/kpi-stats/KPIStats';
import { getStats } from './tools';
import "./Overview.scss";

const GenerateNewSubscribersOverview = ({ campaigns, slug }) => {
  const statObj = {
    popupsDisplayed: 0,
    newSubscribers: 0,
    totalOrders: 0,
    totalRevenue: 0,
    clicked: 0,
    purchased: 0,
  }
  const [stats, setStats] = useState(statObj)
  const [locationStats, setLocationStats] = useState([]);
  const [timeline, setTimeline] = useState([]);
  
  const fetchData = async () => {
    setLocationStats(await getCampaignLocationStats(slug));
    setTimeline(await getCampaignTimeline(slug));
  }

  useEffect(() => {
    setStats(getStats(statObj, campaigns))
    fetchData();
  }, [campaigns]);

  return (
    <div className="an-layout">
      <div className="an-row an-row_gap_8">
        {!!Object.keys(stats).length && <KPIStats 
          stats={stats}
          indicators={{
            popupsDisplayed: "Popups displayed",
            newSubscribers: "Total new subscribers",
            totalOrders: "Total orders",
            totalRevenue: {
              label: 'Total revenue',
              type: 'money'
            },
          }}
        />}
      </div>
      {!!stats.popupsDisplayed && (
        <div className="an-row">
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
            <PopupPerformance
              totalPopupsDisplayed={stats?.popupsDisplayed}
              subscribed={stats?.newSubscribers}
              clicked={stats?.clicked}
              purchased={stats?.purchased}
            />
          </div>
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
            <TotalEmailsSent campaigns={campaigns} />
          </div>
        </div>
      )}
      <CampaignInstances
        campaigns={campaigns}
        slug={slug}
      />
      <div className="an-row">
        {!!locationStats.length && (
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
            <UserLocation
              stats={locationStats}
              clicked={stats.clicked}
            />
          </div>
        )}
        {!!timeline.length && (
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
            <Timeline
              timeline={timeline}
              campaignName={campaigns[0].name}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default GenerateNewSubscribersOverview;