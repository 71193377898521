import turstpilot from "../../static/images/logos/trustpilot.svg";
import googleBusiness from "../../static/images/logos/google_business.svg";
import google from "../../static/images/logos/google.svg";
import facebook from "../../static/images/logos/facebook.svg";
import feefo from "../../static/images/logos/feefo.svg";
import yotpo from "../../static/images/logos/yotpo.svg";
import reviewSvg from "../../static/images/logos/reviews.svg";

const fields = [
	{
		icon: {
			type: "custom",
			value: turstpilot,
		},
		fields: [
			{
				type: "url",
				label: "Add your URL",
				name: "trustpilot",
				placeholder: "Ex. uk.trustpilot.com/mydomain",
			},
		],
	},
	{
		icon: {
			type: "custom",
      value: google,
		},
		fields: [
			{
				type: "url",
				label: "Add your URL",
				name: "googleBusiness",
				placeholder: "Ex. www.google.com/business",
			},
		],
	},
	{
		icon: {
			type: "custom",
			value: facebook,
		},
		fields: [
			{
				type: "url",
				label: "Add your URL",
				name: "facebook",
				placeholder: "Ex. www.facebook.com",
			},
		],
	},
	{
		icon: {
			type: "custom",
			value: feefo,
		},
		fields: [
			{
				type: "url",
				label: "Add your URL",
				name: "feefo",
				placeholder: "Ex. www.feefo.com",
			},
		],
	},
	{
		icon: {
			type: "custom",
			value: yotpo,
		},
		fields: [
			{
				type: "url",
				label: "Add your URL",
				name: "yotpo",
				placeholder: "Ex. www.yotpo.com",
			},
		],
	},
	{
		icon: {
			type: "custom",
			value: reviewSvg,
		},
		fields: [
			{
				type: "url",
				label: "Add your URL",
				name: "review",
				placeholder: "Ex. www.review.io",
			},
		],
	},
];

export default fields