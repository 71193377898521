import React from "react";
import Chips from "../ui-elements/common/Chips";

const WidgetPreviewStatus = ({ widget }) => {
  if (widget.status == 'draft') {
    return (
      <div className="mt-8 pt-12 pb-4 widget-preview__campaigns">
        <div className="widget-preview__being color-secondary-colour-secondary-7">
          This widget is not published
        </div>
        <div className="widget-preview__campaigns-list mt-4">
          <Chips value="Draft" color="red" />
        </div>
      </div>
    )
  }

  if (widget.campaignNames && widget.campaignNames.length) {
    return (
      <div className="mt-8 pt-12 pb-4 widget-preview__campaigns">
        <div className="widget-preview__being color-secondary-colour-secondary-7">
          Being used in
        </div>
        <div className="widget-preview__campaigns-list mt-4">
          {widget.campaignNames.map((campaignName, key) => (
            <Chips key={key} value={campaignName} color="blue" />
          ))}
        </div>
      </div>
    )
  }

  if (widget.campaignNames && !widget.campaignNames.length) {
    return (
      <div className="mt-8 pt-12 pb-4 widget-preview__campaigns">
        <div className="widget-preview__being color-secondary-colour-secondary-7">
          This widget has not been assigned to a campaign yet
        </div>
        <div className="widget-preview__campaigns-list mt-4">
          <Chips value="Not runnig" color="blue" />
        </div>
      </div>
    )
  }

  return null
}

export default WidgetPreviewStatus;