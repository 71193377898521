import { useState, useEffect, useContext } from "react";
import {
  ChevronDown,
  Question,
  LockOn,
  BookOpen,
  SignOut,
  Person,
  Gear,
  Edit,
  CreditCard,
} from "akar-icons";
import OutsideClickHandler from "react-outside-click-handler";
import HeaderProfileImage from "./HeaderProfileImage";
import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import HeaderProfileNav from "./HeaderProfileNav";
import UiSmallRegular from "../ui-elements/text/UiSmallRegular";
import "./HeaderProfile.scss";
import { AuthContext } from "../context/AuthContext";

const HeaderProfile = (props) => {
  const [opened, setOpened] = useState(false);
  const [links, setLinks] = useState([]);
  const { user } = props;

  useEffect(() => {
    if (props.type === "onboarding") {
      setLinks([
        {
          url: process.env.REACT_APP_CUSTOMER_SUPPORT_URL,
          title: "Contact us",
          target: "_blank",
          icon: <Question size="16" color="#d9d9d9" />,
        },
        {
          url: "https://anomate.co/privacy-policy/",
          title: "Privacy policy",
          target: "_blank",
          icon: <LockOn size="16" color="#d9d9d9" />,
        },
        {
          url: "https://anomate.co/terms-conditions/",
          title: "Terms and conditions",
          target: "_blank",
          icon: <BookOpen size="16" color="#d9d9d9" />,
        },
        {
          url: "/logout",
          title: "Log out",
          icon: <SignOut size="16" color="#d9d9d9" />,
        },
      ]);
    } else if (props.type === "dashboard") {
      if (user.permission === 'employee') {
        setLinks([
          {
            url: "/profile",
            title: "Profile",
            icon: <Person size="16" color="#d9d9d9" />,
          },
          {
            url: "/brand-assets",
            title: "Brand assets",
            icon: <Edit size="16" color="#d9d9d9" />,
          },
          {
            url: process.env.REACT_APP_CUSTOMER_SUPPORT_URL,
            title: "Support",
            target: "_blank",
            icon: <Question size="16" color="#d9d9d9" />,
          },
        ]);
      } else if (user.permission === 'admin') {
        setLinks([
          {
            url: "/profile",
            title: "Profile",
            icon: <Person size="16" color="#d9d9d9" />,
          },
          {
            url: "/store-settings",
            title: "Store settings",
            icon: <Gear size="16" color="#d9d9d9" />,
          },
          {
            url: "/brand-assets",
            title: "Brand assets",
            icon: <Edit size="16" color="#d9d9d9" />,
          },
          {
            url: process.env.REACT_APP_CUSTOMER_SUPPORT_URL,
            title: "Support",
            target: "_blank",
            icon: <Question size="16" color="#d9d9d9" />,
          },
        ]);
      } else {
        setLinks([
          {
            url: "/profile",
            title: "Profile",
            icon: <Person size="16" color="#d9d9d9" />,
          },
          {
            url: "/store-settings",
            title: "Store settings",
            icon: <Gear size="16" color="#d9d9d9" />,
          },
          {
            url: "/brand-assets",
            title: "Brand assets",
            icon: <Edit size="16" color="#d9d9d9" />,
          },
          {
            url: "/billing",
            title: "Billing",
            icon: <CreditCard size="16" color="#d9d9d9" />,
          },
          {
            url: process.env.REACT_APP_CUSTOMER_SUPPORT_URL,
            title: "Support",
            target: "_blank",
            icon: <Question size="16" color="#d9d9d9" />,
          },
        ]);
      }
    }
  }, []);

  const toggleOpened = (e) => setOpened(!opened);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpened(false)}>
      <div
        className={`header-profile${opened ? " header-profile_opened" : ""}`}
      >
        <button onClick={toggleOpened} className="header-profile__button">
          <HeaderProfileImage avatar={user.avatar} />
          <div>
            <UiCaptionMedium
              text={`${user.firstName} ${user.lastName}`}
              className="header-profile__name text-left"
            />
            {user.position ? (
              <UiSmallRegular
                text={user.position}
                className="color-secondary-colour-secondary-7 text-left"
              />
            ) : (
              ""
            )}
          </div>
          <div className="header-profile__icon">
            <ChevronDown color="#d9d9d9" size="16" />
          </div>
        </button>
        {opened ? (
          <HeaderProfileNav links={links} onClick={() => setOpened(false)} />
        ) : (
          ""
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default HeaderProfile;
