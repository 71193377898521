import React, { useId, useState, useEffect } from "react";
import Flatpicker from "react-flatpickr";
import "./Flatpickr.scss";
import styles from "./Flatpickr.module.scss";
import classNames from "classnames";
import moment from "moment";
import { ChevronLeft, ChevronRight } from "akar-icons";
import { renderToStaticMarkup } from "react-dom/server";

const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
  const id = useId();
  const _value = value || defaultValue;
  const offset = _value ? _value.split('+')[1] : 0;
  const dateTZ = moment(_value).utcOffset(`+${offset}`)
  
  const date = _value
    ? dateTZ.format("DD MMM YYYY")
    : props.placeholder || "DD MM YYYY";
    
  const time = props.enableTime
    ? _value
      ? dateTZ.format("hh:mm a")
      : "00:00 pm"
    : "";

  return (
    <label
      htmlFor={id}
      className={classNames("form-control", styles["base"], {
        [`${styles["has-addon"]}`]: props.addOn,
      })}
    >
      {props.addOn && <div className={styles["addon"]}>{props.addOn}</div>}

      <div>
        <div>{date}</div>
        {time && <div className={styles["time"]}>{time}</div>}
      </div>
      <input id={id} style={{height: '100%'}} defaultValue={defaultValue} ref={inputRef} />
    </label>
  );
};

const Flatpickr = (props) => {
  const [value, setValue] = useState(() => {
    return props.value ? moment(props.value).toISOString() : undefined
  });

  useEffect(() => {
    if (value !== props.value) setValue(props.value)
  }, [props.value])

  const handleOnChange = (e) => {
    const value = moment(e[0]).format();
    setValue(value);
    props.onChange && props.onChange(value);
  };

  return (
    <Flatpicker
      options={{
        locale: {
          weekdays: {
            shorthand: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            longhand: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
          }
        },
        enableTime: props.enableTime,
        minDate: props.minDate,
        maxDate: props.maxDate,
        monthSelectorType: 'static',
        prevArrow: renderToStaticMarkup(<ChevronLeft size={16} />),
        nextArrow: renderToStaticMarkup(<ChevronRight size={16} />)
      }}
      onChange={handleOnChange}
      render={({ defaultValue }, ref) => {
        return (
          <CustomInput
            {...props}
            value={value}
            defaultValue={defaultValue}
            inputRef={ref}
          />
        );
      }}
    />
  );
};

export default Flatpickr;
