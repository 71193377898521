import React, { useState, useContext, useEffect, useMemo } from "react";
import axios from "axios";
import Select from "react-dropdown-select";
import validator from "validator";
import FormControl from "../ui-elements/forms/FormControl";
import UiButton from "../ui-elements/buttons/UiButton";
import { saveCompanyDetails } from "../api/Shop";
import Preloader from "../preloader/Preloader";
import { AuthContext } from "../context/AuthContext";
import { Notify } from "../ui-elements/notify/notify";
import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";

const CompanyDetails = () => {
  const [info, setInformation] = useState({});
  const { shopData, updateShop } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [selectedIndustryValues, setSelectedIndustryValues] = useState([]);

  useEffect(() => {
    axios.get('/industries')
      .then(response => {
        setIndustries(response.data.map(industry => {
          if (shopData.companyDetails.industry === industry._id) {
            setSelectedIndustryValues([{
              label: industry.name,
              value: industry._id
            }]);
          }

          return {
            label: industry.name,
            value: industry._id
          }
        }));
      })
      .catch(err => {
        console.error(err);
      })

    if (!shopData?.companyDetails) {
      return;
    }

    setInformation(shopData.companyDetails || {});
  }, [shopData]);

  const handleOnChange = e => {
    const key = e.target?.name ? e.target.name : 'industry';
    const value = key === 'industry' ? e[0].value : e.target.value;

    setInformation({ ...info, [key]: value });
  }

  const handleSaveInfo = async (e) => {
    e.preventDefault();

    if (Object.keys(info).length === 0 || loading) {
      return;
    }

    if (info.phone) {
      if (!validator.isMobilePhone(info.phone)) {
        setErrors({
          phone: {
            message: 'Phone number is invalid'
          }
        });

        setLoading(false);

        return;
      }
    }

    setLoading(true);

    try {
      await saveCompanyDetails(info);
      await updateShop();

      setErrors({});

      Notify.success({
        title: 'Changes saved successfully',
        message: 'Your company details have been updated.'
      });
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const isChanged = useMemo(() => {
    return (JSON.stringify(info) === JSON.stringify(shopData.companyDetails || {}));
  }, [shopData.companyDetails, info, loading]);
  
  return (
    <form className="card card_24 card_shadow card_overflow_visible" onSubmit={handleSaveInfo}>
      <div className="an-layout">
        <div className="an-row">
          <div className="an-col an-col_50">
            <FormControl
              label="Business name"
              type="text"
              id="business_name"
              name="businessName"
              placeholder="Business name"
              value={info.businessName || ''}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
            />
          </div>
          <div className="an-col an-col_50">
            <UiCaptionMedium text="Industry" />
            <div className="mt-4">
              <Select
                options={industries}
                placeholder="Select industry"
                onChange={handleOnChange}
                className="form-select"
                values={selectedIndustryValues}
              />
            </div>
          </div>
        </div>
        <div className="an-row">
          <div className="an-col an-col_50">
            <FormControl
              label="Website URL"
              type="url"
              id="website_url"
              name="websiteUrl"
              placeholder="Website URL"
              value={info.websiteUrl || ''}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
            />
          </div>
          <div className="an-col an-col_50">
            <FormControl
              label="Email"
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={info.email || ''}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
            />
          </div>
        </div>
        <div className="an-row">
          <div className="an-col an-col_50">
            <FormControl
              label="Phone"
              type="text"
              id="phone"
              name="phone"
              placeholder="Add your phone number"
              value={info.phone || ''}
              labelMargin="4"
              onChange={handleOnChange}
              labelClassName="ui-caption-medium"
              validationError={errors.phone}
            />
          </div>
        </div>
        <div className="flex-right gap-8 mt-4">
          <UiButton
            type="button"
            text="Cancel"
            size="medium"
            design="secondary"
            onClick={() => setInformation(shopData.companyDetails || {})}
            disabled={loading || isChanged}
          />
          <UiButton
            text="Save changes"
            size="medium"
            disabled={loading || isChanged}
          />
        </div>
        {loading && <Preloader absolute={true} overflow={true} />}
      </div>
    </form>
  );
};

export default CompanyDetails;
