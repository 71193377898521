import axios from "axios";

export const getServices = () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/services", {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem('token')}`
          }
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  