import classNames from "classnames";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import styles from "./WidgetLayout.module.scss";

const WidgetLayout = props => {
  return (
    <div
      className={classNames(styles['widget-layout-item'], styles[props.widget.slug], { [styles['active']]: props.active })}
      onClick={props.onClick}
    >
      <img
        src={props.layout.image}
        alt=""
        className={classNames(styles['widget-layout-item__image'], 'responsive-img')}
      />
      <UiCaptionRegular
        margin="16"
        text={props.layout.description}
        className="color-secondary-colour-secondary-7"
      />
    </div>
  )
}

export default WidgetLayout;