import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import FormControl from "../ui-elements/forms/FormControl";
import LinkButton from "../ui-elements/buttons/LinkButton";
import UiBaseCheckbox from "../ui-elements/forms/UiBaseCheckbox";
import UiButton from "../ui-elements/buttons/UiButton";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import { AuthContext } from "../context/AuthContext";
import { loginWithEmailPassword, verifyTwoFactor } from "../api/Auth";
import Preloader from "../preloader/Preloader";
import illustration1 from "../static/images/sign-up/Illustration.svg";
import UiHeading from "../ui-elements/text/UiHeading";

Modal.setAppElement('#root');

const LoginForm = () => {
  const [errors, setErrors] = useState({});
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [data, setData] = useState({});
  const [show2faModal, setShow2faModal] = useState(false);
  const [trustedDevice, setTrustedDevice] = useState(false);
  const [cancelDisabled, setCancelDisabled] = useState(true);
  const [signInDisabled, setSignInDisabled] = useState(true);
  const [verificationCode, setVerificationCode] = useState('');
  const [logInDisabled, setLogInDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setLoggedIn } = useContext(AuthContext);

  const updateData = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  const handleSubmitEvent = async (e) => {
    e.preventDefault();

    setLogInDisabled(true);

    try {
      const { token, twoFactorNeeded } = await loginWithEmailPassword(data);

      setErrors({});

      if (twoFactorNeeded) {
        setShow2faModal(true);
      } else {
        localStorage.setItem("token", token);
        setLoggedIn(true);
        navigate("/");
      }

      setLogInDisabled(false);
    } catch (e) {
      console.log('e', e)
      setErrors(e.errors);
      setLogInDisabled(false);
    }
  };

  const toggleInputPasswordType = (e) => {
    e.preventDefault();

    setPasswordInputType(
      passwordInputType === "password" ? "text" : "password"
    );
  };

  const reset = () => {
    setVerificationCode('');
    setTrustedDevice(false);
  }

  const verify = async () => {
    setLoading(true);

    try {
      const { token } = await verifyTwoFactor({
        email: data.email,
        password: data.password,
        uuid: localStorage.getItem('uuid'),
        verificationCode,
        trustedDevice
      });
      
      localStorage.setItem("token", token);
      setLoggedIn(true);
      navigate("/");
    } catch (e) {
      console.log('e', e)
      setErrors(e.errors);
      setLogInDisabled(false);
      setLoading(false);
    }
  }

  useEffect(() => {
    setSignInDisabled(verificationCode.toString().length !== 6);
    setCancelDisabled(!verificationCode);
  }, [verificationCode]);

  return (
    <>
      <form
        onSubmit={handleSubmitEvent}
        className="mt-64 login-form mt-32-mobile"
      >
        <FormControl
          type="email"
          id="email"
          name="email"
          placeholder="Email*"
          required={true}
          onChange={updateData}
          validationError={errors.email}
        />
        <div className="mt-24 form-control mt-16-mobile">
          <FormControl
            type={passwordInputType}
            id="password"
            name="password"
            placeholder="Password*"
            required={true}
            onChange={updateData}
            validationError={errors.password}
          />
          <div
            onClick={toggleInputPasswordType}
            className="form-control__toggle-password-input toggle-password-input"
          >
            {passwordInputType === "password" ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="toggle-password-input__icon"
              >
                <path
                  d="M21.257 10.962C21.731 11.582 21.731 12.419 21.257 13.038C19.764 14.987 16.182 19 12 19C7.81801 19 4.23601 14.987 2.74301 13.038C2.51239 12.7411 2.38721 12.3759 2.38721 12C2.38721 11.6241 2.51239 11.2589 2.74301 10.962C4.23601 9.013 7.81801 5 12 5C16.182 5 19.764 9.013 21.257 10.962Z"
                  stroke="#D9D9D9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                  stroke="#D9D9D9"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="toggle-password-input__icon"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.8863 7.52789L20.7071 4.70711C21.0976 4.31658 21.0976 3.68342 20.7071 3.29289C20.3166 2.90237 19.6834 2.90237 19.2929 3.29289L17.0003 5.58546C15.5509 4.67954 13.8509 4 11.9996 4C9.5921 4 7.43669 5.14943 5.74925 6.46045C4.05187 7.77919 2.72444 9.3416 1.95061 10.3515C1.5851 10.8235 1.38672 11.4035 1.38672 12.0005C1.38672 12.5976 1.58516 13.1777 1.95078 13.6497C2.66275 14.5781 3.8466 15.9795 5.35881 17.227L3.29289 19.2929C2.90237 19.6834 2.90237 20.3166 3.29289 20.7071C3.68342 21.0976 4.31658 21.0976 4.70711 20.7071L7.54182 17.8724C7.56704 17.8497 7.59127 17.8255 7.6144 17.7998L10.4793 14.9349C10.5215 14.9038 10.5618 14.869 10.5998 14.8304C10.6338 14.7958 10.6648 14.7594 10.6928 14.7214L14.7187 10.6955C14.7579 10.6669 14.7956 10.635 14.8312 10.5999C14.871 10.5608 14.9068 10.5192 14.9386 10.4756L17.7819 7.63227C17.8188 7.60048 17.8538 7.56567 17.8863 7.52789ZM15.542 7.04375C14.4152 6.40378 13.2184 6 11.9996 6C10.2252 6 8.4986 6.85707 6.97629 8.0398C5.46525 9.21377 4.25592 10.631 3.53649 11.5701L3.53243 11.5754C3.438 11.697 3.38672 11.8466 3.38672 12.0005C3.38672 12.1544 3.43798 12.304 3.53241 12.4256L3.53624 12.4305C4.22766 13.3323 5.36393 14.6667 6.78033 15.8055L8.55222 14.0336C8.45888 13.8764 8.37621 13.7127 8.30494 13.5436C8.09996 13.0569 7.99335 12.5346 7.99126 12.0065C7.98917 11.4785 8.09165 10.9553 8.29278 10.4671C8.49391 9.97886 8.78972 9.53529 9.16313 9.16196C9.53655 8.78864 9.98019 8.49293 10.4685 8.29192C10.9567 8.0909 11.48 7.98855 12.008 7.99076C12.536 7.99297 13.0584 8.09971 13.5449 8.30481C13.7138 8.37599 13.8772 8.45853 14.0341 8.55171L15.542 7.04375ZM11.9996 9.99074C12.1769 9.99148 12.3529 10.0158 12.5231 10.0627L10.0632 12.5226C10.0163 12.3522 9.99195 12.1761 9.99124 11.9986C9.9902 11.7346 10.0414 11.473 10.142 11.2289C10.2426 10.9848 10.3905 10.763 10.5772 10.5763C10.7639 10.3897 10.9857 10.2418 11.2298 10.1413C11.474 10.0408 11.7356 9.98964 11.9996 9.99074Z"
                  fill="#D9D9D9"
                />
                <path
                  d="M20.2902 8.30619C19.907 7.90846 19.2739 7.89666 18.8762 8.27985C18.4785 8.66303 18.4667 9.29608 18.8498 9.69381C19.4222 10.2879 19.9605 10.9137 20.4623 11.5684L20.4665 11.5737C20.5611 11.6954 20.6125 11.8452 20.6125 11.9993C20.6126 12.1535 20.5614 12.3032 20.4669 12.425L20.4632 12.4299C19.7437 13.369 18.5344 14.7862 17.0234 15.9602C15.5017 17.1424 13.7759 17.9993 12.0022 18C11.4208 17.9971 10.8426 17.9115 10.2853 17.7456C9.75596 17.588 9.19912 17.8894 9.04156 18.4187C8.884 18.948 9.18537 19.5049 9.71471 19.6624C10.455 19.8828 11.223 19.9965 11.9954 20L12 20C14.4075 20 16.563 18.8506 18.2504 17.5396C19.9478 16.2208 21.2753 14.6583 22.0491 13.6484C22.4146 13.1761 22.6128 12.5957 22.6125 11.9983C22.6123 11.4012 22.4136 10.8212 22.0478 10.3493C21.5008 9.63577 20.914 8.95367 20.2902 8.30619Z"
                  fill="#D9D9D9"
                />
              </svg>
            )}
          </div>
        </div>
        <div className="mt-24 mt-16-mobile login-form__bottom">
          <LinkButton href="/forgot-password" text="Forgot your password?" />
          <UiBaseCheckbox
            name="remember_me"
            id="remember_me"
            value="1"
            label="Remember me"
            onChange={updateData}
          />
        </div>
        <div className="mt-64 text-center">
          <UiButton
            type="submit"
            size="large"
            text="Log in"
            className="ui-button_mw_200"
            disabled={logInDisabled}
          />
          <UiCaptionRegular
            margin="16"
            text={
              <>
                Need an account? <Link to="/sign-up">Sign up</Link>
              </>
            }
          />
        </div>
      </form>
      <Modal
        isOpen={show2faModal}
        onRequestClose={() => setShow2faModal(false)}
        className="an-modal an-modal_w_400 text-center"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__body an-modal__body_mh_0 mt-24 text-center">
          <img
            src={illustration1}
            alt="Two-factor authentication"
            className="responsive-img"
          />
          <UiHeading
            margin="32"
            elem="h6"
            text="Two-factor authentication"
            desktop="other-headings-h6-bold"
          />
          <UiCaptionRegular
            margin="8"
            text="Thanks for keeping your account secure. Please provide the code sent to your phone number."
            className="color-secondary-colour-secondary-7"
          />
          <div className="mt-40 text-left">
            <FormControl
              type="number"
              name="verificationCode"
              id="verification_code"
              label="Verification code from SMS"
              labelMargin="4"
              labelClassName="ui-caption-medium"
              placeholder="6-digit code"
              onChange={e => setVerificationCode(e.target.value)}
              value={verificationCode}
              validationError={errors.verificationCode}
            />
            <div className="mt-12">
              <UiBaseCheckbox
                name="trustedDevice"
                id="trusted_device"
                value="1"
                label="This is a trusted device, don’t ask again."
                onChange={e => setTrustedDevice(e.target.checked)}
                checked={trustedDevice}
              />
            </div>
          </div>
          <div className="mt-40">
            <UiButton
              text="Sign In"
              size="medium"
              className="d-block"
              disabled={signInDisabled}
              onClick={verify}
            />
            <UiButton
              text="Cancel"
              size="medium"
              design="secondary"
              className="d-block mt-8"
              onClick={reset}
              disabled={cancelDisabled}
            />
          </div>
        </div>
        {loading ? (
          <Preloader
            absolute={true}
            overflow={true}
          />
        ) : ''}
      </Modal>
    </>
  );
};

export default LoginForm;
