import React from "react";
import { DesktopDevice, MobileDevice } from "akar-icons";
import styles from "./PreviewInstance.module.scss";
import classNames from "classnames";
import UiSmallRegular from "../text/UiSmallRegular";
import { CircleChevronLeft, CircleChevronRight } from "akar-icons";

const PreviewInstance = (props) => {
  const handleChange = (e, index) => {
    e.preventDefault();

    if (props.active === index) return;
    props.onChange && props.onChange(index);
  };

  const toggleIndex = e => {
    e.preventDefault();

    props.onChange && props.onChange((props.active === 1) ? 0 : 1);
  }

  return (
    <div className={styles["base"]}>
      <div className={styles["inner"]}>
        <button
          className={styles["nav"]}
          onClick={toggleIndex}
        >
          <CircleChevronLeft
            size="24"
            color="#d9d9d9"
          />
        </button>
        <div className={styles["mid-buttons"]}>
          <button
            className={classNames(styles["instance"], {
              [`${styles["active"]}`]: props.active === 0,
            })}
            onClick={(e) => handleChange(e, 0)}
          >
            <DesktopDevice strokeWidth={2} size={24} />
          </button>
          <button
            className={classNames(styles["instance"], {
              [`${styles["active"]}`]: props.active === 1,
            })}
            onClick={(e) => handleChange(e, 1)}
          >
            <MobileDevice strokeWidth={2} size={24} />
          </button>
        </div>
        <button
          className={styles["nav"]}
          onClick={toggleIndex}
        >
          <CircleChevronRight
            size="24"
            color="#d9d9d9"
          />
        </button>
      </div>
      <UiSmallRegular
        margin="8"
        text={`This is your ${props.title.toLowerCase()}.`}
        className="text-center"
      />
    </div>
  );
};

export default PreviewInstance;
