import akarIconsCircleCheckFill from "../static/images/onboarding/akar-icons_circle-check-fill.svg"
import "./InstallationCompleted.scss";

const InstallationCompleted = () => {
  return (
    <div className="installation-status text-center">
      <div className="installation-status__inner">
        <img
          src={akarIconsCircleCheckFill}
          alt="Installation completed successfully"
          className="installation-status__icon"
        />
        <div className="email-templates-subtitle-2-bold mt-32">Installation completed successfully</div>
        <div className="email-templates-paragraph-1-regular">Please start the data synchronisation to complete the setup.</div>
      </div>
    </div>
  );
}

export default InstallationCompleted;