import { useState, useEffect } from "react";
import FormControl from "../ui-elements/forms/FormControl";
import TagList from "./TagList";
import "./Inputs.scss";

const Inputs = ({
  question, 
  onboardingData, 
  placeholder, 
  updateOnboardingData, 
  value, 
  next, 
  user,
  label
}) => {
  const { type, name, options, custom, columns, maxItems } = question;
  let [mixedData, setMixedData] = useState(value ? value : {});
  let [selectedOptions, setSelectedOptions] = useState(value ? value : []);
  let [tagList, setTagList] = useState([]);
  const [customInputValue, setCustomInputValue] = useState('');
  
  useEffect(() => {
    if (value && type === 'checkbox' && custom) {
      setTagList(value.filter(value => !options.includes(value)));
    }
  }, []);

  const updateData = e => {
    let updatedData = {};

    if (type === 'mixed') {
      const updatedMixedData = ({...mixedData, [e.target.name] : e.target.value});
      
      setMixedData(mixedData => updatedMixedData);

      updatedData = {...onboardingData, [name] : updatedMixedData};
    } else if (type === 'checkbox') {
      let updatedSelectedOptions = selectedOptions;

      if (e.target.checked) {
        updatedSelectedOptions.push(e.target.value);
      } else {
        updatedSelectedOptions = selectedOptions.filter(option => option !== e.target.value);
      }

      setSelectedOptions(updatedSelectedOptions);

      updatedData = {...onboardingData, [name] : updatedSelectedOptions};
    } else {
      updatedData = {...onboardingData, [e.target.name] : e.target.value};
    }

    updateOnboardingData(updatedData, {type, options, name});
  }

  const addCustomWords = e => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (!tagList.includes(e.target.value)) {
        tagList.push(e.target.value);
      }
      
      setTagList(tagList);
      setCustomInputValue('');

      tagList.map(tag => {
        if (!selectedOptions.includes(tag)) {
          selectedOptions.push(tag);
        }

        return tag;
      });

      setSelectedOptions(selectedOptions);

      updateOnboardingData({...onboardingData, [name] : selectedOptions}, {type, options, name});
    }
  }

  const removeTag = tag => {
    setTagList(tagList.filter(item => item !== tag));
    
    if (selectedOptions.includes(tag)) {
      const updatedSelectedOptions = selectedOptions.filter(item => item !== tag);

      setSelectedOptions(updatedSelectedOptions);

      updateOnboardingData({...onboardingData, [name] : updatedSelectedOptions}, {type, options, name});
    }
  }

  if (['checkbox', 'radio'].includes(type)) {
    return (
      <form className={`onboarding-inputs${columns && columns > 1 ? ` onboarding-inputs_col_${columns}` : ''}`}>
        {custom ? (
          <div className="onboarding-inputs__custom mb-40">
            <FormControl
              id="custom"
              name="custom"
              label="Enter custom words or terms"
              labelMargin="16"
              onKeyDown={addCustomWords}
              placeholder="Write a custom word or a phrase here"
              type="text"
              onChange={e => setCustomInputValue(e.target.value)}
              value={customInputValue}
            />
            <TagList
              list={tagList}
              onClick={removeTag}
            />
          </div>
        ) : ('')}
        {options.map((option, key) => {
          const checked = onboardingData && onboardingData[name] && (onboardingData[name] === option || onboardingData[name].includes(option))
          const disabled = maxItems ? selectedOptions.length === maxItems && !checked : false
          return (
            <div
              className={`onboarding-input${columns && columns > 1 ? ` onboarding-input_col_${columns}` : ''}`}
              key={key}
            >
              <input
                type={type}
                name={name}
                value={option || ''}
                className="onboarding-input__elem"
                onChange={updateData}
                id={`${name}_${key}`}
                checked={checked}
                disabled={disabled}
              />
              <label 
                htmlFor={`${name}_${key}`} 
                className={`ui-caption-regular color-secondary-colour-secondary-7 onboarding-input__label onboarding-input__label_${type}`}
              >
                {option}
              </label>
            </div>
          )
        })}
      </form>
    );
  } else if (type === 'mixed') {
    return (
      <form action="" className="onboarding-inputs">
        {options.map((option, key) => (
          <FormControl
            margin="16"
            id={option.name}
            key={key}
            name={option.name}
            label={option.label}
            labelMargin="16"
            onChange={updateData}
            placeholder={option.placeholder}
            type={option.type}
            defaultValue={value?.[option.name] ?? ''}
          />
        ))}
      </form>
    )
  } else if (type === 'textarea') {
    let defaultValue = value ?? ''
    if (value?.[name]) defaultValue=value?.[name]

    return (
      <form action="" className="onboarding-inputs">
        <FormControl
          margin="16"
          id={name}
          name={name}
          label={label ?? ''}
          labelMargin="16"
          onChange={updateData}
          placeholder={placeholder}
          type={type}
          defaultValue={defaultValue}
        />
      </form>
    )
  }
}

export default Inputs;