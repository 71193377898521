import React, { useState } from 'react'
import { useParams, NavLink } from "react-router-dom";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { useSort, HeaderCellSort } from "@table-library/react-table-library/sort";
import { Table, Header, HeaderRow, Body, Row, Cell } from "@table-library/react-table-library/table";

import SectionHead from '../../ui-elements/common/SectionHead';
import UiButton from '../../ui-elements/buttons/UiButton';
import AnTable from '../../ui-elements/common/AnTable';
import Chips from '../../ui-elements/common/Chips';
import Pagination from '../../ui-elements/common/Pagination';
import PageSize from '../../ui-elements/common/PageSize';

import AkarIconsChevronVertical from "../../static/images/common/akar-icons_chevron-vertical.svg";
import AkarIconsChevronVerticalCopy from "../../static/images/common/akar-icons_chevron-vertical copy.svg";

import useCampaign from '../../hooks/useCampaign';

const SurveysPage = () => {
  const { campaignSlug } = useParams();
  const { shopCampaigns } = useCampaign();
  const [shopCampaign, setShopCampaign] = useState(() => {
    return shopCampaigns.filter((campaign) => campaign.slug === campaignSlug)
  });

  const theme = useTheme([getTheme(), {
    Table: `--data-table-library_grid-template-columns: repeat(2, minmax(0, 1fr)) repeat(3, 120px)`
  }]);

  const nodes = shopCampaign.map(campaign => {
    let instanceSubjectLine = '';

    if (campaign?.customizer) {
      Object.keys(campaign.customizer).map(key => {
        if (!instanceSubjectLine && key.includes('email') && campaign.customizer[key]?.emailSubjectLine) {
          instanceSubjectLine = campaign.customizer[key].emailSubjectLine;
        }

        return key;
      });
    }

    return {
      ...campaign,
      instanceSubjectLine
    }
  });

  const timeFormat = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  }

  const sort = useSort({ nodes }, {}, {
    sortIcon: {
      margin: '0px',
      iconDefault: <img src={AkarIconsChevronVertical} alt="" />,
      iconUp: <img src={AkarIconsChevronVerticalCopy} alt="" />,
      iconDown: <img src={AkarIconsChevronVerticalCopy} alt="" />,
    },
    sortFns: {
      INSTANCENAME: array => array.sort((a, b) => a.name.localeCompare(b.name)),
      DATE: array => array.sort((a, b) => {
        const aDate = new Date(a);
        const bDate = new Date(b);
        return aDate - bDate
      }),
      STATUS: array => array.sort((a, b) => a.status.localeCompare(b.status)),
      SURVEYSSENT: array => array.sort((a, b) => a.emailsSent - b.emailsSent),
      ANSWERED: array => array.sort((a, b) => a.completedSurveys - b.completedSurveys),
      CLICKED: array => array.sort((a, b) => a.clicked - b.clicked),
      ORDERED: array => array.sort((a, b) => a.purchased - b.purchased),
    },
  });

  const changePageSize = value => pagination.fns.onSetSize(value[0].value);
  const onPaginationChange = (action, state) => {
    console.log(action, state);
  }
  const pagination = usePagination(nodes, {
    state: {
      page: 0,
      size: 10,
    },
    onChange: onPaginationChange,
  });

  return (
    <div className="card card_br_24 card_24 card_shadow card_h_survey card_flex">
      <SectionHead
        align="center"
        title={
        <>
            <div className="email-templates-subtitle-2-medium">Table of surveys</div>
            <div className="mt-4 email-templates-caption-regular color-secondary-colour-secondary-4">Latest activity</div>
        </>
        }
        filter={
          <div className="d-flex d-flex_gap_12">
              {/* TODO: commented because it doesn't work well with CSFLE encrypted fields */}
              {/* <FormControl
              type="text"
              name="search"
              id="search"
              placeholder="Search"
              className="form-control__input_search form-control__input_size_xsmall"
              value={search}
              onChange={handleSearch}
              /> */}
              {/* TODO: no filters defined */}
          </div>
        }
      />
      <div className="mt-24 h-100">
        <AnTable
          className="an-table_survey"
          body={
            <>
              <Table
                data={{ nodes }}
                sort={sort}
                theme={theme}
                layout={{custom: true}}
              >
                {(tableList) => {
                  if (!tableList) return <span>No available data</span>
                  return (
                    <>
                      <Header>
                        <HeaderRow>
                          <HeaderCellSort sortKey="INSTANCENAME">Survey Name</HeaderCellSort>
                          <HeaderCellSort sortKey="DATE" className="table-align-right">Time</HeaderCellSort>
                          <HeaderCellSort sortKey="STATUS">Status</HeaderCellSort>
                          <HeaderCellSort sortKey="SURVEYSSENT">Surveys sent</HeaderCellSort>
                          <HeaderCellSort sortKey="ANSWERED">Answered</HeaderCellSort>
                        </HeaderRow>
                      </Header>
                      <Body>
                        {tableList.map((item) => (
                          <Row item={item} key={item._id}>
                            <Cell>
                              <NavLink className="underline" to={`/campaigns/customer-survey/${item._id}`}>{item.name}</NavLink>
                            </Cell>
                            <Cell>{new Intl.DateTimeFormat('en-US', timeFormat).format(new Date(item.createdAt))}</Cell>
                            <Cell>
                              {item.status && (
                                <Chips
                                  color={item.status === 'active' ? 'green' : 'red'}
                                  value={item.status}
                                />
                              )}
                            </Cell>
                            <Cell>{item.emailsSent.toLocaleString()}</Cell>
                            <Cell>{item.completedSurveys.toLocaleString()}</Cell>
                          </Row>
                        ))}
                      </Body>
                    </>
                  )
                }}
              </Table>
              <div className="an-table__bottom pl-0 pr-0">
                <PageSize onChange={changePageSize} />
                <Pagination
                  pagination={pagination}
                  pagesCount={pagination.state.getTotalPages(nodes)}
                />
              </div>
            </>
          }
        />
      </div>
    </div>
  );
}

export default SurveysPage;