import { useState, useEffect } from "react";
import axios from "axios";
import Preloader from "../../preloader/Preloader";
import NoDomain from "../../store-settings/NoDomain";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import DomainStatus from "../../store-settings/DomainStatus";
import SenderAddress from "../../store-settings/SenderAddress";
import MainContent from "../../dashboard/MainContent";

const DomainAccounts = () => {
  const [loading, setLoading] = useState(true);
  const [domains, setDomains] = useState([]);
  const [emails, setEmails] = useState([]);

  const refresh = () => {
    axios
      .get("/domains", {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setDomains(response.data);

        if (response.data.length) {
          axios
            .get("/domains/emails", {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("token")}`,
              },
            })
            .then((response) => {
              setEmails(response.data);
              setLoading(false);
            })
            .catch((err) => {
              console.error(err);

              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);

        setLoading(false);
      });
  };

  useEffect(() => {
    refresh();
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <MainContent fullHeight={!domains.length}>
      {domains.length ? (
        <div className="an-layout">
          <SectionHead
            title={
              <>
                <UiHeading
                  text="Domain status"
                  desktop="email-templates-subtitle-2-bold"
                />
                <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                  Monitor and manage your domains to ensure compliance and
                  security. This will improve the success and effectiveness of
                  your email marketing campaigns.
                </div>
              </>
            }
          />
          <DomainStatus domains={domains} refresh={refresh} />
          {domains[0].verified ? (
            <>
              <SectionHead
                title={
                  <>
                    <UiHeading
                      elem="h2"
                      text="Sender address"
                      desktop="email-templates-subtitle-2-bold"
                    />
                    <div className="email-templates-paragraph-2-regular color-secondary-colour-secondary-7 mt-4">
                      The sender address is the email address that recipients
                      see as the sender of the email. It typically appears in
                      the "From" field of the email client. It's important for
                      to use a recognizable and trustworthy sender address to
                      increase the likelihood of their emails being opened and
                      to maintain good sender reputation.
                    </div>
                  </>
                }
              />
              <SenderAddress emails={emails} refresh={refresh} />
            </>
          ) : (
            ""
          )}
        </div>
      ) : (
        <NoDomain />
      )}
    </MainContent>
  );
};

export default DomainAccounts;
