import { useState, useEffect } from "react";
import { Check, Cross } from "akar-icons";
import axios from "axios";
import Modal from "react-modal";
import UiHeading from "../ui-elements/text/UiHeading";
import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import AccordionItem from "../ui-elements/accordion/AccordionItem";
import UiButton from "../ui-elements/buttons/UiButton";
import UiSmallRegular from "../ui-elements/text/UiSmallRegular";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import illustration2 from "../static/images/billing/Illustration (2).svg";
import "./PackageItem.scss";

import PaymentModal from "./PaymentModal";
import { Notify } from "../ui-elements/notify/notify";
import { createPaymentMethod } from "../api/Auth";

Modal.setAppElement('#root');

const PackageItem = props => {
  const [campaigns, setCampaigns] = useState([]);
  const [services, setServices] = useState([]);
  const [openPayment, setOpenPayment] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [upgradeText, setUpgradeText] = useState('');

  const handleItemClick = index => setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  const closeModal = () => setModalIsOpen(false);

  useEffect(() => {
    let campaignsIncluded = [];
    let servicesIncluded = [];

    if (props.package.campaigns.length) {
      props.campaigns.map(campaign => {
        if (props.package.campaigns.includes(campaign._id)) {
          campaignsIncluded.push(campaign.name);
        }

        return campaign;
      })
    }

    if (props.package.services.length) {
      props.services.map(service => {
        if (props.package.services.includes(service._id)) {
          if (service.isWidgetService) {
            servicesIncluded.push(props.package.widgetsPricingText);
          } else {
            servicesIncluded.push(service.name);
          }
        }

        return service;
      });
    }

    setCampaigns(campaignsIncluded);
    setServices(servicesIncluded);
  }, []);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0
  });

  const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  });

  const changePlan = () => {
    setLoading(true);
    const isDowngrade = props.currentPackage.emailsPerMonth > props.package.emailsPerMonth;
    setUpgradeText(isDowngrade ? 'Your previous plan features are still accessible until the end of the current billing cycle. Your next billing cycle will reflect the new plan details.' : <>Your upgraded plan benefits are active immediately.<br />Your next billing cycle will reflect the new plan details.</>);

    axios.post('/user/plan', {
      packageId: props.package._id,
      isDowngrade: isDowngrade
    }, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    }).then(response => {
      props.setCurrentPackageId(response.data);

      setLoading(false);
      setModalIsOpen(true);
    }).catch(e => {
      const resStatus = e.response.status;
      if (resStatus == 403) setOpenPayment(true);
    });
  }

  const handleCreatePayment = async(paymentId) => {
    try {
      await createPaymentMethod(paymentId);
      Notify.success({
        title: 'Changes saved successfully',
        message: 'A new payment method has been added.'
      });
      setOpenPayment(false)

      changePlan();
    } catch (error) {
      Notify.error({ title: error.response.data.message });
    }
  }

  return (
    <div className="card card_16 package-item card_border">
      <UiHeading
        element="h6"
        text={props.package.name}
        className="color-golden-purple-purple-5"
        desktop="other-headings-h6-bold"
      />
      <UiHeading
        element="div"
        text={<><span className="ui-other-headings-h4-bold">{formatter.format(props.package.price / 100)}</span> per month{props.package.price === 0 ? ', forever' : ''}</>}
        desktop="other-headings-h6-bold"
      />
      <div className="mt-8 pt-24">
        <div className="package-item__included-label color-golden-purple-purple-5 text-uppercase">Included in the price</div>
        {props.package.emailsPerMonth ? (
          <UiCaptionMedium
            className="package-item__emails mt-16 pt-16"
            text={`${numberFormatter.format(props.package.emailsPerMonth)} emails per month*`}
          />
        ) : ''}
        {campaigns.length || services.length ? (
          <div className="accordion mt-16">
            {campaigns.length ? (
              <AccordionItem
                title="Campaigns"
                isOpen={activeIndex === 0}
                onClick={() => handleItemClick(0)}
                content={
                  <ul className="services-list">
                    {campaigns.map((campaign, key) => (
                      <li
                        className="services-list__item"
                        key={key}
                      >
                        <Check
                          size="16"
                          color="#6c5e77"
                        />
                        <div className="email-templates-paragraph-2-regular">{campaign}</div>
                      </li>
                    ))}
                  </ul>
                }
              />
            ) : ''}
            {services.length ? (
              <AccordionItem
                isOpen={activeIndex === 1}
                title="Service"
                onClick={() => handleItemClick(1)}
                content={
                  <ul className="services-list">
                    {services.map((service, key) => (
                      <li
                        className="services-list__item"
                        key={key}
                      >
                        <Check
                          size="16"
                          color="#6c5e77"
                        />
                        <div className="email-templates-paragraph-2-regular">{service}</div>
                      </li>
                    ))}
                  </ul>
                }
              />
            ) : ''}
          </div>
        ) : ''}
      </div>
      <UiButton
        text={props.package._id === props.currentPackage._id ? 'Current plan' : 'Choose plan'}
        disabled={props.package._id === props.currentPackage._id || loading}
        size="medium"
        design="secondary"
        className="mt-24 package-item__button"
        onClick={changePlan}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="an-modal text-center"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          <div className="an-modal__title an-modal__title_fill pt-44 pb-44">
            <img
              src={illustration2}
              alt=""
              className="responsive-img"
            />
            <UiHeading
              element="h6"
              desktop="other-headings-h6-bold"
              text="Plan updated successfully!"
              margin="16"
            />
            <UiCaptionRegular
              className="mt-8 color-secondary-colour-secondary-7"
              text={upgradeText}
            />
          </div>
          <button
            onClick={closeModal}
            className="an-modal__close"
          >
            <Cross
              size="24"
              color="#d9d9d9"
            />
          </button>
        </div>
        <div className="an-modal__body an-modal__body_mh_0 mt-24 text-right">
          <UiButton
            text="Got it"
            onClick={closeModal}
            size="medium"
          />
        </div>
      </Modal>
      <PaymentModal 
        open={openPayment}
        setOpen={setOpenPayment}
        handleCreate={handleCreatePayment}
        handleClose={() => setLoading(false)}
      />
      <UiSmallRegular
        margin="24"
        className="color-secondary-colour-secondary-7 text-center"
        text={props.package.price ? '*Extra charges will apply once email quota is reached.' : '*After email quota is reached, we will stop sending emails until next month reset.'}
      />
    </div>
  )
}

export default PackageItem;