import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useWebSocket from "react-use-websocket";
import Progress from "./Progress";
import Question from "./Question";
import UiButton from "../ui-elements/buttons/UiButton";
import Stepper from "../stepper/Stepper";
import Preloader from "../preloader/Preloader";
import ShopImport from "./ShopImport";
import DownloadPlugin from "./DownloadPlugin";
import InstallationCompleted from "./InstallationCompleted";
import PluginNotFound from "./PluginNotFound";
import SyncInProgress from "./SyncInProgress";
import SyncCompleted from "./SyncCompleted";
import useAuth from '../hooks/useAuth';
import "./Questionnaire.scss";

const Questionnaire = props => {
  const [step, setStep] = useState(props.step ? props.step : 1);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [sections, setSections] = useState([]);
  const [onboardingData, setOnboardingData] = useState(null);
  const [currentSection, setCurrentSection] = useState(1);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [importStep, setImportStep] = useState(false);
  const [nextTextButton, setNextTextButton] = useState('Next');
  const [shopConnected, setShopConnected] = useState(false);
  const [downloadPluginStep, setDownloadPluginStep] = useState(false);
  const [syncStep, setSyncStep] = useState(false);
  const [pluginInstalled, setPluginInstalled] = useState(false);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [syncCompleted, setSyncCompleted] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [shopImported, setShopImported] = useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  const { sendMessage } = useWebSocket(process.env.REACT_APP_SOCKET_URL, {
    shouldReconnect: () => true,
    onError: error => console.error('WebSocket error:', error),
    onMessage: message => {
      const data = JSON.parse(message.data);

      if (data.message === 'importFinished') {
        setSyncInProgress(false);
        setSyncCompleted(true);
      }
    },
  });

  useEffect(() => {
    const urls = ['/user', '/onboarding/sections', '/onboarding/questions', '/shop/import'];
    const token = localStorage.getItem('token');

    const requests = urls.map(url => {
      return axios.get(url, {
        headers: {
          Authorization: `Bearer: ${token}`
        }
      });
    });
  
    axios.all(requests)
      .then(response => {
        const [user, sections, questions, shopImport] = response
        let isShopConnected = false;

        let currentStep = user.data.onboardingStep ? user.data.onboardingStep : 1;

        //first skipped question
        const questionNames = questions.data.map(question => question.name);
        const onnboardingKeys = Object.keys(user.data?.onboardingData || {});
        if (!!onnboardingKeys.length) {
          currentStep = questionNames.findIndex((questionName) => !onnboardingKeys.includes(questionName)) + 1;
        }
        
        setStep(currentStep > questions.data.length ? currentStep - 1 : currentStep);
        setQuestions(response[2].data);
        setProgress(currentStep / response[2].data.length * 100);
        setOnboardingData(props.user.onboardingData);

        if (response[0].data.shops.length) {
          setShopConnected(true);

          isShopConnected = true;
        } else {
          response[1].data.push({
            name: 'Connect your store',
            order: 4
          });
        }

        setSections(response[1].data);

        if (typeof response[2].data[currentStep - 1] === 'undefined') {
          setCurrentSection(response[1].data.length);

          if (isShopConnected) {
            setDownloadPluginStep(true);
            setNextTextButton('Check plugin');
          } else {
            setImportStep(true);
            setNextTextButton('Next');
            setNextDisabled(true);
          }
        } else {
          response[1].data.map((section, key) => {
            if (section._id === response[2].data[currentStep - 1].section) {
              setCurrentSection(key + 1);
            }

            return section;
          });
        }

        setLoading(false);

        if (response[3].data) {
          if (response[3].data === 'started') {
            setSyncInProgress(true);
          } else if (response[3].data === 'finished') {
            const isShopImported = true;

            setLoading(true);
            setShopImported(true);

            const initialProgress = 1 / response[2].data.length * 100;

            axios.get('/shop', {
              headers: {
                Authorization: `Bearer: ${token}`
              }
            }).then(response => {
              if (!response.data.onboarded) {
                setSyncInProgress(false);
                setSyncCompleted(false);
                setDownloadPluginStep(false);
                setImportStep(false);
                setSyncStep(false);
                // setStep(1);
                setNextTextButton('Next');
                // setProgress(initialProgress);
              } else {
                setSyncInProgress(false);
                setSyncCompleted(true);
              }

              setLoading(false);

              if (isShopImported) {
                navigate('/');
              }
            }).catch(err => {
              console.error(err);

              setLoading(false);
            });
          }
        }

        setShopConnected(isShopConnected);
      })
      .catch(err => {
        setLoading(false);

        console.error(err);
      });

    sendMessage(JSON.stringify({ token }));
  }, []);

  const updateOnboardingData = (data, inputProps) => {
    let updatedOnboardingData = onboardingData;
    let isStepValid = true;

    Object.keys(data).map(item => {
      updatedOnboardingData = { ...data, [item]: data[item] };

      return item;
    });

    setOnboardingData(updatedOnboardingData);

    if (inputProps.type === 'mixed') {
      inputProps.options.map(option => {
        if (updatedOnboardingData?.[inputProps.name]?.[option.name] && option.type === 'url' && !isValidUrl(updatedOnboardingData[inputProps.name][option.name])) {
          isStepValid = false;
        }

        return option;
      });
    }

    isStepValid ? setNextDisabled(false) : setNextDisabled(true);
  }

  const isValidUrl = urlString => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$', 'i');

    return !!urlPattern.test(urlString);
  }

  const updateStep = (step, update) => {
    setStep(step);
    if (step > questions.length) {
      if (props.user.shops.length && shopConnected && shopImported) {
        navigate('/');
      } else {
        if (shopConnected) {
          setDownloadPluginStep(true);
          setNextTextButton('Check plugin');
        } else {
          setImportStep(true);
          setNextTextButton('Next');
          setNextDisabled(true);
        }
      }

      setCurrentSection(sections.length);
    } else {
      setImportStep(false);
      setNextDisabled(false);
      setDownloadPluginStep(false);
      setSyncStep(false);
      setNextTextButton('Next');
      setProgress(step / questions.length * 100);

      sections.map((section, key) => {
        if (section._id === questions[step - 1]?.section) {
          setCurrentSection(key + 1);
        }

        return section;
      });
    }

    if (update) {
      axios.put('/user', {
        onboardingData: onboardingData,
        onboardingStep: step
      }, {
        headers: {
          Authorization: `Bearer: ${token}`
        }
      })
      // setUser({...user, onboardingData: onboardingData})
      const currentShop = localStorage.getItem('currentShop');

      if (currentShop) {
        axios.put('/shop', {
          onboardingData: onboardingData
        }, {
          headers: {
            Authorization: `Bearer: ${token}`
          }
        });
      }
    }
  }

  const prev = () => {
    if (syncStep || pluginInstalled) {
      setImportStep(true);
      setSyncStep(false);
      setNextDisabled(false);
      setNextTextButton('Check plugin');
    } else {
      step === 1 ? props.setIntroState() : updateStep(step ? step - 1 : questions.length, true);
    }
  }

  const checkPlugin = async () => {
    setLoading(true);

    try {
      await axios.get('/shop/helper/installed', {
        headers: {
          Authorization: `Bearer: ${token}`
        }
      }).then(response => {
        const isPluginInstalled = !!response.data.version;

        setSyncStep(true);
        setPluginInstalled(isPluginInstalled);
        setNextTextButton('Sync store');
        setNextDisabled(false);
        setLoading(false);
      });
    } catch (error) {
      setSyncStep(true);
      setPluginInstalled(false);
      setNextTextButton('Sync store');
      setNextDisabled(true);
      setLoading(false);
    }
  }

  const next = async () => {
    if (syncStep) {
      setSyncInProgress(true);

      try {
        await axios.post('/shop/import', {}, {
          headers: {
            Authorization: `Bearer: ${token}`
          }
        });
      } catch (error) {
        setLoading(false);

        // TODO: maybe set additional screen if sync failed to restart it

        console.error(error);
      }
    } else if (downloadPluginStep) {
      await checkPlugin();
    } else {
      console.log(step);

      updateStep(step + 1, true);
    }
  }

  if (loading) {
    return <Preloader />
  }

  return (
    <div className="onboarding-questionnaire">
      {(syncInProgress || syncCompleted) ? (
        <div className="onboarding-questionnaire__sync text-center">
          {syncInProgress ? <SyncInProgress /> : <SyncCompleted />}
        </div>
      ) : (
        <div>
          {(downloadPluginStep || importStep || syncStep) ? (
            <div>
              {syncStep ? (
                <div>
                  {pluginInstalled ? <InstallationCompleted /> : <PluginNotFound checkPlugin={checkPlugin} />}
                </div>
              ) : (
                <div>
                  {downloadPluginStep ? (
                    <div className="onboarding-question">
                      <div className="email-templates-subtitle-2-bold text-center mt-24">Download Anomate plugin</div>
                      <div className="email-templates-paragraph-1-regular text-center mt-8 color-secondary-colour-secondary-7">To integrate the Anomate Plugin with your WordPress site, follow the steps below. <br />Click the download button to obtain the plugin and then proceed with the upload.</div>
                      <div className="onboarding-question__answers">
                        <DownloadPlugin />
                      </div>
                    </div>
                  ) : (
                    <div className="onboarding-question">
                      <div className="email-templates-subtitle-2-bold text-center mt-24">Authorise Anomate to connect to your store</div>
                      <div className="email-templates-paragraph-1-regular text-center mt-8 color-secondary-colour-secondary-7">Choose your store platform and authorise Anomate to initiate the integration. <br />If the shop URL provided is incorrect, no worries – you can correct it first and then proceed with the authorisation.</div>
                      <div className="onboarding-question__answers">
                        <ShopImport
                          user={props.user}
                          disablePrev={() => setPrevDisabled(true)}
                          enablePrev={() => setPrevDisabled(false)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <Progress value={progress} />
              {questions.map((question, key) => (
                (key === step - 1) ? (
                  <Question
                    key={key}
                    question={questions[step - 1]}
                    value={onboardingData ? onboardingData[questions[step - 1].name] : ''}
                    current={step}
                    total={questions.length}
                    onboardingData={onboardingData}
                    updateOnboardingData={updateOnboardingData}
                    next={next}
                    user={props.user}
                    placeholder={question.placeholder}
                  />
                ) : ('')
              ))}
            </div>
          )}
        </div>
      )}
      {(!(syncCompleted || syncInProgress)) ? (
        <div className="onboarding-questionnaire__bottom">
          <UiButton
            onClick={prev}
            size="medium"
            text="Back"
            design="secondary"
            className="ui-button_mw_150"
            disabled={prevDisabled}
          />
          <Stepper
            sections={sections}
            currentSection={currentSection}
          />
          <UiButton
            onClick={next}
            size="medium"
            text={nextTextButton}
            design="secondary"
            className="ui-button_mw_150"
            disabled={nextDisabled}
          />
        </div>
      ) : ('')}
    </div>
  );
}

export default Questionnaire;