import { useState, useEffect } from "react";
import axios from "axios";
import { Table, Header, HeaderRow, Body, Row, Cell } from "@table-library/react-table-library/table";
import { useSort, HeaderCellSort } from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import SectionHead from "../ui-elements/common/SectionHead";
import AnTable from "../ui-elements/common/AnTable";
import ProductPreview from "./ProductPreview";
import AkarIconsChevronVertical from '../static/images/common/akar-icons_chevron-vertical.svg';
import AkarIconsChevronVerticalCopy from '../static/images/common/akar-icons_chevron-vertical copy.svg';
import "./TopSellers.scss";

const TopSellers = props => {
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    axios.get('/shop/top-sellers', {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem('token')}`
      }
    }).then(response => {
      setNodes(response.data);
    }).catch(e => console.error(e));
  }, []);

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: props.currency
  });

  const theme = useTheme([getTheme(), {
    Table: `
      --data-table-library_grid-template-columns: minmax(0, 1fr) 80px 100px 110px
    `
  }])

  const sort = useSort({ nodes }, {}, {
    sortIcon: {
      margin: '0px',
      iconDefault: <img src={AkarIconsChevronVertical} alt="" />,
      iconUp: <img src={AkarIconsChevronVerticalCopy} alt="" />,
      iconDown: <img src={AkarIconsChevronVerticalCopy} alt="" />
    },
    sortFns: {
      ITEM: array => array.sort((a, b) => a.name.localeCompare(b.name)),
      QTY: array => array.sort((a, b) => a.quantity - b.quantity),
      PRICE: array => array.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)),
      TOTALPRICE: array => array.sort((a, b) => a.total - b.total),
    }
  });

  return (
    <>
      {nodes.length ? (
        <div className={`card card_24 card_br_24 card_shadow ${props.className}${props.performanceDisabled ? ' blured' : ''}`}>
          <SectionHead
            title={
              <>
                <h5 className="email-templates-subtitle-2-medium">Top sellers</h5>
                <div className="mt-4 email-templates-caption-regular color-secondary-colour-secondary-4">Discover the most popular and best-selling products that are driving success in your campaigns.</div>
              </>
            }
          />
          <div className="mt-24 top-sellers-table">
            <AnTable
              body={
                <Table
                  data={{ nodes }}
                  sort={sort}
                  theme={theme}
                  layout={{
                    custom: true
                  }}
                >
                  {(tableList) => (
                    <>
                      <Header>
                        <HeaderRow>
                          <HeaderCellSort sortKey="ITEM">Item</HeaderCellSort>
                          <HeaderCellSort sortKey="QTY">Qty</HeaderCellSort>
                          <HeaderCellSort sortKey="PRICE">Price</HeaderCellSort>
                          <HeaderCellSort sortKey="TOTALPRICE">Total Price</HeaderCellSort>
                        </HeaderRow>
                      </Header>
                      <Body>
                        {tableList.map(item => (
                          <Row
                            item={item}
                            key={item._id}
                          >
                            <Cell>
                              <ProductPreview
                                src={item.images.length ? item.images[0].src : null}
                                alt={item.images.length ? item.images[0].alt : null}
                                name={item.name}
                              />
                            </Cell>
                            <Cell>{item.quantity}</Cell>
                            <Cell>{formatter.format(parseFloat(item.price))}</Cell>
                            <Cell>{formatter.format(parseFloat(item.total))}</Cell>
                          </Row>
                        ))}
                      </Body>
                    </>
                  )}
                </Table>
              }
            />
          </div>
        </div>
      ) : ('')}
    </>
  )
}

export default TopSellers;