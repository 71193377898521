import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useMemo,
} from "react";
import FormControl from "../../forms/FormControl";
import { useFormik } from "formik";
import * as yup from "yup";
import useAuth from "../../../hooks/useAuth";

const CouponAmount = forwardRef((props, ref) => {
  const { shopData } = useAuth();

  const initialValues = {
    amount: props.value,
  };

  const formatMoney = number => {
    return number.toLocaleString('en-US', {
      style: 'currency',
      currency: shopData.currency
    });
  }

  const getOptionLabel = (value) => {
    switch (props.type) {
      case "percentage":
        return `${value}%`;
      case "fixed":
        return `${formatMoney(parseInt(value))}`;
      default:
        break;
    }
  };

  const options = useMemo(() => {
    return [
      { label: getOptionLabel(5), value: 5 },
      { label: getOptionLabel(10), value: 10 },
      { label: getOptionLabel(20), value: 20 },
      { label: 'Other amount', value: 'other' }
    ];
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      amount:
        props.type === "percentage"
          ? yup
              .number()
              .positive("This is mandatory")
              .lessThan(100, "Amount should be less than 100")
              .required("This is mandatory")
          : yup
              .number()
              .positive("This is mandatory")
              .required("This is mandatory"),
    }),
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(false);
      props.onSubmit && props.onSubmit(values.amount);
    },
  });

  const runValidation = () => formik.validateForm().then(errors => props.onValidate(Object.keys(errors).length === 0));

  useEffect(() => {
    runValidation()
  }, [props.value]);

  useImperativeHandle(
    ref,
    () => ({
      submit() {
        formik.handleSubmit();
      }
    }),
    []
  );

  return (
    <div className="an-layout an-layout_gap_8">
      <FormControl
        label={`How much discount would you like to offer?`}
        variant="pill"
        type="radio"
        name="amount"
        id="amount"
        value={Number(formik.values.amount)}
        options={options}
        blockRadios
        labelClassName="ui-caption-medium"
        labelMargin="4"
        onReset={() => formik.setFieldValue('amount', undefined)}
        onChange={(e) => {
          formik.handleChange(e);

          setTimeout(() => {
            props.onNext();
          }, 100);
        }}
        onCustomChange={(value, name) => {
          formik.setFieldValue(name, value);

          runValidation();
        }}
        blocks={2}
        validationError={
          formik.touched.amount &&
          Boolean(formik.errors.amount)
            ? formik.errors.amount
            : false
        }
      />
    </div>
  );
});

CouponAmount.displayName = "CouponAmount";
export default CouponAmount;
