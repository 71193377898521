import axios from "axios";

export const getImages = query => {
  return new Promise((resolve, reject) => {
    let url = '/stock';

    if (query) {
      url += `?query=${query}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};