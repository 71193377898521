import React, {
  useState,
  useMemo,
  useContext,
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef,
} from "react";
import { getShopWidgets } from "../api/Shop";
import useStep from "../hooks/useStep";
import StepWizardNavigation from "../ui-elements/step-wizard/StepWizardNavigation";
import CustomizationContainer from "../ui-elements/customization/customization-container/CustomizationContainer";
import { getCampaignPreview } from "../api/Customizer";
import { CampaignContext } from "../context/CampaignContext";
import { useParams } from "react-router-dom";
import CustomizationOptionsContainer from "../ui-elements/customization/customiztion-options-container/CustomizationOptionsContainer";
import Select from "react-dropdown-select";
import UiCaptionMedium from "../ui-elements/text/UiCaptionMedium";
import WidgetPreview from "../widgets/WidgetPreview";
import WidgetPreviewBody from "../widgets/WidgetPreviewBody";
import Preloader from "../preloader/Preloader";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import "./WidgetOrganizationStep.scss";
import { getSortedWidgets, emailBodyWidget } from "./tools";

const WidgetOrganizationStep = () => {
  const { stepData, handleSkip, steps } = useStep();
  const [widgets, setWidgets] = useState([]);
  const { handlePrev, handleNext } = useStep();
  const [loading, setLoading] = useState(false);
  const [widgetLoading, setWidgetLoading] = useState(false);
  const [previewHTML, setPreviewHTML] = useState("");
  const { campaigns } = useContext(CampaignContext);
  const { campaignSlug } = useParams();
  const campaign = campaigns.find((campaign) => campaign.slug === campaignSlug);
  const sections = steps.preview.sections;
  const emails = sections.filter((section) => section.type.includes("email"));
  const [selectedEmail, setSelectedEmail] = useState(emails[0]);
  const selectedWidgetIds = stepData.widgets;
  const [customizer, setCustomizer] = useState(null);
  const [draggingItem, setDraggingItem] = useState(null);

  useLayoutEffect(() => {
    if (!selectedWidgetIds.length) {
      handleSkip();
    }
  }, [selectedWidgetIds]);

  useEffect(() => {
    if (!selectedWidgetIds.length) return;
    fetchWidgets();
  }, [selectedWidgetIds]);

  useEffect(() => {
    if (!stepData.widgets || !stepData.widgets.length) return;
    const _customizer = {};
    Object.keys(stepData.customizer).forEach((key) => {
      if (key.includes("email")) {
        if (!stepData.customizer[key]?.widgets) {
          _customizer[key] = {
            ...stepData.customizer[key],
            widgets: stepData.widgets,
          };
        } else {
          const customizerWidgets = stepData.customizer[key].widgets.filter(
            (id) => stepData.widgets.includes(id)
          );
          let mergedWidgets = [...customizerWidgets, ...stepData.widgets];
          const _widgets = [];

          mergedWidgets.forEach((id) => {
            if (!_widgets.includes(id)) {
              _widgets.push(id);
            }
          });

          _customizer[key] = {
            ...stepData.customizer[key],
            widgets: _widgets,
          };
        }
      } else {
        _customizer[key] = stepData.customizer[key];
      }
    });
    setCustomizer(_customizer);
  }, [stepData, widgets]);

  const selectedEmailWidgets = useMemo(() => {
    if (!customizer) return [];

    let key = selectedEmail.type;
    if (selectedEmail.index) {
      key +=  selectedEmail.index.charAt(0).toUpperCase() + selectedEmail.index.slice(1);
    }

    if (customizer[key] && customizer[key].widgets) {
      const arr = [];
      const widgetIds = customizer[key].widgets;

      widgetIds.forEach((id) => {
        const widget = widgets.find((widget) => widget._id === id);
        if (widget) {
          arr.push({ ...widget, id: widget._id });
        }
      });

      return arr;
    }

    return [];
  }, [customizer, selectedEmail, widgets]);

  useEffect(() => {
    if (!customizer) return
    fetchPreview(customizer);
  }, [customizer, selectedEmailWidgets])
  
  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const fetchPreview = async (customizer) => {
    if (loading || !selectedEmail || widgetLoading) {
      return;
    }
    setLoading(true);

    let index = selectedEmail.type;
    if (selectedEmail.index) {
      index += capitalizeFirstLetter(selectedEmail.index);
    }

    try {
      const response = await getCampaignPreview({
        customizer,
        id: campaign._id,
        section: index,
      });
      setPreviewHTML(response);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleNext(customizer, "customizer");
  };

  const getSelectedEmails = useCallback(() => {
    return emails
      .filter((email) => {
        if (email.index) {
          return (
            email.type === selectedEmail.type &&
            email.index === selectedEmail.index
          );
        } else {
          return email.type === selectedEmail.type;
        }
      })
      .map((email) => ({
        label: email.title,
        value: `${email.type}-${email.index || ""}`,
      }));
  }, [selectedEmail, emails]);

  const handleEmailChange = (e) => {
    const type = e[0].value.split("-")[0];
    const index = e[0].value.split("-")[1];
    const email = emails.find((email) => {
      if (index) {
        return email.type === type && email.index === index;
      } else {
        return email.type === type;
      }
    });
    setSelectedEmail(email);
  };

  const fetchWidgets = async () => {
    if (widgetLoading) return;
    setWidgetLoading(true);
    try {
      const response = await getShopWidgets();
      setWidgets(response.concat(emailBodyWidget));
    } catch (error) {
      console.log(error);
    }
    setWidgetLoading(false);
  };

  const handleDragStart = (event) => {
    if (!selectedEmailWidgets) return;

    setDraggingItem(
      selectedEmailWidgets.find((widget) => widget._id === event.active.id)
    );
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    let key = selectedEmail.type;
    if (selectedEmail.index) {
      key += selectedEmail.index;
    }
    let arr = customizer[key]?.widgets;

    if (!active || !over || !arr) return;

    const oldIndex = arr.findIndex((id) => id === active.id);
    const newIndex = arr.findIndex((id) => id === over.id);

    const newOptions = arrayMove(arr, oldIndex, newIndex);

    const newCustomizer = {
      ...customizer,
      [key]: { ...customizer[key], widgets: newOptions },
    };

    setCustomizer(newCustomizer);
    fetchPreview(newCustomizer);
    setDraggingItem(null);
  };

  return (
    <form className="an-row w-100 h-100" onSubmit={handleSubmit}>
      <div className="an-col_3_12 an-layout an-layout_gap_32">
        <div className="widget-organization-select">
          <UiCaptionMedium text="Select an email" className="mb-8" />
          <Select
            id="channel"
            name="channel"
            options={emails.map((email) => ({
              label: email.title,
              value: `${email.type}-${email.index || ""}`,
            }))}
            placeholder="Select email"
            onChange={handleEmailChange}
            className="form-select"
            values={getSelectedEmails()}
          />
        </div>

        <CustomizationOptionsContainer className={`h-100`}>
          {selectedEmailWidgets && selectedEmailWidgets.length > 0 && (
            <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
              <SortableContext items={selectedEmailWidgets}>
                {selectedEmailWidgets.map((widget, key) => {
                  if (widget.widgetSlug === 'email-body') {
                    return <WidgetPreviewBody widget={widget} key={widget._id} />
                  }
                  return (
                    <WidgetPreview
                      widget={widget}
                      key={widget._id}
                      fullWidth
                      draggable
                    />
                  )
                })}
              </SortableContext>
              <DragOverlay>
                {draggingItem ? (
                  <WidgetPreview draggable widget={draggingItem} fullWidth />
                ) : null}
              </DragOverlay>
            </DndContext>
          )}

          {!selectedEmailWidgets.length && <Preloader absolute overflow />}
        </CustomizationOptionsContainer>
      </div>

      <CustomizationContainer
        key={`customization-container`}
        className="an-col_7_12"
        previewHTML={previewHTML}
        loading={loading}
      />

      <StepWizardNavigation onPrev={() => handlePrev("", null, true)} />
    </form>
  );
};

export default WidgetOrganizationStep;
