import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import UiSmallRegular from "../ui-elements/text/UiSmallRegular";
import "./WidgetButton.scss";

const WidgetButton = props => {
  return (
    <div
      className={`card card_8 card_mh_80 widget-button card_shadow card_border${props.active ? ' widget-button_active' : ''}`}
      onClick={() => props.onClick(props.widget)}
    >
      <div className="widget-button__emoji">{props.widget.emoji}</div>
      <div className="widget-button__body">
        <UiCaptionRegular
          text={props.widget.name}
          className="color-secondary-colour-secondary-7"
        />
        <UiSmallRegular
          text={props.widget.description}
          className="color-secondary-colour-secondary-4"
        />
      </div>
    </div>
  )
}

export default WidgetButton;