import { Table, Header, HeaderRow, Body, Row, Cell } from "@table-library/react-table-library/table";
import { useSort, HeaderCellSort } from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import AnTable from "../../ui-elements/common/AnTable";
import AkarIconsChevronVertical from "../../static/images/common/akar-icons_chevron-vertical.svg";
import AkarIconsChevronVerticalCopy from "../../static/images/common/akar-icons_chevron-vertical copy.svg";
import "./UserLocation.scss";

const UserLocation = ({ stats, clicked }) => {
  const nodes = stats.map(stat => {
    return {
      ...stat,
      percentage: (stat.count / clicked * 100).toFixed(2)
    }
  });

  const sort = useSort({ nodes }, {}, {
    sortIcon: {
      margin: '0px',
      iconDefault: <img src={AkarIconsChevronVertical} alt="" />,
      iconUp: <img src={AkarIconsChevronVerticalCopy} alt="" />,
      iconDown: <img src={AkarIconsChevronVerticalCopy} alt="" />,
    },
    sortFns: {
      CITY: array => array.sort((a, b) => a.city.localeCompare(b.city)),
      COUNTRY: array => array.sort((a, b) => a.country.localeCompare(b.country)),
      CLICKED: array => array.sort((a, b) => a.count - b.count),
      PERCENTAGE: array => array.sort((a, b) => a.percentage - b.percentage)
    },
  });

  const theme = useTheme([getTheme(), {
    Table: `
      --data-table-library_grid-template-columns: repeat(2, minmax(0, 1fr)) repeat(2, 120px)
    `
  }]);

  return (
    <>
      <div className="email-templates-subtitle-2-medium">User location</div>
      <div className="email-templates-caption-regular color-secondary-colour-secondary-4 mt-4">Latest activity from your customers</div>
      <div className="mt-24 user-location-table">
        <AnTable
          body={
            <Table
              data={{ nodes }}
              sort={sort}
              theme={theme}
              layout={{
                custom: true
              }}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCellSort sortKey="CITY">City</HeaderCellSort>
                      <HeaderCellSort sortKey="COUNTRY">Country</HeaderCellSort>
                      <HeaderCellSort sortKey="CLICKED">Clicked</HeaderCellSort>
                      <HeaderCellSort sortKey="PERCENTAGE">Percentage</HeaderCellSort>
                    </HeaderRow>
                  </Header>
                  <Body>
                    {tableList.map((item) => (
                      <Row item={item} key={item._id}>
                        <Cell>{item.city}</Cell>
                        <Cell>{item.country}</Cell>
                        <Cell>{item.count}</Cell>
                        <Cell>{item.percentage}%</Cell>
                      </Row>
                    ))}
                  </Body>
                </>
              )}
            </Table>
          }
        />
      </div>
    </>
  );
}

export default UserLocation;