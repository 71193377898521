import React, { useState } from "react";
import FormControl from "../ui-elements/forms/FormControl";
import UiButton from "../ui-elements/buttons/UiButton";
import Preloader from "../preloader/Preloader";

const SignatureForm = ({ signature = "", loading, onSave }) => {
  const [_signature, setSignature] = useState(signature);

  const buttonDisabled = () => {
    return JSON.stringify(signature) === JSON.stringify(_signature);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(_signature);
  };

  return (
    <form className="card card_dropdown card_24" onSubmit={handleSubmit}>
      <div className="an-layout an-layout_gap_32">
        <FormControl
          id="signature"
          label="Signature"
          name="signature"
          placeholder="Ex: My store team"
          value={_signature}
          onChange={(e) => setSignature(e.target.value)}
          labelClassName="ui-caption-medium"
          labelMargin="4"
        />

        <div className="flex-right gap-8">
          <UiButton
            type="button"
            size="medium"
            design="secondary"
            text="Cancel"
            disabled={buttonDisabled()}
            onClick={() => setSignature(signature)}
          />
          <UiButton
            size="medium"
            text="Save changes"
            disabled={buttonDisabled()}
          />
        </div>
      </div>

      {loading && <Preloader absolute overflow />}
    </form>
  );
};

export default SignatureForm;
