import React from 'react'

const Textarea = (props) => {
  return (
    <div className={`form-control${props.margin ? ` mt-${props.margin}` : ""}`}>
      {props.label && (
        <label
          htmlFor={props.id}
          className={`${
            props.labelClassName
              ? props.labelClassName
              : "email-templates-paragraph-1-medium"
          } form-control__label${
            props.labelMargin ? ` mb-${props.labelMargin}` : ""
          }${
            props.disabled ? ' form-control__label_disabled' : ''
          }`}
        >
          {props.label}
        </label>
      )}
      <textarea
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue ?? ''}
        className={`form-control__input form-control__input_textarea ui-caption-regular${
          props.validationError ? " form-control__input_error" : ""
        }${props.inputClassName ? ` ${props.inputClassName}` : ""}`}
        required={props.required ? props.required : false}
        onChange={props.onChange}
        value={props.value ?? props.value}
        readOnly={props.readOnly ? "readonly" : false}
        onClick={props.onClick}
        disabled={props.disabled}
      ></textarea>
      {props.validationError && (
        <div className="form-control__error ui-caption-regular">
          {props.validationError.message}
        </div>
      )}
    </div>
  );
}

export default Textarea;