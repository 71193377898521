import HeaderProfile from "./HeaderProfile";
import { Link } from "react-router-dom";
import "./Header.scss";
import logo from "../static/images/common/Logo.svg";
import { AuthContext } from "../context/AuthContext";

const Header = (props) => {
  return (
    <header className={`header${props.type ? ` header_${props.type}` : ""}`}>
      <div className="container">
        <div className="header__inner">
          <Link to={process.env.REACT_APP_HOME_URL} className="header__logo">
            <img src={logo} className="responsive-img" alt="Anomate" />
          </Link>
          {props.user && <HeaderProfile user={props.user} type={props.type} />}
        </div>
      </div>
    </header>
  );
};

export default Header;
