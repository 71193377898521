import React, { memo, useCallback } from "react";
import classNames from "classnames";
import FormControl from "../../forms/FormControl";
import styles from "./QuestionBlockOption.module.scss";
import { TrashCan, CirclePlus } from "akar-icons";
import UiBaseCheckbox from "../../forms/UiBaseCheckbox";
import TextareaAutosize from "../../textarea-autosize/TextareaAutosize";

const QuestionBlockOption = (props) => {
  const handleKeyDown = useCallback(
    (e) => {
      if (props.addDisabled) return;
      if (e.key === "Enter") {
        props.onAdd(props.index);
      }
    },
    [props.onAdd, props.addDisabled, props.index]
  );

  const handleOnChange = useCallback(
    (e) => {
      props.onChange(e, props.index);
    },
    [props.index, props.onChange]
  );

  if (props.type === "rating") {
    return (
      <>
        <div
          className={classNames(
            styles["base"],
            styles["editing"],
            styles["rating"]
          )}
        >
          <div
            className={classNames(
              styles["number"],
              "ui-email-templates-subtitle-1-semibold"
            )}
          >
            {props.index + 1}
          </div>
          {props.isEditing ? (
            <FormControl
              type="text"
              placeholder="Label"
              fullWidth
              value={props.value}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
            />
          ) : <div className="email-templates-paragraph-2-regular text-center">{props.value}</div>}
        </div>
      </>
    );
  }

  if (props.type === "textarea") {
    return (
      <div className={classNames(styles["base"], styles["editing"])}>
        <TextareaAutosize
          minRows={5}
          readOnly
          fullWidth
          placeholder={`Client answer goes here`}
        />
      </div>
    );
  }

  if (props.type === "radio" || props.type === "checkbox")
    return (
      <div className={classNames(styles["base"], styles["editing"])}>
        {props.type === "radio" && (
          <FormControl
            className={styles["checkbox"]}
            type="radio"
            options={[""]}
            readOnly
          />
        )}

        {props.type === "checkbox" && (
          <UiBaseCheckbox className={styles["checkbox"]} disabled />
        )}

        <FormControl
          classNameDiv={styles["input"]}
          type="text"
          placeholder="Add an option here"
          fullWidth
          value={props.value}
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          autoFocus
          readOnly={!props.isEditing}
        />
        <span
          className={classNames(styles["trash"], {
            [`${styles["disabled"]}`]: props.removeDisabled,
          })}
          onClick={() =>
            props.removeDisabled ? {} : props.onRemove(props.index)
          }
        >
          <TrashCan size={24} />
        </span>
        <span
          className={classNames(styles["plus"], {
            [`${styles["disabled"]}`]: props.addDisabled,
          })}
          onClick={() => (props.addDisabled ? {} : props.onAdd(props.index))}
        >
          <CirclePlus size={24} />
        </span>
      </div>
    );
};

export default memo(QuestionBlockOption);
