import { useEffect, useState } from "react";
import TotalEmailsSent from "../TotalEmailsSent";
import AutomatedRaffles from './AutomatedRaffles';
import RaffleGiveawayCampaignPerformance from "./RaffleGiveawayCampaignPerformance";
import UserLocation from "../UserLocation";
import Timeline from "../Timeline";
import KPIStats from '../../../ui-elements/kpi-stats/KPIStats';
import { getStats } from '../tools';
import { getRaffleGiveawaySubscribersToday } from "../../../api/Campaign";

import { getCampaignLocationStats, getCampaignTimeline } from "../../../api/Campaign";
import "../Overview.scss";

const RaffleGiveawayOverview = ({ campaigns, slug, handleCreateMode }) => {
  const statObj = {
    clicked: 0,
    subscriptions: 0,
    subscribersToday: 0,
    totalWinners: 0,
    customers: 0,
    participants: 0,
    impressions: 0,
    refs: 0
  }
  const [stats, setStats] = useState(statObj);
  const [locationStats, setLocationStats] = useState([]);
  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    fetchStats();
    fetchData();
  }, [campaigns]);

  const fetchStats = async () => {
    let _stats = getStats(statObj, campaigns);
    const subscribersToday = await getRaffleGiveawaySubscribersToday();

    _stats.subscribersToday = subscribersToday;

    setStats(_stats);
  }

  const fetchData = async () => {
    setLocationStats(await getCampaignLocationStats(slug));
    setTimeline(await getCampaignTimeline(slug));
  }

  return (
    <div className="an-layout">
      <div className="an-row an-row_gap_8">
        {stats && <KPIStats 
          stats={stats}
          indicators={{
            subscriptions: 'Total subscribers',
            subscribersToday: 'Subscribers today',
            totalWinners: 'Total winners',
            customers: 'Became customers',
          }}
        />}
      </div>
      {/* {!!stats?.emailsSent && ( */}
        <div className="an-row">
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
            <RaffleGiveawayCampaignPerformance stats={stats} />
          </div>
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
            <TotalEmailsSent campaigns={campaigns} showChart={!!stats?.emailsSent} />
          </div>
        </div>
      {/* )} */}
      <AutomatedRaffles campaigns={campaigns} slug={slug} handleCreateMode={handleCreateMode} />
      <div className="an-row">
        {!!locationStats.length && (
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
            <UserLocation
              stats={locationStats}
              clicked={stats.clicked}
            />
          </div>
        )}
        {!!timeline.length && (
          <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
            <Timeline
              timeline={timeline}
              campaignName={campaigns[0].name}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default RaffleGiveawayOverview;