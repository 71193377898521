import "./Progress.scss";

const Progress = props => {
  return (
    <div className="onboarding-progress">
      <div className="onboarding-progress__value" style={{ width: `${props.value}%` }}></div>
    </div>
  )
}

export default Progress;