import { useState } from "react";
import axios from "axios";

import Preloader from "../preloader/Preloader";
import { Notify } from "../ui-elements/notify/notify";

import CompleteTask from "./CompleteTask";
import Score from "./Score";
import CompleteButton from "./CompleteButton";
import illustration1 from "../static/images/dashboard/Illustration.svg";

import "./CompleteSetup.scss";

import { getPropgress } from "./tools";

const CompleteSetup = ({ tasks, setTasks }) => {
  const defaultTask = {
    title: 'Setting up is almost done!',
    content: 'To get the best out of Anomate, complete the 4 steps to kickstart your growth!'
  }
  const [progress, setProgress] = useState(getPropgress(tasks));
  
  const [loading, setLoading] = useState(false);
  const [selectedTask, setSelectedTask] = useState(defaultTask)
  const token = localStorage.getItem('token');

  const switchTask = (task) => {
    if (!task.completed) {
      setSelectedTask({
        title: task.title,
        content: task.content,
        type: task.type,
      })
    }
  }

  const handleSkip = () => {
    const changedTasks = [...tasks]
    const taskIndex = changedTasks.findIndex(task => task.type == selectedTask.type)
    changedTasks[taskIndex].completed = true

    setLoading(true);
    axios.put('/shop', {[selectedTask.type]: true}, {
      headers: {
        Authorization: `Bearer: ${token}`
      }
    })
    .then(() => {
      setTasks(changedTasks)
      setSelectedTask(defaultTask)
      setProgress(getPropgress(changedTasks))
    })
    .catch(err => {
      Notify.error({title: 'Something went wrong :(. Please, try later'})
    })
    .finally(() => {
      setLoading(false)
    }) 
  }

  if (progress == 100) return null

  return (
    <>
      <div className="card complete-setup">
      <div className="complete-setup__row">
        <div className="complete-setup__tasks">
          <div className="email-templates-overline">Complete your setup</div>
          <h2 className="email-templates-subtitle-1-semibold mt-4 color-gray-gray-10 mb-0">Getting started tasks</h2>
        </div>
        <div className="complete-setup__state">
          <Score value={progress} />
        </div>
      </div>
      <div className="complete-setup__row">
        <div className="complete-setup__tasks">
          {loading ? <Preloader absolute={true} /> : (
            <ul className="complete-tasks">
              {tasks.map((task, key) => (
                <CompleteTask
                  task={task}
                  active={task.type == selectedTask.type}
                  key={key}
                  switchTask={task => switchTask(task)}
                />
              ))}
            </ul>
          )}
        </div>
        <div className="complete-setup__state">
          <div className="complete-data">
            <div className="complete-data__content">
              <div>
                <div className="email-templates-paragraph-1-medium">{selectedTask.title}</div>
                <div className="mt-8 email-templates-paragraph-2-regular color-secondary-colour-secondary-7">{selectedTask.content}</div>
              </div>
              {/* <div className="complete-data__buttons">
                <CompleteButton activeTask={selectedTask.type} handleSkip={handleSkip} />
              </div> */}
            </div>
            <div className="complete-data__image-wrapper">
              <img
                src={illustration1}
                alt={selectedTask.title}
                className="complete-data__image responsive-img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default CompleteSetup;