import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import FormControl from "../../forms/FormControl";
import { useFormik } from "formik";
import * as yup from "yup";

const IncludeCoupon = forwardRef((props, ref) => {
  const [value, setValue] = useState(Number(props.value));
  const initialValues = {
    includeCoupon: Number(props.value),
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: yup.object().shape({
      includeCoupon: props.required
        ? yup.boolean().required("This is mandatory")
        : yup.boolean(),
    }),
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(false);
      props.onChange && props.onChange(Boolean(values.includeCoupon));
    },
  });

  useEffect(() => {
    setValue(Number(props.value));
  }, [props.value]);

  const runValidation = () => formik.validateForm().then(errors => props.onValidate(Object.keys(errors).length === 0));

  useEffect(() => {
    runValidation();
  }, [props.value]);

  useImperativeHandle(
    ref,
    () => ({
      submit() {
        formik.handleSubmit();
      }
    }),
    []
  );

  const handleOnChange = (e) => {
    const _value = Number(e.target.value);
    setValue(_value);
    formik.handleChange(e);
    props.onChange(Boolean(_value));

    runValidation();
  };

  return (
    <div>
      <FormControl
        type="radio"
        id="includeCoupon"
        blockRadios={true}
        label={`Would you like to include a coupon?`}
        labelClassName="ui-caption-medium"
        options={[
          {
            label: "Yes",
            value: 1,
          },
          {
            label: "No",
            value: 0,
          },
        ]}
        value={value}
        blockGap={4}
        labelMargin="4"
        onChange={handleOnChange}
      />
    </div>
  );
});

export default IncludeCoupon;
