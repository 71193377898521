import axios from "axios";
// Get packages
export const getPackages = () => {
  const token = localStorage.getItem("token");

  return new Promise((resolve, reject) => {
    axios
      .get("/packages", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
