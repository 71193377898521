import React, { useContext, useEffect } from "react";
import UiHeading from "../../ui-elements/text/UiHeading";
import styles from "./AccessDeniedPage.module.scss";
import { AuthContext } from "../../context/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import Preloader from "../../preloader/Preloader";

const AccessDeniedPage = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const {
    user,
    shopData,
    isLoggedIn,
    updateSession,
    loading,
    hasNoPermission,
    isSubScriptionPaused,
  } = useContext(AuthContext);

  useEffect(() => {
    if (!isLoggedIn) return;

    if ((!user || !shopData) && token) {
      updateSession(token);
    }
  }, [token, user, shopData, isLoggedIn]);

  useEffect(() => {
    if (!user || !isLoggedIn) return;
    if (!hasNoPermission(user)) return;

    const timer = setTimeout(() => {
      navigate("/logout");
    }, 5000);

    return () => clearTimeout(timer);
  }, [isLoggedIn, user]);

  if (isLoggedIn) {
    if (loading || !user) {
      return <Preloader />;
    } else {
      if (hasNoPermission(user)) {
        return (
          <div className={styles["base"]}>
            <UiHeading text={`Access Denied`} element="h3" />
            <UiHeading
              text={`You will be redirected to the login page shortly`}
              element="h3"
            />
          </div>
        );
      } else {
        return <Navigate to="/" replace />;
      }
    }
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default AccessDeniedPage;
