import { useState, useEffect } from "react";
import { CirclePlus, CircleX } from "akar-icons";
import UiBaseCheckbox from "../forms/UiBaseCheckbox";
import TimePicker from "rc-time-picker";
import moment from "moment";
import 'rc-time-picker/assets/index.css';
import "./OpeningHours.scss";
import "./Timepicker.scss";

const OpeningHours = ({ value, onChange }) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const showSecondInitial = value ? value.map(item => item.opensAt2 || item.closesAt2) : [false, false, false, false, false, false, false];
  const [showSecond, setShowSecond] = useState(showSecondInitial);

  const toggleClosed = (e, key) => {
    const updatedValues = value;

    updatedValues[key] = { ...updatedValues[key], closed: e.target.checked };

    onChange(updatedValues);
  }

  const handleChange = (e, key, field) => {
    const updatedValues = value;
    
    updatedValues[key] = { ...updatedValues[key], [field]: e ? e.format('HH:mm') : '' };

    onChange(updatedValues);
  }

  const handleShowSecond = (e, key) => {
    e.preventDefault();

    setShowSecond({ ...showSecond, [key]: true });
  }

  const handleRemoveSecond = (e, key) => {
    e.preventDefault();

    setShowSecond(prevShowSecond => ({ ...prevShowSecond, [key]: false }));

    const updatedValues = value;
    
    updatedValues[key] = {
      ...updatedValues[key],
      opensAt2: '',
      closesAt2: ''
    }

    onChange(updatedValues);
  }

  return (
    <div className="opening-hours">
      {days.map((day, key) => (
        <div
          className="oh-day"
          key={key}
        >
          <div className="oh-day__head">
            <div className="email-templates-paragraph-2-medium color-golden-purple-purple-5">{day}</div>
            <UiBaseCheckbox
              label="Closed"
              onChange={e => toggleClosed(e, key)}
              checked={value[key].closed}
            />
          </div>
          {!value[key].closed ? (
            <div className="oh-day__body mt-8">
              <div className="oh-day__body-row">
                <div className="oh-day__time">
                  <label htmlFor={`opensAt${key}`} className="email-templates-paragraph-2-medium form-control__label mb-4">Opens at</label>
                  <TimePicker
                    className="timepicker"
                    onChange={(e) => handleChange(e, key, 'opensAt1')}
                    placeholder="09:00"
                    id={`opensAt${key}`}
                    showSecond={false}
                    allowEmpty={false}
                    minuteStep={5}
                    defaultValue={value[key].opensAt1 ? moment(value[key].opensAt1, 'HH:mm') : null}
                  />
                </div>
                <div className="oh-day__time">
                  <label htmlFor={`closesAt${key}`} className="email-templates-paragraph-2-medium form-control__label mb-4">Closes at</label>
                  <TimePicker
                    className="timepicker"
                    onChange={(e) => handleChange(e, key, 'closesAt1')}
                    placeholder="18:00"
                    id={`closesAt${key}`}
                    showSecond={false}
                    allowEmpty={false}
                    minuteStep={5}
                    defaultValue={value[key].closesAt1 ? moment(value[key].closesAt1, 'HH:mm') : null}
                  />
                </div>
                <div className="oh-day__add">
                  {!showSecond[key] && (
                    <button
                      className="oh-day__add-button"
                      onClick={e => handleShowSecond(e, key)}
                    >
                      <CirclePlus size="24" />
                    </button>
                  )}
                </div>
              </div>
              {showSecond[key] && (
                <div className="oh-day__body-row">
                  <div className="oh-day__time">
                    <label htmlFor={`opensAt${key}2`} className="email-templates-paragraph-2-medium form-control__label mb-4">Opens at</label>
                    <TimePicker
                      className="timepicker"
                      onChange={(e) => handleChange(e, key, 'opensAt2')}
                      placeholder="09:00"
                      id={`opensAt${day}2`}
                      minuteStep={5}
                      showSecond={false}
                      allowEmpty={false}
                      defaultValue={value[key].opensAt2 ? moment(value[key].opensAt2, 'HH:mm') : null}
                    />
                  </div>
                  <div className="oh-day__time">
                    <label htmlFor={`closesAt${key}2`} className="email-templates-paragraph-2-medium form-control__label mb-4">Closes at</label>
                    <TimePicker
                      className="timepicker"
                      onChange={(e) => handleChange(e, key, 'closesAt2')}
                      placeholder="18:00"
                      id={`closesAt${key}2`}
                      showSecond={false}
                      allowEmpty={false}
                      minuteStep={5}
                      defaultValue={value[key].closesAt2 ? moment(value[key].closesAt2, 'HH:mm') : null}
                    />
                  </div>
                  <div className="oh-day__add">
                    <button
                      className="oh-day__add-button"
                      onClick={e => handleRemoveSecond(e, key)}
                    >
                      <CircleX size="24" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : ''}
        </div>
      ))}
    </div>
  );
}

export default OpeningHours;