import React from 'react'
import { useSortable } from '@dnd-kit/sortable';

import UiHeading from '../ui-elements/text/UiHeading';
import UiExtraSmallRegular from '../ui-elements/text/UiExtraSmallRegular';
import EmailMockup from "../static/images/widgets/EmailMockup.svg";

const WidgetPreviewBody = ({widget}) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: widget._id,
	  })
	return (
		<div 
			className='card card_shadow card_border card_16 widget-preview widget-preview_static card_overflow_visible'
			ref={setNodeRef}
			{...attributes}
		>
			<div className="widget-preview__head mb-8">
				<div className="widget-preview__head-data">
					<UiHeading
						desktop="other-headings-h6-bold"
						element="h6"
						className="nowrap-overflow mb-8"
						text='Email body'
					/>
					<UiExtraSmallRegular 
						className="color-secondary-colour-secondary-5 text-uppercase"
						text="This can’t be changed"
					/>
				</div>
			</div>
			<div className='widget-preview__body'>
				<img
					src={EmailMockup}
					alt=""
					className="responsive-img"
				/>
			</div>
		</div>
	);
}

export default WidgetPreviewBody;