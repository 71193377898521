import React from "react";
import Modal from "react-modal";
import { Cross } from "akar-icons";
import UiButton from "../../ui-elements/buttons/UiButton";
import illustration from "../../static/images/widgets/Illustration.svg";
import Divider from "../../ui-elements/divider/Divider";

const PhysicalCustomPrizeModal = (props) => {
  return (
    <Modal
      isOpen={props.open}
      onRequestClose={props.onClose}
      className="an-modal an-modal__max-w-600 an-modal__rounded-8"
      overlayClassName="an-overlay"
    >
      <div className="an-modal__head">
        <div>
          <div className="d-flex w-100 justify-center pt-12">
            <img src={illustration} alt="" className="responsive-img mt-10" />
          </div>
          <h2 className="email-templates-subtitle-3 text-center">
            Something about physical custom prize
          </h2>
          <p className="email-templates-paragraph-2-regular text-center font-normal color-secondary-colour-secondary-7">
            A physical custom prize requires you to manage the delivery of the
            prize to the winner. In this case, the winner's email will be used
            to request this information, enabling you to send the prize.
          </p>
        </div>
        <button onClick={props.onClose} className="an-modal__close">
          <Cross size="24" color="#d9d9d9" />
        </button>
      </div>

      <Divider />
      <div className="an-modal__body an-modal__body_mh_0 text-left">
        <div className="mt-20 text-right">
          <div className="button-group">
            <UiButton
              size="medium"
              design="secondary"
              text="Cancel"
              onClick={props.onClose}
            />
            <UiButton
              size="medium"
              text={`Yes, I agree`}
              onClick={props.onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PhysicalCustomPrizeModal;
