import React from 'react'

import KPIStats from '../../../ui-elements/kpi-stats/KPIStats';
import TotalEmailsSent from '../TotalEmailsSent';
import Answers from './Answers';
import ActivityTable from '../../../ui-elements/activity-table/ActivityTable';

const SurveySingleOverview = ({ campaigns }) => {
  const campaign = campaigns[0];
  const stats = {
    emailsSent: campaign.emailsSent,
    opened: campaign.opened,
    openSurveys: campaign.openSurveys,
    completedSurveys: campaign.completedSurveys,
  }

  return (
    <div className="an-layout">
      <div className="an-row an-row_gap_8">
        <KPIStats 
          indicators={{
            emailsSent: "Surveys sent",
            opened: "Emails opened",
            openSurveys: "Open surveys",
            completedSurveys: "Completed surveys",
          }} 
          stats={stats} 
        />
      </div>
      <div className="an-row">
        <div className="an-col an-col_50 card card_shadow card_br_24 card_24">
          <TotalEmailsSent
            campaigns={[campaigns[0]]}
            slug="customer-survey"
            individual={true}
          />
        </div>
        <div className="an-col an-col_50 card card_shadow card_br_24 card_24 card_overflow_visible">
          <Answers campaign={campaign} />
        </div>
      </div>
      {stats.completedSurveys > 0 && (
        <div className="an-row">
          <div className="an-col an-col_100 position-relative">
            <ActivityTable
              slug="customer-survey"
              type="open-questions"
              shopCampaignId={campaign._id}
              single={true}
              title="Open questions"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SurveySingleOverview;