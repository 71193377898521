import { ArrowDown } from "akar-icons";
import "./Chips.scss";

const Chips = props => {
  let arrowColor = '#52c41a';
  
  if (props.color === 'red') {
    arrowColor = '#f5222d';
  } else if (props.color === 'blue') {
    arrowColor = '#597ef7';
  }

  return (
    <div className={`chips${props.color ? ` chips_${props.color}` : ''}${props.size ? ` chips_size_${props.size}` : ''}${props.className ? ` ${props.className}` : ''}`}>
      {props.arrow ? (
        <ArrowDown
          size="10"
          color={arrowColor}
        />
      ) : ('')}
      <div className="chips__value">{props.value}</div>
    </div>
  );
}

export default Chips;