import MainContent from "../../dashboard/MainContent";
import SectionHead from "../../ui-elements/common/SectionHead";
import UiHeading from "../../ui-elements/text/UiHeading";
import UiCaptionRegular from "../../ui-elements/text/UiCaptionRegular";
import { useEffect, useState, useCallback, useRef, useContext } from "react";
import { Table, Header, HeaderRow, HeaderCell, Body, Row, Cell, useCustom } from "@table-library/react-table-library/table";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { getShopCustomers } from "../../api/Shop";
import FormControl from "../../ui-elements/forms/FormControl";
import UiButton from "../../ui-elements/buttons/UiButton";
import AnTable from "../../ui-elements/common/AnTable";
import { NavLink } from "react-router-dom";
import PageSize from "../../ui-elements/common/PageSize";
import Pagination from "../../ui-elements/common/Pagination";
import { AuthContext } from "../../context/AuthContext";
import { Tooltip } from "react-tooltip";
import OutsideClickHandler from "react-outside-click-handler";
import { getCustomerTypes, getCustomersSubscribersCount } from "../../api/Shop";
import Chips from "../../ui-elements/common/Chips";

const CustomersPage = () => {
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState({});
  const { shopData } = useContext(AuthContext);
  const [showFilters, setShowFilters] = useState(false);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [customersCount, setCustomersCount] = useState(0);
  const [subscribersCount, setSubscribersCount] = useState(0);

  const [data, setData] = useState({
    nodes: [],
    totalPages: 0
  });

  const handleSearch = e => {
    setSearch(e.target.value);
  }

  const fetchData = useCallback(async (params) => {
    const result = await getShopCustomers({ params });

    setData({
      nodes: result.data,
      totalPages: result.totalPages,
    });

    if (result?.total) {
      setTotal(result.total);
    }
  }, []);

  useEffect(() => {
    fetchCustomerTypes();
    fetchCustomersSubscribersCount();
  }, []);

  const fetchCustomerTypes = async () => {
    let types = await getCustomerTypes();

    types.push({
      _id: 'subscriber',
      name: 'Subscribers'
    });

    setCustomerTypes(types);
  }
  
  const fetchCustomersSubscribersCount = async () => {
    const count = await getCustomersSubscribersCount();
    
    if (count?.subscribers) {
      setSubscribersCount(count.subscribers);
    }

    if (count?.customers) {
      setCustomersCount(count.customers);
    }
  }

  useEffect(() => {
    fetchData({
      search,
      order,
      page: 0,
      customerTypes: selectedFilters
    });
  }, [fetchData]);

  const timeout = useRef();

  const onSearchChange = (action, state) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => fetchData({
      search: state.search,
      page: pagination.state.page,
      size: pagination.state.size,
      order: state.order,
      customerTypes: selectedFilters
    }), 500);
  }

  const onPaginationChange = (action, state) => fetchData({
    search,
    page: state.page,
    size: state.size,
    order,
    customerTypes: selectedFilters
  });

  useCustom('', data, {
    state: {
      search,
      order
    },
    onChange: onSearchChange
  });

  const pagination = usePagination(data, {
    state: {
      page: 0,
    },
    onChange: onPaginationChange,
  }, {
    isServer: true,
  });

  const tableStyles = '--data-table-library_grid-template-columns: repeat(2, minmax(0, 1fr)) 150px 120px 150px 120px';

  const theme = useTheme([getTheme(), {
    Table: tableStyles
  }]);

  const changePageSize = value => pagination.fns.onSetSize(value[0].value);

  const changeOrder = value => {
    let _order = order;

    if (order?.[value]) {
      _order[value] = order[value] === 1 ? -1 : 1;
    } else {
      _order = {};
      _order[value] = 1;
    }

    setOrder(_order);

    pagination.fns.onSetPage(0);
  }

  const formatMoney = value => {
    if (!value) {
      value = 0;
    }

    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: shopData.currency
    });
  }

  const formatDate = dateString => {
    if (!dateString) {
      return '--';
    }
    
    const date = (new Date(dateString).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    }));

    if (date === 'Invalid Date') {
      return '--';
    }

    return date;
  }

  const toggleFilter = id => {
    const index = selectedFilters.indexOf(id);
    let _selectedFilters = selectedFilters;

    if (index !== -1) {
      _selectedFilters.splice(index, 1);
    } else {
      _selectedFilters.push(id);
    }

    setSelectedFilters(_selectedFilters);
    setShowFilters(false);

    pagination.fns.onSetPage(0);

    fetchData({
      search,
      page: 0,
      size: pagination.state.size,
      customerTypes: _selectedFilters
    })
  }

  const getCustomerTypeChips = id => {
    const customerType = customerTypes.find(obj => obj._id === id);

    if (!customerType) {
      return '';
    }

    return (
      <Chips
        color={customerType.color}
        value={customerType.name}
        className="chips_minw_54"
      />
    )
  }

  const renderTable = tableList => {
    if (tableList.length === 0) {
      return <>No customers found</>
    }

    return (
      <>
        <Header>
          <HeaderRow>
            <HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.firstName ? ' activity-table__th_active' : ''}`}>Name</HeaderCell>
            <HeaderCell className={`activity-table__th activity-table__th_no-sorting${order?.email ? ' activity-table__th_active' : ''}`}>Email</HeaderCell>
            <HeaderCell onClick={() => changeOrder('type')} className={`activity-table__th${order?.type ? ' activity-table__th_active' : ''}`}>Customer Type</HeaderCell>
            <HeaderCell onClick={() => changeOrder('totalOrders')} className={`activity-table__th table-align-right${order?.totalOrders ? ' activity-table__th_active' : ''}`}>Total Orders</HeaderCell>
            <HeaderCell onClick={() => changeOrder('avgOrderValue')} className={`activity-table__th table-align-right${order?.avgOrderValue ? ' activity-table__th_active' : ''}`} data-tooltip-id="avg-order-value" data-tooltip-html='<div class="ui-extra-small-medium text-uppercase color-gray-gray-9">Average Order Value</div><div class="ui-small-regular mt-8 color-secondary-colour-secondary-7">This is the average amount customers spend per transaction, vital for understanding purchasing behavior.</div>' data-tooltip-place="bottom-end">Avg Order Value</HeaderCell>
            <HeaderCell onClick={() => changeOrder('lastOrder')} className={`activity-table__th table-align-right${order?.lastOrder ? ' activity-table__th_active' : ''}`}>Last Order</HeaderCell>
          </HeaderRow>
        </Header>
        <Body>
          {tableList.map(item => (
            <Row
              item={item}
              key={item._id}
            >
              <Cell>
                {/* FOR PROD! */}
                {/* <NavLink to={item._id}> */}
                  {(item.firstName || item.lastName) ? (
                    <>{item.firstName} {item.lastName}</>
                  ) : <span style={{opacity: 0.5}}>No Name</span>}
                {/* </NavLink> */}
              </Cell>
              <Cell>{item.email}</Cell>
              <Cell className="table-align-center">{getCustomerTypeChips(item.type)}</Cell>
              <Cell className="table-align-right">{item.totalOrders ? item.totalOrders : 0}</Cell>
              <Cell className="table-align-right">{formatMoney(item.avgOrderValue ? item.avgOrderValue : 0)}</Cell>
              <Cell className="table-align-right">{formatDate(item.lastOrder)}</Cell>
            </Row>
          ))}
        </Body>
      </>
    );
  }

  return (
    <MainContent>
      {/* <SectionHead
        title={
          <>
            <UiHeading
              text={`You have ${total} customers`}
              desktop="other-headings-h6-bold"
            />
            <UiCaptionRegular
              text="Explore individual customer profiles to gain valuable insights into your customer base. From the customer profile, you can chat, respond to customer questions, and leave notes for enhanced support. Additionally, view preferences, interactions, and purchase history for a comprehensive understanding."
              margin="4"
              className="color-secondary-colour-secondary-7"
            />
          </>
        }
      /> */}
      <div className="card card_br_24 card_24 card_shadow card_align_center card_flex">
        <SectionHead
          align="center"
          title={
            <>
              <div className="email-templates-subtitle-2-medium">{customersCount} customers & {subscribersCount} subscribers</div>
              <div className="mt-4 email-templates-caption-regular color-secondary-colour-secondary-4">Latest activity</div>
            </>
          }
          filter={
            <div className="d-flex d-flex_gap_12">
              {/* TODO: commented because it doesn't work well with CSFLE encrypted fields */}
              {/* <FormControl
                type="text"
                name="search"
                id="search"
                placeholder="Search"
                className="form-control__input_search form-control__input_size_xsmall"
                value={search}
                onChange={handleSearch}
              /> */}
              <div className="position-relative">
                <UiButton
                  size="small"
                  design="secondary"
                  text="Filters"
                  onClick={() => setShowFilters(true)}
                />
                <OutsideClickHandler onOutsideClick={() => setShowFilters(false)}>
                  {showFilters && customerTypes.length && (
                    <div className="dropdown dropdown_filter dropdown_campaign">
                      {customerTypes.map(customerType => (
                        <button className={`dropdown__item ui-caption-regular${selectedFilters.includes(customerType._id) ? ' dropdown__item_selected' : ''}`} onClick={() => toggleFilter(customerType._id)} key={customerType._id}>{customerType.name}</button>
                      ))}
                    </div>
                  )}
                </OutsideClickHandler>
              </div>
            </div>
          }
        />
        <div className="mt-24 activity-table">
          <AnTable
            body={
              <>
                <Table
                  data={data}
                  theme={theme}
                  pagination={pagination}
                  layout={{
                    custom: true
                  }}
                >
                  {tableList => renderTable(tableList)}
                </Table>
                <div className="an-table__bottom pl-0 pr-0">
                  <PageSize onChange={changePageSize} />
                  <Pagination
                    pagination={pagination}
                    pagesCount={data?.totalPages ? data.totalPages : 0}
                  />
                </div>
              </>
            }
          />
        </div>
      </div>
      <Tooltip
        id="avg-order-value"
        className="an-tooltip"
        arrowColor="transparent"
      />
    </MainContent>
  );
};

export default CustomersPage;