import React from "react";
import ntExploring1 from "../../../static/images/customization/illustration.svg";
import styles from "./CustomizationPlaceholder.module.scss";
import UiCaptionRegular from "../../text/UiCaptionRegular";
import classNames from "classnames";

const CustomizationPlaceholder = ({ className = "" }) => {
  return (
    <div className={classNames(styles["base"], className)}>
      <img src={ntExploring1} alt="placeholder" />
      <UiCaptionRegular
        className="color-secondary-colour-secondary-7 text-break-spaces"
        text={
          <>
            Select one element on the left <br /> to start customising your
            campaign
          </>
        }
      />
    </div>
  );
};

export default CustomizationPlaceholder;
