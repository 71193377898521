import React, {useState, useEffect} from 'react'

import Preloader from '../../../preloader/Preloader';
import Categories from './Categories';
import Holidays from './Holidays';
import { getPromoCategories } from '../../../api/Campaign';

const Promo = ({handleAIGenerate}) => {
	const [categories, setCategories] = useState([])
	const [step, setStep] = useState(0)
	const [loading, setLoading] = useState(true)
	const [selected, setSelected] = useState({})

	const fetchCategories = async() => {
		try {
			setLoading(true)
			const response = await getPromoCategories()
			setCategories(response)
		} catch (error) {
			throw error
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchCategories()
	}, [])

	if (loading) return <Preloader opaque overflow />

	if (step === 0) {
		return (
			<Categories 
				categories={categories} 
				handleSelect={(category) => {
					setStep(1)
					setSelected({category})
				}} 
			/>
		)
	}

	if (step === 1) {
		return (
			<Holidays
				category={selected.category}
				handleBack={() => setStep(0)}
				handleAIGenerate={handleAIGenerate}
			/>
		)
	}

	return null
}

export default Promo;