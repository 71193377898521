import { useEffect, useState, useContext, useMemo } from "react";
import Banner from "../banner/Banner";
import Banners from "../campaigns/Banners";
import UiHeading from "../ui-elements/text/UiHeading";
import UiButton from "../ui-elements/buttons/UiButton";
import ntWorkEfficiently1 from "../static/images/dashboard/nt-work-efficiently 1.svg";
import { AuthContext } from "../context/AuthContext";
import { CampaignContext } from "../context/CampaignContext";

const CampaignsSetup = () => {
  const { shopData } = useContext(AuthContext);
  const { availableCampaigns, shopCampaigns } = useContext(CampaignContext);
  const [bannerVisible, setBannerVisible] = useState(false);
  useEffect(() => {
    const campaignSetupBannerHidden = localStorage.getItem(
      "campaignSetupBannerHidden"
    );
    if (!campaignSetupBannerHidden && !shopData?.campaignsSetupHidden) {
      setBannerVisible(true);
    }
  }, [shopData]);

  const hideCampaignsSetupNotification = () => {
    setBannerVisible(false);

    localStorage.setItem("campaignSetupBannerHidden", true);
  };

  const available = useMemo(() => {
    return availableCampaigns.length - shopCampaigns.length;
  }, [shopData]);

  return (
    <>
      {available && bannerVisible ? (
        <Banner
          color="sunrise-yellow-yellow-1"
          className="pr-235"
          content={
            <>
              <div className="email-templates-overline">
                Complete your setup
              </div>
              <UiHeading
                elem="h3"
                text={`You still have ${available} campaigns to setup`}
                margin="4"
                desktop="email-templates-subtitle-1-semibold"
              />
              <div className="mt-8 email-templates-paragraph-2-regular color-secondary-colour-secondary-7">
                You have {available} campaigns waiting for your personal touch
                in Anomate. Complete the setup process for each one to unlock
                their full potential and achieve remarkable results.
              </div>
              <div className="button-group mt-16">
                <UiButton
                  text="Go to campaigns"
                  elem="a"
                  href="/campaigns"
                  size="small"
                  design="secondary"
                />
                <UiButton
                  text="Remind me later"
                  size="small"
                  design="no-border"
                  onClick={hideCampaignsSetupNotification}
                />
              </div>
            </>
          }
          image={ntWorkEfficiently1}
          score={true}
        />
      ) : (
        <Banners />
      )}
    </>
  );
};

export default CampaignsSetup;
