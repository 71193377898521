import { useEffect, useState } from "react";
import { Table, Header, HeaderRow, Body, Row, Cell, HeaderCell } from "@table-library/react-table-library/table";
import { useSort, HeaderCellSort } from "@table-library/react-table-library/sort";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { useRowSelect } from "@table-library/react-table-library/select";
import { usePagination } from "@table-library/react-table-library/pagination";
import { Download, Cross } from "akar-icons";
import Modal from "react-modal";
import { PDFDocument, PDFName, PDFArray } from 'pdf-lib';
import FormControl from "../ui-elements/forms/FormControl";
import UiButton from "../ui-elements/buttons/UiButton";
import { getInvoices } from "../api/Auth";
import AnTable from "../ui-elements/common/AnTable";
import { loadPdf } from "../api/Stripe";
import { getPackages } from "../api/Package";
import Chips from "../ui-elements/common/Chips";
import UiBaseCheckbox from "../ui-elements/forms/UiBaseCheckbox";
import Pagination from "../ui-elements/common/Pagination";
import PageSize from "../ui-elements/common/PageSize";
import UiHeading from "../ui-elements/text/UiHeading";
import UiCaptionRegular from "../ui-elements/text/UiCaptionRegular";
import Preloader from "../preloader/Preloader";
import AkarIconsChevronVertical from "../static/images/common/akar-icons_chevron-vertical.svg";
import AkarIconsChevronVerticalCopy from "../static/images/common/akar-icons_chevron-vertical copy.svg";
import styles from "./PaymentHistory.module.scss";

Modal.setAppElement('#root');

const PaymentHistory = () => {
  const [search, setSearch] = useState('');
  const [nodes, setNodes] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [exportDisabled, setExportDisabled] = useState(false);

  const [exportData, setExportData] = useState({
    exportRadio: 'Current page'
  });

  const [data, setData] = useState({
    nodes: []
  });

  const theme = useTheme([
    getTheme(),
    {
      Table: `
      --data-table-library_grid-template-columns: 26px repeat(3, minmax(0, 1fr)) 150px 120px 42px
    `,
    },
  ]);

  const select = useRowSelect(data, {
    onChange: (action, state) => {
      setSelectedIds(state.ids);
      setExportDisabled(exportData.exportRadio !== 'Current page' || state.ids.length === 0);
    }
  });

  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 10,
    }
  });

  const sort = useSort(data, {}, {
    sortIcon: {
      margin: '0px',
      iconDefault: (
        <img
          src={AkarIconsChevronVertical}
          alt=""
        />
      ),
      iconUp: (
        <img
          src={AkarIconsChevronVerticalCopy}
          alt=""
        />
      ),
      iconDown: (
        <img
          src={AkarIconsChevronVerticalCopy}
          alt=""
        />
      )
    },
    sortFns: {
      INVOICE: array => array.sort((a, b) => a.productName.localeCompare(b.productName)),
      CYCLE: array => array.sort((a, b) => a.created - b.created),
      DATE: array => array.sort((a, b) => a.created - b.created),
      STATUS: array => array.sort((a, b) => a.status.localeCompare(b.status)),
      AMOUNT: array => array.sort((a, b) => a.total - b.total)
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const moneyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  });

  const fetchData = async () => {
    try {
      const invoices = await getInvoices();
      const packages = await getPackages();

      const packageObj = packages.reduce((acc, obj) => {
        acc[obj.stripeId] = obj.name;

        return acc;
      }, {});

      const currentNodes = invoices.data.map(invoice => {
        const date = new Date(invoice.period_start * 1000);

        const month = date.toLocaleString('default', {
          month: 'short'
        });

        const day = new Intl.DateTimeFormat('en-US', {
          day: '2-digit'
        }).format(date);

        return {
          ...invoice,
          productName: packageObj[invoice.lines.data[invoice.lines.data.length - 1].plan.product],
          cycle: `Billing cycle ${month} ${date.getFullYear()}`,
          created: `Created ${day} ${month} ${date.getFullYear()}`,
          amount: moneyFormatter.format(invoice.total / 100)
        }
      });

      setNodes(currentNodes);

      setData({
        nodes: currentNodes
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleSearchChange = e => {
    setSearch(e.target.value);

    if (e.target.value) {
      const searchValue = e.target.value.toLowerCase();

      setData({
        nodes: data.nodes.filter((item) => (item.productName.toLowerCase().includes(searchValue) || item.cycle.toLowerCase().includes(searchValue) || item.created.toLowerCase().includes(searchValue) || item.status.toLowerCase().includes(searchValue) || item.amount.toLowerCase().includes(searchValue)))
      });
    } else {
      setData({ nodes });
    }
  }

  const changePageSize = value => pagination.fns.onSetSize(value[0].value);

  const mergePDFs = async (pdfFiles) => {
    const mergedPdf = await PDFDocument.create();

    for (const pdfFile of pdfFiles) {
      const pdfBytes = await loadPdf(pdfFile);
      const pdf = await PDFDocument.load(pdfBytes);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());

      copiedPages.forEach(page => mergedPdf.addPage(page));
    }

    return await mergedPdf.save();
  }

  const handleExport = async (e) => {
    e.preventDefault();

    setLoading(true);

    let pdfFiles = [];
    
    if (exportData.exportRadio === 'Current page') {
      pdfFiles = data.nodes.map(node => node.invoice_pdf);
    } else {
      const selectedInvoices = data.nodes.filter(node => selectedIds.includes(node.id));
      
      pdfFiles = selectedInvoices.map(invoice => invoice.invoice_pdf);
    }

    if (pdfFiles.length) {
      const mergedPdfBytes = await mergePDFs(pdfFiles);
      const mergedPdfBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(mergedPdfBlob);
      const link = document.createElement('a');
  
      link.href = url;
      link.download = 'invoices.pdf';
  
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(link);
    }

    setLoading(false);
  }

  const handleExportChange = e => {
    setExportData({ ...exportData, [e.target.name]: e.target.value });
    setExportDisabled(e.target.name === 'exportRadio' && e.target.value === 'Selected invoices' && selectedIds.length === 0);
  }

  return (
    <div className={`card card_24 card_shadow card_overflow_visible ${styles['payment-history']}`}>
      <div className={styles['payment-history__head']}>
        <div className="ui-paragraph-medium">Payment history</div>
        <div className={styles['payment-history__actions']}>
          <FormControl
            name="search"
            type="text"
            id="search"
            onChange={handleSearchChange}
            placeholder="Search"
            value={search}
            className="form-control__input_search form-control__input_size_small"
          />
          <UiButton
            text="Export"
            design="secondary"
            size="small"
            onClick={() => setModalIsOpen(true)}
          />
        </div>
      </div>
      <div className="mt-32">
        <AnTable
          body={
            <>
              <Table
                data={data}
                sort={sort}
                theme={theme}
                layout={{
                  custom: true
                }}
                select={select}
                pagination={pagination}
              >
                {(tableList) => (
                  <>
                    <Header>
                      <HeaderRow>
                        <HeaderCell stiff>
                          <UiBaseCheckbox
                            name="payment_history_all"
                            id="payment_history_all"
                            checked={select.state.all}
                            onChange={select.fns.onToggleAll}
                          />
                        </HeaderCell>
                        <HeaderCellSort sortKey="INVOICE">Invoice</HeaderCellSort>
                        <HeaderCellSort sortKey="CYCLE">Cycle</HeaderCellSort>
                        <HeaderCellSort sortKey="DATE">Date</HeaderCellSort>
                        <HeaderCellSort sortKey="STATUS">Status</HeaderCellSort>
                        <HeaderCellSort sortKey="AMOUNT">Amount</HeaderCellSort>
                        <HeaderCell>&nbsp;</HeaderCell>
                      </HeaderRow>
                    </Header>
                    <Body>
                      {tableList.map((item) => (
                        <Row item={item} key={item.id}>
                          <Cell stiff>
                            <UiBaseCheckbox
                              name="payment_history"
                              id={`payment_history_${item.id}`}
                              checked={select.state.ids.includes(item.id)}
                              onChange={() => select.fns.onToggleById(item.id)}
                            />
                          </Cell>
                          <Cell>{item.productName}</Cell>
                          <Cell>{item.cycle}</Cell>
                          <Cell>{item.created}</Cell>
                          <Cell>
                            {item.status === 'paid' ? (
                              <Chips
                                value="Paid"
                                color="green"
                                size="large"
                              />
                            ) : (
                              <Chips
                                color="yellow"
                                value={item.status}
                                size="large"
                              />
                            )}
                          </Cell>
                          <Cell>{item.amount}</Cell>
                          <Cell>
                            <a
                              href={item.invoice_pdf}
                              download
                            >
                              <Download
                                color="#d9d9d9"
                                size="16"
                              />
                            </a>
                          </Cell>
                        </Row>
                      ))}
                    </Body>
                  </>
                )}
              </Table>
              <div className="an-table__bottom">
                <PageSize onChange={changePageSize} />
                <Pagination
                  pagination={pagination}
                  nodes={data.nodes}
                />
              </div>
            </>
          }
        />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="an-modal"
        overlayClassName="an-overlay"
      >
        <div className="an-modal__head">
          <div className="an-modal__title">
            <UiHeading
              element="h6"
              desktop="other-headings-h6-bold"
              text="Export your invoices"
            />
            <UiCaptionRegular
              className="mt-8 color-secondary-colour-secondary-7"
              text="Choose to export either the selected invoices or the current page from your payment history."
            />
          </div>
          <button
            onClick={() => setModalIsOpen(false)}
            className="an-modal__close"
          >
            <Cross
              size="24"
              color="#d9d9d9"
            />
          </button>
        </div>
        <form
          onSubmit={handleExport}
          className="an-modal__body an-modal__body_mh_0 mt-32 pt-32 bt-1 text-left"
        >
          <FormControl
            label="Export"
            type="radio"
            id="exportRadio"
            name="exportRadio"
            value={exportData.exportRadio}
            labelMargin="16"
            onChange={handleExportChange}
            labelClassName="ui-caption-medium"
            options={['Current page', 'Selected invoices']}
            blockRadios={true}
            radioLabelColor="gray-gray-9"
          />
          <div className="mt-24 pt-24 bt-1 text-right">
            <div className="button-group">
              <UiButton
                size="medium"
                design="secondary"
                text="Cancel"
                onClick={() => setModalIsOpen(false)}
              />
              <UiButton
                size="medium"
                text="Export invoices"
                type="submit"
                disabled={exportDisabled}
              />
            </div>
          </div>
        </form>
        {loading ? (
          <Preloader
            absolute={true}
            overflow={true}
          />
        ) : ''}
      </Modal>
    </div>
  )
}

export default PaymentHistory;